<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                <span class="color-grey ">Accounting Preferences For: Your Company Name Here <a style="color: red;">{{this.partyId}}</a> </span>
                <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass">Back</button>

            </div>

            <div class="row w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:2px">
                        <li [ngClass]="activeCategoryMain==1?'active':''" (click)="AccountingInfo()"><a>
                        Available Internal Accounting Organizations</a></li>
                        <li [ngClass]="activeCategoryMain==2?'active':''" (click)="AccountingCompany()"><a>
                        Create new accounting company</a></li>


                    </ul>
                </div>

                <div class="p-3">
                    <div class="w3-card-4 classCard p-0 mt-3">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                                <li [ngClass]="activeMain==1?'active':''" (click)="setup()"><a>
                                Setup</a></li>
                                <!-- <li [ngClass]="activeMain==2?'active':''" (click)="accounting()"><a>
                                Accounting</a></li> -->
    
    
                            </ul>
                        </div>
                        <div class=" bg-white color-grey" style="    padding: 8px 15px;">
                            <div class="port-header-contact create-lead font-medium mb-0">
                                <div class="suppliers-wrapper">
    
                                </div>
                            </div>
                            <div class="w3-card-4 classCard">
                                <div class="container-fluid">
                                    <ul class="tabbing-section tabbing-accordians tabClass">
    
                                        <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategoryFunction(2)"><a>
                                    Accounting Preferences</a></li>
                                        <li [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategoryFunction(3)"><a>
                                    Chart of Accounts
                                </a></li>
    
                                        <li [ngClass]="activeCategory==4?'active':''" (click)="changeactiveCategoryFunction(4)"><a>
                                    Doc. Type template</a></li>
                                        <li [ngClass]="activeCategory==5?'active':''" (click)="changeactiveCategoryFunction(5)"><a>
                                    Journals </a></li>
                                        <li [ngClass]="activeCategory==8?'active':''" (click)="changeactiveCategoryFunction(8)"><a>
                                    GL Account Defaults </a></li>
                                        <li [ngClass]="activeCategory==6?'active':''" (click)="changeactiveCategoryFunction(6)"><a>
                                    Time Periods </a></li>
    
    
    
                                    </ul>
                                </div>
                                <div>
                                    <div class="container">
                                        <div class="row">
                                            <form class="w-100">
                                                <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                                    <div class="w-100">
                                                        <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
    
                                                            <div *ngIf="activeCategory==2">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling">
                                                                        Accounting Preferences
                                                                    </h4>
    
    
                                                                </div>
                                                                <div class="panel-body">
                                                                    <div>
                                                                        <div class="container-fluid">
                                                                            <div class="row">
                                                                                <form class="w-100" [formGroup]="createAccounting">
                                                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                        <div class="col-lg-12">
                                                                                            <div class="row">
    
                                                                                                <div class="col-lg-3 form-group classInput">
                                                                                                    <label for="exampleInputEmail1">Organization
                                                                                        Party ID
    
                                                                                    </label>
                                                                                                </div>
    
                                                                                                <div class="col-lg-2">
                                                                                                    <label style="color: black;">{{this.partyId}}</label>
                                                                                                </div>
                                                                                                <div class="col-lg-3 form-group classInputA">
                                                                                                    <label for="exampleInputEmail1">Fiscal
                                                                                            Year Start Month</label>
                                                                                                </div>
                                                                                                <div class="col-lg-2">
                                                                                                    <p-dropdown placeholder="Enter Fiscal Year Start Month" filter="true" formControlName="fiscalYearStart" [options]="fiscalYearStartMonthArray" optionlabel="label">
    
                                                                                                    </p-dropdown>
                                                                                                </div>
    
    
    
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-lg-12">
                                                                                            <div class="row">
    
                                                                                                <div class="col-lg-3 form-group classInput">
                                                                                                    <label for="exampleInputEmail1">Fiscal
                                                                                            Year Start Day</label>
                                                                                                </div>
    
                                                                                                <div class="col-lg-2">
                                                                                                    <p-dropdown placeholder="Enter Fiscal Year Start Day" filter="true" formControlName="fiscalDay" [options]="fiscalDay" optionlabel="label">
    
                                                                                                    </p-dropdown>
                                                                                                </div>
                                                                                                <div class="col-lg-3 form-group classInputA">
                                                                                                    <label for="exampleInputEmail1">Tax Form
                                                                                            for Organization<span
                                                                                            style="color:red">*</span></label>
                                                                                                </div>
                                                                                                <div class="col-lg-2">
                                                                                                    <p-dropdown placeholder="Enter Tax Form for Organization" filter="true" formControlName="taxForm" [options]="taxFormOrgIdArray" optionlabel="label">
    
                                                                                                    </p-dropdown>
                                                                                                </div>
    
    
    
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-lg-12">
                                                                                            <div class="row">
    
                                                                                                <div class="col-lg-3 form-group classInput">
                                                                                                    <label for="exampleInputEmail1">Cost Of
                                                                                            Goods Sold (COGS) Method<span
                                                                                            style="color:red">*</span></label>
                                                                                                </div>
    
                                                                                                <div class="col-lg-2">
                                                                                                    <p-dropdown placeholder="Enter Cost Of Goods Sold (COGS) Method" filter="true" formControlName="soldCOGS" [options]="costOfGoodsSoldArray" optionlabel="label">
    
                                                                                                    </p-dropdown>
                                                                                                </div>
                                                                                                <div class="col-lg-3 form-group classInputA">
                                                                                                    <label for="exampleInputEmail1">Base
                                                                                            Currency<span
                                                                                            style="color:red">*</span></label>
                                                                                                </div>
                                                                                                <div class="col-lg-2">
                                                                                                    <p-dropdown placeholder="Enter Base Currency" filter="true" formControlName="baseCurrency" [options]="currencyArray" optionlabel="label">
    
                                                                                                    </p-dropdown>
                                                                                                </div>
    
    
    
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-lg-12">
                                                                                            <div class="row">
    
                                                                                                <div class="col-lg-3 form-group classInput">
                                                                                                    <label for="exampleInputEmail1">Invoice
                                                                                            Seq Cust Meth ID<span
                                                                                            style="color:red">*</span></label>
                                                                                                </div>
    
                                                                                                <div class="col-lg-2">
                                                                                                    <input type="email" class="form-control" id="exampleInputEmail1" formControlName="invoiceSeq" aria-describedby="emailHelp" placeholder="Enter Invoice Seq Cust Meth ID">
                                                                                                </div>
                                                                                                <div class="col-lg-3 form-group classInputA">
                                                                                                    <label for="exampleInputEmail1">Invoice
                                                                                            ID Prefix</label>
                                                                                                </div>
                                                                                                <div class="col-lg-2">
                                                                                                    <input type="email" class="form-control" id="exampleInputEmail1" formControlName="invoiceIDPrefix" aria-describedby="emailHelp" placeholder="Enter Invoice ID Prefix">
                                                                                                </div>
    
    
    
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-lg-12">
                                                                                            <div class="row">
    
                                                                                                <div class="col-lg-3 form-group classInput">
                                                                                                    <label for="exampleInputEmail1">Last
                                                                                            Invoice Number</label>
                                                                                                </div>
    
                                                                                                <div class="col-lg-2">
                                                                                                    <input type="email" class="form-control" id="exampleInputEmail1" formControlName="invoiceNumber" aria-describedby="emailHelp" placeholder="Enter Last Invoice Number">
                                                                                                </div>
                                                                                                <div class="col-lg-3 form-group classInputA">
                                                                                                    <label for="exampleInputEmail1">Last
                                                                                            Invoice Restart Date</label>
                                                                                                </div>
                                                                                                <div class="col-lg-2">
                                                                                                    <input type="date" class="form-control" id="exampleInputEmail1" formControlName="invoiceRestartDate" aria-describedby="emailHelp" placeholder="Enter Last Invoice Restart Date">
                                                                                                </div>
    
    
    
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-lg-12">
                                                                                            <div class="row">
    
                                                                                                <div class="col-lg-3 form-group classInput">
                                                                                                    <label for="exampleInputEmail1">Use
                                                                                            Invoice Id For Returns</label>
                                                                                                </div>
    
                                                                                                <div class="col-lg-2">
                                                                                                    <p-dropdown placeholder="Enter Use Invoice Id For Returns" filter="true" formControlName="useInvoice" [options]="refundable" optionlabel="label">
    
                                                                                                    </p-dropdown>
                                                                                                </div>
                                                                                                <div class="col-lg-3 form-group classInputA">
                                                                                                    <label for="exampleInputEmail1">Quote
                                                                                            Seq Cust Meth ID</label>
                                                                                                </div>
                                                                                                <div class="col-lg-2">
                                                                                                    <input type="email" class="form-control" id="exampleInputEmail1" formControlName="quoteSeqCust" aria-describedby="emailHelp" placeholder="Enter Quote Seq Cust Meth ID">
                                                                                                </div>
    
    
    
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-lg-12">
                                                                                            <div class="row">
    
                                                                                                <div class="col-lg-3 form-group classInput">
                                                                                                    <label for="exampleInputEmail1">Quote ID
                                                                                            Prefix</label>
                                                                                                </div>
    
                                                                                                <div class="col-lg-2">
                                                                                                    <input type="email" class="form-control" id="exampleInputEmail1" formControlName="quotePrefix" aria-describedby="emailHelp" placeholder="Enter Quote ID Prefix">
                                                                                                </div>
                                                                                                <div class="col-lg-3 form-group classInputA">
                                                                                                    <label for="exampleInputEmail1">Last
                                                                                            Quote Number</label>
                                                                                                </div>
                                                                                                <div class="col-lg-2">
                                                                                                    <input type="email" class="form-control" id="exampleInputEmail1" formControlName="lastQuote" aria-describedby="emailHelp" placeholder="Enter Last Quote Number">
                                                                                                </div>
    
    
    
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-lg-12">
                                                                                            <div class="row">
    
                                                                                                <div class="col-lg-3 form-group classInput">
                                                                                                    <label for="exampleInputEmail1">Order
                                                                                            Seq Cust Meth ID</label>
                                                                                                </div>
    
                                                                                                <div class="col-lg-2">
                                                                                                    <input type="email" class="form-control" id="exampleInputEmail1" formControlName="orderSeqCust" aria-describedby="emailHelp" placeholder="Enter Order Seq Cust Meth ID">
                                                                                                </div>
                                                                                                <div class="col-lg-3 form-group classInputA">
                                                                                                    <label for="exampleInputEmail1">
                                                                                            Order ID Prefix</label>
                                                                                                </div>
                                                                                                <div class="col-lg-2">
                                                                                                    <input type="email" class="form-control" id="exampleInputEmail1" formControlName="orderIdPrefix" aria-describedby="emailHelp" placeholder="Enter Order ID Prefix">
                                                                                                </div>
    
    
    
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-lg-12">
                                                                                            <div class="row">
    
                                                                                                <div class="col-lg-3 form-group classInput">
                                                                                                    <label for="exampleInputEmail1">Last
                                                                                            Order Number</label>
                                                                                                </div>
    
                                                                                                <div class="col-lg-2">
                                                                                                    <input type="email" class="form-control" id="exampleInputEmail1" formControlName="lastOrderNumber" aria-describedby="emailHelp" placeholder="Enter Last Order Number">
                                                                                                </div>
                                                                                                <div class="col-lg-3 form-group classInputA">
                                                                                                    <label for="exampleInputEmail1">Refund
                                                                                            Payment Method ID</label>
                                                                                                </div>
                                                                                                <div class="col-lg-2">
                                                                                                    <p-dropdown placeholder="Enter Refund Payment Method ID" filter="true" formControlName="refundPayment" [options]="refundPaymentMethodArray" optionlabel="label">
    
                                                                                                    </p-dropdown>
                                                                                                </div>
    
    
    
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-lg-12">
                                                                                            <div class="row">
    
                                                                                                <div class="col-lg-3 form-group classInput">
                                                                                                    <label for="exampleInputEmail1">Error Gl
                                                                                            Journal ID</label>
                                                                                                </div>
    
                                                                                                <div class="col-lg-2">
                                                                                                    <p-dropdown placeholder="Enter Error Gl Journal ID" filter="true" formControlName="errorGl" [options]="errorGlJournalArray" optionlabel="label">
    
                                                                                                    </p-dropdown>
                                                                                                </div>
                                                                                                <div class="col-lg-3 form-group classInputA">
                                                                                                    <label for="exampleInputEmail1">Enable
                                                                                            Accounting
                                                                                        </label> </div>
                                                                                                <div class="col-lg-2">
                                                                                                    <p-dropdown placeholder="Enter Enable Accounting" filter="true" [options]="refundable" optionlabel="label" formControlName="enableAccounting" [options]="refundable" optionlabel="label">
    
                                                                                                    </p-dropdown>
                                                                                                </div>
    
    
    
    
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-lg-12">
                                                                                            <div class="row">
    
                                                                                                <div class="col-lg-3 form-group classInput">
                                                                                                    <label for="exampleInputEmail1">Old
                                                                                            Invoice Sequence Enum ID </label>
                                                                                                </div>
    
                                                                                                <div class="col-lg-2">
                                                                                                    <p-dropdown placeholder="Enter Old Invoice Sequence Enum ID" filter="true" formControlName="oldInvoiceSequence" [options]="oldInvoiceSequenceEnumIdArray" optionlabel="label">
    
                                                                                                    </p-dropdown>
                                                                                                </div>
                                                                                                <div class="col-lg-3 form-group classInputA">
                                                                                                    <label for="exampleInputEmail1">Old
                                                                                            Order Sequence Enum ID </label> </div>
                                                                                                <div class="col-lg-2">
                                                                                                    <p-dropdown placeholder="Enter Old Order Sequence Enum ID" filter="true" formControlName="oldOrderSeqEnumId" [options]="oldOrderSequenceEnumIdArray" optionlabel="label">
    
                                                                                                    </p-dropdown>
                                                                                                </div>
    
    
    
    
                                                                                            </div>
                                                                                        </div>
                                                                                        <div class="col-lg-12">
                                                                                            <div class="row">
    
                                                                                                <div class="col-lg-3 form-group classInput">
                                                                                                    <label for="exampleInputEmail1">Old
                                                                                            Quote Sequence Enum ID</label>
                                                                                                </div>
    
                                                                                                <div class="col-lg-2">
                                                                                                    <p-dropdown placeholder="Enter Old Quote Sequence Enum ID" filter="true" formControlName="oldQuoteSeq" [options]="oldQuoteSequenceEnumIdArray" optionlabel="label">
    
                                                                                                    </p-dropdown>
                                                                                                </div>
    
    
    
    
                                                                                            </div>
                                                                                        </div>
    
    
                                                                                    </div>
                                                                                    <div class="main-submit-button" style="
                                                                        margin-right: 22%;">
                                                                                        <button type="submit" *ngIf="this.formValue" (click)="onSubmit()" class="btn btn-secondary submit-btn">Create</button>
                                                                                        <button type="submit" *ngIf="!this.formValue" (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button>
    
                                                                                    </div>
                                                                                    <br><br><br>
    
                                                                                </form>
    
                                                                            </div>
                                                                        </div>
    
                                                                    </div>
                                                                </div>
    
                                                            </div>
    
                                                            <div *ngIf="activeCategory==3">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling">
                                                                        Chart of Accounts
                                                                    </h4>
    
                                                                    <span class="edit-buttons ">
                                                            <span class="edit-buttons  hCreate"><a  data-toggle="modal" 
                                                                data-target="#exampleModalCenter">Create</a>
                                                              </span>|
                                                                    <a (click)="getChartPdf()">PDF</a></span>
    
                                                                </div>
                                                                <br>
                                                                <div>
                                                                    <!--  <button type="button" style="width: 132px;"
                                                            class="btn btn-outline-secondary bg-brown ">Export as CSV</button>
                                                        &nbsp; <button type="button" style="width: 132px;"
                                                            class="btn btn-danger" routerLinkActive="active">Export as PDF</button> -->
                                                                </div>
                                                                <div class="panel-body">
                                                                    <div class="all-users-infomation font-13 font-medium own-user-select" style="display: block!important;">
                                                                        <div class="form-group">
                                                                            <div class="card own-account-table">
                                                                                <p-table [value]="glChartOfAccount" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                    <ng-template pTemplate="header">
                                                                                        <tr style="background: #0d3769;">
                                                                                            <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                <div class="checkbox-align" style="color: white;">
                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <!---->
                                                                                                    </p-checkbox>
    
                                                                                                    Code
                                                                                                    <p-sortIcon field="code">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                <div style="color: white;"> Account Name
                                                                                                    <p-sortIcon field="name"></p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>
    
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                <div style="color: white;"> Parent Gl Account ID
                                                                                                    <p-sortIcon field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                <div style="color: white;"> Gl Account Type ID
                                                                                                    <p-sortIcon field="category">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                <div style="color: white;"> Gl Account Class ID
                                                                                                    <p-sortIcon field="category">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                <div style="color: white;"> Gl Resource Type ID
                                                                                                    <p-sortIcon field="category">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                <div style="color: white;"> Gl Xbrl Class ID
                                                                                                    <p-sortIcon field="category">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                <div style="color: white;"> Parent Gl Xbrl Class ID
                                                                                                    <p-sortIcon field="category">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                <div style="color: white;"> Description
                                                                                                    <p-sortIcon field="category">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                <div style="color: white;"> Product ID
                                                                                                    <p-sortIcon field="category">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                <div style="color: white;"> External ID
                                                                                                    <p-sortIcon field="category">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>
    
    
    
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body" let-product>
                                                                                        <tr>
                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                <div class="checkbox-align">
                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </p-checkbox>
                                                                                                    <span (click)="accountDetails()"  class="account-button" >{{product.glAccountId}} </span>
    
                                                                                                </div>
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'170px'}">{{product.accountName}} </td>
    
                                                                                            <td [ngStyle]="{'width':'170px'}">{{product.parentGlAccount}}</td>
                                                                                            <td [ngStyle]="{'width':'170px'}">{{product.glAccountType}}</td>
                                                                                            <td [ngStyle]="{'width':'170px'}">{{product.glAccountClass}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'170px'}">{{product.glResourceType}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'170px'}">{{product.glXbrlClass}}</td>
                                                                                            <td [ngStyle]="{'width':'170px'}">{{product.parentGLXbrlClassId}}</td>
                                                                                            <td [ngStyle]="{'width':'170px'}">{{product.description}}</td>
                                                                                            <td [ngStyle]="{'width':'170px'}">{{product.productId}}</td>
                                                                                            <td [ngStyle]="{'width':'170px'}">{{product.externalId}}</td>
    
    
    
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <span class="text-center">
                                                                                        <h4 class="ml-2">No Record Found
                                                                                        </h4>
                                                                                    </span>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>
                                                                                <p class="paginate_data">
                                                                                    View per page
    
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
    
                                                            </div>
                                                            <div *ngIf="activeCategory==4">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling">
                                                                        Doc. Type Template
                                                                    </h4>
                                                                    <span class="edit-buttons  hCreate"><a  data-toggle="modal" 
                                                            data-target="#exampleModalCenter1">Create</a>
                                                          </span>
                                                                    <!-- <span class="edit-buttons ">
                                                            <a (click)="createDocType()">Create</a></span> -->
    
                                                                </div>
                                                                <br>
    
                                                                <div class="panel-body">
                                                                    <div class="all-users-infomation font-13 font-medium own-user-select" style="display: block!important;">
                                                                        <div class="form-group">
                                                                            <div class="card own-account-table">
                                                                                <p-table [value]="docTypeTemplate" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                    <ng-template pTemplate="header">
                                                                                        <tr style="background: #0d3769;">
                                                                                            <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                <div class="checkbox-align" style="color: white;">
                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <!---->
                                                                                                    </p-checkbox>
    
    
                                                                                                    Invoice Type
                                                                                                    <p-sortIcon field="code"></p-sortIcon>
                                                                                                </div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                <div style="color: white;"> Quote Type ID
                                                                                                    <p-sortIcon field="name"></p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>
    
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                <div style="color: white;"> Order Type ID
                                                                                                    <p-sortIcon field="name"></p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                <div style="color: white;"> Custom screen
                                                                                                    <p-sortIcon field="category">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                <div style="color: white;"> From Date
                                                                                                    <p-sortIcon field="category">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                <div style="color: white;"> Through Date
                                                                                                    <p-sortIcon field="category">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                <div style="color: white;"> Expire
                                                                                                    <p-sortIcon field="category">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>
    
    
    
    
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body" let-product>
                                                                                        <tr>
                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                <div class="checkbox-align">
                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </p-checkbox>
                                                                                                    {{product.invoiceTypeId}}
                                                                                                </div>
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'170px'}"> {{product.quoteTypeId}} </td>
    
                                                                                            <td [ngStyle]="{'width':'170px'}"> {{product.orderTypeId}}</td>
                                                                                            <td [ngStyle]="{'width':'170px'}"> {{product.customScreenId}}</td>
                                                                                            <td [ngStyle]="{'width':'170px'}"> {{product.fromDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'170px'}"><span *ngIf="product.thruDate==null"> <input type="date" [(ngModel)]="product.thruDate"
                                                                                    [ngModelOptions]="{standalone: true}"></span><span *ngIf="product.thruDate!=null">  {{product.thruDate|date:'yyyy-MM-dd hh:mm:ss'}}</span>
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'170px'}"> <span style="color: #207EBB;cursor: pointer;" *ngIf="product.isExpired=='N'" (click)="expireDocType(product.partyPrefDocTypeTplId,product.thruDate,product.fromDate,product.invoiceTypeId,product.quoteTypeId,product.orderTypeId,product.customScreenId)"
                                                                                                    [ngStyle]="{'width':'170px'}">Expire</span></td>
    
    
    
    
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <span class="text-center">
                                                                                        <h4 class="ml-2">No Record Found
                                                                                        </h4>
                                                                                    </span>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>
                                                                                <p class="paginate_data">
                                                                                    View per page
    
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
    
                                                            </div>
                                                            <div *ngIf="activeCategory==5">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling">
                                                                        Journals
    
                                                                    </h4>
                                                                    <span class="edit-buttons  hCreate"><a  data-toggle="modal" 
                                                            data-target="#exampleModalCenter2">Create</a>
                                                          </span>
                                                                    <!-- <span class="edit-buttons ">
                                                            <a (click)="createJournal()">Create</a></span> -->
    
                                                                </div>
                                                                <br>
    
                                                                <div class="panel-body">
                                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                        <div class="form-group">
                                                                            <div class="card own-account-table">
                                                                                <p-table [value]="getJournalDeatils" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                    <ng-template pTemplate="header">
                                                                                        <tr style="background: #0d3769;">
                                                                                            <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                <div class="checkbox-align" style="color: white;">
                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <!---->
                                                                                                    </p-checkbox>
    
    
    
                                                                                                    Gl Journal ID
                                                                                                    <p-sortIcon field="code"></p-sortIcon>
                                                                                                </div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                <div style="color: white;"> Gl Journal Name
                                                                                                    <p-sortIcon field="name"></p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>
    
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                <div style="color: white;"> Is Posted
                                                                                                    <p-sortIcon field="name"></p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                <div style="color: white;"> Posted Date
                                                                                                    <p-sortIcon field="category">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                <div style="color: white;"> Action
                                                                                                    <p-sortIcon field="category">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>
    
    
    
    
    
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body" let-product>
                                                                                        <tr>
                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                <div class="checkbox-align">
                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </p-checkbox>
                                                                                                    {{product.glJournalId}}
                                                                                                </div>
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                {{product.glJournalName}} </td>
    
                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                {{product.isPosted}}</td>
                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                {{product.postedDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                <svg class="hover" (click)="onUpdateJournal(product.glJournalName,product.glJournalId,product.organizationPartyId)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21.223 21.222">
                                                                                    <g transform="translate(0 -0.004)">
                                                                                      <g transform="translate(0 1.52)">
                                                                                        <path
                                                                                          d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                          transform="translate(0 -34.137)" />
                                                                                      </g>
                                                                                      <g transform="translate(4.548 0.004)">
                                                                                        <g transform="translate(0 0)">
                                                                                          <path
                                                                                            d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                            transform="translate(-102.409 -0.004)" />
                                                                                        </g>
                                                                                      </g>
                                                                                    </g>
                                                                                  </svg>
                                                                                                <svg xmlns="http://www.w3.org/2000/svg" (click)="deleteJournal(product.glJournalId)" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                                                                        <defs>
                                                                                            <style>
                                                                                                .a {
                                                                                                    fill: #f44336;
                                                                                                }
    
                                                                                                .b {
                                                                                                    fill: #fafafa;
                                                                                                    margin-left: 20px;
                                                                                                }
                                                                                            </style>
                                                                                        </defs>
                                                                                        <path class="a fill-color"
                                                                                            d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                        <path class="b"
                                                                                            d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                            transform="translate(-147.576 -147.576)" />
                                                                                    </svg>
                                                                                            </td>
    
    
    
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <span class="text-center">
                                                                                        <h4 class="ml-2">No Record Found
                                                                                        </h4>
                                                                                    </span>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>
                                                                                <p class="paginate_data">
                                                                                    View per page
    
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
    
                                                            </div>
                                                            <div *ngIf="activeCategory==8">
                                                                <br>
                                                                <div class="w3-card-4 classCard">
                                                                    <div class="container-fluid">
                                                                        <ul class="tabbing-section tabbing-accordians tabClass" style="
                                                        flex-wrap: wrap !important;
                                                        display: flex !important;
                                                        padding: 9px !important;">
    
                                                                            <li [ngClass]="activeCategoryValue==2?'active':''" (click)="changeactiveCategoryFunctionSubMenu(2)"><a>
                                                                    GL Account Type Defaults</a></li>
                                                                            <li [ngClass]="activeCategoryValue==3?'active':''" (click)="changeactiveCategoryFunctionSubMenu(3)"><a>
                                                                    Product GL Accounts
                                                                </a></li>
    
                                                                            <li [ngClass]="activeCategoryValue==4?'active':''" (click)="changeactiveCategoryFunctionSubMenu(4)"><a>
                                                                    Product Category GL Accounts</a></li>
                                                                            <li [ngClass]="activeCategoryValue==5?'active':''" (click)="changeactiveCategoryFunctionSubMenu(5)"><a>
                                                                    FinAccount Type Gl Account</a></li>
                                                                            <li [ngClass]="activeCategoryValue==6?'active':''" (click)="changeactiveCategoryFunctionSubMenu(6)"><a>
                                                                    Sales Invoice</a></li>
                                                                            <li [ngClass]="activeCategoryValue==7?'active':''" (click)="changeactiveCategoryFunctionSubMenu(7)"><a>
                                                                    Purchases Invoice</a></li>
                                                                            <li [ngClass]="activeCategoryValue==8?'active':''" (click)="changeactiveCategoryFunctionSubMenu(8)"><a>
                                                                    Payment Type/Gl Account Type Id</a></li>
                                                                            <li [ngClass]="activeCategoryValue==9?'active':''" (click)="changeactiveCategoryFunctionSubMenu(9)"><a>
                                                                    Payment Method Id/Gl Account Id</a></li>
                                                                            <li [ngClass]="activeCategoryValue==10?'active':''" (click)="changeactiveCategoryFunctionSubMenu(10)"><a>
                                                                    Variance Reason Gl Accounts</a></li>
                                                                            <li [ngClass]="activeCategoryValue==11?'active':''" (click)="changeactiveCategoryFunctionSubMenu(11)"><a>
                                                                    Credit Card Type GlAccount </a></li>
                                                                            <li [ngClass]="activeCategoryValue==12?'active':''" (click)="changeactiveCategoryFunctionSubMenu(12)"><a>
                                                                    TaxAuthority GL Account
                                                                </a></li>
                                                                            <li [ngClass]="activeCategoryValue==13?'active':''" (click)="changeactiveCategoryFunctionSubMenu(13)"><a>
                                                                    Party Gl Account
    
                                                                </a></li>
                                                                            <li [ngClass]="activeCategoryValue==14?'active':''" (click)="changeactiveCategoryFunctionSubMenu(14)"><a>
                                                                    Fixed Assets Type Gl Mappings
    
                                                                </a></li>
    
    
    
    
                                                                        </ul>
                                                                    </div>
                                                                    <br>
                                                                    <div *ngIf="activeCategoryValue==2">
                                                                        <div class="header-tabs">
                                                                            <h4 class=" common-styling hMargin">
                                                                                GL Account Type Defaults
    
                                                                            </h4>
                                                                            <span class="edit-buttons  hCreate"><a  data-toggle="modal" 
                                                                data-target="#exampleModalCenter3">Create</a>
                                                              </span>
                                                                            <!-- <span class="edit-buttons  hMaRight">
                                                                <a (click)="glAccountDefault()">Create</a></span> -->
    
                                                                        </div>
                                                                        <br>
    
                                                                        <div class="panel-body">
                                                                            <div class="all-users-infomation font-13 font-medium own-user-select allUserDetails">
                                                                                <div class="form-group">
                                                                                    <div class="card own-account-table">
                                                                                        <p-table [value]="getGlAccount" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                            <ng-template pTemplate="header">
                                                                                                <tr style="background: #0d3769;">
                                                                                                    <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <!---->
                                                                                                            </p-checkbox>
    
    
    
                                                                                                            Gl Account Type ID
                                                                                                            <p-sortIcon field="code"></p-sortIcon>
                                                                                                        </div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                        <div style="color: white;"> Gl Account ID
                                                                                                            <p-sortIcon field="name">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>
    
    
                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                        <div style="color: white;"> Action
                                                                                                            <p-sortIcon field="category">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>
    
    
    
    
    
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template pTemplate="body" let-product>
                                                                                                <tr>
                                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                                        <div class="checkbox-align">
                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </p-checkbox>
                                                                                                            {{product.glAccountTypeId}}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                                        {{product.glAccountId}}</td>
    
                                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" (click)="deleteGlAccountTypeDefault(product.organizationPartyId,product.glAccountId,product.glAccountTypeId)" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                                                                            <defs>
                                                                                                <style>
                                                                                                    .a {
                                                                                                        fill: #f44336;
                                                                                                    }
    
                                                                                                    .b {
                                                                                                        fill: #fafafa;
                                                                                                        margin-left: 20px;
                                                                                                    }
                                                                                                </style>
                                                                                            </defs>
                                                                                            <path class="a fill-color"
                                                                                                d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                            <path class="b"
                                                                                                d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                                transform="translate(-147.576 -147.576)" />
                                                                                        </svg>
                                                                                                    </td>
    
    
    
    
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No Record Found
                                                                                            </h4>
                                                                                        </span>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                        </p-table>
                                                                                        <p class="paginate_data">
                                                                                            View per page
    
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div *ngIf="activeCategoryValue==3">
                                                                        <div class="header-tabs">
                                                                            <h4 class=" common-styling hMargin">
                                                                                Product GL Accounts
    
                                                                            </h4>
                                                                            <span class="edit-buttons  hCreate"><a  data-toggle="modal" 
                                                                data-target="#exampleModalCenter4">Create</a>
                                                              </span>
                                                                            <!-- <span class="edit-buttons  hMaRight">
                                                                <a (click)="glProductAccount()">Create</a></span> -->
    
                                                                        </div>
                                                                        <br>
    
                                                                        <div class="panel-body">
                                                                            <div class="all-users-infomation font-13 font-medium own-user-select allUserDetails">
                                                                                <div class="form-group">
                                                                                    <div class="card own-account-table">
                                                                                        <p-table [value]="productGlAccount" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                            <ng-template pTemplate="header">
                                                                                                <tr style="background: #0d3769;">
                                                                                                    <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <!---->
                                                                                                            </p-checkbox>
    
    
    
    
                                                                                                            Product ID
                                                                                                            <p-sortIcon field="code"></p-sortIcon>
                                                                                                        </div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                        <div style="color: white;"> Gl Account Type ID
                                                                                                            <p-sortIcon field="name">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                        <div style="color: white;"> GL Account
                                                                                                            <p-sortIcon field="name">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>
    
    
                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                        <div style="color: white;"> Action
                                                                                                            <p-sortIcon field="category">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>
    
    
    
    
    
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template pTemplate="body" let-product>
                                                                                                <tr>
                                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                                        <div class="checkbox-align">
                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </p-checkbox>
                                                                                                            {{product.productId}}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td [ngStyle]="{'width':'170px'}"> {{product.glAccountTypeId}}</td>
                                                                                                    <td [ngStyle]="{'width':'170px'}"> {{product.glAccountId}}</td>
    
                                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                                        <svg class="hover" (click)="updateProduct(product.productId)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21.223 21.222">
                                                                                        <g transform="translate(0 -0.004)">
                                                                                          <g transform="translate(0 1.52)">
                                                                                            <path
                                                                                              d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                              transform="translate(0 -34.137)" />
                                                                                          </g>
                                                                                          <g transform="translate(4.548 0.004)">
                                                                                            <g transform="translate(0 0)">
                                                                                              <path
                                                                                                d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                transform="translate(-102.409 -0.004)" />
                                                                                            </g>
                                                                                          </g>
                                                                                        </g>
                                                                                      </svg>
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" (click)="deleteProductAccount(product.organizationPartyId,product.glAccountId,product.productId,product.glAccountTypeId)" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                                                                            <defs>
                                                                                                <style>
                                                                                                    .a {
                                                                                                        fill: #f44336;
                                                                                                    }
        
                                                                                                    .b {
                                                                                                        fill: #fafafa;
                                                                                                        margin-left: 20px;
                                                                                                    }
                                                                                                </style>
                                                                                            </defs>
                                                                                            <path class="a fill-color"
                                                                                                d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                            <path class="b"
                                                                                                d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                                transform="translate(-147.576 -147.576)" />
                                                                                        </svg>
                                                                                                    </td>
    
    
    
    
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No Record Found
                                                                                            </h4>
                                                                                        </span>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                        </p-table>
                                                                                        <p class="paginate_data">
                                                                                            View per page
    
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div *ngIf="activeCategoryValue==4">
                                                                        <div class="header-tabs">
                                                                            <h4 class=" common-styling hMargin">
                                                                                Product Category GL Accounts
    
                                                                            </h4>
                                                                            <span class="edit-buttons  hCreate hMaRight"><a  data-toggle="modal" 
                                                                                data-target="#exampleModalCenter5">Create</a>
                                                                            </span>
                                                                            <!-- <span class="edit-buttons  hMaRight">
                                                                <a (click)="glProductCategoryAccount()">Create</a></span> -->
    
                                                                        </div>
                                                                        <br>
    
                                                                        <div class="panel-body">
                                                                            <div class="all-users-infomation font-13 font-medium own-user-select allUserDetails">
                                                                                <div class="form-group">
                                                                                    <div class="card own-account-table">
                                                                                        <p-table [value]="productGlAccountCategory" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                            <ng-template pTemplate="header">
                                                                                                <tr style="background: #0d3769;">
                                                                                                    <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <!---->
                                                                                                            </p-checkbox>
    
    
    
    
    
                                                                                                            Product Category ID
                                                                                                            <p-sortIcon field="code"></p-sortIcon>
                                                                                                        </div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                        <div style="color: white;"> Gl Account Type ID
                                                                                                            <p-sortIcon field="name">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                        <div style="color: white;"> GL Account
                                                                                                            <p-sortIcon field="name">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>
    
    
                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                        <div style="color: white;"> Action
                                                                                                            <p-sortIcon field="category">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>
    
    
    
    
    
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template pTemplate="body" let-product>
                                                                                                <tr>
                                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                                        <div class="checkbox-align">
                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </p-checkbox>
                                                                                                            {{product.productCategoryId}}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td [ngStyle]="{'width':'170px'}"> {{product.glAccountTypeId}}</td>
                                                                                                    <td [ngStyle]="{'width':'170px'}"> {{product.glAccountId}}</td>
    
                                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                                        <svg class="hover" (click)="updateProductAccountCategory(product.glAccountId,product.productCategoryId,product.glAccountTypeId)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21.223 21.222">
                                                                                         <g transform="translate(0 -0.004)">
                                                                                           <g transform="translate(0 1.52)">
                                                                                             <path
                                                                                               d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                               transform="translate(0 -34.137)" />
                                                                                           </g>
                                                                                           <g transform="translate(4.548 0.004)">
                                                                                             <g transform="translate(0 0)">
                                                                                               <path
                                                                                                 d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                 transform="translate(-102.409 -0.004)" />
                                                                                             </g>
                                                                                           </g>
                                                                                         </g>
                                                                                       </svg>
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" (click)="deleteProductAccountCategoryData(product.organizationPartyId,product.glAccountId,product.productCategoryId,product.glAccountTypeId)" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                                                                             <defs>
                                                                                                 <style>
                                                                                                     .a {
                                                                                                         fill: #f44336;
                                                                                                     }
         
                                                                                                     .b {
                                                                                                         fill: #fafafa;
                                                                                                         margin-left: 20px;
                                                                                                     }
                                                                                                 </style>
                                                                                             </defs>
                                                                                             <path class="a fill-color"
                                                                                                 d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                             <path class="b"
                                                                                                 d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                                 transform="translate(-147.576 -147.576)" />
                                                                                         </svg>
                                                                                                    </td>
    
    
    
    
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No Record Found
                                                                                            </h4>
                                                                                        </span>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                        </p-table>
                                                                                        <p class="paginate_data">
                                                                                            View per page
    
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div *ngIf="activeCategoryValue==5">
                                                                        <div class="header-tabs">
                                                                            <h4 class=" common-styling hMargin">
                                                                                FinAccount Type Gl Account
    
                                                                            </h4>
                                                                            <span class="edit-buttons  hCreate hMaRight"><a  data-toggle="modal" 
                                                                                data-target="#exampleModalCenter6">Create</a>
                                                                            </span>
                                                                            <!-- <span class="edit-buttons  hMaRight">
                                                                <a (click)="glFinAccountType()">Create</a></span> -->
    
                                                                        </div>
                                                                        <br>
    
                                                                        <div class="panel-body">
                                                                            <div class="all-users-infomation font-13 font-medium own-user-select allUserDetails">
                                                                                <div class="form-group">
                                                                                    <div class="card own-account-table">
                                                                                        <p-table [value]="financialAccountType" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                            <ng-template pTemplate="header">
                                                                                                <tr style="background: #0d3769;">
                                                                                                    <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <!---->
                                                                                                            </p-checkbox>
    
    
    
    
    
    
                                                                                                            Fin Account Type ID
                                                                                                            <p-sortIcon field="code"></p-sortIcon>
                                                                                                        </div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                        <div style="color: white;"> GL Account ID
                                                                                                            <p-sortIcon field="name">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>
    
    
    
                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                        <div style="color: white;"> Action
                                                                                                            <p-sortIcon field="category">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>
    
    
    
    
    
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template pTemplate="body" let-product>
                                                                                                <tr>
                                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                                        <div class="checkbox-align">
                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </p-checkbox>
                                                                                                            {{product.finAccountTypeId}}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td [ngStyle]="{'width':'170px'}"> {{product.glAccountId}} </td>
                                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                                        <svg class="hover" (click)="updateFinancialCategoryData(product.glAccountId,product.finAccountTypeId)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21.223 21.222">
                                                                                         <g transform="translate(0 -0.004)">
                                                                                           <g transform="translate(0 1.52)">
                                                                                             <path
                                                                                               d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                               transform="translate(0 -34.137)" />
                                                                                           </g>
                                                                                           <g transform="translate(4.548 0.004)">
                                                                                             <g transform="translate(0 0)">
                                                                                               <path
                                                                                                 d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                 transform="translate(-102.409 -0.004)" />
                                                                                             </g>
                                                                                           </g>
                                                                                         </g>
                                                                                       </svg>
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" (click)="deleteFinancialCategoryData(product.organizationPartyId,product.glAccountId,product.finAccountTypeId)" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                                                                             <defs>
                                                                                                 <style>
                                                                                                     .a {
                                                                                                         fill: #f44336;
                                                                                                     }
         
                                                                                                     .b {
                                                                                                         fill: #fafafa;
                                                                                                         margin-left: 20px;
                                                                                                     }
                                                                                                 </style>
                                                                                             </defs>
                                                                                             <path class="a fill-color"
                                                                                                 d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                             <path class="b"
                                                                                                 d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                                 transform="translate(-147.576 -147.576)" />
                                                                                         </svg>
                                                                                                    </td>
    
    
    
    
    
    
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No Record Found
                                                                                            </h4>
                                                                                        </span>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                        </p-table>
                                                                                        <p class="paginate_data">
                                                                                            View per page
    
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div *ngIf="activeCategoryValue==6">
                                                                        <div class="header-tabs">
                                                                            <h4 class=" common-styling hMargin">
                                                                                Sales Invoice
    
                                                                            </h4>
                                                                            <span class="edit-buttons  hCreate hMaRight"><a  data-toggle="modal" 
                                                                data-target="#exampleModalCenter7">Create</a>
                                                              </span>
                                                                            <!-- <span class="edit-buttons  hMaRight">
                                                                <a (click)="createSalesInvoice()">Create</a></span> -->
    
                                                                        </div>
                                                                        <br>
    
                                                                        <div class="panel-body">
                                                                            <div class="all-users-infomation font-13 font-medium own-user-select allUserDetails">
                                                                                <div class="form-group">
                                                                                    <div class="card own-account-table">
                                                                                        <p-table [value]="saleInvoice" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                            <ng-template pTemplate="header">
                                                                                                <tr style="background: #0d3769;">
                                                                                                    <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <!---->
                                                                                                            </p-checkbox>
    
    
                                                                                                            Description
                                                                                                            <p-sortIcon field="code"></p-sortIcon>
                                                                                                        </div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                        <div style="color: white;"> Default Gl Account ID
                                                                                                            <p-sortIcon field="name">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>
    
    
    
                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                        <div style="color: white;"> Override Gl Account ID
                                                                                                            <p-sortIcon field="category">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>
    
                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                        <div style="color: white;"> Active Gl Description
                                                                                                            <p-sortIcon field="category">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>
    
                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                        <div style="color: white;"> Action
                                                                                                            <p-sortIcon field="category">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>
    
    
    
    
    
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template pTemplate="body" let-product>
                                                                                                <tr>
                                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                                        <div class="checkbox-align">
                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </p-checkbox>
                                                                                                            {{product.description}}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td [ngStyle]="{'width':'170px'}"> 
                                                                                                        <span style="color: #207EBB;cursor: pointer;" (click)="redirectToGL(product.defaultGlAccountId)">
                                                                                                            {{product.defaultGlAccountId}} 
                                                                                                        </span>
                                                                                                    </td>
                                                                                                    <td [ngStyle]="{'width':'170px'}"> {{product.overrideGlAccountId}} </td>
                                                                                                    <td [ngStyle]="{'width':'170px'}"> {{product.activeGlDescription}} </td>
                                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" *ngIf="product.overrideGlAccountId!=null" (click)="removeInvoiceItemTypeGlAssignment(product.invoiceItemTypeId)" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                                                                        <defs>
                                                                                            <style>
                                                                                                .a {
                                                                                                    fill: #f44336;
                                                                                                }
    
                                                                                                .b {
                                                                                                    fill: #fafafa;
                                                                                                    margin-left: 20px;
                                                                                                }
                                                                                            </style>
                                                                                        </defs>
                                                                                        <path class="a fill-color"
                                                                                            d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                        <path class="b"
                                                                                            d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                            transform="translate(-147.576 -147.576)" />
                                                                                    </svg>
                                                                                                    </td>
    
    
    
    
    
    
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No Record Found
                                                                                            </h4>
                                                                                        </span>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                        </p-table>
                                                                                        <p class="paginate_data">
                                                                                            View per page
    
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div *ngIf="activeCategoryValue==7">
                                                                        <div class="header-tabs">
                                                                            <h4 class=" common-styling hMargin">
                                                                                Purchases Invoice
    
                                                                            </h4>
                                                                            <span class="edit-buttons  hCreate hMaRight"><a  data-toggle="modal" 
                                                                data-target="#exampleModalCenter8">Create</a>
                                                              </span>
                                                                            <!-- <span class="edit-buttons  hMaRight">
                                                                <a (click)="createPurchaseInvoice()">Create</a></span> -->
    
                                                                        </div>
                                                                        <br>
    
                                                                        <div class="panel-body">
                                                                            <div class="all-users-infomation font-13 font-medium own-user-select allUserDetails">
                                                                                <div class="form-group">
                                                                                    <div class="card own-account-table">
                                                                                        <p-table [value]="invoiceItemTypeIdPurchase" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                            <ng-template pTemplate="header">
                                                                                                <tr style="background: #0d3769;">
                                                                                                    <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <!---->
                                                                                                            </p-checkbox>
    
    
                                                                                                            Description
                                                                                                            <p-sortIcon field="code"></p-sortIcon>
                                                                                                        </div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                        <div style="color: white;"> Default Gl Account ID
                                                                                                            <p-sortIcon field="name">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>
    
    
    
                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                        <div style="color: white;"> Invoice Override Expense Gl Account ID
                                                                                                            <p-sortIcon field="category">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>
    
                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                        <div style="color: white;"> Active Gl Description
                                                                                                            <p-sortIcon field="category">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>
    
                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                        <div style="color: white;"> Action
                                                                                                            <p-sortIcon field="category">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>
    
    
    
    
    
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template pTemplate="body" let-product>
                                                                                                <tr>
                                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                                        <div class="checkbox-align">
                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </p-checkbox>
                                                                                                            {{product.description}}
    
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td [ngStyle]="{'width':'170px'}" > 
                                                                                                        <span style="color: #207EBB;cursor: pointer;" (click)="redirectToGL(product.defaultGlAccountId)">
                                                                                                            {{product.defaultGlAccountId}}
                                                                                                        </span>
                                                                                                        </td>
                                                                                                    <td [ngStyle]="{'width':'170px'}"> {{product.overrideGlAccountId}}</td>
                                                                                                    <td [ngStyle]="{'width':'170px'}"> {{product.activeGlDescription}} </td>
                                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" *ngIf="product.overrideGlAccountId!=null" (click)="removeInvoiceItemTypeGlAssignment(product.invoiceItemTypeId)" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                                                                        <defs>
                                                                                            <style>
                                                                                                .a {
                                                                                                    fill: #f44336;
                                                                                                }
    
                                                                                                .b {
                                                                                                    fill: #fafafa;
                                                                                                    margin-left: 20px;
                                                                                                }
                                                                                            </style>
                                                                                        </defs>
                                                                                        <path class="a fill-color"
                                                                                            d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                        <path class="b"
                                                                                            d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                            transform="translate(-147.576 -147.576)" />
                                                                                    </svg>
                                                                                                    </td>
    
    
    
    
    
    
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No Record Found
                                                                                            </h4>
                                                                                        </span>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                        </p-table>
                                                                                        <p class="paginate_data">
                                                                                            View per page
    
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div *ngIf="activeCategoryValue==8">
                                                                        <div class="header-tabs">
                                                                            <h4 class=" common-styling hMargin">
                                                                                Payment Type Assign Account Type
    
    
                                                                            </h4>
                                                                            <span class="edit-buttons  hCreate hMaRight"><a  data-toggle="modal" 
                                                                data-target="#exampleModalCenter9">Create Payment Type</a>
                                                              </span>
                                                                            <!-- <span class="edit-buttons  hMaRight">
                                                                <a (click)="createPaymentType()">Create</a></span> -->
    
                                                                        </div>
                                                                        <br>
    
                                                                        <div class="panel-body">
                                                                            <div class="all-users-infomation font-13 font-medium own-user-select allUserDetails">
                                                                                <div class="form-group">
                                                                                    <div class="card own-account-table">
                                                                                        <p-table [value]="paymentType" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                            <ng-template pTemplate="header">
                                                                                                <tr style="background: #0d3769;">
                                                                                                    <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <!---->
                                                                                                            </p-checkbox>
    
    
    
                                                                                                            Payment Type
                                                                                                            <p-sortIcon field="code"></p-sortIcon>
                                                                                                        </div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                        <div style="color: white;"> Gl Account Type
                                                                                                            <p-sortIcon field="name">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>
    
                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                        <div style="color: white;"> Action
                                                                                                            <p-sortIcon field="category">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>
    
    
    
    
    
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template pTemplate="body" let-product>
                                                                                                <tr>
                                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                                        <div class="checkbox-align">
                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </p-checkbox>
                                                                                                            {{product.paymentTypeId}}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td [ngStyle]="{'width':'170px'}"> {{product.glAccountTypeId}}</td>
                                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" (click)="deletepaymentType(product.organizationPartyId,product.glAccountTypeId,product.paymentTypeId)" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                                                                        <defs>
                                                                                            <style>
                                                                                                .a {
                                                                                                    fill: #f44336;
                                                                                                }
    
                                                                                                .b {
                                                                                                    fill: #fafafa;
                                                                                                    margin-left: 20px;
                                                                                                }
                                                                                            </style>
                                                                                        </defs>
                                                                                        <path class="a fill-color"
                                                                                            d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                        <path class="b"
                                                                                            d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                            transform="translate(-147.576 -147.576)" />
                                                                                    </svg>
                                                                                                    </td>
    
    
    
    
    
    
    
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No Record Found
                                                                                            </h4>
                                                                                        </span>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                        </p-table>
                                                                                        <p class="paginate_data">
                                                                                            View per page
    
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div *ngIf="activeCategoryValue==9">
                                                                        <div class="header-tabs hMargin">
                                                                            <h4 class=" common-styling">
                                                                                Payment Method Assign Account Type
    
    
    
                                                                            </h4>
                                                                            <span class="edit-buttons  hCreate hMaRight"><a  data-toggle="modal" 
                                                                data-target="#exampleModalCenter10">Create Payment Method </a>
                                                              </span>
                                                                            <!-- <span class="edit-buttons  hMaRight">
                                                                <a (click)="createPaymentMethod()">Create</a></span> -->
    
                                                                        </div>
                                                                        <br>
    
                                                                        <div class="panel-body">
                                                                            <div class="all-users-infomation font-13 font-medium own-user-select allUserDetails">
                                                                                <div class="form-group">
                                                                                    <div class="card own-account-table">
                                                                                        <p-table [value]="paymentMethodType" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                            <ng-template pTemplate="header">
                                                                                                <tr style="background: #0d3769;">
                                                                                                    <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <!---->
                                                                                                            </p-checkbox>
    
    
    
                                                                                                            Payment Method Type
                                                                                                            <p-sortIcon field="code"></p-sortIcon>
                                                                                                        </div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                        <div style="color: white;"> Gl Account Id
                                                                                                            <p-sortIcon field="name">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                        <div style="color: white;"> Default Gl Account ID
                                                                                                            <p-sortIcon field="name">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>
    
                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                        <div style="color: white;"> Action
                                                                                                            <p-sortIcon field="category">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>
    
    
    
    
    
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template pTemplate="body" let-product>
                                                                                                <tr>
                                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                                        <div class="checkbox-align">
                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </p-checkbox>
                                                                                                            {{product.paymentMethodTypeId}}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td [ngStyle]="{'width':'170px'}"> 
                                                                                                        <span style="color: #207EBB;cursor: pointer;" (click)="redirectToGL(product.glAccountId)">
                                                                                                            {{product.glAccountId}}
                                                                                                        </span>
                                                                                                        </td>
                                                                                                    <td [ngStyle]="{'width':'170px'}"> </td>
                                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" (click)="removeGlAccountMethodTypePaymentType(product.organizationPartyId,product.glAccountId,product.paymentMethodTypeId)" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                                                                        <defs>
                                                                                            <style>
                                                                                                .a {
                                                                                                    fill: #f44336;
                                                                                                }
    
                                                                                                .b {
                                                                                                    fill: #fafafa;
                                                                                                    margin-left: 20px;
                                                                                                }
                                                                                            </style>
                                                                                        </defs>
                                                                                        <path class="a fill-color"
                                                                                            d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                        <path class="b"
                                                                                            d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                            transform="translate(-147.576 -147.576)" />
                                                                                    </svg>
                                                                                                    </td>
    
    
    
    
    
    
    
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No Record Found
                                                                                            </h4>
                                                                                        </span>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                        </p-table>
                                                                                        <p class="paginate_data">
                                                                                            View per page
    
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div *ngIf="activeCategoryValue==10">
                                                                        <div class="header-tabs">
                                                                            <h4 class=" common-styling hMargin">
                                                                                Variance Reason Gl Accounts
                                                                            </h4>
                                                                            <span class="edit-buttons  hCreate hMaRight"><a  data-toggle="modal" 
                                                                data-target="#exampleModalCenter11">Create Variance Reason Gl Accounts</a>
                                                              </span>
                                                                            <!-- <span class="edit-buttons  hMaRight">
                                                                <a (click)="createVarianceReason()">Create</a></span> -->
    
                                                                        </div>
                                                                        <br>
    
                                                                        <div class="panel-body">
                                                                            <div class="all-users-infomation font-13 font-medium own-user-select allUserDetails">
                                                                                <div class="form-group">
                                                                                    <div class="card own-account-table">
                                                                                        <p-table [value]="VarianceReasonGlAccounts" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                            <ng-template pTemplate="header">
                                                                                                <tr style="background: #0d3769;">
                                                                                                    <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <!---->
                                                                                                            </p-checkbox>
    
    
    
    
                                                                                                            Variance Reason ID
                                                                                                            <p-sortIcon field="code"></p-sortIcon>
                                                                                                        </div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                        <div style="color: white;"> Gl Account Id
                                                                                                            <p-sortIcon field="name">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>
    
    
                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                        <div style="color: white;"> Action
                                                                                                            <p-sortIcon field="category">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>
    
    
    
    
    
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template pTemplate="body" let-product>
                                                                                                <tr>
                                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                                        <div class="checkbox-align">
                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </p-checkbox>
                                                                                                            {{product.varianceReasonId}}
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td [ngStyle]="{'width':'170px'}"> {{product.glAccountId}}</td>
                                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                                        <svg class="hover" (click)="updateVarianceReasonData(product.organizationPartyId,product.glAccountId,product.varianceReasonId)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21.223 21.222">
                                                                                         <g transform="translate(0 -0.004)">
                                                                                           <g transform="translate(0 1.52)">
                                                                                             <path
                                                                                               d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                               transform="translate(0 -34.137)" />
                                                                                           </g>
                                                                                           <g transform="translate(4.548 0.004)">
                                                                                             <g transform="translate(0 0)">
                                                                                               <path
                                                                                                 d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                 transform="translate(-102.409 -0.004)" />
                                                                                             </g>
                                                                                           </g>
                                                                                         </g>
                                                                                       </svg>
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" (click)="deleteVarianceReasonGlAccounts(product.organizationPartyId,product.glAccountId,product.varianceReasonId)" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                                                                        <defs>
                                                                                            <style>
                                                                                                .a {
                                                                                                    fill: #f44336;
                                                                                                }
    
                                                                                                .b {
                                                                                                    fill: #fafafa;
                                                                                                    margin-left: 20px;
                                                                                                }
                                                                                            </style>
                                                                                        </defs>
                                                                                        <path class="a fill-color"
                                                                                            d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                        <path class="b"
                                                                                            d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                            transform="translate(-147.576 -147.576)" />
                                                                                    </svg>
                                                                                                    </td>
    
    
    
    
    
    
    
    
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No Record Found
                                                                                            </h4>
                                                                                        </span>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                        </p-table>
                                                                                        <p class="paginate_data">
                                                                                            View per page
    
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div *ngIf="activeCategoryValue==11">
                                                                        <div class="header-tabs">
                                                                            <h4 class=" common-styling hMargin">
                                                                                Credit Card Type GlAccount
    
    
                                                                            </h4>
                                                                            <span class="edit-buttons  hMaRight">
                                                                <a (click)="createCreditCard()">Create Credit Card Type GlAccount</a></span>
    
                                                                        </div>
                                                                        <br>
    
                                                                        <div class="panel-body">
                                                                            <div class="all-users-infomation font-13 font-medium own-user-select allUserDetails">
                                                                                <div class="form-group">
                                                                                    <div class="card own-account-table">
                                                                                        <p-table [value]="CreditCardTypeGlAccounts" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                            <ng-template pTemplate="header">
                                                                                                <tr style="background: #0d3769;">
                                                                                                    <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <!---->
                                                                                                            </p-checkbox>
    
    
    
    
                                                                                                            Card Type
                                                                                                            <p-sortIcon field="code"></p-sortIcon>
                                                                                                        </div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                        <div style="color: white;"> Gl Account Id
                                                                                                            <p-sortIcon field="name">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>
    
    
                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                        <div style="color: white;"> Action
                                                                                                            <p-sortIcon field="category">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>
    
    
    
    
    
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template pTemplate="body" let-product>
                                                                                                <tr>
                                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                                        <div class="checkbox-align">
                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </p-checkbox>
                                                                                                            {{product.cardType}}
    
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td [ngStyle]="{'width':'170px'}"> {{product.glAccountId}} </td>
                                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                                        <svg class="hover" (click)="updateCreditCardTypeGlAccounts(product.organizationPartyId,product.glAccountId,product.cardType)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21.223 21.222">
                                                                                         <g transform="translate(0 -0.004)">
                                                                                           <g transform="translate(0 1.52)">
                                                                                             <path
                                                                                               d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                               transform="translate(0 -34.137)" />
                                                                                           </g>
                                                                                           <g transform="translate(4.548 0.004)">
                                                                                             <g transform="translate(0 0)">
                                                                                               <path
                                                                                                 d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                 transform="translate(-102.409 -0.004)" />
                                                                                             </g>
                                                                                           </g>
                                                                                         </g>
                                                                                       </svg>
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" (click)="deleteCreditCardTypeGlAccounts(product.organizationPartyId,product.glAccountId,product.cardType)" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                                                                        <defs>
                                                                                            <style>
                                                                                                .a {
                                                                                                    fill: #f44336;
                                                                                                }
    
                                                                                                .b {
                                                                                                    fill: #fafafa;
                                                                                                    margin-left: 20px;
                                                                                                }
                                                                                            </style>
                                                                                        </defs>
                                                                                        <path class="a fill-color"
                                                                                            d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                        <path class="b"
                                                                                            d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                            transform="translate(-147.576 -147.576)" />
                                                                                    </svg>
                                                                                                    </td>
    
    
    
    
    
    
    
    
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No Record Found
                                                                                            </h4>
                                                                                        </span>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                        </p-table>
                                                                                        <p class="paginate_data">
                                                                                            View per page
    
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div *ngIf="activeCategoryValue==12">
                                                                        <div class="header-tabs">
                                                                            <h4 class=" common-styling hMargin">
                                                                                TaxAuthority GL Account
    
    
                                                                            </h4>
                                                                            <span class="edit-buttons  hCreate hMaRight"><a  data-toggle="modal" 
                                                                data-target="#exampleModalCenter12">Create TaxAuthority GL Account</a>
                                                              </span>
                                                                            <!-- <span class="edit-buttons  hMaRight">
                                                                <a (click)="createTaxAuthority()">Create</a></span> -->
    
                                                                        </div>
                                                                        <br>
    
                                                                        <div class="panel-body">
                                                                            <div class="all-users-infomation font-13 font-medium own-user-select allUserDetails">
                                                                                <div class="form-group">
                                                                                    <div class="card own-account-table">
                                                                                        <p-table [value]="TaxAuthorityGlAccount" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                            <ng-template pTemplate="header">
                                                                                                <tr style="background: #0d3769;">
                                                                                                    <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <!---->
                                                                                                            </p-checkbox>
    
    
    
    
    
                                                                                                            Tax Authority Geo
                                                                                                            <p-sortIcon field="code"></p-sortIcon>
                                                                                                        </div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                        <div style="color: white;"> Tax Authority Party
                                                                                                            <p-sortIcon field="name">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                        <div style="color: white;"> GL Account ID
                                                                                                            <p-sortIcon field="category">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>
    
                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                        <div style="color: white;"> Action
                                                                                                            <p-sortIcon field="category">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>
    
    
    
    
    
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template pTemplate="body" let-product>
                                                                                                <tr>
                                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                                        <div class="checkbox-align">
                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </p-checkbox>
                                                                                                            {{product.taxAuthGeoIdWithCountryName}} {{product.taxAuthGeoId}}
    
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td [ngStyle]="{'width':'170px'}"> {{product.taxAuthPartyIdWithDescription}} {{product.taxAuthPartyId}} </td>
                                                                                                    <td [ngStyle]="{'width':'170px'}"> {{product.glAccountId}}</td>
                                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                                        <svg class="hover" (click)="updateOrganizationTaxAuthorityGlAccounts(product.organizationPartyId,product.glAccountId,product.taxAuthGeoId,product.taxAuthPartyId)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21.223 21.222">
                                                                                         <g transform="translate(0 -0.004)">
                                                                                           <g transform="translate(0 1.52)">
                                                                                             <path
                                                                                               d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                               transform="translate(0 -34.137)" />
                                                                                           </g>
                                                                                           <g transform="translate(4.548 0.004)">
                                                                                             <g transform="translate(0 0)">
                                                                                               <path
                                                                                                 d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                 transform="translate(-102.409 -0.004)" />
                                                                                             </g>
                                                                                           </g>
                                                                                         </g>
                                                                                       </svg>
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" (click)="deleteOrganizationTaxAuthorityGlAccounts(product.organizationPartyId,product.glAccountId,product.taxAuthGeoId,product.taxAuthPartyId)" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                                                                        <defs>
                                                                                            <style>
                                                                                                .a {
                                                                                                    fill: #f44336;
                                                                                                }
    
                                                                                                .b {
                                                                                                    fill: #fafafa;
                                                                                                    margin-left: 20px;
                                                                                                }
                                                                                            </style>
                                                                                        </defs>
                                                                                        <path class="a fill-color"
                                                                                            d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                        <path class="b"
                                                                                            d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                            transform="translate(-147.576 -147.576)" />
                                                                                    </svg>
                                                                                                    </td>
    
    
    
    
    
    
    
    
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No Record Found
                                                                                            </h4>
                                                                                        </span>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                        </p-table>
                                                                                        <p class="paginate_data">
                                                                                            View per page
    
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div *ngIf="activeCategoryValue==13">
                                                                        <div class="header-tabs">
                                                                            <h4 class=" common-styling hMargin">
                                                                                Party Gl Account
    
    
    
                                                                            </h4>
                                                                            <span class="edit-buttons  hCreate hMaRight"><a  data-toggle="modal" 
                                                                data-target="#exampleModalCenter13">Create Party Gl Account</a>
                                                              </span>
                                                                            <!-- <span class="edit-buttons  hMaRight">
                                                                <a (click)="createPartyAccount()">Create</a></span> -->
    
                                                                        </div>
                                                                        <br>
    
                                                                        <div class="panel-body">
                                                                            <div class="all-users-infomation font-13 font-medium own-user-select allUserDetails">
                                                                                <div class="form-group">
                                                                                    <div class="card own-account-table">
                                                                                        <p-table [value]="PartyGlAccount" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                            <ng-template pTemplate="header">
                                                                                                <tr style="background: #0d3769;">
                                                                                                    <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <!---->
                                                                                                            </p-checkbox>
    
    
    
    
                                                                                                            Party Id
                                                                                                            <p-sortIcon field="code"></p-sortIcon>
                                                                                                        </div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                        <div style="color: white;"> Role Type Id
                                                                                                            <p-sortIcon field="name">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                        <div style="color: white;"> Gl Account Type ID
                                                                                                            <p-sortIcon field="name">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                        <div style="color: white;"> GL Account ID
                                                                                                            <p-sortIcon field="name">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>
    
    
                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                        <div style="color: white;"> Action
                                                                                                            <p-sortIcon field="category">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>
    
    
    
    
    
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template pTemplate="body" let-product>
                                                                                                <tr>
                                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                                        <div class="checkbox-align">
                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </p-checkbox>
                                                                                                            {{product.partyId}}
    
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td [ngStyle]="{'width':'170px'}"> {{product.roleTypeId}}</td>
                                                                                                    <td [ngStyle]="{'width':'170px'}"> {{product.glAccountTypeId}}</td>
                                                                                                    <td [ngStyle]="{'width':'170px'}"> {{product.glAccountId}} </td>
                                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                                        <svg class="hover" (click)="updatePartyGlAccount(product.organizationPartyId,product.glAccountId,product.glAccountTypeId,product.partyId,product.roleTypeId)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21.223 21.222">
                                                                                         <g transform="translate(0 -0.004)">
                                                                                           <g transform="translate(0 1.52)">
                                                                                             <path
                                                                                               d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                               transform="translate(0 -34.137)" />
                                                                                           </g>
                                                                                           <g transform="translate(4.548 0.004)">
                                                                                             <g transform="translate(0 0)">
                                                                                               <path
                                                                                                 d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                 transform="translate(-102.409 -0.004)" />
                                                                                             </g>
                                                                                           </g>
                                                                                         </g>
                                                                                       </svg>
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" (click)="deletePartyGlAccount(product.organizationPartyId,product.glAccountId,product.glAccountTypeId,product.partyId,product.roleTypeId)" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                                                                        <defs>
                                                                                            <style>
                                                                                                .a {
                                                                                                    fill: #f44336;
                                                                                                }
    
                                                                                                .b {
                                                                                                    fill: #fafafa;
                                                                                                    margin-left: 20px;
                                                                                                }
                                                                                            </style>
                                                                                        </defs>
                                                                                        <path class="a fill-color"
                                                                                            d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                        <path class="b"
                                                                                            d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                            transform="translate(-147.576 -147.576)" />
                                                                                    </svg>
                                                                                                    </td>
    
    
    
    
    
    
    
    
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No Record Found
                                                                                            </h4>
                                                                                        </span>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                        </p-table>
                                                                                        <p class="paginate_data">
                                                                                            View per page
    
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div *ngIf="activeCategoryValue==14">
                                                                        <div class="header-tabs ">
                                                                            <h4 class=" common-styling hMargin">
                                                                                Fixed Assets Type Gl Mappings
    
    
    
                                                                            </h4>
                                                                            <span class="edit-buttons  hCreate"><a  data-toggle="modal" 
                                                                data-target="#exampleModalCenter14">Create Fixed Assets Type Gl Mappings</a>
                                                              </span>
                                                                            <!-- <span class="edit-buttons  hMaRight">
                                                                <a (click)="createFixedAssest()">Create</a></span> -->
    
                                                                        </div>
                                                                        <br>
    
                                                                        <div class="panel-body">
                                                                            <div class="all-users-infomation font-13 font-medium own-user-select allUserDetails">
                                                                                <div class="form-group" style="    width: 100%;">
                                                                                    <div class="card own-account-table">
                                                                                        <p-table [value]="AssetTypeGlAccount" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                            <ng-template pTemplate="header">
                                                                                                <tr style="background: #0d3769;">
                                                                                                    <th pSortableColumn="code" [ngStyle]="{'width':'200px'}">
                                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <!---->
                                                                                                            </p-checkbox>
    
    
    
    
    
                                                                                                            Fixed Asset Type ID
                                                                                                            <p-sortIcon field="code"></p-sortIcon>
                                                                                                        </div>
                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                        <div style="color: white;"> Fixed Asset ID
                                                                                                            <p-sortIcon field="name">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                        <div style="color: white;"> Asset GL account
                                                                                                            <p-sortIcon field="name">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                        <div style="color: white;"> Accumulated depreciation GL account
                                                                                                            <p-sortIcon field="name">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>
    
    
                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                        <div style="color: white;"> Depreciation GL account
                                                                                                            <p-sortIcon field="category">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                        <div style="color: white;"> Profit GL account
                                                                                                            <p-sortIcon field="category">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                        <div style="color: white;"> Loss GL account
                                                                                                            <p-sortIcon field="category">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>
                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                        <div style="color: white;"> Action
                                                                                                            <p-sortIcon field="category">
                                                                                                            </p-sortIcon>
                                                                                                        </div>
                                                                                                        <div>
                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                        </div>
                                                                                                    </th>
    
    
    
    
    
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template pTemplate="body" let-product>
                                                                                                <tr>
                                                                                                    <td [ngStyle]="{'width':'200px'}">
                                                                                                        <div class="checkbox-align">
                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                        <input type="checkbox" name="undefined" value="undefined">
                                                                                                                    </div>
                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </p-checkbox>
                                                                                                            {{product.fixedAssetTypeId}}
    
                                                                                                        </div>
                                                                                                    </td>
                                                                                                    <td [ngStyle]="{'width':'170px'}"> {{product.fixedAssetId}}</td>
                                                                                                    <td [ngStyle]="{'width':'170px'}"> {{product.assetGlAccountId}}</td>
                                                                                                    <td [ngStyle]="{'width':'170px'}"> {{product.accDepGlAccountId}}</td>
                                                                                                    <td [ngStyle]="{'width':'170px'}"> {{product.depGlAccountId}}</td>
                                                                                                    <td [ngStyle]="{'width':'170px'}"> {{product.profitGlAccountId}}</td>
                                                                                                    <td [ngStyle]="{'width':'170px'}"> {{product.lossGlAccountId}}</td>
                                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" (click)="deleteFixedAssetTypeGlAccounts(product.organizationPartyId,product.accDepGlAccountId,product.assetGlAccountId,product.depGlAccountId,product.fixedAssetId,product.fixedAssetTypeId,product.lossGlAccountId,product.profitGlAccountId)"
                                                                                                            class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                                                                        <defs>
                                                                                            <style>
                                                                                                .a {
                                                                                                    fill: #f44336;
                                                                                                }
    
                                                                                                .b {
                                                                                                    fill: #fafafa;
                                                                                                    margin-left: 20px;
                                                                                                }
                                                                                            </style>
                                                                                        </defs>
                                                                                        <path class="a fill-color"
                                                                                            d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                        <path class="b"
                                                                                            d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                            transform="translate(-147.576 -147.576)" />
                                                                                    </svg>
                                                                                                    </td>
    
    
    
    
    
    
    
    
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                                                <tr>
                                                                                                    <td>
                                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No Record Found
                                                                                            </h4>
                                                                                        </span>
                                                                                                    </td>
                                                                                                </tr>
                                                                                            </ng-template>
                                                                                        </p-table>
                                                                                        <p class="paginate_data">
                                                                                            View per page
    
                                                                                        </p>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
    
    
                                                            </div>
                                                            <div *ngIf="activeCategory==6">
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling">
                                                                        Time Period
                                                                    </h4>
                                                                    <span class="edit-buttons  hCreate hMaRight"><a  data-toggle="modal" 
                                                            data-target="#exampleModalCenter15">Create Time Period</a>
                                                          </span>
                                                                    <!-- <span class="edit-buttons ">
                                                            <a (click)="createTimePeriod()">Create</a></span> -->
    
                                                                </div>
                                                                <br>
    
                                                                <div class="panel-body">
                                                                    <div class="header-tabs">
                                                                        <h4 class=" common-styling">
                                                                            Open Time Period
                                                                        </h4>
    
                                                                    </div>
                                                                    <div class="all-users-infomation font-13 font-medium own-user-select" style="display: block!important;">
                                                                        <div class="form-group">
                                                                            <div class="card own-account-table">
                                                                                <p-table [value]="OpenCustomTimePeriodId" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                    <ng-template pTemplate="header">
                                                                                        <tr style="background: #0d3769;">
                                                                                            <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                <div class="checkbox-align" style="color: white;">
                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <!---->
                                                                                                    </p-checkbox>
    
                                                                                                    Custom Time Period ID
                                                                                                    <p-sortIcon field="code">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                <div style="color: white;"> Parent Period ID
                                                                                                    <p-sortIcon field="name"></p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>
    
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                <div style="color: white;"> Period Type ID
                                                                                                    <p-sortIcon field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                <div style="color: white;"> Period Num
                                                                                                    <p-sortIcon field="category">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                <div style="color: white;"> Period Name
                                                                                                    <p-sortIcon field="category">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                <div style="color: white;"> From Date
                                                                                                    <p-sortIcon field="category">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                <div style="color: white;"> Through Date
                                                                                                    <p-sortIcon field="category">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                <div style="color: white;"> Action
                                                                                                    <p-sortIcon field="category">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>
    
    
    
    
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body" let-product>
                                                                                        <tr>
                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                <div class="checkbox-align">
                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </p-checkbox>
                                                                                                    {{product.customTimePeriodId}}
    
                                                                                                </div>
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'170px'}"> {{product.parentPeriodId}}</td>
    
                                                                                            <td [ngStyle]="{'width':'170px'}">{{product.periodTypeId}}</td>
                                                                                            <td [ngStyle]="{'width':'170px'}">{{product.periodNum}}</td>
                                                                                            <td [ngStyle]="{'width':'170px'}">{{product.periodName}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'170px'}">{{product.fromDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'170px'}">{{product.thruDate|date:'yyyy-MM-dd hh:mm:ss'}}</td>
                                                                                            <td  [ngStyle]="{'width':'170px'}" (click)="closeCustomTimePeriods(product.organizationPartyId,product.customTimePeriodId)">
                                                                                                <span style="color: #207EBB;cursor: pointer;">
                                                                                                    Close
                                                                                                </span></td>
    
    
    
    
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <span class="text-center">
                                                                                        <h4 class="ml-2">No Record Found
                                                                                        </h4>
                                                                                    </span>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>
                                                                                <p class="paginate_data">
                                                                                    View per page
    
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
    
                                                                    <br><br>
                                                                    <div class="header-tabs">
                                                                        <h4 class=" common-styling">
                                                                            Close Time Period
                                                                        </h4>
    
                                                                    </div>
                                                                    <div class="all-users-infomation font-13 font-medium own-user-select" style="display: block!important;">
                                                                        <div class="form-group">
                                                                            <div class="card own-account-table">
                                                                                <p-table [value]="CloseCustomTimePeriodId" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                    <ng-template pTemplate="header">
                                                                                        <tr style="background: #0d3769;">
                                                                                            <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                <div class="checkbox-align" style="color: white;">
                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <!---->
                                                                                                    </p-checkbox>
    
                                                                                                    Custom Time Period ID
                                                                                                    <p-sortIcon field="code">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                <div style="color: white;"> Parent Period ID
                                                                                                    <p-sortIcon field="name"></p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>
    
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                <div style="color: white;"> Period Type ID
                                                                                                    <p-sortIcon field="name">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                <div style="color: white;"> Period Num
                                                                                                    <p-sortIcon field="category">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                <div style="color: white;"> Period Name
                                                                                                    <p-sortIcon field="category">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                <div style="color: white;"> From Date
                                                                                                    <p-sortIcon field="category">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>
                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="category">
                                                                                                <div style="color: white;"> Through Date
                                                                                                    <p-sortIcon field="category">
                                                                                                    </p-sortIcon>
                                                                                                </div>
                                                                                                <div>
                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                </div>
                                                                                            </th>
    
    
    
    
    
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="body" let-product>
                                                                                        <tr>
                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                <div class="checkbox-align">
                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                <input type="checkbox" name="undefined" value="undefined">
                                                                                                            </div>
                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </p-checkbox>
                                                                                                    {{product.customTimePeriodId}}
    
                                                                                                </div>
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'170px'}"> {{product.parentPeriodId}}</td>
    
                                                                                            <td [ngStyle]="{'width':'170px'}">{{product.periodTypeId}}</td>
                                                                                            <td [ngStyle]="{'width':'170px'}">{{product.periodNum}}</td>
                                                                                            <td [ngStyle]="{'width':'170px'}">{{product.periodName}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'170px'}">{{product.fromDate|date:'yyyy-MM-dd hh:mm:ss'}}
                                                                                            </td>
                                                                                            <td [ngStyle]="{'width':'170px'}">{{product.thruDate|date:'yyyy-MM-dd hh:mm:ss'}}</td>
    
    
    
    
    
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <span class="text-center">
                                                                                        <h4 class="ml-2">No Record Found
                                                                                        </h4>
                                                                                    </span>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </p-table>
                                                                                <p class="paginate_data">
                                                                                    View per page
    
                                                                                </p>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
    
                                                            </div>
    
                                                        </div>
                                                    </div>
                                                </div>
    
                                            </form>
                                        </div>
                                    </div>
                                </div>
                            </div>
    
                        </div>
                    </div>
                </div>

               

            </div>
            <br> <br> <br> <br> <br>
        </div>
    </div>
</div>

<!--Newcode-->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Add Chart of Accounts </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                     <span aria-hidden="true" #closebutton>&times;</span>
                 </button>

            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                            Add Chart of Accounts</a></li>


                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createGlobalSettingForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputNew1">
                                                        <label for="exampleInputEmail1">
                                                        Gl Account ID<span
                                                        style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Gl Account ID" filter="true" formControlName="glAccountId" [options]="assignGLPartyIdArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA1">
                                                        <label for="exampleInputEmail1">From Date<span
                                                        style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date">
                                                    </div>



                                                </div>
                                            </div>

                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: 56%;">

                                        <button type="submit" (click)="onSubmitNew1();" class="btn btn-secondary submit-btn">Create Assignment</button>


                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>
<!--code end-->

<!--Newcode1--->
<div class="modal fade" id="exampleModalCenter1" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Add document type template </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton1>&times;</span>
            </button>

            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                        Add document type template</a></li>


                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createDoc">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputNew2" *ngIf="this.showNew">
                                                        <label for="exampleInputEmail1">
                                                
                                                    Document Type</label>
                                                    </div>

                                                    <div class="col-lg-2" *ngIf="this.showNew">
                                                        <p-dropdown placeholder="Enter Document Type" filter="true" formControlName="documentType" [options]="dropdownValue" optionlabel="label" (onChange)="onChange($event)">

                                                        </p-dropdown>


                                                    </div>
                                                    <div class="col-lg-3 form-group classInputNew2" *ngIf="!this.showNew">
                                                        <label for="exampleInputEmail1" *ngIf="this.InvoiceDropdown">
                                                
                                                    Invoice Type<span
                                                    style="color:red">*</span></label>
                                                        <label for="exampleInputEmail1" *ngIf="this.OrderDropdown">
                                                
                                                        Order Type<span
                                                        style="color:red">*</span></label>
                                                        <label for="exampleInputEmail1" *ngIf="this.QuoteDropdowm">
                                                
                                                            Quote Type<span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown *ngIf="this.InvoiceDropdown" placeholder="Enter Type" filter="true" formControlName="invoiceTypeId" [options]="typeIdArray" optionlabel="label">

                                                        </p-dropdown>
                                                        <p-dropdown *ngIf="this.OrderDropdown" placeholder="Enter Type" filter="true" formControlName="orderTypeId" [options]="typeIdArrayOrder" optionlabel="label">

                                                        </p-dropdown>
                                                        <p-dropdown *ngIf="this.QuoteDropdowm" placeholder="Enter Type" filter="true" formControlName="quoteTypeId" [options]="quoteTypeIdArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>




                                                </div>
                                            </div>
                                            <div class="col-lg-12" *ngIf="!this.showNew">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputNew2">
                                                        <label for="exampleInputEmail1">
                                                
                                                    Form Date<span
                                                    style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="date" formControlName="fromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date">

                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA1">
                                                        <label for="exampleInputEmail1">
                                                
                                                    Custom screen</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Custom screen" filter="true" formControlName="customScreenId" [options]="customerScreen" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>


                                        </div>


                                    </form>
                                    <div *ngIf="this.showNew" class="main-submit-button"  style="margin-left: 18%;">

                                        <button type="submit" (click)="onSubmitNew2();" class="btn btn-secondary submit-btn">Select Button</button>


                                    </div>
                                    <div *ngIf="!this.showNew" class="main-submit-button"  style="margin-left: 60%;">

                                        <button type="submit" (click)="onSubmitNew2();" class="btn btn-secondary submit-btn">Select Button</button>


                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>
<!--code end1-->
<!--Newcode2--->
<div class="modal fade" id="exampleModalCenter2" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Add Journals
            </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton2>&times;</span>
            </button>

            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="!this.showNew1"><a>
                        Add Journals
                    </a></li>
                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="this.showNew1"><a>
                        Update Journals
                    </a></li>


                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createJournal1">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputNew3">
                                                        <label for="exampleInputEmail1">
                                                    Gl Journal Name<span
                                                    style="color:red">*</span></label>

                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" formControlName="journalName" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Gl Journal Name">
                                                    </div>


                                                </div>
                                            </div>


                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: 21%;">

                                        <button type="submit" (click)="onSubmitNew3();" *ngIf="!this.showNew1" class="btn btn-secondary submit-btn">Submit</button>

                                        <button type="submit" (click)="onUpdate();" *ngIf="this.showNew1" class="btn btn-secondary submit-btn">Update</button>


                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>
<!--code end2-->

<!--Newcode3--->
<div class="modal fade" id="exampleModalCenter3" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Add  GL Account Type Defaults </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton3>&times;</span>
            </button>

            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                        Add  GL Account Type Defaults</a></li>


                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createAccountDefault">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputNew4">
                                                        <label for="exampleInputEmail1">
                                           
                                                    Gl Account Type<span
                                                    style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Gl Account Type" filter="true" formControlName="glAccountType" [options]="glAccountTypeArray" optionlabel="label">

                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA4">
                                                        <label for="exampleInputEmail1">
                                             
                                                    Gl Account ID<span
                                                    style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Gl Account ID" filter="true" formControlName="glAccountId" [options]="glChartOfAccountArray" optionlabel="label">

                                                        </p-dropdown>

                                                    </div>



                                                </div>
                                            </div>

                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: 63%;">

                                        <button type="submit" (click)="onSubmitNew03();" class="btn btn-secondary submit-btn">Save</button>


                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>
<!--code end3-->
<!--Newcode4--->
<div class="modal fade" id="exampleModalCenter4" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Add Product GL Accounts </span>


                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton4>&times;</span>
            </button>

            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="!this.showNew2"><a>
                        Add Product GL Accounts</a></li>
                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="this.showNew2"><a>
                            Update Product GL Accounts</a></li>


                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createProduct">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputNew5">
                                                        <label for="exampleInputEmail1">
                                            
                                                    Account Type<span
                                                    style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-2" *ngIf="!this.showNew2">
                                                        <p-dropdown placeholder="Enter Account Type" filter="true" formControlName="accountType" [options]="glAccountTypeArray" optionlabel="label">

                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-2" *ngIf="this.showNew2">
                                                        <input type="email" formControlName="accountType" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Account Type" readonly>

                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA5">
                                                        <label for="exampleInputEmail1">
                                                

                                                    GL Account<span
                                                    style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter GL Account" filter="true" formControlName="accountId" [options]="glChartOfAccountArray" optionlabel="label">

                                                        </p-dropdown>

                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputNew5">
                                                        <label for="exampleInputEmail1">
                                               
                                                    Product ID<span
                                                    style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-2" *ngIf="!this.showNew2">
                                                        <p-dropdown placeholder="Enter Product ID" filter="true" formControlName="productId" [options]="productListArray" optionlabel="label">

                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-2" *ngIf="this.showNew2">
                                                        <input type="email" formControlName="productId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter GL Account" readonly>

                                                    </div>

                                                </div>
                                            </div>


                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: 62%;">

                                        <button type="submit" *ngIf="!this.showNew2" (click)="onSubmitNew4();" class="btn btn-secondary submit-btn">Save</button>
                                        <button type="submit" *ngIf="this.showNew2" (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>
<!--code end4-->
<!--Newcode5--->
<div class="modal fade" id="exampleModalCenter5" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Add Product Category GL Accounts </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton5>&times;</span>
            </button>

            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid" style="width: 100%;">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="!this.showNew3"><a>
                        Add Product Category GL Accounts</a></li>
                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="this.showNew3"><a>
                            Update Product Category GL Accounts</a></li>


                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createProduct1">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputNew6">
                                                        <label for="exampleInputEmail1">
                                            
                                                    Account Type<span
                                                    style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-2" *ngIf="!this.showNew3">
                                                        <p-dropdown placeholder="Enter Account Type" filter="true" formControlName="accountType" [options]="glAccountTypeArray" optionlabel="label">

                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-2" *ngIf="this.showNew3">
                                                        <input type="email" formControlName="accountType" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Account Type" readonly>

                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA6">
                                                        <label for="exampleInputEmail1">
                                                

                                                    GL Account<span
                                                    style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter GL Account" filter="true" formControlName="accountId" [options]="glChartOfAccountArray" optionlabel="label">

                                                        </p-dropdown>

                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputNew6">
                                                        <label for="exampleInputEmail1">
                                                

                                                    Product Category ID<span
                                                    style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-2" *ngIf="!this.showNew3">
                                                        <p-dropdown placeholder="Enter Product Category ID" filter="true" formControlName="productId" [options]="categoryListArray" optionlabel="label">

                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-2" *ngIf="this.showNew3">
                                                        <input type="email" formControlName="productId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter GL Account" readonly>

                                                    </div>

                                                </div>
                                            </div>


                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: 66%;">

                                        <button type="submit" (click)="onSubmitNew5();" *ngIf="!this.showNew3" class="btn btn-secondary submit-btn">Save</button>
                                        <button type="submit" *ngIf="this.showNew3" (click)="onUpdate();" class="btn btn-secondary submit-btn">Update</button>



                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>


                </div>
            </div>

        </div>
    </div>
</div>
<!--code end5-->
<!--Newcode6--->
<div class="modal fade" id="exampleModalCenter6" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Add FinAccount Type Gl Account</span>


                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton6>&times;</span>
            </button>

            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="!this.showNew6"><a>
                        Add FinAccount Type Gl Account
                    </a></li>
                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="this.showNew6"><a>
                        Update FinAccount Type Gl Account
                    </a></li>


                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createFin">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputNew7">
                                                        <label for="exampleInputEmail1">
                    
                                                    Fin Account Type ID<span
                                                    style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown *ngIf="!this.showNew6" placeholder="Enter Fin Account Type ID" filter="true" formControlName="finType" [options]="finAccountTypeIdArray" optionlabel="label">

                                                        </p-dropdown>
                                                        <input type="email" *ngIf="this.showNew6" formControlName="finType" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Fin Account Type ID" readonly>

                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA7">
                                                        <label for="exampleInputEmail1">
                                            
                                                    GL Account Id<span
                                                    style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter GL Account" filter="true" formControlName="accountId" [options]="glChartOfAccountArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>



                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: 66%;">

                                        <button type="submit" *ngIf="!this.showNew6" (click)="onSubmitNew6();" class="btn btn-secondary submit-btn">Save</button>
                                        <button type="submit" *ngIf="this.showNew6" (click)="onUpdate();" class="btn btn-secondary submit-btn">Update</button>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>
<!--code end6-->
<!--Newcode7--->
<div class="modal fade" id="exampleModalCenter7" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Add Assign Sales Invoice type to Revenue GL Account Number
            </span>


                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton7>&times;</span>
            </button>

            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                        Assign Sales Invoice type to Revenue GL Account Number
                    </a></li>


                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createSale">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputNew8">
                                                        <label for="exampleInputEmail1">
                                                

                                                    Invoice Item Type<span
                                                    style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Invoice Item Type" filter="true" formControlName="invoiceItemType" [options]="invoiceItemTypeIdArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA8">
                                                        <label for="exampleInputEmail1">
                                                

                                                    Override Revenue Gl Account ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Override Revenue Gl Account ID" filter="true" formControlName="overrideRevenue" [options]="glAccountIdArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>



                                        </div>

                                        <div class="main-submit-button" style="    margin-right: 29%;">

                                            <button type="submit" (click)="onSubmitnew7()" class="btn btn-secondary submit-btn">Save</button>


                                        </div>
                                    </form>

                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>
<!--code end7-->
<!--Newcode8--->
<div class="modal fade" id="exampleModalCenter8" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Add Assign Purchase Invoice type to Revenue GL Account Number
 
            </span>



                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton8>&times;</span>
            </button>

            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                         Assign Purchase Invoice type to Revenue GL Account Number
 
                    </a></li>


                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createPurchase">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputNew9">
                                                        <label for="exampleInputEmail1">
                                                

                                                    Invoice Purchase Item Type<span
                                                    style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Invoice Purchase Item Type" filter="true" formControlName="invoiceItemType" [options]="invoiceItemTypeIdArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA9">
                                                        <label for="exampleInputEmail1">
                                                

                                                    Invoice Override Expense Gl Account ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Invoice Override Expense Gl Account ID" filter="true" formControlName="overrideRevenue" [options]="glAccountIdArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>


                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: 69%;">

                                        <button type="submit" (click)="onSubmitNew8()" class="btn btn-secondary submit-btn">Save</button>


                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>
<!--code end8-->
<!--Newcode9--->
<div class="modal fade" id="exampleModalCenter9" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Add  Payment Type Assign Account Type
            </span>



                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton9>&times;</span>
            </button>

            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                        Add  Payment Type Assign Account Type
                    </a></li>


                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createPayment">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputNew10">
                                                        <label for="exampleInputEmail1">
                                       
                                                    Payment Type<span
                                                    style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Payment Type" filter="true" formControlName="paymentType" [options]="paymentTypeIdArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA10">
                                                        <label for="exampleInputEmail1">
                                           
                                                    Gl Account Type<span
                                                    style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Gl Account Type" filter="true" formControlName="glAccountType" [options]="glAccountTypeArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>

                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: 62%;">

                                        <button type="submit" (click)="onSubmitNew9();" class="btn btn-secondary submit-btn">Save</button>


                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>
<!--code end9-->
<!--Newcode10--->
<div class="modal fade" id="exampleModalCenter10" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Add  Payment Method Assign Account Type
 
            </span>


                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton10>&times;</span>
            </button>

            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                        Add  Payment Method Assign Account Type
 
                    </a></li>


                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createPaymentNew1">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputNew11">
                                                        <label for="exampleInputEmail1">
                                                

                                                    Payment Method Type<span
                                                    style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Payment Method Type" filter="true" formControlName="paymentType" [options]="getPaymentMethodTypeArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA11">
                                                        <label for="exampleInputEmail1">
                                                

                                                    Gl Account Id<span
                                                    style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Gl Account Id" filter="true" formControlName="glAccountType" [options]="glChartOfAccountArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>


                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: 67%;">

                                        <button type="submit" (click)="onSubmitNew10();" class="btn btn-secondary submit-btn">Save</button>


                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>


                </div>
            </div>

        </div>
    </div>
</div>
<!--code end10-->
<!--Newcode11--->
<div class="modal fade" id="exampleModalCenter11" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Add  Variance Reason Gl Accounts

 
            </span>


                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton11>&times;</span>
            </button>

            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="!this.showNew4"><a>
                        Add Variance Reason Gl Accounts

 
                    </a></li>
                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="this.showNew4"><a>
                        Update Variance Reason Gl Accounts

 
                    </a></li>


                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createVaraince">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputNew12">
                                                        <label for="exampleInputEmail1">
                                                

                                                
                                                    Variance Reason ID<span
                                                    style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-2" *ngIf="!this.showNew4">
                                                        <p-dropdown placeholder="Enter Variance Reason ID" filter="true" formControlName="varainceType" [options]="varianceReasonIdArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2" *ngIf="this.showNew4">
                                                        <input type="email" formControlName="varainceType" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Account Type" readonly>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA12">
                                                        <label for="exampleInputEmail1">
                                                

                                                    Gl Account Type Id<span
                                                    style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Gl Account Type Id" filter="true" formControlName="glAccountType" [options]="glChartOfAccountArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>

                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: -25%;">

                                        <button type="submit" *ngIf="!this.showNew4" (click)="onSubmitNew11();" class="btn btn-secondary submit-btn">Save</button>
                                        <button type="submit" *ngIf="this.showNew4" (click)="onUpdate();" class="btn btn-secondary submit-btn">Update</button>


                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>
<!--code end11-->
<!--Newcode12--->
<div class="modal fade" id="exampleModalCenter12" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Add  TaxAuthority GL Account

 
            </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton12>&times;</span>
            </button>

            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="!this.showNew5"><a>
                        Add TaxAuthority GL Account

 
                    </a></li>

                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="this.showNew5"><a>
                        Update TaxAuthority GL Account

 
                    </a></li>


                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createTax">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputNew13">
                                                        <label for="exampleInputEmail1">
                                                
                                                    Tax Authority Geo<span
                                                    style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-2" *ngIf="!this.showNew5">
                                                        <p-dropdown placeholder="Enter Tax Authority Geo" filter="true" formControlName="taxAuth" (onChange)="taxAuthorityGlAccountInitialFormDataParty()" [options]="taxAuthorityGlAccountormArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2" *ngIf="this.showNew5">
                                                        <input type="email" formControlName="taxAuth" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Fin Account Type ID" readonly>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA13">
                                                        <label for="exampleInputEmail1">
                                            
                                                    Tax Authority Party<span
                                                    style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2" *ngIf="!this.showNew5">
                                                        <p-dropdown placeholder="Enter Tax Authority Party" filter="true" formControlName="taxAuthParty" [options]="taxAuthorityGlAccountormDataArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2" *ngIf="this.showNew5">
                                                        <input type="email" formControlName="taxAuthParty" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Fin Account Type ID" readonly>
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputNew13">
                                                        <label for="exampleInputEmail1">
                                                

                                                    Gl Account Id</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Gl Account Id" filter="true" formControlName="glAccountType" [options]="glChartOfAccountArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>


                                                </div>
                                            </div>


                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: 64%;">

                                        <button type="submit" *ngIf="!this.showNew5" (click)="onSubmitNew12();" class="btn btn-secondary submit-btn">Save</button>

                                        <button type="submit" *ngIf="this.showNew5" (click)="onUpdate();" class="btn btn-secondary submit-btn">Update</button>


                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>
<!--code end12-->
<!--Newcode13--->
<div class="modal fade" id="exampleModalCenter13" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Add  Party Gl Account

 
            </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton13>&times;</span>
            </button>

            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="!this.showNew6"><a>
                        Add  Party Gl Account

 
                    </a></li>

                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="this.showNew6"><a>
                        Update  Party Gl Account

 
                    </a></li>


                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createParty">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputNew14">
                                                        <label for="exampleInputEmail1">
                                                

                                                    Party Id<span
                                                    style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown *ngIf="!this.showNew7" placeholder="Enter Party Id" filter="true" formControlName="partyId" [options]="partyIdArray" optionlabel="label">

                                                        </p-dropdown>
                                                        <input type="email" *ngIf="this.showNew7" formControlName="partyId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Fin Account Type ID" readonly>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA14">
                                                        <label for="exampleInputEmail1">
                                                

                                                    Role Type Id<span
                                                    style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown *ngIf="!this.showNew7" placeholder="Enter Role Type Id" filter="true" formControlName="roleType" [options]="allroleArray" optionlabel="label">

                                                        </p-dropdown>
                                                        <input type="email" *ngIf="this.showNew7" formControlName="roleType" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Fin Account Type ID" readonly>
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputNew14">
                                                        <label for="exampleInputEmail1">
                                                

                                                    Gl Account Type Id<span
                                                    style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown *ngIf="!this.showNew7" placeholder="Enter Gl Account Type Id" filter="true" formControlName="glAccountType" [options]="glAccountTypeArray" optionlabel="label">

                                                        </p-dropdown>
                                                        <input type="email" *ngIf="this.showNew7" formControlName="glAccountType" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Fin Account Type ID" readonly>
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA14">
                                                        <label for="exampleInputEmail1">
                                                

                                                    Gl Account Id<span
                                                    style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Gl Account Id" filter="true" formControlName="glAccountId" [options]="glChartOfAccountArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>


                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: 65%;">

                                        <button type="submit" *ngIf="!this.showNew7" (click)="onSubmitNew13()" class="btn btn-secondary submit-btn">Save</button>
                                        <button type="submit" *ngIf="this.showNew7" (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button>


                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>
<!--code end13-->
<!--Newcode14--->
<div class="modal fade" id="exampleModalCenter14" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Add Fixed Asset Type Gl Mappings

 
            </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton14>&times;</span>
            </button>

            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                        Add Fixed Asset Type Gl Mappings

 
                    </a></li>


                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createFixedAssest21">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputNew15">
                                                        <label for="exampleInputEmail1">
                                          
                                                    Asset GL account<span
                                                    style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Asset GL account" filter="true" formControlName="assestGlAccount" [options]="assetGlAccountIdArrayNew" optionlabel="label">

                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA15">
                                                        <label for="exampleInputEmail1">
                                               
                                                    Accumulated depreciation GL account<span
                                                    style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Accumulated depreciation GL account" filter="true" formControlName="accumulated" [options]="accDepGlAccountIdArrayNew" optionlabel="label">

                                                        </p-dropdown>

                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputNew15">
                                                        <label for="exampleInputEmail1">
                                                

                                                    Depreciation GL account<span
                                                    style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Depreciation GL account" filter="true" formControlName="depreciation" [options]="depGlAccountIdArrayNew" optionlabel="label">

                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA15">
                                                        <label for="exampleInputEmail1">
                                                

                                                    Profit GL account<span
                                                    style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Profit GL account" filter="true" formControlName="profit" [options]="profitGlAccountIdArrayNew" optionlabel="label">

                                                        </p-dropdown>

                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputNew15">
                                                        <label for="exampleInputEmail1">
                                                

                                                    Loss GL account<span
                                                    style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Loss GL account" filter="true" formControlName="loss" [options]="lossGlAccountIdArrayNew" optionlabel="label">

                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA15">
                                                        <label for="exampleInputEmail1">
                                                

                                                    Fixed Asset Type ID<span
                                                    style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Fixed Asset Type ID" filter="true" formControlName="fixedAssestTypeId" [options]="fixedAssetTypeIdArrayNew" optionlabel="label">

                                                        </p-dropdown>

                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputNew15">
                                                        <label for="exampleInputEmail1">
                                                

                                                    Fixed Asset ID<span
                                                    style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Fixed Asset ID" filter="true" formControlName="asssestId" [options]="fixedAssetIdArrayNew" optionlabel="label">

                                                        </p-dropdown>

                                                    </div>

                                                </div>
                                            </div>

                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left:68%;">

                                        <button type="submit" (click)="onSubmitNew14();" class="btn btn-secondary submit-btn">Save</button>


                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>
<!--code end14-->
<!--Newcode15--->
<div class="modal fade" id="exampleModalCenter15" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Add Custom Time Period </span>


                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                <span aria-hidden="true" #closebutton15>&times;</span>
            </button>

            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey" style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                        Add Custom Time Period</a></li>



                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createTimePeriodNew12">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputNew16">
                                                        <label for="exampleInputEmail1">Parent Period Id</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Parent Period Id" filter="true" formControlName="parentPeriodId" [options]="parentPeriodIdArray" optionlabel="label">

                                                        </p-dropdown>


                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA16">
                                                        <label for="exampleInputEmail1">Period Type Id<span
                                                    style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Period Type" filter="true" formControlName="periodTypeId" [options]="DataTimePeriodArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputNew16">
                                                        <label for="exampleInputEmail1">Period Number</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Period Number" formControlName="periodNum">


                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA16">
                                                        <label for="exampleInputEmail1">Period Name	</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Period Name" formControlName="periodName">
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputNew16">
                                                        <label for="exampleInputEmail1">From Date<span
                                                    style="color:red">*</span>
                                                   
                                                </label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter From Date" formControlName="fromDate">
                                                    </div>
                                                    <div class="col-lg-3 form-group classInputA16">
                                                        <label for="exampleInputEmail1">Through Date	<span
                                                    style="color:red">*</span>
                                                    
                                                </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Period Name" formControlName="thruDate">
                                                    </div>



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputNew16">
                                                        <label for="exampleInputEmail1">Is Closed</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Is Closed" filter="true" formControlName="isClosed" [options]="closed" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>



                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left:62%;">

                                        <button type="submit" (click)="onSubmitNew15();" class="btn btn-secondary submit-btn">Submit</button>


                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>
            </div>

        </div>
    </div>
</div>
<!--code end15-->




<ngx-spinner></ngx-spinner>