<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">

            <div class=" bg-white color-grey">
                <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">

                    <span class="color-black pl-1 titlePanels"></span>
                    <span class="d-flex">

                        <button type="submit" (click)="homeButton();"
                            class=" btn btn-outline-secondary mr-2">Home</button>

                        <button type="submit" (click)="cancelSubmit();"
                            class="btn btn-danger buttonclass ">Back</button>
                    </span>
                </div>
                <div class="w3-card-4 classCard">

                    <div class="header-tabs">

                        <h4 class=" common-styling hCard" style="margin-left: 7px;">
                            Company Information
                        </h4>

                        <span class=" pl-1 edit-buttons">
                            <a style="cursor: pointer;" data-toggle="modal"
                                data-target="#updateexampleModalCenter">Update</a>&nbsp;
                            <!-- <a style="cursor: pointer;" data-toggle="modal" data-target="#exampleModal">| Create
                                Secondary Company</a>&nbsp; -->

                        </span>

                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">

                                            <div class="w3-card-4 classCard w-100">
                                                <div class="container-fluid">
                                                    <ul class="tabbing-section tabbing-accordians tabClass"
                                                        style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 8px !important;">
                                                        <li [ngClass]="activeCategory==1?'active':''"
                                                            (click)="changeactiveCategory(1)">
                                                            <a>Owner Information</a>
                                                        </li>
                                                        <li [ngClass]="activeCategory==2?'active':''"
                                                            (click)="changeactiveCategory(2)">
                                                            <a>Contact Person Name
                                                            </a>
                                                        </li>
                                                        <li [ngClass]="activeCategory==3?'active':''"
                                                            (click)="changeactiveCategory(3)">
                                                            <a>Alt Contact Person Name
                                                            </a>
                                                        </li>
                                                        <li [ngClass]="activeCategory==4?'active':''"
                                                            (click)="changeactiveCategory(4)">
                                                            <a>Billing Address</a>
                                                        </li>
                                                        <li [ngClass]="activeCategory==5?'active':''"
                                                            (click)="changeactiveCategory(5)">
                                                            <a>Shipping Address</a>
                                                        </li>
                                                        <li [ngClass]="activeCategory==6?'active':''"
                                                            (click)="changeactiveCategory(6)">
                                                            <a>Payment Information</a>
                                                        </li>
                                                    </ul>
                                                </div>
                                                <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                                    <div *ngIf="activeCategory==1" class="w-100">



                                                        <div class="w3-card-4 classCardView">
                                                            <div class="header-tabs"
                                                                style="background:#F5F7FA !important;">
                                                                <h4 class=" common-styling hleft pl-2">
                                                                    Owner Information
                                                                </h4>
                                                            </div>
                                                            <form>
                                                                <div
                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 rightForm">
                                                                                <div class="form-group">
                                                                                    <label
                                                                                        for="exampleInputEmail1">Owner
                                                                                        Name


                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <div class="form-group">
                                                                                    {{tenantDetails.tenantDetails.ownerName}}

                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3 rightFormA">
                                                                                <div class="form-group">
                                                                                    <label
                                                                                        for="exampleInputEmail1">Tenant
                                                                                        Name


                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <div class="form-group">
                                                                                    {{userDetailsData.companyName}}

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <!-- <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 rightForm">
                                                                                <div class="form-group">
                                                                                    <label for="exampleInputEmail1">Tenant
                                                                                        Logo
    
    
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <div class="form-group">
                                                                                    {{tenantDetails.tenantDetails.logoUrl}}
    
                                                                                </div>
                                                                            </div>
    
    
                                                                        </div>
                                                                    </div> -->

                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="activeCategory==2" class="w-100">



                                                        <div class="w3-card-4 classCardView">
                                                            <div class="header-tabs"
                                                                style="background:#F5F7FA !important;">
                                                                <h4 class=" common-styling hleft pl-2">
                                                                    Contact Person Name
                                                                </h4>
                                                            </div>
                                                            <form>
                                                                <div
                                                                    class="all-users-infomation font-13 font-medium own-user-select">

                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 rightForm">
                                                                                <div class="form-group">
                                                                                    <label
                                                                                        for="exampleInputEmail1">Title
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <div class="form-group">


                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3 rightFormA">
                                                                                <div class="form-group">
                                                                                    <label
                                                                                        for="exampleInputEmail1">First
                                                                                        Name


                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <div class="form-group">
                                                                                    {{userDetailsData.firstName}}
                                                                                </div>
                                                                            </div>


                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 rightForm">
                                                                                <div class="form-group">
                                                                                    <label
                                                                                        for="exampleInputEmail1">Middle
                                                                                        Name


                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <div class="form-group">

                                                                                </div>
                                                                            </div>

                                                                            <div class="col-lg-3 rightFormA">
                                                                                <div class="form-group">
                                                                                    <label for="exampleInputEmail1">Last
                                                                                        Name



                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <div class="form-group">
                                                                                    {{userDetailsData.lastName}}
                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">

                                                                            <div class="col-lg-3 rightForm">
                                                                                <div class="form-group">
                                                                                    <label
                                                                                        for="exampleInputEmail1">Phone


                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <div class="form-group">
                                                                                    {{userDetailsData.contactNo}}
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3 rightFormA">
                                                                                <div class="form-group">
                                                                                    <label
                                                                                        for="exampleInputEmail1">Email



                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <div class="form-group">
                                                                                    {{userDetailsData.emailId}}
                                                                                </div>
                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="activeCategory==3" class="w-100">

                                                        <div class="w3-card-4 classCardView">
                                                            <div class="header-tabs"
                                                                style="background:#F5F7FA !important;">
                                                                <h4 class=" common-styling hleft pl-2">
                                                                    Alt Contact Person Name </h4>
                                                            </div>
                                                            <form>
                                                                <div
                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 rightForm">
                                                                                <div class="form-group">
                                                                                    <label
                                                                                        for="exampleInputEmail1">Title
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <div class="form-group">



                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3 rightFormA">
                                                                                <div class="form-group">
                                                                                    <label
                                                                                        for="exampleInputEmail1">First
                                                                                        Name


                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <div class="form-group">

                                                                                </div>
                                                                            </div>


                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 rightForm">
                                                                                <div class="form-group">
                                                                                    <label
                                                                                        for="exampleInputEmail1">Middle
                                                                                        Name


                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <div class="form-group">

                                                                                </div>
                                                                            </div>

                                                                            <div class="col-lg-3 rightFormA">
                                                                                <div class="form-group">
                                                                                    <label for="exampleInputEmail1">Last
                                                                                        Name



                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <div class="form-group">

                                                                                </div>
                                                                            </div>

                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">

                                                                            <div class="col-lg-3 rightForm">
                                                                                <div class="form-group">
                                                                                    <label
                                                                                        for="exampleInputEmail1">Phone


                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <div class="form-group">

                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <div class="form-group rightFormA">
                                                                                    <label
                                                                                        for="exampleInputEmail1">Email



                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <div class="form-group">

                                                                                </div>
                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 rightForm">
                                                                                <div class="form-group">
                                                                                    <label for="exampleInputEmail1">Type
                                                                                        of
                                                                                        Business/Industries



                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <div class="form-group">
                                                                                    {{tenantDetails.tenantDetails.typeOfBusiness}}

                                                                                </div>
                                                                            </div>

                                                                            <div class="col-lg-3 rightFormA">
                                                                                <div class="form-group">
                                                                                    <label for="exampleInputEmail1">Sell
                                                                                        online
                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <div class="form-group">
                                                                                    {{tenantDetails.tenantDetails.sellOnline}}

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">

                                                                            <div class="col-lg-3 rightForm">
                                                                                <div class="form-group">
                                                                                    <label
                                                                                        for="exampleInputEmail1">Professional/Service
                                                                                        Industry Website URL

                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <div class="form-group">

                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3 rightFormA">
                                                                                <div class="form-group">
                                                                                    <label
                                                                                        for="exampleInputEmail1">Number
                                                                                        of Employee/User



                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <div class="form-group">
                                                                                    {{tenantDetails.tenantDetails.noOfEmployees}}

                                                                                </div>
                                                                            </div>


                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-3 rightForm">
                                                                                <div class="form-group">
                                                                                    <label for="exampleInputEmail1">How
                                                                                        Many
                                                                                        User Interested In



                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <div class="form-group">

                                                                                </div>
                                                                            </div>

                                                                            <div class="col-lg-3 rightFormA">
                                                                                <div class="form-group">
                                                                                    <label
                                                                                        for="exampleInputEmail1">Additional
                                                                                        Users

                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <div class="form-group">

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-12">
                                                                        <div class="row">

                                                                            <div class="col-lg-3 rightForm">
                                                                                <div class="form-group">
                                                                                    <label for="exampleInputEmail1">IRS
                                                                                        Tax
                                                                                        ID Number


                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <div class="form-group">
                                                                                    {{tenantDetails.tenantDetails.irsTaxIdNo}}

                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3 rightFormA">
                                                                                <div class="form-group">
                                                                                    <label
                                                                                        for="exampleInputEmail1">Upload
                                                                                        Proof of Tax ID/Documents



                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <div class="form-group">
                                                                                    {{tenantDetails.tenantDetails.taxIdProofUrl}}

                                                                                </div>
                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="activeCategory==4" class="w-100">

                                                        <div class="w3-card-4 classCardView">
                                                            <div class="header-tabs"
                                                                style="background:#F5F7FA !important;">
                                                                <h4 class=" common-styling hleft pl-2">
                                                                    Billing Address
                                                                </h4>
                                                            </div>
                                                            <form>
                                                                <div
                                                                    class="all-users-infomation font-13 font-medium own-user-select">

                                                                    <div class="col-lg-12">
                                                                        <div class="row">

                                                                            <div class="col-lg-3 rightForm">
                                                                                <div class="form-group">
                                                                                    <label
                                                                                        for="exampleInputEmail1">Address
                                                                                        1



                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <div class="form-group">

                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3 rightFormA">
                                                                                <div class="form-group">
                                                                                    <label
                                                                                        for="exampleInputEmail1">Address
                                                                                        2



                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <div class="form-group">

                                                                                </div>
                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">

                                                                            <div class="col-lg-3 rightForm">
                                                                                <div class="form-group">
                                                                                    <label
                                                                                        for="exampleInputEmail1">Address
                                                                                        3


                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <div class="form-group">

                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3 rightFormA">
                                                                                <div class="form-group">
                                                                                    <label
                                                                                        for="exampleInputEmail1">Country

                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <div class="form-group">

                                                                                </div>
                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">

                                                                            <div class="col-lg-3 rightForm">
                                                                                <div class="form-group">
                                                                                    <label
                                                                                        for="exampleInputEmail1">State



                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <div class="form-group">

                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3 rightFormA">
                                                                                <div class="form-group">
                                                                                    <label for="exampleInputEmail1">City




                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <div class="form-group">

                                                                                </div>
                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">

                                                                            <div class="col-lg-3 rightForm">
                                                                                <div class="form-group">
                                                                                    <label for="exampleInputEmail1">Zip
                                                                                        Code


                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <div class="form-group">

                                                                                </div>
                                                                            </div>



                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="activeCategory==5" class="w-100">

                                                        <div class="w3-card-4 classCardView">
                                                            <div class="header-tabs"
                                                                style="background:#F5F7FA !important;">
                                                                <h4 class=" common-styling hleft pl-2">
                                                                    Shipping Address
                                                                </h4>
                                                            </div>
                                                            <form>
                                                                <div
                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="col-lg-12">
                                                                        <div class="row">

                                                                            <div class="col-lg-3 rightForm">
                                                                                <div class="form-group">
                                                                                    <label
                                                                                        for="exampleInputEmail1">Address
                                                                                        1



                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <div class="form-group">

                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3 rightFormA">
                                                                                <div class="form-group">
                                                                                    <label
                                                                                        for="exampleInputEmail1">Address
                                                                                        2



                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <div class="form-group">

                                                                                </div>
                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">

                                                                            <div class="col-lg-3 rightForm">
                                                                                <div class="form-group">
                                                                                    <label
                                                                                        for="exampleInputEmail1">Address
                                                                                        3


                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <div class="form-group">

                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3 rightFormA">
                                                                                <div class="form-group">
                                                                                    <label
                                                                                        for="exampleInputEmail1">Country

                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <div class="form-group">

                                                                                </div>
                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">

                                                                            <div class="col-lg-3 rightForm">
                                                                                <div class="form-group">
                                                                                    <label
                                                                                        for="exampleInputEmail1">State



                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <div class="form-group">

                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3 rightFormA">
                                                                                <div class="form-group">
                                                                                    <label for="exampleInputEmail1">City




                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <div class="form-group">

                                                                                </div>
                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">

                                                                            <div class="col-lg-3 rightForm">
                                                                                <div class="form-group">
                                                                                    <label for="exampleInputEmail1">Zip
                                                                                        Code


                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <div class="form-group">

                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                    <div *ngIf="activeCategory==6" class="w-100">

                                                        <div class="w3-card-4 classCardView">
                                                            <div class="header-tabs"
                                                                style="background:#F5F7FA !important;">
                                                                <h4 class=" common-styling hleft pl-2">
                                                                    Payment Information
                                                                </h4>
                                                            </div>
                                                            <form>
                                                                <div
                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="col-lg-12">
                                                                        <div class="row">

                                                                            <div class="col-lg-3 rightForm">
                                                                                <div class="form-group">
                                                                                    <label
                                                                                        for="exampleInputEmail1">Credit
                                                                                        Card Number




                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <div class="form-group">

                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3 rightFormA">
                                                                                <div class="form-group">
                                                                                    <label for="exampleInputEmail1">Name
                                                                                        on Card





                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <div class="form-group">

                                                                                </div>
                                                                            </div>


                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">

                                                                            <div class="col-lg-3 rightForm">
                                                                                <div class="form-group">
                                                                                    <label for="exampleInputEmail1">Exp
                                                                                        Date





                                                                                    </label>
                                                                                </div>
                                                                            </div>
                                                                            <div class="col-lg-3">
                                                                                <div class="form-group">

                                                                                </div>
                                                                            </div>




                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </form>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<!-- 
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Update Company Information</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton1>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="container-fluid main-container-dialog">
                    <div class="row">
                        <div class="col-12">
                            <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                                <div class=" bg-white color-grey" style="width: 100%;">
                                    <div class="container-fluid">
                                        <ul class="tabbing-section tabbing-accordians tabClass">

                                            <li [ngClass]="activeCategoryOne==1?'active':''"
                                                (click)="changeactiveCategoryFunction(1)">
                                                <a>Owner Information</a>
                                            </li>
                                            <li [ngClass]="activeCategoryOne==2?'active':''"
                                                (click)="changeactiveCategoryFunction(2)">
                                                <a>Contact Person Name
                                                </a>
                                            </li>
                                            <li [ngClass]="activeCategory==3?'active':''"
                                                (click)="changeactiveCategoryFunction(3)">
                                                <a>Alt Contact Person Name
                                                </a>
                                            </li>
                                            <li [ngClass]="activeCategoryOne==4?'active':''"
                                                (click)="changeactiveCategoryFunction(4)">
                                                <a>Billing Address</a>
                                            </li>
                                            <li [ngClass]="activeCategoryOne==5?'active':''"
                                                (click)="changeactiveCategoryFunction(5)">
                                                <a>Shipping Address</a>
                                            </li>
                                            <li [ngClass]="activeCategoryOne==6?'active':''"
                                                (click)="changeactiveCategoryFunction(6)">
                                                <a>Payment Information</a>
                                            </li>



                                        </ul>
                                    </div>

                                    <div class="create-new-leade mt-2">
                                        <div class="container-fluid">
                                            <div class="row">
                                                <form class="w-100" [formGroup]="createPlan">
                                                    <div *ngIf="activeCategoryOne==1">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 rightForm">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Owner
                                                                                Name

                                                                                <span style="color: red;">*</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="ownerName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Owner Name">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3 rightFormA">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Tenant
                                                                                Name

                                                                                <span style="color: red;">*</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="tenantName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Tenant Name">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 rightForm">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Tenant
                                                                                Logo

                                                                                <span style="color: red;">*</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="file" class="form-control"
                                                                                formControlName="tenantLogo"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Tenant Logo">
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="main-submit-button" style="margin-right:11%;">
                                                            <button type="submit"
                                                                (click)="changeactiveCategoryFunction(2)"
                                                                class="btn btn-secondary submit-btn">Continue</button>

                                                        </div>
                                                    </div>
                                                    <div *ngIf="activeCategoryOne==2">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">

                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 rightForm">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Title
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">


                                                                            <p-dropdown formControlName="Title"
                                                                                filter="true" placeholder="Enter Title">
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3 rightFormA">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">First
                                                                                Name
                                                                                <span style="color: red;">*</span>

                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" name="lastName"
                                                                                class="form-control"
                                                                                formControlName="lastName" id="lastName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter First Name">
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 rightForm">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Middle
                                                                                Name


                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="firstName"
                                                                                id="firstName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Middle Name">
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-3 rightFormA">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Last
                                                                                Name


                                                                                <span style="color: red;">*</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="groupName"
                                                                                id="groupName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Last Name">
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 rightForm">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Phone

                                                                                <span style="color: red;">*</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="groupName"
                                                                                id="groupName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Phone Number">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3 rightFormA">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Email


                                                                                <span style="color: red;">*</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="groupName"
                                                                                id="groupName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Email">
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>




                                                        </div>
                                                        <div class="main-submit-button" style="margin-right: 13%;">
                                                            <button type="submit"
                                                                (click)="changeactiveCategoryFunction(1)"
                                                                class="btn btn-secondary submit-btn mr-2">Previous</button>
                                                            <button type="submit"
                                                                (click)="changeactiveCategoryFunction(3)"
                                                                class="btn btn-secondary submit-btn">Continue</button>

                                                        </div>
                                                    </div>
                                                    <div *ngIf="activeCategoryOne==3">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">



                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 rightForm">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Title
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">


                                                                            <p-dropdown formControlName="title"
                                                                                filter="true" placeholder="Enter Title">
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3 rightFormA">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">First
                                                                                Name
                                                                                <span style="color: red;">*</span>

                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" name="lastName"
                                                                                class="form-control"
                                                                                formControlName="lastName" id="lastName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter First Name">
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 rightForm">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Middle
                                                                                Name


                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="firstName"
                                                                                id="firstName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Middle Name">
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-3 rightFormA">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Last
                                                                                Name


                                                                                <span style="color: red;">*</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="groupName"
                                                                                id="groupName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Last Name">
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 rightForm">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Phone

                                                                                <span style="color: red;">*</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="groupName"
                                                                                id="groupName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Phone Number">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group rightFormA">
                                                                            <label for="exampleInputEmail1">Email


                                                                                <span style="color: red;">*</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="groupName"
                                                                                id="groupName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Email">
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 rightForm">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Type of
                                                                                Business/Industries


                                                                                <span style="color: red;">*</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" name="idValue"
                                                                                class="form-control"
                                                                                formControlName="idValue" id="idValue"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Type of Business">
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-3 rightFormA">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Sell
                                                                                online
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <p-dropdown [options]="identificationArray"
                                                                                formControlName="sellOnline"
                                                                                filter="true"
                                                                                placeholder="Enter Sell online">
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 rightForm">
                                                                        <div class="form-group">
                                                                            <label
                                                                                for="exampleInputEmail1">Professional/Service
                                                                                Industry Website URL

                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="groupName"
                                                                                id="groupName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Professional/Service Industry Website URL">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3 rightFormA">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Number
                                                                                of Employee/User



                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="groupName"
                                                                                id="groupName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Number of Employee/User">
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 rightForm">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">How Many
                                                                                User Interested In



                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" name="idValue"
                                                                                class="form-control"
                                                                                formControlName="idValue" id="idValue"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter How Many User Interested In">
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-3 rightFormA">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Additional
                                                                                Users

                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <p-dropdown formControlName="additionalUser"
                                                                                filter="true"
                                                                                placeholder="Enter Additional Users">
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 rightForm">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">IRS Tax
                                                                                ID Number


                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="groupName"
                                                                                id="groupName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter IRS Tax ID Number">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3 rightFormA">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Upload
                                                                                Proof of Tax ID/Documents



                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="file" class="form-control"
                                                                                formControlName="groupName"
                                                                                id="groupName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Upload Proof of Tax ID/Documents">
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>



                                                        </div>
                                                        <div class="main-submit-button" style="margin-right:5%;">
                                                            <button type="submit"
                                                                (click)="changeactiveCategoryFunction(3)"
                                                                class="btn btn-secondary submit-btn">Previous</button>
                                                            <button type="submit"
                                                                (click)="changeactiveCategoryFunction(5)"
                                                                class="btn btn-secondary submit-btn">Continue</button>

                                                        </div>
                                                    </div>
                                                    <div *ngIf="activeCategoryOne==4">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">



                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 rightForm">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Address
                                                                                1
                                                                                <span style="color: red;">*</span>


                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="groupName"
                                                                                id="groupName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Address 1">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3 rightFormA">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Address
                                                                                2



                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="groupName"
                                                                                id="groupName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Address 2">
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 rightForm">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Address
                                                                                3


                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="groupName"
                                                                                id="groupName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Address 3">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3 rightFormA">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Country
                                                                                <span style="color: red;">*</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <p-dropdown formControlName="countryGeoId"
                                                                                filter="true"
                                                                                placeholder="Enter Country">
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 rightForm">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">State
                                                                                <span style="color: red;">*</span>


                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <p-dropdown formControlName="stateGeoId"
                                                                                filter="true"
                                                                                placeholder="Enter Country">
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3 rightFormA">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">City
                                                                                <span style="color: red;">*</span>



                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="city" id="groupName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter City">
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 rightForm">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Zip Code
                                                                                <span style="color: red;">*</span>

                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="zipcode" id="groupName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Zip Code">
                                                                        </div>
                                                                    </div>



                                                                </div>
                                                            </div>


                                                        </div>
                                                        <div class="main-submit-button" style="margin-right: 12%;">
                                                            <button type="submit"
                                                                (click)="changeactiveCategoryFunction(4)"
                                                                class="btn btn-secondary submit-btn mr-2">Previous</button>
                                                            <button type="submit"
                                                                (click)="changeactiveCategoryFunction(6)"
                                                                class="btn btn-secondary submit-btn mr-2">Continue</button>


                                                        </div>
                                                    </div>
                                                    <div *ngIf="activeCategoryOne==5">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">



                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 rightForm">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Address
                                                                                1
                                                                                <span style="color: red;">*</span>


                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="groupName"
                                                                                id="groupName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Address 1">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3 rightFormA">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Address
                                                                                2



                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="groupName"
                                                                                id="groupName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Address 2">
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 rightForm">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Address
                                                                                3


                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="groupName"
                                                                                id="groupName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Address 3">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3 rightFormA">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Country
                                                                                <span style="color: red;">*</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <p-dropdown
                                                                                formControlName="partyIdentificationTypeId"
                                                                                filter="true"
                                                                                placeholder="Enter Country">
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 rightForm">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">State
                                                                                <span style="color: red;">*</span>


                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <p-dropdown
                                                                                formControlName="partyIdentificationTypeId"
                                                                                filter="true"
                                                                                placeholder="Enter Country">
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3 rightFormA">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">City
                                                                                <span style="color: red;">*</span>



                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="groupName"
                                                                                id="groupName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter City">
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 rightForm">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Zip Code
                                                                                <span style="color: red;">*</span>

                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="groupName"
                                                                                id="groupName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Zip Code">
                                                                        </div>
                                                                    </div>



                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div class="main-submit-button" style="margin-right: 12%;">
                                                            <button type="submit"
                                                                (click)="changeactiveCategoryFunction(4)"
                                                                class="btn btn-secondary submit-btn mr-2">Previous</button>
                                                            <button type="submit"
                                                                (click)="changeactiveCategoryFunction(6)"
                                                                class="btn btn-secondary submit-btn mr-2">Continue</button>


                                                        </div>
                                                    </div>
                                                    <div *ngIf="activeCategoryOne==6">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 rightForm">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Credit Card
                                                                                Number




                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="creditCardNo"
                                                                                id="groupName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Credit Card Number">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3 rightFormA">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Name on Card





                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="nameOnCard"
                                                                                id="groupName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter name On Card">
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 rightForm">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Exp Date



                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="date" class="form-control"
                                                                                formControlName="expDate" id="groupName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter ">
                                                                        </div>
                                                                    </div>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="main-submit-button" style="margin-right: 12%;">
                                                            <button type="submit"
                                                                (click)="changeactiveCategoryFunction(5)"
                                                                class="btn btn-secondary submit-btn mr-2">Previous</button>
                                                            <button type="submit"
                                                                class="btn btn-secondary submit-btn mr-2">Submit</button>


                                                        </div>
                                                    </div>




                                                </form>

                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<!-- 
<div class="modal fade" id="exampleModal" tabindex="-1" role="dialog" aria-labelledby="exampleModal" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Create New Secondary Company</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton1>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="container-fluid main-container-dialog">
                    <div class="row">
                        <div class="col-12">
                            <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                                <div class=" bg-white color-grey" style="width: 100%;">
                                    <div class="container-fluid">
                                        <ul class="tabbing-section tabbing-accordians tabClass">

                                            <li [ngClass]="activeCategoryTwo==1?'active':''"
                                                (click)="changeactiveCategoryTwo(1)">
                                                <a>Owner Information</a>
                                            </li>
                                            <li [ngClass]="activeCategoryTwo==2?'active':''"
                                                (click)="changeactiveCategoryTwo(2)">
                                                <a>Contact Person Name
                                                </a>
                                            </li>
                                            <li [ngClass]="activeCategoryTwo==3?'active':''"
                                                (click)="changeactiveCategoryTwo(3)">
                                                <a>Alt Contact Person Name
                                                </a>
                                            </li>
                                            <li [ngClass]="activeCategoryTwo==4?'active':''"
                                                (click)="changeactiveCategoryTwo(4)">
                                                <a>Billing Address</a>
                                            </li>
                                            <li [ngClass]="activeCategoryTwo==5?'active':''"
                                                (click)="changeactiveCategoryTwo(5)">
                                                <a>Shipping Address</a>
                                            </li>
                                            <li [ngClass]="activeCategoryTwo==6?'active':''"
                                                (click)="changeactiveCategoryTwo(6)">
                                                <a>Payment Information</a>
                                            </li>



                                        </ul>
                                    </div>

                                    <div class="create-new-leade mt-2">
                                        <div class="container-fluid">
                                            <div class="row">
                                                <form class="w-100" [formGroup]="createSecondaryCompanyForm">
                                                    <div *ngIf="activeCategoryTwo==1">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 rightForm">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Owner
                                                                                Name

                                                                                <span style="color: red;">*</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="ownerName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Owner Name">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3 rightFormA">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Tenant
                                                                                Name

                                                                                <span style="color: red;">*</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="tenantName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Tenant Name">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 rightForm">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Tenant
                                                                                Logo

                                                                                <span style="color: red;">*</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="file" class="form-control"
                                                                                formControlName="tenantLogo"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Tenant Logo">
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="main-submit-button" style="margin-right:11%;">
                                                            <button type="submit" (click)="changeactiveCategoryTwo(2)"
                                                                class="btn btn-secondary submit-btn">Continue</button>

                                                        </div>
                                                    </div>
                                                    <div *ngIf="activeCategoryTwo==2">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">

                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 rightForm">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Title
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">


                                                                            <p-dropdown formControlName="Title"
                                                                                filter="true" placeholder="Enter Title">
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3 rightFormA">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">First
                                                                                Name
                                                                                <span style="color: red;">*</span>

                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" name="lastName"
                                                                                class="form-control"
                                                                                formControlName="lastName" id="lastName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter First Name">
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 rightForm">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Middle
                                                                                Name


                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="firstName"
                                                                                id="firstName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Middle Name">
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-3 rightFormA">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Last
                                                                                Name


                                                                                <span style="color: red;">*</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="groupName"
                                                                                id="groupName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Last Name">
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 rightForm">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Phone

                                                                                <span style="color: red;">*</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="groupName"
                                                                                id="groupName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Phone Number">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3 rightFormA">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Email


                                                                                <span style="color: red;">*</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="groupName"
                                                                                id="groupName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Email">
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>




                                                        </div>
                                                        <div class="main-submit-button" style="margin-right: 13%;">
                                                            <button type="submit" (click)="changeactiveCategoryTwo(1)"
                                                                class="btn btn-secondary submit-btn mr-2">Previous</button>
                                                            <button type="submit" (click)="changeactiveCategoryTwo(3)"
                                                                class="btn btn-secondary submit-btn">Continue</button>

                                                        </div>
                                                    </div>
                                                    <div *ngIf="activeCategoryTwo==3">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">



                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 rightForm">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Title
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">


                                                                            <p-dropdown formControlName="title"
                                                                                filter="true" placeholder="Enter Title">
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3 rightFormA">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">First
                                                                                Name
                                                                                <span style="color: red;">*</span>

                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" name="lastName"
                                                                                class="form-control"
                                                                                formControlName="lastName" id="lastName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter First Name">
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 rightForm">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Middle
                                                                                Name


                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="firstName"
                                                                                id="firstName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Middle Name">
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-3 rightFormA">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Last
                                                                                Name


                                                                                <span style="color: red;">*</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="groupName"
                                                                                id="groupName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Last Name">
                                                                        </div>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 rightForm">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Phone

                                                                                <span style="color: red;">*</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="groupName"
                                                                                id="groupName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Phone Number">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group rightFormA">
                                                                            <label for="exampleInputEmail1">Email


                                                                                <span style="color: red;">*</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="groupName"
                                                                                id="groupName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Email">
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 rightForm">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Type of
                                                                                Business/Industries


                                                                                <span style="color: red;">*</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" name="idValue"
                                                                                class="form-control"
                                                                                formControlName="idValue" id="idValue"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Type of Business">
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-3 rightFormA">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Sell
                                                                                online
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <p-dropdown [options]="identificationArray"
                                                                                formControlName="sellOnline"
                                                                                filter="true"
                                                                                placeholder="Enter Sell online">
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 rightForm">
                                                                        <div class="form-group">
                                                                            <label
                                                                                for="exampleInputEmail1">Professional/Service
                                                                                Industry Website URL

                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="groupName"
                                                                                id="groupName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Professional/Service Industry Website URL">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3 rightFormA">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Number
                                                                                of Employee/User



                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="groupName"
                                                                                id="groupName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Number of Employee/User">
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 rightForm">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">How Many
                                                                                User Interested In



                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" name="idValue"
                                                                                class="form-control"
                                                                                formControlName="idValue" id="idValue"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter How Many User Interested In">
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-3 rightFormA">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Additional
                                                                                Users

                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <p-dropdown formControlName="additionalUser"
                                                                                filter="true"
                                                                                placeholder="Enter Additional Users">
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 rightForm">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">IRS Tax
                                                                                ID Number


                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="groupName"
                                                                                id="groupName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter IRS Tax ID Number">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3 rightFormA">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Upload
                                                                                Proof of Tax ID/Documents



                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="file" class="form-control"
                                                                                formControlName="groupName"
                                                                                id="groupName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Upload Proof of Tax ID/Documents">
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>



                                                        </div>
                                                        <div class="main-submit-button" style="margin-right:5%;">
                                                            <button type="submit" (click)="changeactiveCategoryTwo(2)"
                                                                class="btn btn-secondary submit-btn">Previous</button>
                                                            <button type="submit" (click)="changeactiveCategoryTwo(4)"
                                                                class="btn btn-secondary submit-btn">Continue</button>

                                                        </div>
                                                    </div>
                                                    <div *ngIf="activeCategoryTwo==4">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">



                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 rightForm">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Address
                                                                                1
                                                                                <span style="color: red;">*</span>


                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="groupName"
                                                                                id="groupName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Address 1">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3 rightFormA">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Address
                                                                                2



                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="groupName"
                                                                                id="groupName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Address 2">
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 rightForm">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Address
                                                                                3


                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="groupName"
                                                                                id="groupName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Address 3">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3 rightFormA">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Country
                                                                                <span style="color: red;">*</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <p-dropdown formControlName="countryGeoId"
                                                                                filter="true"
                                                                                placeholder="Enter Country">
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 rightForm">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">State
                                                                                <span style="color: red;">*</span>


                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <p-dropdown formControlName="stateGeoId"
                                                                                filter="true"
                                                                                placeholder="Enter Country">
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3 rightFormA">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">City
                                                                                <span style="color: red;">*</span>



                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="city" id="groupName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter City">
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 rightForm">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Zip Code
                                                                                <span style="color: red;">*</span>

                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="zipcode" id="groupName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Zip Code">
                                                                        </div>
                                                                    </div>



                                                                </div>
                                                            </div>


                                                        </div>
                                                        <div class="main-submit-button" style="margin-right: 12%;">
                                                            <button type="submit" (click)="changeactiveCategoryTwo(3)"
                                                                class="btn btn-secondary submit-btn mr-2">Previous</button>
                                                            <button type="submit" (click)="changeactiveCategoryTwo(5)"
                                                                class="btn btn-secondary submit-btn mr-2">Continue</button>


                                                        </div>
                                                    </div>
                                                    <div *ngIf="activeCategoryTwo==5">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">



                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 rightForm">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Address
                                                                                1
                                                                                <span style="color: red;">*</span>


                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="groupName"
                                                                                id="groupName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Address 1">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3 rightFormA">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Address
                                                                                2



                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="groupName"
                                                                                id="groupName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Address 2">
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 rightForm">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Address
                                                                                3


                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="groupName"
                                                                                id="groupName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Address 3">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3 rightFormA">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Country
                                                                                <span style="color: red;">*</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <p-dropdown
                                                                                formControlName="partyIdentificationTypeId"
                                                                                filter="true"
                                                                                placeholder="Enter Country">
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 rightForm">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">State
                                                                                <span style="color: red;">*</span>


                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <p-dropdown
                                                                                formControlName="partyIdentificationTypeId"
                                                                                filter="true"
                                                                                placeholder="Enter Country">
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3 rightFormA">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">City
                                                                                <span style="color: red;">*</span>



                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="groupName"
                                                                                id="groupName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter City">
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 rightForm">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Zip Code
                                                                                <span style="color: red;">*</span>

                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="groupName"
                                                                                id="groupName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Zip Code">
                                                                        </div>
                                                                    </div>



                                                                </div>
                                                            </div>

                                                        </div>
                                                        <div class="main-submit-button" style="margin-right: 12%;">
                                                            <button type="submit" (click)="changeactiveCategoryTwo(4)"
                                                                class="btn btn-secondary submit-btn mr-2">Previous</button>
                                                            <button type="submit" (click)="changeactiveCategoryTwo(6)"
                                                                class="btn btn-secondary submit-btn mr-2">Continue</button>


                                                        </div>
                                                    </div>
                                                    <div *ngIf="activeCategoryTwo==6">
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 rightForm">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Credit Card
                                                                                Number




                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="creditCardNo"
                                                                                id="groupName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Credit Card Number">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3 rightFormA">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Name on Card





                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="nameOnCard"
                                                                                id="groupName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter name On Card">
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 rightForm">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Exp Date



                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="date" class="form-control"
                                                                                formControlName="expDate" id="groupName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter ">
                                                                        </div>
                                                                    </div>



                                                                </div>
                                                            </div>
                                                        </div>
                                                        <div class="main-submit-button" style="margin-right: 12%;">
                                                            <button type="submit" (click)="changeactiveCategoryTwo(5)"
                                                                class="btn btn-secondary submit-btn mr-2">Previous</button>
                                                            <button type="submit"
                                                                class="btn btn-secondary submit-btn mr-2">Submit</button>


                                                        </div>
                                                    </div>




                                                </form>

                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div> -->
<div class="modal fade" id="updateexampleModalCenter" tabindex="-1" role="dialog"
    aria-labelledby="updateexampleModalCenter" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Update Company Information</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="container-fluid main-container-dialog">
                    <div class="row">
                        <div class="col-12">
                            <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                                <div class=" bg-white color-grey" style="width: 100%;">
                                    <div class="container-fluid">
                                        <ul class="tabbing-section tabbing-accordians tabClass">

                                            <li [ngClass]="activeCategoryOne==1?'active':''"
                                                (click)="changeactiveCategoryFunction(1)">
                                                <a>Update Company Information</a>
                                            </li>



                                        </ul>
                                    </div>

                                    <div class="create-new-leade mt-2">
                                        <div class="container-fluid">
                                            <div class="row">
                                                <form class="w-100" [formGroup]="updateUserForm">
                                                    <div>
                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 rightForm">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Company Name


                                                                                <span style="color: red;">*</span>
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="companyName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Company Name">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3 rightFormA">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Contact No



                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="contactNo"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Contact No">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 rightForm">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Created Date


                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="date" class="form-control"
                                                                                formControlName="createdDate"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter created Date ">
                                                                        </div>
                                                                    </div>

                                                                    <div class="col-lg-3 rightFormA">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">First Name


                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="firstName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter First Name ">
                                                                        </div>
                                                                    </div>


                                                                </div>
                                                            </div>


                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 rightForm">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Last Name



                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="lastName"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Last Name">
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3 rightFormA">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Password



                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="password" class="form-control"
                                                                                formControlName="password"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter password ">
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 rightForm">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Is Active
                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">


                                                                            <p-dropdown [options]="yesNoArray"
                                                                                formControlName="isActive" filter="true"
                                                                                placeholder="Enter Is Active">
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3 rightFormA">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Is Deleted



                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <p-dropdown [options]="yesNoArray"
                                                                                formControlName="isDeleted"
                                                                                filter="true"
                                                                                placeholder="Enter Is Deleted">
                                                                            </p-dropdown>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12">
                                                                <div class="row">
                                                                    <div class="col-lg-3 rightForm">
                                                                        <div class="form-group">
                                                                            <label for="exampleInputEmail1">Email Id



                                                                            </label>
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <div class="form-group">
                                                                            <input type="text" class="form-control"
                                                                                formControlName="emailId"
                                                                                aria-describedby="emailHelp"
                                                                                placeholder="Enter Email Id ">
                                                                        </div>
                                                                    </div>
                                        
                                                                </div>
                                                            </div>
                                                            <div class="main-submit-button" style="margin-left:86%;">
                                                                <button type="submit" (click)="updateUserDetails()"
                                                                    class="btn btn-secondary submit-btn">Save</button>

                                                            </div>
                                                        </div>

                                                    </div>


                                                </form>

                                            </div>
                                        </div>

                                    </div>
                                </div>

                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>



<ngx-spinner></ngx-spinner>