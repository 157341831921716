import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from "@angular/forms";
import {AccountsService} from "../../crm/accounts/accounts.service";
import { ToastrService } from 'ngx-toastr';
import {Router} from "@angular/router";

declare var $: any;

@Component({
  selector: 'app-import',
  templateUrl: './import.component.html',
  styleUrls: ['./import.component.css']
})
export class ImportAccountComponent implements OnInit {
  fileData: File;
  Editform: FormGroup;
  activeCategory=1;
  constructor(readonly accountsService:AccountsService,
    readonly router:Router,
    readonly toastr:ToastrService) { }

  ngOnInit(): void {
    this.Editform = new FormGroup({
      file: new FormControl('', [
        Validators.required
      ]),
    });
   
  }
  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    
  }
  onSubmit(){
    const formData = new FormData();
    formData.append("file",this.fileData);
    this.accountsService.importAccount(formData).subscribe((res:any)=>{
      if(res.success) {
        this.toastr.success(res.data.responseMessage);
        this.router.navigate(["/crm/accounts"]);

      }     },(err)=>{
        this.toastr.error(err.message);
     })
  }
  changeactiveCategoryFunction(tab: number){
    this.activeCategory =tab;
  }
  onCancel(){
    this.router.navigate(["/crm/accounts"])
  }
}
