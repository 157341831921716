import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-reports-order',
  templateUrl: './reports-order.component.html',
  styleUrls: ['./reports-order.component.css']
})
export class ReportsOrderComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  activeCategory = 1;
  resData: any;
  total = 0;
  show=true;
  emailReport=false
  trackCode=false;
  marketCampaignId=false;
  partyStatusReport=false;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  sprintTable: any;
  SalesbyStoreReportForm: FormGroup;
  OpenOrderItemsReportForm: FormGroup;
  PurchasesbyOrganizationReportForm: FormGroup;
  SaleOrdersByChannelForm: FormGroup;
  SalesOrderDiscountCodeForm: FormGroup;
  PurchasesbyProductReportForm: FormGroup;
  PurchasesbyPaymentMethodReportForm: FormGroup;
  SalesReportForm: FormGroup;
  ReportByArray: { label: string; value: string; }[];
  FormatTypeArray: { label: string; value: string; }[];
  ProductDemandReportForm: FormGroup;
  salesChannel: any;
  salesChannelArray: any[]=[];
  productStoreIdReport: any;
  productStoreIdReportArray:any[]=[];
  originFacilityIdReport: any;
  originFacilityIdReportArray: any[]=[];
  personId: any;
  partyIdArray: any[]=[];
  StatusIdReport: any;
  StatusIdReportArray: any[]=[];
  newFromDateValue: string;
  ThroughDateValue: string;
  statusOpenOrderArray: { label: string; value: string; }[];
  OrderTypeIDArray: { label: string; value: string; }[];
  openOrderItemsReport: any;
  reportArray: { label: string; value: string; }[];
  reportForm: FormGroup;
  SalesbyStoreReport: boolean;
  PurchasesbyProductReport: boolean;
  reportTypeArray: { label: string; value: string; }[];
  ProductDemandReport: boolean;
  SalesOrderDiscountCode: boolean;
  SalesReport: boolean;
  SaleOrdersByChannel: boolean;
  CouponSalesReport: boolean;
  Last3MonthsSalesReport: boolean;
  OpenOrderItemsReport: boolean;
  PurchasesbyPaymentMethodReport: boolean;
  PurchasesbyOrganizationReport: boolean;
  constructor(    public createEventFormService: CreateEventFormService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly headerService: HeaderService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router,
    private _location: Location,) { 

      this.SalesbyStoreReportForm = this._FormBuilder.group({
        ProductStoreID: [''],
        ToPartyID: [''],
        OrderStatusID: [''],
        FromDate: [''],
        ThroughDate: [''],
      });
      this.OpenOrderItemsReportForm = this._FormBuilder.group({
        ProductStoreID: [''],
        OrderTypeID: [''],
        OrderStatusID: [''],
        FromDate: [''],
        ThroughDate: [''],
      });
      this.PurchasesbyOrganizationReportForm = this._FormBuilder.group({
        FromParty: [''],
        ToPartyID: [''],
        OrderStatusID: [''],
        FromDate: [''],
        ThroughDate: [''],
      });
      this.SaleOrdersByChannelForm = this._FormBuilder.group({
        SalesChannel: [''],
     
      });
      this.SalesOrderDiscountCodeForm = this._FormBuilder.group({
     
      });

      this.PurchasesbyProductReportForm = this._FormBuilder.group({
        ProductStoreID: [''],
        OriginFacilityID: [''],
        TerminalID: [''],
        OrderStatusID: [''],
        FromDate: [''],
        ThroughDate: [''],
     
      });

      this.PurchasesbyPaymentMethodReportForm = this._FormBuilder.group({
        ProductStoreID: [''],
        OriginFacilityID: [''],
        TerminalID: [''],
        OrderStatusID: [''],
        FromDate: [''],
        ThroughDate: [''],
        OrderTypeID: [''],
      });
      this.SalesReportForm = this._FormBuilder.group({
        FromDate: [''],
        ReportBy: [''],
        FormatType: [''],
      });
      this.ProductDemandReportForm = this._FormBuilder.group({
        ProductStoreID: [''],
      });
      this.FormatTypeArray = [

        {
          label: 'PDF',
          value: 'pdf'
        },
        {
          label: 'Excel',
          value: 'excel'
        },
        {
          label: 'HTML',
          value: 'html'
        },
      ];
      this.ReportByArray = [

        {
          label: 'Days',
          value: 'day'
        },
        {
          label: 'Weeks',
          value: 'week'
        },
        {
          label: 'Months',
          value: 'month'
        },
      ];
      this.OrderTypeIDArray = [

        {
          label: 'Purchase Order',
          value: 'PURCHASE_ORDER'
        },
        {
          label: 'Sales Order',
          value: 'SALES_ORDER'
        },
      
      ];
      this.statusOpenOrderArray =[
        {
          label: 'Any',
          value: ''
        },
        {
          label: 'Created',
          value: 'ORDER_CREATED'
        },
        {
          label: 'Approved',
          value: 'ORDER_APPROVED'
        },
        {
          label: 'Held',
          value: 'ORDER_HOLD'
        }

      ]

      this.reportTypeArray =[
        {
          label: 'Sales by Store Report',
          value: 'SalesbyStoreReport'
        },
        {
          label: 'Purchases by Product Report',
          value: 'PurchasesbyProductReport'
        },
        {
          label: 'Open Order Items Report',
          value: 'OpenOrderItemsReport'
        },
        {
          label: 'Purchases by Payment Method Report',
          value: 'PurchasesbyPaymentMethodReport'
        },
        {
          label: 'Purchases by Organization Report',
          value: 'PurchasesbyOrganizationReport'
        },
        {
          label: 'Last 3 Months Sales Report',
          value: 'Last3MonthsSalesReport'
        },
        {
          label: 'Coupon Sales Report',
          value: 'CouponSalesReport'
        },
        {
          label: 'Sale Orders By Channel',
          value: 'SaleOrdersByChannel'
        },
        {
          label: 'Sales Report',
          value: 'SalesReport'
        },
        {
          label: 'Sales Order Discount Code',
          value: 'SalesOrderDiscountCode'
        },
        {
          label: 'Product Demand Report',
          value: 'ProductDemandReport'
        },

      ]
      this.reportForm = this._FormBuilder.group({
        report: [''],
      });
    }

  ngOnInit(): void {
    this.ProductDemandReport= false;
    this.SalesOrderDiscountCode= false;
    this.SalesReport= false;
    this.SaleOrdersByChannel= false;
    this.OpenOrderItemsReport= false;
    this.CouponSalesReport= false;
    this.Last3MonthsSalesReport= false;
    this.PurchasesbyPaymentMethodReport= false;
    this.PurchasesbyOrganizationReport= false;
    this.SalesbyStoreReport= false;
    this.PurchasesbyProductReport= false;

    this.getOriginFacilityIdReport();
    this.getProductStoreIdReport();
    this.getSalesChannel();
    this.getPartyId();
    this.getOrderStatusIdReport();
    this.getSalesChannel();
  }  

  onChange(event) {
    console.log(event, "event value")
    if (event) {
      if(event=='SalesbyStoreReport')
      {
        this.SalesbyStoreReport = true;

        this.ProductDemandReport= false;
        this.SalesOrderDiscountCode= false;
        this.SalesReport= false;
        this.SaleOrdersByChannel= false;
        this.OpenOrderItemsReport= false;
        this.CouponSalesReport= false;
        this.Last3MonthsSalesReport= false;
        this.PurchasesbyPaymentMethodReport= false;
        this.PurchasesbyOrganizationReport= false;
        this.PurchasesbyProductReport= false;
      }
      else if(event=='PurchasesbyProductReport')
      {
        this.PurchasesbyProductReport = true;

        this.ProductDemandReport= false;
        this.SalesOrderDiscountCode= false;
        this.SalesReport= false;
        this.SaleOrdersByChannel= false;
        this.OpenOrderItemsReport= false;
        this.CouponSalesReport= false;
        this.Last3MonthsSalesReport= false;
        this.PurchasesbyPaymentMethodReport= false;
        this.PurchasesbyOrganizationReport= false;
        this.SalesbyStoreReport= false;
      }
      else if(event=='OpenOrderItemsReport')
      {
        this.OpenOrderItemsReport = true;

        this.ProductDemandReport= false;
        this.SalesOrderDiscountCode= false;
        this.SalesReport= false;
        this.SaleOrdersByChannel= false;
        this.CouponSalesReport= false;
        this.Last3MonthsSalesReport= false;
        this.PurchasesbyPaymentMethodReport= false;
        this.PurchasesbyOrganizationReport= false;
        this.SalesbyStoreReport= false;
        this.PurchasesbyProductReport= false;
      }
      else if(event=='PurchasesbyPaymentMethodReport')
      {
        this.PurchasesbyPaymentMethodReport = true;

        this.ProductDemandReport= false;
        this.SalesOrderDiscountCode= false;
        this.SalesReport= false;
        this.SaleOrdersByChannel= false;
        this.OpenOrderItemsReport= false;
        this.CouponSalesReport= false;
        this.Last3MonthsSalesReport= false;
        this.PurchasesbyOrganizationReport= false;
        this.SalesbyStoreReport= false;
        this.PurchasesbyProductReport= false;
      }
      else if(event=='PurchasesbyOrganizationReport')
      {
        this.PurchasesbyOrganizationReport = true;

        this.ProductDemandReport= false;
        this.SalesOrderDiscountCode= false;
        this.SalesReport= false;
        this.SaleOrdersByChannel= false;
        this.OpenOrderItemsReport= false;
        this.CouponSalesReport= false;
        this.Last3MonthsSalesReport= false;
        this.PurchasesbyPaymentMethodReport= false;
        this.SalesbyStoreReport= false;
        this.PurchasesbyProductReport= false;
      }
      else if(event=='Last3MonthsSalesReport')
      {
        this.Last3MonthsSalesReport = true;
      
        this.ProductDemandReport= false;
        this.SalesOrderDiscountCode= false;
        this.SalesReport= false;
        this.SaleOrdersByChannel= false;
        this.OpenOrderItemsReport= false;
        this.CouponSalesReport= false;
        this.PurchasesbyPaymentMethodReport= false;
        this.PurchasesbyOrganizationReport= false;
        this.SalesbyStoreReport= false;
        this.PurchasesbyProductReport= false;
      }
      else if(event=='CouponSalesReport')
      {
        this.CouponSalesReport = true;
      
        this.ProductDemandReport= false;
        this.SalesOrderDiscountCode= false;
        this.SalesReport= false;
        this.SaleOrdersByChannel= false;
        this.OpenOrderItemsReport= false;
        this.Last3MonthsSalesReport= false;
        this.PurchasesbyPaymentMethodReport= false;
        this.PurchasesbyOrganizationReport= false;
        this.SalesbyStoreReport= false;
        this.PurchasesbyProductReport= false;
      }
      else if(event=='SaleOrdersByChannel')
      {
        this.SaleOrdersByChannel = true;

        this.ProductDemandReport= false;
        this.SalesOrderDiscountCode= false;
        this.SalesReport= false;
        this.OpenOrderItemsReport= false;
        this.CouponSalesReport= false;
        this.Last3MonthsSalesReport= false;
        this.PurchasesbyPaymentMethodReport= false;
        this.PurchasesbyOrganizationReport= false;
        this.SalesbyStoreReport= false;
        this.PurchasesbyProductReport= false;
      }
      else if(event=='SalesReport')
      {
        this.SalesReport = true;

        this.ProductDemandReport= false;
        this.SalesOrderDiscountCode= false;
        this.SaleOrdersByChannel= false;
        this.OpenOrderItemsReport= false;
        this.CouponSalesReport= false;
        this.Last3MonthsSalesReport= false;
        this.PurchasesbyPaymentMethodReport= false;
        this.PurchasesbyOrganizationReport= false;
        this.SalesbyStoreReport= false;
        this.PurchasesbyProductReport= false;
      }
      else if(event=='SalesOrderDiscountCode')
      {
        this.SalesOrderDiscountCode = true;

        this.ProductDemandReport= false;
        this.SalesReport= false;
        this.SaleOrdersByChannel= false;
        this.OpenOrderItemsReport= false;
        this.CouponSalesReport= false;
        this.Last3MonthsSalesReport= false;
        this.PurchasesbyPaymentMethodReport= false;
        this.PurchasesbyOrganizationReport= false;
        this.SalesbyStoreReport= false;
        this.PurchasesbyProductReport= false;
      }
      else if(event=='ProductDemandReport')
      {
        this.ProductDemandReport = true;

        this.SalesOrderDiscountCode= false;
        this.SalesReport= false;
        this.SaleOrdersByChannel= false;
        this.OpenOrderItemsReport= false;
        this.CouponSalesReport= false;
        this.Last3MonthsSalesReport= false;
        this.PurchasesbyPaymentMethodReport= false;
        this.PurchasesbyOrganizationReport= false;
        this.SalesbyStoreReport= false;
        this.PurchasesbyProductReport= false;
      }
      else{
        this.ProductDemandReport= false;
        this.SalesOrderDiscountCode= false;
        this.SalesReport= false;
        this.SaleOrdersByChannel= false;
        this.OpenOrderItemsReport= false;
        this.CouponSalesReport= false;
        this.Last3MonthsSalesReport= false;
        this.PurchasesbyPaymentMethodReport= false;
        this.PurchasesbyOrganizationReport= false;
        this.SalesbyStoreReport= false;
        this.PurchasesbyProductReport= false;
      }
    }
  }
  reportPopUp() {
    this.spinner.show();
    if(this.reportForm.value.report=='SalesbyStoreReport')
    {
      this.SalesbyStoreReport = true;
    }
    else if(this.reportForm.value.report=='PurchasesbyProductReport')
    {
      this.PurchasesbyProductReport = true;
    }
    else if(this.reportForm.value.report=='OpenOrderItemsReport')
    {
      this.OpenOrderItemsReport = true;
      
    }
    else if(this.reportForm.value.report=='PurchasesbyPaymentMethodReport')
    {
      this.PurchasesbyPaymentMethodReport = true;
    }
    else if(this.reportForm.value.report=='PurchasesbyOrganizationReport')
    {
      this.PurchasesbyOrganizationReport = true;
      
    }
    else if(this.reportForm.value.report=='Last3MonthsSalesReport')
    {
      this.Last3MonthsSalesReport = true;
    }
    else if(this.reportForm.value.report=='CouponSalesReport')
    {
      this.CouponSalesReport = true;
    }
    else if(this.reportForm.value.report=='SaleOrdersByChannel')
    {
      this.SaleOrdersByChannel = true;
    }
    else if(this.reportForm.value.report=='SalesReport')
    {
      this.SalesReport = true;
    }
    else if(this.reportForm.value.report=='SalesOrderDiscountCode')
    {
      this.SalesOrderDiscountCode = true;
    }
    else(this.reportForm.value.report=='ProductDemandReport')
    {
      this.ProductDemandReport = true;
    }
  }
  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/order/main-order']);
  }
  generateCouponSalesReport() {
    this.spinner.show();
    
    this.myContactsService.generateCouponSalesReport().subscribe(res => {
      const blob = new Blob([res], { type:'application/pdf'});
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      this.spinner.hide();
      link.download = "CouponSalesReport.pdf";
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(() => {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });

  } 
  generateProductDemandReport() {
    this.spinner.show();
    
    this.myContactsService.generateProductDemandReport(this.ProductDemandReportForm.value.ProductStoreID).subscribe(res => {
      const blob = new Blob([res], { type:'application/pdf'});
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      this.spinner.hide();
      link.download = "ProductDemandReport.pdf";
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(() => {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });

  }  
  generateSalesReport() {
    this.spinner.show();
    const fromDate = this.SalesReportForm.get('FromDate').value;
    this.newFromDateValue = this.datePipe.transform(fromDate, "yyyy-MM-dd hh:mm:ss");

    this.myContactsService.generateSalesReport(  this.SalesReportForm.value.FormatType,
      this.newFromDateValue,this.SalesReportForm.value.ReportBy,   
     ).subscribe(res => {
      const blob = new Blob([res], { type:'application/pdf'});
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      this.spinner.hide();
      link.download = "SalesReport.pdf";
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(() => {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });

  } 
  generateSalesOrderDiscountCode() {
    this.spinner.show();
    
    this.myContactsService.generateSalesOrderDiscountCode().subscribe(res => {
      const blob = new Blob([res], { type:'application/pdf'});
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      this.spinner.hide();
      link.download = "OrdersByChannel.pdf";
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(() => {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });

  } 
  generateLastThreeMonthsSalesReport() {
    this.spinner.show();
    
    this.myContactsService.generateLastThreeMonthsSalesReport().subscribe(res => {
      const blob = new Blob([res], { type:'application/pdf'});
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      this.spinner.hide();
      link.download = "Last3MonthsSalesReport.pdf";
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(() => {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });

  } 
  detailOpenOrderPage(){
    this._Router.navigate(['order/detail-open-order-item-report'])
  }
  generateSalesOrderByChannelPdf() {
    this.spinner.show();
    
    this.myContactsService.generateSalesOrderByChannelPdf(
      this.SaleOrdersByChannelForm.value.SalesChannel
     ).subscribe(res => {
      const blob = new Blob([res], { type:'application/pdf'});
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      this.spinner.hide();
      link.download = "OrdersByChannel.pdf";
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(() => {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });

  } 
  generatePurchasesByOrganizationReportPdf() {
    this.spinner.show();
    const fromDate = this.PurchasesbyOrganizationReportForm.get('FromDate').value;
    this.newFromDateValue = this.datePipe.transform(fromDate, "yyyy-MM-dd hh:mm:ss");

    const throughDate = this.PurchasesbyOrganizationReportForm.get('ThroughDate').value;
    this.ThroughDateValue = this.datePipe.transform(throughDate, "yyyy-MM-dd hh:mm:ss");
    
    this.myContactsService.generatePurchasesByOrganizationReportPdf(this.newFromDateValue,
      this.PurchasesbyOrganizationReportForm.value.FromParty,
      this.PurchasesbyOrganizationReportForm.value.OrderStatusID,
      this.ThroughDateValue,
      this.PurchasesbyOrganizationReportForm.value.ToPartyID,
     
     ).subscribe(res => {
      const blob = new Blob([res], { type:'application/pdf'});
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      this.spinner.hide();
      link.download = "PurchasesByOrganizationReport.pdf";
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(() => {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });

  }  
  generatePurchasesByPaymentMethodReportPdf() {
    this.spinner.show();
    const fromDate = this.PurchasesbyPaymentMethodReportForm.get('FromDate').value;
    this.newFromDateValue = this.datePipe.transform(fromDate, "yyyy-MM-dd hh:mm:ss");

    const throughDate = this.PurchasesbyPaymentMethodReportForm.get('ThroughDate').value;
    this.ThroughDateValue = this.datePipe.transform(throughDate, "yyyy-MM-dd hh:mm:ss");
   
    this.myContactsService.generatePurchasesByPaymentMethodReportPdf(this.newFromDateValue,
      this.PurchasesbyPaymentMethodReportForm.value.OrderTypeID,
      this.PurchasesbyPaymentMethodReportForm.value.OriginFacilityID,
      this.PurchasesbyPaymentMethodReportForm.value.ProductStoreID,
      this.PurchasesbyPaymentMethodReportForm.value.OrderStatusID,
      this.PurchasesbyPaymentMethodReportForm.value.TerminalID,
      this.ThroughDateValue,
     ).subscribe(res => {
      const blob = new Blob([res], { type:'application/pdf'});
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      this.spinner.hide();
      link.download = "OrderPurchaseReportPayment.pdf";
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(() => {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });

  } 
  generatePurchaseByProductReportPdf() {
    this.spinner.show();
    const fromDate = this.PurchasesbyProductReportForm.get('FromDate').value;
    this.newFromDateValue = this.datePipe.transform(fromDate, "yyyy-MM-dd hh:mm:ss");

    const throughDate = this.PurchasesbyProductReportForm.get('ThroughDate').value;
    this.ThroughDateValue = this.datePipe.transform(throughDate, "yyyy-MM-dd hh:mm:ss");

    this.myContactsService.generatePurchaseByProductReportPdf(this.newFromDateValue,
      this.PurchasesbyProductReportForm.value.OriginFacilityID,
      this.PurchasesbyProductReportForm.value.ProductStoreID,
      this.PurchasesbyProductReportForm.value.OrderStatusID,
      this.PurchasesbyProductReportForm.value.TerminalID,
      this.ThroughDateValue,
     ).subscribe(res => {
      const blob = new Blob([res], { type:'application/pdf'});
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      this.spinner.hide();
      link.download = "OrderPurchaseReportProduct.pdf";
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(() => {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });

  }
  onSalesByStoreReportPdf() {
    this.spinner.show();
    const fromDate = this.SalesbyStoreReportForm.get('FromDate').value;
    this.newFromDateValue = this.datePipe.transform(fromDate, "yyyy-MM-dd hh:mm:ss");

    const throughDate = this.SalesbyStoreReportForm.get('ThroughDate').value;
    this.ThroughDateValue = this.datePipe.transform(throughDate, "yyyy-MM-dd hh:mm:ss");

    this.myContactsService.generateSalesByStoreReportPdf(this.newFromDateValue,
      this.SalesbyStoreReportForm.value.OrderStatusID,
      this.SalesbyStoreReportForm.value.ProductStoreID,
      this.ThroughDateValue,
      this.SalesbyStoreReportForm.value.ToPartyID).subscribe(res => {
      const blob = new Blob([res], { type:'application/pdf'});
      if (window.navigator && window.navigator.msSaveOrOpenBlob) {
        window.navigator.msSaveOrOpenBlob(blob);
        return;
      }
      const data = window.URL.createObjectURL(blob);
      const link = document.createElement('a');
      link.href = data;
      this.spinner.hide();
      link.download = "SalesByStoreReport.pdf";
      link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
      setTimeout(() => {
        window.URL.revokeObjectURL(data);
        link.remove();
      }, 100);
    });

  }
  getPartyId() {
    this.spinner.show();
    this.myContactsService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
    
  }
  getOrderStatusIdReport() {
    this.spinner.show();
    this.myContactsService.getOrderStatusIdReport().subscribe(res => {
      this.StatusIdReport = res.data.orderStatusData ;
      this.spinner.hide();
      for (const value of this.StatusIdReport) {
        this.StatusIdReportArray.push({
          label: value.description,
          value: value.statusId
        })
      }
    })
  }
  getOriginFacilityIdReport() {
    this.spinner.show();
    this.myContactsService.getOriginFacilityIdReport().subscribe(res => {
      this.originFacilityIdReport = res.data[0].orderStatusData ;
      this.spinner.hide();
      for (const value of this.originFacilityIdReport) {
        this.originFacilityIdReportArray.push({
          label: value.facilityId,
          value: value.facilityId
        })
      }
    })
  }
  getProductStoreIdReport() {
    this.spinner.show();
    this.myContactsService.getProductStoreIdReport().subscribe(res => {
      this.productStoreIdReport = res.data.productStoreList ;
      this.spinner.hide();
      for (const value of this.productStoreIdReport) {
        this.productStoreIdReportArray.push({
          label: value.storeName,
          value: value.productStoreId
        })
      }
    })
  }
  getSalesChannel() {
    this.spinner.show();
    this.myContactsService.getSalesChannel().subscribe(res => {
      this.salesChannel = res.data[0].data ;
      this.spinner.hide();
      for (const value of this.salesChannel) {
        this.salesChannelArray.push({
          label: value.description,
          value: value.enumId
        })
      }
    })
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
