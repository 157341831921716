import { HttpClient } from '@angular/common/http';
import { Injectable } from '@angular/core';
import { Observable } from 'rxjs';
import { ApiService } from '../api.service';

@Injectable({
    providedIn: 'root'
})
export class OrderManagementService {

    constructor(
        private _ApiService: ApiService,
        private _HttpClient: HttpClient
    ) {

    }
    findOrder(pagination: { pageNo: number, pageSize: number }, formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/order/lookup?pageNo=${pagination.pageNo}&pageSize=${pagination.pageSize}`, formData);
    }
    getOrderItems(): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/order/listItems`);
    }
    clearOrder(): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/order/clearOrder`, {});
    }
    finalizeOrder(checkoutPaymentId: string, finalizeMode: string, securityCode: string): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/order/finalizeOrderWithDefault?checkOutPaymentId=${checkoutPaymentId}&finalizeMode=${finalizeMode}&securityCode_9015=${securityCode}`, {});
    }
    recalculateOrder(formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/order/recalculateOrder`, formData);
    }


    removeCart(termIndex: string): Promise<any> {
        return this._ApiService.deleteRequest<any>(`/phyCorp/control/api/v1/order/orderTerm?termIndex=${termIndex}`);
    }
    getSalesChannel(): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/order/salesChannel`);
    }
    getOrderShippingMethod(): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/order/shippingMethods`);
    }
    createShippingMethod(finalizeMode: string, formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/order/shippingOptions?finalizeMode=${finalizeMode}`, formData);
    }
    getOrderTermType(): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/order/orderTermType`);
    }
    createOrderTerm(formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/order/orderTerm`, formData);
    }
    getOrderTerms(): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/order/orderTerms`);
    }
    getOrderTermByIndex(termIndex: string): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/order/orderTerm?termIndex=${termIndex}`);
    }
    setBilling(createNew: string): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/order/paymentSetting?createNew=${createNew}`, {});
    }
    processOrder(): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/order/processOrder`, {});
    }
    createNewShipGroup(createNewShipGroup: string): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/order/findOrder/shipGroup?createNewShipGroup=${createNewShipGroup}`, {});
    }
    finalizeOrderWithDynamicBody(formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/order/finalizeOrder`, formData);
    }
    reviewOrder(): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/order/reviewOrder`);
    }
    initOrderEntry(formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/order/initorderentry`, formData);
    }
    addItems(formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/order/orderItems`, formData);
    }
    orderSummary(orderId: string): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/order/orderSummary/${orderId}`);
    }
    setStatus(orderId: string, statusId: string): Promise<any> {
        return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/order/findOrder/changeOrderStatus?orderId=${orderId}&statusId=${statusId}`, {});
    }
    addPaymentMethodToOrder(maxAmount: string, orderId: string,paymentMethodId:string): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/order/addPaymentMethodToOrder?maxAmount=${maxAmount}&orderId=${orderId}&paymentMethodId=${paymentMethodId}`, {});
    }
    
    addRemoveAdditionalParty(createNew: boolean, formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/order/additionalParty?create=${createNew}`, formData);
    }
    getAllPromotion(): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/order/promotions`);
    }
    getPromotionById(productPromoId: string): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/order/promotionDetail?productPromoId=${productPromoId}`);
    }
    addPromo(productPromoId: string): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/order/findOrder/promotions?productPromoCodeId=${productPromoId}`, {});
    }
    getReceivePayment(orderId: string): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/order/receivePayment/${orderId}`);
    }
    createReceivePayment(orderId: string, partyId: string, formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/order/receivePayment/${orderId}?partyId=${partyId}`, formData);
    }
    getEventPurposeTypeId(): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/order/findOrder/communicationEventPurposeTypeId`);
    }
    getNoteList(orderId: string): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/order/findOrder/orderNote?orderId=${orderId}`);
    }
    createNote(formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/order/findOrder/orderNote`, formData);
    }
    updateNote(noteId: string, formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/order/findOrder/orderNote?noteId=${noteId}`, formData);
    }
    deleteNote(orderId: string, noteId: string): Promise<any> {
        return this._ApiService.deleteRequest<any>(`/phyCorp/control/api/v1/order/findOrder/orderNote?noteId=${noteId}&orderId=${orderId}`);
    }
    createConversation(formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/order/findOrder/newConversation`, formData);
    }
    createShippingAddress(formData: any): Promise<any> {
        return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/order/shipAddress`, formData);
    }
    getOrderPDF(orderId: string): Observable<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/order/findOrder/orderPdf?orderId=${orderId}`, { responseType: 'blob' });
    }
    getConversationList(orderId: string): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/order/findOrder/newConversation?orderId=${orderId}`);
    }
    getItemType(): Promise<any> {
        return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/order/listItems`);
    }
    generatePickingPDF(facilityId: string, orderId: string): Observable<any> {
        return this._HttpClient.get(`/phyCorp/control/api/v1/facility/picking/printPicking?facilityId=${facilityId}&maxNumberOfOrdersToPrint=1&orderId=${orderId}`, { responseType: 'blob' });
    }
}