<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
        
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
              <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">Bom Simulation



                </span>
                <span class="color-black pl-1"> >Add New Bom Simulation




                </span>

                <span class="">

                    <button type="submit" (click)="homeButton();"
                        class=" btn btn-outline-secondary mr-2">Home</button>
            
                    <button type="submit" (click)="cancelSubmit();"
                        class="btn btn-danger buttonclass ">Back</button>
                </span>

            </div>
            <div class="w3-card-4 classCard">
                <div class="container-fluid">
                  <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                      <li [ngClass]="activeCategoryMain==1?'active':''" (click)="BomList()"><a>
                        Bom Simulation</a></li>
                      <li [ngClass]="activeCategoryMain==2?'active':''" (click)="ManufacturingRules()"><a>
                        Manufacturing Rules</a></li>
                       
                     
                  </ul>
              </div>
            <div class=" bg-white color-grey" style="padding: 15px 15px;">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">

                            <li [ngClass]="activeCategory==2?'active':''"><a>
                                    Add Bom Simulation




                                </a></li>



                        </ul>
                    </div>


                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100" [formGroup]="createSimulation">
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                   
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">
                                                        Product ID<span
                                                        style="color:red">*</span></label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown placeholder="Enter Product ID" filter="true"
                                                    formControlName="ProductID" 
                                                    [options]="productListArray" optionlabel="label">

                                                </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">
                                                        Bom Type<span
                                                        style="color:red">*</span></label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown placeholder="Enter Bom Type" filter="true"
                                                    formControlName="BomType" 
                                                    [options]="bomTypesArray" optionlabel="label">

                                                </p-dropdown>
                                                </div>
                                            </div>
                                        </div>
                                    
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">
                                                        From Date</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="date" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter From Date"
                                                    formControlName="FromDate">
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">
                                                        Quantity</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Quantity"
                                                    formControlName="Quantity">

                                                </div>
                                            </div>
                                        </div>
                                    
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">
                                                        Amount</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Amount"
                                                    formControlName="Amount">
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">
                                                        Type</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown placeholder="Enter Type" filter="true"
                                                    formControlName="Type"
                                                    [options]="InitailBomSimualtionTypeArray" optionlabel="label">

                                                </p-dropdown>
                                                </div>
                                            </div>
                                        </div>
                                     
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group rightForm">
                                                    <label for="exampleInputEmail1">
                                                        Facility ID</label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown placeholder="Enter Facility ID" filter="true"
                                                    formControlName="FacilityID"
                                                    [options]="MrpFacilitiesArray" optionlabel="label">

                                                </p-dropdown>
                                                </div>
                                                <div class="col-lg-3 form-group rightFormA">
                                                    <label for="exampleInputEmail1">
                                                        Currency Uom ID<span
                                                        style="color:red">*</span></label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <p-dropdown placeholder="Enter Currency Uom ID" filter="true"
                                                    formControlName="CurrencyUomID"
                                                    [options]="currencyArray" optionlabel="label">

                                                </p-dropdown>
                                                </div>
                                            </div>
                                        </div>
                                    

                                    </div>


                                </form>
                                <div class="main-submit-button" style="margin-left: 60%;">

                                    <button type="submit"  (click)="onSubmit()" class="btn btn-secondary submit-btn">Save</button>

                                </div>
                                <br>
                               
                            </div>
                           
                        </div>

                    </div>
                </div>
                <div class="w3-card-4 classCard" style="margin-top: 2%;">
                <div class="all-users-infomation font-13 font-medium border-top-grey own-user-select">
                    <div class="col-lg-12 col-12">
                        <div class="form-group">
                          
                            <div class="card own-account-table">
                                <p-table [value]="BomDataArray" [paginator]="true" [rows]="rows"
                                    scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]"
                                    [totalRecords]="total" [scrollable]="true"
                                    (sortFunction)="customSort($event)" [customSort]="true">
                                    <ng-template pTemplate="header">
                                        <tr style="background: #0d3769;">
                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="code">
                                                <div class="checkbox-align" style="color: white;">
                                                    <p-checkbox _ngcontent-egj-c329=""
                                                    styleclass="custom-checkbox" binary="true"
                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                    <div
                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                        <div class="ui-helper-hidden-accessible">
                                                            <input type="checkbox" name="undefined"
                                                                value="undefined"></div>
                                                        <div role="checkbox"
                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                            <span
                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                        </div>
                                                    </div>
                                                    <!---->
                                                </p-checkbox>

                                                   Product Level <p-sortIcon field="code">
                                                    </p-sortIcon>
                                                </div>
                                            </th>
                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                             <div style="color: white;"> 	Product ID <p-sortIcon field="name"></p-sortIcon>
                                             </div>
                                            </th>
                                          
                                         
                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                <div style="color: white;"> Virtual <p-sortIcon field="price"></p-sortIcon></div>
                                            </th>
                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                <div style="color: white;"> 	Product Name <p-sortIcon field="name"></p-sortIcon>
                                                </div>
                                               </th>
                                             
                                            
                                               <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                   <div style="color: white;"> Quantity<p-sortIcon field="price"></p-sortIcon></div>
                                               </th>
                                               <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                <div style="color: white;"> Action<p-sortIcon field="name"></p-sortIcon>
                                                </div>
                                               </th>
                                              
                                          
                                          
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-product>
                                        <tr>
                                            <td [ngStyle]="{'width':'180px'}"
                                               >
                                                <div class="checkbox-align">
                                                    <p-checkbox _ngcontent-egj-c329=""
                                                    styleclass="custom-checkbox" binary="true"
                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                    <div
                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                        <div class="ui-helper-hidden-accessible">
                                                            <input type="checkbox" name="undefined"
                                                                value="undefined"></div>
                                                        <div role="checkbox"
                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                            <span
                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                        </div>
                                                    </div>
                                                    <!---->
                                                </p-checkbox>
                                                   <span>{{product.product.billOfMaterialLevel}}</span>
                                                </div>
                                            </td>
                                            <td [ngStyle]="{'width':'170px'}">
                                              {{product.productId}}
                                            </td>
                                           
                                            <td [ngStyle]="{'width':'170px'}">
                                               
                                            </td>
                                            <td [ngStyle]="{'width':'170px'}">
                                                {{product.product.productName}}
                                            </td>
                                            <td [ngStyle]="{'width':'170px'}">
                                              
                                            </td>
                                           
                                            <td [ngStyle]="{'width':'170px'}">
                                                <svg class="hover" (click)="updateBOM(product.productId,product.productAssocTypeId);"
                                                xmlns="http://www.w3.org/2000/svg" 
                                                width="16" height="16" viewBox="0 0 21.223 21.222"><g transform="translate(0 -0.004)"><g transform="translate(0 1.52)"><path d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z" transform="translate(0 -34.137)"/></g><g transform="translate(4.548 0.004)"><g transform="translate(0 0)"><path d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                   transform="translate(-102.409 -0.004)"/></g></g></g></svg>
                                            </td>
                                           
                                            
                                            
                                   
                                          
                                    </ng-template>
                                </p-table>
                                <p class="paginate_data">
                                    View per page </p>
                            </div>
                            <br>
                            <div class="card own-account-table">
                                <p-table [value]="BomDataArray" [paginator]="true" [rows]="rows"
                                    scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]"
                                    [totalRecords]="total" [scrollable]="true"
                                    (sortFunction)="customSort($event)" [customSort]="true">
                                    <ng-template pTemplate="header">
                                        <tr style="background: #0d3769;">
                                            <th [ngStyle]="{'width':'180px'}" pSortableColumn="code">
                                                <div class="checkbox-align" style="color: white;">
                                                    <p-checkbox _ngcontent-egj-c329=""
                                                    styleclass="custom-checkbox" binary="true"
                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                    <div
                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                        <div class="ui-helper-hidden-accessible">
                                                            <input type="checkbox" name="undefined"
                                                                value="undefined"></div>
                                                        <div role="checkbox"
                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                            <span
                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                        </div>
                                                    </div>
                                                    <!---->
                                                </p-checkbox>

                                                
Product ID <p-sortIcon field="code">
                                                    </p-sortIcon>
                                                </div>
                                            </th>
                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                             <div style="color: white;"> 	Product Name <p-sortIcon field="name"></p-sortIcon>
                                             </div>
                                            </th>
                                          
                                         
                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                <div style="color: white;"> Quantity <p-sortIcon field="price"></p-sortIcon></div>
                                            </th>
                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                <div style="color: white;">QOH <p-sortIcon field="name"></p-sortIcon>
                                                </div>
                                               </th>
                                             
                                            
                                               <th [ngStyle]="{'width':'170px'}" pSortableColumn="price">
                                                   <div style="color: white;"> Weight<p-sortIcon field="price"></p-sortIcon></div>
                                               </th>
                                               <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                <div style="color: white;"> Cost<p-sortIcon field="name"></p-sortIcon>
                                                </div>
                                               </th>
                                               <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                <div style="color: white;"> Total Cost<p-sortIcon field="name"></p-sortIcon>
                                                </div>
                                               </th>
                                          
                                          
                                        </tr>
                                    </ng-template>
                                    <ng-template pTemplate="body" let-product>
                                        <tr>
                                            <td [ngStyle]="{'width':'180px'}"
                                               >
                                                <div class="checkbox-align">
                                                    <p-checkbox _ngcontent-egj-c329=""
                                                  styleclass="custom-checkbox" binary="true"
                                                  class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                  <div
                                                      class="custom-checkbox ui-chkbox ui-widget">
                                                      <div class="ui-helper-hidden-accessible">
                                                          <input type="checkbox" name="undefined"
                                                              value="undefined"></div>
                                                      <div role="checkbox"
                                                          class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                          <span
                                                              class="ui-chkbox-icon ui-clickable"></span>
                                                      </div>
                                                  </div>
                                                  <!---->
                                              </p-checkbox>
                                                   <span> {{product.productId}}</span>
                                                </div>
                                            </td>
                                            <td [ngStyle]="{'width':'170px'}">
                                                {{product.product.productName}}
                                            </td>
                                           
                                            <td [ngStyle]="{'width':'170px'}">
                                               
                                            </td>
                                            <td [ngStyle]="{'width':'170px'}">
                                                
                                            </td>
                                            <td [ngStyle]="{'width':'170px'}">
                                              
                                            </td>
                                            <td [ngStyle]="{'width':'170px'}">
                                               
                                            </td>
                                            <td [ngStyle]="{'width':'170px'}">
                                               
                                            </td>
                                           
                                            
                                            
                                   
                                          
                                    </ng-template>
                                </p-table>
                                <p class="paginate_data">
                                    View per page </p>
                            </div>
                        </div>
                    </div>
                </div>
                </div>
<br><br>
            </div>
            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>