import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder ,FormGroup,Validators} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSpinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';  import { Location } from '@angular/common';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MergeContactsService } from 'src/app/crm/contacts/merge-contacts/merge-contacts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { WarehouseService } from 'src/app/warehouse/warehouse/warehouse.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-find-requirements-main',
  templateUrl: './find-requirements-main.component.html',
  styleUrls: ['./find-requirements-main.component.css']
})
export class FindRequirementsMainComponent implements OnInit {
  @ViewChild('closebutton1') closebutton1;
  activeCategory = 2;
  resData: any;
  total=0;
 
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  findRequirementsForm: FormGroup;
  findRequirementList: any;
  requirementTypeId: any;
  requirementTypeIdArray: any[]=[];
  facilityArray: any[]=[];
  facility: any;
  requirementStatusId: any;
  requirementStatusIdArray: any[]=[];
  ProductId: any;
  ProductIdArray: any[]=[];
  suppliers: any[];
  createRequirementForm: FormGroup;
  fixedAssetId: any;
  fixedAssetIdArray: any[]=[];
  CustRequestIds: any;
  CustRequestIdsArray: any[]=[];
  orderItemTypeId: any;
  orderItemTypeIdArray: any[]=[];

  constructor(
    public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly addSkillService: AddSkillService,
    readonly accountsService: AccountsService,    private _location: Location,
    readonly myContactsService: MyContactsService,
    readonly _ToastrService: ToastrService,
    readonly _FormBuilder: FormBuilder,
    readonly _WarehouseService: WarehouseService,
    readonly toastr: ToastrService,
    readonly mergeContactsService: MergeContactsService,
    readonly spinner: NgxSpinnerService,
    readonly _Router: Router
  ) 
  {
    this.findRequirementsForm=this._FormBuilder.group({
      requirementId:"",
      requirementTypeId:"",
      facilityId:"",
      productId:"",
      supplier:"",
      status:"",
      description:"",
      fromDate:"",
      thruDate:"",
    })
    this.createRequirementForm=this._FormBuilder.group({
      requirementId:"",
       requirementTypeId:"",
       facilityId:"",
       groupName:"",
       deliverableId:"",
       fixedAssetId:"",
       productId:"",
       statusId:"",
       description:"",
       requirementStartDate:"",
       requiredByDate:"",
       estimatedBudget:"",
       quantity:"",
       useCase:"",
       reason:"",
       orderItemTypeId:"",
       facilityIdTo:"",
       custRequestId:"",
       custRequestItemSeqId:"",
      })
  }

  ngOnInit(): void {
    this.getFacilityId();
    this.getRequirementTypeId();
    this.getRequirementStatusId();
    this.getProductIds();
    this.getSuppliers();
    this.getfindRequirement();
    this.getfixedAssetID();
    this.getCustRequestIds();
    this.orderItemType();
  }
  homeButton(){
    this._Router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  orderItemType() {
    this.spinner.show();
    this.myContactsService.orderItemType().subscribe(res => {
      this.orderItemTypeId = res.data[0].orderItemType;
      this.spinner.hide();
      for (const value of this.orderItemTypeId) {
        this.orderItemTypeIdArray.push({
          label: value.description,
          value: value.orderItemTypeId
        })
      }
    })
  }
  getCustRequestIds() {
    this.spinner.show();
    this.addSkillService.getCustRequestIds().subscribe(res => {
      this.CustRequestIds = res.data[0].list;
      this.spinner.hide();
      for (const value of this.CustRequestIds) {
        this.CustRequestIdsArray.push({
          label: value.custRequestId,
          value: value.custRequestId 
        })
      }
    })
  }
  getfixedAssetID() {
    this.spinner.show();
    this.myContactsService.getFixedAssetIdRequirement().subscribe(res => {
      this.fixedAssetId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.fixedAssetId) {
        this.fixedAssetIdArray.push({
          label: value.fixedAssetId,
          value: value.fixedAssetId
        })
      }
    })
  }
  detailPage(requirementId) {
    this._Router.navigate(['/facilities/Planning/find-requirements-detail'], { queryParams: { requirementId: requirementId } });
}
  submit(): void {
    this.spinner.show();
    const requestData = {
      "custRequestId": this.createRequirementForm.value.custRequestId,
    "custRequestItemSeqId": this.createRequirementForm.value.custRequestItemSeqId,
    "deliverableId": this.createRequirementForm.value.deliverableId,
    "description": this.createRequirementForm.value.description,
    "estimatedBudget": this.createRequirementForm.value.estimatedBudget,
    "facilityId": this.createRequirementForm.value.facilityId,
    "facilityIdTo": this.createRequirementForm.value.facilityIdTo,
    "fixedAssetId": this.createRequirementForm.value.fixedAssetId,
    "groupName": this.createRequirementForm.value.groupName,
    "orderItemTypeId": this.createRequirementForm.value.orderItemTypeId,
    "productId": this.createRequirementForm.value.productId,
    "quantity": this.createRequirementForm.value.quantity,
    "reason": this.createRequirementForm.value.reason,
    "requiredByDate":this.datePipe.transform(this.createRequirementForm.value.requiredByDate,"yyyy-MM-dd hh:mm:ss") ,
    "requirementId": this.createRequirementForm.value.requirementId,
    "requirementStartDate":this.datePipe.transform(this.createRequirementForm.value.requirementStartDate,"yyyy-MM-dd hh:mm:ss"),
    "requirementTypeId": this.createRequirementForm.value.requirementTypeId,
    "statusId": this.createRequirementForm.value.statusId,
    "useCase":this.createRequirementForm.value.useCase,
    };
    this.myContactsService.createRequirementPlanning(requestData).subscribe((res: any) => {
  
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton1.nativeElement.click();

      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  
  }
  deleteRequirementPlanning(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req = {
          "requirementId":product.data.requirementId 

        }
        this.myContactsService.deleteRequirementPlanning(req).subscribe((res: any) => {
          this.getfindRequirement();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  getProductIds() {
    this.spinner.show();
    this.myContactsService.getProductIds().subscribe(res => {
      this.ProductId = res.data[0].getProductId;
      this.spinner.hide();
      for (const value of this.ProductId) {
        this.ProductIdArray.push({
          label: value.internalName,
          value: value.productId
        })
      }
    })
  }
  getRequirementStatusId() {
    this.spinner.show();
    this.myContactsService.getRequirementStatusId().subscribe(res => {
      this.requirementStatusId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.requirementStatusId) {
        this.requirementStatusIdArray.push({
          label: value.description,
          value: value.statusValue
        })
      }
    })
   
  }
  getRequirementTypeId() {
    this.spinner.show();
    this.myContactsService.getRequirementTypeId().subscribe(res => {
      this.requirementTypeId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.requirementTypeId) {
        this.requirementTypeIdArray.push({
          label: value.description,
          value: value.requirementTypeId
        })
      }
    })
   
  }
  getFacilityId() {
    this.spinner.show();
    this.myContactsService.getFacilityId().subscribe(res => {
      this.facility = res.data[0].getFacilityId;
      this.spinner.hide();
      for (const value of this.facility) {
        this.facilityArray.push({
          label: value.facilityName,
          value: value.facilityId
        })
      }
    })
   
  }
  getSuppliers(): void {

    this.spinner.show();
    this._WarehouseService.getSuppliers()
        .then(data => {
            this.spinner.hide();
            this.suppliers = data.data.map(value => {
                return {
                    label: value.partyId,
                    value: value.partyId
                };
            });
        });
        
}
  reset() {
    this.findRequirementsForm.reset();
    this.getfindRequirement();

  }
  getfindRequirement() {
    this.spinner.show();
    const formData = {
      "description": "",
  "facilityId": "",
  "groupName": "",
  "productId": "",
  "requiredByDate": "",
  "requirementId": "",
  "requirementTypeId": "",
  "statusId": ""
    }

    this.mergeContactsService.findRequirement(formData).subscribe(res => {
      this.findRequirementList = res.data;
      this.spinner.hide();

    })
  } 
  findRequirement() {
    this.spinner.show();
    const formData = {
      "description": this.findRequirementsForm.value.description ? this.findRequirementsForm.value.description : '',
      "facilityId": this.findRequirementsForm.value.facilityId ? this.findRequirementsForm.value.facilityId : '',
      "groupName": this.findRequirementsForm.value.supplier ? this.findRequirementsForm.value.supplier : '',
      "productId":  this.findRequirementsForm.value.productId ? this.findRequirementsForm.value.productId : '',
      "requiredByDate":  "" ? "" : '',
      "requirementId":  this.findRequirementsForm.value.requirementId ? this.findRequirementsForm.value.requirementId : '',
      "requirementTypeId": this.findRequirementsForm.value.requirementTypeId ? this.findRequirementsForm.value.requirementTypeId : '',
      "statusId":  this.findRequirementsForm.value.status ? this.findRequirementsForm.value.status : '',
    }
    this.mergeContactsService.findRequirement(formData).subscribe(res => {
      this.findRequirementList = res.data;
      this.spinner.hide();

    })
  }
  openInventory()
  {
    this._Router.navigate(['/facilities/Planning']);

  }
  openFindRequirements()
  {
    this._Router.navigate(['/facilities/Planning/find-requirements']);
  }
  openRequirements()
  {
    this._Router.navigate(['/facilities/Planning/open-requirements']);
  }
  openProductRequirements()
  {
    this._Router.navigate(['/facilities/Planning/product-requirements']);
  }
  openInternalRequirements()
  {
    this._Router.navigate(['/facilities/Planning/internal-requirements']);
  }
  openTransferRequirements()
  {
    this._Router.navigate(['/facilities/Planning/transfer-requirements']);
  }
  openSalesOrderHistory()
  {
    this._Router.navigate(['/facilities/Planning/upload-sales-order-history']);
  }
  openRunMRP()
  {
    this._Router.navigate(['/facilities/Planning/run-mrp']);
  }
  openViewMRP()
  {
    this._Router.navigate(['/facilities/Planning/view-mrp']);
  }
  openConfigureWarehouses()
  {
    this._Router.navigate(['/facilities/Planning/configure-warehouse']);
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
}



