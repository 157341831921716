import { FormControl,Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class CreateTimeSheetModel {

  constructor(
   
  ) { }

  mapDataToModel(data: any = undefined) {
    const form_data = ({
        approvedByUserLoginId:(data && data.approvedByUserLoginId) ? data.approvedByUserLoginId : null,
        clientPartyId:(data && data.clientPartyId) ? data.clientPartyId : null,
        comments:(data && data.comments) ? data.comments : null,
        fromDate:(data && data.fromDate) ? data.fromDate : null,
        partyId:(data && data.partyId) ? data.partyId : null,
        statusId:(data && data.statusId) ? data.statusId : null,
        thruDate:(data && data.thruDate) ? data.thruDate : null,
    });
    return this.getFormGroup(form_data);
  }
  getFormGroup(data:any): any {
   return ({
    approvedByUserLoginId: new FormControl(data.approvedByUserLoginId),
    clientPartyId: new FormControl(data.clientPartyId),
    comments: new FormControl(data.comments),
    fromDate: new FormControl(data.fromDate),
    partyId: new FormControl(data.partyId,[Validators.required]),
    statusId: new FormControl(data.statusId,[Validators.required]),
    thruDate: new FormControl(data.thruDate),
   });
  }

  preparePayload(payload:any): any {
    return payload;
  }
}
