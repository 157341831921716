import { Component, OnInit ,ViewChild} from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { SortEvent } from 'primeng/api';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';

@Component({
  selector: 'app-dataresource-find',
  templateUrl: './dataresource-find.component.html',
  styleUrls: ['./dataresource-find.component.css']
})
export class DataresourceFindComponent implements OnInit {
  activeCategory= 2;
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  resData: any;
  total=0;
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  navigateData: any;
  navigateDataArray: any[]=[];
  navigateDataValue: any;
  navigateForm: FormGroup;
  DataResourceC: any;

  constructor(
    readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
   
    readonly _ToastrService: ToastrService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly toastr: ToastrService,
    private _location: Location,
  )
  {
    this.navigateForm = this._FormBuilder.group({
      navigate: "",
    });
  }

  ngOnInit(): void {
    this.getnavigateDataResource();
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/hub/Dashboard']);
  }
  navigateDataResourceC() {
    this.spinner.show();
    this.myContactsService.navigateDataResourceC(this.navigateForm.value.navigate).subscribe(res => {
      this.DataResourceC = res.data.navigateDataResource;

      this.spinner.hide();
     
    })
  }
  getnavigateDataResource() {
    this.spinner.show();
    this.myContactsService.navigateDataResource().subscribe(res => {
      this.navigateData = res.data.navigateDataResource;
      this.spinner.hide();
      for (const value of this.navigateData) {
        this.navigateDataArray.push({
          label: value.dataCategoryId,
          value: value.dataCategoryId
        })
      }
    })
    
  }
  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null) {
            result = -1;
        }
        else if (value1 != null && value2 == null) {
            result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
        }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });

    
}

ngOnDestroy(): void {
  this.spinner.hide();
    }

changeactiveCategory(tab: number)
{
  this.activeCategory = tab;

}

navigateOpen()
  {
    this._Router.navigate(['/content/data-resource/dataresource-navigate-page']);
  }

  findOpen()
  {
    this._Router.navigate(['/content/data-resource']);
  }

}
