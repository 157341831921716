<div class="container-fluid main-container-wrapper">
  <div class="row">
    <div class="col-12">
      <div class=" bg-white color-grey create-new-table">
        <div style="margin: 4%;font-size: 11px;">
          <ng-wizard [config]="config">

            <ng-wizard-step [title]="'Step 1'" [description]="'Purchase Order'"> 
            </ng-wizard-step>

            <ng-wizard-step [title]="'Step 2'" [description]="' Enter Order Currency, Agreements, and Ship Dates '">  
            </ng-wizard-step>
           
          <ng-wizard-step [title]="'Step 3'" [description]="'Create Sales Order'">     
            </ng-wizard-step>
            <ng-wizard-step [title]="'Step 4'" [description]="'Shipping'">     
            </ng-wizard-step>
            <ng-wizard-step [title]="'Step 5'" [description]="'Option'">     
            </ng-wizard-step>
            <ng-wizard-step [title]="'Step 6'" [description]="'Order Term'">     
            </ng-wizard-step>
           
            <ng-wizard-step [title]="'Step 8'" [description]="'Parties'">     
            </ng-wizard-step>
            <ng-wizard-step [title]="'Step 9'" [description]="'Review Order'">     
            </ng-wizard-step>
           
          
         
          </ng-wizard>
        </div>
        <div>
          <div class="container-fluid">
            <div class="row">
              <div class="w-100 port-header-contact create-lead font-medium mb-0 text-white">
                <div class="w-100">
                  <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                    <div class="panel panel-default">
                      <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians">
              
                          <li [ngClass]="activeCategory==2?'active':''" ><a>
                            Enter Order Currency, Agreements, and Ship Dates</a></li>
                           
                         
                        </ul>
                      </div>
                     
                      <div id="seo-collapse" class="port-body custom-group-wrapper collpase show inner-sections">
                        <div class="panel-body">
                          <div class="mt-2">
                            <div class="">
                              <div class="row">
                                <form class="w-100">
                                  <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-4 col-12">
                                      <div class="form-group">
                                        <label class="common-labels">Order Id </label>
                                        <input type="text" class="form-control" [(ngModel)]="poContinue.orderId"
                                          [ngModelOptions]="{standalone: true}" placeholder="Enter Order Id">
                                      </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                      <div class="form-group">
                                        <label class="common-labels">Order Name </label>
                                        <input type="text" class="form-control"
                                          [(ngModel)]="poContinue.orderName"
                                          [ngModelOptions]="{standalone: true}" placeholder="Enter Order Name	">
                                      </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                      <div class="form-group">
                                        <label class="common-labels">Set a currency for this order
                                        </label>
                                        <p-dropdown filter="true" [options]="currencyArray" appendTo="body"
                                          [ngModelOptions]="{standalone: true}" [(ngModel)]="poContinue.currencyUomId"
                                          optionlabel="label" placeholder="Select Currency"></p-dropdown>
                                      </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                      <div class="form-group">
                                        <label class="common-labels">Choose catalogue
                                        </label>
                                        <p-dropdown filter="true" [options]="catalogueArray" appendTo="body"
                                          [ngModelOptions]="{standalone: true}"
                                          [(ngModel)]="poContinue.CURRENT_CATALOG_ID" optionlabel="label"
                                          placeholder="Select Catalogue"></p-dropdown>
                                      </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                      <div class="form-group">
                                        <label class="common-labels">WorkEffort ID
                                        </label>
                                        <p-dropdown filter="true" [options]="workEffortArray" appendTo="body"
                                          [ngModelOptions]="{standalone: true}" [(ngModel)]="poContinue.workEffortId"
                                          optionlabel="label" placeholder="Select WorkEffort ID"></p-dropdown>
                                      </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                      <div class="form-group">
                                        <label for="exampleInputEmail1">Default Ship After Date </label>
                                        <div class="">
                                          <p-calendar showSeconds="true" placholder="Select Date" showTime="true"
                                            [(ngModel)]="poContinue.shipAfterDate" [ngModelOptions]="{standalone: true}"
                                            hourFormat="24" dateFormat="dd.mm.yy"></p-calendar>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                      <div class="form-group">
                                        <label for="exampleInputEmail1">Default Ship Before Date </label>
                                        <div class="">
                                          <p-calendar showSeconds="true" placholder="Select Date" showTime="true"
                                            [(ngModel)]="poContinue.shipBeforeDate" [ngModelOptions]="{standalone: true}"
                                            hourFormat="24" dateFormat="dd.mm.yy"></p-calendar>
                                        </div>
                                      </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                      <div class="form-group">
                                        <label for="exampleInputEmail1">Cancel Back Order Date</label>
                                        <div class="">
                                          <p-calendar showSeconds="true" placholder="Select Date" showTime="true"
                                            [(ngModel)]="poContinue.reserveAfterDate" [ngModelOptions]="{standalone: true}"
                                            hourFormat="24" dateFormat="dd.mm.yy"></p-calendar>
                                        </div>
                                      </div>
                                    </div>
                                  </div>
                                  <div class="main-submit-button" style="margin-left: 80%;">
                                    <button type="submit"   (click)="continuePurchaseOrderCreation()" class="btn btn-secondary submit-btn">Continue</button>
                                  
                                </div>
                                </form>
                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                    </div>

                  </div>
                </div>
              </div>
            </div>

          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner></ngx-spinner> 