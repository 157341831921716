import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
@Component({
  selector: 'app-icalendar',
  templateUrl: './icalendar.component.html',
  styleUrls: ['./icalendar.component.css']
})
export class IcalendarComponent implements OnInit {

  @ViewChild('closebutton') closebutton;
  activeCategory = 9;
  resData: any;
  total = 0;

  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  addiCalender: FormGroup;
  calendarvisibilityArray: { label: string; value: string; }[];
  personId: any;
  partyIdArray: any[]=[];
  ICalenderdata: any;
  FromDate: string;
  ToDate: string;

  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;
  constructor( public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly addSkillService: AddSkillService,
    private _location: Location,
    
    readonly spinner: NgxSpinnerService,
    readonly myContactsService: MyContactsService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router) {

      this.calendarvisibilityArray = [

        {
          label: 'General, public access',
          value: 'WES_PUBLIC'
        },
        {
          label: 'Restricted, private access',
          value: 'WES_PRIVATE'
        },
        {
          label: 'Very restricted, confidential access',
          value: 'WES_CONFIDENTIAL'
        },
  
  
      ]; 
      this.addiCalender = this._FormBuilder.group({
        calendarOwner: "",
        calendarName: "",
        calendarVisibility: "",
        fromDate: "",
        toDate: "",
      });

     }

  ngOnInit(): void {

    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.WORKEFFORTMGR_ADMIN=="WORKEFFORTMGR_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.WORKEFFORTMGR_ROLE_VIEW=="WORKEFFORTMGR_ROLE_VIEW"){
      this.Hidebtn=true;
    }
    else if(this.userPermissions.WORKEFFORTMGR_ROLE_CREATE=="WORKEFFORTMGR_ROLE_CREATE"){
      this.HideCrudbtn=true;
    }
    this.getPartyId();
    this.getICalender();
  }
  detailedPage(ID) {
    this._Router.navigate(["/psa/work-effort/iCalendar-view/EditiCalendar"], { queryParams:{ workEffortId: ID} })
  }
  homeButton(){
     this._Router.navigate(['/psa/Dashboard'])
   
   }
   cancelSubmit(){
     this._location.back();
   }
   
  createICalendar(): void {
    this.spinner.show();
    const fromDate=this.addiCalender.get('fromDate').value;
    this.FromDate=this.datePipe.transform(fromDate,"yyyy-MM-dd hh:mm:ss");
    const toDate=this.addiCalender.get('toDate').value;
    this.ToDate=this.datePipe.transform(toDate,"yyyy-MM-dd hh:mm:ss");
    
     const formData ={
   
      "actualCompletionDate": this.ToDate,
      "actualStartDate": this.FromDate,
      "currentStatusId": "CAL_CANCELLED",
      "quickAssignPartyId": this.addiCalender.value.calendarOwner,
      "scopeEnumId": this.addiCalender.value.calendarVisibility,
      "timesheetId": "",
      "workEffortName": this.addiCalender.value.calendarName,
      "workEffortTypeId": "PUBLISH_PROPS" 
  
       
         
     }
     this.myContactsService.createICalendar(formData).subscribe((res: any) => {
      
       if (res.success) {
         this.toastr.success("Successfully Created");
         this.spinner.hide();
         this.closebutton.nativeElement.click();
         this.addiCalender.reset();
         this.getICalender();
         
        
       }
     }, (err) => {
      this.spinner.hide();
       for (const value of err.error.errors) {
         this.toastr.error(value.fieldName + ' ' + value.fieldError);
       }
     });
    
   }
  getICalender(){
    this.spinner.show();
    this.myContactsService.getICalender().subscribe((res:any)=> {
      this.ICalenderdata = res.data;
      this.spinner.hide();
    
    })   
   }
  TaskList(){
    this._Router.navigate(["/psa/work-effort/task-list"])
  }
  Calendar(){
    this._Router.navigate(["/psa/work-effort/calendar"])
  }
  MyTime(){
    this._Router.navigate(["/psa/work-effort/my-time-effort"])
  }
  RequestList(){
    this._Router.navigate(["/psa/work-effort/request-list-effort"])
  }
  WorkEffort(){
    this._Router.navigate(["/psa/work-effort/work-effort-effort"])
  }
  Timesheet(){
    this._Router.navigate(["/psa/work-effort/timesheet-effort"])
  }
  SubmittedJobs(){
    this._Router.navigate(["/psa/work-effort/submitted-jobs"])
  }
  iCalendar(){
    this._Router.navigate(["/psa/work-effort/icalendar"])
  }
 

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
   }
}

