import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { AddEmployeePositionService } from '../add-employee-position/add-employee-position.service';
import { ReallocationsService } from '../reallocation/reallocations.service';

import { Location } from '@angular/common';

@Component({
  selector: 'app-reallocation-summary',
  templateUrl: './reallocation-summary.component.html',
  styleUrls: ['./reallocation-summary.component.css']
})
export class ReallocationSummaryComponent implements OnInit {
  interviewId: any;
  pageNo = 1;
  total=0;
  rows = 50;
  pageSize= 100;
  personId: any;
  partyIdArray: any = [];
  internalOrganization: any;
  organizationArray: any = [];
  internalArray: any = [];
  public applicationSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize
  }
  activeCategory=1;
  reLocationsList: any;
  constructor(
    readonly activatedRoute:ActivatedRoute,
    readonly reallocationsService: ReallocationsService,
    readonly router:Router,
    readonly addEmployeePositionService: AddEmployeePositionService,
      private _location: Location,
      readonly spinner:NgxSpinnerService,
   
  ) { }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params=> {
      this.interviewId = params["interviewId"];
    });
    this.relocationsDetails();
  }

  homeButton(){
    this.router.navigate(['/hr/overview-hrm-main'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  relocationsDetails() {
    this.spinner.show();
    const relocation = {
      "emplPositionId":  this.interviewId,
      "emplPositionIdReportingTo": "",
      "internalOrganisation": "",
      "partyId": "",
      "reportingDateFrom": "",
      "reportingDateFromSearchType": "Contains",
      "reportingDateTo": "",
      "reportingDateToSearchType": "Contains"
    }
    this.reallocationsService.getRelocationList(this.applicationSize, relocation).subscribe((res: any) => {
      this.reLocationsList = res.data[0];
      this.spinner.hide();
    })
   
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
