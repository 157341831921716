<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Detail Portal Other Communication Summary</span>
                <button type="submit" (click)="cancelSubmit();"  class="btn btn-danger buttonclass" >Back</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard" >
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left: 9px;">
                        <li [ngClass]="activeCategory==1?'active':''" ><a>
                            Portal Other Communication Information</a></li>
                       
                    </ul>
                </div>
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                    <div class="w-100">
                                        <div class="panel-group">
                                            <div *ngIf="activeCategory==1">
                                               
                                                <div class="panel-body">
                                                    <div
                                                        class="all-users-infomation font-13 font-medium own-user-select">
                                                        <div class="color-black container">
                                                            <div class="row">
                                                                <div class="article-container">
                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Subject</p>
                                                                        <span
                                                                            class="color-grey pl-20 col-lg-10 col-12">{{this.subject}}</span>
                                                                    </div>
                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Type</p>
                                                                        <span
                                                                            class="color-grey pl-20 col-lg-10 col-12">{{this.communicationEventTypeDesc}}</span>
                                                                    </div>
                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Status Id</p>
                                                                        <span
                                                                            class="color-grey pl-20 col-lg-10 col-12">{{this.statusDesc}}</span>
                                                                    </div>
                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Party Id</p>
                                                                        <span
                                                                            class="color-grey pl-20 col-lg-10 col-12">{{this.partyIdFrom}}</span>
                                                                    </div>
                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Role Type Id</p>
                                                                        <span
                                                                            class="color-grey pl-20 col-lg-10 col-12">{{this.roleTypeId}}</span>
                                                                    </div>
                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Role Status Id</p>
                                                                        <span
                                                                            class="color-grey pl-20 col-lg-10 col-12">{{this.roleStatusId}}</span>
                                                                    </div>
                                                                    <div class="article">
                                                                        <p class="col-lg-6 col-12">Created Date</p>
                                                                        <span
                                                                            class="color-grey pl-20 col-lg-10 col-12">{{this.entryDate|date:'yyyy-MM-dd hh:mm:ss'}}</span>
                                                                    </div>
                                    
                                                                  
                                    
                                    
                                                                  
                                                                 
                                    
                                                                    
                                                                  </div>

                                                               
                                                               
                                                               
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                           
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner> 