import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { HeaderService } from 'src/app/header/header.service';
import { Location } from '@angular/common';

@Component({
  selector: 'app-detail-allocation-plan',
  templateUrl: './detail-allocation-plan.component.html',
  styleUrls: ['./detail-allocation-plan.component.css']
})
export class DetailAllocationPlanComponent implements OnInit {
  activeCategory = 2;
  resData: any;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  planId: any;
  allocationForm: any;
  ProductId: any;
  ProductIdArray: any[]=[];
  viewAllocationPlanList: any;
  itemList: any;
  summaryList: any;
  showApprove: boolean;
  constructor(public createEventFormService: CreateEventFormService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _ToastrService: ToastrService,
    readonly _Router: Router,
    private _location: Location,) { 
      this.allocationForm = this._FormBuilder.group({
        ProductID : [''],
        PlanName: [''],
      });
    }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params=>{
      this.planId = params.planId;
    })
    this.showApprove = true;
    this.getProductId();
    this.viewAllocationPlan();
  }
 
  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/order/main-order']);
  }
  changeAllocationPlanStatusApprove() {
    this.spinner.show();
  
    this.myContactsService.changeAllocationPlanStatus(this.planId,'ALLOC_PLAN_APPROVED').subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }
  changeAllocationPlanStatusCancel() {
    this.spinner.show();
    this.myContactsService.changeAllocationPlanStatus(this.planId,'ALLOC_PLAN_CANCELLED').subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Success");
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  }

  viewAllocationPlan() {
    this.spinner.show();
    this.myContactsService.viewAllocationPlan(this.planId).subscribe(res => {
      this.viewAllocationPlanList = res.data.allocationPlanInfo;
      this.itemList = res.data.allocationPlanInfo.itemList;
      this.summaryList = res.data.allocationPlanInfo.summaryMap ;
      this.spinner.hide();
      
      if(this.viewAllocationPlanList.statusId=='ALLOC_PLAN_APPROVED'||'ALLOC_PLAN_CANCELLED'){
        this.showApprove = false;
      }
    
    })
  }

  getProductId() {
    this.spinner.show();
    this.accountsService.getProductId().subscribe(res => {
      this.ProductId = res.data[0].Product;
      this.spinner.hide();
      for (const value of this.ProductId) {
        this.ProductIdArray.push({
          label: value.productId,
          value: value.productId
        })
      }
    })
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
