<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;">Party Security Group
                </span>
                <span class="color-black pl-1" > >Add Party Security Group
                </span>

                <button type="submit" (click)="cancelSubmit();"  class="btn btn-danger buttonclass" >Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''" *ngIf="!this.show" ><a>
                        Add Party Security Group
                    </a></li>
                    <li [ngClass]="activeCategory==2?'active':''" *ngIf="this.show" ><a>
                        Update Party Security Group
                    </a></li>
                       
                   
                    </ul>
                  </div>
               
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form  class="w-100" [formGroup]="createSecurity">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12" >
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm">
                                                <label for="exampleInputEmail1">
                                                    Classification Group ID</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown *ngIf="!this.show" [options]="groupIdIdArray" filter="true" formControlName="Group"
                                                placeholder="Enter  Group"
                                                name="Parent Type ID">
                                            </p-dropdown>
                                            <input type="text" *ngIf="this.show"  formControlName="Group"  class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp" placeholder="Enter Group" readonly>
                                          
                                            </div>
                                            <div class="col-lg-3 form-group rightFormA">
                                                <label for="exampleInputEmail1">From Date</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="date" *ngIf="!this.show"  formControlName="FromDate"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Resume Date">
                                                <input type="date"  *ngIf="this.show" formControlName="FromDate"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Resume Date" readonly>
                                            </div>
                                           
                                        </div>
                                      </div>
                                      <div class="col-lg-12" >
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm">
                                                <label for="exampleInputEmail1">
                                                    Through Date</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="date"  formControlName="ThroughDate"  class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Resume Text">
                                          
                                            </div>
                                           
                                           
                                        </div>
                                      </div>

                                </div>
                                

                            </form>
                            <div class="main-submit-button" style="margin-left: 80%;">
                               
                                <button type="submit" *ngIf="!this.show" (click)="onSubmit();"  class="btn btn-secondary submit-btn">Create</button>
                                 
                                <button type="submit" *ngIf="this.show" (click)="onUpdate();"  class="btn btn-secondary submit-btn">Update</button>
                                
                                
                                
                              
                            </div>
                        </div>
                    </div>

                </div>
                </div>

            </div>
           
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>




