import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { HeaderService } from 'src/app/header/header.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';


@Component({
  selector: 'app-forum-roles',
  templateUrl: './forum-roles.component.html',
  styleUrls: ['./forum-roles.component.css']
})
export class ForumRolesComponent implements OnInit {
  @ViewChild('closebutton')closebutton;
  @ViewChild('closebutton2')closebutton2;
  @ViewChild('closebutton3')closebutton3;
  @ViewChild('closebutton4')closebutton4;
  activeCategory = 4;
  resData: any;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  rolesForm: FormGroup;
  ContentRoleTypeId: any;
  ContentRoleTypeIdArray: any[]=[];
  personId: any;
  partyIdArray: any[]=[];
  forumGroupRoles: any;
  contentId: any;
  forumGroupId: any;
  show: boolean;
  updateContentRolesPat: any;
  updaterolesForm: FormGroup;
  fromDateCate: any;
  throughDateCate: any;
  product: any;
  throughDateCatePatch: string;
  fromDateCatePatch: string;
  constructor(public createEventFormService: CreateEventFormService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _ToastrService: ToastrService,
    readonly addSkillService: AddSkillService,
    readonly _Router: Router,
private _location: Location,
    ) 
{
this.rolesForm=this._FormBuilder.group({
      partyID:"",
      roleTypeID:"",
      fromDate:"",
      throughDate:"",
    })

    this.updaterolesForm=this._FormBuilder.group({
      partyID:"",
      roleTypeID:"",
      fromDate:"",
      throughDate:"",
    })
}

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.forumGroupId = params["forumGroupId"];
    });

    this.getContentRoleTypeId();
    this.getPartyId();
    this.getforumGroupRole();
  }


backWindow(){
  this._location.back();
}
  toDashboard()
{
this._Router.navigate(['/hub/Dashboard']);
}

  getContentRoleTypeId() {
    this.spinner.show();
    this.accountsService.getContentRoleTypeId().subscribe(res => {
      this.ContentRoleTypeId = res.data[0].node;
      this.spinner.hide();
      for (const value of this.ContentRoleTypeId) {
        this.ContentRoleTypeIdArray.push({
          label: value.description,
          value: value.roleTypeId
        })
      }
    })
  }

  getforumGroupRole() {
    this.spinner.show();
    this.accountsService.getforumGroupRole(this.forumGroupId).subscribe(res => {
      this.forumGroupRoles = res.data;
      this.spinner.hide();
    })
  }

getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
  }
  createForumGroupRole(): void {
    this.spinner.show();
    const requestData ={
      "contentId":this.forumGroupId,
      "forumGroupId":this.forumGroupId,
      "fromDate": this.datePipe.transform(this.rolesForm.value.fromDate, "yyyy-MM-dd hh:mm:ss"),
      "partyId":this.rolesForm.value.partyID,
      "roleTypeId":this.rolesForm.value.roleTypeID,
      "thruDate": this.datePipe.transform(this.rolesForm.value.throughDate, "yyyy-MM-dd hh:mm:ss"),
    }
     this.accountsService.createForumGroupRole(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Create Successfully");
        this.spinner.hide();
        this.getforumGroupRole();
        this.closebutton.nativeElement.click();
        this.rolesForm.reset();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }

updateContentRoles(): void {
    this.spinner.show();
    const requestData = {
      "contentId":this.forumGroupId ,
      "forumGroupId":this.forumGroupId,
      "fromDate": this.datePipe.transform(this.updaterolesForm.value.fromDate, "yyyy-MM-dd hh:mm:ss"), 
      "partyId": this.updaterolesForm.value.partyID,
      "roleTypeId": this.updaterolesForm.value.roleTypeID,
      "thruDate": this.datePipe.transform(this.updaterolesForm.value.throughDate, "yyyy-MM-dd hh:mm:ss"), 
}
    this.accountsService.updateContentRoles(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.getforumGroupRole();
        this.closebutton2.nativeElement.click();
        this.show=true;

      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }

  updateContentRolesPatch(product) {
    this.show=false;
    this.updateContentRolesPat=product;
    this.spinner.show();
    const throughDateCatePatch=product.thruDate;
    this.throughDateCatePatch=this.datePipe.transform(throughDateCatePatch,"yyyy-MM-dd");
    
    const fromDateCatePatch=product.fromDate;
    this.fromDateCatePatch=this.datePipe.transform(fromDateCatePatch,"yyyy-MM-dd");
    
      setTimeout(() => {
        const formValue = this.updaterolesForm;
        formValue.patchValue({
          partyID:this.updateContentRolesPat.partyId,
          roleTypeID:this.updateContentRolesPat.roleTypeId,
          fromDate:this.updateContentRolesPat.fromDate,
          throughDate:this.throughDateCatePatch,
        })
      }, 2000);
      this.spinner.hide();
  }

  deleteForumGroupRole(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        let req = {
          "contentId":this.forumGroupId,
          "contentIdTo": "",
          "forumGroupId": this.forumGroupId,
          "fromDate":product.fromDate ,
          "partyId": product.partyId,
          "roleTypeId": product.roleTypeId,
          "view_INDEX_1": "",
          "view_SIZE_1": ""
        }
        this.accountsService.deleteForumGroupRole(req).subscribe((res: any) => {
          if (res.success) {
            this.getforumGroupRole();
          }
          else {
            this.toastr.error("Error");
          }
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }

  openForumGroup()
  {
    this._Router.navigate(['/content/forum']);

  }
  openForums()
  {
    this._Router.navigate(['/content/forum/forums'], { queryParams: { forumGroupId: this.forumGroupId } });

  }
  openPurposes()
  {
    this._Router.navigate(['/content/forum/forum-purposes'], { queryParams: { forumGroupId: this.forumGroupId } });

  }
  openRoles()
  {
    this._Router.navigate(['/content/forum/forum-roles'], { queryParams: { forumGroupId: this.forumGroupId } });

  }


  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null) {
            result = -1;
        }
        else if (value1 != null && value2 == null) {
            result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
        }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });

  }

  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
