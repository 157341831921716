<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" style="cursor: pointer;" (click)="cancelSubmit()">Employee </span>
                <span class="color-black pl-1"> > {{show ? 'Update':'Create'}} Postal Address</span>
            </div>
            <div class="w3-card-4 classCard" >
                <header class="w3-container w3-blue">
                    <div class="header-tabs">
                      <h4 class=" common-styling" style="margin-left: 7px;">
                        {{show ? 'Update':'Create'}} Postal Address
                      </h4>
                   
                    </div>
                  </header>
            <div class=" bg-white color-grey">
              
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form *ngIf="addEmployeeFormService.postal_employee_form"
                                [formGroup]="addEmployeeFormService.postal_employee_form" class="w-100">
                                <div class="all-users-infomation font-13 font-medium own-user-select">


                                </div>
                                <div class="port-header-contact create-lead font-medium mb-0border-bottom-none mb-2"
                                    data-toggle="collapse" href="#collapseone">
                                    <span class="">
                                        Address
                                    </span>
                                </div>
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Party Id </label>
                                            </div>
                                            <div class="col-lg-2" >
                                                <input type="email" formControlName="partyId" class="form-control"
                                                id="exampleInputEmail1" aria-describedby="emailHelp" 
                                                disabled>
                                            </div>

                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                <label for="exampleInputEmail1">Address 1 <span
                                                    style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-2" >
                                                <input type="email" formControlName="address1" class="form-control"
                                                id="exampleInputEmail1" aria-describedby="emailHelp" >

                                            </div>
                                        
                                        </div>
                                    </div>
                                 
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Address 2 </label>
                                            </div>
                                            <div class="col-lg-2" >
                                                <input type="email" formControlName="address2" class="form-control"
                                                id="exampleInputEmail1" aria-describedby="emailHelp" >
                                            </div>

                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                <label for="exampleInputEmail1">City <span
                                                    style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-2" >
                                                <input type="email" formControlName="city" class="form-control"
                                                id="exampleInputEmail1" aria-describedby="emailHelp" >
                                            </div>
                                        
                                        </div>
                                    </div>
                                 
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">State</label>
                                            </div>
                                            <div class="col-lg-2" >
                                                <p-dropdown [options]="stateArray" formControlName="stateProvinceGeoId"
                                                filter="true" optionlabel="label">

                                            </p-dropdown>
                                            </div>

                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                <label for="exampleInputEmail1">Zip/Postal Code <span
                                                    style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-2" >
                                                <input type="email" formControlName="postalCode" class="form-control"
                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                placeholder="Enter Postal Code">
                                            </div>
                                        
                                        </div>
                                    </div>
                                   
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Country</label>
                                            </div>
                                            <div class="col-lg-2" >
                                                <p-dropdown [options]="array" (ngModelChange)="onCountryChange($event)"
                                                formControlName="countryGeoId" filter="true" optionlabel="label">

                                            </p-dropdown>
                                            </div>

                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                <label for="exampleInputEmail1">Solicitation</label>
                                            </div>
                                            <div class="col-lg-2" >
                                                <p-dropdown [options]="solicitation" formControlName="allowSolicitation"
                                                    filter="true" optionlabel="label">

                                                </p-dropdown>
                                            </div>
                                        
                                        </div>
                                    </div>
                                   
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">To Name </label>
                                            </div>
                                            <div class="col-lg-2" >
                                                <input type="email" formControlName="toName" class="form-control"
                                                id="exampleInputEmail1" aria-describedby="emailHelp" >
                                            </div>

                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                <label for="exampleInputEmail1">Alternative Name</label>
                                            </div>
                                            <div class="col-lg-2" >
                                                <input type="email" formControlName="attnName" class="form-control"
                                                id="exampleInputEmail1" aria-describedby="emailHelp" >
                                            </div>
                                        
                                        </div>
                                    </div>
                                 
                                    
                                  
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">


                                            <input type="hidden" formControlName="contactMechId" class="form-control"
                                                id="exampleInputEmail1" aria-describedby="emailHelp" >
                                        </div>
                                    </div>
                                </div>


                            </form>
                        </div>
                    </div>

                </div>

            </div>
            <div class="main-submit-button" style="    margin-right: 33%;">
                <button type="submit" *ngIf="show" (click)="onUpdate()"
                    class="btn btn-secondary submit-btn">Update</button>
                <button type="submit" *ngIf="!show" (click)="onSubmit()"
                    class="btn btn-secondary submit-btn">Submit</button>

                <button type="submit" class="btn btn-degault cncl-btn" (click)="cancelSubmit()">Cancel</button>
            </div>
            <br><br>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>