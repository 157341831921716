import { Component, EventEmitter, OnInit, Output, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { AccountingApService } from '../accounting-ap/accounting-ap.service';
import { DatePipe, Location } from '@angular/common';
@Component({
  selector: 'app-create-party-attribute',
  templateUrl: './create-party-attribute.component.html',
  styleUrls: ['./create-party-attribute.component.css']
})
export class CreatePartyAttributeComponent implements OnInit {
  createAttribue: FormGroup;
  activeCategory = 2;
  partyId: any;
  jid: any;
  jname: any;
  show = false;
  party: any;
  identification: any;
  identificationArray: any[] = [];
  partyValue: any;
  partyTypeId: any;
  resumeDate: string;
  resumeId: any;
  contentId: any;
  eresumeDate: any;
  resumeText: any;
  getEstimateFrom: string;
  contentIds: any[];
  attName: any;
  attDes: any;
  attValue: any;
  @Output() closeAttrModal: EventEmitter<any> = new EventEmitter<any>();
  @ViewChild('closebutton') closebutton;
  
  constructor(readonly _Router: Router, private _location: Location,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
    readonly _AccountingApService: AccountingApService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly _ToastrService: ToastrService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly accountsService: AccountsService,) {
    this.contentIds = [];
    this.createAttribue = this._FormBuilder.group({
      attrName: [''],
      attrDescription: [''],
      attrValue: [''],



    });

  }

  ngOnInit(): void {
  
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.party = params["party"];
      this.attName = params["attName"];
      this.attValue = params["attValue"];
      this.attDes = params["attDes"];
      
    });

    if (this.attName) {
      this.show = true;
      const fromdate = this.eresumeDate

      setTimeout(() => {
        const formValue = this.createAttribue;
        formValue.patchValue({
          attrName:  this.attName,
          attrDescription: this.attDes,
          attrValue:this.attValue,
        })
      }, 3000);
    }
    else {
      this.show = false;
    }

  }


  onSubmit() {
    this.spinner.show();

    const requestData = {
      "attrDescription": this.createAttribue.value.attrDescription,
      "attrName": this.createAttribue.value.attrName,
      "attrValue": this.createAttribue.value.attrValue,
      "partyId": this.party


    }
    this.accountsService.createPartyAttribute(requestData).subscribe((res: any) => {

      if (res.success) {
        this.modalClose();
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        //this._location.back();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }


      }
      else {
        this._ToastrService.error(err.error.message);
      }
    });

  }
  onUpdate() {
    this.spinner.show();

    const requestData = {
      "attrDescription": this.createAttribue.value.attrDescription,
      "attrName": this.createAttribue.value.attrName,
      "attrValue": this.createAttribue.value.attrValue,
      "partyId": this.party
    }
    this.accountsService.updatePartyAttribute(requestData).subscribe((res: any) => {

      if (res.success) {
        this._ToastrService.success("Updated Successfully");
        this.spinner.hide();
        this._location.back();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  } 

  cancelSubmit() {
    this._location.back();
  }

  modalClose(): void {
    this.closebutton.nativeElement.click();
    this.closeAttrModal.emit();
  }

}