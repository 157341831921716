import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { SortEvent } from 'primeng/api';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { DatePipe } from '@angular/common';
import { Location } from '@angular/common';

@Component({
  selector: 'app-software-main',
  templateUrl: './software-main.component.html',
  styleUrls: ['./software-main.component.css']
})
export class SoftwareMainComponent implements OnInit {
  activeCategory: number;
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  resData: any;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  compDocForm: FormGroup;
  findSoftwareForm: FormGroup;
  updateSoftwareForm: FormGroup;
  computerSoftwareData: any;
  classEnumId: any;
  classEnumIdArray: any[] = [];
  umoId: any;
  umoIdArray: any[] = [];
  calendarArray: { label: string; value: string; }[];
  parentFixedAssetId: any;
  parentFixedAssetIdArray: any[] = [];
  FacilityID: any;
  FacilityIDArray: any[] = [];
  FixedAssetIdListArray: any[] = [];
  personId: any;
  ProductIdArray: any[] = [];
  ProductId: any;
  LocationSeqId: any;
  LocationSeqIdArray: any[] = [];
  constructor(
    readonly _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,

    readonly router: Router,
    readonly _ToastrService: ToastrService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly toastr: ToastrService,
    private _location: Location,
  ) {
    this.findSoftwareForm = this._FormBuilder.group({
      fixedAssetID: "",
      classEnumID: "",
      fixedAssetName: "",
    })

    this.updateSoftwareForm = this._FormBuilder.group({
      fixedAssetID: "",
      fixedAssetParentID: "",
      instanceOfProductID: "",
      classEnumID: "",
      fixedAssetName: "",
      acquireOrderID: "",
      acquireOrderItemSeqID: "",
      dateAcquired: "",
      dateLastServiced: "",
      dateNextService: "",
      expectedEndOfLife: "",
      actualEndOfLife: "",
      productionCapacity: "",
      UOMId: "",
      calendar: "",
      serialNumber: "",
      locatedAtFacilityID: "",
      locatedAtLocationSeqID: "",
      salvageValue: "",
      depreciation: "",
      purchaseCost: "",
      purchaseCostUomID: "",


    })
    this.calendarArray = [
      {
        label: "[DEFAULT] Default calendar used when no specific calendar is defined",
        value: "DEFAULT"
      },
      {
        label: "[DEMO_CALENDAR] Demo Workcenter Calendar",
        value: "DEMO_CALENDAR"
      },
      {
        label: "[SUPPLIER] Calendar used for Re-Order date calculation for bought product",
        value: "SUPPLIER"
      },
    ]
  }

  ngOnInit(): void {
    this.getListComputerSoftware();
    this.LookupComputerSoftwareClassEnumId();
    this.getListComputerSoftware();
    this.getUmo();
    this.FixedAssetParentIDSoftware();
    this.getFacilityID();
    this.getFixedAssetIdList();
    this.getFacilityID();
    this.getProductId();
    this.getFixedAssetList();
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/ismgr/ismgr-Dashboard']);
  }
  
  getFixedAssetList() {
    this.spinner.show();
    this.accountsService.getFixedAssetList().subscribe(res => {
      this.LocationSeqId = res.data[0].node;
      this.spinner.hide();
      for (const value of this.LocationSeqId) {
        this.LocationSeqIdArray.push({
          label: value.locatedAtLocationSeqId,
          value: value.locatedAtLocationSeqId
        })
      }
    })
  }
  getProductId() {
    this.spinner.show();
    this.accountsService.getProductId().subscribe(res => {
      this.ProductId = res.data[0].Product;
      this.spinner.hide();
      for (const value of this.ProductId) {
        this.ProductIdArray.push({
          label: value.productId,
          value: value.productId
        })
      }
    })
  }
  getFixedAssetIdList() {
    this.spinner.show();
    this.myContactsService.getFixedAssetIdList().subscribe(res => {
      this.personId = res.data[0].node;
      this.spinner.hide();
      for (const value of this.personId) {
        this.FixedAssetIdListArray.push({
          label: value.fixedAssetName,
          value: value.fixedAssetId
        })
      }
    })
  }
  getFacilityID() {
    this.spinner.show();
    this.accountsService.getFacilityID().subscribe(res => {
      this.FacilityID = res.data[0].data;
      this.spinner.hide();
      for (const value of this.FacilityID) {
        this.FacilityIDArray.push({
          label: value.description,
          value: value.facilityId
        })
      }
    })
  }
  FixedAssetParentIDSoftware() {
    this.spinner.show();
    this.myContactsService.FixedAssetParentIDSoftware().subscribe(res => {
      this.parentFixedAssetId = res.data[0].FixedAssetParentIDHardware;
      this.spinner.hide();
      for (const value of this.parentFixedAssetId) {
        this.parentFixedAssetIdArray.push({
          label: value.parentFixedAssetId,
          value: value.parentFixedAssetId
        })
      }
    })
  }
  getUmo() {
    this.spinner.show();
    this.myContactsService.getUmo().subscribe(res => {
      this.umoId = res.data[0].FixedAssetParentIDHardware;
      this.spinner.hide();
      for (const value of this.umoId) {
        this.umoIdArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })
  }
  LookupComputerSoftwareClassEnumId() {
    this.spinner.show();
    this.myContactsService.LookupComputerSoftwareClassEnumId().subscribe(res => {
      this.classEnumId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.classEnumId) {
        this.classEnumIdArray.push({
          label: value.description,
          value: value.enumId
        })
      }
    })
  }

  createComputerSoftware(): void {
    this.spinner.show();
    const requestData = {
      "acquireOrderId": this.updateSoftwareForm.value.acquireOrderID,
      "acquireOrderItemSeqId": this.updateSoftwareForm.value.acquireOrderItemSeqID,
      "actualEndOfLife": this.datePipe.transform(this.updateSoftwareForm.value.actualEndOfLife, "yyyy-MM-dd hh:mm:ss"),
      "calendarId": this.updateSoftwareForm.value.calendar,
      "classEnumId": this.updateSoftwareForm.value.classEnumID,
      "depreciation": this.updateSoftwareForm.value.depreciation,
      "fixedAssetId": this.updateSoftwareForm.value.fixedAssetID,
      "fixedAssetName": this.updateSoftwareForm.value.fixedAssetName,
      "fixedAssetTypeId": "COMPUTER_SOFTWARE",
      "dateAcquired": this.datePipe.transform(this.updateSoftwareForm.value.dateAcquired, "yyyy-MM-dd hh:mm:ss"),
      "dateLastServiced": this.datePipe.transform(this.updateSoftwareForm.value.dateLastServiced, "yyyy-MM-dd hh:mm:ss"),
      "dateNextService": this.datePipe.transform(this.updateSoftwareForm.value.dateNextService, "yyyy-MM-dd hh:mm:ss"),
      "expectedEndOfLife": this.datePipe.transform(this.updateSoftwareForm.value.expectedEndOfLife, "yyyy-MM-dd hh:mm:ss"),
      "instanceOfProductId": this.updateSoftwareForm.value.instanceOfProductID,
      "locatedAtFacilityId": this.updateSoftwareForm.value.locatedAtFacilityID,
      "locatedAtLocationSeqId": this.updateSoftwareForm.value.locatedAtLocationSeqID,
      "parentFixedAssetId": this.updateSoftwareForm.value.fixedAssetParentID,
      "partyId": "",
      "productionCapacity": this.updateSoftwareForm.value.productionCapacity,
      "purchaseCost": this.updateSoftwareForm.value.purchaseCost,
      "purchaseCostUomId": this.updateSoftwareForm.value.purchaseCostUomID,
      "roleTypeId": "",
      "salvageValue": this.updateSoftwareForm.value.salvageValue,
      "serialNumber": this.updateSoftwareForm.value.serialNumber,
      "uomId": this.updateSoftwareForm.value.UOMId
    }
    this.myContactsService.createComputerSoftware(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.updateSoftwareForm.reset();
        this.closebutton2.nativeElement.click();
        this.getListComputerSoftware();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  detail_page(id: any) {
    this._Router.navigate(['/ismgr/software/software-detail-page'],
      { queryParams: { fixedAssetId: id } });
  }
  getListComputerSoftware() {
    this.spinner.show();
    const formData = {

      "classEnumId": "",
      "fixedAssetId": "",
      "fixedAssetName": ""
    }

    this.myContactsService.ListComputerSoftware(formData).subscribe(res => {
      this.computerSoftwareData = res.data;
      this.spinner.hide();

    })
  }
  reset() {
    this.findSoftwareForm.reset();
    this.getListComputerSoftware();
  }
  ListComputerSoftware(): void {
    this.spinner.show();
    const requestData = {
      "classEnumId": this.findSoftwareForm.value.classEnumID ? this.findSoftwareForm.value.classEnumID : '',
      "fixedAssetId": this.findSoftwareForm.value.fixedAssetID ? this.findSoftwareForm.value.fixedAssetID : '',
      "fixedAssetName": this.findSoftwareForm.value.fixedAssetName ? this.findSoftwareForm.value.fixedAssetName : '',
    }
    this.myContactsService.ListComputerSoftware(requestData).subscribe((res: any) => {
      if (res.success) {
        this.computerSoftwareData = res.data;
        this.spinner.hide();
        this.findSoftwareForm.reset();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  toHardware() {
    this._Router.navigate(['/ismgr/hardware'])
  }

  toSoftware() {
    this._Router.navigate(['/ismgr/software']);
  }

  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });


  }

  ngOnDestroy(): void {
    this.spinner.hide();
  }

  changeactiveCategory(tab: number) {
    this.activeCategory = tab;

  }

  openNewHardware() {
    this._Router.navigate(['/ismgr/main/']);
  }



}
