import { FormControl,Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AddSkillModel {

  constructor(
   
  ) { }

  mapDataToModel(data: any = undefined) {
    const form_data = ({
      partyId:(data && data.partyId) ? data.partyId : null,
      rating:(data && data.rating) ? data.rating : null,
      skillLevel:(data && data.skillLevel) ? data.skillLevel : null,
      skillTypeId:(data && data.skillTypeId) ? data.skillTypeId : null,
      yearsExperience:(data && data.yearsExperience) ? data.yearsExperience : null,
    });
    return this.getFormGroup(form_data);
  }
  getFormGroup(data:any): any {
   return ({
    partyId: new FormControl(data.partyId,[Validators.required]),
    rating: new FormControl(data.rating),
    skillLevel: new FormControl(data.skillLevel),
    skillTypeId: new FormControl(data.skillTypeId,[Validators.required]),
    yearsExperience: new FormControl(data.yearsExperience),
   });
  }

  preparePayload(payload:any): any {
    return payload;
  }
}
