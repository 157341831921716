import { DatePipe } from '@angular/common';
import { Component, OnInit , ViewChild} from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { HeaderService } from 'src/app/header/header.service';
import Swal from 'sweetalert2';
@Component({
  selector: 'app-edit-product-subscriptions',
  templateUrl: './edit-product-subscriptions.component.html',
  styleUrls: ['./edit-product-subscriptions.component.css']
})
export class EditProductSubscriptionsComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton3') closebutton3;
  @ViewChild('closebutton4') closebutton4;
  activeCategory = 1;
  resData: any;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  newSubscription: any;
  editSubscription: any;
  editAttributes: any;
  subscriptionId: any;
  SubscriptionByIdList: any;
  showSubscription: boolean;
  orderById: any;
  orderByIdArray: any[] = [];
  ProductId: any;
  ProductIdArray: any[] = [];
  subscriptionTypeArray: any[] = [];
  subscriptionType: any;
  subscriptionResourceId: any;
  subscriptionResourceIdArray: any[] = [];
  personId: any;
  partyIdArray: any[] = [];
  yesNoArray: { label: string; value: string; }[];
  findSubscriptionList: any;
  OriginatedFromRoleTypeId: any;
  OriginatedFromRoleTypeIdArray: any[]=[];
  useTimeUom: any;
  useTimeUomArray: any[]=[];
  productCategory: any;
  productCategoryArray: any[]=[];
  RoleTypeId: any;
  RoleTypeIdArray: any[]=[];
  CommunicationEventId: any;
  CommunicationEventIdArray: any[]=[];
  newCommunication: any;
  subscriptionAttributeList: any;
  showAtt: boolean;
  subscriptionCommEventList: any;
  userPermissions: any;
  Hidebtn: boolean=false;
  HideCrudbtn: boolean=false;
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,    private _location: Location,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router
  ) {
    this.yesNoArray = [
      {
        label: 'Yes',
        value: 'Y'
      },
      {
        label: 'No',
        value: 'N'
      },

    ];
    this.newSubscription = this._FormBuilder.group({
      SubscriptionID: [''],
      description: [''],
      subsResourceID: [''],
      commEventID: [''],
      contactMechID: [''],
      originPartyID: [''],
      originRoleTypeID: [''],
      partyID: [''],
      roleTypeID: [''],
      partyNeedID: [''],
      needTypeID: [''],
      orderID: [''],
      orderItemSeqID: [''],
      productID: [''],
      productCategoryID: [''],
      inventoryItemID: [''],
      subsType: [''],
      extSubsID: [''],
      fromDate: [''],
      throughDate: [''],
      purchaseFromDate: [''],
      purchaseThroughDate: [''],
      maxLifeTime: [''],
      maxUomID: [''],
      availTime: [''],
      availUomID: [''],
      countLimit: [''],
      useTime: [''],
      useTimeUom: [''],
      autoExtend: [''],
      cancelTime: [''],
      cancelUomTime: [''],
      gracePeriodExpiry: [''],
      gracePeriodUomID: [''],
      expCompletedDate: [''],
    })
    this.editSubscription = this._FormBuilder.group({
      description: [''],
      subsResourceID: [''],
      commEventID: [''],
      contactMechID: [''],
      originPartyID: [''],
      originRoleTypeID: [''],
      partyID: [''],
      roleTypeID: [''],
      partyNeedID: [''],
      needTypeID: [''],
      orderID: [''],
      orderItemSeqID: [''],
      productID: [''],
      productCategoryID: [''],
      inventoryItemID: [''],
      subsType: [''],
      extSubsID: [''],
      fromDate: [''],
      throughDate: [''],
      purchaseFromDate: [''],
      purchaseThroughDate: [''],
      maxLifeTime: [''],
      maxUomID: [''],
      availTime: [''],
      availUomID: [''],
      countLimit: [''],
      useTime: [''],
      useTimeUom: [''],
      autoExtend: [''],
      cancelTime: [''],
      cancelUomTime: [''],
      gracePeriodExpiry: [''],
      gracePeriodUomID: [''],
      expCompletedDate: [''],
    });
    this.newCommunication = this._FormBuilder.group({
      CommunicationEvent: [''],

    })
    this.editAttributes = this._FormBuilder.group({
      attrName: [''],
      attrValue: [''],
    });
   }

  ngOnInit(): void {

    this.userPermissions = JSON.parse(localStorage.getItem('userPermissions'));
    if(this.userPermissions.CATALOG_ADMIN=="CATALOG_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.Hidebtn=false;
    }
    else if(this.userPermissions.CATALOG_PURCHASE_ALLOW=="CATALOG_PURCHASE_ALLOW" || this.userPermissions.CATALOG_VIEW_ALLOW=="CATALOG_VIEW_ALLOW"){
      this.Hidebtn=true;
    }
    else if(this.userPermissions.CATALOG_PRICE_MAINT=="CATALOG_PRICE_MAINT"){
      this.HideCrudbtn=true;
    }
    this._ActivatedRoute.queryParams.subscribe(params=>{
      this.subscriptionId = params.subscriptionId;
    })
     this.getSubscriptionById();
    this.getOrderById();
    this.getProductIds();
    this.getSubscriptionType();
    this.getSubscriptionResourceId();
    this.getPartyId();
    this.getOriginatedFromRoleTypeId();
    this.getUseTimeUom();
    this.getProductCategorySubscription();
    this.getUseRoleTypeId();
    this.getCommunicationEventId();
    this.getSubscriptionAttributeById();
    this.getSubscriptionCommEventBySubId();
  }
  homeButton(){
    this._Router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  removeSubscriptionCommEvent(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {

        const req = {
          "communicationEventId": product.communicationEventId,
  "subscriptionId": product.subscriptionId,
  "view_INDEX_1": "0",
  "view_SIZE_1": "20"
        }
        this.myContactsService.removeSubscriptionCommEvent(product).subscribe(res => {    
          this.getSubscriptionCommEventBySubId();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  update(product){
    this.showAtt = true ;
    setTimeout(() => {
      const form = this.newCommunication;
      form.patchValue({
        CommunicationEvent: product.attrName,
      })
      
    }, 2000);
  }
  getSubscriptionCommEventBySubId() {
    this.spinner.show();
    this.showSubscription = true;
    this.myContactsService.getSubscriptionCommEventBySubId(this.subscriptionId).subscribe(res => {
      this.subscriptionCommEventList = res.data[0].data;
      this.spinner.hide();
    })
  }
  createSubscriptionCommEvent(): void {
    this.spinner.show();
    const requestData = {
      "communicationEventId": this.newCommunication.value.CommunicationEvent,
      "subscriptionId": this.subscriptionId
    }
    this.myContactsService.createSubscriptionCommEvent(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton3.nativeElement.click();
        this.newCommunication.reset();
        this.getSubscriptionCommEventBySubId();
       
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  updateAtt(product){
    this.showAtt = true ;
    setTimeout(() => {
      const form = this.editAttributes;
      form.patchValue({
        attrName: product.attrName,
        attrValue: product.attrValue,
      })
      
    }, 2000);
  }
  updateSubscriptionAttribute(): void {
    this.spinner.show();
    const requestData = {
      "attrName":this.editAttributes.value.attrName,
      "attrValue": this.editAttributes.value.attrValue,
      "subscriptionId": this.subscriptionId
     
    }
    this.myContactsService.updateSubscriptionAttribute(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton3.nativeElement.click();
        this.editAttributes.reset();
        this.getSubscriptionAttributeById();
       
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getSubscriptionAttributeById() {
    this.spinner.show();
    this.showSubscription = true;
    this.myContactsService.getSubscriptionAttributeById(this.subscriptionId).subscribe(res => {
      this.subscriptionAttributeList = res.data[0].data;
      this.spinner.hide();
    })
  }
  createSubscriptionAttribute(): void {
    this.spinner.show();
    const requestData = {
      "attrName":this.editAttributes.value.attrName,
      "attrValue": this.editAttributes.value.attrValue,
      "subscriptionId": this.subscriptionId
     
    }
    this.myContactsService.createSubscriptionAttribute(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton3.nativeElement.click();
        this.editAttributes.reset();
        this.getSubscriptionAttributeById();
       
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getCommunicationEventId() {
    this.spinner.show();
    this.myContactsService.getCommunicationEventIdSubs().subscribe(res => {
      this.CommunicationEventId = res.data[0].communicationEventId;
      this.spinner.hide();
      for (const value of this.CommunicationEventId) {
        this.CommunicationEventIdArray.push({
          label: value.communicationEventId,
          value: value.communicationEventId
        })
      }
    })
  }
  getPartyId() {
    this.spinner.show();
    this.myContactsService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })

  }
  getProductIds() {
    this.spinner.show();
    this.myContactsService.getProductIds().subscribe(res => {
      this.ProductId = res.data[0].getProductId;
      this.spinner.hide();
      for (const value of this.ProductId) {
        this.ProductIdArray.push({
          label: value.internalName,
          value: value.productId
        })
      }
    })
  }
  getUseTimeUom() {
    this.spinner.show();
    this.myContactsService.getUseTimeUom().subscribe(res => {
      this.useTimeUom = res.data[0].data;
      this.spinner.hide();
      for (const value of this.useTimeUom) {
        this.useTimeUomArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })

  }
  getProductCategorySubscription() {
    this.spinner.show();
    this.myContactsService.getProductCategorySubscription().subscribe(res => {
      this.productCategory = res.data;
      this.spinner.hide();
      for (const value of this.productCategory) {
        this.productCategoryArray.push({
          label: value.data.productCategoryTypeId,
          value: value.data.primaryParentCategoryId
        })
      }
    })
  }
  getOriginatedFromRoleTypeId() {
    this.spinner.show();
    this.myContactsService.getOriginatedFromRoleTypeId().subscribe(res => {
      this.OriginatedFromRoleTypeId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.OriginatedFromRoleTypeId) {
        this.OriginatedFromRoleTypeIdArray.push({
          label: value.description,
          value: value.roleTypeId
        })
      }
    })

  }
  getSubscriptionType() {
    this.spinner.show();
    this.myContactsService.getSubscriptionType().subscribe(res => {
      this.subscriptionType = res.data[0].subscriptionType;
      this.spinner.hide();
      for (const value of this.subscriptionType) {
        this.subscriptionTypeArray.push({
          label: value.description,
          value: value.subscriptionTypeId
        })
      }
    })

  }
  getSubscriptionResourceId() {
    this.spinner.show();
    this.myContactsService.getSubscriptionResourceId().subscribe(res => {
      this.subscriptionResourceId = res.data;
      this.spinner.hide();
      for (const value of this.subscriptionResourceId) {
        this.subscriptionResourceIdArray.push({
          label: value.description,
          value: value.subscriptionResourceId
        })
      }
    })

  }

  getOrderById() {
    this.spinner.show();
    this.myContactsService.getOrderById().subscribe(res => {
      this.orderById = res.data[0].orderHeader;
      this.spinner.hide();
      for (const value of this.orderById) {
        this.orderByIdArray.push({
          label: value.orderId,
          value: value.orderId
        })
      }
    })

  }
  getUseRoleTypeId() {
    this.spinner.show();
    this.myContactsService.getUseRoleTypeId().subscribe(res => {
      this.RoleTypeId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.RoleTypeId) {
        this.RoleTypeIdArray.push({
          label: value.description,
          value: value.roleTypeId
        })
      }
    })
  }
  createSubscription(): void {
    if(this.userPermissions.CATALOG_ADMIN=="CATALOG_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.spinner.show();
      const requestData = {
      
        "automaticExtend": this.newSubscription.value.autoExtend,
    "availableTime": this.newSubscription.value.availTime,
    "availableTimeUomId": this.newSubscription.value.availUomID,
    "canclAutmExtTime": this.newSubscription.value.cancelTime,
    "canclAutmExtTimeUomId": this.newSubscription.value.cancelUomTime,
    "communicationEventId": this.newSubscription.value.commEventID,
    "contactMechId": this.newSubscription.value.contactMechID,
    "description": this.newSubscription.value.description,
    "expirationCompletedDate": this.datePipe.transform(this.newSubscription.value.expCompletedDate,"yyyy-MM-dd HH:mm:ss") ,
    "expirationCompletedDate_i18n": this.datePipe.transform(this.newSubscription.value.expCompletedDate,"yyyy-MM-dd HH:mm:ss") ,
    "externalSubscriptionId": this.newSubscription.value.extSubsID,
    "fromDate":this.datePipe.transform(this.newSubscription.value.fromDate,"yyyy-MM-dd HH:mm:ss") ,
    "gracePeriodOnExpiry": this.newSubscription.value.gracePeriodExpiry ,
    "gracePeriodOnExpiryUomId": this.newSubscription.value.gracePeriodUomID ,
    "inventoryItemId": this.newSubscription.value.inventoryItemID,
    "maxLifeTime": this.newSubscription.value.maxLifeTime,
    "maxLifeTimeUomId": this.newSubscription.value.maxUomID,
    "needTypeId": "",
    "orderId": this.newSubscription.value.orderID,
    "orderItemSeqId": this.newSubscription.value.orderItemSeqID,
    "originatedFromPartyId": this.newSubscription.value.originPartyID,
    "originatedFromRoleTypeId": this.newSubscription.value.originRoleTypeID,
    "partyId": this.newSubscription.value.partyID,
    "partyNeedId": this.newSubscription.value.partyNeedID,
    "productCategoryId": this.newSubscription.value.productCategoryID,
    "productId": this.newSubscription.value.productID,
    "purchaseFromDate": this.datePipe.transform(this.newSubscription.value.purchaseFromDate,"yyyy-MM-dd HH:mm:ss") ,
    "purchaseThruDate": this.datePipe.transform(this.newSubscription.value.purchaseThroughDate,"yyyy-MM-dd HH:mm:ss") ,
    "roleTypeId":this.newSubscription.value.roleTypeID,
    "subscriptionResourceId": this.newSubscription.value.subsResourceID,
    "subscriptionTypeId": this.newSubscription.value.subsType,
    "thruDate":this.datePipe.transform(this.newSubscription.value.throughDate,"yyyy-MM-dd HH:mm:ss") ,
    "useCountLimit": this.newSubscription.value.countLimit,
    "useTime": this.newSubscription.value.useTime ,
    "useTimeUomId": this.newSubscription.value.useTimeUom
       
      }
      this.myContactsService.createSubscription(requestData).subscribe((res: any) => {
        if (res.success) {
          this.toastr.success("Created Successfully");
          this.spinner.hide();
          this.closebutton.nativeElement.click();
          this.newSubscription.reset();
          this.getSubscriptionById();
         
        }
      }, (err) => {
        this.spinner.hide();
        if (err.error.errors) {
          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + ' ' + value.fieldError);
          }
        }
        else {
          this.toastr.error(err.error.message);
        }
      });
    }
    else if(this.userPermissions.CATALOG_PURCHASE_ALLOW=="CATALOG_PURCHASE_ALLOW" || this.userPermissions.CATALOG_VIEW_ALLOW=="CATALOG_VIEW_ALLOW"){
      this.toastr.error("Permission Denied"); 
    }
    else if(this.userPermissions.CATALOG_PRICE_MAINT=="CATALOG_PRICE_MAINT"){
      this.toastr.error("Permission Denied");
    }

  }
  updateSubscription(): void {
    if(this.userPermissions.CATALOG_ADMIN=="CATALOG_ADMIN" || this.userPermissions.PHYCORP_ADMIN=="PHYCORP_ADMIN"){
      this.spinner.show();
      const requestData = {
      
        "automaticExtend": this.newSubscription.value.autoExtend,
    "availableTime": this.newSubscription.value.availTime,
    "availableTimeUomId": this.newSubscription.value.availUomID,
    "canclAutmExtTime": this.newSubscription.value.cancelTime,
    "canclAutmExtTimeUomId": this.newSubscription.value.cancelUomTime,
    "communicationEventId": this.newSubscription.value.commEventID,
    "contactMechId": this.newSubscription.value.contactMechID,
    "description": this.newSubscription.value.description,
    "expirationCompletedDate": this.datePipe.transform(this.newSubscription.value.expCompletedDate,"yyyy-MM-dd HH:mm:ss") ,
    "expirationCompletedDate_i18n": this.datePipe.transform(this.newSubscription.value.expCompletedDate,"yyyy-MM-dd HH:mm:ss") ,
    "externalSubscriptionId": this.newSubscription.value.extSubsID,
    "fromDate":this.datePipe.transform(this.newSubscription.value.fromDate,"yyyy-MM-dd HH:mm:ss") ,
    "gracePeriodOnExpiry": this.newSubscription.value.gracePeriodExpiry ,
    "gracePeriodOnExpiryUomId": this.newSubscription.value.gracePeriodUomID ,
    "inventoryItemId": this.newSubscription.value.inventoryItemID,
    "maxLifeTime": this.newSubscription.value.maxLifeTime,
    "maxLifeTimeUomId": this.newSubscription.value.maxUomID,
    "needTypeId": "",
    "orderId": this.newSubscription.value.orderID,
    "orderItemSeqId": this.newSubscription.value.orderItemSeqID,
    "originatedFromPartyId": this.newSubscription.value.originPartyID,
    "originatedFromRoleTypeId": this.newSubscription.value.originRoleTypeID,
    "partyId": this.newSubscription.value.partyID,
    "partyNeedId": this.newSubscription.value.partyNeedID,
    "productCategoryId": this.newSubscription.value.productCategoryID,
    "productId": this.newSubscription.value.productID,
    "purchaseFromDate": this.datePipe.transform(this.newSubscription.value.purchaseFromDate,"yyyy-MM-dd HH:mm:ss") ,
    "purchaseThruDate": this.datePipe.transform(this.newSubscription.value.purchaseThroughDate,"yyyy-MM-dd HH:mm:ss") ,
    "roleTypeId":this.newSubscription.value.roleTypeID,
    "subscriptionResourceId": this.newSubscription.value.subsResourceID,
    "subscriptionTypeId": this.newSubscription.value.subsType,
    "thruDate":this.datePipe.transform(this.newSubscription.value.throughDate,"yyyy-MM-dd HH:mm:ss") ,
    "useCountLimit": this.newSubscription.value.countLimit,
    "useTime": this.newSubscription.value.useTime ,
    "useTimeUomId": this.newSubscription.value.useTimeUom,
    "subscriptionId": this.subscriptionId,
       
      }
      this.myContactsService.updateSubscription(requestData).subscribe((res: any) => {
        if (res.success) {
          this.toastr.success("Updated Successfully");
          this.spinner.hide();
          this.closebutton.nativeElement.click();
          this.newSubscription.reset();
          this.getSubscriptionById();
         
        }
      }, (err) => {
        this.spinner.hide();
        if (err.error.errors) {
          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + ' ' + value.fieldError);
          }
        }
        else {
          this.toastr.error(err.error.message);
        }
      });
    }
    else if(this.userPermissions.CATALOG_PURCHASE_ALLOW=="CATALOG_PURCHASE_ALLOW" || this.userPermissions.CATALOG_VIEW_ALLOW=="CATALOG_VIEW_ALLOW"){
      this.toastr.error("Permission Denied"); 
    }
    else if(this.userPermissions.CATALOG_PRICE_MAINT=="CATALOG_PRICE_MAINT"){
      this.toastr.error("Permission Denied");
    }

  }
  reset(){
    this.showSubscription = false;
    this.newSubscription.reset();
  }
  getSubscriptionById() {
    this.spinner.show();
    this.showSubscription = true;
    this.myContactsService.getSubscriptionById(this.subscriptionId).subscribe(res => {
      this.SubscriptionByIdList = res.data[0].data[0];
      setTimeout(() => {
        const formValue = this.newSubscription;
        formValue.patchValue({
          SubscriptionID:  this.subscriptionId,
          description:  this.SubscriptionByIdList.description,
          subsResourceID:  this.SubscriptionByIdList.subscriptionResourceId,
          commEventID:  this.SubscriptionByIdList.communicationEventId,
          contactMechID:  this.SubscriptionByIdList.contactMechId,
          originPartyID:  this.SubscriptionByIdList.originatedFromPartyId,
          originRoleTypeID:  this.SubscriptionByIdList.originatedFromRoleTypeId,
          partyID:  this.SubscriptionByIdList.partyId,
          roleTypeID:  this.SubscriptionByIdList.roleTypeId,
          partyNeedID:  this.SubscriptionByIdList.partyNeedId,
          needTypeID:  this.SubscriptionByIdList.needTypeId,
          orderID:  this.SubscriptionByIdList.orderId,
          orderItemSeqID:  this.SubscriptionByIdList.orderItemSeqId,
          productID:  this.SubscriptionByIdList.productId,
          productCategoryID:  this.SubscriptionByIdList.productCategoryId,
          inventoryItemID:  this.SubscriptionByIdList.inventoryItemId,
          subsType:  this.SubscriptionByIdList.subscriptionTypeId,
          extSubsID:  this.SubscriptionByIdList.externalSubscriptionId,
          fromDate:  this.datePipe.transform(this.SubscriptionByIdList.fromDate,"yyyy-MM-dd hh:mm:ss")  ,
          throughDate: this.datePipe.transform( this.SubscriptionByIdList.thruDate,"yyyy-MM-dd hh:mm:ss")   ,
          purchaseFromDate: this.datePipe.transform( this.SubscriptionByIdList.purchaseFromDate,"yyyy-MM-dd hh:mm:ss")  ,
          purchaseThroughDate: this.datePipe.transform( this.SubscriptionByIdList.purchaseThruDate,"yyyy-MM-dd hh:mm:ss")  ,
          maxLifeTime:  this.SubscriptionByIdList.maxLifeTime,
          maxUomID:  this.SubscriptionByIdList.maxLifeTimeUomId,
          availTime:  this.SubscriptionByIdList.availableTime,
          availUomID:  this.SubscriptionByIdList.availableTimeUomId,
          countLimit:  this.SubscriptionByIdList.useCountLimit,
          useTime:  this.SubscriptionByIdList.useTime,
          useTimeUom:  this.SubscriptionByIdList.useTimeUomId,
          autoExtend:  this.SubscriptionByIdList.automaticExtend ,
          cancelTime:  this.SubscriptionByIdList.canclAutmExtTime,
          cancelUomTime:  this.SubscriptionByIdList.canclAutmExtTimeUomId,
          gracePeriodExpiry:  this.SubscriptionByIdList.gracePeriodOnExpiry,
          gracePeriodUomID:  this.SubscriptionByIdList.gracePeriodOnExpiryUomId,
          expCompletedDate:this.datePipe.transform(this.SubscriptionByIdList.expirationCompletedDate,"yyyy-MM-dd hh:mm:ss")  
        })

      }, 2000);
      this.spinner.hide();
    })
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}
