<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Product: {{"[" + this.productId + "]"}}</span>
          
               <span class="d-flex">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
        
            </div>

            <div class=" bg-white color-grey">
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                    <div class="w-100">
                                        <div class="w3-card-4 classCardA ">

                                            <div class="container-fluid">
                                                <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">


                                                    <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategory(1)"><a>
                                                            Product Overview
                                                        </a></li>
                                                    <li [ngClass]="activeCategory==2?'active':''">
                                                        <a>
                                                            Product Update
                                                        </a>
                                                    </li>
                                                    <li [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategory(3)">
                                                        <a>
                                                            Product Member</a>
                                                    </li>
                                                    <li [ngClass]="activeCategory==4?'active':''" (click)="changeactiveCategory(4)">
                                                        <a>
                                                            Total Backlog
                                                        </a>
                                                    </li>
                                                    <li [ngClass]="activeCategory==5?'active':''" (click)="changeactiveCategory(5)">
                                                        <a>
                                                            Edit Backlog
                                                        </a>
                                                    </li>
                                                    <li [ngClass]="activeCategory==14?'active':''" (click)="unplannedSprint()">
                                                        <a>
                                                            Unplanned Sprint
                                                        </a>
                                                    </li>
                                                    <li [ngClass]="activeCategory==6?'active':''" (click)="changeactiveCategory(6)">
                                                        <a>
                                                            Billing
                                                        </a>
                                                    </li>
                                                    <li [ngClass]="activeCategory==7?'active':''" (click)="changeactiveCategory(7)">
                                                        <a>
                                                            Default Tasks
                                                        </a>
                                                    </li>
                                                    <li [ngClass]="activeCategory==8?'active':''" (click)="changeactiveCategory(8)"><a>
                                                            Categories
                                                        </a></li>
                                                    <li [ngClass]="activeCategory==9?'active':''" (click)="changeactiveCategory(9)">
                                                        <a>
                                                            Content
                                                        </a>
                                                    </li>
                                                    <li [ngClass]="activeCategory==10?'active':''" (click)="changeactiveCategory(10)"><a>
                                                            Communications
                                                        </a></li>
                                                    <li [ngClass]="activeCategory==11?'':''" (click)="changeactiveCategory(11)" hidden><a>
                                                            Detailed Edit Page
                                                        </a></li>
                                                    <li [ngClass]="activeCategory==12?'':''" (click)="changeactiveCategory(12)" hidden><a>
                                                            Edit Default Task
                                                        </a></li>
                                                    <li [ngClass]="activeCategory==13?'':''" (click)="changeactiveCategory(13)" hidden><a>
                                                            Edit Backlog Category
                                                        </a></li>
                                                </ul>
                                            </div>
                                            <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                                                <div class="create-new-leade ">
                                                    <div class="container-fluid">
                                                        <div class="panel-group">
                                                            <div class=" bg-white color-grey">
                                                                <div class="create-new-leade mt-2">
                                                                    <div class="container-fluid">
                                                                        <div class="row">
                                                                            <form class="w-100">
                                                                                <div class="container-fluid">
                                                                                    <ul class=" tabbing-accordians " style="width: 103% !important;
                                                                        flex-wrap: wrap !important;
                                                                        display: flex !important;
                                                                        padding: 10px !important;">
                                                                                        <button type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#exampleModalNewProject">
                                                                                            New
                                                                                            Project
                                                                                        </button>
                                                                                        <button type="submit" class="btn btn-secondary submit-btn ml-2" (click)="closeProduct()">
                                                                                            Close
                                                                                            Product
                                                                                        </button>
                                                                                    </ul>
                                                                                </div>

                                                                                <div class="w3-card-4 classCard mt-2">
                                                                                    <header class="w3-container w3-blue">
                                                                                        <div class="header-tabs">
                                                                                            <h4 class=" common-styling h4Margin">

                                                                                                Unplan Backlog List
                                                                                                <span style="float: right !important;margin-right: -443%;">
                                                                                                    All Backlog
                                                                                                    Items</span>
                                                                                            </h4>
                                                                                        </div>
                                                                                    </header>
                                                                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                                        <div class="col-lg-12 col-12">
                                                                                            <div class="form-group">

                                                                                                <div class="card own-account-table">
                                                                                                    <p-table [value]="UnplanBacklogList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                        <ng-template pTemplate="header">
                                                                                                            <tr style="background: #0d3769;">
                                                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                        </p-checkbox>

                                                                                                                        Sprint Backlog

                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>

                                                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        Status Backlog
                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>


                                                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">


                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>

                                                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">

                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>

                                                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">

                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        Initial Plan / Total Plan

                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">

                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'100px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">

                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'100px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">

                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'100px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">

                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'100px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">

                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>
                                                                                                            </tr>

                                                                                                            <tr style="background: #0d3769;">
                                                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                                                                    <div class="checkbox-align" style="color: white;">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                            <!---->
                                                                                                                        </p-checkbox>
                                                                                                                        Task


                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>

                                                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        Task Type
                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        Status
                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>

                                                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        Actual Hours

                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>

                                                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        Planned Hours
                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>

                                                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">

                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">
                                                                                                                        Assigned To
                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'100px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">

                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'100px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">

                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'100px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">

                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>
                                                                                                                <th [ngStyle]="{'width':'100px'}" pSortableColumn="name">
                                                                                                                    <div style="color: white;">

                                                                                                                    </div>
                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                </th>
                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="body" let-product>
                                                                                                            <tr>
                                                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                                                    <div class="checkbox-align">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                        </p-checkbox>
                                                                                                                        <span *ngIf="product.SprintBacklog[0].custRequestId"  class="account-button"  >
                                                                                                                            {{product.SprintBacklog[0].custRequestId}}</span>

                                                                                                                    </div>
                                                                                                                </td>

                                                                                                                <td [ngStyle]="{'width':'150px'}">

                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'150px'}">

                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'150px'}">

                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'150px'}">

                                                                                                                </td>

                                                                                                                <td [ngStyle]="{'width':'180px'}">


                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'150px'}">


                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'100px'}">
                                                                                                                    <div><span></span></div>

                                                                                                                </td>



                                                                                                                <td [ngStyle]="{'width':'100px'}">
                                                                                                                    <button type="submit" class="btn btn-secondary submit-btn ml-2" (click)="DeleteUnplanBacklog(product)">
                                                                                                                        Delete
                                                                                                                    </button>
                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'100px'}">
                                                                                                                    <button type="submit" class="btn btn-secondary submit-btn ml-2" data-toggle="modal" data-target="#addTaskPopUp">
                                                                                                                        Add
                                                                                                                        Task
                                                                                                                    </button>
                                                                                                                </td>

                                                                                                            </tr>
                                                                                                            <tr>
                                                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                                                    <div class="checkbox-align">
                                                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                                                </div>
                                                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                </div>
                                                                                                                            </div>

                                                                                                                        </p-checkbox>
                                                                                                                        <span *ngIf="product.tasks.workEffortId"  class="account-button"  >
                                                                                                                            {{product.tasks.workEffortId}}</span>

                                                                                                                    </div>
                                                                                                                </td>

                                                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                                                    <div *ngIf="product.tasks"><span>{{product.tasks.workEffortTypeId}}</span></div>

                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                                                    <div *ngIf="product.tasks"><span> {{product.tasks.currentStatusId}}</span></div>

                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                                                    <div><span> {{product.ActualHours}}</span></div>

                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                                                    <div *ngIf="product.PlannedHours[0]"><span> {{product.PlannedHours[0].planHours}}</span></div>

                                                                                                                </td>

                                                                                                                <td [ngStyle]="{'width':'180px'}">
                                                                                                                    <div *ngIf="product.InitialPlan/TotalPlan"><span> {{product.InitialPlan/TotalPlan}}</span></div>

                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'150px'}">
                                                                                                                    <div *ngIf="product.AssignedTo[0]"><span>{{product.AssignedTo[0].partyId}}</span></div>

                                                                                                                </td>
                                                                                                                <td [ngStyle]="{'width':'100px'}">

                                                                                                                    <button type="submit" class="btn btn-secondary submit-btn ml-2" (click)="editPage(product.AssignedTo[0].partyId,product.tasks.workEffortName,product.tasks.workEffortId,product.SprintBacklog[0].custRequestId)">
                                                                                                                        Edit
                                                                                                                    </button>
                                                                                                                </td>


                                                                                                                <td [ngStyle]="{'width':'100px'}">
                                                                                                                    <button type="submit" class="btn btn-secondary submit-btn ml-2" (click)="DeleteUnplanBacklog(product)">
                                                                                                                        Delete
                                                                                                                    </button>
                                                                                                                </td>

                                                                                                                <td [ngStyle]="{'width':'100px'}">
                                                                                                                    <button type="submit" class="btn btn-secondary submit-btn ml-2" (click)="setCompleteFromUnplanBacklog(product)">
                                                                                                                        Complete
                                                                                                                    </button>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                                                            <tr>
                                                                                                                <td>
                                                                                                                    <span class="text-center">
                                                                                                                        <h4
                                                                                                                            class="ml-2">
                                                                                                                            No
                                                                                                                            Record
                                                                                                                            Found
                                                                                                                        </h4>
                                                                                                                    </span>
                                                                                                                </td>
                                                                                                            </tr>
                                                                                                        </ng-template>
                                                                                                    </p-table>

                                                                                                    <p class="paginate_data">
                                                                                                        View per page </p>
                                                                                                </div>
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>


                                                                            </form>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Edit Task # Information</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass" style="width: 103% !important;
            flex-wrap: wrap !important;
            display: flex !important;">
                                <li><a>
                                        Update Product Backlog
                                    </a>
                                </li>

                            </ul>
                        </div>
                        <form [formGroup]="UpdateProductBacklogForm">
                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-3 form-group classInputA">
                                            <label for="exampleInputEmail1">

                                                Product Backlog Item

                                                <span style="color: red;">*</span>
                                            </label>
                                        </div>

                                        <div class="col-lg-3">

                                            <input type="email" class="form-control" id="exampleInputEmail1" formControlName="ProductBacklogItem" aria-describedby="emailHelp" placeholder="Enter Product Backlog Item">
                                        </div>
                                        <div class="col-lg-2" style="color: #0d3769;">255 Chars Maximum</div>

                                    </div>
                                </div>

                                <div class="col-lg-12">
                                    <div class="row">

                                        <div class="col-lg-3 form-group classInputA">
                                            <label for="exampleInputEmail1">Company

                                            </label>
                                        </div>

                                        <div class="col-lg-3">
                                            <input type="email" disabled class="form-control" id="exampleInputEmail1" formControlName="Company" aria-describedby="emailHelp" placeholder="Enter Company">
                                        </div>

                                    </div>
                                </div>

                                <div class="col-lg-12">
                                    <div class="row">

                                        <div class="col-lg-3 form-group classInputA">
                                            <label for="exampleInputEmail1">Product Owner


                                            </label>

                                        </div>

                                        <div class="col-lg-3">
                                            <input type="email" disabled class="form-control" id="exampleInputEmail1" formControlName="ProductOwner" aria-describedby="emailHelp" placeholder="Enter Product Owner">
                                        </div>

                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-3 form-group classInputA">
                                            <label for="exampleInputEmail1">
                                                Backlog Item Category
                                            </label>
                                        </div>
                                        <div class="col-lg-3">
                                            <input type="email" class="form-control" id="exampleInputEmail1" formControlName="BacklogItemCategory" aria-describedby="emailHelp" placeholder="Enter Backlog Item Category">


                                        </div>

                                    </div>
                                </div>


                                <div class="col-lg-12">
                                    <div class="row">

                                        <div class="col-lg-3 form-group classInputA">
                                            <label for="exampleInputEmail1">Plan Time


                                            </label>

                                        </div>

                                        <div class="col-lg-3">
                                            <input type="email" class="form-control" id="exampleInputEmail1" formControlName="PlanTime" aria-describedby="emailHelp" placeholder="Enter Plan Time">
                                        </div>

                                        <div class="col-lg-2" style="color: #0d3769;">hr(s).</div>

                                    </div>
                                </div>

                                <div class="col-lg-12">
                                    <div class="row">

                                        <div class="col-lg-3 form-group classInputA">
                                            <label for="exampleInputEmail1">Sequence Num


                                            </label>

                                        </div>

                                        <div class="col-lg-3">
                                            <input type="email" class="form-control" id="exampleInputEmail1" formControlName="SequenceNum" aria-describedby="emailHelp" placeholder="Enter Sequence Num">
                                        </div>

                                    </div>
                                </div>

                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-3 form-group classInputA">
                                            <label for="exampleInputEmail1">
                                                Billed
                                            </label>
                                        </div>
                                        <div class="col-lg-3">

                                            <p-dropdown filter="true" [options]="yesNOArray" formControlName="Billed" optionlabel="label" placeholder="Enter 
                                                Billed">
                                            </p-dropdown>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-3 form-group classInputA">
                                            <label for="exampleInputEmail1">
                                                Unplanned
                                            </label>
                                        </div>
                                        <div class="col-lg-3">

                                            <p-dropdown filter="true" [options]="yesNOArray" formControlName="Unplanned" optionlabel="label" placeholder="Enter 
                                                Unplanned">
                                            </p-dropdown>
                                        </div>

                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="row">

                                        <div class="col-lg-3 form-group classInputA">
                                            <label for="exampleInputEmail1">Status


                                            </label>

                                        </div>

                                        <div class="col-lg-3">
                                            <input type="email" disabled class="form-control" id="exampleInputEmail1" formControlName="Status" aria-describedby="emailHelp" placeholder="Enter Status">
                                        </div>

                                    </div>
                                </div>
                                <div class="col-lg-12">
                                    <div class="row">
                                        <div class="col-lg-3 form-group classInputA">
                                            <label for="exampleInputEmail1">
                                                Request name
                                            </label>
                                        </div>
                                        <div class="col-lg-3">
                                            <p-dropdown filter="true" [options]="RequesterNameArray" formControlName="Requestname" optionlabel="label" placeholder="Enter 
                                            Request Name">
                                            </p-dropdown>

                                        </div>

                                    </div>
                                </div>

                                <div class="col-lg-12">
                                    <div class="row">

                                        <div class="col-lg-3 form-group classInputA">
                                            <label for="exampleInputEmail1">Story


                                            </label>

                                        </div>

                                        <div class="col-lg-3">
                                            <input type="email" class="form-control" id="exampleInputEmail1" formControlName="Story" aria-describedby="emailHelp" placeholder="Enter Story">
                                        </div>

                                    </div>
                                </div>

                                <div class="col-lg-12">
                                    <div class="row">

                                        <div class="col-lg-3 form-group classInputA">
                                            <label for="exampleInputEmail1">How to test


                                            </label>

                                        </div>

                                        <div class="col-lg-3">
                                            <input type="email" class="form-control" id="exampleInputEmail1" formControlName="Howtotest" aria-describedby="emailHelp" placeholder="Enter How to test">
                                        </div>

                                    </div>
                                </div>


                            </div>
                        </form>
                        <div class="col-lg-12  main-submit-button" style="margin-left: -58%;">
                            <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn">Submit</button>&nbsp;
                        </div>
                    </div>
                </div>


            </div>

        </div>
    </div>
</div>
<div class="modal fade" id="addTaskPopUp" tabindex="-1" role="dialog" aria-labelledby="addTaskPopUpTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1">Edit Task # Information</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                                        Edit Task # Information</a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100 " [formGroup]="addNewTaskForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">

                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="productId">Task Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" formControlName="TaskName" placeholder="Enter Task Name">

                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Task Type
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">

                                                        <p-dropdown filter="true" optionlabel="label" formControlName="TaskType" [options]="RoleTypeArray" placeholder="Enter Task Type">

                                                        </p-dropdown>

                                                    </div>
                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Plan Hours</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" formControlName="PlanHours" aria-describedby="emailHelp" placeholder="Enter Plan Hours">
                                                    </div>


                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Description</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1" formControlName="Description" aria-describedby="emailHelp" placeholder="Enter Description">
                                                    </div>


                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Uploaded File
                                                            </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="file" class="form-control" id="exampleInputEmail1" formControlName="Uploaded" aria-describedby="emailHelp" placeholder="Choose File">
                                                    </div>


                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">


                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Assign To
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">

                                                        <p-dropdown filter="true" optionlabel="label" [options]="AssignToArray" placeholder="Enter Assign To" formControlName="AssignTo">

                                                        </p-dropdown>

                                                    </div>
                                                </div>
                                            </div>



                                        </div>
                                        <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -62%;">
                                            <button type="submit" class="btn btn-secondary submit-btn" (click)="addTask()">Create</button>

                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>