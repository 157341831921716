import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { URLS } from '../../app.constant';
import { ApiService } from 'src/app/api.service';

@Injectable({
  providedIn: 'root'
})
export class EmployeeService {

  constructor(readonly http: HttpClient, readonly _ApiService: ApiService) { }
  getEmployeeList(employess): Observable<any> {
    const empDataList = URLS.getEmployeesList + "?pageNo=" + employess.pageNo + "&pageSize=" + employess.pageSize + "&search=" + employess.search;
   
    return this.http.get(empDataList);
  }
  getListEmployee(employess, data): Observable<any> {
    const empList = URLS.getListEmployee + "?pageNo=" + employess.pageNo + "&pageSize=" + employess.pageSize + "&search=" + employess.search;
    return this.http.post(empList, data)
  }

  getEmployeeId(id): Observable<any> {
    const empDetails = URLS.getEmployeeById.replace(":partyId", id);
    return this.http.get(empDetails);

  }
  internalOrganization(): Observable<any> {
    const internalOrganization = URLS.internalOrganization;
    return this.http.get(internalOrganization);
  }

  createEmployee(formData): Observable<any> {
    const createEmployee = URLS.createEmployee;
    return this.http.post(createEmployee, formData)
  }

  getCountry(): Observable<any> {
    return this.http.get(URLS.getCountry)
  }

  getState(data): Observable<any> {
    const getStateList = URLS.getState;
    const getStateLists = getStateList.replace('accState', data.trim())
    return this.http.get(getStateLists)
  }

  getExportEmployee(): Observable<any> {
    return this.http.get(URLS.getExportEmployee, { responseType: 'blob' })
  }

  importEmployee(formData): Observable<any> {
    const importEmp = URLS.postImportEmployee;
    return this.http.post(importEmp, formData);
  }

  updateEmpInfo(id, formData): Observable<any> {
    const updateEmp = URLS.updateEmployeePersonal.replace(":partyId", id);
    return this.http.put(updateEmp, formData)
  }
  getStatus(): Observable<any> {
    return this.http.get(URLS.getCRMStatus)
  }
  updatePostalAddress(formData: any): Promise<any> {

    return this._ApiService.putRequest<any>(`/phyCorp/control/api/v1/hr/employee/postalAddress`, formData);
  }
  creatPostalAddress(formData: any): Promise<any> {
    return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/crm/partyPostalAddress`, formData);
  }
  getMyAccountRelatedStatus(searchAccountForm, all): Observable<any> {
    const getMyAccountRelated = URLS.getMyAccountRelated + "?all=" + all;
    return this.http.post(getMyAccountRelated, searchAccountForm)
  }

  postMyAccountRelatedStatus(formData): Observable<any> {
    const postMyAccountRelated = URLS.postMyAccountRelated;
    return this.http.post(postMyAccountRelated, formData)
  }

  getEmployeeAccountRelated(contactDetails): Observable<any> {
    const getContacts = URLS.getEmployeeById;
    const getContactsId = getContacts.replace(':partyId', contactDetails)
    return this.http.get(getContactsId);
  }
  getTelephoneEmployeeId(id): Observable<any> {

    const getTelephoneNumberPartyId = URLS.getTelephoneNumberPartyId.replace("contactMechId", id);
    return this.http.get(getTelephoneNumberPartyId);

  }
  updateTelephone(formData): Observable<any> {
    const updateEmp = URLS.updateTelephoneNumberPartyId;
    return this.http.put(updateEmp, formData)
  }

  getEmailEmployeeId(id): Observable<any> {

    const getEmailPartyId = URLS.getEmailPartyId.replace("contactMechId", id);
    return this.http.get(getEmailPartyId);

  }
  updateEmail(formData): Observable<any> {
    const updateEmailPartyId = URLS.updateEmailPartyId;
    return this.http.put(updateEmailPartyId, formData)
  }
  createContactNote(formData, id): Observable<any> {
    const createNote = URLS.createContactNote.replace(":partyId", id);
    return this.http.post(createNote, formData);
  }

  deleteNote(noteId): Observable<any> {
    const deleteNote = URLS.deleteContactNote.replace(":noteId", noteId);
    return this.http.delete(deleteNote);
  }

  updateContactNote(formData, noteId): Observable<any> {
    const updateNote = URLS.updateContactNote.replace(":noteId", noteId);
    return this.http.put(updateNote, formData);
  }

  getPartyStatus(): Observable<any> {
    return this.http.get(`/phyCorp/control/api/v1/resources/statusByType?statusTypes=PARTY_STATUS`);
  }
  getMaritalStatus(): Observable<any> {
    return this.http.get(`/phyCorp/control/api/v1/resources/enumByType?enumTypes=MARITAL_STATUS`);
  }
  createEmployeeData(formData: any): Promise<any> {
    return this._ApiService.postRequest<any>(`/phyCorp/control/api/v1/hr/employee`, formData);
  }
  getEmployeeById(partyId: string): Promise<any> {
    return this._ApiService.getRequest<any>(`/phyCorp/control/api/v1/hr/employee/${partyId}`);
  }
}
