import { Component, OnInit } from '@angular/core';
import { CreateAccountService } from './create-account.service';
import { CreateAccountFormService } from './create-account-service';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import * as moment from 'moment';
import { CRMAccountService } from 'src/app/services/crm-account.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { EditAccountServices } from 'src/app/editContactGroup/edit-contact-group/edit-contact-group.service';



declare var $: any;
@Component({
  selector: 'app-create-account',
  templateUrl: './create-account.component.html',
  styleUrls: ['./create-account.component.css']
})
export class CreateAccountComponent implements OnInit {
  address1: any;
  address2: any;
  areaCode: any;
  city: any;
  activeCategory = 2;
  contactNumber: any;
  countryCode: any;
  countryGeoId: any;
  emailAddress: any;
  extension: any;
  groupName: any;
  numEmployees: any;
  officeSiteName: any;
  postalCode: any;
  stateProvinceGeoId: any;
  partyId: any;
  show = false;
  array: any = [];
  stateData: any;
  createContact: any;
  countryId: any;
  stateArray: any = [];
  // currencyArray: any = [];
  // crmArray: any = [];
  accountForm: FormGroup;
  additionalDetailForm: FormGroup;
  jobInfoForm: FormGroup;
  customerType = [{

    label: 'Individual Customer',
    value: 'IndividualCustomer'
  },
  {
    label: 'Business Customer',
    value: 'BusinessCustomer'
  },
  ]
  salutationArray = [
    {
      label: 'Mr.',
      value: 'Mr.'
    },
    {
      label: 'Mrs.',
      value: 'Mrs.'
    },
    {
      label: 'Ms.',
      value: 'Ms.'
    }
  ];
  currencyList: any[];
  statusList: any[];
  id: any;
  editMode = false;
  jobInfo: any;
  additionalInfo: any;
  
  constructor(readonly createAccountService: CreateAccountService,
    readonly _GlobalResourceService: GlobalResourceService,
    public createAccountFormService: CreateAccountFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly spinner: NgxSpinnerService,
    readonly router: Router,
    readonly toastr: ToastrService,
    readonly _FormBuilder: FormBuilder,
    readonly _CRMAccountService: CRMAccountService,
    readonly _EditAccountServices: EditAccountServices
  ) {
    this.jobInfoForm = this._FormBuilder.group({
      description: [''],
      endDate: [''],
      jobStatus: [''],
      jobType: [''],
      partyId: [''],
      productType: [''],
      projectedEndDate: [''],
      serviceType: [''],
      startDate: ['']
    });
    this.additionalDetailForm = this._FormBuilder.group({
      infoType: [''],
      partyId: [''],
      prefeferredSendMethod: [''],
      priceLevel: [''],
      rep: [''],
      resaleNo: [''],
      terms: ['']
    });
    this.accountForm = this._FormBuilder.group({
      address1: [''],
      address2: [''],
      areaCode: [''],
      city: [''],
      contactNumber: [''],
      countryCode: [''],
      countryGeoId: [''],
      emailAddress: [''],
      extension: [''],
      groupName: ['', [Validators.required]],
      numEmployees: [''],
      officeSiteName: [''],
      postalCode: [''],
      stateProvinceGeoId: [''],
      firstName: [''],
      lastName: [''],
      title: [''],
      customerType: [''],
      annualRevenue: [''],
      description: [''],
      externalId: [''],
      logoImageUrl: [''],
      preferredCurrencyUomId: [''],
      statusId: [''],
    });
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.getCountryList();

    this.accountForm.controls.countryGeoId.valueChanges.subscribe(data => {
      if (data) {
        this.countryId = data;
        this.getStateList();
      }
    });

    this.createAccountFormService.create_account_form = null;
    this.createAccountFormService.prepareAccountForm(this.createAccountFormService.create_account_form);
    this.activatedRoute.queryParams.subscribe(params => {
      this.partyId = params["partyId"];
    })
    if (this.partyId) {
      this.show = true;
      this.editMode = true;
      this.getData();
      this._CRMAccountService.getAccountById(this.partyId)
        .then(data => {
          if (data.success) {
            this.spinner.hide();
            this.additionalInfo = data.data.additionalInformation;
            this.additionalDetailForm.patchValue({
              ...data.data.additionalInformation
            });
          }
        });
      this._CRMAccountService.getAccountById(this.partyId)
        .then(data => {
          if (data.success) {
            this.spinner.hide();
            this.jobInfo = data.data.jobInformation;
            this.jobInfoForm.patchValue({
              ...data.data.jobInformation,
              startDate: data.data.jobInformation.startDate ? moment(data.data.jobInformation.startDate).format('YYYY-MM-DD') : '',
              endDate: data.data.jobInformation.endDate ? moment(data.data.jobInformation.endDate).format('YYYY-MM-DD') : '',
              projectedEndDate: data.data.jobInformation.projectedEndDate ? moment(data.data.jobInformation.projectedEndDate).format('YYYY-MM-DD') : ''
            });

          }

        });
    }
    else {
      this.show = false;
    }
    $('.right-arrow').click(function () {
      $(this).toggleClass('rotate-up');
    });

    this.getCrmStatus();
    this.getCurrency();
  }



  getCrmStatus() {
    this.spinner.show();
    this._EditAccountServices.getCrmStatus().subscribe(res => {
      this.spinner.hide();
      this.statusList = res.data.map(value => {
        return {
          label: value.description,
          value: value.statusId
        };
      });
    })

  }

  getCurrency(): void {
    this.spinner.show();
    this._GlobalResourceService.getCurrency()
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          this.currencyList = data.data.currencyList.map(value => {
            return {
              label: value.description,
              value: value.uomId
            };
          });
        }
      });

  }

  getData() {
    this.spinner.show();
    this.createAccountService.getAccountById(this.partyId).subscribe(res => {
      this.spinner.hide();
      const form = this.createAccountFormService.create_account_form;
      this.accountForm.patchValue({
        groupName: res.data.groupName !== null ? res.data.groupName : '',
        numEmployees: res.data.numEmployees !== null ? res.data.numEmployees : '',
        officeSiteName: res.data.officeSiteName !== null ? res.data.officeSiteName : '',
        emailAddress: res.data.email.infoString !== null ? res.data.email.infoString : '',
        address1: res.data.address.address1 !== null ? res.data.address.address1 : '',
        address2: res.data.address.address2 !== null ? res.data.address.address2 : '',
        city: res.data.address.city !== null ? res.data.address.city : '',
        postalCode: res.data.address.postalCode !== null ? res.data.address.postalCode : '',
        contactNumber: res.data.telenumber.contactNumber !== null ? res.data.telenumber.contactNumber : '',
        countryCode: res.data.telenumber.countryCode !== null ? res.data.telenumber.countryCode : '',
        areaCode: res.data.telenumber.areaCode !== null ? res.data.telenumber.areaCode : '',
        stateProvinceGeoId: res.data.address.stateId !== null ? res.data.address.stateId : '',
        firstName: res.data.firstName,
        lastName: res.data.lastName,
        title: res.data.title,
        annualRevenue: res.data.annualRevenue,
        description: res.data.description,
        externalId: res.data.externalId,
        logoImageUrl: res.data.logoImageUrl,
        preferredCurrencyUomId: res.data.preferredCurrencyUomId,
        statusId: res.data.statusId,
        countryGeoId: res.data.address.countryId
      });

    })

  }


  createAccount() {
    this.spinner.show();
    const formValues = { ...this.createAccountFormService.create_account_form.value };


    const {
      address1,
      address2,
      areaCode,
      city,
      contactNumber,
      countryCode,
      countryGeoId,
      emailAddress,
      extension,
      groupName,
      numEmployees,
      officeSiteName,
      postalCode,
      stateProvinceGeoId,
      firstName,
      lastName,
      title } = this.accountForm.value;
    this._CRMAccountService.createAccount({
      address1,
      address2,
      areaCode,
      city,
      contactNumber,
      countryCode,
      countryGeoId,
      emailAddress,
      extension,
      groupName,
      numEmployees,
      officeSiteName,
      postalCode,
      stateProvinceGeoId,
      firstName,
      lastName,
      title
    })
      .then(data => {
        if (data.success) {
          this.id = data.data.partyId
          this.toastr.success('Customer created successfully');
          this.spinner.hide();
          this.activeCategory = 3;
          //  this.router.navigate(['/crm/accounts/account-details'], { queryParams: { partyId: this.id } });
          // this.router.navigate(['/crm/accounts'])
        }
      }, (err) => {
        this.spinner.hide();
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      });
    this.spinner.hide();


  }

  updateAccount() {

    this.spinner.show();

    this._CRMAccountService.updateAccount(this.partyId, this.accountForm.value)
      .then(data => {
        if (data.success) {
          this.toastr.success("Customer Updated Successfully");
          this.spinner.hide();
          this.activeCategory = 3;
          // this.router.navigate(['/crm/accounts/account-details'], { queryParams: { partyId:  this.partyId } });
        }
      }, (err) => {
        this.spinner.hide();
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      });
    this.spinner.hide();



  }
  back() {
    this.router.navigate(['/crm/accounts']);
  }

  getCountryList() {
    this.spinner.show();
    this.createAccountService.getCountry().subscribe(res => {
      var country = res.data.countryList;
      this.spinner.hide();
      country.forEach(element => {
        this.array.push({
          label: element.split(":")[0],
          value: element.split(":").pop().trim()
        })

      })

    })

  }

  onCountryChange(event) {
    this.stateArray = [];
    this.countryId = event;
    this.getStateList();

  }

  onStateChange(event) {
    this.stateProvinceGeoId = event;
  }

  getStateList() {
    this.spinner.show();
    this.createAccountService.getState(this.countryId).subscribe(res => {
      this.stateData = res.data.stateList;
      this.spinner.hide();
      this.stateData.forEach(stateLists => {
        var splitState = stateLists.split(":");
        var stateIndex = splitState[0];
        this.stateArray.push({
          label: stateIndex,
          value: stateLists.split(":").pop().trim()
        })
      })

    })


  }

  updateAdd(): void {
    this.spinner.show();
    this._CRMAccountService.updateAdditionalDetail({
      ...this.additionalInfo, ...this.additionalDetailForm.value, ...{
        partyId: this.partyId
      }
    })
      .then(data => {
        if (data.success) {
          this.toastr.success('Updated');

          this.spinner.hide();
        }
      });

  }
  updateJob(): void {
    this.spinner.show();
    this._CRMAccountService.updateJobInformation({
      ...this.jobInfo, ...this.jobInfoForm.value, ...{
        partyId: this.partyId,
        startDate: this.jobInfoForm.value.startDate ? moment(this.jobInfoForm.value.startDate).format('YYYY-MM-DD HH:mm:ss') : '',
        endDate: this.jobInfoForm.value.endDate ? moment(this.jobInfoForm.value.endDate).format('YYYY-MM-DD HH:mm:ss') : '',
        projectedEndDate: this.jobInfoForm.value.projectedEndDate ? moment(this.jobInfoForm.value.projectedEndDate).format('YYYY-MM-DD HH:mm:ss') : ''
      }
    })
      .then(data => {
        if (data.success) {
          this.toastr.success('Updated');
          this.router.navigate(['/crm/accounts/account-details'], { queryParams: { partyId: this.partyId } });
          this.spinner.hide();

        }
      });

  }
  submit(): void {
    this.spinner.show();
    this._CRMAccountService.createJobInformation({
      ...this.jobInfoForm.value, ...{
        partyId: this.id,
        startDate: this.jobInfoForm.value.startDate ? moment(this.jobInfoForm.value.startDate).format('YYYY-MM-DD HH:mm:ss') : '',
        endDate: this.jobInfoForm.value.endDate ? moment(this.jobInfoForm.value.endDate).format('YYYY-MM-DD HH:mm:ss') : '',
        projectedEndDate: this.jobInfoForm.value.projectedEndDate ? moment(this.jobInfoForm.value.projectedEndDate).format('YYYY-MM-DD HH:mm:ss') : ''
      }
    })
      .then(data => {
        if (data.success) {
          this.toastr.success('Created');
          this.spinner.hide();
          this.router.navigate(['/crm/accounts/account-details'], { queryParams: { partyId: this.id } });

        }
      });

  }
  submitAdd(): void {
    this.spinner.show();
    this._CRMAccountService.createAdditionalDetail({
      ...this.additionalDetailForm.value, ...{
        partyId: this.id
      }
    })
      .then(data => {
        if (data.success) {
          this.toastr.success('Created');
          this.spinner.hide();
          this.activeCategory = 4;
        }
      });


  }

  ngOnDestroy(): void {
    this.spinner.hide();
  }

}
