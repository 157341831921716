import { Component, OnInit } from '@angular/core';
import { SortEvent } from 'primeng/api';

import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';

import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { request } from 'http';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';

@Component({
  selector: 'app-create-account-category-member',
  templateUrl: './create-account-category-member.component.html',
  styleUrls: ['./create-account-category-member.component.css']
})
export class CreateAccountCategoryMemberComponent implements OnInit {
  show: boolean;
  glAccountCategoryId: any;
  createMember: FormGroup;
  actualfromdate: string;
  actualthroughdate: string;
  parentGlAccount: any;
  parentGlAccountArray: any[]=[];
  glAccountId: any;
  fromDate: any;
  thruDate: any;
  activeCategory = 2;
  amountPercentage: any;
  getEstimateFrom: string;
  getEstimateTo: string;
  constructor(readonly spinner: NgxSpinnerService,
    readonly datePipe: DatePipe,
    readonly router: Router,
    readonly toastr: ToastrService,
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
  ) {
    this.createMember = this._FormBuilder.group({
      amountPercentage: [''],
      fromDate: ['',[Validators.required]],
      glAccountId: ['',[Validators.required]],
      thruDate: [''],




    });
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.getGlAccountInitialData();
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.glAccountCategoryId = params["glAccountCategoryId"];
      this.glAccountId = params["glAccountId"];
      this.fromDate = params["fromDate"];this.thruDate = params["thruDate"];
      this.amountPercentage = params["amountPercentage"];
    });
    if(this.glAccountId){
      this.show=true;
      this.spinner.show();
      setTimeout(() => {
        const fromdate= this.fromDate
        this.getEstimateFrom=this.datePipe.transform(fromdate,"yyyy-MM-dd");
        const toEstdate= this.thruDate
        this.getEstimateTo=this.datePipe.transform(toEstdate,"yyyy-MM-dd");
        const formValue = this.createMember;
        formValue.patchValue({
          amountPercentage:   this.amountPercentage,
          glAccountId: this.glAccountId ,
          fromDate: this.getEstimateFrom,
          thruDate:this.getEstimateTo
        })
      }, 3000);
      this.spinner.hide();
    } else{
      this.show=false;
    }}
  getGlAccountInitialData() {
    this.spinner.show();
    this.accountsService.getGlAccountInitialData().subscribe((res: any) => {

      this.parentGlAccount = res.data.parentGlAccount;
      this.spinner.hide();
      for (const value of this.parentGlAccount) {
        this.parentGlAccountArray.push({
          label: value.accountName,
          value: value.glAccountId
        })
      }




    })
    
  }
  cancelSubmit() {
    this.router.navigate(["financial/detail-account-category-summary"], { queryParams: { glAccountCategoryId: this.glAccountCategoryId } })
  }
  onSubmit(): void {
    this.spinner.show();
    const fromActualdate = this.createMember.get('fromDate').value;
    this.actualfromdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");
    const throughActualdate = this.createMember.get('thruDate').value;
    this.actualthroughdate = this.datePipe.transform(throughActualdate, "yyyy-MM-dd hh:mm:ss");

    const requestData = {
      "amountPercentage": this.createMember.value.amountPercentage,
      "glAccountId": this.createMember.value.glAccountId,
      "fromDate": this.actualfromdate,
      "thruDate": this.actualthroughdate
    }

    this.accountsService.postAccountCategoryMemberSetting(this.glAccountCategoryId, requestData).subscribe((res: any) => {
    
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.router.navigate(["financial/detail-account-category-summary"], { queryParams: { glAccountCategoryId: this.glAccountCategoryId } })
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  
  }
  onUpdate(): void {
    this.spinner.show();
    const fromActualdate = this.createMember.get('fromDate').value;
    this.actualfromdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");
    const throughActualdate = this.createMember.get('thruDate').value;
    this.actualthroughdate = this.datePipe.transform(throughActualdate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "amountPercentage": this.createMember.value.amountPercentage,
      "glAccountId": this.createMember.value.glAccountId,
      "fromDate": this.actualfromdate,
      "thruDate": this.actualthroughdate
    }
    this.accountsService.updateAccountCategoryMemberSetting(this.glAccountCategoryId, requestData).subscribe((res: any) => {
     
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.router.navigate(["financial/detail-account-category-summary"], { queryParams: { glAccountCategoryId: this.glAccountCategoryId } })
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
