import { Component, OnInit, ViewChild } from '@angular/core';
import { SortEvent } from 'primeng/api';

import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';

import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { request } from 'http';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import Swal from 'sweetalert2'
@Component({
  selector: 'app-detail-account-category-summary',
  templateUrl: './detail-account-category-summary.component.html',
  styleUrls: ['./detail-account-category-summary.component.css']
})
export class DetailAccountCategorySummaryComponent implements OnInit {
  products3: any;
  total=0;
  @ViewChild('closebutton') closebutton;
  show: boolean;
  glAccountCategoryType: any;

  glAccountCategoryTypeArray: any[]=[];
  createAccountCategory: FormGroup;
  glAccountCategoryId: any;
  glAccountCategoryTypeId: any;
  description: any;
  rowNumber=0;
  pageSize= 100;
  pageNo= 1;
  rows = 50;
  search=""
  readonly employessSize={
    pageNo:this.pageNo,
    pageSize:this.pageSize,

  }
  activeCategory=1;
 
  AccountCategoryMemberSetting: any;

  constructor(readonly spinner: NgxSpinnerService,
   
    readonly router: Router,
    readonly toastr: ToastrService,
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
   ) {
    this.createAccountCategory = this._FormBuilder.group({
     
      description: [''],
      glAccountCategoryId:  [''],
      glAccountCategoryTypeId:  ['',[Validators.required]],


    });
    }
    memberList=[{
      value:'1'
    }]
    ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.glAccountCategoryId = params["glAccountCategoryId"];
      this.glAccountCategoryTypeId = params["glAccountCategoryTypeId"];
      this.description = params["description"];
    });
    this.glAccountCategoryMemberSetting();
    this.getGlAccountCategoriesInitialData();
  }
  getGlAccountCategoriesInitialData() {
    this.spinner.show();
    this.accountsService.getGlAccountCategoriesInitialData().subscribe(res => {
      
      this.glAccountCategoryType = res.data.glAccountCategoryType;
      this.spinner.hide();
      for (const value of this.glAccountCategoryType) {
        this.glAccountCategoryTypeArray.push({
          label: value.description,
          value: value.glAccountCategoryTypeId
        })
      }
    })
  
  }
edit(){
  if(this.glAccountCategoryId){
    this.show=true;
    setTimeout(() => {
    
    
      const formValue = this.createAccountCategory;
      formValue.patchValue({
        description:  this.description,
        glAccountCategoryTypeId:  this.glAccountCategoryTypeId,
      })
    
    }, 3000);
  }
}
  
  onSubmit() {
    this.spinner.show();
    const requestData = {
      description:this.createAccountCategory.value.description,
      glAccountCategoryId:"",
      glAccountCategoryTypeId:  this.createAccountCategory.value.glAccountCategoryTypeId,
      
     
    }

    this.accountsService.postGlAccountCategories(requestData).subscribe((res: any) => {
    
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.router.navigate(["financial/gl-account-category-list"])
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  
  }
  onUpdate() {
  
    const requestData = {
      description: this.createAccountCategory.value.description,
      glAccountCategoryId: this.glAccountCategoryId,
      glAccountCategoryTypeId: this.createAccountCategory.value.glAccountCategoryTypeId,
    }

    this.accountsService.updateGlAccountCategories(requestData).subscribe((res: any) => {
    
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.closebutton.nativeElement.click();
        this.router.navigate(["/financial/gl-account-category-list"])
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  
  }
  cancelSubmit(){
    this.router.navigate(["/financial/gl-account-category-list"])
  }  
  glAccountCategoryMemberSetting() {
    this.spinner.show();
    this.accountsService.glAccountCategoryMemberSetting(this.glAccountCategoryId,this.employessSize).subscribe((res: any) => {
      this.AccountCategoryMemberSetting=res.data;
      this.spinner.hide();

    })
   
  }
  deleteglAccountCategoryMember(gid,fromdate) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.accountsService.deleteglAccountCategoryMember(this.glAccountCategoryId,gid,fromdate).subscribe((res:any)=> {
          this.glAccountCategoryMemberSetting();
      },(err) => {
        this.toastr.error(err.error.message);
     })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    }) 
  }
  
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
        const value1 = data1[event.field];
        const value2 = data2[event.field];
        let result = null;

        if (value1 == null && value2 != null){
          result = -1;
        }
        else if (value1 != null && value2 == null){
          result = 1;
        }
        else if (value1 == null && value2 == null) {
            result = 0;
         }
        else if (typeof value1 === 'string' && typeof value2 === 'string') {
            result = value1.localeCompare(value2);
        }
        else {
            result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
        }
        return (event.order * result);
    });
  }
  createMember(){
     this.router.navigate(["financial/create-account-category-member"],{ queryParams: { glAccountCategoryId: this.glAccountCategoryId}})


  }
  onUpdateMember(eglAccountId,efromDate,ethruDate,eamountPercentage){
    this.router.navigate(["financial/create-account-category-member"],{ queryParams: { glAccountCategoryId: this.glAccountCategoryId,glAccountId:eglAccountId,fromDate:efromDate,thruDate:ethruDate,amountPercentage:eamountPercentage}})
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
