<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">



                <span class="color-black pl-1 titlePanels">
                </span>

                <span class="d-flex">

                    <button type="submit" (click)="homeButton();" class=" btn btn-outline-secondary mr-2">Home</button>

                    <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass mr-2">Back</button>
                    <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass ">Cancel</button>
                </span>

            </div>

            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">

                <span class="color-black pl-1 titlePanels">
                </span>

                <span class="color-black pl-1 titlePanels"><a style="cursor: pointer;" (click)="mainPage()">All
                        Communications</a>&nbsp;
                    <a style="cursor: pointer;" (click)="newInternalNote();">| New Message</a>&nbsp;
                    <a style="cursor: pointer;" (click)="newMail();">| New Email</a></span>
            </div>


            <div class="w3-card-4 classCard">
                <div class=" bg-white color-grey">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">

                            <!-- <li [ngClass]="activeCategory==2?'active':''"><a *ngIf="this.communicationEventId">
                                Email    From: admin, CommunicationEventId: {{this.communicationEventId}}
                                </a></li> -->

                      <li [ngClass]="activeCategory==2?'active':''" ><a *ngIf="this.communicationEventId">
                        From: admin, CommunicationEventId: {{this.communicationEventId}}</a></li>



                        </ul>
                    </div>
                    <div class="row">
                        <div class="col-md-6">
                            <form class="w-100" [formGroup]="createComm">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-2 form-group rightForm">
                                                <label for="exampleInputEmail1">

                                                    From:</label>
                                            </div>
                                            <div class="col-lg-10">
                                              admin,   CommunicationEventId: {{this.communicationEventId}}
                                            </div>

                                         


                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-2 form-group rightForm">
                                                <label for="exampleInputEmail1">

                                                    Party To</label>
                                            </div>
                                            <div class="col-lg-4">
                                                <p-dropdown filter="true" optionlabel="label" [options]="party"
                                                    formControlName="partyTo" placeholder="Enter Party To">
                                                </p-dropdown>
                                            </div>

                                         


                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                                       

                                            <div class="col-lg-2 form-group rightForm">
                                                <label for="exampleInputEmail1">Subject

                                                </label>
                                            </div>
                                            <div class="col-lg-4">
                                                <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Subject"
                                                    formControlName="subject">
                                            </div>



                                        </div>
                                    </div>


                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-2 form-group rightForm">
                                                <label for="exampleInputEmail1">Content

                                                </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <textarea id="w3review"
                                                    style="height: 100%!important;padding-left: 15px;"
                                                    placeholder="Content" formControlName="content" rows="6" cols="60">
                                           </textarea>

                                            </div>


                                        </div>
                                    </div>



                                </div>


                            </form>

                        </div>
                        <div class="col-md-6">
                            <div class="divA">
                                <div class="w3-card-4 classCard"
                                    style="border-color: #65787e !important;border: 0.3px solid !important;">
                                    <div class=" bg-white color-grey">


                                        <div>
                                            <div class="container-fluid">
                                                <div class="container-fluid">
                                                    <ul class="tabbing-section tabbing-accordians tabClassA">

                                                        <li [ngClass]="activeCategory==2?'active':''"><a>
                                                                Communication Event Roles</a></li>



                                                    </ul>
                                                </div>
                                                <div class="row">
                                                    <br>
                                                    <div class="card own-account-table" style="    padding: 15px 15px;">
                                                        <p-table [value]="eventRoles" [paginator]="false" [rows]="3"
                                                            scrollHeight="150px" scrollWidth="100%"
                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                            [totalRecords]="total" [scrollable]="true"
                                                            (sortFunction)="customSort($event)" [customSort]="true">
                                                            <ng-template pTemplate="header">
                                                                <tr style="background: #0d3769;">
                                                                    <th [ngStyle]="{'width':'190px'}"
                                                                        pSortableColumn="code">
                                                                        <div class="checkbox-align"
                                                                            style="color: white;">
                                                                            <p-checkbox _ngcontent-egj-c329=""
                                                                                styleclass="custom-checkbox"
                                                                                binary="true"
                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div
                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div
                                                                                        class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox"
                                                                                            name="undefined"
                                                                                            value="undefined">
                                                                                    </div>
                                                                                    <div role="checkbox"
                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span
                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>
                                                                            Party Id
                                                                            <p-sortIcon field="code">
                                                                            </p-sortIcon>
                                                                        </div>

                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                        pSortableColumn="name">
                                                                        <div style="color: white;"> Content Mech Id
                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                        </div>
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                        pSortableColumn="name">
                                                                        <div style="color: white;">Role Type Id
                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                        </div>
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                        pSortableColumn="category">
                                                                        <div style="color: white;">Status Id<p-sortIcon
                                                                                field="category"></p-sortIcon>
                                                                        </div>
                                                                    </th>
                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                        pSortableColumn="price">
                                                                        <div style="color: white;">Action <p-sortIcon
                                                                                field="price"></p-sortIcon>
                                                                        </div>
                                                                    </th>


                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="body" let-product>
                                                                <tr>
                                                                    <td [ngStyle]="{'width':'180px'}">
                                                                        <div class="checkbox-align">
                                                                            <p-checkbox _ngcontent-egj-c329=""
                                                                                styleclass="custom-checkbox"
                                                                                binary="true"
                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                <div
                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                    <div
                                                                                        class="ui-helper-hidden-accessible">
                                                                                        <input type="checkbox"
                                                                                            name="undefined"
                                                                                            value="undefined">
                                                                                    </div>
                                                                                    <div role="checkbox"
                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                        <span
                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                    </div>
                                                                                </div>
                                                                                <!---->
                                                                            </p-checkbox>
                                                                            <span>{{product.partyId}}</span>
                                                                        </div>
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.contactMechId}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.roleTypeId}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        {{product.statusId}}
                                                                    </td>
                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                        <button type="button"
                                                                            class="btn btn-secondary submit-btn ml-2"
                                                                            (click)="deleteCommunicationEventRoles(product)">Remove</button>
                                                                    </td>

                                                                </tr>
                                                            </ng-template>
                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                <tr>
                                                                    <td>
                                                                        <span class="text-center">
                                                                            <h4 class="ml-2">No Record Found
                                                                            </h4>
                                                                        </span>
                                                                    </td>
                                                                </tr>
                                                            </ng-template>
                                                        </p-table>

                                                        <!-- <p class="paginate_data">
                                                            View per page </p> -->
                                                    </div>
                                                    <br>
                                                    <div class="container-fluid">

                                                        <div class="row">
                                                            <form class="w-100" [formGroup]="createCommRole">
                                                                <div
                                                                    class="all-users-infomation font-13 font-medium own-user-select pb-0">
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                                            <div class="col-lg-4 form-group rightFormAB">
                                                                                <label for="exampleInputEmail1">
                                                                                    Party ID</label>
                                                                            </div>
                                                                            <div class="col-lg-4">
                                                                                <p-dropdown filter="true"
                                                                                    optionlabel="label"
                                                                                    [options]="partyIdArray"
                                                                                    formControlName="partyID"
                                                                                    placeholder="Enter Party ID">
                                                                                </p-dropdown>
                                                                            </div>

                                                                            <div class="col-lg-4 form-group rightFormCC">
                                                                                <label for="exampleInputEmail1">
                                                                                    Role Type Id</label>
                                                                            </div>
                                                                            <div class="col-lg-4">
                                                                                <p-dropdown filter="true"
                                                                                    optionlabel="label"
                                                                                    [options]="roleTypeIdPreArray"
                                                                                    formControlName="roleTypeID"
                                                                                    placeholder="Enter Role Type ID">
                                                                                </p-dropdown>
                                                                            </div>


                                                                        </div>
                                                                    </div>
              

                                                                </div>


                                                            </form>
                                                            <div class="main-submit-button mt-0" style="margin-left: -4%;">
                                                                <button type="submit"
                                                                    class="btn btn-secondary submit-btn"
                                                                    (click)="createCommunicationEventRole()">Add
                                                                    Role</button>

                                                            </div>
                                                        </div>

                                                    </div>
                                                </div>

                                            </div>

                                        </div>

                                    </div>
                                </div>
                            </div>
                        </div>


                    </div>

                </div>
                <br>
                <div class="row">
                    <div class="panel-group">

                        <div class="col-md-12">
                            <div class="w3-card-4 classCard">
                                <div class=" bg-white color-grey">
                                    <div class="container-fluid">
                                        <ul class="tabbing-section tabbing-accordians tabClass">

                                            <li [ngClass]="activeCategory==2?'active':''"><a>
                                                    Communication Content</a></li>



                                        </ul>
                                    </div>

                                    <div>
                                        <div class="container-fluid">
                                            <br>
                                            <div class="card own-account-table">
                                                <p-table [value]="communicationContentUploadById" [paginator]="false"
                                                    [rows]="3" scrollHeight="150px" scrollWidth="100%"
                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                    [totalRecords]="total" [scrollable]="true"
                                                    (sortFunction)="customSort($event)" [customSort]="true">
                                                    <ng-template pTemplate="header">
                                                        <tr style="background: #0d3769;">
                                                            <th [ngStyle]="{'width':'190px'}" pSortableColumn="code">
                                                                <div class="checkbox-align" style="color: white;">
                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                        styleclass="custom-checkbox" binary="true"
                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                        <div
                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                            <div class="ui-helper-hidden-accessible">
                                                                                <input type="checkbox" name="undefined"
                                                                                    value="undefined">
                                                                            </div>
                                                                            <div role="checkbox"
                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                <span
                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                            </div>
                                                                        </div>
                                                                        <!---->
                                                                    </p-checkbox>
                                                                    Content Name
                                                                    <p-sortIcon field="code">
                                                                    </p-sortIcon>
                                                                </div>

                                                            </th>

                                                            <th [ngStyle]="{'width':'170px'}"
                                                                pSortableColumn="category">
                                                                <div style="color: white;">Action <p-sortIcon
                                                                        field="price"></p-sortIcon>
                                                                </div>
                                                            </th>



                                                        </tr>
                                                    </ng-template>

                                                    <ng-template pTemplate="body" let-product>
                                                        <tr>
                                                            <td [ngStyle]="{'width':'180px'}">
                                                                <div class="checkbox-align">
                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                        styleclass="custom-checkbox" binary="true"
                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                        <div
                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                            <div class="ui-helper-hidden-accessible">
                                                                                <input type="checkbox" name="undefined"
                                                                                    value="undefined">
                                                                            </div>
                                                                            <div role="checkbox"
                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                <span
                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                            </div>
                                                                        </div>
                                                                        <!---->
                                                                    </p-checkbox>
                                                                    <span>{{product.existingContentId}}</span>

                                                                </div>
                                                            </td>

                                                            <td [ngStyle]="{'width':'170px'}">
                                                                <button type="button"
                                                                    class="btn btn-secondary submit-btn ml-2"
                                                                    (click)="deleteUpload(product)">Remove</button>
                                                            </td>


                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                        <tr>
                                                            <td>
                                                                <span class="text-center">
                                                                    <h4 class="ml-2">No Record Found
                                                                    </h4>
                                                                </span>
                                                            </td>
                                                        </tr>
                                                    </ng-template>
                                                </p-table>
                                                <!-- <p class="paginate_data">
                                                    View per page </p> -->
                                            </div>
                                            <div class="row">
                                                <form class="w-100" [formGroup]="createContent">
                                                    <div
                                                        class="all-users-infomation font-13 font-medium own-user-select pb-0">
                                                        <div class="col-lg-12">
                                                            <div class="row">
                                                                <div class="col-lg-2 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">
                                                                        Existing Content ID</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown filter="true" optionlabel="label"
                                                                        [options]="existingContentIdsArray"
                                                                        formControlName="contentID"
                                                                        placeholder="Enter Existing Content ID">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-2 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">
                                                                        Parent Content ID</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <p-dropdown filter="true" optionlabel="label"
                                                                        [options]="parentContentIdArray"
                                                                        formControlName="parentContent"
                                                                        placeholder="Enter Parent Content ID">
                                                                    </p-dropdown>
                                                                </div>
                                                                <div class="col-lg-2 form-group rightFormA">
                                                                    <label for="exampleInputEmail1">
                                                                        Uploaded File</label>
                                                                </div>
                                                                <div class="col-lg-2">
                                                                    <input type="file" class="form-control"
                                                                        (change)="fileProgress($event)"
                                                                        id="exampleInputEmail1"
                                                                        aria-describedby="emailHelp"
                                                                        formControlName="file" placeholder="Enter file">
                                                                </div>


                                                            </div>
                                                        </div>
                                                      

                                                    </div>


                                                </form>
                                                <div class="main-submit-button mt-0" style="margin-left: -5%;">
                                                    <button type="submit" class="btn btn-secondary submit-btn"
                                                        (click)="createUpload()"> Upload</button>

                                                </div>
                                            </div>
                                        </div>

                                    </div>

                                </div>
                            </div>







                        </div>


                    </div>
                    <div class="main-submit-button" style="margin-left: -7%;">
                        <button type="submit" class="btn btn-secondary submit-btn"
                            (click)="sendSaveCommunicationEventSave()">Save</button>
                            &nbsp; 

                        <button type="submit" class="btn btn-secondary submit-btn"
                            (click)="sendSaveCommunicationEvent()">Send</button>


                    </div>
                </div>
                <br>
            </div>

            <br> <br> <br> <br> <br>



        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>