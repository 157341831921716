import { FormControl,Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AddNewModel {

  constructor(
   
  ) { }

  mapDataToModel(data: any = undefined) {
    const form_data = ({
      approverPartyId:(data && data.approverPartyId) ? data.approverPartyId : null,
      description:(data && data.description) ? data.description : null,
      emplLeaveReasonTypeId:(data && data.emplLeaveReasonTypeId) ? data.emplLeaveReasonTypeId : null,
      fromDate:(data && data.fromDate) ? data.fromDate : null,
      leaveTypeId:(data && data.leaveTypeId) ? data.leaveTypeId : null,
      partyId:(data && data.partyId) ? data.partyId : null,
      thruDate:(data && data.thruDate) ? data.thruDate : null,
      leaveStatus:(data && data.leaveStatus) ? data.leaveStatus : null,
    });
    return this.getFormGroup(form_data);
  }
  getFormGroup(data:any): any {
   return ({
    approverPartyId: new FormControl(data.approverPartyId,[Validators.required]),
    description: new FormControl(data.description),
    emplLeaveReasonTypeId: new FormControl(data.emplLeaveReasonTypeId),
    fromDate: new FormControl(data.fromDate,[Validators.required]),
    leaveTypeId: new FormControl(data.leaveTypeId),
    partyId: new FormControl(data.partyId,[Validators.required]),
    thruDate: new FormControl(data.thruDate,[Validators.required]),
    leaveStatus: new FormControl(data.leaveStatus),
   });
  }

  preparePayload(payload:any): any {
    return payload;
  }
}
