import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { AccountingApService } from '../accounting-ap/accounting-ap.service';

@Component({
  selector: 'app-gl-account-type-payment-type',
  templateUrl: './gl-account-type-payment-type.component.html',
  styleUrls: ['./gl-account-type-payment-type.component.css']
})
export class GlAccountTypePaymentTypeComponent implements OnInit {
  createPayment:FormGroup;
  activeCategory=2;
  partyId: any;
  paymentTypeId: any;
  glAccountTypeId: any;
  paymentTypeIdArray: any[]=[];
  glAccountTypeIdArray: any[]=[];
  glAccountType: any;
  glAccountTypeArray: any[]=[];
  constructor( readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
   
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _ToastrService: ToastrService,) { 
      this.createPayment = this._FormBuilder.group({
        paymentType: [''],
        glAccountType:['']
       
        
      });
    
  }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.partyId = params["partyId"];
     
     
    });
    this.getGlAccountInitialData();
    this.getGlAccountIni();
  }
  getGlAccountIni() {
    this.spinner.show();
    this.accountsService.getGlAccountInitialData().subscribe((res: any) => {

      this.glAccountType = res.data.glAccountType;
      this.spinner.hide();
      for (const value of this.glAccountType) {
        this.glAccountTypeArray.push({
          label: value.description,
          value: value.glAccountTypeId
        })
      }





    })
   
  }
  
  onSubmit() {
    this.spinner.show();
    const requestData = {
     
      "glAccountTypeId":this.createPayment.value.glAccountType,
      "paymentTypeId": this.createPayment.value.paymentType,
      "organizationPartyId":  this.partyId,
      
      
     

    }

    this.accountsService.postpaymentType(requestData).subscribe((res: any) => {
      
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this._Router.navigate(['/financial/detail-organization-gl-summary'], { queryParams: { partyId:  this.partyId } });
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
  getGlAccountInitialData() {
    this.spinner.show();
    this.accountsService.getpaymentTypeInitialData().subscribe((res: any) => {

      this.paymentTypeId = res.data.paymentTypeId;
      this.glAccountTypeId = res.data.glAccountTypeId;
      this.spinner.hide();
      for (const value of this.paymentTypeId) {
        this.paymentTypeIdArray.push({
          label: value.description,
          value: value.paymentTypeId
        })
      }
      for (const value of this.glAccountTypeId) {
        this.glAccountTypeIdArray.push({
          label: value.description,
          value: value.paymentTypeId
        })
      }





    })
   
  }
  cancelSubmit(){
    this._Router.navigate(['/financial/detail-organization-gl-summary'], { queryParams: { partyId:  this.partyId } });
  }

}

