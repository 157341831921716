import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { SortEvent } from 'primeng/api';
import { AccountingApService } from '../accounting-ap/accounting-ap.service';
import { faTrash, faEdit, faSpinner } from '@fortawesome/free-solid-svg-icons';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2'
import * as moment from 'moment';
import { Location } from '@angular/common';
import { MainCatalogService } from 'src/app/catalog-mgmt/main-catalog-mgmt/catalog.service';
import { PayablePaymentService } from '../accounting-ap/payables-payment/payables-payment.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
@Component({
    selector: 'app-detailed-payable-invoice-summary',
    templateUrl: './detailed-payable-invoice-summary.component.html',
    styleUrls: ['./detailed-payable-invoice-summary.component.css']
})
export class DetailedPayableInvoiceSummaryComponent implements OnInit {
    createRoleForm: FormGroup;
    
    products3: any;
    @ViewChild('closebutton') closebutton;
    @ViewChild('closebutton1') closebutton1;
    @ViewChild('closebuttonN') closebuttonN;
    @ViewChild('closebuttonNW') closebuttonNW;

    total=0;
    activeCategory = 1;
    rowNumber=0;
    pageSize = 10;
    rows = 50;
    invoiceId: string;
    invoiceValue: any;
    appliedPayments: any[];
    transactions: any[];
    invoiceRoles: any[];
    downloading: boolean;
    faSpinner = faSpinner;
    faTrash = faTrash;
    faEdit = faEdit;
    invoiceTerms: any[];
    invoiceItems: any[];
    invoiceStatus: any[];
    invoiceType: string;
    statusId: string;
    
    invoiceStatusValue: string;
    isEditable: boolean;
    appliedAmount: {
        appliedAmount: number,
        invoiceAmount: number,
        notAppliedAmount: number
    };
    activeCategoryMain = 2;
    decisionArray = [
        {
            label: 'Yes',
            value: 1
        },
        {
            label: 'No',
            value: 0
        }
    ];

    invoiceItemForm: FormGroup;
    editMode: boolean;
    invoiceItemTypes: any[];
    productIds: any[];
    uomIds: any[];
    updateSalesInvoiceForm:FormGroup
    activeCategoryInv=2;
    invoiceTypeIds: any[];
    invoiceTermForm: FormGroup;

    invoiceTermValue: any;

    invoiceTermId: string;

    invoiceItemSeqId: string;
    featureIds: any[];
    GIAccounts: any[];
    inventoryItems: any[];
    itemValue: any;
    partyIds: any[];
   
    createInvoiceForm: FormGroup;
    invoiceUrl: string;
    isCredit: boolean;
    roleTypeId: string;
    supplierArray: any[]=[];
 
    roleTypeIds: any[];
  
    currencies: any[];
    billingAccounts: any[];
    constructor(
        readonly  _Router: Router,
        readonly _FormBuilder: FormBuilder,
        private _location: Location,
        readonly spinner: NgxSpinnerService,
        readonly _ActivatedRoute: ActivatedRoute,
        readonly _AccountingApService: AccountingApService,
        readonly _ToastrService: ToastrService,
    
        readonly _MainCatalogService: MainCatalogService,
      
        readonly _PayablePaymentService: PayablePaymentService,
        readonly _GlobalResourceService: GlobalResourceService
    ) {
        this.invoiceItemTypes = [];
        this.editMode = false;
        this.invoiceId = '';
        this.updateSalesInvoiceForm = this._FormBuilder.group({
            billingAccountId: [''],
              currencyUomId: [''],
              description: [''],
              dueDate: [new Date()],
              invoiceDate: [new Date()],
              invoiceMessage: [''],
              invoiceTypeId: [''],
              partyId: [''],
              partyIdFrom: [''],
              recurrenceInfoId: [''],
              referenceNumber: [''],
              roleTypeId: [''],
              statusId: ['']
          });
        this.invoiceItemForm = this._FormBuilder.group({
            amount: [''],
            description: [''],
            inventoryItemId: [''],
            invoiceItemTypeId: [''],
            overrideGlAccountId: [''],
            productFeatureId: [''],
            productId: [''],
            quantity: [''],
            taxableFlag: [1],
            uomId: ['']
        });
        this.uomIds = [];
        this.invoiceType = 'payable';
        this.invoiceItemSeqId = '';
        this.featureIds = [];
        this.GIAccounts = [];
        this.inventoryItems = [];
        this.invoiceId = '';
        this.invoiceRoles = [];
        this.invoiceTerms = [];
        this.invoiceItems = [];
        this.invoiceStatus = [];
        this.invoiceType = 'payable';
        this.statusId = '';
        this.invoiceStatusValue = '';
        this.isEditable = true;
        this.transactions = [];
        this.appliedPayments = [];
        this.downloading = false;
        this.appliedAmount = {
            appliedAmount: 0,
            invoiceAmount: 0,
            notAppliedAmount: 0
        };
        this.invoiceTermForm = this._FormBuilder.group({
            description: [''],
            invoiceItemSeqId: [''],
            invoiceTermId: [''],
            termDays: [''],
            termTypeId: [''],
            termValue: [''],
            textValue: [''],
            uomId: ['']
        });
      
        this.uomIds = [];
      
        this.editMode = false;
        this.invoiceType = 'payable';
        this.partyIds = [];
        this.invoiceId = '';
        this.createRoleForm = this._FormBuilder.group({
            datetimePerformed: [new Date()],
            partyId: [''],
            percentage: [''],
            roleTypeId: ['']
        });
        this.roleTypeIds = [];
        this.invoiceType = 'payable';
    }

    
    ngAfterViewInit(): void { this.spinner.show(); }
    ngOnInit(): void {
        this.invoiceId = this._ActivatedRoute.snapshot.queryParams.invoiceId;
        this.invoiceType = window.location.pathname.includes('receiveable') ? 'receiveable' : 'payable';
        this.getInvoiceById();
        this.getInvoiceRoles();
        this.getInvoiceTerms();
        this.getInvoiceItems();
        this.getInvoiceStatus();
        this.getInvoiceItemType();
        this.getProducts();
        this.getFeatureIds();
        this.getGIAccounts();
        this.getUomIds();
        this.getInventoryItem();
        this.getInvoiceTypeIds();
        this.getBillingAccount();
        this.getPartyIdDatas();
        this.getCurrency();
        this.getRoleType();

        this.getPartyId();
        this.getRoleTypes();

        this.editInvoice();
    }
    getPartyId(): void {
        this.spinner.show();
        this._GlobalResourceService.getPartyGroups()
            .then(data => {
                this.spinner.hide();
                this.partyIds = data.data.PersonsGroups.map(value => {
                    return {
                        label: value.partyId,
                        value: value.partyId
                    };
                });
            });
          
    }
    getRoleTypes(): void {
        this.spinner.show();
        this._AccountingApService.getRoleType()
            .then(data => {
                this.spinner.hide();
                this.roleTypeIds = data.data.map(value => {
                    return {
                        label: value.description,
                        value: value.roleTypeId
                    };
                });
            });
          
    }
    submitInvoiceRole(): void {
        this.spinner.show();
        this._AccountingApService.createInvoiceRole(this.invoiceId, {
            ...this.createRoleForm.value, ...{
                datetimePerformed: moment(this.createRoleForm.value.datetimePerformed).format('YYYY-MM-DD HH:mm:ss')
            }
        })
            .then(data => {
                if (data.success) {
                    this._ToastrService.success('created');
                    this.getInvoiceRoles();
                    this.closebuttonN.nativeElement.click();
                    this.createRoleForm.reset();
                    this.spinner.hide();
                    this._Router.navigate([`/financial/${this.invoiceType}/invoices/detailed-summary`], { queryParams: { invoiceId: this.invoiceId } });
                }
            });
           
    }
    // reset(): void {
    //     this.createRoleForm.reset();
    //     this._Router.navigate([`/financial/${this.invoiceType}/invoices/detailed-summary`], { queryParams: { invoiceId: this.invoiceId } });
    // }
    getPartyIdDatas(): void {
        this.spinner.show();
        this._GlobalResourceService.getPartyGroups()
            .then(data => {
                this.spinner.hide();
                this.partyIds = data.data.PersonsGroups.map(value => {
                    return {
                        label: value.partyId,
                        value: value.partyId
                    };
                });
            });
          
    }
    getCurrency(): void {
        this.spinner.show();
        this._GlobalResourceService.getCurrency()
            .then(data => {
                this.spinner.hide();
                this.currencies = data.data.currencyList.map(value => {
                    return {
                        label: value.description,
                        value: value.uomId
                    };
                });
            });
           
    }
    getRoleType(): void {
        this.spinner.show();
        this._GlobalResourceService.getRoleType()
            .then(data => {
                this.spinner.hide();
                this.roleTypeIds = data.data.roleType.map(value => {
                    return {
                        label: value.description,
                        value: value.roleTypeId
                    };
                });
            });
           
    }
    getBillingAccount(): void {
        this.spinner.show();
        this._AccountingApService.getBillingAccount({ pageNo: 1, pageSize: 200 }, {
            accountLimit: '', billingAccountId: '', billingAccountIdSearchType: 'Contains', description: '', descriptionSearchType: 'Contains', fromDate: '', thruDate: ''
        })
            .then(data => {
                this.spinner.hide();
                this.billingAccounts = data.data.map(value => {
                    return {
                        label: value.billingAccountId,
                        value: value.billingAccountId
                    };
                });
            });
           
    }
    editInvoice(){
        this._AccountingApService.getInvoiceById(this.invoiceId)
        .then(data => {
            this.invoiceValue = data.data;
            this.updateSalesInvoiceForm.patchValue({
                ...data.data, ...{
                    dueDate: data.data.dueDate ? new Date(data.data.dueDate) : data.data.dueDate,
                    invoiceDate: data.data.invoiceDate ? new Date(data.data.invoiceDate) : data.data.invoiceDate
                }
            });
        });
    }
    submitInvoice(): void {
        this.spinner.show();
        this._AccountingApService.updateInvoice(this.invoiceId, {
            ...this.updateSalesInvoiceForm.value, ...{
                dueDate: moment(this.updateSalesInvoiceForm.value.dueDate).format('YYY-MM-DD HH:mm:ss'),
                invoiceDate: moment(this.updateSalesInvoiceForm.value.invoiceDate).format('YYYY-MM-DD HH:mm:ss')
            }
        })
            .then(data => {
                if (data.success) {
                    this._ToastrService.success('Updated');
                    this.spinner.hide();
                    this.closebuttonNW.nativeElement.click();
                    this.getInvoiceById();
                    this.updateSalesInvoiceForm.reset();
                   // this._Router.navigate([`/financial/${this.invoiceType}/invoices`]);
                }
            });
           
    }
    getInvoiceTypeIds(): void {
        this.spinner.show();
        this._AccountingApService.getTermType()
            .then(data => {
                this.spinner.hide();
                this.uomIds = data.data.uom.map(value => {
                    return {
                        label: value.description,
                        value: value.uomId
                    };
                });
                this.invoiceTypeIds = data.data.termTypes.map(value => {
                    return {
                        label: value.description,
                        value: value.termTypeId
                    };
                });
            });
          
    }
    updateTerm(): void {
        this.spinner.show();
        this._AccountingApService.updateInvoiceTerm(this.invoiceId, {
            ...this.invoiceTermValue, ...this.invoiceTermForm.value
        })
            .then(data => {
                if (data.success) {
                    this._ToastrService.success('Updated');
                    this.invoiceTermForm.reset();
                    this.spinner.hide();
                    this._Router.navigate(['/financial/payable/invoices/detailed-summary'], { queryParams: { invoiceId: this.invoiceId } });
                }
            });
           
    }
    submitTerm(): void {
        this.spinner.show();
        if (this.editMode) {
            this.updateTerm();
        } else {
            this._AccountingApService.createInvoiceTerm(this.invoiceId, this.invoiceTermForm.value)
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Created');
                        this.spinner.hide();
                        this.invoiceTermForm.reset();
                        this.getInvoiceTerms();
                        this.getInvoiceItems();
                        this.getInvoiceStatus();
                        this.getInvoiceItemType();
                        this.closebutton1.nativeElement.click();
                    }
                });
               
        }
    }
    getInventoryItem(): void {
        this.spinner.show();
        this._AccountingApService.getInventoryItem()
            .then(data => {
                this.spinner.hide();
                this.inventoryItems = data.data.map(value => {
                    return {
                        label: value.inventoryItemId,
                        value: value.inventoryItemId
                    };
                });
            });
          
    }
    getInvoiceItemType(): void {
        this.spinner.show();
        this._AccountingApService.getInvoiceItemTypes()
            .then(data => {
                this.spinner.hide();
                this.invoiceItemTypes = data.data.invoiceItemType.map(value => {
                    return {
                        label: value.description,
                        value: value.invoiceItemTypeId
                    };
                });
            });
           
    }
    getFeatureIds(): void {
        this.spinner.show();
        this._GlobalResourceService.getFeatureIds()
            .then(data => {
                this.spinner.hide();
                this.featureIds = data.data.map(value => {
                    return {
                        label: value.productFeatureId,
                        value: value.productFeatureId
                    };
                });
            });
           
    }
    getGIAccounts(): void {
        this.spinner.show();
        this._PayablePaymentService.getGIAccountAndPaymentMethod()
            .then(data => {
                if (data) {
                    this.spinner.hide();
                    this.GIAccounts = data.data.glAccounts.map(value => {
                        return {
                            label: value.glAccountId,
                            value: value.glAccountId
                        };
                    });
                }
            });
           
    }
    getUomIds(): void {
        this.spinner.show();
        this._AccountingApService.getTermType()
            .then(data => {
                this.spinner.hide();
                this.uomIds = data.data.uom.map(value => {
                    return {
                        label: value.description,
                        value: value.uomId
                    };
                });
            });
           
    }
    getProducts(): void {
        this.spinner.show();
        this._MainCatalogService.getProducts({ pageNo: 1, pageSize: 200 }, { internalName: '', internalNameSearchType: 'Contains', productId: '', productIdSearchType: 'Contains' })
            .then(data => {
                this.spinner.hide();
                this.productIds = data.data.map(value => {
                    return {
                        label: value.productId,
                        value: value.productId
                    };
                });
            });
           
    }
    update(): void {
        this.spinner.show();
        this._AccountingApService.updateInvoiceItem(this.invoiceId, this.invoiceItemSeqId, {
            ...this.itemValue, ...this.invoiceItemForm.value
        })
            .then(data => {
                if (data.success) {
                    this._ToastrService.success('Updated');
                    this.spinner.hide();
                    this.invoiceItemForm.reset();
                    this._Router.navigate([`/financial/${this.invoiceType}/invoices/detailed-summary`], { queryParams: { invoiceId: this.invoiceId } });
                }
            });
           
    }
    reset(): void {
        this.invoiceItemForm.reset();
        this._Router.navigate([`/financial/${this.invoiceType}/invoices/detailed-summary`], { queryParams: { invoiceId: this.invoiceId } });
    }
    submit(): void {
        this.spinner.show();
        if (this.editMode) {
            this.update();
        } else {
            this._AccountingApService.createInvoiceItem(this.invoiceId, this.invoiceItemForm.value)
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Created');
                        this.spinner.hide();
                        this.invoiceItemForm.reset();
                        this.closebutton.nativeElement.click();
                        this.getInvoiceTerms();
        this.getInvoiceItems();
                    }
                });
        }
       
    }
    getInvoiceTerms(): void {
        this.spinner.show();
        this._AccountingApService.getInvoiceTerms(this.invoiceId, { pageNo: 1, pageSize: 200 })
            .then(data => {
                this.invoiceTerms = data.data;
            });
            this.spinner.hide();
    }
    cancelSubmit(){
        this._location.back();
      }
    getInvoiceStatus(): void {
        this.spinner.show();
        this._AccountingApService.getInvoiceTypeAndStatus()
            .then(data => {
                this.invoiceStatus = data.data.invoiceStatus.map(value => {
                    return {
                        label: value.description,
                        value: value.statusId
                    };
                });
            });
            this.spinner.hide();
    }
    getInvoiceById(): void {
        this.spinner.show();
        this._AccountingApService.getInvoiceById(this.invoiceId)
            .then(data => {
                this.invoiceValue = data.data;
                this.transactions = data.data.transactions;
                this.appliedPayments = data.data.appliedPayments.invoiceApplications ? data.data.appliedPayments.invoiceApplications : [];
                this.appliedAmount = {
                    appliedAmount: data.data.appliedPayments.appliedAmount,
                    invoiceAmount: data.data.appliedPayments.invoiceAmount,
                    notAppliedAmount: data.data.appliedPayments.notAppliedAmount
                };
                data.data.status.filter(value => {
                    if (value.statusId === data.data.statusId) {
                        this.invoiceStatusValue = value.status;
                    }
                });
                this.isEditable = data.data.statusId === 'INVOICE_IN_PROCESS' ? true : false;
            });
            this.spinner.hide();
    }
    getInvoiceRoles(): void {
        this.spinner.show();
        this._AccountingApService.getInvoiceRole(this.invoiceId)
            .then(data => {
                this.invoiceRoles = data.data;
                this.spinner.hide();
            });
           
    }
    deleteInvoiceRole(partyId: string, roleTypeId: string): void {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this data.!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
          }).then((result) => {
            if (result.value) {
                this._AccountingApService.deleteInvoiceRole(this.invoiceId, partyId, roleTypeId)
                .then(data => {
                    if (data.success) {
                        
                        this.getInvoiceRoles();
                    } });
              Swal.fire(
                'Deleted!',
                'Deleted Successfully.',
                'success'
              )
           
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire(
                'Cancelled',
                'Your data is safe :)',
                'error'
              )
            }
          })        }
    deleteInvoiceTerm(invoiceTermId: string): void {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this data.!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
          }).then((result) => {
            if (result.value) {
                this._AccountingApService.deleteInvoiceTerm(invoiceTermId)
                .then(data => {
                    if (data.success) {
                       
                        this.getInvoiceTerms();
                    }
                });
              Swal.fire(
                'Deleted!',
                'Deleted Successfully.',
                'success'
              )
           
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire(
                'Cancelled',
                'Your data is safe :)',
                'error'
              )}
          })    }
    getInvoiceItems(): void {
        this.spinner.show();
        this._AccountingApService.getInvoiceItems(this.invoiceId)
            .then(data => {
                this.invoiceItems = data.data;
                this.spinner.hide();
            });
           
    }
    deleteInvoiceItem(invoiceItemSeqId: string): void {
        Swal.fire({
            title: 'Are you sure?',
            text: 'You will not be able to recover this data.!',
            icon: 'warning',
            showCancelButton: true,
            confirmButtonText: 'Yes, delete it!',
            cancelButtonText: 'No, keep it'
          }).then((result) => {
            if (result.value) {
                this._AccountingApService.deleteInvoiceItem(this.invoiceId, invoiceItemSeqId)
                .then(data => {
                    if (data.success) {
                      
                        this.getInvoiceItems();
                    }
                });
              Swal.fire(
                'Deleted!',
                'Deleted Successfully.',
                'success'
              )
            } else if (result.dismiss === Swal.DismissReason.cancel) {
              Swal.fire(
                'Cancelled',
                'Your data is safe :)',
                'error'
              )
            }  })  }

    downloadPDF(): void {
        this.spinner.show();
        this.downloading = true;
        this._AccountingApService.generateInvoicePDF(this.invoiceId)
            .subscribe(value => {
                const blob = new Blob([value], { type: 'application/application/pdf' });
                if (window.navigator && window.navigator.msSaveOrOpenBlob) {
                    window.navigator.msSaveOrOpenBlob(blob);
                    return;
                }
                const data = window.URL.createObjectURL(blob);
                const link = document.createElement('a');
                link.href = data;
                this.spinner.hide();
                link.download = `${this.invoiceId}.pdf`;
                link.dispatchEvent(new MouseEvent('click', { bubbles: true, cancelable: true, view: window }));
                setTimeout(() => {
                    window.URL.revokeObjectURL(data);
                    link.remove();
                }, 100);
                this.downloading = false;
            });
           
    }


    setStatus(event: any): void {
        this.spinner.show();
        this._AccountingApService.setInvoiceStatus(this.invoiceId, this.statusId)
            .then(data => {
                if (data.success) {
                    this.spinner.hide();
                    this._ToastrService.success('Status Updated');
                    this.getInvoiceById();
                }
            });
         
    }
    customSort(event: SortEvent): void {
        event.data.sort((data1, data2) => {
            const value1 = data1[event.field];
            const value2 = data2[event.field];
            let result = null;

            if (value1 == null && value2 != null) {
                result = -1;
            }
            else if (value1 != null && value2 == null) {
                result = 1;
            }
            else if (value1 == null && value2 == null) {
                result = 0;
            }
            else if (typeof value1 === 'string' && typeof value2 === 'string') {
                result = value1.localeCompare(value2);
            }
            else {
                result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
            }
            return (event.order * result);
        });
    }
    changeactiveCategoryFunction(tab: number) {
        this.activeCategory = tab;
    }
    ngOnDestroy(): void {
        this.spinner.hide();
          }
}
