<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Internal Job Posting Applications </span>
                <span class="color-grey search-icons">
                    <div class="suppliers-wrapper">
                        <button type="button" class="btn btn-outline-secondary" data-toggle="modal"
                            data-target="#exampleModal">+ More Filters</button>
                    </div>
                </span>
            </div>
            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                    <div class="suppliers-wrapper">
                        <button type="button" class="btn btn-outline-secondary" routerLinkActive="active"
                            routerLink="/hr/job-requisition/new-internal-job-posting">Create New Internal Job Posting</button>
                       
                        <button type="button" class="btn btn-outline-secondary text-white bg-brown">Import</button>
                   
                    </div>
                </div>
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Application ID</label>
                                            <input type="email" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" >
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Internal Job Posting Status</label>
                                            <div>
                                                <p-dropdown filter="true" optionLabel="label">
                                                    <ng-template let-item1 pTemplate="selectedItem1">
                                                        <span class="label-info"
                                                            style="margin-left: .5em">{{item1.label}}</span>
                                                    </ng-template>
                                                    <ng-template let-car1 pTemplate="item1">
                                                        <div class="ui-helper-clearfix"
                                                            style="position: relative;height: 25px;">
                                                            <div style="font-size:14px;margin-top:4px">{{car1.label}}
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                </p-dropdown>
                                            </div>
                                        </div>
                                    </div>

                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Application Date</label>
                                            <input type="email" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" >
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Approver Party</label>
                                            <input type="email" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" >
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Job Requisition ID</label>
                                            <input type="email" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" >
                                        </div>
                                    </div>

                                </div>
                                <div class="col-lg-12 col-12 main-submit-button "><button _ngcontent-cta-c128=""
                                        type="submit" class="btn btn-secondary submit-btn">Find</button></div>
                                <div class="all-users-infomation font-13 font-medium border-top-grey own-user-select">
                                    <div class="col-lg-12 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Job Posting Application List</label>
                                            <div class="card own-account-table">
                                                <p-table [value]="products3" [paginator]="true" [rows]="rows"
                                                    scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                    [totalRecords]="total" [scrollable]="true"
                                                    (sortFunction)="customSort($event)" [customSort]="true">
                                                    <ng-template pTemplate="header">
                                                        <tr>
                                                            <th [ngStyle]="{'width':'190px'}" pSortableColumn="code">
                                                                <div class="checkbox-align">
                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                        styleclass="custom-checkbox" binary="true"
                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                        <div
                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                            <div class="ui-helper-hidden-accessible">
                                                                                <input type="checkbox" name="undefined"
                                                                                    value="undefined"></div>
                                                                            <div role="checkbox"
                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                <span
                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                            </div>
                                                                        </div>
                                                                        <!---->
                                                                    </p-checkbox>
                                                                    Application ID <p-sortIcon field="code">
                                                                    </p-sortIcon>
                                                                </div>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                            <th pSortableColumn="name"> IJP Status<p-sortIcon
                                                                    field="name"></p-sortIcon>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                            <th pSortableColumn="category">Party ID <p-sortIcon
                                                                    field="category"></p-sortIcon>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                            <th pSortableColumn="quantity"> Application Date<p-sortIcon
                                                                    field="quantity"></p-sortIcon>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                            <th pSortableColumn="price">Approver Party <p-sortIcon
                                                                    field="price"></p-sortIcon>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                            <th pSortableColumn="price">Requisition ID <p-sortIcon
                                                                    field="price"></p-sortIcon>
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    value="" autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                            <th pSortableColumn="price">Action
                                                                <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                    style="visibility:hidden;" value=""
                                                                    autocomplete="off"
                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                    role="textbox" aria-disabled="false"
                                                                    aria-readonly="false">
                                                            </th>
                                                        </tr>
                                                    </ng-template>
                                                    <ng-template pTemplate="body" let-product>
                                                        <tr>
                                                            <td [ngStyle]="{'width':'190px'}">
                                                                <div class="checkbox-align">
                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                        styleclass="custom-checkbox" binary="true"
                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                        <div
                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                            <div class="ui-helper-hidden-accessible">
                                                                                <input type="checkbox" name="undefined"
                                                                                    value="undefined"></div>
                                                                            <div role="checkbox"
                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                <span
                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                            </div>
                                                                        </div>
                                                                        <!---->
                                                                    </p-checkbox>
                                                                    {{product.code}}
                                                                </div>
                                                            </td>
                                                            <td>{{product.name}}</td>
                                                            <td>{{product.category}}</td>
                                                            <td>{{product.quantity}}</td>
                                                            <td>{{product.price | currency: 'USD'}}</td>
                                                            <td>{{product.category}}</td>
                                                            <td>
                                                                <div>
                                                                    <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                                                        height="16" viewBox="0 0 21.223 21.222">
                                                                        <g transform="translate(0 -0.004)">
                                                                            <g transform="translate(0 1.52)">
                                                                                <path
                                                                                    d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                    transform="translate(0 -34.137)" />
                                                                            </g>
                                                                            <g transform="translate(4.548 0.004)">
                                                                                <g transform="translate(0 0)">
                                                                                    <path
                                                                                        d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                        transform="translate(-102.409 -0.004)" />
                                                                                </g>
                                                                            </g>
                                                                        </g>
                                                                    </svg>
                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                        class="ml-20" width="16" height="16"
                                                                        viewBox="0 0 26 26">
                                                                        <defs>
                                                                            <style>
                                                                                .a {
                                                                                    fill: #f44336;
                                                                                }

                                                                                .b {
                                                                                    fill: #fafafa;
                                                                                    margin-left: 20px;
                                                                                }
                                                                            </style>
                                                                        </defs>
                                                                        <path class="a fill-color"
                                                                            d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                        <path class="b"
                                                                            d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                            transform="translate(-147.576 -147.576)" />
                                                                    </svg>
                                                                </div>
                                                            </td>

                                                        </tr>
                                                    </ng-template>
                                                </p-table>
                                               
                                                <p class="paginate_data">
                                                    View per page </p>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>

                        </div>
                    </div>
                </div>
            </div>
          

        </div>
    </div>
</div>
<div class="container demo">
    <div class="modal left fade" id="exampleModal" tabindex="" role="dialog" aria-labelledby="exampleModalLabel"
        aria-hidden="true">
        <div class="modal-dialog" role="document">
            <div class="modal-content">
                <div class="modal-header">
                    <div>More Filters</div>
                    <div class="close-modal"><button type="button" class="btn" data-dismiss="modal">
                            <svg xmlns="http://www.w3.org/2000/svg" width="20.487" height="20.488"
                                viewBox="0 0 20.487 20.488">
                                <g transform="translate(306.243 -227.756)">
                                    <g transform="translate(-306.243 227.757)">
                                        <path
                                            d="M11.375,10.244l8.877-8.877A.8.8,0,0,0,19.121.235L10.243,9.113,1.366.235A.8.8,0,0,0,.234,1.367l8.877,8.877L.234,19.122a.8.8,0,0,0,1.132,1.132l8.877-8.877,8.877,8.877a.8.8,0,0,0,1.132-1.132Z"
                                            transform="translate(0 0)" />
                                    </g>
                                </g>
                            </svg>
                        </button></div>
                </div>
                <div class="modal-body">
                    <div>
                        <div class="all-users-infomation font-13 font-medium own-user-select">
                            <div class="col-lg-12 col-12">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Invoice Id</label>
                                    <div class="more-filters-option row">
                                        <div class="col-lg-5 col-12">
                                            <p-dropdown filter="true" optionLabel="label">
                                                <ng-template let-item pTemplate="selectedItem">
                                                    <span class="label-info"
                                                        style="margin-left: .5em">{{item.label}}</span>
                                                </ng-template>
                                                <ng-template let-car pTemplate="item">
                                                    <div class="ui-helper-clearfix"
                                                        style="position: relative;height: 25px;">
                                                        <div style="font-size:14px;margin-top:4px">{{car.label}}
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </p-dropdown>
                                        </div>
                                        <div class="col-lg-5 col-12">
                                            <input type="email" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" >
                                        </div>
                                        <label class="own-checkbox-container col-lg-2 col-12">Ignore
                                            <input type="checkbox" class="check-box">
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>

                            </div>
                            <div class="col-lg-12 col-12">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Description</label>
                                    <div class="more-filters-option row">
                                        <div class="col-lg-5 col-12">
                                            <p-dropdown filter="true" optionLabel="label">
                                                <ng-template let-item pTemplate="selectedItem">
                                                    <span class="label-info"
                                                        style="margin-left: .5em">{{item.label}}</span>
                                                </ng-template>
                                                <ng-template let-car pTemplate="item">
                                                    <div class="ui-helper-clearfix"
                                                        style="position: relative;height: 25px;">
                                                        <div style="font-size:14px;margin-top:4px">{{car.label}}
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </p-dropdown>
                                        </div>
                                        <div class="col-lg-5 col-12">
                                            <input type="email" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" >
                                        </div>
                                        <label class="own-checkbox-container col-lg-2 col-12">Ignore
                                            <input type="checkbox" class="check-box">
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>

                            </div>
                            <div class="col-lg-12 col-12">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Invoice Type</label>
                                    <div class="more-filters-option row">
                                        <div class="col-lg-5 col-12">
                                            <p-dropdown filter="true" optionLabel="label">
                                                <ng-template let-item pTemplate="selectedItem">
                                                    <span class="label-info"
                                                        style="margin-left: .5em">{{item.label}}</span>
                                                </ng-template>
                                                <ng-template let-car pTemplate="item">
                                                    <div class="ui-helper-clearfix"
                                                        style="position: relative;height: 25px;">
                                                        <div style="font-size:14px;margin-top:4px">{{car.label}}
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </p-dropdown>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="col-lg-12 col-12">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Status</label>
                                    <div class="status-type">
                                        <label class="own-checkbox-container">In process
                                            <input type="checkbox" class="check-box">
                                            <span class="checkmark"></span>
                                        </label>
                                        <label class="own-checkbox-container">Approved
                                            <input type="checkbox" class="check-box">
                                            <span class="checkmark"></span>
                                        </label>
                                        <label class="own-checkbox-container">Sent
                                            <input type="checkbox" class="check-box">
                                            <span class="checkmark"></span>
                                        </label>
                                        <label class="own-checkbox-container">Received
                                            <input type="checkbox" class="check-box">
                                            <span class="checkmark"></span>
                                        </label>
                                        <label class="own-checkbox-container">Ready for Posting
                                            <input type="checkbox" class="check-box">
                                            <span class="checkmark"></span>
                                        </label>
                                        <label class="own-checkbox-container">Paid
                                            <input type="checkbox" class="check-box">
                                            <span class="checkmark"></span>
                                        </label>
                                    </div>
                                </div>
                            </div>
                            <div class="col-lg-12 col-12">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">From Party ID</label>
                                    <div class="more-filters-option row">
                                        <div class="col-lg-5 col-12">
                                            <p-dropdown filter="true" optionLabel="label">
                                                <ng-template let-item pTemplate="selectedItem">
                                                    <span class="label-info"
                                                        style="margin-left: .5em">{{item.label}}</span>
                                                </ng-template>
                                                <ng-template let-car pTemplate="item">
                                                    <div class="ui-helper-clearfix"
                                                        style="position: relative;height: 25px;">
                                                        <div style="font-size:14px;margin-top:4px">{{car.label}}
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </p-dropdown>
                                        </div>

                                        <div class="col-lg-5 col-12">
                                            <p-dropdown filter="true" optionLabel="label">
                                                <ng-template let-item pTemplate="selectedItem">
                                                    <span class="label-info"
                                                        style="margin-left: .5em">{{item.label}}</span>
                                                </ng-template>
                                                <ng-template let-car pTemplate="item">
                                                    <div class="ui-helper-clearfix"
                                                        style="position: relative;height: 25px;">
                                                        <div style="font-size:14px;margin-top:4px">{{car.label}}
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </p-dropdown>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="col-lg-12 col-12">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Billing Account Id</label>
                                    <div class="more-filters-option row">
                                        <div class="col-lg-5 col-12">
                                            <p-dropdown filter="true" optionLabel="label">
                                                <ng-template let-item pTemplate="selectedItem">
                                                    <span class="label-info"
                                                        style="margin-left: .5em">{{item.label}}</span>
                                                </ng-template>
                                                <ng-template let-car pTemplate="item">
                                                    <div class="ui-helper-clearfix"
                                                        style="position: relative;height: 25px;">
                                                        <div style="font-size:14px;margin-top:4px">{{car.label}}
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </p-dropdown>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="col-lg-12 col-12">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">From Date</label>
                                    <div class="more-filters-option row">
                                        <div class="col-lg-5 col-12">
                                            <p-dropdown filter="true" optionLabel="label">
                                                <ng-template let-item pTemplate="selectedItem">
                                                    <span class="label-info"
                                                        style="margin-left: .5em">{{item.label}}</span>
                                                </ng-template>
                                                <ng-template let-car pTemplate="item">
                                                    <div class="ui-helper-clearfix"
                                                        style="position: relative;height: 25px;">
                                                        <div style="font-size:14px;margin-top:4px">{{car.label}}
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </p-dropdown>
                                        </div>

                                        <div class="col-lg-5 col-12">
                                            <p-dropdown filter="true" optionLabel="label">
                                                <ng-template let-item pTemplate="selectedItem">
                                                    <span class="label-info"
                                                        style="margin-left: .5em">{{item.label}}</span>
                                                </ng-template>
                                                <ng-template let-car pTemplate="item">
                                                    <div class="ui-helper-clearfix"
                                                        style="position: relative;height: 25px;">
                                                        <div style="font-size:14px;margin-top:4px">{{car.label}}
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </p-dropdown>
                                        </div>
                                    </div>
                                </div>

                            </div>
                            <div class="col-lg-12 col-12">
                                <div class="form-group">
                                    <label for="exampleInputEmail1">Reference Number</label>
                                    <div class="more-filters-option row">
                                        <div class="col-lg-5 col-12">
                                            <p-dropdown filter="true" optionLabel="label">
                                                <ng-template let-item pTemplate="selectedItem">
                                                    <span class="label-info"
                                                        style="margin-left: .5em">{{item.label}}</span>
                                                </ng-template>
                                                <ng-template let-car pTemplate="item">
                                                    <div class="ui-helper-clearfix"
                                                        style="position: relative;height: 25px;">
                                                        <div style="font-size:14px;margin-top:4px">{{car.label}}
                                                        </div>
                                                    </div>
                                                </ng-template>
                                            </p-dropdown>
                                        </div>
                                    </div>
                                </div>

                            </div>



                        </div>
                    </div>
                </div>
                <div class="suppliers-wrapper modal-footer">
                    <button type="button" class="btn btn-outline-secondary active">Submit</button>
                    <button type="button" class="btn btn-outline-secondary">Cancel</button>
                    <button type="button" class="btn btn-danger ml-2">Reset</button>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 