import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountingApService } from 'src/app/accounting/accounting-ap/accounting-ap.service';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { FinancialService } from 'src/app/services/financial.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { WarehouseService } from 'src/app/warehouse/warehouse/warehouse.service';
import { MainCatalogService } from '../../main-catalog-mgmt/catalog.service';
import { Location } from '@angular/common';
@Component({
  selector: 'app-create-configurations',
  templateUrl: './create-configurations.component.html',
  styleUrls: ['./create-configurations.component.css']
})
export class CreateConfigurationsComponent implements OnInit {
  dropdownValue: { label: string; value: string; }[];

  constructor(readonly spinner: NgxSpinnerService,
    readonly datePipe: DatePipe,
    readonly router: Router,
    readonly toastr: ToastrService,
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly _MainCatalogService: MainCatalogService,
    readonly _FinancialService: FinancialService,
    private _location: Location,

    readonly  _Router: Router,
    readonly _ToastrService: ToastrService,
   
    readonly _AccountingApService: AccountingApService,
    readonly _WarehouseService: WarehouseService,
    readonly addSkillService: AddSkillService,) {
      this.dropdownValue=[{
        label: 'Single Choice',
        value: 'SINGLE'
    },
    {
        label: 'Multi Choice',
        value: 'MULTIPLE'
    },]
     }

  ngOnInit(): void {
  }
  homeButton(){
    this._Router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
 

}
