import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

import { Location } from '@angular/common';

@Component({
  selector: 'app-detail-training-class-type',
  templateUrl: './detail-training-class-type.component.html',
  styleUrls: ['./detail-training-class-type.component.css']
})
export class DetailTrainingClassTypeComponent implements OnInit {
  activeCategory=1;
  skillType: any;
  Des: any;
  date: any;
  id: any;
  tId: any;
  pId: any;

  constructor(
    readonly activatedRoute:ActivatedRoute,
    readonly spinner: NgxSpinnerService,
      private _location: Location,
      readonly router: Router,
  ) { }

  ngOnInit(): void {
  
    this.activatedRoute.queryParams.subscribe(params=> [
      this.tId = params["tId"],
      this.Des=params["des"],
      this.pId=params["pId"],
    
    
    ])
  
  }
  homeButton(){
    this.router.navigate(['/hr/overview-hrm-main'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
 

}

