import { Component, OnInit } from '@angular/core';
import { Router } from '@angular/router';
import { saveAs } from 'file-saver';

@Component({
  selector: 'app-cashiers-only-import',
  templateUrl: './cashiers-only-import.component.html',
  styleUrls: ['./cashiers-only-import.component.css']
})
export class CashiersOnlyImportComponent implements OnInit {

  activeCategory=1;
  total=0;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  userLoginIdArray:any[]=[];
  stateArray: any[]=[];
  state: any;
  roleTypeId: any;
  roleTypeIdArray: any[]=[];
  userLogin: any;
  findPartyArray: any[]=[];
  identification: any;
  identificationArray: any[]=[];
  fileData: File;
  constructor(readonly router: Router) { }

  ngOnInit(): void {
  }

  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    
  }
  goToPrev(){
    this.router.navigate(['/users/banks-only-import']);
  }
  goToNext(){
    this.router.navigate(['/users/branches-only-import']);
  }
}
