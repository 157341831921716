<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               
            </div>

            <div class=" bg-white color-grey">
                <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
             
                    <span class="color-black pl-1" >Edit Group Information </span>
                    <button type="submit" class="btn btn-danger buttonclass" 
                    (click)="onCancel()" routerLink="/crm/leads" routerLinkActive="active">Cancel</button>
                </div>
                <div class="w3-card-4 classCard" >
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100" *ngIf="leadPersonalFormService.lead_group_form" [formGroup]="leadPersonalFormService.lead_group_form">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12" >
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm">
                                                <label for="exampleInputEmail1">Party Id 
                                                </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" id="exampleInputEmail1" class="form-control" [(ngModel)]="partyIdValue" [ngModelOptions]="{standalone: true}" aria-describedby="emailHelp" >
                                           
                                            </div>
                                            <div class="col-lg-3 form-group rightFormA">
                                                <label for="exampleInputEmail1">Group Name <span style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" formControlName="groupName" class="form-control"   id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Group Name">
                                            </div>
                                            
                                           
                                        </div>
                                    </div>
                                   
                                    <div class="col-lg-12" >
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm">
                                                <label for="exampleInputEmail1">Group Name local</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" formControlName="groupLocalName" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Group Name Local">
                                            </div>
                                            
                                            <div class="col-lg-3 form-group rightFormA">
                                                <label for="exampleInputEmail1">Office Site Name</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" formControlName="officeSiteName" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Office Site Name">
                                            </div>
                                        </div>
                                    </div>
                                   
                                    <div class="col-lg-12" >
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm">
                                                <label for="exampleInputEmail1">Annual revenue</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" formControlName="annualRevenue" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Annual Revenue">
                                            </div>
                                            <div class="col-lg-3 form-group rightFormA">
                                                <label for="exampleInputEmail1">Number of employess</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" formControlName="noOfEmployees" class="form-control"  id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Number Of Employess">
                                            </div>
                                           
                                        </div>
                                    </div>
                                  
                                    <div class="col-lg-12" >
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm">
                                                <label for="exampleInputEmail1">Ticker Symbol</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input formControlName="tickerSymbol" class="form-control" optionlabel="label"  placeholder="Ticker symbol">
                                            </div>
                                            
                                            <div class="col-lg-3 form-group rightFormA">
                                                <label for="exampleInputEmail1">Comments</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" formControlName="comments" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Comments">
                                            </div>
                                        </div>
                                    </div>
                                   
                                    <div class="col-lg-12" >
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm">
                                                <label for="exampleInputEmail1">Logo Image Url</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input   formControlName="logoImageUrl" optionlabel="label" class="form-control" placeholder="Logo Image Url">
                                            </div>
                                            
                                            <div class="col-lg-3 form-group rightFormA">
                                                <label for="exampleInputEmail1">Description</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" formControlName="description" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Description">
                                            </div>
                                            
                                        </div>
                                    </div>
                                 
                                    <div class="col-lg-12" >
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm">
                                                <label for="exampleInputEmail1">Currency</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown  filter="true" [options]="currencyArray" formControlName="currencyId"  optionlabel="label"  placeholder="Select Currency"></p-dropdown>
                                            </div>
                                            
                                            <div class="col-lg-3 form-group rightFormA">
                                                <label for="exampleInputEmail1">External Id</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" formControlName="externalId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter External Id">
                                            </div>
                                        </div>
                                    </div>
                                  
                                    <div class="col-lg-12" >
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm">
                                                <label for="exampleInputEmail1">Status Id</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown  filter="true" formControlName="statusId" [options]="crmArray" optionlabel="label"  placeholder="Select Status Id"></p-dropdown>
                                            </div>
                                            
                                           
                                        </div>
                                    </div>
                                   
                                 
                                 
                                   
                                </div>
                               
                              
                               
                                <div class="main-submit-button" style="margin-right: 28%;">
                                    <button type="submit" (click)="onUpdate()"  class="btn btn-secondary submit-btn">Update</button> 
                                  
                                   
                                </div>  
                            </form>
                        </div>
                    </div>
                </div>
                </div>
            </div>
           
        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>
