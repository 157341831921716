import { Component, OnInit, ViewChild } from '@angular/core';
import { AccountsService } from '../../crm/accounts/accounts.service';
import { SortEvent } from 'primeng/api';
import { Router } from '@angular/router';
import { LabelTypesService } from './label-types.service';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';

@Component({
  selector: 'app-label-types',
  templateUrl: './label-types.component.html',
  styleUrls: ['./label-types.component.css']
})
export class LabelTypesComponent implements OnInit {
  products3: any;
  activeCategory = 3;
  activeCategoryMain=2;
  @ViewChild('closebutton') closebutton;
  total=0;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  hasTableArray=[{
    'label':'Y',
    'value':'Y'
  },
  {
    'label':'N',
    'value':'N'
  }]
  InventoryItemLabelTypeList: any = [];
  editMode: boolean;
  parentTypeIdList: any = [];
  inventoryLabelTypes: FormGroup;
  parentTypeIdListArray: any[]=[];

  constructor(readonly accountsService: AccountsService,
    readonly router: Router,
    readonly spinner: NgxSpinnerService,
    readonly LabelTypesService: LabelTypesService,
    readonly toastr: ToastrService,
    readonly _FormBuilder: FormBuilder,
    private _location: Location,
    ) {
    this.inventoryLabelTypes = this._FormBuilder.group({
      inventoryItemLabelTypeId: ['', [Validators.required]],
      parentTypeId: [null],
      hasTable: [null],
      description: [''],
    });
    this.editMode = false;
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.products3 = this.accountsService.customerDetails()
   

    this.getInventoryItemLabelTypeList();
    this.getParentTypeId();
  }
  homeButton(){
    this.router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

  getInventoryItemLabelTypeList() {
    this.spinner.show();
    this.InventoryItemLabelTypeList = [];
    this.LabelTypesService.getInventoryItemLabelTypeList().subscribe((responce) => {
      this.spinner.hide();
      this.InventoryItemLabelTypeList = responce.data;
    });
   
  }
  Main(){
    this.router.navigate(["/facilities/warehouse"])
  }
  Facility(){
    this.router.navigate(["/facilities/facility-group"])
  }
  Inventory(){
    this.router.navigate(["/facilities/inventory-labels"])
  }
  Shipments(){
    this.router.navigate(["/facilities/warehouse-shipment"])
  }
  Label(){
    this.router.navigate(["/facilities/inventory-labels/label-types"])
  }
  InventoryItemLabels(){
    this.router.navigate(["/facilities/inventory-labels"])
  }
  getParentTypeId() {
    this.spinner.show();
    this.LabelTypesService.getParentTypeId().subscribe((responce) => {
      this.spinner.hide();
      this.parentTypeIdList = responce.data;
      for(const value of this.parentTypeIdList) {
        this.parentTypeIdListArray.push({
          label:value.inventoryItemLabelTypeId,
          value:value.inventoryItemLabelTypeId
        })
      }
    });

  }

  onSubmit(): void {
    this.spinner.show();
    if (this.editMode) {
      var id;
      this.LabelTypesService.updateInventoryItemLabelType({ ...this.inventoryLabelTypes.value }).subscribe((res: any) => {
       
        if (res.success) {
          this.toastr.success(res.data.successMessage);
          this.spinner.hide();
          this.inventoryLabelTypes.reset();
          this.getInventoryItemLabelTypeList();
          this.closebutton.nativeElement.click();
          // this.router.navigate(['/facilities/facility-group']);
        }

      }, (err) => {
        this.spinner.hide();
        for (const value of err.error.error) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      });
  
    } else {
      this.LabelTypesService.createInventoryItemLabelType(this.inventoryLabelTypes.value).subscribe((res: any) => {
       
        if (res.success) {
          this.toastr.success(res.data.successMessage);
          this.spinner.hide();
          this.inventoryLabelTypes.reset();
          this.getInventoryItemLabelTypeList();
          this.closebutton.nativeElement.click();

        }
      }, (err) => {
        this.spinner.hide();
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      });
    }
 
  }

  getData(data) {
    this.editMode = true;

    this.inventoryLabelTypes.patchValue({
      inventoryItemLabelTypeId: data.inventoryItemLabelTypeId,
      parentTypeId: data.parentTypeId,
      hasTable: data.hasTable,
      description: data.description,
    });
  }

  scroll(el: HTMLElement) {
    el.scrollIntoView({ behavior: 'smooth' });

  }

  cancel() {
    if (this.editMode) {
      this.editMode = false;
      this.inventoryLabelTypes.patchValue({
        inventoryItemLabelTypeId: [''],
        parentTypeId: [''],
        hasTable: [''],
        description: ['']
      });
    }
    else {
      this.inventoryLabelTypes.patchValue({
        inventoryItemLabelTypeId: [''],
        parentTypeId: [''],
        hasTable: [''],
        description: ['']
      });
    }
  }


  delete(data): void {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        var id = data.inventoryItemLabelTypeId;
        this.LabelTypesService.deleteInventoryItemLabelType(id).subscribe((responce: any) => {
          if (responce.success) {
            this.getInventoryItemLabelTypeList();
          }}, (err) => {
          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + ' ' + value.fieldError);
          } });
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }})  }
  detailPage(eid,eparentId,ehTable,edes,edate) {
    this.router.navigate(["/facilities/inventory-labels/detail-label-summary"], { queryParams: { id: eid,parentId:eparentId,hTable:ehTable,des:edes,date:edate} })
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
