import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { Location } from '@angular/common';

@Component({
  selector: 'app-hrms-dashboard',
  templateUrl: './hrms-dashboard.component.html',
  styleUrls: ['./hrms-dashboard.component.css']
})
export class HRMSDashboardComponent implements OnInit {
  activeCategory=2;
  activeCategoryValue=2;
  constructor(
    readonly router: Router,
    private _location: Location,

  ) { }

  ngOnInit(): void {
  }
  homeButton(){
    this.router.navigate(['/hr/overview-hrm-main'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
}
