import { Component, OnInit, ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';

@Component({
  selector: 'app-segment-id',
  templateUrl: './segment-id.component.html',
  styleUrls: ['./segment-id.component.css']
})
export class SegmentIdComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebuttonA') closebuttonA;
  @ViewChild('closebuttonB') closebuttonB;
  @ViewChild('closebuttonC') closebuttonC;

  activeCategory = 1;
  resData: any;
  total = 0;

  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  sprintTable: any;
  roleForm: FormGroup;
  AddTaskForm: FormGroup;
  projectForm: FormGroup;
  ratesForm: FormGroup;
  updateProfile: FormGroup;
  createUserLogIn: FormGroup;
  addRelatedAccount: FormGroup;
  createNewContactInformation: FormGroup;
  partyAttributes: FormGroup;
  addCheckAccount: FormGroup;
  createBillingAccount: FormGroup;
  addNewCreditCard: FormGroup;
  createGiftCard: FormGroup;
  createEFTAcc: FormGroup;
  CreateDataSourceForm: FormGroup;
  CreateDataSourceTypeForm: FormGroup;
  CreateMarketingCampaignForm: FormGroup;
  CreateMarketingCampaignRoleForm: FormGroup;
  CreateTrackingCodeForm: FormGroup;
  CreateTrackingCodeTypeForm: FormGroup;
  CreateNewSegmentGroupForm: FormGroup;
  SegmentGroupTypeIDs: any;
  SegmentGroupTypeIDsArray: any[]=[];
  ProductStoreIDs: any;
  ProductStoreIDsArray: any[]=[];
  RoleTypeId: any;
  RoleTypeIdArray: any[]=[];
  SegmentGroupById: any;
  segmentId: any;
  SegmentGroupClassification: any;
  partyClassificationGroupId: any;
  partyClassificationGroupIdArray: any[]=[];
  partyClassificationGroupIdForm:FormGroup;
  GeoById: any;
  partyGeoForm:FormGroup;
  CreateNewRoleForm:FormGroup;
  GeoId: any;
  GeoIdArray: any[]=[];
  GeoRoleById: any;
  personId: any;
  partyIdArray: any[]=[];
  id: string;
  allrole: any;
  allroleArray: any[]=[];
  
  constructor(
    public createEventFormService: CreateEventFormService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    private addSkillService: AddSkillService,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router,
    private _location: Location,
  ) {
    this.CreateNewSegmentGroupForm = this._FormBuilder.group({
      SegmentGroupTypeID: [''],
      ProductStoreID: [''],
      Description: [''],
      
    });
    this.CreateNewRoleForm=this._FormBuilder.group({
      partyId: [''],
      roleid: [''],
 
    });
    this.partyClassificationGroupIdForm=this._FormBuilder.group({
      partyClassificationGroupId: [''],
      
    });
    this.partyGeoForm=this._FormBuilder.group({
      geoId: [''],
      
    });
   }

  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params=> {
      this.segmentId = params["segmentId"];
    });
    this.getSegmentGroupTypeIDs();
    this.getProductStoreIDs();
    this.getRoleTypeId();
    this.getSegmentGroupById();
    this.getSegmentGroupClassification();
    this.getpartyClassificationGroupId();
    this.getGeoById();
    this.getGeoId();
    this.getGeoRoleById();
    this.getPartyId();
    this.getRoleAllType();
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/crm/main-overview']);
  }
  getRoleAllType(){
    this.spinner.show();
    this.id="ALL"
      this.accountsService.getRoleMainType(this.id).subscribe((res:any)=> {
        this.allrole = res.data;
        this.spinner.hide();
        for(const value of this.allrole){
          this.allroleArray.push({
            label:value.description,
            value:value.roleTypeId
          })
        }
      
      })
     
     }
     
     createSegmentGroupRole(): void {
      this.spinner.show();
  
      const requestData ={
        "segmentGroupId": this.segmentId,
        "partyId": this.CreateNewRoleForm.value.partyId,
        "roleTypeId": this.CreateNewRoleForm.value.roleid,
  
        }
       
      this.accountsService.createSegmentGroupRole(requestData).subscribe((res: any) => { 
        if (res.success) {
          this.toastr.success("Created Successfully");
          this.spinner.hide();
         // this._Router.navigate(["/marketing/segment-marketing"])
          this.closebuttonB.nativeElement.click();
         this.getGeoRoleById(); 
        }
      }, (err) => {
        this.spinner.hide();
        if(err.error.errors){
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this.toastr.error(err.error.message);
        }
      }); }
  createSegmentGroupGeo(): void {
    this.spinner.show();

    const requestData ={
      "segmentGroupId": this.segmentId,
      "geoId":this.partyGeoForm.value.geoId,

      }
     
    this.accountsService.createSegmentGroupGeo(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
       // this._Router.navigate(["/marketing/segment-marketing"])
        this.closebuttonA.nativeElement.click();
       this.getGeoById(); 
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }
  createSegmentGroupClassification(): void {
    this.spinner.show();

    const requestData ={
      "segmentGroupId": this.segmentId,
      "partyClassificationGroupId":this.partyClassificationGroupIdForm.value.partyClassificationGroupId,

      }
     
    this.accountsService.createSegmentGroupClassification(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
       // this._Router.navigate(["/marketing/segment-marketing"])
        this.closebutton.nativeElement.click();
       this.getSegmentGroupClassification(); 
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }
  createSegmentGroup(): void {
    this.spinner.show();

    const requestData ={
      "segmentGroupId": this.segmentId,
      "description":  this.CreateNewSegmentGroupForm.value.Description,
      "productStoreId":  this.CreateNewSegmentGroupForm.value.ProductStoreID,
      "segmentGroupTypeId":  this.CreateNewSegmentGroupForm.value.SegmentGroupTypeID

      }
     
    this.accountsService.updateSegmentGroup(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebuttonC.nativeElement.click();
        this.getSegmentGroupById();
        // this._Router.navigate(["/marketing/segment-marketing"])
      //  this.closebutton.nativeElement.click();
      //  this.getSegmentGroupFrontPage(); 
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); }
    
    deleteSegmentGroupRole(product) {
    
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          const req={
            "partyId": product.partyId,
            "roleTypeId":  product.roleTypeId,
            "segmentGroupId":this.segmentId,
            "view_INDEX_1": "",
            "view_SIZE_1": ""
           
           
          }
          this.accountsService.deleteSegmentGroupRole(req).subscribe(res => {
            this.getGeoRoleById();
          })
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )
       
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })
    }
    deleteSegmentGroupGeo(product) {
    
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          const req={
            "geoId": product.geoId,
            "segmentGroupId":this.segmentId,
            "view_INDEX_1": "",
            "view_SIZE_1": ""
           
           
          }
          this.accountsService.deleteSegmentGroupGeo(req).subscribe(res => {
            this.getGeoById();
          })
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )
       
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })
    }
    deleteSegmentGroupClassification(product) {
    
      Swal.fire({
        title: 'Are you sure?',
        text: 'You will not be able to recover this data.!',
        icon: 'warning',
        showCancelButton: true,
        confirmButtonText: 'Yes, delete it!',
        cancelButtonText: 'No, keep it'
      }).then((result) => {
        if (result.value) {
          const req={
            "partyClassificationGroupId":product.partyClassificationGroupId,
            "segmentGroupId":this.segmentId,
            "view_INDEX_1": "",
            "view_SIZE_1": ""
           
           
          }
          this.accountsService.deleteSegmentGroupClassification(req).subscribe(res => {
            this.getSegmentGroupClassification();
          })
          Swal.fire(
            'Deleted!',
            'Deleted Successfully.',
            'success'
          )
       
        } else if (result.dismiss === Swal.DismissReason.cancel) {
          Swal.fire(
            'Cancelled',
            'Your data is safe :)',
            'error'
          )
        }
      })
    }
    getSegmentGroupClassification() {
      this.spinner.show();
      this.accountsService.getSegmentGroupClassification(this.segmentId).subscribe(res => {
        this.SegmentGroupClassification = res.data[0].data;
        this.spinner.hide();
      
      })
    }
    getGeoRoleById() {
      this.spinner.show();
      this.accountsService.getGeoRoleById(this.segmentId).subscribe(res => {
        this.GeoRoleById = res.data[0].data;
        this.spinner.hide();
      
      })
    }
    
    getGeoById() {
      this.spinner.show();
      this.accountsService.getGeoById(this.segmentId).subscribe(res => {
        this.GeoById = res.data[0].data;
        this.spinner.hide();
      
      })
    }
    
  getSegmentGroupById() {
    this.spinner.show();
    this.accountsService.getSegmentGroupById(this.segmentId).subscribe(res => {
      this.SegmentGroupById = res.data[0].data[0];
      this.spinner.hide();
      setTimeout(() => {
  
        const formValue = this.CreateNewSegmentGroupForm;
        formValue.patchValue({
      SegmentGroupTypeID:this.SegmentGroupById.segmentGroupTypeId,
      ProductStoreID:this.SegmentGroupById.productStoreId,
      Description:this.SegmentGroupById.description,
  
        })
      
      }, 2000);
    })
  }
  Segment() {
    this._Router.navigate(["/marketing/segment-id"])
  }
  Classification() {
    this._Router.navigate(["/marketing/segment-id"])
  }
  Geo() {
    this._Router.navigate(["/marketing/segment-id"])
  }
  Rule() {
    this._Router.navigate(["/marketing/segment-id"])
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
  
  getpartyClassificationGroupId() {
    this.spinner.show();
    this.accountsService.getpartyClassificationGroupId().subscribe(res => {
      this.partyClassificationGroupId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.partyClassificationGroupId) {
        this.partyClassificationGroupIdArray.push({
          label: value.description,
          value: value.partyClassificationGroupId
        })
      }
    })
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
  
  }
  getGeoId() {
    this.spinner.show();
    this.accountsService.getGeoId().subscribe(res => {
      this.GeoId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.GeoId) {
        this.GeoIdArray.push({
          label: value.geoId,
          value: value.geoId
        })
      }
    })
  }
  
  getSegmentGroupTypeIDs() {
    this.spinner.show();
    this.myContactsService.getSegmentGroupTypeIDs().subscribe(res => {
      this.SegmentGroupTypeIDs = res.data[0].data;
      this.spinner.hide();
      for (const value of this.SegmentGroupTypeIDs) {
        this.SegmentGroupTypeIDsArray.push({
          label: value.segmentGroupTypeId,
          value: value.segmentGroupTypeId
        })
      }
    })
  }
  getProductStoreIDs() {
    this.spinner.show();
    this.myContactsService.getProductStoreIDs().subscribe(res => {
      this.ProductStoreIDs = res.data[0].data;
      this.spinner.hide();
      for (const value of this.ProductStoreIDs) {
        this.ProductStoreIDsArray.push({
          label: value.storeName,
          value: value.productStoreId
        })
      }
    })
  }

  cancelSubmit(){
    this._Router.navigate(['/marketing/segment-marketing'])
  }

  getRoleTypeId() {
    this.spinner.show();
    this.myContactsService.getRoleTypeId().subscribe(res => {
      this.RoleTypeId = res.data[0].node;
      this.spinner.hide();
      for (const value of this.RoleTypeId) {
        this.RoleTypeIdArray.push({
          label: value.roleTypeId,
          value: value.description
        })
      }
    })
  }
}
