<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="margin-bottom: 0 !important;">
               <span class="color-black pl-1 titlePanels">Content</span>
            </div>
            <div class="bg-white color-grey" style="padding-bottom: 70px;">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">

                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px; width: 103%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                            <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategory(1)"><a>
                                Content</a></li>
                            <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategory(2)" ><a>
                                Association</a></li>
                            <li [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategory(3)" ><a>
                                Roles</a></li>
                            <li [ngClass]="activeCategory==4?'active':''"  (click)="changeactiveCategory(4)"><a>
                                Purposes</a></li>
            
                            <li [ngClass]="activeCategory==5?'active':''" (click)="changeactiveCategory(5)"><a>
                                Attributes</a></li>
            
                            <li [ngClass]="activeCategory==6?'active':''"  (click)="changeactiveCategory(6)"><a>
                                WebSites</a></li>
                                        
                            <li [ngClass]="activeCategory==7?'active':''" (click)="changeactiveCategory(7)"><a>
                                Metadata</a></li>
                                        
                            <li [ngClass]="activeCategory==8?'active':''" (click)="changeactiveCategory(8)" ><a>
                                Work Effort</a></li>
            
                            <li [ngClass]="activeCategory==9?'active':''" (click)="changeactiveCategory(9)"><a>
                                Keywords</a></li>

                            
                        </ul>
                    </div>
                    <div class="create-new-leade ">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 w-100">
                                        
                    
                                        <div *ngIf="activeCategory==3">

                                            <div class="create-new-leade mt-2">
                                                <div class="container-fluid">
                                                    <div class="row">
                                                        <form class="w-100">
                                                            <div class="w3-card-4 classCard">
                                                                <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                                                    <button type="button" class="btn btn-outline-secondary active" data-toggle="modal" data-target="#rolesPopup">Create New Roles</button>
                                                                </div><br>
                                                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <form class="w-100" [formGroup]="rolesForm">
                                                                    
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                    
                                                                            <div class="col-lg-3 form-group classInput" >
                                                                                <label for="exampleInputEmail1">
                                                                                    Content ID<span style="color: red;">*</span></label>
                                                                            </div>
                                                                            
                                                                            <div class="col-lg-2" >
                                                                                <p-dropdown filter="true"  optionlabel="label"
                                                                                formControlName="contentID" placeholder="Content ID">
                                                                            </p-dropdown>
                                                                            </div>
                                                                            
                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                <label for="exampleInputEmail1">Party ID<span style="color: red;">*</span>	
                                                                                </label>
                                                                            </div>
                                                                            
                                                                            <div class="col-lg-2" >
                                                                                <p-dropdown filter="true"  optionlabel="label"
                                                                                formControlName="partyID" placeholder="Party ID">
                                                                            </p-dropdown>
                                                                            </div>
                                                    
                                                                            
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                    
                                                                            <div class="col-lg-3 form-group classInput" >
                                                                                <label for="accountLimit">Role Type ID</label>
                                                                            </div>
                                                                            
                                                                            <div class="col-lg-2">
                                                                                <p-dropdown filter="true" placeholder="Role Type ID	"
                                                                                    optionlabel="label"  formControlName="roleTypeID">
                                                                            </p-dropdown>
                                                                            </div>
                                                                            <div class="col-lg-3 form-group classInputA">
                                                                                <label for="exampleInputEmail1">From Date</label>
                                                                            </div>
                                                                            
                                                                            
                                                                            <div class="col-lg-2">
                                                                                <input type="date" class="form-control" id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp" 
                                                                                formControlName="fromDate">
                                                                            </div>
                                                    
                                                                        </div>
                                                                    </div>
                                                                    <div class="col-lg-12">
                                                                        <div class="row">
                                                    
                                                                            <div class="col-lg-3 form-group classInput" >
                                                                                <label for="exampleInputEmail1">Through Date</label>
                                                                            </div>
                                                                            
                                                                            <div class="col-lg-2">
                                                                                <input type="date" class="form-control" id="exampleInputEmail1"
                                                                                aria-describedby="emailHelp" 
                                                                                formControlName="throughDate">
                                                                            </div>
                                                                            
                                                    
                                                                        </div>
                                                                    </div>
                                                                    
                                                                    
                                                                    </form>
                                                                </div>
                    
                                                                <div class="col-lg-12 col-12 main-submit-button " style="    margin-left: -30%;">
                                                                    <button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn">Find</button>&nbsp;
                                                                    <button type="button" class="btn btn-danger ml-2">Reset</button>
                                                                </div>
                                                            </div><br>
                    
                                                            <div class="w3-card-4 classCardB" style="    width: 100%;">
                                                                <header class="w3-container w3-blue">
                                                                    <div class="header-tabs">
                                                                    <h4 class=" common-styling h4M">
                                                                        Roles
                                                                    </h4>
                                                                    
                                                                    </div>
                                                                </header>
                                                            <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select" style="display: block!important;">
                                                                <div class="col-lg-12 col-12">
                                                                    <div class="form-group">
                                                                        
                                                                        <div class="card own-account-table">
                                                                            <p-table [value]="gateway" [paginator]="true" [rows]="rows"
                                                                                scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                [totalRecords]="total" [scrollable]="true"
                                                                                (sortFunction)="customSort($event)" [customSort]="true">
                                                                                <ng-template pTemplate="header">
                                                                                    <tr style="background: #0d3769;">
                                                                                        <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                            <div class="checkbox-align" style="color: white;">
                                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                                    styleclass="custom-checkbox" binary="true"
                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible"><input
                                                                                                                type="checkbox" name="undefined"
                                                                                                                value="undefined"></div>
                                                                                                        <div role="checkbox"
                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span
                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    
                                                                                                </p-checkbox>
                                                                                                
                                                                                                Content ID <p-sortIcon field="code"></p-sortIcon>
                                                                                            </div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value="" autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th pSortableColumn="name" [ngStyle]="{'width':'170px'}"><div style="color: white;">Party ID <p-sortIcon field="name">
                                                                                            </p-sortIcon></div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value="" autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th pSortableColumn="category" [ngStyle]="{'width':'170px'}"><div style="color: white;"> Role Type ID <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon></div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value="" autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th pSortableColumn="price" [ngStyle]="{'width':'170px'}"><div style="color: white;">From Date</div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value="" autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th pSortableColumn="price" [ngStyle]="{'width':'170px'}"><div style="color: white;">Through Date</div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value="" autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                        <th pSortableColumn="price" [ngStyle]="{'width':'170px'}"><div style="color: white;">Action</div>
                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value="" autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                        </th>
                                                                                       
                                                                                        
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="body" let-product>
                                                                                    <tr>
                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                            <div class="checkbox-align">
                                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                                    styleclass="custom-checkbox" binary="true"
                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div class="ui-helper-hidden-accessible"><input
                                                                                                                type="checkbox" name="undefined"
                                                                                                                value="undefined"></div>
                                                                                                        <div role="checkbox"
                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span
                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    
                                                                                                </p-checkbox>
                                                                                                <span  class="account-button" 
                                                                                                    >{{product.paymentGatewayResponseId}}</span>
                                                                                            </div>
                                                                                        </td>
                                                                                        <td [ngStyle]="{'width':'170px'}">{{product.paymentServiceTypeEnumId}}</td>
                                                                                        <td [ngStyle]="{'width':'170px'}">{{product.orderPaymentPreferenceId}}</td>
                                                                                        <td [ngStyle]="{'width':'170px'}">{{product.paymentMethodTypeId}}</td>
                                                                                        <td [ngStyle]="{'width':'170px'}">{{product.paymentMethodId}}</td>
                                                                                        <td [ngStyle]="{'width':'170px'}">{{product.transCodeEnum}}</td>
                                                                                        <td [ngStyle]="{'width':'170px'}">{{product.amount}}</td>
                                                                                        <td [ngStyle]="{'width':'170px'}">{{product.currencyUomId}}</td>
                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                            
                                                                                            <button type="submit" class="btn btn-secondary submit-btn" data-toggle="modal" data-target="#rolesPopup">Update</button>
                                                                                            <button type="submit" class="btn btn-danger ">Delete</button>
                
                  
                                                                                        </td>
                
                                                                                        
                                                                                    </tr>
                                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                                        <tr>
                                                                                            <td>
                                                                                                <span class="text-center">
                                                                                                    <h4 class="ml-2">No Record Found</h4>
                                                                                                </span>
                                                                                            </td>
                                                                                        </tr>
                                                                                    </ng-template>
                                                                                </ng-template>
                                                                            </p-table>
                                                                            <p class="paginate_data">
                                                                                View per page </p>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                            </div>
                                                        </form>
                    
                                                    </div>
                                                </div>
                                            </div>
                                            
                                            
                                            
                                                
                                                
                                            
                                        </div>
                    

                                    </div>
                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
 
 
 
 
 

 <div class="modal fade" id="creatContentPopup" tabindex="-1" role="dialog" aria-labelledby="creatContentPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Create Content</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton1>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Create Content</a>
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createContentForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Content ID	</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="contentID" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Content ID">
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Content Type ID	</label>
                                                    </div>

                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="contentTypeId" 
                                                        placeholder="Choose Content Type ID" >

                                                        </p-dropdown>
                                                        
                                                        
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Owner Content ID	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="ownerContentID" 
                                                        placeholder="Choose Owner Content ID">

                                                        </p-dropdown>
                                                        
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Decorator Content ID	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="decoratorContentID" 
                                                        placeholder="Choose Decorator Content ID" >

                                                        </p-dropdown>
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Instance Of Content ID	</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="instanceContentID" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Instance Of Content ID">
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Data Resource ID	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="dataResourceId" 
                                                        placeholder="Choose Data Resource ID" >
                                                        </p-dropdown>
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Template Data Resource ID	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="templateDataResourceID" 
                                                        placeholder="Choose Template Data Resource ID	
                                                        " >

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Data Source ID	</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="dataSourceID" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Data Source ID">
                                               
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Privilege Enum ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="privilegeEnumID" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Privilege Enum ID">
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Service Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="serviceName" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Service Name">
                                               
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Custom Method ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="customMethodID" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Custom Method ID">
                                               
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Content Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="contentName" class="form-control" id="exampleInputEmail1" 
                                                            aria-describedby="emailHelp" placeholder="Content Name">
                                               
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Description	</label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <input type="email"  formControlName="description" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Description">
                                           
                                                        
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Locale String	
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="localeString" 
                                                        placeholder="Locale String	
                                                        ">

                                                        </p-dropdown>
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Mime Type ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="mimeTypeID" 
                                                        placeholder="Mime Type ID" >

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Character Set ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                        formControlName="characterSetID" 
                                                        placeholder="Character Set ID">

                                                        </p-dropdown>
                                                    </div>

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Child Leaf Count</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="childLeafCount" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Child Leaf Count">
                                           
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Child Branch Count</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="childBranchCount" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Child Branch Count">
                                           
                                                    </div>

                                                   

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Created Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date"  formControlName="createdDate" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Created Date">
                                           
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Created By User	</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="createdByUser" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Created By User">
                                           
                                                    </div>

                                                   

                                                  
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Last Modified Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date"  formControlName="lastModifiedDate" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Last Modified Date">
                                           
                                                    </div>

                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Last Modified By User</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email"  formControlName="lastModifiedByUser" class="form-control" id="exampleInputEmail1" 
                                                        aria-describedby="emailHelp" placeholder="Last Modified By User">
                                                        
                                                    </div>

                                                   

                                                  
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                <button type="submit" class="btn btn-secondary submit-btn" >Update</button>
                                                
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
Article



<div class="modal fade" id="rolesPopup" tabindex="-1" role="dialog" aria-labelledby="rolesPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Add a Role

                </span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg blackcolor-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Add a Role

                                    </a>
                                </li>
                            </ul>
                        </div>
                      
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="rolesForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-3 form-group rightForm" >
                                                        <label for="exampleInputEmail1">
                                                            Content ID<span style="color: red;">*</span></label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2" >
                                                        <p-dropdown filter="true"  optionlabel="label"
                                                        formControlName="contentID" placeholder="Content ID">
                                                    </p-dropdown>
                                                    </div>
                                                    
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Party ID<span style="color: red;">*</span>	
                                                        </label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2" >
                                                        <p-dropdown filter="true"  optionlabel="label"
                                                        formControlName="partyID" placeholder="Party ID">
                                                    </p-dropdown>
                                                    </div>
                            
                                                    
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-3 form-group rightForm" >
                                                        <label for="accountLimit">Role Type ID</label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" placeholder="Role Type ID	"
                                                            optionlabel="label"  formControlName="roleTypeID">
                                                    </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>
                                                    
                                                    
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" 
                                                        formControlName="fromDate">
                                                    </div>
                            
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                            
                                                    <div class="col-lg-3 form-group rightForm" >
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                        aria-describedby="emailHelp" 
                                                        formControlName="throughDate">
                                                    </div>
                                                    
                            
                                                </div>
                                            </div>

                                            <div class="col-lg-5 main-submit-button" style="margin-left: 51%;">
                                                <button type="submit" class="btn btn-secondary submit-btn">Create</button>
                                                <button type="submit" class="btn btn-secondary submit-btn">Update</button>
                                            </div>
                                            
                                            
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>


 <ngx-spinner></ngx-spinner> 

