<div class="container-fluid main-container-wrapper">
  <div class="row">
    <div class="col-12">
      <div class=" bg-white color-grey create-new-table">
        <div style="margin: 4%;font-size: 11px;">
          <ng-wizard [config]="config">

            <ng-wizard-step [title]="'Step 1'" [description]="'Sales Order'"> 
            </ng-wizard-step>

            <ng-wizard-step [title]="'Step 2'" [description]="' Enter Order Currency, Agreements, and Ship Dates '">  
            </ng-wizard-step>
           
          <ng-wizard-step [title]="'Step 3'" [description]="'Create Sales Order'">     
            </ng-wizard-step>
            <ng-wizard-step [title]="'Step 4'" [description]="'Shipping'">     
            </ng-wizard-step>
            <ng-wizard-step [title]="'Step 5'" [description]="'Option'">     
            </ng-wizard-step>
            <ng-wizard-step [title]="'Step 6'" [description]="'Order Term'">     
            </ng-wizard-step>
            <ng-wizard-step [title]="'Step 7'" [description]="'Payment'">     
            </ng-wizard-step>
            <ng-wizard-step [title]="'Step 8'" [description]="'Parties'">     
            </ng-wizard-step>
            <ng-wizard-step [title]="'Step 9'" [description]="'Review Order'">     
            </ng-wizard-step>
           
          
         
          </ng-wizard>
        </div>
        <div>
          <div class="container-fluid">
            <div class="container-fluid">
              <ul class="tabbing-section  tabbing-accordians" style="padding-left: 9px;">
                <li [ngClass]="activeCategory==1?'active':''" ><a>
                  Sales Order : Order Option Settings </a></li>
               
               
              
              </ul>
            </div>
            <div class="row">
              <div class="w-100 port-header-contact create-lead font-medium mb-0 text-white">
                <div class="w-100">
                  <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">
                    <div class="panel panel-default">
                      <div *ngIf="activeCategory==1">
                        <div class="header-tabs">
                          <h4 class=" common-styling">
                            Sales Order : Order Option Settings
                          </h4>
                          <span class="arrow-toggle toggle-arrow detail-created" data-toggle="collapse"
                          href="#seo-collapse">
                        </span>
                        <span style="margin-left: 28%!important" class="edit-buttons" routerLinkActive="active"
                          routerLink="/order/create-sales-continue"> Order Items</span>
                        <span class="edit-buttons" routerLinkActive="active"
                          routerLink="/order/sales-finalize-order">Shipping</span>
                        <span class="edit-buttons">Options</span>
                        <span class="edit-buttons">Order Terms</span>
                        <span class="edit-buttons">Payment </span>
                        <span class="edit-buttons">Parties</span>
                        <span class="edit-buttons">Review Order</span>
                        <span class="edit-buttons" routerLinkActive="active"
                          (click)="finalizeOrder()"> Continue</span>


                         
                         
                        </div>
                        <div class="panel-body">
                          <div class="mt-2">
                            <div class="">
                              <div class="row">
                                <form class="w-100">
                                  <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-4 col-12">
                                      <div class="form-group">
                                        <label class="common-labels">Internal Note
                                        </label>
                                        <input type="text" class="form-control" [(ngModel)]="notes.internalNote"
                                          [ngModelOptions]="{standalone:true}" placeholder="Enter Note">
                                      </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                      <div class="form-group">
                                        <label class="common-labels">Shipping Notes
                                        </label>
                                        <input type="text" class="form-control" [(ngModel)]="notes.shippingNote"
                                          [ngModelOptions]="{standalone:true}" placeholder="Enter Shipping Notes">
                                      </div>
                                    </div>
                                  </div>
                                </form>
                              </div>
                              <h3 class="top-heading">Ship Group Nbr 1</h3>
                              <h4 class="detail-heading">Shipment Method
                              </h4>
                              <div class="radios-sections">
                                <ng-template ngFor let-option [ngForOf]="carrierShipments" let-index="index">
                                  <div class="col-lg-9 pl-0 col-12">
                                    <div class="form-group  d-flex radio-boxes-wrapper">
                                      <input type="radio" [id]="index+'Guaranteed'" name="radio"
                                        (change)="setOption(option.shipmentMethodTypeId,option.partyId,option.shippingEst)">
                                      <label class="common-labels" [for]="index+'Guaranteed'">{{option.partyId}}
                                        {{option.description}} - US${{option.shippingEst}}</label>
                                    </div>
                                  </div>
                                </ng-template>

                                <h4 class="detail-heading">Ship all at once, or 'as available'?</h4>
                                <div class="col-lg-9 pl-0 col-12">
                                  <div class="form-group d-flex radio-boxes-wrapper">
                                    <input type="radio" id="until" name="radio-btns" value="until"
                                      [(ngModel)]="shipmentCustom.shipAtOnce" [ngModelOptions]="{standalone:true}">
                                    <label class="common-labels" for="until">Please wait until the entire order is ready
                                      before shipping.</label>
                                  </div>
                                </div>
                                <div class="col-lg-9 pl-0 col-12">
                                  <div class="form-group d-flex radio-boxes-wrapper">
                                    <input type="radio" id="ship items" name="radio-btns" value="ship items"
                                      [(ngModel)]="shipmentCustom.shipAtOnce" [ngModelOptions]="{standalone:true}">
                                    <label class="common-labels" for="ship items">Please ship items I ordered as they
                                      become available (you may incur additional shipping charges). - US$0.00</label>
                                  </div>
                                </div>
                                <div class="col-lg-4 pl-0 col-12">
                                  <div class="form-group">
                                    <label style="color: #65787E !important;" for="exampleInputEmail1">Ship Before Date
                                    </label>
                                    <div class="">
                                      <p-calendar showSeconds="true" placeholder="Select Date" showTime="true"
                                        hourFormat="24" dateFormat="dd.mm.yy"
                                        [(ngModel)]="shipmentCustom.shipDateBefore"
                                        [ngModelOptions]="{standalone:true}"></p-calendar>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-lg-4 pl-0 col-12">
                                  <div class="form-group">
                                    <label style="color: #65787E !important;" for="exampleInputEmail1">Ship After Date
                                    </label>
                                    <div class="">
                                      <p-calendar [(ngModel)]="shipmentCustom.shipDateAfter"
                                        [ngModelOptions]="{standalone:true}" showSeconds="true"
                                        placeholder="Select Date" showTime="true" hourFormat="24" dateFormat="dd.mm.yy">
                                      </p-calendar>
                                    </div>
                                  </div>
                                </div>
                                <div class="col-lg-4 pl-0 col-12">
                                  <div class="form-group">
                                    <label style="color: #65787E !important;" for="exampleInputEmail1">Special
                                      Instructions</label>
                                    <div>
                                      <textarea name="" id="exampleInputEmail1" class="form-control" cols="30"
                                        [ngModelOptions]="{standalone:true}"
                                        [(ngModel)]="shipmentCustom.specialInstruction" rows="10"></textarea>
                                    </div>
                                  </div>
                                </div>
                                <h4 class="detail-heading">Is This a Gift? </h4>
                                <div class="col-lg-9 pl-0 col-12">
                                  <div class="form-group d-flex radio-boxes-wrapper">
                                    <input type="radio" id="Yes" name="Yes-btns" value="Yes"
                                      [(ngModel)]="shipmentCustom.isGift" [ngModelOptions]="{standalone:true}">
                                    <label class="common-labels" for="Yes">Yes</label>
                                  </div>
                                </div>
                                <div class="col-lg-9 pl-0 col-12">
                                  <div class="form-group d-flex radio-boxes-wrapper">
                                    <input type="radio" id="No" name="Yes-btns" value="No"
                                      [(ngModel)]="shipmentCustom.isGift" [ngModelOptions]="{standalone:true}">
                                    <label class="common-labels" for="No">No</label>
                                  </div>
                                </div>

                                <div class="col-lg-4 pl-0 col-12">
                                  <div class="form-group">
                                    <label style="color: #65787E !important;" for="exampleInputEmail1">Gift
                                      Message</label>
                                    <div>
                                      <textarea name="" id="exampleInputEmail1" class="form-control" cols="30"
                                        [(ngModel)]="shipmentCustom.giftMessage" [ngModelOptions]="{standalone:true}"
                                        rows="10"></textarea>
                                    </div>
                                  </div>
                                </div>

                              </div>
                            </div>
                          </div>
                        </div>
                      </div>
                     
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
  <ngx-spinner></ngx-spinner> 