import { FormControl,Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class PositionTypeModel {

  constructor(
   
  ) { }

  mapDataToModel(data: any = undefined) {
    const form_data = ({
      description:(data && data.description) ? data.description : null,
      emplPositionTypeId:(data && data.emplPositionTypeId) ? data.emplPositionTypeId : null,
      hasTable:(data && data.hasTable) ? data.hasTable : null,
      parentTypeId:(data && data.parentTypeId) ? data.parentTypeId : null,
    });

    return this.getFormGroup(form_data);
  }

  
  getFormGroup(data:any): any {
   return ({
    description: new FormControl(data.description,[Validators.required]),
    emplPositionTypeId: new FormControl(data.emplPositionTypeId),
    hasTable: new FormControl(data.hasTable),
    parentTypeId: new FormControl(data.parentTypeId),
   });
  }

  preparePayload(payload:any): any {
    return payload;
  }
}
