import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';
import { SortEvent } from 'primeng/api';
import Swal from 'sweetalert2'
import { FormBuilder, FormGroup } from '@angular/forms';
import { ProductionService } from '../production.service';
import { DatePipe } from '@angular/common';
@Component({
  selector: 'app-detail-calender',
  templateUrl: './detail-calender.component.html',
  styleUrls: ['./detail-calender.component.css']
})
export class DetailCalenderComponent implements OnInit {
  activeCategory=1;
  activeCategoryMain=2;
  createDay:FormGroup;

  calendarId: any;
  exceptionday: string;
  exceptionDateStartTime: any;
  description: any;
  exceptionCapacity: any;
  usedCapacity: any;
  show: boolean;
  products3: any;
  createCalender:FormGroup;
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebuttonA') closebuttonA;
  @ViewChild('closebuttonB') closebuttonB;
  weekListArray: any[]=[];
  weekList: any;
  calendarWeekId: any;
 
  paymentPartyGroup: any;
  downloading: boolean;
  total=0;
  ReportTax:any[]=[];
  rowNumber=0;
  pageSize= 500;
  pageNo = 1;
  rows = 50;
  search= ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  FinancialTransaction:any[]=[];
  budgetId: any;
  createDayWeek:FormGroup;
  status: string;
  routingId: any;
  taskAssoc: any;
  productLink: any;

  exceptionDateStart: any;
 
  ExceptionDay: any;
  ExceptionWeek: any;
  showException: boolean;
  showWeek: boolean;

  constructor(readonly spinner: NgxSpinnerService,
    readonly _ActivatedRoute: ActivatedRoute,
    private _location: Location,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly  _Router: Router,
  
    readonly datePipe: DatePipe,
 
    readonly _ToastrService: ToastrService,
  
    readonly productionService:ProductionService,
    readonly router: Router) {
      this.createCalender = this._FormBuilder.group({
        CalendarWeekID: [''],
        CalendarID:[''],
        Description:[''],
  
      });
      this.createDay = this._FormBuilder.group({
        exceptionday: [''],
        ManufacturingCalendarCapacity:[''],
        ManufacturingUsedCapacity: [''],
        Description:['']
      
       
       
      });
      this.createDayWeek = this._FormBuilder.group({
        exceptionday: [''],
        CalendarWeekID: [''],
        Description:['']

       
      });
     }

  ngOnInit(): void {
  
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.calendarId = params["calendarId"];
      this.description = params["description"];
      this.calendarWeekId = params["calendarWeekId"];

    });
    this.show=true;
    this.showException=false;
    this.showWeek=false;
    this.getExceptionDay();
    this.getExceptionWeek();
    this.getProdCalenderWeekId();
    this.getProdCalenderWeekIdData();
  }
  getProdCalenderWeekIdData() {
    this.spinner.show();
   
    this.productionService.getProdCalenderWeekId().subscribe(res => {
      this.weekList = res.data;
    
      this.spinner.hide();
      for (const value of this.weekList) {
        this.weekListArray.push({
          label: value.description,
          value: value.calendarWeekId
        })
      }
    
    })
    
  }
  homeButton(){
    this.router.navigate(['/production/main-production'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  onSubmitWeek() {
    this.spinner.show();
    const completeDate = this.createDayWeek.get('exceptionday').value;
    this.exceptionday = this.datePipe.transform(completeDate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "calendarWeekId":this.createDayWeek.value.CalendarWeekID,
      "description": this.createDayWeek.value.Description,
      "exceptionDateStart": this.exceptionday
    }

    this.productionService.postExceptionWeek(this.calendarId,requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Created Successfully");
        this.closebuttonB.nativeElement.click();
        this.getExceptionDay();
    this.getExceptionWeek();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this._ToastrService.error(err.error.debugMessage);
        }
    });

  }
  onUpdateWeek() {
    this.spinner.show();
    const requestData = {
      "calendarWeekId":this.createDayWeek.value.CalendarWeekID,
      "description": this.createDayWeek.value.Description,
      "exceptionDateStart": this.exceptionDateStart
    }
    this.productionService.updateExceptionWeek(this.calendarId,requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Updated Successfully");
        this.closebuttonB.nativeElement.click();
        this.getExceptionDay();
    this.getExceptionWeek();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  onSubmitException() {
    this.spinner.show();
  const completeDate = this.createDay.get('exceptionday').value;
    this.exceptionday = this.datePipe.transform(completeDate, "yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "description": this.createDay.value.Description,
      "exceptionCapacity": this.createDay.value.ManufacturingCalendarCapacity,
      "exceptionDateStartTime": this.exceptionday,
      "usedCapacity": this.createDay.value.ManufacturingUsedCapacity,
    }
    this.productionService.postExceptionDay(this.calendarId,requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Created Successfully");
        this.closebuttonA.nativeElement.click();
        this.getExceptionDay();
    this.getExceptionWeek();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  onUpdateException() {
    this.spinner.show();
    const requestData = {
      "description": this.createDay.value.Description,
      "exceptionCapacity": this.createDay.value.ManufacturingCalendarCapacity,
      "exceptionDateStartTime":  this.exceptionDateStartTime,
      "usedCapacity": this.createDay.value.ManufacturingUsedCapacity,
    }
    this.productionService.updateExceptionDay(this.calendarId,requestData).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this._ToastrService.success("Updated Successfully");
        this.closebuttonA.nativeElement.click();
        this.getExceptionDay();
        this.getExceptionWeek();
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  getProdCalenderWeekId() {
    this.spinner.show();
   
    this.productionService.getProdCalenderWeekId().subscribe(res => {
      this.weekList = res.data;
    
      this.spinner.hide();
      for (const value of this.weekList) {
        this.weekListArray.push({
          label: value.description,
          value: value.calendarWeekId
        })
      }
    
    })
    
  }

  onUpdate() {
    this.spinner.show();
    
    const requestData = {
      "calendarId":  this.createCalender.value.CalendarID,
      "calendarWeekId":  this.createCalender.value.CalendarWeekID,
      "description":  this.createCalender.value.Description,

    }

    this.productionService.updateProdCalender(requestData).subscribe((res: any) => {
     
      if (res.success) {
      
        this.toastr.success("Updated Successfully");
        this.closebutton.nativeElement.click();
        this.router.navigate(['/production/calender-list']);
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });

  }
  edit(){
    const formValue = this.createCalender;
    formValue.patchValue({
      CalendarWeekID:this.calendarWeekId,
      CalendarID: this.calendarId,
      Description: this.description,
     
     
    })
  }

  getExceptionDay() {
    this.spinner.show();
  
    this.productionService.getExceptionDay(this.calendarId,this.finSize).subscribe((res: any) => {
      this.ExceptionDay = res.data;
      this.spinner.hide();
    })
  
  }
  getExceptionWeek() {
    this.spinner.show();
  
    this.productionService.getExceptionWeek(this.calendarId,this.finSize).subscribe((res: any) => {
      this.ExceptionWeek = res.data;
      this.spinner.hide();
    })
  
  }
  
  deleteWeek(time){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {    
        this.productionService.deleteExceptionWeek(this.calendarId,time).subscribe((res: any) => {  
          this.getExceptionWeek();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        ) }}) }
  deleteDay(time){
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {  
        this.productionService.deleteExceptionDay(this.calendarId,time).subscribe((res: any) => { 
          this.getExceptionDay();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        ) } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }}) }
  
  /* createDay() {
    this.router.navigate(["production/create-exception-day"],{ queryParams: { calendarId : this.calendarId}})

  } */
  updateDay(exceptionDateStartTime,description,exceptionCapacity,usedCapacity) {
    this.router.navigate(["production/create-exception-day"],{ queryParams: { calendarId : this.calendarId,exceptionDateStartTime:exceptionDateStartTime,description:description,exceptionCapacity:exceptionCapacity,usedCapacity:usedCapacity}})

  }
  createWeek() {
    this.router.navigate(["production/create-exception-week"],{ queryParams: { calendarId : this.calendarId}})

  }
  updateWeek(exceptionDateStart,description,calendarWeekId) {
    this.router.navigate(["production/create-exception-week"],{ queryParams: { calendarId : this.calendarId,exceptionDateStart:exceptionDateStart,description:description,calendarWeekId:calendarWeekId}})

  }
 
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
