import { Component, OnInit, Inject, ViewChild } from '@angular/core';
import { SortEvent } from 'primeng/api';
import { ActivatedRoute, Router } from '@angular/router';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { JobRequistionService } from './job-requisition.service';
import { DatePipe, DOCUMENT } from '@angular/common';
import { ToastrService } from 'ngx-toastr';
import { NewJobRequistionsService } from '../new-job-requisition/new-job-requisition.service';
import { stretch } from '@amcharts/amcharts4/.internal/core/utils/Math';
import { NgxSpinnerService } from 'ngx-spinner';
import Swal from 'sweetalert2'
import { NewJobRequistionService } from '../new-job-requisition/new-job-requistion-service';
declare var $: any;

import { Location } from '@angular/common';

@Component({
  selector: 'app-job-requisition',
  templateUrl: './job-requisition.component.html',
  styleUrls: ['./job-requisition.component.css']
})
export class JobRequisitionComponent implements OnInit {
  show: boolean;
  @ViewChild('closebutton') closebutton;
  activeCategory = 2;
  activeCategoryTab = 2;
  products3: any;
  total=0;
  activeCategoryMain=1;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  jobRequestion: any;
  qualification: any;
  qualArray: any = [];
  enumArray: any = [];
  Gender: any = [];
  getSkill: any;
  skillTypeArray: any = [];
  partyIdArray: any = [];
  dateIdArray: any = [];
  advanceSearch: {
    examTypeEnumId: string,
    experienceMonthSearchType: string,
    experienceMonthSecond: string,
    experienceMonthSecondSearchType: string,
    experienceMonths: string,
    experienceYearSecond: string,
    experienceYearSecondSearchType: string,
    experienceYears: string,
    experienceYearsSearchType: string,
    jobLocation: string,
    jobLocationSearchType: string,
    jobPostingTypeEnumId: string,
    jobRequisitionId: string,
    skillTypeId: string

  };
  opportunityIdArray: ({ label: string; value: string; text?: undefined; } | { label: string; text: string; value?: undefined; })[];
  rIdArray: any = [];
  isShown=false;
  GenderNew: any[]=[];
  constructor(
    public newJobRequistionService: NewJobRequistionService,
    readonly activatedRoute: ActivatedRoute,
      private _location: Location,
      public datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly jobRequistionService: JobRequistionService,
    readonly newJobRequistionsService: NewJobRequistionsService,
    readonly router: Router,
    readonly toastr: ToastrService,
    readonly spinner:NgxSpinnerService,
    @Inject(DOCUMENT) readonly document: Document
  ) {
    this.advanceSearch = {
      examTypeEnumId: "",
      experienceMonthSearchType: "Equals",
      experienceMonthSecond: "",
      experienceMonthSecondSearchType: "Equals",
      experienceMonths: "",
      experienceYearSecond: "",
      experienceYearSecondSearchType: "Equals",
      experienceYears: "",
      experienceYearsSearchType: "Equals",
      jobLocation: "",
      jobLocationSearchType: "Contains",
      jobPostingTypeEnumId: "",
      jobRequisitionId: "",
      skillTypeId: ""

    };
    this.opportunityIdArray = [
      {
        label: 'Contains',
        value: 'Contains'
      },
      {
        label: 'Equals',
        value: 'Equals'
      },
      {
        label: 'Is Empty',
        value: 'Is Empty'
      },
      {
        label: 'Begins With',
        value: 'Begins With'
      },
    ];
    this.dateIdArray = [
      {
        label: 'Less Than',
        value: 'LessThan'
      },
      {
        label: 'Equals',
        value: 'Equals'
      },

      {
        label: 'Greater Than',
        value: 'GreaterThan'
      },
      {
        label: 'Greater Than Equal To',
        value: 'GreaterThanEqualTo'
      },
      {
        label: 'Less Than Equal To',
        value: 'LessThanEqualTo'
      },
      {
        label: 'Is Empty',
        value: 'IsEmpty'
      }

    ];
  }

  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.getSkillTypeListNew();
    this.getQualificationNew();
    this.GenderNew = [{ label: "Male", value: "M" }, { label: "Female", value: "F" }];
    this.newJobRequistionService.job_requistion_form = null;
    this.newJobRequistionService.jobRequistionForm(this.newJobRequistionService.job_requistion_form);


    this.getJobRequistionList();
    $('.right-arrow').click(function () {
      $(this).toggleClass('rotate-up');
    });
    this.getSkillTypeList();
    this.getQualification();
    this.getJobRequistionListDropdown();
    this.Gender = [{ label: "External Job Posting", value: "JOB_POSTING_EXTR " }, { label: "Internal Job Posting", value: "JOB_POSTING_INTR " }];
  }
  homeButton(){
    this.router.navigate(['/hr/overview-hrm-main'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  getQualificationNew() {
    this.spinner.show();
    this.newJobRequistionsService.getQualification().subscribe(res => {
      this.qualification = res.data;
      this.spinner.hide();
      for (const qual of this.qualification.qualifications) {
        this.qualArray.push({
          label: qual.description,
          value: qual.partyQualTypeId
        })
      }
      for (const exam of this.qualification.examEnumtype) {
        this.enumArray.push({
          label: exam.description,
          value: exam.enumId
        })
      }
    })
    
  }
  jobInfo(loc,res,gender,qual){
    if (loc && res && gender &&qual) {
      this.activeCategoryTab = 3;
    }
    else {
      this.toastr.error("Job Loaction,No. of resource,Gender and Qualification is required");
    }
  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategoryTab = tab;
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  getSkillTypeListNew() {
    this.spinner.show();
    this.newJobRequistionsService.getSkillTypeList().subscribe(res => {
      this.getSkill = res.data;
      this.spinner.hide();
      for (const value of this.getSkill) {
        this.skillTypeArray.push({
          label: value.description,
          value: value.skillTypeId
        })
      }
    })
   
  }
  onSubmit() {
    this.spinner.show();
    const formValue = { ...this.newJobRequistionService.job_requistion_form.value };
    
    this.newJobRequistionsService.createJobRequistion(formValue).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Job Subbmitted Successfully");
        this.spinner.hide();
        this.newJobRequistionService.job_requistion_form.reset();
        this.getJobRequistionList();
        this.closebutton.nativeElement.click();
        // this.router.navigate(['/hr/job-requisition'])
      }

      else if (res.success == false) {
        this.toastr.error(res.message);
        this.spinner.hide();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    })
   
  }

  getQualification() {
    this.spinner.show();
    this.newJobRequistionsService.getQualification().subscribe(res => {
      this.qualification = res.data;
      this.spinner.hide();
      for (const qual of this.qualification.qualifications) {
        this.qualArray.push({
          label: qual.description,
          value: qual.partyQualTypeId
        })
      }
      for (const exam of this.qualification.examEnumtype) {
        this.enumArray.push({
          label: exam.description,
          value: exam.examEnumtype
        })
      }
    })
   
  }

  getSkillTypeList() {
    this.spinner.show();
    this.newJobRequistionsService.getSkillTypeList().subscribe(res => {
      this.getSkill = res.data;
      this.spinner.hide();
      for (const value of this.getSkill) {
        this.skillTypeArray.push({
          label: value.description,
          value: value.skillTypeId
        })
      }
    })
   
  }
  getJobRequistionListDropdown() {
    this.spinner.show();
    const jobRequistion = {
      examTypeEnumId: "",
      experienceMonthSearchType: "Equals",
      experienceMonthSecond: "",
      experienceMonthSecondSearchType: "Equals",
      experienceMonths: "",
      experienceYearSecond: "",
      experienceYearSecondSearchType: "Equals",
      experienceYears: "",
      experienceYearsSearchType: "Equals",
      jobLocation: "",
      jobLocationSearchType: "Contains",
      jobPostingTypeEnumId: "",
      jobRequisitionId: "",
      skillTypeId: ""
    }
    this.jobRequistionService.getJobRequistionList(jobRequistion).subscribe(res => {
      this.jobRequestion = res.data;
      this.spinner.hide();
      for (const value of this.jobRequestion) {
        this.rIdArray.push({
          label: value.jobRequisitionId,
          value: value.jobRequisitionId
        })
      }
    })
   
  }
  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  navigate(id) {
    this.router.navigate(["/hr/job-requisition/create-internal-posting"], { queryParams: { jobRequistionId: id } })
  }
  JobRequisition() {
    this.router.navigate(["/hr/job-requisition"])
  }
  InternalJobPostingApplication() {
    this.router.navigate(["/hr/job-requisition/internal-job-posting"])
  }
  getJobRequistionList() {
    this.spinner.show();
    const jobRequistion = {
      examTypeEnumId: "",
      experienceMonthSearchType: "Contains",
      experienceMonthSecond: "",
      experienceMonthSecondSearchType: "Contains",
      experienceMonths: "",
      experienceYearSecond: "",
      experienceYearSecondSearchType: "Contains",
      experienceYears: "",
      experienceYearsSearchType: "Contains",
      jobLocation: "",
      jobLocationSearchType: "Contains",
      jobPostingTypeEnumId: "",
      jobRequisitionId: "",
      skillTypeId: ""
    }
    this.jobRequistionService.getJobRequistionList(jobRequistion).subscribe(res => {
      this.jobRequestion = res.data;
      this.spinner.hide();
    })
   
  }
  onDelete(id) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.jobRequistionService.onDelete(id).subscribe((res: any) => {
          if (res.success) {
            this.getJobRequistionList();
          }
  
          else if (res.success == false) {
            this.toastr.error(res.message);
          }
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )  } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }})}

  onUpdate(id) {
    this.router.navigate(["/hr/job-requisition/new-job-requisition"], { queryParams: { updateId: id } })
  }
  detailPage(id) {
    this.router.navigate(["/hr/job-requisition/detail-summary-requisition"], { queryParams: { updateId: id } })
  }
  advanceSearchContacts(isPopUp: boolean): void {
    this.spinner.show();
    this.jobRequestion = [];
  
    const jobRequistion = {
      examTypeEnumId: this.advanceSearch.examTypeEnumId ? this.advanceSearch.examTypeEnumId : '',
      experienceMonthSearchType: this.advanceSearch.experienceMonthSearchType ? this.advanceSearch.experienceMonthSearchType : '',
      experienceMonthSecond: this.advanceSearch.experienceMonthSecond ? this.advanceSearch.experienceMonthSecond : '',
      experienceMonthSecondSearchType: this.advanceSearch.experienceMonthSecondSearchType ? this.advanceSearch.experienceMonthSecondSearchType : '',
      experienceMonths: this.advanceSearch.experienceMonths ? this.advanceSearch.experienceMonths : '',
      experienceYearSecond: this.advanceSearch.experienceYearSecond ? this.advanceSearch.experienceYearSecond : '',
      experienceYearSecondSearchType: this.advanceSearch.experienceYearSecondSearchType ? this.advanceSearch.experienceYearSecondSearchType : '',
      experienceYears: this.advanceSearch.experienceYears ? this.advanceSearch.experienceYears : '',
      experienceYearsSearchType: this.advanceSearch.experienceYearsSearchType ? this.advanceSearch.experienceYearsSearchType : '',
      jobLocation: this.advanceSearch.jobLocation ? this.advanceSearch.jobLocation : '',
      jobLocationSearchType: this.advanceSearch.jobLocationSearchType ? this.advanceSearch.jobLocationSearchType : '',
      jobPostingTypeEnumId: this.advanceSearch.jobPostingTypeEnumId ? this.advanceSearch.jobPostingTypeEnumId : '',
      jobRequisitionId: this.advanceSearch.jobRequisitionId ? this.advanceSearch.jobRequisitionId : '',
      skillTypeId: this.advanceSearch.skillTypeId ? this.advanceSearch.skillTypeId : '',
    }
    this.jobRequistionService.getJobRequistionList(jobRequistion).subscribe(res => {
      if (res.success) {
        this.spinner.hide();
        this.jobRequestion = res.data;

      } else {
     
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        
      }
    });
    
  }


  reset() {
    this.spinner.show();
    this.advanceSearch.examTypeEnumId = "";
    this.advanceSearch.experienceMonthSecond = "";
    this.advanceSearch.experienceMonths = "";
    this.advanceSearch.experienceYearSecond = "";
    this.advanceSearch.experienceYears = "";
    this.advanceSearch.jobLocation = "";
    this.advanceSearch.jobPostingTypeEnumId = "";
    this.advanceSearch.jobRequisitionId = "";
    this.advanceSearch.skillTypeId = "";
    const jobRequistion = {
      examTypeEnumId: "",
      experienceMonthSearchType: "Contains",
      experienceMonthSecond: "",
      experienceMonthSecondSearchType: "Contains",
      experienceMonths: "",
      experienceYearSecond: "",
      experienceYearSecondSearchType: "Contains",
      experienceYears: "",
      experienceYearsSearchType: "Contains",
      jobLocation: "",
      jobLocationSearchType: "Contains",
      jobPostingTypeEnumId: "",
      jobRequisitionId: "",
      skillTypeId: ""
    }
    this.jobRequistionService.getJobRequistionList(jobRequistion).subscribe(res => {
      this.jobRequestion = res.data;
      this.spinner.hide();
    })
   
  }
  feautureValue(){
    this.isShown = !this.isShown;
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
