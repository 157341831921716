<div class="container-fluid main-container-wrapper" style="margin-top: 54px !important;">

    <div class="create-new-leade mt-2">
        <div class="container-fluid" style="padding: 0px 0px;">
            <div class="" style="margin-bottom: 10px !important;">
                <span style="font-size: 15px; font-weight: bold; margin-left: 16px!important;">Dispatch Board</span>

            </div> 
            <div class="row mb-3 " style="width: 100%; margin-top: 1%;">
                <div class="col-md-12">
                    <div class="w3-card-4 classCard" style="margin-left: 12px;margin-right: -18px; width: 101%; margin-top: 0%;">
                        <div class="d-flex justify-content-between bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                         style="background-color: rgb(206, 214, 218);  color: black !important; margin-top: -0.5px;">
                            <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important; color: black">
                                Time Scale</p>

                        </div>
                        <div class="col-lg-12">
                            <div class="row font font-12" style="margin-left: 1%;">
                                <label>You can configure the day schedule view on the Dispatch Board
                                    by configuring the start time. The board will open up on that start
                                    time and show you the subsequent 24 hours.
                                </label>
                            </div>

                        </div>
                       

                        <div class="col-lg-12">
                            <div class="row d-flex font-12" style="margin-left: 1% !important;">
                                <div class="col-lg-2">
                                    <label>
                                        View Status at
                                    </label>
                                </div>

                                <div>
                                    <label class="ml-3">
                                        08:00 AM

                                    </label>

                                </div>


                                <div class="col-lg-1 ml-5" style="font-size: 14px;">
                                    <a class="" style=""> Edit</a>
                                </div>
                            </div>

                        </div>
        
                        
                    </div>
                </div>


            </div>

            <div class="row mb-3" style="width: 100%; margin-top: 1%;">
                <div class="col-md-12">
                    <div class="w3-card-4 classCard" style="margin-left: 12px;margin-right: -18px; width: 101%; margin-top: 0%;">
                        <div class="d-flex justify-content-between bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper"
                         style="background-color: rgb(206, 214, 218);  color: black !important; margin-top: 0.5px;">
                            <p class="h-text color-grey" style="margin: 0px 15px -1px -2px !important; color: black">
                                Refresh Interval</p>

                        </div>

                        <div class="col-lg-12">
                            <div class="row font-12" style="margin-left: 1%;">
                                <label>Refresh interval defines how often the Dispatch Board gets
                                    new data and displays it on the screen. Recommended setting is 
                                    5 minutes 
                                </label>
                            </div>

                        </div>

                        <div class="col-lg-12">
                            <div class="row d-flex font-12" style="margin-left: 1% !important;">
                                <div class="col-lg-2">
                                    <label>
                                        Interval
                                    </label>
                                </div>

                                <div>
                                    <label class="ml-3">
                                        5 min

                                    </label>

                                </div>


                                <div class="col-lg-1 ml-5" style="font-size: 14px;">
                                    <a class="" style=""> Edit</a>
                                </div>
                            </div>

                        </div>
        
                        
                    </div>
                </div>


            </div> 
         

            
            <div class="row d-flex" style="background-color: rgb(206, 214, 218);width: 97%;
                margin-left: 15px !important ; padding-top: 4px; margin-left: 15px !important;">
        

                <div class="col-lg-3">
                   <span class="color-grey " style="font-size: 14px !important;color: black; font-weight: bold;">List View Fields for View:</span>
                </div>
                <div class="col-lg-2" style="margin-left: -54px!important;">
                    <p-dropdown filter="true"
                    [options]="" optionlabel="label"
                    
                    >
                    </p-dropdown>
    
                </div>
                <div class="col-lg-3" style="margin-left: 5% !important; font-size: 14px !important;">
                    <label>Restore Default settings</label>
    
                </div>
                <div class="col-lg-4">
                    <a class="btn btn-link" data-toggle="modal" 
                     data-target="#listViewFieldForViewPopup"
                     style=" margin-left: -12px !important;
                    font-size: 14px !important; ">
                        Dispatch Board Setting</a>
                </div>
                    
               
                
            </div>
                    


                     <div class="all-users-infomation font-13 font-medium own-user-select" style="margin-top: -15px !important;">
                        <div class="col-lg-12 col-12">
                            <div class="form-group">
                                <div class="card own-account-table" style="border: 1px solid lightcyan !important;">
                                

                                    
                                    <p-table [value]="dispatchBoardSettings" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                        <ng-template pTemplate="header">
                                            <tr style="background:rgb(206, 214, 218);">
                                                <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                    <div class="checkbox-align" style="color: black;">
                                                        <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                <div class="ui-helper-hidden-accessible">
                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                </div>
                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                </div>
                                                            </div>
                                                            
                                                        </p-checkbox>
                                                        Rank
                                                        <p-sortIcon field="code">
                                                        </p-sortIcon>
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>
                                                
                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                    <div style="color: black;">
                                                        Field
                                                        <p-sortIcon field="name">
                                                        </p-sortIcon>
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>

                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                    <div style="color: black;">
                                                        Width
                                                        <p-sortIcon field="name">
                                                        </p-sortIcon>
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>

                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                    <div style="color: black;">
                                                        Display As
                                                        <p-sortIcon field="name">
                                                        </p-sortIcon>
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>

                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                    <div style="color: black;">
                                                        Show
                                                        <p-sortIcon field="name">
                                                        </p-sortIcon>
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>

                                                <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                    <div style="color: black;">
                                                        Action
                                                        <p-sortIcon field="name">
                                                        </p-sortIcon>
                                                    </div>
                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                </th>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-product>
                                            <tr>
                                                <td [ngStyle]="{'width':'150px'}">
                                                    <div class="checkbox-align">
                                                        <p-checkbox styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                <div class="ui-helper-hidden-accessible">
                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                </div>
                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                </div>
                                                            </div>
                                                            
                                                        </p-checkbox>
                                                        <span  class="account-button"  >
                                                            {{product.rank}}
                                                        </span>

                                                    </div>
                                                </td>
                                                <td [ngStyle]="{'width':'180px'}">{{product.field}}
                                                </td>
                                                <td [ngStyle]="{'width':'180px'}">{{product.width}}
                                                </td>
                    
                                                <td [ngStyle]="{'width':'180px'}">{{product.displayAs}}
                                                </td>
                                                <td [ngStyle]="{'width':'180px'}">{{product.show}}
                                                </td>
                                                <td [ngStyle]="{'width':'180px'}">

                                                    <small style="color: #0d3769 !important;font-weight: 600;cursor:pointer; " 
                                                      data-toggle="modal" data-target="#editDispatchBoardPopup" (click)="getDispatchBoardSettingsById(product)">Edit</small>
                                                    <small style="color: #0d3769 !important;margin-left: 9px;font-weight: 600;cursor:
                                                        pointer; "(click)="deleteDispatchBoardSettings(product)" >Delete</small>
                                                  
                                                    <!-- <button type="button" class="btn btn-secondary submit-btn ml-2" 
                                                    data-toggle="modal" (click)="getDispatchBoardSettingsById(product)" 
                                                    data-target="#editDispatchBoardPopup">Edit</button>

                                                    <button type="button" class="btn btn-secondary submit-btn ml-2" 
                                                    (click)="deleteDispatchBoardSettings(product)">Delete</button> -->
                                              
                                                </td>
                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="emptymessage" let-columns>
                                            <tr>
                                                <td>
                                                    <span class="text-center">
                                                        <h4 class="ml-2">No Record Found</h4>
                                                    </span>
                                                </td>
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                    <p class="paginate_data">
                                        View per page

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div> 


            
        </div>
    </div>
</div>


<div class="modal fade" id="editDispatchBoardPopup" tabindex="-1" role="dialog" aria-labelledby="editDispatchBoardPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Edit Repair Code</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Edit Repair Code</a>
                                </li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="editDispatchBoardForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Show</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true" optionlabel="label" 
                                                                                [options]="yesNoArray" 
                                                                                formControlName="yesNo" 
                                                                                placeholder="Choose Option">

                                                                                </p-dropdown>
                                                    </div>

                                                
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-5 main-submit-button" style="margin-left: 52%;">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                (click)="updateDispatchBoardSettings()">Update</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="listViewFieldForViewPopup" tabindex="-1" role="dialog" aria-labelledby="listViewFieldForViewPopup" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">List View Fields for View</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton1>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey w-100">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>List View Fields for View</a>
                                </li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="listViewFieldForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Feild</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                      <input type="text" class="form-control" placeholder="Enter Field"
                                                        formControlName="field">
                                                    </div>
                                                    
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Width</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                      <input type="text" class="form-control"
                                                        placeholder="Enter Width" formControlName="width">
                                                    </div>



                                                
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">Display As</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                      <input type="text" class="form-control"
                                                       placeholder="Enter Display As" formControlName="displayAs">
                                                    </div>
                                                    
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">Show</label>
                                                    </div>

                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true"
                                                        optionlabel="label"
                                                        placeholder="Choose Show" [options]="yesNoArray"
                                                        formControlName="show">
                                                    </p-dropdown>
                                                    </div>



                                                
                                                </div>
                                            </div>
                                            
                                            <div class="col-lg-2 main-submit-button" style="margin-left: 72%;">
                                                <button type="submit" class="btn btn-secondary submit-btn" (click)="createDispatchBoardSettings()" >Save</button>
                                               

                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>
