import { Component, OnInit } from '@angular/core';
import { FormControl, FormGroup, Validators, FormBuilder } from "@angular/forms";
import { ToastrService } from 'ngx-toastr';
import {Router} from "@angular/router";
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { NgxSpinnerService } from 'ngx-spinner';


@Component({
  selector: 'app-import-user',
  templateUrl: './import-user.component.html',
  styleUrls: ['./import-user.component.css']
})
export class ImportUserComponent implements OnInit {
  fileData: File;
  Editform: FormGroup;
  url: string;
  constructor( readonly router:Router,  readonly accountsService: AccountsService,
    readonly spinner: NgxSpinnerService,
    readonly toastr:ToastrService) { 
      this.url='/users/user-list'
    }

  ngOnInit(): void {
    
  
  }
  fileProgress(fileInput: any) {
    this.fileData = <File>fileInput.target.files[0];
    
  }
  onSubmit(){
    this.spinner.show();
    const formData = new FormData();
    formData.append("file",this.fileData);
    this.accountsService.importUserData(formData).subscribe((res:any)=>{
      if(res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();
        this.router.navigate([this.url])

      }     },(err)=>{
        this.spinner.hide();
        this.toastr.error(err.message);
     })
    
  }
  onCancel(){
    this.router.navigate([this.url])
  }
}
