<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" style="cursor: pointer;" (click)="navigate()" >Employee Position </span>
                <span class="color-black pl-1"> >Employee Position Reporting Structure</span>
                <button  type="submit" (click)="navigate()"   class="btn btn-danger buttonclass" >Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard" >
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li *ngIf="showCreateBtn" [ngClass]="activeCategory==2?'active':''" ><a>
                        Add New Reporting Structure </a></li>
                       
                     
                    </ul>
                  </div>
               
                
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form  [formGroup]="report_employee_form"
                            class="w-100">
                               
                                
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Empl Position Id Reporting To</label>
                                            </div>
                                            <div class="col-lg-2">
                                                
                                            <p-dropdown [options]="partyIDArray" placeholder="Enter Position Id Reporting To "
                                            formControlName="emplPositionIdReportingTo" [(ngModel)]="pDes" filter="true" optionlabel="label">
                                                 
                                           </p-dropdown>
                                            </div>

                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                <label for="exampleInputEmail1">From Date</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input [(ngModel)]="updateDateDate" type="date" formControlName="fromDate" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Start Date">
        
                                            </div>
                                          
                                        </div>
                                    </div>
                                   
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Through Date</label>
                                            </div>
                                            <div class="col-lg-2">
                                                
                                                <input type="date" [(ngModel)]="throughFromDate" formControlName="thruDate" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Through Date">
                                            </div>


                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                <label for="exampleInputEmail1">Comments </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" [(ngModel)]="updateComment" formControlName="comments" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Comment" >
        
                                            </div>
                                         
                                        </div>
                                    </div>
                                   
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Primary Flag <span
                                                    style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-2">
                                                
                                                <p-dropdown [(ngModel)]="flag" [options]="solicitation" placeholder="Enter Primary Flag" formControlName="primaryFlag" filter="true" optionlabel="label">
                                                   
                                                </p-dropdown>
                                            </div>
                                            <div class="col-lg-2"></div>
                                            <div class="col-lg-2 form-group">
                                                <label for="exampleInputEmail1"> </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="hidden" [(ngModel)]="positionID" formControlName="emplPositionId" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" >
                                            </div>
                                        </div>
                                    </div>
                                  
                                 
                                </div>
                                <div class="proper" style=" margin-right: 20%;">
                                    <div class="col-lg-12 col-12 main-submit-button" *ngIf="showCreateBtn">
                                       <button type="submit" (click)="onSubmit()" class="btn btn-secondary submit-btn">Create</button>
                                      
                                   </div>
                                   
                               </div>

                            </form>
                        </div>
                    </div>

                </div>
                </div>

            </div>
           
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>