<div class="container-fluid main-container-wrapper">
    <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
       <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;"> Tax Authority


        </span>
        <span class="color-black pl-1" > >Add Tax Authority


        </span>

        <button type="submit" (click)="cancelSubmit();"  class="btn btn-danger buttonclass" >Cancel</button>
    </div>
  
    
     <div class=" bg-white color-grey">
        <div class="w3-card-4 classCard">
        <div class="container-fluid">
            <ul class="tabbing-section tabbing-accordians tabClass">
  
              <li [ngClass]="activeCategory==2?'active':''" *ngIf="!this.show"  ><a>
                Add Tax Authority


            </a></li>
            <li [ngClass]="activeCategory==2?'active':''"  *ngIf="this.show" ><a>
                Update Tax Authority


            </a></li>
         
               
           
            </ul>
          </div>
       
       
        <div class="create-new-leade mt-2">
            <div class="container-fluid">
                <div class="row">
                    <form  class="w-100" [formGroup]="createTax">
                        <div class="all-users-infomation font-13 font-medium own-user-select">
                            <div class="col-lg-12">
                                <div class="row">

                                    <div class="col-lg-3 form-group classInput">
                                        <label for="exampleInputEmail1">
                         
                                            Geo<span
                                            style="color:red">*</span> </label>
                                    </div>
                                 
                                    <div class="col-lg-2">
                                        <p-dropdown *ngIf="!this.show"  placeholder="Enter Geo" filter="true"
                                        formControlName="Geo"
                                        [options]="getGeoTyArray" optionlabel="label" >
                                                        
                                        </p-dropdown>
                                        <input type="email" *ngIf="this.show"  class="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp" placeholder="Enter Tax ID Format Pattern"
                                        formControlName="Geo" readonly>
                                    </div>
                                    <div class="col-lg-3 form-group classInputA">
                                        <label for="exampleInputEmail1">
                                  
                                            Party<span
                                            style="color:red">*</span></label>
                                    </div>
                                 
                                    <div class="col-lg-2">
                                        <p-dropdown *ngIf="!this.show" placeholder="Enter Party" filter="true"
                                    formControlName="Party"
                                    [options]="partyIdArray " optionlabel="label"  >
                                                    
                                    </p-dropdown>
                                    <input type="email" *ngIf="this.show"  class="form-control" id="exampleInputEmail1"
                                    aria-describedby="emailHelp" placeholder="Enter Tax ID Format Pattern"
                                    formControlName="Party" readonly>
                                    </div>

                                   

                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="row">

                                    <div class="col-lg-3 form-group classInput">
                                        <label for="exampleInputEmail1">
                                    
                                            Require Tax Id For Exemption</label>
                                    </div>
                                 
                                    <div class="col-lg-2">
                                        <p-dropdown  placeholder="Enter Require Tax Id For Exemption" filter="true"
                                        formControlName="exemption"
                                        [options]="exemption" optionlabel="label" >
                                                        
                                        </p-dropdown>
                                    </div>
                                    <div class="col-lg-3 form-group classInputA">
                                        <label for="exampleInputEmail1">Tax ID Format Pattern  </label>
                                    </div>
                                 
                                    <div class="col-lg-2">
                                        <input type="email"   class="form-control" id="exampleInputEmail1"
                                        aria-describedby="emailHelp" placeholder="Enter Tax ID Format Pattern"
                                        formControlName="pattern" >
                                    </div>

                                   

                                </div>
                            </div>
                            <div class="col-lg-12">
                                <div class="row">

                                    <div class="col-lg-3 form-group classInput">
                                        <label for="exampleInputEmail1">
                                  
                                            Include Tax In Price</label>
                                    </div>
                                 
                                    <div class="col-lg-2">
                                        <p-dropdown  placeholder="Enter Include Tax In Price" filter="true"
                                    formControlName="price"
                                    [options]="exemption" optionlabel="label"  >
                                                    
                                    </p-dropdown>
                                    </div>
                                  

                                   

                                </div>
                            </div>
                         
                           
                        </div>
                        

                    </form>
                    <div class="main-submit-button" style="margin-left: 71%;">
                       
                        <button type="submit" *ngIf="!this.show" (click)="onSubmit()"   class="btn btn-secondary submit-btn">Save</button>
                        <button type="submit" *ngIf="this.show" (click)="onUpdate();"   class="btn btn-secondary submit-btn">Update</button>
                       
                    </div>
                </div>
            </div>

        </div>
        </div>

    </div>
 </div>
 <ngx-spinner></ngx-spinner> 