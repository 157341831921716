import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { Location } from '@angular/common';

@Component({
  selector: 'app-detail-prefernce-summary',
  templateUrl: './detail-prefernce-summary.component.html',
  styleUrls: ['./detail-prefernce-summary.component.css']
})
export class DetailPrefernceSummaryComponent implements OnInit {
  portalPageName: any;
  description: any;
  portalPageId: any;

  activeCategory=1;

  constructor(
    readonly activatedRoute:ActivatedRoute,
    readonly spinner: NgxSpinnerService, private _location: Location,
      
    readonly router: Router) { }
   
  ngOnInit(): void {
 
    this.activatedRoute.queryParams.subscribe(params=> [
      this.portalPageName = params["portalPageName"],
      this.description=params["description"],
      this.portalPageId=params["portalPageId"],
    
    ])
   
  }
 
  homeButton(){
    this.router.navigate(['/myPortal/my-portal-my-profile'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
     


}
