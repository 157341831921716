<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels"> Password Reset
                </span>
            </div>
            <div class=" bg-white color-grey">

                <div class="container-fluid">
                    <div class="row">
                        <div class="w3-card-4 classCard" style="width: 100%;">

                            <div
                                class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                                <p class="h-text"> Update Password </p>

                            </div>

                            <form class="w-100" [formGroup]="changePassword">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightForm">
                                                <label for="exampleInputEmail1">
                                                    User Login Id</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text" formControlName="UserLoginId" disabled
                                                    class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter User Login Id">
                                            </div>

                                            <div class="col-lg-3 form-group rightForm">
                                                <label for="exampleInputEmail1">
                                                    Current Password</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="password" formControlName="CurrentPassword"
                                                    class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Current Password">
                                            </div>
                                           

                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightFormA">
                                                <label for="exampleInputEmail1">
                                                    New Password</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="password" formControlName="NewPassword" class="form-control"
                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                    placeholder="Enter New Password">
                                            </div>
                                            <div class="col-lg-3 form-group rightForm">
                                                <label for="exampleInputEmail1">
                                                    Verify New Password</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text" formControlName="VerifyNewPassword"
                                                    class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp"
                                                    placeholder="Enter Verify New Password">
                                            </div>
                                           

                                        </div>
                                    </div>
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group rightFormA">
                                                <label for="exampleInputEmail1">
                                                    Password Hint</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="text" formControlName="PasswordHint" class="form-control"
                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                    placeholder="Enter Password Hint">
                                            </div>
                                            <div class="col-lg-3 form-group rightForm">
                                                <label for="exampleInputEmail1">
                                                    Require Password Change</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown [options]="EnabledArray" filter="true"
                                                    formControlName="RequirePasswordChange"
                                                    placeholder="Enter Require Password Change" name="Parent Type ID">
                                                </p-dropdown>
                                            </div>


                                        </div>
                                    </div>
                                </div>
                            </form>
                            <div class="main-submit-button" style="margin-right: 24%;">

                                <button type="submit" (click)="onSubmit();" class="btn btn-secondary submit-btn">
                                    Reset Password</button>

                                    <button type="submit" *ngIf="showLoginBtn" (click)="login();" class="btn btn-secondary submit-btn">
                                       >> Login With New Password</button>
                            </div>

                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>