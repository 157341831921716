import { FormControl,Validators } from '@angular/forms';
import { Injectable } from '@angular/core';

@Injectable({
  providedIn: 'root'
})
export class AddEmployeePositionModel {

  constructor(
   
  ) { }

  mapDataToModel(data: any = undefined) {
    const form_data = ({
      internalOrganization:(data && data.internalOrganization) ? data.internalOrganization : null,
      actualFromDate:(data && data.actualFromDate) ? data.actualFromDate : null,
      actualThruDate:(data && data.actualThruDate) ? data.actualThruDate : null,
      budgetId:(data && data.budgetId) ? data.budgetId : null,
      budgetItemSeqId:(data && data.budgetItemSeqId) ? data.budgetItemSeqId : null,
      empPositionId:(data && data.empPositionId) ? data.empPositionId : null,
      empPositionTypeId:(data && data.empPositionTypeId) ? data.empPositionTypeId : null,
      exemptFlag:(data && data.exemptFlag) ? data.exemptFlag : null,
      fromDate:(data && data.fromDate) ? data.fromDate : null,
      fulltimeFlag:(data && data.fulltimeFlag) ? data.fulltimeFlag : null,
      partyId:(data && data.partyId) ? data.partyId : null,  
      salaryFlag:(data && data.salaryFlag) ? data.salaryFlag : null,
      statusId:(data && data.statusId) ? data.statusId : null,
      temporaryFlag:(data && data.temporaryFlag) ? data.temporaryFlag : null,
      thruDate:(data && data.thruDate) ? data.thruDate : null,
    });

    
    return this.getFormGroup(form_data);
  }

  
  getFormGroup(data:any): any {
   return ({
    internalOrganization:new FormControl(data.internalOrganization,[Validators.required]),
    actualFromDate: new FormControl(data.actualFromDate,[Validators.required]),
    actualThruDate: new FormControl(data.actualThruDate),
    budgetId: new FormControl(data.budgetId),
    budgetItemSeqId: new FormControl(data.budgetItemSeqId),
    empPositionId: new FormControl(data.empPositionId),
    empPositionTypeId: new FormControl(data.empPositionTypeId,[Validators.required]),
    exemptFlag: new FormControl(data.exemptFlag,[Validators.required]),
    fromDate: new FormControl(data.fromDate),
    fulltimeFlag: new FormControl(data.fulltimeFlag),
    partyId: new FormControl(data.partyId),
    salaryFlag: new FormControl(data.salaryFlag,[Validators.required]),
    statusId: new FormControl(data.statusId),
    temporaryFlag: new FormControl(data.temporaryFlag,[Validators.required]),
    thruDate: new FormControl(data.thruDate),
   
   });
  }

  preparePayload(payload:any): any {
    return payload;
  }
}
