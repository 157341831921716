import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder ,FormGroup,Validators} from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { NgxSpinner } from 'ngx-spinner/lib/ngx-spinner.enum';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';  import { Location } from '@angular/common';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MergeContactsService } from 'src/app/crm/contacts/merge-contacts/merge-contacts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';


@Component({
  selector: 'app-configure-warehouse-detail',
  templateUrl: './configure-warehouse-detail.component.html',
  styleUrls: ['./configure-warehouse-detail.component.css']
})
export class ConfigureWarehouseDetailComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton1') closebutton1;

  resData: any;
  total=0;
 
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  addNewForm: FormGroup;
  planningWareHouseId: any;
  planningWareHousesById: any;
  currencyUomIds: any[];
  personId: any;
  partyIdArray: any[]=[];  InitialManager: any;
  InitialManagerArray: any[]=[];
  inventoryItemArray: { label: string; value: string; }[];
  primaryWarehouse: any;
  primaryWarehouseArray: any[]=[];
  primaryWarehouseId: any;

  constructor(
    public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,    private _location: Location,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly mergeContactsService: MergeContactsService,
    readonly _ToastrService: ToastrService,
    readonly addSkillService: AddSkillService,
    readonly _FormBuilder: FormBuilder,
        readonly _GlobalResourceService: GlobalResourceService,
        readonly toastr: ToastrService,
    readonly spinner: NgxSpinnerService,
    readonly _Router: Router
  ) 
  {

    this.addNewForm=this._FormBuilder.group({
      primaryWarehouse:"",
      Name:"",
      Owner:"",
      Currency:"",
      inventoryItemType:"",
      initialManager:"",
      Priority:"",
      From:"",
      Thru:"",
    })
    this.inventoryItemArray =[
      {
       label:"Serialized",
       value:"SERIALIZED_INV_ITEM"
      },
       {
         label:"Non-Serialized",
         value:"NON_SERIAL_INV_ITEM"
       }
     ]
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params=>{
      this.planningWareHouseId = params.planningWareHouseId;
      this.primaryWarehouseId = params.primaryWarehouseId;

    })
    this.getPrimaryWarehouse();
    this.getInitialManager();
    this.getPartyId();
    this.getCurrency();
    this.getPlanningWareHousesById();
  }
  homeButton(){
    this._Router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  getInitialManager() {
    this.spinner.show();
    this.mergeContactsService.getInitialManager().subscribe(res => {
      this.InitialManager = res.data[0].getInitialManager;
      this.spinner.hide();
      for (const value of this.InitialManager) {
        this.InitialManagerArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
  }
  getPrimaryWarehouse() {
    this.spinner.show();
    this.mergeContactsService.getPrimaryWarehouse().subscribe(res => {
      this.primaryWarehouse = res.data[0].getPrimaryWarehouse;
      this.spinner.hide();
      for (const value of this.primaryWarehouse) {
        this.primaryWarehouseArray.push({
          label: value.facilityName,
          value: value.facilityId
        })
      }
    })
  }
  getCurrency(): void {
    this.spinner.show();
    this._GlobalResourceService.getCurrency()
      .then(data => {
        this.spinner.hide();
        this.currencyUomIds = data.data.currencyList.map(value => {
          return {
            label: value.description,
            value: value.uomId
          };
        });
      });
    
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
    
  }
  getPlanningWareHousesById() {
    this.spinner.show();
    this.mergeContactsService.getPlanningWareHousesById(this.planningWareHouseId).subscribe(res => {
      this.planningWareHousesById = res.data[0].getPlanningWareHousesExistingAll[0];
      this.spinner.hide();
      setTimeout(() => {
        const form = this.addNewForm;
      form.patchValue({
        primaryWarehouse: this.planningWareHousesById.primaryWarehouse ,
      Name: this.planningWareHousesById.name ,
      Owner: this.planningWareHousesById.partyId ,
      Currency: this.planningWareHousesById.currencyUomId ,
      inventoryItemType: this.planningWareHousesById.inventoryItemType ,
      initialManager: this.planningWareHousesById.userLoginId ,
      Priority: this.planningWareHousesById.priority ,
      From:this.datePipe.transform(this.planningWareHousesById.fromDate,"yyyy-MM-dd")   ,
      Thru:this.datePipe.transform( this.planningWareHousesById.thruDate,"yyyy-MM-dd"),
      })
      }, 2000);
    })
   
  }
  UpdatePlanningWareHouse(): void {
    this.spinner.show();
    const requestData = {
      "currencyUomId":  this.addNewForm.value.Currency,
      "fromDate":this.datePipe.transform( this.addNewForm.value.From,"yyyy-MM-dd hh:mm:ss") , 
      "inventoryItemType":  this.addNewForm.value.inventoryItemType,
      "name":  this.addNewForm.value.Name,
      "partyId":  this.addNewForm.value.Owner,
      "planningWareHouseId":  this.planningWareHouseId ,
      "primaryWarehouse":  this.primaryWarehouseId,
      "priority":  this.addNewForm.value.Priority,
      "thruDate":this.datePipe.transform(this.addNewForm.value.Thru,"yyyy-MM-dd hh:mm:ss") ,
      "userLoginId":  this.addNewForm.value.initialManager,

    
    };
    this.mergeContactsService.UpdatePlanningWareHouse(requestData).subscribe((res: any) => {
  
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton1.nativeElement.click();
        this.addNewForm.reset();
        this.getPlanningWareHousesById();
      }
    }, (err) => {
      this.spinner.hide();
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
    });
  
  }
  toBack()
  {
    this._Router.navigate(['/facilities/Planning/configure-warehouse']);
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

}
