<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Product Catalog </span>
               
               <span class="d-flex">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
              
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''" ><a>
                        New Product Catalog</a></li>
                     
                    </ul>
                  </div>
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form [formGroup]="catalogForm" class="w-100">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12 col-12 mb-20">If you don't populate a Catalog with Categories,
                                        it will not be shown in the Browse Catalogs/Categories tree</div>
                                   
                                        <div class="col-lg-12">
                                            <div class="row">
                                              
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Catalog [ID]</label>
                                                </div>
                                              
                                                <div class="col-lg-2">
                                                    <input type="email" formControlName="prodCatalogId" class="form-control"
                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                placeholder="Enter CatalogID">
                                                </div>
                                               
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Catalog Name <span
                                                        style="color:red">*</span></label>
                                                </div>
                                              
                                                <div class="col-lg-2">
                                                    <input type="email" formControlName="catalogName" class="form-control"
                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                placeholder="Enter Catalog Name">
                                                </div>
                                            </div>
                                        </div>
                                      
                                        <div class="col-lg-12">
                                            <div class="row">
                                              
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Use Quick Add
                                              
                                                    </label>
                                                </div>
                                              
                                                <div class="col-lg-2">
                                                    <p-dropdown filter="true" [options]="catalogArray"
                                                    formControlName="useQuickAdd" optionlabel="label">
                                                </p-dropdown>
                                                </div>
                                               
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Style Sheet
                                              
                                                    </label>
                                                </div>
                                              
                                                <div class="col-lg-2">
                                                    <input type="email" formControlName="styleSheet" class="form-control"
                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                placeholder="Enetr Style Sheet">
                                                </div>
                                            </div>
                                        </div>
                                       
                                        <div class="col-lg-12">
                                            <div class="row">
                                              
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Header Logo </label>
                                                </div>
                                              
                                                <div class="col-lg-2">
                                                    <input type="email" formControlName="headerLogo" class="form-control"
                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                    placeholder="Enter Header Logo">
                                                </div>
                                               
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Content Path Prefix </label>
                                                </div>
                                              
                                                <div class="col-lg-2">
                                                    <input type="email" formControlName="contentPathPrefix" class="form-control"
                                                    id="exampleInputEmail1" aria-describedby="emailHelp"
                                                    placeholder="Enetr Content Path Prefix ">
                                                </div>
                                            </div>
                                        </div>
                                      
                                        <div class="col-lg-12">
                                            <div class="row">
                                              
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Template Path Prefix</label>
                                                </div>
                                              
                                                <div class="col-lg-2">
                                                    <input type="email" formControlName="templatePathPrefix"
                                                class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Template Path Prefix">
                                                </div>
                                               
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Add product to "ViewAllow" category</label>
                                                </div>
                                              
                                                <div class="col-lg-2">
                                                    <p-dropdown filter="true" [options]="catalogArray"
                                                    formControlName="viewAllowPermReqd" optionlabel="label">

                                                </p-dropdown>
                                                </div>
                                            </div>
                                        </div>
                                     
                                        <div class="col-lg-12">
                                            <div class="row">
                                              
                                                <div class="col-lg-3 form-group classInput">
                                                    <label for="exampleInputEmail1">Add product to "PurchaseAllow"
                                                        category</label>
                                                </div>
                                              
                                                <div class="col-lg-2">
                                                    <p-dropdown filter="true" [options]="catalogArray"
                                                    formControlName="purchaseAllowPermReqd" optionlabel="label">

                                                </p-dropdown>
                                                </div>
                                            </div>
                                        </div>

                                  
                                </div>


                                <div class="main-submit-button" style="margin-right: 22%;">
                                    <button type="submit" (click)="onSubmit()"
                                    class="btn btn-secondary submit-btn">{{editMode? 'Update':'Submit'}}</button>
                                   
                                </div>
                            </form>
                        </div>
                    </div>


                </div>
                </div>

            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>