import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { ToastrService } from 'ngx-toastr';
import { AccountingApService } from 'src/app/accounting/accounting-ap/accounting-ap.service';
import * as moment from 'moment';
import { NgxSpinnerService } from 'ngx-spinner';
@Component({
    selector: 'app-create-new-item-promotion',
    templateUrl: './create-new-item-promotion.component.html'
})
export class CreateNewItemPromotionComponent implements OnInit {
    itemPromotionForm: FormGroup;
    agreementType: string;
    activeCategory=1;
    productPromoIds: any[];
    agreement: {
        agreementId: string,
        agreementItemSeqId: string
    };
    editMode: boolean;
    promotion: any;
    constructor(
        readonly _FormBuilder: FormBuilder,
        readonly  _Router: Router,
        readonly spinner: NgxSpinnerService,
        readonly _ActivatedRoute: ActivatedRoute,
        readonly _AccountingApService: AccountingApService,
        readonly _ToastrService: ToastrService
    ) {
        this.agreementType = '';
        this.productPromoIds = [];
        this.itemPromotionForm = this._FormBuilder.group({
            agreementId: [''],
            agreementItemSeqId: [''],
            fromDate: [''],
            productPromoId: [''],
            sequenceNum: [''],
            thruDate: ['']
        });
        this.agreement = {
            agreementId: '',
            agreementItemSeqId: ''
        };
        this.editMode = false;
    }
    ngAfterViewInit(): void { this.spinner.show(); }
    ngOnInit(): void {
        this.agreementType = window.location.pathname.includes('receiveable') ? 'receiveable' : 'payable';
        this._ActivatedRoute.queryParams.subscribe(data => {
            if (data) {
                this.agreement = {
                    agreementId: data.agreementId,
                    agreementItemSeqId: data.agreementItemSeqId
                };
                if (data.promoId) {
                    this.editMode = true;
                    this._AccountingApService.getProductPromoById(this.agreement.agreementId, this.agreement.agreementItemSeqId, moment(Number(data.fromDate)).format('YYYY-MM-DD HH:mm:ss'), data.promoId)
                        .then(value => {
                        
                            this.promotion = value.data;
                            this.itemPromotionForm.patchValue({
                                ...value.data, ...{
                                    fromDate: value.data.fromDate ? new Date(value.data.fromDate) : '',
                                    thruDate: value.data.thruDate ? new Date(value.data.thruDate) : ''
                                }
                            });
                        });
                }
            }
        });
        this.getProductPromoIds();
    }
    getProductPromoIds(): void {
        this.spinner.show();
        this._AccountingApService.getProductPromoIds()
            .then(data => {
                this.spinner.hide();
                this.productPromoIds = data.data.map(value => {
                    return {
                        label: value.promoName,
                        value: value.productPromoId
                    };
                });
            });
           
    }
    update(): void {
        this.spinner.show();
        this._AccountingApService.updateAgreementItemPromotions({
            ...this.promotion, ...this.itemPromotionForm.value, ...{
                agreementId: this.agreement.agreementId,
                agreementItemSeqId: this.agreement.agreementItemSeqId,
                fromDate: this.itemPromotionForm.value.fromDate ? moment(this.itemPromotionForm.value.fromDate).format('YYYY-MM-DD HH:mm::ss') : '',
                thruDate: this.itemPromotionForm.value.thruDate ? moment(this.itemPromotionForm.value.thruDate).format('YYYY-MM-DD HH:mm:ss') : ''
            }
        })
            .then(data => {
                if (data.success) {
                    this._ToastrService.success('Updated');
                    this.spinner.hide();
                    this.itemPromotionForm.reset();
                    this._Router.navigate([`/financial/${this.agreementType}/customer-agreeement/agreement-summary-item`], { queryParams: { agreementId: this.agreement.agreementId, agreementItemSeqId: this.agreement.agreementItemSeqId } });

                }
            });
          

    }
    reset(): void {
        this.itemPromotionForm.reset();
        this._Router.navigate([`/financial/${this.agreementType}/customer-agreeement/agreement-summary-item`], { queryParams: { agreementId: this.agreement.agreementId, agreementItemSeqId: this.agreement.agreementItemSeqId } });
    }
    submit(): void {
        this.spinner.show();
        if (this.editMode) {
            this.update();
        } else {
            this._AccountingApService.createAgreementItemPromotions({
                ...this.itemPromotionForm.value, ...{
                    agreementId: this.agreement.agreementId,
                    agreementItemSeqId: this.agreement.agreementItemSeqId,
                    fromDate: this.itemPromotionForm.value.fromDate ? moment(this.itemPromotionForm.value.fromDate).format('YYYY-MM-DD HH:mm:ss') : '',
                    thruDate: this.itemPromotionForm.value.thruDate ? moment(this.itemPromotionForm.value.thruDate).format('YYYY-MM-DD HH:mm:ss') : ''
                }
            })
                .then(data => {
                    if (data.success) {
                        this._ToastrService.success('Created');
                        this.spinner.hide();
                        this.itemPromotionForm.reset();
                        this._Router.navigate([`/financial/${this.agreementType}/customer-agreeement/agreement-summary-item`], { queryParams: { agreementId: this.agreement.agreementId, agreementItemSeqId: this.agreement.agreementItemSeqId } });
                    }
                });
        }
     
    }
    ngOnDestroy(): void {
        this.spinner.hide();
          }
}