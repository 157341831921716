<div class="container-fluid main-container-wrapper pr-0">
    <div class="row">
        <div class="col-12">
          <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
            <span class="color-black pl-1 titlePanels">PIM | Product Information Management
            </span>
            <span>
                 <!-- <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button> -->
             </span> 
         </div>
         
            <div class=" bg-white color-grey">
                <div class="container-fluid">

                </div>

                <div>
                    <div class="container-fluid">
                        <div class="row" >
                          <form class="w-100">
                            
                              <div class="w-100">
                                <div class="panel-group">
                                  <div>
                                    <div style="width: 49%; float:left">
                                    <div class="w3-card-4 cardEffect" >
                                      <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                          <h4 class=" common-styling" style="margin-left: 7px;">
                                            Product Management
                                          </h4>
                                       
                                        </div>
                                      </header>
                                  
                                      <div class="w3-container">
                                        <div class="panel-body" style="background: white;">
                                          <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="color-black container">
                                              <div  class="row">
                                                <div class="article-container">
                                                  <div class="article">
                                                    <p style="cursor: pointer;color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/product-management/main-catalog">Search</p>
                                                    <span style="cursor: pointer;color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/product-management/main-catalog">Catalog Administration</span>  
                                                </div>
                                                  
                                               
                                                </div>
                                              
                                                
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                  
                                     
                                    </div>
                                    <br>
                                   
                                    <div class="w3-card-4 cardEffect" >
                                      <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                          <h4 class=" common-styling" style="margin-left: 7px;">
                                            Catalog
                                          </h4>
                                       
                                        </div>
                                      </header>
                                  
                                      <div class="w3-container">
                                        <div class="panel-body" style="background: white;">
                                          <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="color-black container">
                                              <div  class="row">
                                                <div class="article-container">
                                                  <div class="article">
                                                    <p style="cursor: pointer;color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/product-management/catalogs">Find Catalog</p>
                                                    <span style="cursor: pointer;color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/product-management/catalogs">Create New Catalog</span>    
                                                </div>
                                                
                                               
                                                </div>
                                              
                                                
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                  
                                     
                                    </div>
                                    <br>
                                    <div class="w3-card-4 cardEffect" >
                                      <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                          <h4 class=" common-styling" style="margin-left: 7px;">
                                            Product

                                          </h4>
                                       
                                        </div>
                                      </header>
                                  
                                      <div class="w3-container">
                                        <div class="panel-body" style="background: white;">
                                          <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="color-black container">
                                              <div  class="row">
                                                <div class="article-container">
                                                  <div class="article">
                                                    <p style="cursor: pointer;color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/product-management/products">Find Product</p>
                                                    <span style="cursor: pointer;color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/product-management/products">Create New Product</span>
                                                  </div>
                                                
                                               
                                                </div>
                                              
                                                
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                  
                                     
                                    </div>
                                    <br>

                                 
                                    
                                      <div class="w3-card-4 cardEffect" >
                                      <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                          <h4 class=" common-styling" style="margin-left: 7px;">
                                        Promos
                                          </h4>
                                       
                                        </div>
                                      </header>
                                  
                                      <div class="w3-container">
                                        <div class="panel-body" style="background: white;">
                                          <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="color-black container">
                                              <div  class="row">
                                                <div class="article-container">
                                                  <div class="article">
                                                    <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/product-management/promos">Create New Product Promos</p>
                                                  </div>

                                                </div>
                                              
                                                
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                  
                                     
                                    </div>
                                    <br>
                                    <div class="w3-card-4 cardEffect" style="border:1px solid;
                                    border-color: #0d3769 !important;">
                                      <header class="w3-container w3-blue">
                                        <div class="header-tabs" >
                                          <h4 class=" common-styling" style="margin-left: 7px;">
                                            Product Store Group
                                          </h4>
                                        
                                      </div>
                                      </header>
                                  
                                      <div class="w3-container">
                                          <div class="panel-body" style="background: white;">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                              <div class="color-black container">
                                                <div class="article-container">
                                                  <div class="article">
                                                    <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/order/requirements">Create New Product Store Group                                                   </p>
                                                  </div>
                                                

                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                    
                                  
                                    
                                    </div>
                                    <br>
                                    <div class="w3-card-4 cardEffect" style="border:1px solid;
                                    border-color: #0d3769 !important;">
                                      <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                          <h4 class=" common-styling" style="margin-left: 7px;">
                                            Reviews
                                          </h4>
                                        
                                      </div>
                                      </header>
                                  
                                      <div class="w3-container">
                                          <div class="panel-body" style="background: white;">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                              <div class="color-black container">
                                                <div class="article-container">
                                                  <div class="article">
                                                    <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/product-management/reviews">Find Reviews</p>
                                                  </div>
                                                

                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                    
                                  
                                    
                                    </div>
                                    <br>
                                    <div class="w3-card-4 cardEffect" style="border:1px solid;
                                    border-color: #0d3769 !important;">
                                      <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                          <h4 class=" common-styling" style="margin-left: 7px;">
                                            Subscriptions
                                          </h4>
                                        
                                      </div>
                                      </header>
                                  
                                      <div class="w3-container">
                                          <div class="panel-body" style="background: white;">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                              <div class="color-black container">
                                                <div class="article-container">
                                                  <div class="article">
                                                    <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/product-management/subscriptions">Find Subscriptions</p>
                                                    <span style="cursor: pointer;color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/product-management/subscriptions">Create New Subscriptions</span>
 
                                                </div>
                                                

                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                    
                                  
                                    
                                    </div>
                                    <br>

                                    <div class="w3-card-4 cardEffect mb-5" style="border:1px solid;
                                    border-color: #0d3769 !important;">
                                      <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                          <h4 class=" common-styling" style="margin-left: 7px;">
                                            Image Management
                                          </h4>
                                        
                                      </div>
                                      </header>
                                  
                                      <div class="w3-container">
                                          <div class="panel-body" style="background: white;">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                              <div class="color-black container">
                                                <div class="article-container">
                                                  <div class="article">
                                                    <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/product-management/image-management">Image Management Application</p>
                                                    <span style="cursor: pointer;color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/product-management/image-management">Gallery</span>
                                                  </div>
                                                  <div class="article">
                                                    <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/product-management/image-management">Upload Image</p>
                                                    <span style="cursor: pointer;color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/product-management/image-management">Manage</span>
                                                  </div>
                                                  <div class="article">
                                                    <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/product-management/image-management">Approve</p>
                                                    <span style="cursor: pointer;color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/product-management/image-management">Rejected</span>
                                                  </div>
                                                  <div class="article">
                                                    <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/product-management/image-management">Recently Approved</p>
                                                    <span style="cursor: pointer;color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/product-management/image-management">Replace</span>
                                                  </div>
                                                  <div class="article">
                                                    <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/product-management/image-management">Resize</p>
                                                  </div>
                                                
                                                
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                    
                                  
                                    
                                    </div>
                                    <br>
                                  
                                   
                                   </div>
                                   
                                   <div style="width: 49.5%; float:right">
                                    <div class="w3-card-4 cardEffect" >
                                      <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                          <h4 class=" common-styling" style="margin-left: 7px;">
                                            Store
                                          </h4>
                                       
                                        </div>
                                      </header>
                                  
                                      <div class="w3-container">
                                        <div class="panel-body" style="background: white;">
                                          <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="color-black container">
                                              <div  class="row">
                                                <div class="article-container">
                                                  <div class="article">
                                                    <span style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/product-management/stores">Create New Product Store</span>
                                                  </div>

                                                </div>
                                              
                                                
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                  
                                     
                                    </div>
                                    <br>
                                    <div class="w3-card-4 cardEffect" >
                                      <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                          <h4 class=" common-styling" style="margin-left: 7px;">
                                            Category
                                          </h4>
                                       
                                        </div>
                                      </header>
                                  
                                      <div class="w3-container">
                                        <div class="panel-body" style="background: white;">
                                          <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="color-black container">
                                              <div  class="row">
                                                <div class="article-container">
                                                  <div class="article">
                                                    <p style="cursor: pointer;    color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/product-management/catagories">Find Category</p>
                                                    <span style="cursor: pointer;color: #0000FF;" class="color-grey pl-20 col-lg-8 col-8" routerLink="/product-management/catagories">Create New Category </span>
  
                                                </div>
                                            
                                                </div>
                                              
                                                
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                  
                                     
                                    </div>
                                    <br>
                                    <div class="w3-card-4 cardEffect" >
                                      <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                          <h4 class=" common-styling" style="margin-left: 7px;">
                                            Feature

                                          </h4>
                                       
                                        </div>
                                      </header>
                                  
                                      <div class="w3-container">
                                        <div class="panel-body" style="background: white;">
                                          <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="color-black container">
                                              <div  class="row">
                                                <div class="article-container">
                                                  <div class="article">
                                                    <p style="cursor: pointer;color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/product-management/feature-type">Find Feature</p>
                                                    <span style="cursor: pointer;color: #0000FF;" class="color-grey pl-20 col-lg-8 col-8" routerLink="/product-management/feature-type">Create New Feature </span>
                                                  </div>
                                                
                                               
                                               
                                                </div>
                                              
                                                
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                  
                                     
                                    </div>
                                    <br>
                                    <div class="w3-card-4 cardEffect" >
                                      <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                          <h4 class=" common-styling" style="margin-left: 7px;">
                                            Price Rules

                                          </h4>
                                       
                                        </div>
                                      </header>
                                  
                                      <div class="w3-container">
                                        <div class="panel-body" style="background: white;">
                                          <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="color-black container">
                                              <div  class="row">
                                                <div class="article-container">
                                                  <div class="article">
                                                    <p style="cursor: pointer;color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/product-management/price-rules">Add New Price Rules</p>
                                                  </div>
                                                
                                               
                                                </div>
                                              
                                                
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                  
                                     
                                    </div>
                                    <br>
                                    <div class="w3-card-4 cardEffect" >
                                      <header class="w3-container w3-blue">
                                        <div class="header-tabs">
                                          <h4 class=" common-styling" style="margin-left: 7px;">
                                            Thesaurus

                                          </h4>
                                       
                                        </div>
                                      </header>
                                  
                                      <div class="w3-container">
                                        <div class="panel-body" style="background: white;">
                                          <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="color-black container">
                                              <div  class="row">
                                                <div class="article-container">
                                                  <div class="article">
                                                    <p style="cursor: pointer;color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/product-management/thesaurus">Add Alternate KeyWord Thesaurus</p>
                                                  </div>
                                                 
                                               
                                                </div>
                                              
                                                
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                      </div>
                                  
                                     
                                    </div>
                                    <br>
                                    <div class="w3-card-4 cardEffect" >
                                        <header class="w3-container w3-blue">
                                          <div class="header-tabs">
                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                                Configurations
  
                                            </h4>
                                         
                                          </div>
                                        </header>
                                    
                                        <div class="w3-container">
                                          <div class="panel-body" style="background: white;">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                              <div class="color-black container">
                                                <div  class="row">
                                                  <div class="article-container">
                                                    <div class="article">
                                                      <p style="cursor: pointer;color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/product-management/configurations">Find Config Items</p>
                                                      <span style="cursor: pointer;color: #0000FF;" class="color-grey pl-20 col-lg-8 col-8" routerLink="/product-management/configurations">Create New Config Items</span>

                                                    </div>
                                                   
                                                 
                                                  </div>
                                                
                                                  
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                    
                                       
                                      </div>
                                      <br>
                                      <div class="w3-card-4 cardEffect" >
                                        <header class="w3-container w3-blue">
                                          <div class="header-tabs">
                                            <h4 class=" common-styling" style="margin-left: 7px;">
                                            Shipping
  
                                            </h4>
                                         
                                          </div>
                                        </header>
                                    
                                        <div class="w3-container">
                                          <div class="panel-body" style="background: white;">
                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                              <div class="color-black container">
                                                <div  class="row">
                                                  <div class="article-container">
                                                    <div class="article">
                                                      <p style="cursor: pointer;color: #0000FF;" class="color-grey pl-20 col-lg-10 col-12" routerLink="/product-management/shipment">Create Shipping</p>

                                                    </div>
                                                   
                                                   
                                                 
                                                  </div>
                                                
                                                  
                                                </div>
                                              </div>
                                            </div>
                                          </div>
                                        </div>
                                    
                                       
                                      </div>
                                      <br>

                                   
                                  
                                  
                                  </div>
                                   
                                   
                                  
                                
    
                              </div>
                              </div>
                            </div>
    
                          </form>
                        </div>
                      </div>
                  

                </div>

            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>