<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
                <span class="color-black pl-1 titlePanels">JobShop : [{{ this.workEffortId}}]</span> <span></span>
                <span class="">

                    <button type="submit" (click)="homeButton();"
                        class=" btn btn-outline-secondary mr-2">Home</button>
            
                    <button type="submit" (click)="cancelSubmit();"
                        class="btn btn-danger buttonclass ">Back</button>
                </span>
            </div>


            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                    <div class="suppliers-wrapper">

                    </div>
                </div>
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">

                            <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategoryFunction(2)"><a>
                                    Overview</a></li>
                            <li *ngIf="this.statuDescription=='Created' || this.statuDescription=='Scheduled'"
                                [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategoryFunction(3)"><a>
                                    Tasks</a></li>

                            <li *ngIf="this.statuDescription=='Created' || this.statuDescription=='Scheduled'"
                                [ngClass]="activeCategory==4?'active':''" (click)="changeactiveCategoryFunction(4)"><a>
                                    Materials</a></li>
                            <li *ngIf="this.statuDescription=='Created' || this.statuDescription=='Scheduled'"
                                [ngClass]="activeCategory==5?'active':''" (click)="changeactiveCategoryFunction(5)"><a>
                                    Fixed Assets</a></li>
                            <li *ngIf="this.statuDescription=='Running' || this.statuDescription=='Running'"
                                [ngClass]="activeCategory==10?'active':''" (click)="changeactiveCategoryFunction(10)">
                                <a>
                                    Actual Materials</a>
                            </li>
                            <li [ngClass]="activeCategory==6?'active':''" (click)="changeactiveCategoryFunction(6)"><a>
                                    Parties</a></li>
                            <li [ngClass]="activeCategory==7?'active':''" (click)="changeactiveCategoryFunction(7)"><a>
                                    Assocs</a></li>
                            <li [ngClass]="activeCategory==8?'active':''" (click)="changeactiveCategoryFunction(8)"><a>
                                    Content</a></li>
                            <li [ngClass]="activeCategory==9?'active':''" (click)="changeactiveCategoryFunction(9)"><a>
                                    Actual Costs</a></li>




                        </ul>
                    </div>
                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                        <div class="w-100">
                                            <div class="panel-group" id="accordion" role="tablist"
                                                aria-multiselectable="true">

                                                <div *ngIf="activeCategory==2">

                                                    <div class="w3-card-4 classCard">
                                                        <div class="container-fluid">
                                                            <ul class="tabbing-section tabbing-accordians tabClass">
                                    
                                                                <li [ngClass]="activeCategoryA==2?'active':''" (click)="changeactiveCategoryFunctionA(2)"><a>
                                                                    Job Shop Information
                                                                </a></li>
                                                                <li 
                                                                    [ngClass]="activeCategoryA==3?'active':''" (click)="changeactiveCategoryFunctionA(3)"><a>
                                                                        Production Run Declare And Produce
</a></li>
                                    
                                                                <li 
                                                                    [ngClass]="activeCategoryA==4?'active':''" (click)="changeactiveCategoryFunctionA(4)"><a>
                                                                        Inventory Items
</a></li>
                                                                <li 
                                                                    [ngClass]="activeCategoryA==5?'active':''" (click)="changeactiveCategoryFunctionA(5)"><a>
                                                                        Tasks</a></li>
                                                                <li 
                                                                    [ngClass]="activeCategoryA==6?'active':''" (click)="changeactiveCategoryFunctionA(6)">
                                                                    <a>
                                                                        Materials</a>
                                                                </li>
                                                                <li [ngClass]="activeCategoryA==7?'active':''" (click)="changeactiveCategoryFunctionA(7)"><a>
                                                                    List Of Production Run Fixed Assets
                                                                </a></li>
                                                                <li [ngClass]="activeCategoryA==8?'active':''" (click)="changeactiveCategoryFunctionA(8)"><a>
                                                                    List Of Production Run Notes
                                                                </a></li>
                                                                <li [ngClass]="activeCategoryA==9?'active':''" (click)="changeactiveCategoryFunctionA(9)"><a>
                                                                    Return Unused Materials To Warehouse
                                                                </a></li>
                                                                <li [ngClass]="activeCategoryA==10?'active':''" (click)="changeactiveCategoryFunctionA(10)"><a>
                                                                    Materials Required By The Running Task </a></li>
                                                            </ul>
                                                        </div>
                                                        <div class="create-new-leade mt-2">
                                                            <div class="container-fluid">
                                                                <div class="row">
                                                                    <form class="w-100">
                                                                        <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                                                            <div class="w-100">
                                                                                <div class="panel-group" id="accordion" role="tablist"
                                                                                    aria-multiselectable="true">
                                    
                                                                                    <div *ngIf="activeCategoryA==2">
                                                                                        <div class="header-tabs">
                                                                                            <h4 class=" common-styling">
                                                                                                Overview
                                                                                            </h4>
                                                                                        </div>
                                                                                        <div
                                                                                            class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium classHead">
                                                                                        </div>
                                                                                        <div class="panel-body">
                                                                                            <div style="display: block!important;">
                                    
                                                                                                   <div style="display: block;" class="all-users-infomation font-13 font-medium own-user-select">
                                    
                                    
                                                                                                    <div class="w3-card-4 classCard">
                                                                                                        <header class="w3-container w3-blue">
                                                                                                            <div class="header-tabs">
                                                                                                                <h4 class=" common-styling Hleft">
                                                                                                                    Job Shop Information
                                                                                                                </h4>
                                                                                                                <span *ngIf="this.statuDescription=='Created' || this.statuDescription=='Scheduled'" class="edit-buttons hCreate">
                                                                   
                                                                              <a  data-toggle="modal"
                                                                              data-target="#exampleModalCenter" (click)="getJobShopProductionListData()">Update</a>
                                                                            </span>
                                                                                                            </div>
                                                                                                        </header>
                                    
                                                                                                        <div class="w3-container">
                                                                                                            <div class="panel-body">
                                                                                                                <div class="all-users-infomation font-13 font-medium own-user-select" *ngIf="productionRunData">
                                                                                                                    <div class="color-black container">
                                                                                                                        <div class="row">
                                                                                                                            <div class="article-container">
                                                                                                                                <div class="article">
                                                                                                                                    <p class="col-lg-6 col-12">Product Name</p>
                                                                                                                                    <span class="color-grey pl-20 col-lg-10 col-12">{{this.productionRunData.productName}}</span>
                                                                                                                                </div>
                                                                                                                                <div class="article">
                                                                                                                                    <p class="col-lg-6 col-12">Current Status ID</p>
                                                                                                                                    <span class="color-grey pl-20 col-lg-10 col-12">{{this.productionRunData.status}}</span>
                                    
                                                                                                                                </div>
                                    
                                    
                                    
                                                                                                                                <div class="article">
                                                                                                                                    <p class="col-lg-6 col-12">Qty To Produce</p>
                                                                                                                                    <span class="color-grey pl-20 col-lg-10 col-12">{{this.productionRunData.quantity}}</span>
                                                                                                                                </div>
                                    
                                                                                                                                <div class="article">
                                                                                                                                    <p class="col-lg-6 col-12">Estimated Start Date</p>
                                                                                                                                    <span class="color-grey pl-20 col-lg-10 col-12">{{this.productionRunData.estimatedStartDate| date :'yyyy-MM-dd hh:mm:ss'}}</span>
                                                                                                                                </div>
                                                                                                                                <div class="article">
                                                                                                                                    <p class="col-lg-6 col-12">Actual Start Date</p>
                                                                                                                                    <span class="color-grey pl-20 col-lg-10 col-12">{{this.productionRunData.estimatedStartDate| date :'yyyy-MM-dd hh:mm:ss'}}</span>
                                                                                                                                </div>
                                                                                                                                <div class="article">
                                                                                                                                    <p class="col-lg-6 col-12">Calculated Completion Date</p>
                                                                                                                                    <span class="color-grey pl-20 col-lg-10 col-12">{{this.productionRunData.estimatedCompletionDate| date :'yyyy-MM-dd hh:mm:ss'}}</span>
                                                                                                                                </div>
                                                                                                                                <div class="article">
                                                                                                                                    <p class="col-lg-6 col-12">Completion Date</p>
                                                                                                                                    <span class="color-grey pl-20 col-lg-10 col-12"></span>
                                                                                                                                </div>
                                                                                                                                <div class="article">
                                                                                                                                    <p class="col-lg-6 col-12">Production Run Name</p>
                                                                                                                                    <span class="color-grey pl-20 col-lg-10 col-12">{{this.productionRunData.workEffortName}}</span>
                                                                                                                                </div>
                                                                                                                                <div class="article">
                                                                                                                                    <p class="col-lg-6 col-12">Description</p>
                                                                                                                                    <span class="color-grey pl-20 col-lg-10 col-12"></span>
                                                                                                                                </div>
                                                                                                                                <div class="article">
                                                                                                                                    <p class="col-lg-6 col-12">Manufacturer ID</p>
                                                                                                                                    <span class="color-grey pl-20 col-lg-10 col-12"></span>
                                                                                                                                </div>
                                                                                                                                <div class="article">
                                                                                                                                    <p class="col-lg-6 col-12">Facility</p>
                                                                                                                                    <span class="color-grey pl-20 col-lg-10 col-12">{{this.productionRunData.facilityId}}</span>
                                                                                                                                </div>
                                                                                                                                <div class="article">
                                                                                                                                    <p class="col-lg-6 col-12">Produced</p>
                                                                                                                                    <span class="color-grey pl-20 col-lg-10 col-12"></span>
                                                                                                                                </div>
                                                                                                                                <div class="article">
                                                                                                                                    <p class="col-lg-6 col-12">Rejected</p>
                                                                                                                                    <span class="color-grey pl-20 col-lg-10 col-12"></span>
                                                                                                                                </div>
                                    
                                    
                                    
                                    
                                                                                                                            </div>
                                    
                                    
                                    
                                    
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                    
                                    
                                                                                                    </div>
                                                                                                </div> 
                                  
                                                                                                    <div *ngIf="orderItems && orderItems.length"
                                                                                                        class="all-users-infomation font-13 font-medium own-user-select"
                                                                                                        style="display: block!important;">
                                    
                                                                                                        <div class="w3-card-4  w-100 mt-3">
                                                                                                            <div class="accordion classCard"
                                                                                                                id="myAccordion">
                                                                                                                <div class="accordion-item">
                                                                                                                    <h2 class="accordion-header"
                                                                                                                        id="headingOne">
                                                                                                                        <div
                                                                                                                            class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion">
                                                                                                                            <span class="headingAccords">
                                                                                                                                Order
                                                                                                                                Items
                                                                                                                            </span>
                                    
                                    
                                                                                                                            <button type="button"
                                                                                                                                class="accordion-button  collapsed"
                                                                                                                                data-bs-toggle="collapse"
                                                                                                                                data-bs-target="#collapse4"
                                                                                                                                style="margin-left: 76%!important; padding-top: 11px !important;
                                                                                                                        padding-bottom: 11px !important;">
                                                                                                                            </button>
                                    
                                                                                                                        </div>
                                    
                                                                                                                    </h2>
                                                                                                                    <div id="collapse4"
                                                                                                                        class="accordion-collapse collapse"
                                                                                                                        data-bs-parent="#myAccordion">
                                                                                                                        <div class="card-body">
                                                                                                                            <div
                                                                                                                                class="all-users-infomation font-13 font-medium own-user-select allUserDetails">
                                                                                                                                <div
                                                                                                                                    class="card own-account-table">
                                                                                                                                    <p-table
                                                                                                                                        [value]="orderItems"
                                                                                                                                        [paginator]="true"
                                                                                                                                        [rows]="rows"
                                                                                                                                        scrollWidth="100%"
                                                                                                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]"
                                                                                                                                        [totalRecords]="total"
                                                                                                                                        [scrollable]="true"
                                                                                                                                        (sortFunction)="customSort($event)"
                                                                                                                                        [customSort]="true">
                                                                                                                                        <ng-template
                                                                                                                                            pTemplate="header">
                                                                                                                                            <tr
                                                                                                                                                style="background: #0d3769;">
                                                                                                                                                <th pSortableColumn="code"
                                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                                    <div class="checkbox-align"
                                                                                                                                                        style="color: white;">
                                                                                                                                                        <p-checkbox
                                                                                                                                                            _ngcontent-egj-c329=""
                                                                                                                                                            styleclass="custom-checkbox"
                                                                                                                                                            binary="true"
                                                                                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                                            <div
                                                                                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                                                <div
                                                                                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                                                                                    <input
                                                                                                                                                                        type="checkbox"
                                                                                                                                                                        name="undefined"
                                                                                                                                                                        value="undefined">
                                                                                                                                                                </div>
                                                                                                                                                                <div role="checkbox"
                                                                                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                                    <span
                                                                                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                                                </div>
                                                                                                                                                            </div>
                                    
                                                                                                                                                        </p-checkbox>
                                    
                                    
                                                                                                                                                        Order
                                                                                                                                                        ID
                                                                                                                                                        <p-sortIcon
                                                                                                                                                            field="code"></p-sortIcon>
                                                                                                                                                    </div>
                                                                                                                                                    <input
                                                                                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                                        value=""
                                                                                                                                                        autocomplete="off"
                                                                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                        role="textbox"
                                                                                                                                                        aria-disabled="false"
                                                                                                                                                        aria-readonly="false">
                                                                                                                                                </th>
                                    
                                    
                                    
                                    
                                    
                                    
                                                                                                                                            </tr>
                                                                                                                                        </ng-template>
                                                                                                                                        <ng-template
                                                                                                                                            pTemplate="body"
                                                                                                                                            let-product>
                                                                                                                                            <tr>
                                                                                                                                                <td
                                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                                    <div
                                                                                                                                                        class="checkbox-align">
                                                                                                                                                        <p-checkbox
                                                                                                                                                            _ngcontent-egj-c329=""
                                                                                                                                                            styleclass="custom-checkbox"
                                                                                                                                                            binary="true"
                                                                                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                                            <div
                                                                                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                                                <div
                                                                                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                                                                                    <input
                                                                                                                                                                        type="checkbox"
                                                                                                                                                                        name="undefined"
                                                                                                                                                                        value="undefined">
                                                                                                                                                                </div>
                                                                                                                                                                <div role="checkbox"
                                                                                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                                    <span
                                                                                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                                                </div>
                                                                                                                                                            </div>
                                    
                                                                                                                                                        </p-checkbox>
                                                                                                                                                        {{product.orderId}}
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                    
                                    
                                    
                                                                                                                                            </tr>
                                                                                                                                        </ng-template>
                                                                                                                                        <ng-template
                                                                                                                                            pTemplate="emptymessage"
                                                                                                                                            let-columns>
                                                                                                                                            <tr>
                                                                                                                                                <td>
                                                                                                                                                    <span
                                                                                                                                                        class="text-center">
                                                                                                                                                        <h4
                                                                                                                                                            class="ml-2">
                                                                                                                                                            No
                                                                                                                                                            Record
                                                                                                                                                            Found
                                                                                                                                                        </h4>
                                                                                                                                                    </span>
                                                                                                                                                </td>
                                                                                                                                            </tr>
                                                                                                                                        </ng-template>
                                                                                                                                    </p-table>
                                                                                                                                    <p
                                                                                                                                        class="paginate_data">
                                                                                                                                        View per page
                                    
                                                                                                                                    </p>
                                                                                                                                </div>
                                                                                                                            </div>
                                    
                                                                                                                            <div class="col-lg-12 col-12 main-submit-button"
                                                                                                                                style="    margin-left: -29%;">
                                                                                                                                <button
                                                                                                                                    _ngcontent-cta-c128=""
                                                                                                                                    type="submit"
                                                                                                                                    class="btn btn-secondary submit-btn"
                                                                                                                                    (click)="advanceSearchContacts(false)">Find</button>&nbsp;
                                                                                                                                <button
                                                                                                                                    _ngcontent-cta-c128=""
                                                                                                                                    type="submit"
                                                                                                                                    class="btn btn-danger ml-2"
                                                                                                                                    (click)="reset()">Reset</button>
                                                                                                                            </div>
                                    
                                    
                                    
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                    
                                                                                                        </div>
                                                                                                    </div>
                                    
                                                                                                    <!-- <div *ngIf="orderItems && orderItems.length" class="all-users-infomation font-13 font-medium own-user-select" style="display: block!important;">
                                    
                                                                                                    <div class="w3-card-4 classCard">
                                                                                                        <header class="w3-container w3-blue">
                                                                                                            <div class="header-tabs">
                                                                                                                <h4 class=" common-styling Hleft">
                                                                                                                    Order Items
                                                                                                                </h4>
                                    
                                                                                                            </div>
                                                                                                        </header>
                                    
                                                                                                        <div class="w3-container">
                                                                                                            <div class="panel-body">
                                                                                                                <div class="all-users-infomation font-13 font-medium own-user-select allUserDetails">
                                                                                                                    <div class="card own-account-table">
                                                                                                                        <p-table [value]="orderItems" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                                            <ng-template pTemplate="header">
                                                                                                                                <tr style="background: #0d3769;">
                                                                                                                                    <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                                                        <div class="checkbox-align" style="color: white;">
                                                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                                                        <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                                
                                                                                                                                            </p-checkbox>
                                    
                                    
                                                                                                                                            Order ID
                                                                                                                                            <p-sortIcon field="code"></p-sortIcon>
                                                                                                                                        </div>
                                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                    </th>
                                    
                                    
                                    
                                    
                                    
                                    
                                                                                                                                </tr>
                                                                                                                            </ng-template>
                                                                                                                            <ng-template pTemplate="body" let-product>
                                                                                                                                <tr>
                                                                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                                                                        <div class="checkbox-align">
                                                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                                                        <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                                
                                                                                                                                            </p-checkbox>
                                                                                                                                            {{product.orderId}}
                                                                                                                                        </div>
                                                                                                                                    </td>
                                    
                                    
                                    
                                                                                                                                </tr>
                                                                                                                            </ng-template>
                                                                                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                                                                                <tr>
                                                                                                                                    <td>
                                                                                                                                        <span class="text-center">
                                                                                                        <h4 class="ml-2">No Record Found
                                                                                                        </h4>
                                                                                                    </span>
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                            </ng-template>
                                                                                                                        </p-table>
                                                                                                                        <p class="paginate_data">
                                                                                                                            View per page
                                    
                                                                                                                        </p>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                    
                                    
                                                                                                    </div>
                                                                                                </div> -->
                                    
                                                                                                    <div *ngIf="precedingProductionRun && precedingProductionRun.length"
                                                                                                        class="all-users-infomation font-13 font-medium own-user-select"
                                                                                                        style="display: block!important;">
                                    
                                    
                                                                                                        <div class="w3-card-4  w-100 mt-3">
                                                                                                            <div class="accordion classCard"
                                                                                                                id="myAccordion">
                                                                                                                <div class="accordion-item">
                                                                                                                    <h2 class="accordion-header"
                                                                                                                        id="headingOne">
                                                                                                                        <div
                                                                                                                            class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion">
                                                                                                                            <span class="headingAccords">
                                                                                                                                Manufacturing Mandatory
                                                                                                                                WorkEfforts
                                                                                                                            </span>
                                    
                                    
                                                                                                                            <button type="button"
                                                                                                                                class="accordion-button  collapsed"
                                                                                                                                data-bs-toggle="collapse"
                                                                                                                                data-bs-target="#collapse6"
                                                                                                                                style="margin-left: 76%!important; padding-top: 11px !important;
                                                                                                                        padding-bottom: 11px !important;">
                                                                                                                            </button>
                                    
                                                                                                                        </div>
                                    
                                                                                                                    </h2>
                                                                                                                    <div id="collapse6"
                                                                                                                        class="accordion-collapse collapse"
                                                                                                                        data-bs-parent="#myAccordion">
                                                                                                                        <div class="card-body">
                                                                                                                            <div
                                                                                                                                class="all-users-infomation font-13 font-medium own-user-select allUserDetails">
                                                                                                                                <div
                                                                                                                                    class="card own-account-table">
                                                                                                                                    <p-table
                                                                                                                                        [value]="precedingProductionRun"
                                                                                                                                        [paginator]="true"
                                                                                                                                        [rows]="rows"
                                                                                                                                        scrollWidth="100%"
                                                                                                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]"
                                                                                                                                        [totalRecords]="total"
                                                                                                                                        [scrollable]="true"
                                                                                                                                        (sortFunction)="customSort($event)"
                                                                                                                                        [customSort]="true">
                                                                                                                                        <ng-template
                                                                                                                                            pTemplate="header">
                                                                                                                                            <tr
                                                                                                                                                style="background: #0d3769;">
                                                                                                                                                <th pSortableColumn="code"
                                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                                    <div class="checkbox-align"
                                                                                                                                                        style="color: white;">
                                    
                                                                                                                                                        <p-checkbox
                                                                                                                                                            _ngcontent-egj-c329=""
                                                                                                                                                            styleclass="custom-checkbox"
                                                                                                                                                            binary="true"
                                                                                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                                            <div
                                                                                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                                                <div
                                                                                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                                                                                    <input
                                                                                                                                                                        type="checkbox"
                                                                                                                                                                        name="undefined"
                                                                                                                                                                        value="undefined">
                                                                                                                                                                </div>
                                                                                                                                                                <div role="checkbox"
                                                                                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                                    <span
                                                                                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                                                </div>
                                                                                                                                                            </div>
                                    
                                                                                                                                                        </p-checkbox>
                                    
                                    
                                                                                                                                                        ID
                                                                                                                                                        <p-sortIcon
                                                                                                                                                            field="code"></p-sortIcon>
                                                                                                                                                    </div>
                                                                                                                                                    <input
                                                                                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                                        value=""
                                                                                                                                                        autocomplete="off"
                                                                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                        role="textbox"
                                                                                                                                                        aria-disabled="false"
                                                                                                                                                        aria-readonly="false">
                                                                                                                                                </th>
                                                                                                                                                <th pSortableColumn="name"
                                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                                    <div
                                                                                                                                                        style="color: white;">
                                                                                                                                                        Routing
                                                                                                                                                        Task
                                                                                                                                                        Name
                                                                                                                                                        <p-sortIcon
                                                                                                                                                            field="name"></p-sortIcon>
                                                                                                                                                    </div>
                                                                                                                                                    <div>
                                                                                                                                                        <input
                                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                                            value=""
                                                                                                                                                            autocomplete="off"
                                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                            role="textbox"
                                                                                                                                                            aria-disabled="false"
                                                                                                                                                            aria-readonly="false">
                                                                                                                                                    </div>
                                                                                                                                                </th>
                                                                                                                                                <th pSortableColumn="name"
                                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                                    <div
                                                                                                                                                        style="color: white;">
                                                                                                                                                        Qty
                                                                                                                                                        To
                                                                                                                                                        Produce
                                                                                                                                                        <p-sortIcon
                                                                                                                                                            field="name"></p-sortIcon>
                                                                                                                                                    </div>
                                                                                                                                                    <div>
                                                                                                                                                        <input
                                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                                            value=""
                                                                                                                                                            autocomplete="off"
                                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                            role="textbox"
                                                                                                                                                            aria-disabled="false"
                                                                                                                                                            aria-readonly="false">
                                                                                                                                                    </div>
                                                                                                                                                </th>
                                                                                                                                                <th pSortableColumn="name"
                                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                                    <div
                                                                                                                                                        style="color: white;">
                                                                                                                                                        Produced
                                                                                                                                                        <p-sortIcon
                                                                                                                                                            field="name"></p-sortIcon>
                                                                                                                                                    </div>
                                                                                                                                                    <div>
                                                                                                                                                        <input
                                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                                            value=""
                                                                                                                                                            autocomplete="off"
                                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                            role="textbox"
                                                                                                                                                            aria-disabled="false"
                                                                                                                                                            aria-readonly="false">
                                                                                                                                                    </div>
                                                                                                                                                </th>
                                                                                                                                                <th pSortableColumn="name"
                                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                                    <div
                                                                                                                                                        style="color: white;">
                                                                                                                                                        Start
                                                                                                                                                        Date
                                                                                                                                                        <p-sortIcon
                                                                                                                                                            field="name"></p-sortIcon>
                                                                                                                                                    </div>
                                                                                                                                                    <div>
                                                                                                                                                        <input
                                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                                            value=""
                                                                                                                                                            autocomplete="off"
                                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                            role="textbox"
                                                                                                                                                            aria-disabled="false"
                                                                                                                                                            aria-readonly="false">
                                                                                                                                                    </div>
                                                                                                                                                </th>
                                                                                                                                                <th pSortableColumn="name"
                                                                                                                                                    [ngStyle]="{'width':'190px'}">
                                                                                                                                                    <div
                                                                                                                                                        style="color: white;">
                                                                                                                                                        Estimated
                                                                                                                                                        Completion
                                                                                                                                                        Date
                                                                                                                                                        <p-sortIcon
                                                                                                                                                            field="name"></p-sortIcon>
                                                                                                                                                    </div>
                                                                                                                                                    <div>
                                                                                                                                                        <input
                                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                                            value=""
                                                                                                                                                            autocomplete="off"
                                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                            role="textbox"
                                                                                                                                                            aria-disabled="false"
                                                                                                                                                            aria-readonly="false">
                                                                                                                                                    </div>
                                                                                                                                                </th>
                                                                                                                                                <th pSortableColumn="name"
                                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                                    <div
                                                                                                                                                        style="color: white;">
                                                                                                                                                        Actual
                                                                                                                                                        Start
                                                                                                                                                        Date
                                                                                                                                                        <p-sortIcon
                                                                                                                                                            field="name"></p-sortIcon>
                                                                                                                                                    </div>
                                                                                                                                                    <div>
                                                                                                                                                        <input
                                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                                            value=""
                                                                                                                                                            autocomplete="off"
                                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                            role="textbox"
                                                                                                                                                            aria-disabled="false"
                                                                                                                                                            aria-readonly="false">
                                                                                                                                                    </div>
                                                                                                                                                </th>
                                                                                                                                                <th pSortableColumn="name"
                                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                                    <div
                                                                                                                                                        style="color: white;">
                                                                                                                                                        Completion
                                                                                                                                                        Date
                                                                                                                                                        <p-sortIcon
                                                                                                                                                            field="name"></p-sortIcon>
                                                                                                                                                    </div>
                                                                                                                                                    <div>
                                                                                                                                                        <input
                                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                                            value=""
                                                                                                                                                            autocomplete="off"
                                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                            role="textbox"
                                                                                                                                                            aria-disabled="false"
                                                                                                                                                            aria-readonly="false">
                                                                                                                                                    </div>
                                                                                                                                                </th>
                                                                                                                                                <th pSortableColumn="name"
                                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                                    <div
                                                                                                                                                        style="color: white;">
                                                                                                                                                        Action
                                                                                                                                                        <p-sortIcon
                                                                                                                                                            field="name"></p-sortIcon>
                                                                                                                                                    </div>
                                                                                                                                                    <div>
                                                                                                                                                        <input
                                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                                            value=""
                                                                                                                                                            autocomplete="off"
                                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                            role="textbox"
                                                                                                                                                            aria-disabled="false"
                                                                                                                                                            aria-readonly="false">
                                                                                                                                                    </div>
                                                                                                                                                </th>
                                    
                                    
                                    
                                    
                                                                                                                                            </tr>
                                                                                                                                        </ng-template>
                                                                                                                                        <ng-template
                                                                                                                                            pTemplate="body"
                                                                                                                                            let-activity>
                                                                                                                                            <tr>
                                                                                                                                                <td
                                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                                    <div
                                                                                                                                                        class="checkbox-align">
                                                                                                                                                        <p-checkbox
                                                                                                                                                            _ngcontent-egj-c329=""
                                                                                                                                                            styleclass="custom-checkbox"
                                                                                                                                                            binary="true"
                                                                                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                                            <div
                                                                                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                                                <div
                                                                                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                                                                                    <input
                                                                                                                                                                        type="checkbox"
                                                                                                                                                                        name="undefined"
                                                                                                                                                                        value="undefined">
                                                                                                                                                                </div>
                                                                                                                                                                <div role="checkbox"
                                                                                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                                    <span
                                                                                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                                                </div>
                                                                                                                                                            </div>
                                    
                                                                                                                                                        </p-checkbox>
                                                                                                                                                        {{activity.workEffortId}}
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                                    {{activity.workEffortName}}
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                                    {{activity.quantityToProduce}}
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                                    {{activity.quantityProduced}}
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                                    {{activity.estimatedStartDate|date:'yyyy-MM-dd   hh:mm:ss'}}
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    [ngStyle]="{'width':'190px'}">
                                                                                                                                                    {{activity.estimatedCompletionDate|date:'yyyy-MM-dd      hh:mm:ss'}}
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                                    {{activity.actualStartDate|date:'yyyy-MM-dd  hh:mm:ss'}}
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                                    {{activity.actualCompletionDate|date:'yyyy-MM-dd   hh:mm:ss'}}
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                                                                        (click)="removeRoutingTaskAssoc(activity.fromDate,activity.workEffortAssocTypeId,activity.workEffortId,activity.workEffortIdFrom,activity.workEffortIdTo)"
                                                                                                                                                        class="ml-20"
                                                                                                                                                        width="16"
                                                                                                                                                        height="16"
                                                                                                                                                        viewBox="0 0 26 26">
                                                                                                                                                        <defs>
                                                                                                                                                            <style>
                                                                                                                                                                .a {
                                                                                                                                                                    fill: #f44336;
                                                                                                                                                                }
                                    
                                                                                                                                                                .b {
                                                                                                                                                                    fill: #fafafa;
                                                                                                                                                                    margin-left: 20px;
                                                                                                                                                                }
                                                                                                                                                            </style>
                                                                                                                                                        </defs>
                                                                                                                                                        <path
                                                                                                                                                            class="a fill-color"
                                                                                                                                                            d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                                                                                        <path
                                                                                                                                                            class="b"
                                                                                                                                                            d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                                                                                            transform="translate(-147.576 -147.576)" />
                                                                                                                                                    </svg>
                                                                                                                                                </td>
                                    
                                    
                                    
                                                                                                                                            </tr>
                                                                                                                                        </ng-template>
                                                                                                                                        <ng-template
                                                                                                                                            pTemplate="emptymessage"
                                                                                                                                            let-columns>
                                                                                                                                            <tr>
                                                                                                                                                <td>
                                                                                                                                                    <span
                                                                                                                                                        class="text-center">
                                                                                                                                                        <h4
                                                                                                                                                            class="ml-2">
                                                                                                                                                            No
                                                                                                                                                            Record
                                                                                                                                                            Found
                                                                                                                                                        </h4>
                                                                                                                                                    </span>
                                                                                                                                                </td>
                                                                                                                                            </tr>
                                                                                                                                        </ng-template>
                                                                                                                                    </p-table>
                                                                                                                                    <p
                                                                                                                                        class="paginate_data">
                                                                                                                                        View per page
                                    
                                                                                                                                    </p>
                                                                                                                                </div>
                                                                                                                            </div>
                                    
                                    
                                    
                                    
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                    
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <!-- <div *ngIf="precedingProductionRun && precedingProductionRun.length" class="all-users-infomation font-13 font-medium own-user-select" style="display: block!important;">
                                                                                                    <div class="w3-card-4 classCard">
                                                                                                        <header class="w3-container w3-blue">
                                                                                                            <div class="header-tabs">
                                                                                                                <h4 class=" common-styling Hleft">
                                                                                                                    Manufacturing Mandatory WorkEfforts
                                                                                                                </h4>
                                    
                                                                                                            </div>
                                                                                                        </header>
                                    
                                                                                                        <div class="w3-container">
                                                                                                            <div class="panel-body">
                                                                                                                <div class="all-users-infomation font-13 font-medium own-user-select allUserDetails">
                                                                                                                    <div class="card own-account-table">
                                                                                                                        <p-table [value]="precedingProductionRun" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                                            <ng-template pTemplate="header">
                                                                                                                                <tr style="background: #0d3769;">
                                                                                                                                    <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                                                        <div class="checkbox-align" style="color: white;">
                                    
                                                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                                                        <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                                
                                                                                                                                            </p-checkbox>
                                    
                                    
                                                                                                                                            ID
                                                                                                                                            <p-sortIcon field="code"></p-sortIcon>
                                                                                                                                        </div>
                                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                    </th>
                                                                                                                                    <th pSortableColumn="name" [ngStyle]="{'width':'170px'}">
                                                                                                                                        <div style="color: white;"> Routing Task Name
                                                                                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                                                                                        </div>
                                                                                                                                        <div>
                                                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                        </div>
                                                                                                                                    </th>
                                                                                                                                    <th pSortableColumn="name" [ngStyle]="{'width':'170px'}">
                                                                                                                                        <div style="color: white;"> Qty To Produce
                                                                                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                                                                                        </div>
                                                                                                                                        <div>
                                                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                        </div>
                                                                                                                                    </th>
                                                                                                                                    <th pSortableColumn="name" [ngStyle]="{'width':'170px'}">
                                                                                                                                        <div style="color: white;"> Produced
                                                                                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                                                                                        </div>
                                                                                                                                        <div>
                                                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                        </div>
                                                                                                                                    </th>
                                                                                                                                    <th pSortableColumn="name" [ngStyle]="{'width':'170px'}">
                                                                                                                                        <div style="color: white;"> Start Date
                                                                                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                                                                                        </div>
                                                                                                                                        <div>
                                                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                        </div>
                                                                                                                                    </th>
                                                                                                                                    <th pSortableColumn="name" [ngStyle]="{'width':'190px'}">
                                                                                                                                        <div style="color: white;"> Estimated Completion Date
                                                                                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                                                                                        </div>
                                                                                                                                        <div>
                                                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                        </div>
                                                                                                                                    </th>
                                                                                                                                    <th pSortableColumn="name" [ngStyle]="{'width':'170px'}">
                                                                                                                                        <div style="color: white;"> Actual Start Date
                                                                                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                                                                                        </div>
                                                                                                                                        <div>
                                                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                        </div>
                                                                                                                                    </th>
                                                                                                                                    <th pSortableColumn="name" [ngStyle]="{'width':'170px'}">
                                                                                                                                        <div style="color: white;"> Completion Date
                                                                                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                                                                                        </div>
                                                                                                                                        <div>
                                                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                        </div>
                                                                                                                                    </th>
                                                                                                                                    <th pSortableColumn="name" [ngStyle]="{'width':'170px'}">
                                                                                                                                        <div style="color: white;"> Action
                                                                                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                                                                                        </div>
                                                                                                                                        <div>
                                                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                        </div>
                                                                                                                                    </th>
                                    
                                    
                                    
                                    
                                                                                                                                </tr>
                                                                                                                            </ng-template>
                                                                                                                            <ng-template pTemplate="body" let-activity>
                                                                                                                                <tr>
                                                                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                                                                        <div class="checkbox-align">
                                                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                                                        <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                                
                                                                                                                                            </p-checkbox>
                                                                                                                                            {{activity.workEffortId}}
                                                                                                                                        </div>
                                                                                                                                    </td>
                                                                                                                                    <td [ngStyle]="{'width':'170px'}"> {{activity.workEffortName}} </td>
                                                                                                                                    <td [ngStyle]="{'width':'170px'}"> {{activity.quantityToProduce}}</td>
                                                                                                                                    <td [ngStyle]="{'width':'170px'}"> {{activity.quantityProduced}} </td>
                                                                                                                                    <td [ngStyle]="{'width':'170px'}"> {{activity.estimatedStartDate|date:'yyyy-MM-dd hh:mm:ss'}} </td>
                                                                                                                                    <td [ngStyle]="{'width':'190px'}"> {{activity.estimatedCompletionDate|date:'yyyy-MM-dd hh:mm:ss'}}</td>
                                                                                                                                    <td [ngStyle]="{'width':'170px'}"> {{activity.actualStartDate|date:'yyyy-MM-dd hh:mm:ss'}} </td>
                                                                                                                                    <td [ngStyle]="{'width':'170px'}"> {{activity.actualCompletionDate|date:'yyyy-MM-dd hh:mm:ss'}} </td>
                                                                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" (click)="removeRoutingTaskAssoc(activity.fromDate,activity.workEffortAssocTypeId,activity.workEffortId,activity.workEffortIdFrom,activity.workEffortIdTo)" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                                                                            <defs>
                                                                                                <style>
                                                                                                    .a {
                                                                                                        fill: #f44336;
                                                                                                    }
                                                  
                                                                                                    .b {
                                                                                                        fill: #fafafa;
                                                                                                        margin-left: 20px;
                                                                                                    }
                                                                                                </style>
                                                                                            </defs>
                                                                                            <path class="a fill-color"
                                                                                                d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                            <path class="b"
                                                                                                d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                                transform="translate(-147.576 -147.576)" />
                                                                                        </svg> </td>
                                    
                                    
                                    
                                                                                                                                </tr>
                                                                                                                            </ng-template>
                                                                                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                                                                                <tr>
                                                                                                                                    <td>
                                                                                                                                        <span class="text-center">
                                                                                                    <h4 class="ml-2">No Record Found
                                                                                                    </h4>
                                                                                                </span>
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                            </ng-template>
                                                                                                                        </p-table>
                                                                                                                        <p class="paginate_data">
                                                                                                                            View per page
                                    
                                                                                                                        </p>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                    
                                    
                                                                                                    </div>
                                                                                                </div> -->
                                    
                                                                                                    <!-- <div *ngIf="succeedingProductionRun && succeedingProductionRun.length" class="all-users-infomation font-13 font-medium own-user-select" style="display: block!important;">
                                                                                                    <div class="w3-card-4 classCard">
                                                                                                        <header class="w3-container w3-blue">
                                                                                                            <div class="header-tabs">
                                                                                                                <h4 class=" common-styling Hleft">
                                                                                                                    Manufacturing Dependent WorkEfforts
                                                                                                                </h4>
                                    
                                                                                                            </div>
                                                                                                        </header>
                                    
                                                                                                        <div class="w3-container">
                                                                                                            <div class="panel-body">
                                                                                                                <div class="all-users-infomation font-13 font-medium own-user-select allUserDetails">
                                                                                                                    <div class="card own-account-table">
                                                                                                                        <p-table [value]="succeedingProductionRun" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                                            <ng-template pTemplate="header">
                                                                                                                                <tr style="background: #0d3769;">
                                                                                                                                    <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                                                        <div class="checkbox-align" style="color: white;">
                                    
                                    
                                                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                                                        <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                                
                                                                                                                                            </p-checkbox>
                                    
                                                                                                                                            ID
                                                                                                                                            <p-sortIcon field="code"></p-sortIcon>
                                                                                                                                        </div>
                                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                    </th>
                                                                                                                                    <th pSortableColumn="name" [ngStyle]="{'width':'170px'}">
                                                                                                                                        <div style="color: white;"> Routing Task Name
                                                                                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                                                                                        </div>
                                                                                                                                        <div>
                                                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                        </div>
                                                                                                                                    </th>
                                                                                                                                    <th pSortableColumn="name" [ngStyle]="{'width':'170px'}">
                                                                                                                                        <div style="color: white;"> Qty To Produce
                                                                                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                                                                                        </div>
                                                                                                                                        <div>
                                                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                        </div>
                                                                                                                                    </th>
                                                                                                                                    <th pSortableColumn="name" [ngStyle]="{'width':'170px'}">
                                                                                                                                        <div style="color: white;"> Produced
                                                                                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                                                                                        </div>
                                                                                                                                        <div>
                                                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                        </div>
                                                                                                                                    </th>
                                                                                                                                    <th pSortableColumn="name" [ngStyle]="{'width':'170px'}">
                                                                                                                                        <div style="color: white;"> Start Date
                                                                                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                                                                                        </div>
                                                                                                                                        <div>
                                                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                        </div>
                                                                                                                                    </th>
                                                                                                                                    <th pSortableColumn="name" [ngStyle]="{'width':'190px'}">
                                                                                                                                        <div style="color: white;"> Estimated Completion Date
                                                                                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                                                                                        </div>
                                                                                                                                        <div>
                                                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                        </div>
                                                                                                                                    </th>
                                                                                                                                    <th pSortableColumn="name" [ngStyle]="{'width':'170px'}">
                                                                                                                                        <div style="color: white;"> Actual Start Date
                                                                                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                                                                                        </div>
                                                                                                                                        <div>
                                                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                        </div>
                                                                                                                                    </th>
                                                                                                                                    <th pSortableColumn="name" [ngStyle]="{'width':'170px'}">
                                                                                                                                        <div style="color: white;"> Completion Date
                                                                                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                                                                                        </div>
                                                                                                                                        <div>
                                                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                        </div>
                                                                                                                                    </th>
                                                                                                                                    <th pSortableColumn="name" [ngStyle]="{'width':'170px'}">
                                                                                                                                        <div style="color: white;"> Action
                                                                                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                                                                                        </div>
                                                                                                                                        <div>
                                                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                        </div>
                                                                                                                                    </th>
                                    
                                    
                                    
                                    
                                                                                                                                </tr>
                                                                                                                            </ng-template>
                                                                                                                            <ng-template pTemplate="body" let-activity>
                                                                                                                                <tr>
                                                                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                                                                        <div class="checkbox-align">
                                                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                                                        <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                                
                                                                                                                                            </p-checkbox>
                                                                                                                                            {{activity.workEffortIdTo}}
                                                                                                                                        </div>
                                                                                                                                    </td>
                                                                                                                                    <td [ngStyle]="{'width':'170px'}"> {{activity.workEffortName}} </td>
                                                                                                                                    <td [ngStyle]="{'width':'170px'}"> {{activity.quantityToProduce}}</td>
                                                                                                                                    <td [ngStyle]="{'width':'170px'}"> {{activity.quantityProduced}} </td>
                                                                                                                                    <td [ngStyle]="{'width':'170px'}"> {{activity.estimatedStartDate|date:'yyyy-MM-dd hh:mm:ss'}} </td>
                                                                                                                                    <td [ngStyle]="{'width':'190px'}"> {{activity.estimatedCompletionDate|date:'yyyy-MM-dd hh:mm:ss'}}</td>
                                                                                                                                    <td [ngStyle]="{'width':'170px'}"> {{activity.actualStartDate|date:'yyyy-MM-dd hh:mm:ss'}} </td>
                                                                                                                                    <td [ngStyle]="{'width':'170px'}"> {{activity.actualCompletionDate|date:'yyyy-MM-dd hh:mm:ss'}} </td>
                                                                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                                                                        <svg xmlns="http://www.w3.org/2000/svg" (click)="removeRoutingTaskAssoc(activity.fromDate,activity.workEffortAssocTypeId,activity.workEffortId,activity.workEffortIdFrom,activity.workEffortIdTo)" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                                                                                  <defs>
                                                                                                      <style>
                                                                                                          .a {
                                                                                                              fill: #f44336;
                                                                                                          }
                                                        
                                                                                                          .b {
                                                                                                              fill: #fafafa;
                                                                                                              margin-left: 20px;
                                                                                                          }
                                                                                                      </style>
                                                                                                  </defs>
                                                                                                  <path class="a fill-color"
                                                                                                      d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                                  <path class="b"
                                                                                                      d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                                      transform="translate(-147.576 -147.576)" />
                                                                                              </svg>
                                                                                                                                    </td>
                                    
                                    
                                    
                                                                                                                                </tr>
                                                                                                                            </ng-template>
                                                                                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                                                                                <tr>
                                                                                                                                    <td>
                                                                                                                                        <span class="text-center">
                                                                                                        <h4 class="ml-2">No Record Found
                                                                                                        </h4>
                                                                                                    </span>
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                            </ng-template>
                                                                                                                        </p-table>
                                                                                                                        <p class="paginate_data">
                                                                                                                            View per page
                                    
                                                                                                                        </p>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                    
                                    
                                                                                                    </div>
                                                                                                </div> -->
                                                                                                    <div *ngIf="succeedingProductionRun && succeedingProductionRun.length"
                                                                                                        class="all-users-infomation font-13 font-medium own-user-select"
                                                                                                        style="display: block!important;">
                                                                                                        <div class="w3-card-4  w-100  mt-3">
                                                                                                            <div class="accordion classCard"
                                                                                                                id="myAccordion">
                                                                                                                <div class="accordion-item">
                                                                                                                    <h2 class="accordion-header"
                                                                                                                        id="headingOne">
                                                                                                                        <div
                                                                                                                            class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion">
                                                                                                                            <span class="headingAccords">
                                                                                                                                Manufacturing Dependent
                                                                                                                                WorkEfforts
                                                                                                                            </span>
                                    
                                    
                                                                                                                            <button type="button"
                                                                                                                                class="accordion-button  collapsed"
                                                                                                                                data-bs-toggle="collapse"
                                                                                                                                data-bs-target="#collapse8"
                                                                                                                                style="margin-left: 76%!important; padding-top: 11px !important;
                                                                                                                        padding-bottom: 11px !important;">
                                                                                                                            </button>
                                    
                                                                                                                        </div>
                                    
                                                                                                                    </h2>
                                                                                                                    <div id="collapse8"
                                                                                                                        class="accordion-collapse collapse"
                                                                                                                        data-bs-parent="#myAccordion">
                                                                                                                        <div class="card-body">
                                                                                                                            <div
                                                                                                                                class="all-users-infomation font-13 font-medium own-user-select allUserDetails">
                                                                                                                                <div
                                                                                                                                    class="card own-account-table">
                                                                                                                                    <p-table
                                                                                                                                        [value]="succeedingProductionRun"
                                                                                                                                        [paginator]="true"
                                                                                                                                        [rows]="rows"
                                                                                                                                        scrollWidth="100%"
                                                                                                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]"
                                                                                                                                        [totalRecords]="total"
                                                                                                                                        [scrollable]="true"
                                                                                                                                        (sortFunction)="customSort($event)"
                                                                                                                                        [customSort]="true">
                                                                                                                                        <ng-template
                                                                                                                                            pTemplate="header">
                                                                                                                                            <tr
                                                                                                                                                style="background: #0d3769;">
                                                                                                                                                <th pSortableColumn="code"
                                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                                    <div class="checkbox-align"
                                                                                                                                                        style="color: white;">
                                    
                                    
                                                                                                                                                        <p-checkbox
                                                                                                                                                            _ngcontent-egj-c329=""
                                                                                                                                                            styleclass="custom-checkbox"
                                                                                                                                                            binary="true"
                                                                                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                                            <div
                                                                                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                                                <div
                                                                                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                                                                                    <input
                                                                                                                                                                        type="checkbox"
                                                                                                                                                                        name="undefined"
                                                                                                                                                                        value="undefined">
                                                                                                                                                                </div>
                                                                                                                                                                <div role="checkbox"
                                                                                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                                    <span
                                                                                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                                                </div>
                                                                                                                                                            </div>
                                    
                                                                                                                                                        </p-checkbox>
                                    
                                                                                                                                                        ID
                                                                                                                                                        <p-sortIcon
                                                                                                                                                            field="code"></p-sortIcon>
                                                                                                                                                    </div>
                                                                                                                                                    <input
                                                                                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                                        value=""
                                                                                                                                                        autocomplete="off"
                                                                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                        role="textbox"
                                                                                                                                                        aria-disabled="false"
                                                                                                                                                        aria-readonly="false">
                                                                                                                                                </th>
                                                                                                                                                <th pSortableColumn="name"
                                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                                    <div
                                                                                                                                                        style="color: white;">
                                                                                                                                                        Routing
                                                                                                                                                        Task
                                                                                                                                                        Name
                                                                                                                                                        <p-sortIcon
                                                                                                                                                            field="name"></p-sortIcon>
                                                                                                                                                    </div>
                                                                                                                                                    <div>
                                                                                                                                                        <input
                                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                                            value=""
                                                                                                                                                            autocomplete="off"
                                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                            role="textbox"
                                                                                                                                                            aria-disabled="false"
                                                                                                                                                            aria-readonly="false">
                                                                                                                                                    </div>
                                                                                                                                                </th>
                                                                                                                                                <th pSortableColumn="name"
                                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                                    <div
                                                                                                                                                        style="color: white;">
                                                                                                                                                        Qty
                                                                                                                                                        To
                                                                                                                                                        Produce
                                                                                                                                                        <p-sortIcon
                                                                                                                                                            field="name"></p-sortIcon>
                                                                                                                                                    </div>
                                                                                                                                                    <div>
                                                                                                                                                        <input
                                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                                            value=""
                                                                                                                                                            autocomplete="off"
                                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                            role="textbox"
                                                                                                                                                            aria-disabled="false"
                                                                                                                                                            aria-readonly="false">
                                                                                                                                                    </div>
                                                                                                                                                </th>
                                                                                                                                                <th pSortableColumn="name"
                                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                                    <div
                                                                                                                                                        style="color: white;">
                                                                                                                                                        Produced
                                                                                                                                                        <p-sortIcon
                                                                                                                                                            field="name"></p-sortIcon>
                                                                                                                                                    </div>
                                                                                                                                                    <div>
                                                                                                                                                        <input
                                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                                            value=""
                                                                                                                                                            autocomplete="off"
                                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                            role="textbox"
                                                                                                                                                            aria-disabled="false"
                                                                                                                                                            aria-readonly="false">
                                                                                                                                                    </div>
                                                                                                                                                </th>
                                                                                                                                                <th pSortableColumn="name"
                                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                                    <div
                                                                                                                                                        style="color: white;">
                                                                                                                                                        Start
                                                                                                                                                        Date
                                                                                                                                                        <p-sortIcon
                                                                                                                                                            field="name"></p-sortIcon>
                                                                                                                                                    </div>
                                                                                                                                                    <div>
                                                                                                                                                        <input
                                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                                            value=""
                                                                                                                                                            autocomplete="off"
                                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                            role="textbox"
                                                                                                                                                            aria-disabled="false"
                                                                                                                                                            aria-readonly="false">
                                                                                                                                                    </div>
                                                                                                                                                </th>
                                                                                                                                                <th pSortableColumn="name"
                                                                                                                                                    [ngStyle]="{'width':'190px'}">
                                                                                                                                                    <div
                                                                                                                                                        style="color: white;">
                                                                                                                                                        Estimated
                                                                                                                                                        Completion
                                                                                                                                                        Date
                                                                                                                                                        <p-sortIcon
                                                                                                                                                            field="name"></p-sortIcon>
                                                                                                                                                    </div>
                                                                                                                                                    <div>
                                                                                                                                                        <input
                                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                                            value=""
                                                                                                                                                            autocomplete="off"
                                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                            role="textbox"
                                                                                                                                                            aria-disabled="false"
                                                                                                                                                            aria-readonly="false">
                                                                                                                                                    </div>
                                                                                                                                                </th>
                                                                                                                                                <th pSortableColumn="name"
                                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                                    <div
                                                                                                                                                        style="color: white;">
                                                                                                                                                        Actual
                                                                                                                                                        Start
                                                                                                                                                        Date
                                                                                                                                                        <p-sortIcon
                                                                                                                                                            field="name"></p-sortIcon>
                                                                                                                                                    </div>
                                                                                                                                                    <div>
                                                                                                                                                        <input
                                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                                            value=""
                                                                                                                                                            autocomplete="off"
                                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                            role="textbox"
                                                                                                                                                            aria-disabled="false"
                                                                                                                                                            aria-readonly="false">
                                                                                                                                                    </div>
                                                                                                                                                </th>
                                                                                                                                                <th pSortableColumn="name"
                                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                                    <div
                                                                                                                                                        style="color: white;">
                                                                                                                                                        Completion
                                                                                                                                                        Date
                                                                                                                                                        <p-sortIcon
                                                                                                                                                            field="name"></p-sortIcon>
                                                                                                                                                    </div>
                                                                                                                                                    <div>
                                                                                                                                                        <input
                                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                                            value=""
                                                                                                                                                            autocomplete="off"
                                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                            role="textbox"
                                                                                                                                                            aria-disabled="false"
                                                                                                                                                            aria-readonly="false">
                                                                                                                                                    </div>
                                                                                                                                                </th>
                                                                                                                                                <th pSortableColumn="name"
                                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                                    <div
                                                                                                                                                        style="color: white;">
                                                                                                                                                        Action
                                                                                                                                                        <p-sortIcon
                                                                                                                                                            field="name"></p-sortIcon>
                                                                                                                                                    </div>
                                                                                                                                                    <div>
                                                                                                                                                        <input
                                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                                            value=""
                                                                                                                                                            autocomplete="off"
                                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                            role="textbox"
                                                                                                                                                            aria-disabled="false"
                                                                                                                                                            aria-readonly="false">
                                                                                                                                                    </div>
                                                                                                                                                </th>
                                    
                                    
                                    
                                    
                                                                                                                                            </tr>
                                                                                                                                        </ng-template>
                                                                                                                                        <ng-template
                                                                                                                                            pTemplate="body"
                                                                                                                                            let-activity>
                                                                                                                                            <tr>
                                                                                                                                                <td
                                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                                    <div
                                                                                                                                                        class="checkbox-align">
                                                                                                                                                        <p-checkbox
                                                                                                                                                            _ngcontent-egj-c329=""
                                                                                                                                                            styleclass="custom-checkbox"
                                                                                                                                                            binary="true"
                                                                                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                                            <div
                                                                                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                                                <div
                                                                                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                                                                                    <input
                                                                                                                                                                        type="checkbox"
                                                                                                                                                                        name="undefined"
                                                                                                                                                                        value="undefined">
                                                                                                                                                                </div>
                                                                                                                                                                <div role="checkbox"
                                                                                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                                    <span
                                                                                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                                                </div>
                                                                                                                                                            </div>
                                    
                                                                                                                                                        </p-checkbox>
                                                                                                                                                        {{activity.workEffortIdTo}}
                                                                                                                                                    </div>
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                                    {{activity.workEffortName}}
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                                    {{activity.quantityToProduce}}
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                                    {{activity.quantityProduced}}
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                                    {{activity.estimatedStartDate|date:'yyyy-MM-dd  hh:mm:ss'}}
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    [ngStyle]="{'width':'190px'}">
                                                                                                                                                    {{activity.estimatedCompletionDate|date:'yyyy-MM-dd  hh:mm:ss'}}
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                                    {{activity.actualStartDate|date:'yyyy-MM-dd  hh:mm:ss'}}
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                                    {{activity.actualCompletionDate|date:'yyyy-MM-dd  hh:mm:ss'}}
                                                                                                                                                </td>
                                                                                                                                                <td
                                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                                                                                                        (click)="removeRoutingTaskAssoc(activity.fromDate,activity.workEffortAssocTypeId,activity.workEffortId,activity.workEffortIdFrom,activity.workEffortIdTo)"
                                                                                                                                                        class="ml-20"
                                                                                                                                                        width="16"
                                                                                                                                                        height="16"
                                                                                                                                                        viewBox="0 0 26 26">
                                                                                                                                                        <defs>
                                                                                                                                                            <style>
                                                                                                                                                                .a {
                                                                                                                                                                    fill: #f44336;
                                                                                                                                                                }
                                    
                                                                                                                                                                .b {
                                                                                                                                                                    fill: #fafafa;
                                                                                                                                                                    margin-left: 20px;
                                                                                                                                                                }
                                                                                                                                                            </style>
                                                                                                                                                        </defs>
                                                                                                                                                        <path
                                                                                                                                                            class="a fill-color"
                                                                                                                                                            d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                                                                                        <path
                                                                                                                                                            class="b"
                                                                                                                                                            d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                                                                                            transform="translate(-147.576 -147.576)" />
                                                                                                                                                    </svg>
                                                                                                                                                </td>
                                    
                                    
                                    
                                                                                                                                            </tr>
                                                                                                                                        </ng-template>
                                                                                                                                        <ng-template
                                                                                                                                            pTemplate="emptymessage"
                                                                                                                                            let-columns>
                                                                                                                                            <tr>
                                                                                                                                                <td>
                                                                                                                                                    <span
                                                                                                                                                        class="text-center">
                                                                                                                                                        <h4
                                                                                                                                                            class="ml-2">
                                                                                                                                                            No
                                                                                                                                                            Record
                                                                                                                                                            Found
                                                                                                                                                        </h4>
                                                                                                                                                    </span>
                                                                                                                                                </td>
                                                                                                                                            </tr>
                                                                                                                                        </ng-template>
                                                                                                                                    </p-table>
                                                                                                                                    <p
                                                                                                                                        class="paginate_data">
                                                                                                                                        View per page
                                    
                                                                                                                                    </p>
                                                                                                                                </div>
                                                                                                                            </div>
                                    
                                    
                                    
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                    
                                                                                                        </div>
                                                                                                    </div>
                                    
                                                                                                    <!-- <div class="w3-card-4  w-100  mt-3">
                                                                                                        <div class="accordion classCard" id="myAccordion">
                                                                                                            <div class="accordion-item">
                                                                                                                <h2 class="accordion-header"
                                                                                                                    id="headingOne">
                                                                                                                    <div
                                                                                                                        class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion">
                                                                                                                        <span class="headingAccords">
                                                                                                                            Materials
                                                                                                                        </span>
                                    
                                                                                                                        <button type="button"
                                                                                                                            class="accordion-button  collapsed"
                                                                                                                            data-bs-toggle="collapse"
                                                                                                                            data-bs-target="#collapse13"
                                                                                                                            style="margin-left: 71%!important; padding-top: 11px !important;
                                                                                                                    padding-bottom: 11px !important;">
                                                                                                                        </button>
                                    
                                                                                                                    </div>
                                    
                                                                                                                </h2>
                                                                                                                <div id="collapse13"
                                                                                                                    class="accordion-collapse collapse"
                                                                                                                    data-bs-parent="#myAccordion">
                                                                                                                    <div class="card-body">
                                                                                                                        <div
                                                                                                                            class="all-users-infomation font-13 font-medium own-user-select allUserDetails">
                                                                                                                            <div
                                                                                                                                class="card own-account-table">
                                                                                                                                <p-table
                                                                                                                                    [value]="productionRunMaterialsData"
                                                                                                                                    [paginator]="true"
                                                                                                                                    [rows]="rows"
                                                                                                                                    scrollWidth="100%"
                                                                                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]"
                                                                                                                                    [totalRecords]="total"
                                                                                                                                    [scrollable]="true"
                                                                                                                                    (sortFunction)="customSort($event)"
                                                                                                                                    [customSort]="true">
                                                                                                                                    <ng-template
                                                                                                                                        pTemplate="header">
                                                                                                                                        <tr
                                                                                                                                            style="background: #0d3769;">
                                                                                                                                            <th pSortableColumn="code"
                                                                                                                                                [ngStyle]="{'width':'170px'}">
                                                                                                                                                <div class="checkbox-align"
                                                                                                                                                    style="color: white;">
                                    
                                                                                                                                                    <p-checkbox
                                                                                                                                                        _ngcontent-egj-c329=""
                                                                                                                                                        styleclass="custom-checkbox"
                                                                                                                                                        binary="true"
                                                                                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                                        <div
                                                                                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                                            <div
                                                                                                                                                                class="ui-helper-hidden-accessible">
                                                                                                                                                                <input
                                                                                                                                                                    type="checkbox"
                                                                                                                                                                    name="undefined"
                                                                                                                                                                    value="undefined">
                                                                                                                                                            </div>
                                                                                                                                                            <div role="checkbox"
                                                                                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                                <span
                                                                                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                                            </div>
                                                                                                                                                        </div>
                                    
                                                                                                                                                    </p-checkbox>
                                    
                                    
                                    
                                    
                                                                                                                                                    Routing
                                                                                                                                                    Task
                                                                                                                                                    ID
                                                                                                                                                    <p-sortIcon
                                                                                                                                                        field="code"></p-sortIcon>
                                                                                                                                                </div>
                                                                                                                                                <input
                                                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                                    value=""
                                                                                                                                                    autocomplete="off"
                                                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                    role="textbox"
                                                                                                                                                    aria-disabled="false"
                                                                                                                                                    aria-readonly="false">
                                                                                                                                            </th>
                                                                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                                                                pSortableColumn="name">
                                                                                                                                                <div
                                                                                                                                                    style="color: white;">
                                                                                                                                                    Product
                                                                                                                                                    Name
                                                                                                                                                    <p-sortIcon
                                                                                                                                                        field="name"></p-sortIcon>
                                                                                                                                                </div>
                                                                                                                                                <div>
                                                                                                                                                    <input
                                                                                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                                        value=""
                                                                                                                                                        autocomplete="off"
                                                                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                        role="textbox"
                                                                                                                                                        aria-disabled="false"
                                                                                                                                                        aria-readonly="false">
                                                                                                                                                </div>
                                                                                                                                            </th>
                                    
                                                                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                                                                pSortableColumn="name">
                                                                                                                                                <div
                                                                                                                                                    style="color: white;">
                                                                                                                                                    Quantity
                                                                                                                                                    <p-sortIcon
                                                                                                                                                        field="name"></p-sortIcon>
                                                                                                                                                </div>
                                                                                                                                                <div>
                                                                                                                                                    <input
                                                                                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                                        value=""
                                                                                                                                                        autocomplete="off"
                                                                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                        role="textbox"
                                                                                                                                                        aria-disabled="false"
                                                                                                                                                        aria-readonly="false">
                                                                                                                                                </div>
                                                                                                                                            </th>
                                                                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                                                                pSortableColumn="name">
                                                                                                                                                <div
                                                                                                                                                    style="color: white;">
                                                                                                                                                    UOM
                                                                                                                                                    <p-sortIcon
                                                                                                                                                        field="name"></p-sortIcon>
                                                                                                                                                </div>
                                                                                                                                                <div>
                                                                                                                                                    <input
                                                                                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                                        value=""
                                                                                                                                                        autocomplete="off"
                                                                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                        role="textbox"
                                                                                                                                                        aria-disabled="false"
                                                                                                                                                        aria-readonly="false">
                                                                                                                                                </div>
                                                                                                                                            </th>
                                    
                                    
                                    
                                    
                                    
                                    
                                                                                                                                        </tr>
                                                                                                                                    </ng-template>
                                                                                                                                    <ng-template
                                                                                                                                        pTemplate="body"
                                                                                                                                        let-product>
                                                                                                                                        <tr>
                                                                                                                                            <td
                                                                                                                                                [ngStyle]="{'width':'170px'}">
                                                                                                                                                <div
                                                                                                                                                    class="checkbox-align">
                                                                                                                                                    <p-checkbox
                                                                                                                                                        _ngcontent-egj-c329=""
                                                                                                                                                        styleclass="custom-checkbox"
                                                                                                                                                        binary="true"
                                                                                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                                        <div
                                                                                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                                            <div
                                                                                                                                                                class="ui-helper-hidden-accessible">
                                                                                                                                                                <input
                                                                                                                                                                    type="checkbox"
                                                                                                                                                                    name="undefined"
                                                                                                                                                                    value="undefined">
                                                                                                                                                            </div>
                                                                                                                                                            <div role="checkbox"
                                                                                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                                <span
                                                                                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                                            </div>
                                                                                                                                                        </div>
                                    
                                                                                                                                                    </p-checkbox>
                                                                                                                                                    {{product.workEffortId}}
                                                                                                                                                </div>
                                                                                                                                            </td>
                                                                                                                                            <td
                                                                                                                                                [ngStyle]="{'width':'170px'}">
                                                                                                                                                {{product.productName}}
                                                                                                                                            </td>
                                                                                                                                            <td
                                                                                                                                                [ngStyle]="{'width':'170px'}">
                                                                                                                                                {{product.estimatedQuantity}}
                                                                                                                                            </td>
                                                                                                                                            <td
                                                                                                                                                [ngStyle]="{'width':'170px'}">
                                                                                                                                                {{product.uomDesc}}
                                                                                                                                            </td>
                                    
                                    
                                    
                                    
                                                                                                                                        </tr>
                                                                                                                                    </ng-template>
                                                                                                                                    <ng-template
                                                                                                                                        pTemplate="emptymessage"
                                                                                                                                        let-columns>
                                                                                                                                        <tr>
                                                                                                                                            <td>
                                                                                                                                                <span
                                                                                                                                                    class="text-center">
                                                                                                                                                    <h4
                                                                                                                                                        class="ml-2">
                                                                                                                                                        No
                                                                                                                                                        Record
                                                                                                                                                        Found
                                                                                                                                                    </h4>
                                                                                                                                                </span>
                                                                                                                                            </td>
                                                                                                                                        </tr>
                                                                                                                                    </ng-template>
                                                                                                                                </p-table>
                                                                                                                                <p class="paginate_data">
                                                                                                                                    View per page
                                    
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                        </div>
                                    
                                    
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                    
                                                                                                    </div> -->
                                                                                            
                                    
                                                                                               
                                    
                                                                                               <!-- <div class="w3-card-4  w-100 ">
                                                                                                        <div class="accordion classCard" id="myAccordion">
                                                                                                            <div class="accordion-item">
                                                                                                                <h2 class="accordion-header"
                                                                                                                    id="headingOne">
                                                                                                                    <div
                                                                                                                        class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion">
                                                                                                                        <span class="headingAccords"> List
                                                                                                                            Of
                                                                                                                            Production Run Fixed Assets
                                                                                                                        </span>
                                    
                                                                                                                        <button type="button"
                                                                                                                            class="accordion-button  collapsed"
                                                                                                                            data-bs-toggle="collapse"
                                                                                                                            data-bs-target="#collapse12"
                                                                                                                            style="margin-left: 45%!important; padding-top: 11px !important;
                                                                                                                        padding-bottom: 11px !important;">
                                                                                                                        </button>
                                    
                                                                                                                    </div>
                                    
                                                                                                                </h2>
                                                                                                                <div id="collapse12"
                                                                                                                    class="accordion-collapse collapse"
                                                                                                                    data-bs-parent="#myAccordion">
                                                                                                                    <div class="card-body">
                                                                                                                        <div
                                                                                                                            class="all-users-infomation font-13 font-medium own-user-select allUserDetails">
                                                                                                                            <div
                                                                                                                                class="card own-account-table">
                                                                                                                                <p-table
                                                                                                                                    [value]="productionRunAssetsData"
                                                                                                                                    [paginator]="true"
                                                                                                                                    [rows]="rows"
                                                                                                                                    scrollWidth="100%"
                                                                                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]"
                                                                                                                                    [totalRecords]="total"
                                                                                                                                    [scrollable]="true"
                                                                                                                                    (sortFunction)="customSort($event)"
                                                                                                                                    [customSort]="true">
                                                                                                                                    <ng-template
                                                                                                                                        pTemplate="header">
                                                                                                                                        <tr
                                                                                                                                            style="background: #0d3769;">
                                                                                                                                            <th pSortableColumn="code"
                                                                                                                                                [ngStyle]="{'width':'170px'}">
                                                                                                                                                <div class="checkbox-align"
                                                                                                                                                    style="color: white;">
                                    
                                                                                                                                                    <p-checkbox
                                                                                                                                                        _ngcontent-egj-c329=""
                                                                                                                                                        styleclass="custom-checkbox"
                                                                                                                                                        binary="true"
                                                                                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                                        <div
                                                                                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                                            <div
                                                                                                                                                                class="ui-helper-hidden-accessible">
                                                                                                                                                                <input
                                                                                                                                                                    type="checkbox"
                                                                                                                                                                    name="undefined"
                                                                                                                                                                    value="undefined">
                                                                                                                                                            </div>
                                                                                                                                                            <div role="checkbox"
                                                                                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                                <span
                                                                                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                                            </div>
                                                                                                                                                        </div>
                                    
                                                                                                                                                    </p-checkbox>
                                    
                                    
                                    
                                    
                                                                                                                                                    Work
                                                                                                                                                    Effort
                                                                                                                                                    ID
                                                                                                                                                    <p-sortIcon
                                                                                                                                                        field="code"></p-sortIcon>
                                                                                                                                                </div>
                                                                                                                                                <input
                                                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                                    value=""
                                                                                                                                                    autocomplete="off"
                                                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                    role="textbox"
                                                                                                                                                    aria-disabled="false"
                                                                                                                                                    aria-readonly="false">
                                                                                                                                            </th>
                                                                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                                                                pSortableColumn="name">
                                                                                                                                                <div
                                                                                                                                                    style="color: white;">
                                                                                                                                                    Fixed
                                                                                                                                                    Asset
                                                                                                                                                    ID
                                                                                                                                                    <p-sortIcon
                                                                                                                                                        field="name"></p-sortIcon>
                                                                                                                                                </div>
                                                                                                                                                <div>
                                                                                                                                                    <input
                                                                                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                                        value=""
                                                                                                                                                        autocomplete="off"
                                                                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                        role="textbox"
                                                                                                                                                        aria-disabled="false"
                                                                                                                                                        aria-readonly="false">
                                                                                                                                                </div>
                                                                                                                                            </th>
                                    
                                                                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                                                                pSortableColumn="name">
                                                                                                                                                <div
                                                                                                                                                    style="color: white;">
                                                                                                                                                    Status
                                                                                                                                                    ID
                                                                                                                                                    <p-sortIcon
                                                                                                                                                        field="name"></p-sortIcon>
                                                                                                                                                </div>
                                                                                                                                                <div>
                                                                                                                                                    <input
                                                                                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                                        value=""
                                                                                                                                                        autocomplete="off"
                                                                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                        role="textbox"
                                                                                                                                                        aria-disabled="false"
                                                                                                                                                        aria-readonly="false">
                                                                                                                                                </div>
                                                                                                                                            </th>
                                                                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                                                                pSortableColumn="name">
                                                                                                                                                <div
                                                                                                                                                    style="color: white;">
                                                                                                                                                    From
                                                                                                                                                    Date
                                                                                                                                                    <p-sortIcon
                                                                                                                                                        field="name"></p-sortIcon>
                                                                                                                                                </div>
                                                                                                                                                <div>
                                                                                                                                                    <input
                                                                                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                                        value=""
                                                                                                                                                        autocomplete="off"
                                                                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                        role="textbox"
                                                                                                                                                        aria-disabled="false"
                                                                                                                                                        aria-readonly="false">
                                                                                                                                                </div>
                                                                                                                                            </th>
                                                                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                                                                pSortableColumn="name">
                                                                                                                                                <div
                                                                                                                                                    style="color: white;">
                                                                                                                                                    Through
                                                                                                                                                    Date
                                                                                                                                                    <p-sortIcon
                                                                                                                                                        field="name"></p-sortIcon>
                                                                                                                                                </div>
                                                                                                                                                <div>
                                                                                                                                                    <input
                                                                                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                                        value=""
                                                                                                                                                        autocomplete="off"
                                                                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                        role="textbox"
                                                                                                                                                        aria-disabled="false"
                                                                                                                                                        aria-readonly="false">
                                                                                                                                                </div>
                                                                                                                                            </th>
                                    
                                                                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                                                                pSortableColumn="name">
                                                                                                                                                <div
                                                                                                                                                    style="color: white;">
                                                                                                                                                    Availability
                                                                                                                                                    <p-sortIcon
                                                                                                                                                        field="name"></p-sortIcon>
                                                                                                                                                </div>
                                                                                                                                                <div>
                                                                                                                                                    <input
                                                                                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                                        value=""
                                                                                                                                                        autocomplete="off"
                                                                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                        role="textbox"
                                                                                                                                                        aria-disabled="false"
                                                                                                                                                        aria-readonly="false">
                                                                                                                                                </div>
                                                                                                                                            </th>
                                    
                                                                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                                                                pSortableColumn="name">
                                                                                                                                                <div
                                                                                                                                                    style="color: white;">
                                                                                                                                                    Allocated
                                                                                                                                                    Cost
                                                                                                                                                    <p-sortIcon
                                                                                                                                                        field="name"></p-sortIcon>
                                                                                                                                                </div>
                                                                                                                                                <div>
                                                                                                                                                    <input
                                                                                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                                        value=""
                                                                                                                                                        autocomplete="off"
                                                                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                        role="textbox"
                                                                                                                                                        aria-disabled="false"
                                                                                                                                                        aria-readonly="false">
                                                                                                                                                </div>
                                                                                                                                            </th>
                                    
                                                                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                                                                pSortableColumn="name">
                                                                                                                                                <div
                                                                                                                                                    style="color: white;">
                                                                                                                                                    Comments
                                                                                                                                                    <p-sortIcon
                                                                                                                                                        field="name"></p-sortIcon>
                                                                                                                                                </div>
                                                                                                                                                <div>
                                                                                                                                                    <input
                                                                                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                                        value=""
                                                                                                                                                        autocomplete="off"
                                                                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                        role="textbox"
                                                                                                                                                        aria-disabled="false"
                                                                                                                                                        aria-readonly="false">
                                                                                                                                                </div>
                                                                                                                                            </th>
                                    
                                    
                                    
                                    
                                    
                                                                                                                                        </tr>
                                                                                                                                    </ng-template>
                                                                                                                                    <ng-template
                                                                                                                                        pTemplate="body"
                                                                                                                                        let-product>
                                                                                                                                        <tr>
                                                                                                                                            <td
                                                                                                                                                [ngStyle]="{'width':'170px'}">
                                                                                                                                                <div
                                                                                                                                                    class="checkbox-align">
                                                                                                                                                    <p-checkbox
                                                                                                                                                        _ngcontent-egj-c329=""
                                                                                                                                                        styleclass="custom-checkbox"
                                                                                                                                                        binary="true"
                                                                                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                                        <div
                                                                                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                                            <div
                                                                                                                                                                class="ui-helper-hidden-accessible">
                                                                                                                                                                <input
                                                                                                                                                                    type="checkbox"
                                                                                                                                                                    name="undefined"
                                                                                                                                                                    value="undefined">
                                                                                                                                                            </div>
                                                                                                                                                            <div role="checkbox"
                                                                                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                                <span
                                                                                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                                            </div>
                                                                                                                                                        </div>
                                    
                                                                                                                                                    </p-checkbox>
                                                                                                                                                    {{product.workEffortId}}
                                                                                                                                                </div>
                                                                                                                                            </td>
                                                                                                                                            <td
                                                                                                                                                [ngStyle]="{'width':'170px'}">
                                                                                                                                                {{product.fixedAssteName}}
                                                                                                                                            </td>
                                                                                                                                            <td
                                                                                                                                                [ngStyle]="{'width':'170px'}">
                                                                                                                                                {{product.statusId}}
                                                                                                                                            </td>
                                                                                                                                            <td
                                                                                                                                                [ngStyle]="{'width':'170px'}">
                                                                                                                                                {{product.fromDate|date:'yyyy-MM-dd
                                                                                                                                                hh:mm:ss'}}
                                                                                                                                            </td>
                                                                                                                                            <td
                                                                                                                                                [ngStyle]="{'width':'170px'}">
                                                                                                                                                {{product.thruDate|date:'yyyy-MM-dd
                                                                                                                                                hh:mm:ss'}}
                                                                                                                                            </td>
                                                                                                                                            <td
                                                                                                                                                [ngStyle]="{'width':'170px'}">
                                                                                                                                                {{product.availabilityStatus}}
                                                                                                                                            </td>
                                                                                                                                            <td
                                                                                                                                                [ngStyle]="{'width':'170px'}">
                                                                                                                                                {{product.allocatedCost}}
                                                                                                                                            </td>
                                                                                                                                            <td
                                                                                                                                                [ngStyle]="{'width':'170px'}">
                                                                                                                                                {{product.comments}}
                                                                                                                                            </td>
                                    
                                    
                                    
                                                                                                                                        </tr>
                                                                                                                                    </ng-template>
                                                                                                                                    <ng-template
                                                                                                                                        pTemplate="emptymessage"
                                                                                                                                        let-columns>
                                                                                                                                        <tr>
                                                                                                                                            <td>
                                                                                                                                                <span
                                                                                                                                                    class="text-center">
                                                                                                                                                    <h4
                                                                                                                                                        class="ml-2">
                                                                                                                                                        No
                                                                                                                                                        Record
                                                                                                                                                        Found
                                                                                                                                                    </h4>
                                                                                                                                                </span>
                                                                                                                                            </td>
                                                                                                                                        </tr>
                                                                                                                                    </ng-template>
                                                                                                                                </p-table>
                                                                                                                                <p class="paginate_data">
                                                                                                                                    View per page
                                    
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                        </div>
                                    
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                    
                                                                                                    </div>
                                     -->
                                                                                                    <!-- <div class="all-users-infomation font-13 font-medium own-user-select" style="display: block!important;">
                                                                                                    <div class="w3-card-4 classCard">
                                                                                                        <header class="w3-container w3-blue">
                                                                                                            <div class="header-tabs">
                                                                                                                <h4 class=" common-styling Hleft">
                                                                                                                    List Of Production Run Fixed Assets
                                                                                                                </h4>
                                    
                                                                                                            </div>
                                                                                                        </header>
                                    
                                                                                                        <div class="w3-container">
                                                                                                            <div class="panel-body">
                                                                                                                <div class="all-users-infomation font-13 font-medium own-user-select allUserDetails">
                                                                                                                    <div class="card own-account-table">
                                                                                                                        <p-table [value]="productionRunAssetsData" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                                            <ng-template pTemplate="header">
                                                                                                                                <tr style="background: #0d3769;">
                                                                                                                                    <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                                                        <div class="checkbox-align" style="color: white;">
                                    
                                                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                                                        <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                                
                                                                                                                                            </p-checkbox>
                                    
                                    
                                    
                                    
                                                                                                                                            Work Effort ID
                                                                                                                                            <p-sortIcon field="code"></p-sortIcon>
                                                                                                                                        </div>
                                                                                                                                        <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                            role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                    </th>
                                                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                        <div style="color: white;"> Fixed Asset ID
                                                                                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                                                                                        </div>
                                                                                                                                        <div>
                                                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                        </div>
                                                                                                                                    </th>
                                    
                                                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                        <div style="color: white;">Status ID
                                                                                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                                                                                        </div>
                                                                                                                                        <div>
                                                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                        </div>
                                                                                                                                    </th>
                                                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                        <div style="color: white;"> From Date
                                                                                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                                                                                        </div>
                                                                                                                                        <div>
                                                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                        </div>
                                                                                                                                    </th>
                                                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                        <div style="color: white;"> Through Date
                                                                                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                                                                                        </div>
                                                                                                                                        <div>
                                                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                        </div>
                                                                                                                                    </th>
                                    
                                                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                        <div style="color: white;">Availability
                                                                                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                                                                                        </div>
                                                                                                                                        <div>
                                                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                        </div>
                                                                                                                                    </th>
                                    
                                                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                        <div style="color: white;">Allocated Cost
                                                                                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                                                                                        </div>
                                                                                                                                        <div>
                                                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                        </div>
                                                                                                                                    </th>
                                    
                                                                                                                                    <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                        <div style="color: white;">Comments
                                                                                                                                            <p-sortIcon field="name"></p-sortIcon>
                                                                                                                                        </div>
                                                                                                                                        <div>
                                                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                        </div>
                                                                                                                                    </th>
                                    
                                    
                                    
                                    
                                    
                                                                                                                                </tr>
                                                                                                                            </ng-template>
                                                                                                                            <ng-template pTemplate="body" let-product>
                                                                                                                                <tr>
                                                                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                                                                        <div class="checkbox-align">
                                                                                                                                            <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                                <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                                    <div class="ui-helper-hidden-accessible">
                                                                                                                                                        <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                                                                    <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                        <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                                    </div>
                                                                                                                                                </div>
                                                                                                                                                
                                                                                                                                            </p-checkbox>
                                                                                                                                            {{product.workEffortId}}
                                                                                                                                        </div>
                                                                                                                                    </td>
                                                                                                                                    <td [ngStyle]="{'width':'170px'}"> {{product.fixedAssteName}} </td>
                                                                                                                                    <td [ngStyle]="{'width':'170px'}"> {{product.statusId}} </td>
                                                                                                                                    <td [ngStyle]="{'width':'170px'}"> {{product.fromDate|date:'yyyy-MM-dd hh:mm:ss'}} </td>
                                                                                                                                    <td [ngStyle]="{'width':'170px'}"> {{product.thruDate|date:'yyyy-MM-dd hh:mm:ss'}} </td>
                                                                                                                                    <td [ngStyle]="{'width':'170px'}"> {{product.availabilityStatus}} </td>
                                                                                                                                    <td [ngStyle]="{'width':'170px'}"> {{product.allocatedCost}}</td>
                                                                                                                                    <td [ngStyle]="{'width':'170px'}"> {{product.comments}} </td>
                                    
                                    
                                    
                                                                                                                                </tr>
                                                                                                                            </ng-template>
                                                                                                                            <ng-template pTemplate="emptymessage" let-columns>
                                                                                                                                <tr>
                                                                                                                                    <td>
                                                                                                                                        <span class="text-center">
                                                                                                          <h4 class="ml-2">No Record Found
                                                                                                          </h4>
                                                                                                      </span>
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                            </ng-template>
                                                                                                                        </p-table>
                                                                                                                        <p class="paginate_data">
                                                                                                                            View per page
                                    
                                                                                                                        </p>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                    
                                    
                                                                                                    </div>
                                                                                                </div> -->
                                    
                                    
                                                                                                    <!-- <div class="w3-card-4  w-100 mt-3">
                                                                                                        <div class="accordion classCard" id="myAccordion">
                                                                                                            <div class="accordion-item">
                                                                                                                <h2 class="accordion-header"
                                                                                                                    id="headingOne">
                                                                                                                    <div
                                                                                                                        class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion">
                                                                                                                        <span class="headingAccords"> Return
                                                                                                                            Unused Materials To Warehouse
                                                                                                                        </span>
                                    
                                                                                                                        <button type="button"
                                                                                                                            class="accordion-button  collapsed"
                                                                                                                            data-bs-toggle="collapse"
                                                                                                                            data-bs-target="#collapse10"
                                                                                                                            style="margin-left: 41%!important; padding-top: 11px !important;
                                                                                                                        padding-bottom: 11px !important;">
                                                                                                                        </button>
                                    
                                                                                                                    </div>
                                    
                                                                                                                </h2>
                                                                                                                <div id="collapse10"
                                                                                                                    class="accordion-collapse collapse"
                                                                                                                    data-bs-parent="#myAccordion">
                                                                                                                    <div class="panel-body">
                                                                                                                        <div
                                                                                                                            class="all-users-infomation font-13 font-medium own-user-select allUserDetails">
                                                                                                                            <div
                                                                                                                                class="card own-account-table">
                                                                                                                                <p-table
                                                                                                                                    [value]="returnUnusedMaterialsToWarehouse"
                                                                                                                                    [paginator]="true"
                                                                                                                                    [rows]="rows"
                                                                                                                                    scrollWidth="100%"
                                                                                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]"
                                                                                                                                    [totalRecords]="total"
                                                                                                                                    [scrollable]="true"
                                                                                                                                    (sortFunction)="customSort($event)"
                                                                                                                                    [customSort]="true">
                                                                                                                                    <ng-template
                                                                                                                                        pTemplate="header">
                                                                                                                                        <tr
                                                                                                                                            style="background: #0d3769;">
                                                                                                                                            <th pSortableColumn="code"
                                                                                                                                                [ngStyle]="{'width':'170px'}">
                                                                                                                                                <div class="checkbox-align"
                                                                                                                                                    style="color: white;">
                                    
                                                                                                                                                    <p-checkbox
                                                                                                                                                        _ngcontent-egj-c329=""
                                                                                                                                                        styleclass="custom-checkbox"
                                                                                                                                                        binary="true"
                                                                                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                                        <div
                                                                                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                                            <div
                                                                                                                                                                class="ui-helper-hidden-accessible">
                                                                                                                                                                <input
                                                                                                                                                                    type="checkbox"
                                                                                                                                                                    name="undefined"
                                                                                                                                                                    value="undefined">
                                                                                                                                                            </div>
                                                                                                                                                            <div role="checkbox"
                                                                                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                                <span
                                                                                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                                            </div>
                                                                                                                                                        </div>
                                    
                                                                                                                                                    </p-checkbox>
                                    
                                    
                                    
                                                                                                                                                    Routing
                                                                                                                                                    Task
                                                                                                                                                    ID
                                                                                                                                                    <p-sortIcon
                                                                                                                                                        field="code"></p-sortIcon>
                                                                                                                                                </div>
                                                                                                                                                <input
                                                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                                    value=""
                                                                                                                                                    autocomplete="off"
                                                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                    role="textbox"
                                                                                                                                                    aria-disabled="false"
                                                                                                                                                    aria-readonly="false">
                                                                                                                                            </th>
                                                                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                                                                pSortableColumn="name">
                                                                                                                                                <div
                                                                                                                                                    style="color: white;">
                                                                                                                                                    Product
                                                                                                                                                    Name
                                                                                                                                                    <p-sortIcon
                                                                                                                                                        field="name"></p-sortIcon>
                                                                                                                                                </div>
                                                                                                                                                <div>
                                                                                                                                                    <input
                                                                                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                                        value=""
                                                                                                                                                        autocomplete="off"
                                                                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                        role="textbox"
                                                                                                                                                        aria-disabled="false"
                                                                                                                                                        aria-readonly="false">
                                                                                                                                                </div>
                                                                                                                                            </th>
                                    
                                                                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                                                                pSortableColumn="name">
                                                                                                                                                <div
                                                                                                                                                    style="color: white;">
                                                                                                                                                    Quantity
                                                                                                                                                    <p-sortIcon
                                                                                                                                                        field="name"></p-sortIcon>
                                                                                                                                                </div>
                                                                                                                                                <div>
                                                                                                                                                    <input
                                                                                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                                        value=""
                                                                                                                                                        autocomplete="off"
                                                                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                        role="textbox"
                                                                                                                                                        aria-disabled="false"
                                                                                                                                                        aria-readonly="false">
                                                                                                                                                </div>
                                                                                                                                            </th>
                                                                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                                                                pSortableColumn="name">
                                                                                                                                                <div
                                                                                                                                                    style="color: white;">
                                                                                                                                                    Issued
                                                                                                                                                    <p-sortIcon
                                                                                                                                                        field="name"></p-sortIcon>
                                                                                                                                                </div>
                                                                                                                                                <div>
                                                                                                                                                    <input
                                                                                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                                        value=""
                                                                                                                                                        autocomplete="off"
                                                                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                        role="textbox"
                                                                                                                                                        aria-disabled="false"
                                                                                                                                                        aria-readonly="false">
                                                                                                                                                </div>
                                                                                                                                            </th>
                                                                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                                                                pSortableColumn="name">
                                                                                                                                                <div
                                                                                                                                                    style="color: white;">
                                                                                                                                                    Returned
                                                                                                                                                    <p-sortIcon
                                                                                                                                                        field="name"></p-sortIcon>
                                                                                                                                                </div>
                                                                                                                                                <div>
                                                                                                                                                    <input
                                                                                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                                        value=""
                                                                                                                                                        autocomplete="off"
                                                                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                        role="textbox"
                                                                                                                                                        aria-disabled="false"
                                                                                                                                                        aria-readonly="false">
                                                                                                                                                </div>
                                                                                                                                            </th>
                                    
                                                                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                                                                pSortableColumn="name">
                                                                                                                                                <div
                                                                                                                                                    style="color: white;">
                                                                                                                                                    Lot ID
                                                                                                                                                    <p-sortIcon
                                                                                                                                                        field="name"></p-sortIcon>
                                                                                                                                                </div>
                                                                                                                                                <div>
                                                                                                                                                    <input
                                                                                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                                        value=""
                                                                                                                                                        autocomplete="off"
                                                                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                        role="textbox"
                                                                                                                                                        aria-disabled="false"
                                                                                                                                                        aria-readonly="false">
                                                                                                                                                </div>
                                                                                                                                            </th>
                                    
                                                                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                                                                pSortableColumn="name">
                                                                                                                                                <div
                                                                                                                                                    style="color: white;">
                                                                                                                                                    Quantity
                                                                                                                                                    <p-sortIcon
                                                                                                                                                        field="name"></p-sortIcon>
                                                                                                                                                </div>
                                                                                                                                                <div>
                                                                                                                                                    <input
                                                                                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                                        value=""
                                                                                                                                                        autocomplete="off"
                                                                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                        role="textbox"
                                                                                                                                                        aria-disabled="false"
                                                                                                                                                        aria-readonly="false">
                                                                                                                                                </div>
                                                                                                                                            </th>
                                    
                                                                                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                                                                                pSortableColumn="name">
                                                                                                                                                <div
                                                                                                                                                    style="color: white;">
                                                                                                                                                    Selected
                                                                                                                                                    <p-sortIcon
                                                                                                                                                        field="name"></p-sortIcon>
                                                                                                                                                    <p-checkbox
                                                                                                                                                        _ngcontent-egj-c329=""
                                                                                                                                                        (click)="markUnusedCheck($event)"
                                                                                                                                                        styleclass="custom-checkbox"
                                                                                                                                                        binary="true"
                                                                                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                                        <div
                                                                                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                                            <div
                                                                                                                                                                class="ui-helper-hidden-accessible">
                                                                                                                                                                <input
                                                                                                                                                                    type="checkbox"
                                                                                                                                                                    name="undefined"
                                                                                                                                                                    value="undefined">
                                                                                                                                                            </div>
                                                                                                                                                            <div role="checkbox"
                                                                                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                                <span
                                                                                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                                            </div>
                                                                                                                                                        </div>
                                    
                                                                                                                                                    </p-checkbox>
                                                                                                                                                </div>
                                                                                                                                                <div>
                                                                                                                                                    <input
                                                                                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                                        value=""
                                                                                                                                                        autocomplete="off"
                                                                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                        role="textbox"
                                                                                                                                                        aria-disabled="false"
                                                                                                                                                        aria-readonly="false">
                                                                                                                                                </div>
                                                                                                                                            </th>
                                    
                                    
                                    
                                    
                                    
                                                                                                                                        </tr>
                                                                                                                                    </ng-template>
                                                                                                                                    <ng-template
                                                                                                                                        pTemplate="body"
                                                                                                                                        let-product>
                                                                                                                                        <tr>
                                                                                                                                            <td
                                                                                                                                                [ngStyle]="{'width':'170px'}">
                                                                                                                                                <div
                                                                                                                                                    class="checkbox-align">
                                                                                                                                                    <p-checkbox
                                                                                                                                                        _ngcontent-egj-c329=""
                                                                                                                                                        styleclass="custom-checkbox"
                                                                                                                                                        binary="true"
                                                                                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                                        <div
                                                                                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                                            <div
                                                                                                                                                                class="ui-helper-hidden-accessible">
                                                                                                                                                                <input
                                                                                                                                                                    type="checkbox"
                                                                                                                                                                    name="undefined"
                                                                                                                                                                    value="undefined">
                                                                                                                                                            </div>
                                                                                                                                                            <div role="checkbox"
                                                                                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                                <span
                                                                                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                                            </div>
                                                                                                                                                        </div>
                                    
                                                                                                                                                    </p-checkbox>
                                                                                                                                                    {{product.workEffortId}}
                                                                                                                                                </div>
                                                                                                                                            </td>
                                                                                                                                            <td
                                                                                                                                                [ngStyle]="{'width':'170px'}">
                                                                                                                                                {{product.internalName}}
                                                                                                                                            </td>
                                                                                                                                            <td
                                                                                                                                                [ngStyle]="{'width':'170px'}">
                                                                                                                                                {{product.estimatedQuantity}}
                                                                                                                                            </td>
                                                                                                                                            <td
                                                                                                                                                [ngStyle]="{'width':'170px'}">
                                                                                                                                                {{product.issuedQuantity}}
                                                                                                                                            </td>
                                                                                                                                            <td
                                                                                                                                                [ngStyle]="{'width':'170px'}">
                                                                                                                                                {{product.returnedQuantity}}
                                                                                                                                            </td>
                                                                                                                                            <td
                                                                                                                                                [ngStyle]="{'width':'170px'}">
                                                                                                                                                <input
                                                                                                                                                    type="text"
                                                                                                                                                    style="width: 66%;
                                                                                                              height: 5%!important;" [(ngModel)]="product.lotId"
                                                                                                                                                    [ngModelOptions]="{standalone: true}" />
                                                                                                                                            </td>
                                                                                                                                            <td
                                                                                                                                                [ngStyle]="{'width':'170px'}">
                                                                                                                                                <input
                                                                                                                                                    type="text"
                                                                                                                                                    style="width: 66%;
                                                                                                              height: 5%!important;" [(ngModel)]="product.quantity"
                                                                                                                                                    [ngModelOptions]="{standalone: true}" />
                                                                                                                                            </td>
                                                                                                                                            <td
                                                                                                                                                [ngStyle]="{'width':'170px'}">
                                                                                                                                                <p-checkbox
                                                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                                                    styleclass="custom-checkbox"
                                                                                                                                                    binary="true"
                                                                                                                                                    (click)="oneSelectReturnUnused(product)"
                                                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                                    <div
                                                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                                        <div
                                                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                                                            <input
                                                                                                                                                                type="checkbox"
                                                                                                                                                                name="undefined"
                                                                                                                                                                value="undefined">
                                                                                                                                                        </div>
                                                                                                                                                        <div role="checkbox"
                                                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                            <span
                                                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                                        </div>
                                                                                                                                                    </div>
                                    
                                                                                                                                                </p-checkbox>
                                                                                                                                            </td>
                                    
                                    
                                    
                                                                                                                                        </tr>
                                                                                                                                    </ng-template>
                                                                                                                                    <ng-template
                                                                                                                                        pTemplate="emptymessage"
                                                                                                                                        let-columns>
                                                                                                                                        <tr>
                                                                                                                                            <td>
                                                                                                                                                <span
                                                                                                                                                    class="text-center">
                                                                                                                                                    <h4
                                                                                                                                                        class="ml-2">
                                                                                                                                                        No
                                                                                                                                                        Record
                                                                                                                                                        Found
                                                                                                                                                    </h4>
                                                                                                                                                </span>
                                                                                                                                            </td>
                                                                                                                                        </tr>
                                                                                                                                    </ng-template>
                                                                                                                                </p-table>
                                                                                                                                <p class="paginate_data">
                                                                                                                                    View per page
                                    
                                                                                                                                </p>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <button
                                                                                                                            *ngIf="returnUnusedMaterialsToWarehouse && returnUnusedMaterialsToWarehouse.length"
                                                                                                                            _ngcontent-cta-c128=""
                                                                                                                            style="font-size: 12px!important;margin-left: 80%;"
                                                                                                                            type="submit"
                                                                                                                            class="btn btn-secondary submit-btn"
                                                                                                                            (click)="productionRunTaskReturnMaterial();">Submit</button>&nbsp;
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                    
                                                                                                    </div> -->
                                                                                                  
                                    
                                    
                                                                                        
                                                                                               
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                    
                                    
                                                                                    <div *ngIf="activeCategoryA==3">
                                                                        
                                                                                        <!-- <div class="w3-card-4  w-100 mt-3">
                                                                                            <div class="accordion classCard" id="myAccordion">
                                                                                                <div class="accordion-item">
                                                                                                    <h2 class="accordion-header"
                                                                                                        id="headingOne">
                                                                                                        <div
                                                                                                            class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion">
                                                                                                            <span class="headingAccords">
                                                                                                                Production
                                                                                                                Run Declare And Produce
                                                                                                            </span>
                        
                                                                                                            <button type="button"
                                                                                                                class="accordion-button  collapsed"
                                                                                                                data-bs-toggle="collapse"
                                                                                                                data-bs-target="#collapse2"
                                                                                                                style="margin-left: 42%!important; padding-top: 11px !important;
                                                                                                            padding-bottom: 11px !important;">
                                                                                                            </button>
                        
                                                                                                        </div>
                        
                                                                                                    </h2>
                                                                                                    <div id="collapse2"
                                                                                                        class="accordion-collapse collapse"
                                                                                                        data-bs-parent="#myAccordion">
                                                                                                        <div class="panel-body">
                                                                                                            <div class="row">
                                                                                                                <form class="w-100"
                                                                                                                    [formGroup]="createInventory">
                                                                                                                    <div
                                                                                                                        class="all-users-infomation font-13 font-medium own-user-select allDetail">
                        
                                                                                                                        <div class="col-lg-12">
                                                                                                                            <div class="row">
                        
                                                                                                                                <div
                                                                                                                                    class="col-lg-3 form-group formRight">
                                                                                                                                    <label
                                                                                                                                        for="exampleInputEmail1">
                                                                                                                                        Stock
                                                                                                                                        in<span
                                                                                                                                            style="color:red">*</span></label>
                                                                                                                                </div>
                                                                                                                                <div
                                                                                                                                    class="col-lg-3">
                                                                                                                                    <input
                                                                                                                                        type="email"
                                                                                                                                        class="form-control"
                                                                                                                                        id="exampleInputEmail1"
                                                                                                                                        aria-describedby="emailHelp"
                                                                                                                                        placeholder="Enter Stock in"
                                                                                                                                        formControlName="Stockin">
                        
                        
                                                                                                                                </div>
                                                                                                                                <div
                                                                                                                                    class="col-lg-3 form-group formRightA">
                                                                                                                                    <label
                                                                                                                                        for="exampleInputEmail1">
                                                                                                                                        Inventory
                                                                                                                                        Item
                                                                                                                                        Type
                                                                                                                                        ID<span
                                                                                                                                            style="color:red">*</span></label>
                                                                                                                                </div>
                                                                                                                                <div
                                                                                                                                    class="col-lg-3">
                                                                                                                                    <p-dropdown
                                                                                                                                        placeholder="Enter Inventory Item Type ID"
                                                                                                                                        filter="true"
                                                                                                                                        formControlName="InventoryItemTypeID"
                                                                                                                                        [options]="Inventory"
                                                                                                                                        optionlabel="label">
                        
                                                                                                                                    </p-dropdown>
                        
                        
                                                                                                                                </div>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <div class="col-lg-12">
                                                                                                                            <div class="row">
                        
                                                                                                                                <div
                                                                                                                                    class="col-lg-3 form-group formRight">
                                                                                                                                    <label
                                                                                                                                        for="exampleInputEmail1">
                                                                                                                                        Lot
                                                                                                                                        ID</label>
                                                                                                                                </div>
                                                                                                                                <div
                                                                                                                                    class="col-lg-3">
                                                                                                                                    <input
                                                                                                                                        type="email"
                                                                                                                                        class="form-control"
                                                                                                                                        id="exampleInputEmail1"
                                                                                                                                        aria-describedby="emailHelp"
                                                                                                                                        placeholder="Enter Lot ID"
                                                                                                                                        formControlName="LotID">
                        
                        
                                                                                                                                </div>
                        
                                                                                                                            </div>
                                                                                                                        </div>
                        
                        
                        
                                                                                                                    </div>
                        
                        
                                                                                                                </form>
                                                                                                                <div class="main-submit-button"
                                                                                                                    style="margin-left:-12%;">
                        
                                                                                                                    <button type="submit"
                                                                                                                        (click)="postproductionRunDeclareAndProduce();"
                                                                                                                        class="btn btn-secondary submit-btn">Submit</button>
                        
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <div
                                                                                                                class="all-users-infomation font-13 font-medium own-user-select allUserDetails">
                                                                                                                <div
                                                                                                                    class="card own-account-table">
                                                                                                                    <p-table
                                                                                                                        [value]="materialsRequiredByTheRunningTask"
                                                                                                                        [paginator]="true"
                                                                                                                        [rows]="rows"
                                                                                                                        scrollWidth="100%"
                                                                                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]"
                                                                                                                        [totalRecords]="total"
                                                                                                                        [scrollable]="true"
                                                                                                                        (sortFunction)="customSort($event)"
                                                                                                                        [customSort]="true">
                                                                                                                        <ng-template
                                                                                                                            pTemplate="header">
                                                                                                                            <tr
                                                                                                                                style="background: #0d3769;">
                                                                                                                                <th pSortableColumn="code"
                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                    <div class="checkbox-align"
                                                                                                                                        style="color: white;">
                        
                                                                                                                                        <p-checkbox
                                                                                                                                            _ngcontent-egj-c329=""
                                                                                                                                            styleclass="custom-checkbox"
                                                                                                                                            binary="true"
                                                                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                            <div
                                                                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                                <div
                                                                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                                                                    <input
                                                                                                                                                        type="checkbox"
                                                                                                                                                        name="undefined"
                                                                                                                                                        value="undefined">
                                                                                                                                                </div>
                                                                                                                                                <div role="checkbox"
                                                                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                    <span
                                                                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                                </div>
                                                                                                                                            </div>
                        
                                                                                                                                        </p-checkbox>
                        
                        
                        
                        
                                                                                                                                        Routing
                                                                                                                                        Task
                                                                                                                                        ID
                                                                                                                                        <p-sortIcon
                                                                                                                                            field="code"></p-sortIcon>
                                                                                                                                    </div>
                                                                                                                                    <input
                                                                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                        value=""
                                                                                                                                        autocomplete="off"
                                                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                        role="textbox"
                                                                                                                                        aria-disabled="false"
                                                                                                                                        aria-readonly="false">
                                                                                                                                </th>
                                                                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                                                                    pSortableColumn="name">
                                                                                                                                    <div
                                                                                                                                        style="color: white;">
                                                                                                                                        Product
                                                                                                                                        Name
                                                                                                                                        <p-sortIcon
                                                                                                                                            field="name"></p-sortIcon>
                                                                                                                                    </div>
                                                                                                                                    <div>
                                                                                                                                        <input
                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                            value=""
                                                                                                                                            autocomplete="off"
                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                            role="textbox"
                                                                                                                                            aria-disabled="false"
                                                                                                                                            aria-readonly="false">
                                                                                                                                    </div>
                                                                                                                                </th>
                        
                                                                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                                                                    pSortableColumn="name">
                                                                                                                                    <div
                                                                                                                                        style="color: white;">
                                                                                                                                        Quantity
                                                                                                                                        <p-sortIcon
                                                                                                                                            field="name"></p-sortIcon>
                                                                                                                                    </div>
                                                                                                                                    <div>
                                                                                                                                        <input
                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                            value=""
                                                                                                                                            autocomplete="off"
                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                            role="textbox"
                                                                                                                                            aria-disabled="false"
                                                                                                                                            aria-readonly="false">
                                                                                                                                    </div>
                                                                                                                                </th>
                                                                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                                                                    pSortableColumn="name">
                                                                                                                                    <div
                                                                                                                                        style="color: white;">
                                                                                                                                        Issued
                                                                                                                                        <p-sortIcon
                                                                                                                                            field="name"></p-sortIcon>
                                                                                                                                    </div>
                                                                                                                                    <div>
                                                                                                                                        <input
                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                            value=""
                                                                                                                                            autocomplete="off"
                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                            role="textbox"
                                                                                                                                            aria-disabled="false"
                                                                                                                                            aria-readonly="false">
                                                                                                                                    </div>
                                                                                                                                </th>
                                                                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                                                                    pSortableColumn="name">
                                                                                                                                    <div
                                                                                                                                        style="color: white;">
                                                                                                                                        Location
                                                                                                                                        Seq
                                                                                                                                        ID
                                                                                                                                        <p-sortIcon
                                                                                                                                            field="name"></p-sortIcon>
                                                                                                                                    </div>
                                                                                                                                    <div>
                                                                                                                                        <input
                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                            value=""
                                                                                                                                            autocomplete="off"
                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                            role="textbox"
                                                                                                                                            aria-disabled="false"
                                                                                                                                            aria-readonly="false">
                                                                                                                                    </div>
                                                                                                                                </th>
                        
                                                                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                                                                    pSortableColumn="name">
                                                                                                                                    <div
                                                                                                                                        style="color: white;">
                                                                                                                                        Secondary
                                                                                                                                        Location
                                                                                                                                        Seq
                                                                                                                                        ID
                                                                                                                                        <p-sortIcon
                                                                                                                                            field="name"></p-sortIcon>
                                                                                                                                    </div>
                                                                                                                                    <div>
                                                                                                                                        <input
                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                            value=""
                                                                                                                                            autocomplete="off"
                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                            role="textbox"
                                                                                                                                            aria-disabled="false"
                                                                                                                                            aria-readonly="false">
                                                                                                                                    </div>
                                                                                                                                </th>
                        
                                                                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                                                                    pSortableColumn="name">
                                                                                                                                    <div
                                                                                                                                        style="color: white;">
                                                                                                                                        Fail If
                                                                                                                                        Items
                                                                                                                                        Are
                                                                                                                                        Not
                                                                                                                                        Available
                                                                                                                                        <p-sortIcon
                                                                                                                                            field="name"></p-sortIcon>
                                                                                                                                    </div>
                                                                                                                                    <div>
                                                                                                                                        <input
                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                            value=""
                                                                                                                                            autocomplete="off"
                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                            role="textbox"
                                                                                                                                            aria-disabled="false"
                                                                                                                                            aria-readonly="false">
                                                                                                                                    </div>
                                                                                                                                </th>
                        
                        
                        
                        
                        
                        
                                                                                                                            </tr>
                                                                                                                        </ng-template>
                                                                                                                        <ng-template
                                                                                                                            pTemplate="body"
                                                                                                                            let-product>
                                                                                                                            <tr>
                                                                                                                                <td
                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                    <div
                                                                                                                                        class="checkbox-align">
                                                                                                                                        <p-checkbox
                                                                                                                                            _ngcontent-egj-c329=""
                                                                                                                                            styleclass="custom-checkbox"
                                                                                                                                            binary="true"
                                                                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                            <div
                                                                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                                <div
                                                                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                                                                    <input
                                                                                                                                                        type="checkbox"
                                                                                                                                                        name="undefined"
                                                                                                                                                        value="undefined">
                                                                                                                                                </div>
                                                                                                                                                <div role="checkbox"
                                                                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                    <span
                                                                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                                </div>
                                                                                                                                            </div>
                        
                                                                                                                                        </p-checkbox>
                                                                                                                                        {{product.workEffortId}}
                                                                                                                                    </div>
                                                                                                                                </td>
                                                                                                                                <td
                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                    {{product.internalName}}
                                                                                                                                </td>
                                                                                                                                <td
                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                    {{product.estimatedQuantity}}
                                                                                                                                </td>
                                                                                                                                <td
                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                    {{product.issuedQuantity}}
                                                                                                                                </td>
                                                                                                                                <td
                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                </td>
                                                                                                                                <td
                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                </td>
                                                                                                                                <td
                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                </td>
                        
                        
                        
                        
                                                                                                                            </tr>
                                                                                                                        </ng-template>
                                                                                                                        <ng-template
                                                                                                                            pTemplate="emptymessage"
                                                                                                                            let-columns>
                                                                                                                            <tr>
                                                                                                                                <td>
                                                                                                                                    <span
                                                                                                                                        class="text-center">
                                                                                                                                        <h4
                                                                                                                                            class="ml-2">
                                                                                                                                            No
                                                                                                                                            Record
                                                                                                                                            Found
                                                                                                                                        </h4>
                                                                                                                                    </span>
                                                                                                                                </td>
                                                                                                                            </tr>
                                                                                                                        </ng-template>
                                                                                                                    </p-table>
                                                                                                                    <p class="paginate_data">
                                                                                                                        View per page
                        
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                        
                                                                                        </div> -->
                        
                                                                                      <div class="all-users-infomation font-13 font-medium own-user-select" style="display: block!important;">
                                                                                        <div class="w3-card-4 classCard">
                                                                                            <header class="w3-container w3-blue">
                                                                                                <div class="header-tabs">
                                                                                                    <h4 class=" common-styling Hleft">
                                                                                                        Production Run Declare And Produce
                                                                                                    </h4>
                        
                                                                                                </div>
                                                                                            </header>
                        
                                                                                            <div class="w3-container">
                                                                                                <div class="panel-body">
                                                                                                    <div class="row">
                                                                                                        <form class="w-100" [formGroup]="createInventory">
                                                                                                            <div class="all-users-infomation font-13 font-medium own-user-select allDetail">
                        
                                                                                                                <div class="col-lg-12">
                                                                                                                    <div class="row">
                        
                                                                                                                        <div class="col-lg-3 form-group formRight">
                                                                                                                            <label for="exampleInputEmail1">
                                                                                      Stock in<span
                                                                                      style="color:red">*</span></label>
                                                                                                                        </div>
                                                                                                                        <div class="col-lg-3">
                                                                                                                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Stock in" formControlName="Stockin">
                        
                        
                                                                                                                        </div>
                                                                                                                        <div class="col-lg-3 form-group formRightA">
                                                                                                                            <label for="exampleInputEmail1">
                                                                                      Inventory Item Type ID<span
                                                                                      style="color:red">*</span></label>
                                                                                                                        </div>
                                                                                                                        <div class="col-lg-3">
                                                                                                                            <p-dropdown placeholder="Enter Inventory Item Type ID" filter="true" formControlName="InventoryItemTypeID" [options]="Inventory" optionlabel="label">
                        
                                                                                                                            </p-dropdown>
                        
                        
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                                <div class="col-lg-12">
                                                                                                                    <div class="row">
                        
                                                                                                                        <div class="col-lg-3 form-group formRight">
                                                                                                                            <label for="exampleInputEmail1">
                                                                                    Lot ID</label>
                                                                                                                        </div>
                                                                                                                        <div class="col-lg-3">
                                                                                                                            <input type="email" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Lot ID" formControlName="LotID">
                        
                        
                                                                                                                        </div>
                        
                                                                                                                    </div>
                                                                                                                </div>
                        
                        
                        
                                                                                                            </div>
                        
                        
                                                                                                        </form>
                                                                                                        <div class="main-submit-button" style="margin-left: 57%;">
                        
                                                                                                            <button type="submit" (click)="postproductionRunDeclareAndProduce();" class="btn btn-secondary submit-btn">Submit</button>
                        
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <div class="all-users-infomation font-13 font-medium own-user-select allUserDetails">
                                                                                                        <div class="card own-account-table">
                                                                                                            <p-table [value]="materialsRequiredByTheRunningTask" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                                <ng-template pTemplate="header">
                                                                                                                    <tr style="background: #0d3769;">
                                                                                                                        <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                                            <div class="checkbox-align" style="color: white;">
                        
                                                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                                                            <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    
                                                                                                                                </p-checkbox>
                        
                        
                        
                        
                                                                                                                                Routing Task ID
                                                                                                                                <p-sortIcon field="code"></p-sortIcon>
                                                                                                                            </div>
                                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                        </th>
                                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                            <div style="color: white;"> Product Name
                                                                                                                                <p-sortIcon field="name"></p-sortIcon>
                                                                                                                            </div>
                                                                                                                            <div>
                                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                            </div>
                                                                                                                        </th>
                        
                                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                            <div style="color: white;">Quantity
                                                                                                                                <p-sortIcon field="name"></p-sortIcon>
                                                                                                                            </div>
                                                                                                                            <div>
                                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                            </div>
                                                                                                                        </th>
                                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                            <div style="color: white;">Issued
                                                                                                                                <p-sortIcon field="name"></p-sortIcon>
                                                                                                                            </div>
                                                                                                                            <div>
                                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                            </div>
                                                                                                                        </th>
                                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                            <div style="color: white;">Location Seq ID
                                                                                                                                <p-sortIcon field="name"></p-sortIcon>
                                                                                                                            </div>
                                                                                                                            <div>
                                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                            </div>
                                                                                                                        </th>
                        
                                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                            <div style="color: white;"> Secondary Location Seq ID
                                                                                                                                <p-sortIcon field="name"></p-sortIcon>
                                                                                                                            </div>
                                                                                                                            <div>
                                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                            </div>
                                                                                                                        </th>
                        
                                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                            <div style="color: white;">Fail If Items Are Not Available
                                                                                                                                <p-sortIcon field="name"></p-sortIcon>
                                                                                                                            </div>
                                                                                                                            <div>
                                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                            </div>
                                                                                                                        </th>
                        
                        
                        
                        
                        
                        
                                                                                                                    </tr>
                                                                                                                </ng-template>
                                                                                                                <ng-template pTemplate="body" let-product>
                                                                                                                    <tr>
                                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                                            <div class="checkbox-align">
                                                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                                                            <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    
                                                                                                                                </p-checkbox>
                                                                                                                                {{product.workEffortId}}
                                                                                                                            </div>
                                                                                                                        </td>
                                                                                                                        <td [ngStyle]="{'width':'170px'}"> {{product.internalName}} </td>
                                                                                                                        <td [ngStyle]="{'width':'170px'}"> {{product.estimatedQuantity}} </td>
                                                                                                                        <td [ngStyle]="{'width':'170px'}"> {{product.issuedQuantity}} </td>
                                                                                                                        <td [ngStyle]="{'width':'170px'}"> </td>
                                                                                                                        <td [ngStyle]="{'width':'170px'}"> </td>
                                                                                                                        <td [ngStyle]="{'width':'170px'}"> </td>
                        
                        
                        
                        
                                                                                                                    </tr>
                                                                                                                </ng-template>
                                                                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                                                                    <tr>
                                                                                                                        <td>
                                                                                                                            <span class="text-center">
                                                                                            <h4 class="ml-2">No Record Found
                                                                                            </h4>
                                                                                        </span>
                                                                                                                        </td>
                                                                                                                    </tr>
                                                                                                                </ng-template>
                                                                                                            </p-table>
                                                                                                            <p class="paginate_data">
                                                                                                                View per page
                        
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                        
                        
                                                                                        </div>
                                                                                    </div> 
                        
                                                                                       
                                                                                    </div>
                                                                                    <div *ngIf="activeCategoryA==4">
                                                                                        <!-- <div class="w3-card-4  w-100 mt-3">
                                                                                            <div class="accordion classCard" id="myAccordion">
                                                                                                <div class="accordion-item">
                                                                                                    <h2 class="accordion-header"
                                                                                                        id="headingOne">
                                                                                                        <div
                                                                                                            class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion">
                                                                                                            <span class="headingAccords">
                                                                                                                Inventory
                                                                                                                Items
                                                                                                            </span>
                        
                                                                                                            <button type="button"
                                                                                                                class="accordion-button  collapsed"
                                                                                                                data-bs-toggle="collapse"
                                                                                                                data-bs-target="#collapse3"
                                                                                                                style="margin-left: 65%!important; padding-top: 11px !important; padding-bottom: 11px !important;">
                                                                                                            </button>
                        
                                                                                                        </div>
                        
                                                                                                    </h2>
                                                                                                    <div id="collapse3"
                                                                                                        class="accordion-collapse collapse"
                                                                                                        data-bs-parent="#myAccordion">
                                                                                                        <div class="panel-body">
                                                                                                            <div
                                                                                                                class="all-users-infomation font-13 font-medium own-user-select allUserDetails">
                                                                                                                <div
                                                                                                                    class="card own-account-table">
                                                                                                                    <p-table
                                                                                                                        [value]="productionRunDeclareAndProduce"
                                                                                                                        [paginator]="true"
                                                                                                                        [rows]="rows"
                                                                                                                        scrollWidth="100%"
                                                                                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]"
                                                                                                                        [totalRecords]="total"
                                                                                                                        [scrollable]="true"
                                                                                                                        (sortFunction)="customSort($event)"
                                                                                                                        [customSort]="true">
                                                                                                                        <ng-template
                                                                                                                            pTemplate="header">
                                                                                                                            <tr
                                                                                                                                style="background: #0d3769;">
                                                                                                                                <th pSortableColumn="code"
                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                    <div class="checkbox-align"
                                                                                                                                        style="color: white;">
                        
                                                                                                                                        <p-checkbox
                                                                                                                                            _ngcontent-egj-c329=""
                                                                                                                                            styleclass="custom-checkbox"
                                                                                                                                            binary="true"
                                                                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                            <div
                                                                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                                <div
                                                                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                                                                    <input
                                                                                                                                                        type="checkbox"
                                                                                                                                                        name="undefined"
                                                                                                                                                        value="undefined">
                                                                                                                                                </div>
                                                                                                                                                <div role="checkbox"
                                                                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                    <span
                                                                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                                </div>
                                                                                                                                            </div>
                        
                                                                                                                                        </p-checkbox>
                        
                        
                        
                                                                                                                                        Inventory
                                                                                                                                        Item ID
                                                                                                                                        <p-sortIcon
                                                                                                                                            field="code"></p-sortIcon>
                                                                                                                                    </div>
                                                                                                                                    <input
                                                                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                        value=""
                                                                                                                                        autocomplete="off"
                                                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                        role="textbox"
                                                                                                                                        aria-disabled="false"
                                                                                                                                        aria-readonly="false">
                                                                                                                                </th>
                                                                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                                                                    pSortableColumn="name">
                                                                                                                                    <div
                                                                                                                                        style="color: white;">
                                                                                                                                        Lot ID
                                                                                                                                        <p-sortIcon
                                                                                                                                            field="name"></p-sortIcon>
                                                                                                                                    </div>
                                                                                                                                    <div>
                                                                                                                                        <input
                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                            value=""
                                                                                                                                            autocomplete="off"
                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                            role="textbox"
                                                                                                                                            aria-disabled="false"
                                                                                                                                            aria-readonly="false">
                                                                                                                                    </div>
                                                                                                                                </th>
                        
                                                                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                                                                    pSortableColumn="name">
                                                                                                                                    <div
                                                                                                                                        style="color: white;">
                                                                                                                                        Status
                                                                                                                                        ID
                                                                                                                                        <p-sortIcon
                                                                                                                                            field="name"></p-sortIcon>
                                                                                                                                    </div>
                                                                                                                                    <div>
                                                                                                                                        <input
                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                            value=""
                                                                                                                                            autocomplete="off"
                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                            role="textbox"
                                                                                                                                            aria-disabled="false"
                                                                                                                                            aria-readonly="false">
                                                                                                                                    </div>
                                                                                                                                </th>
                                                                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                                                                    pSortableColumn="name">
                                                                                                                                    <div
                                                                                                                                        style="color: white;">
                                                                                                                                        Unit
                                                                                                                                        Cost
                                                                                                                                        <p-sortIcon
                                                                                                                                            field="name"></p-sortIcon>
                                                                                                                                    </div>
                                                                                                                                    <div>
                                                                                                                                        <input
                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                            value=""
                                                                                                                                            autocomplete="off"
                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                            role="textbox"
                                                                                                                                            aria-disabled="false"
                                                                                                                                            aria-readonly="false">
                                                                                                                                    </div>
                                                                                                                                </th>
                                                                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                                                                    pSortableColumn="name">
                                                                                                                                    <div
                                                                                                                                        style="color: white;">
                                                                                                                                        Quantity
                                                                                                                                        <p-sortIcon
                                                                                                                                            field="name"></p-sortIcon>
                                                                                                                                    </div>
                                                                                                                                    <div>
                                                                                                                                        <input
                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                            value=""
                                                                                                                                            autocomplete="off"
                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                            role="textbox"
                                                                                                                                            aria-disabled="false"
                                                                                                                                            aria-readonly="false">
                                                                                                                                    </div>
                                                                                                                                </th>
                        
                                                                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                                                                    pSortableColumn="name">
                                                                                                                                    <div
                                                                                                                                        style="color: white;">
                                                                                                                                        Date
                                                                                                                                        Time
                                                                                                                                        Received
                                                                                                                                        <p-sortIcon
                                                                                                                                            field="name"></p-sortIcon>
                                                                                                                                    </div>
                                                                                                                                    <div>
                                                                                                                                        <input
                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                            value=""
                                                                                                                                            autocomplete="off"
                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                            role="textbox"
                                                                                                                                            aria-disabled="false"
                                                                                                                                            aria-readonly="false">
                                                                                                                                    </div>
                                                                                                                                </th>
                        
                        
                        
                        
                        
                        
                                                                                                                            </tr>
                                                                                                                        </ng-template>
                                                                                                                        <ng-template
                                                                                                                            pTemplate="body"
                                                                                                                            let-product>
                                                                                                                            <tr>
                                                                                                                                <td
                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                    <div
                                                                                                                                        class="checkbox-align">
                                                                                                                                        <p-checkbox
                                                                                                                                            _ngcontent-egj-c329=""
                                                                                                                                            styleclass="custom-checkbox"
                                                                                                                                            binary="true"
                                                                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                            <div
                                                                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                                <div
                                                                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                                                                    <input
                                                                                                                                                        type="checkbox"
                                                                                                                                                        name="undefined"
                                                                                                                                                        value="undefined">
                                                                                                                                                </div>
                                                                                                                                                <div role="checkbox"
                                                                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                    <span
                                                                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                                </div>
                                                                                                                                            </div>
                        
                                                                                                                                        </p-checkbox>
                                                                                                                                        {{product.inventoryItemId}}
                                                                                                                                    </div>
                                                                                                                                </td>
                                                                                                                                <td
                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                    {{product.inventoryItemDetailSeqId}}
                                                                                                                                </td>
                                                                                                                                <td
                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                    {{product.statusDesc}}
                                                                                                                                </td>
                                                                                                                                <td
                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                    {{product.unitCost}}
                                                                                                                                </td>
                                                                                                                                <td
                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                    {{product.quantity}}
                                                                                                                                </td>
                                                                                                                                <td
                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                    {{product.datetimeReceived|date:'yyyy-MM-dd
                                                                                                                                    hh:mm:ss'}}
                                                                                                                                </td>
                        
                        
                        
                                                                                                                            </tr>
                                                                                                                        </ng-template>
                                                                                                                        <ng-template
                                                                                                                            pTemplate="emptymessage"
                                                                                                                            let-columns>
                                                                                                                            <tr>
                                                                                                                                <td>
                                                                                                                                    <span
                                                                                                                                        class="text-center">
                                                                                                                                        <h4
                                                                                                                                            class="ml-2">
                                                                                                                                            No
                                                                                                                                            Record
                                                                                                                                            Found
                                                                                                                                        </h4>
                                                                                                                                    </span>
                                                                                                                                </td>
                                                                                                                            </tr>
                                                                                                                        </ng-template>
                                                                                                                    </p-table>
                                                                                                                    <p class="paginate_data">
                                                                                                                        View per page
                        
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                        
                                                                                        </div>
                         -->
                        
                        
                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select" style="display: block!important;">
                                                                                        <div class="w3-card-4 classCard">
                                                                                            <header class="w3-container w3-blue">
                                                                                                <div class="header-tabs">
                                                                                                    <h4 class=" common-styling Hleft">
                                                                                                        Inventory Items
                                                                                                    </h4>
                        
                                                                                                </div>
                                                                                            </header>
                        
                                                                                            <div class="w3-container">
                                                                                                <div class="panel-body">
                                                                                                    <div class="all-users-infomation font-13 font-medium own-user-select allUserDetails">
                                                                                                        <div class="card own-account-table">
                                                                                                            <p-table [value]="productionRunDeclareAndProduce" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                                <ng-template pTemplate="header">
                                                                                                                    <tr style="background: #0d3769;">
                                                                                                                        <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                                            <div class="checkbox-align" style="color: white;">
                        
                                                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                                                            <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    
                                                                                                                                </p-checkbox>
                        
                        
                        
                                                                                                                                Inventory Item ID
                                                                                                                                <p-sortIcon field="code"></p-sortIcon>
                                                                                                                            </div>
                                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                        </th>
                                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                            <div style="color: white;"> Lot ID
                                                                                                                                <p-sortIcon field="name"></p-sortIcon>
                                                                                                                            </div>
                                                                                                                            <div>
                                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                            </div>
                                                                                                                        </th>
                        
                                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                            <div style="color: white;"> Status ID
                                                                                                                                <p-sortIcon field="name"></p-sortIcon>
                                                                                                                            </div>
                                                                                                                            <div>
                                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                            </div>
                                                                                                                        </th>
                                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                            <div style="color: white;"> Unit Cost
                                                                                                                                <p-sortIcon field="name"></p-sortIcon>
                                                                                                                            </div>
                                                                                                                            <div>
                                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                            </div>
                                                                                                                        </th>
                                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                            <div style="color: white;">Quantity
                                                                                                                                <p-sortIcon field="name"></p-sortIcon>
                                                                                                                            </div>
                                                                                                                            <div>
                                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                            </div>
                                                                                                                        </th>
                        
                                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                            <div style="color: white;">Date Time Received
                                                                                                                                <p-sortIcon field="name"></p-sortIcon>
                                                                                                                            </div>
                                                                                                                            <div>
                                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                            </div>
                                                                                                                        </th>
                        
                        
                        
                        
                        
                        
                                                                                                                    </tr>
                                                                                                                </ng-template>
                                                                                                                <ng-template pTemplate="body" let-product>
                                                                                                                    <tr>
                                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                                            <div class="checkbox-align">
                                                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                                                            <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    
                                                                                                                                </p-checkbox>
                                                                                                                                {{product.inventoryItemId}}
                                                                                                                            </div>
                                                                                                                        </td>
                                                                                                                        <td [ngStyle]="{'width':'170px'}"> {{product.inventoryItemDetailSeqId}} </td>
                                                                                                                        <td [ngStyle]="{'width':'170px'}"> {{product.statusDesc}} </td>
                                                                                                                        <td [ngStyle]="{'width':'170px'}"> {{product.unitCost}}</td>
                                                                                                                        <td [ngStyle]="{'width':'170px'}"> {{product.quantity}} </td>
                                                                                                                        <td [ngStyle]="{'width':'170px'}"> {{product.datetimeReceived|date:'yyyy-MM-dd hh:mm:ss'}} </td>
                        
                        
                        
                                                                                                                    </tr>
                                                                                                                </ng-template>
                                                                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                                                                    <tr>
                                                                                                                        <td>
                                                                                                                            <span class="text-center">
                                                                                          <h4 class="ml-2">No Record Found
                                                                                          </h4>
                                                                                      </span>
                                                                                                                        </td>
                                                                                                                    </tr>
                                                                                                                </ng-template>
                                                                                                            </p-table>
                                                                                                            <p class="paginate_data">
                                                                                                                View per page
                        
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                        
                        
                                                                                        </div>
                                                                                    </div>
                        
                                                                                    </div>
                                                                                    <div *ngIf="activeCategoryA==5">
                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select" style="display: block!important;">
                                                                                            <div class="w3-card-4 classCard">
                                                                                                <header class="w3-container w3-blue">
                                                                                                    <div class="header-tabs">
                                                                                                        <h4 class=" common-styling Hleft">
                                                                                                            Tasks
                                                                                                        </h4>
                            
                                                                                                    </div>
                                                                                                </header>
                            
                                                                                                <div class="w3-container">
                                                                                                    <div class="panel-body">
                                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select allUserDetails">
                                                                                                            <div class="card own-account-table">
                                                                                                                <p-table [value]="productionRunRoutingTasksData" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                                    <ng-template pTemplate="header">
                                                                                                                        <tr style="background: #0d3769;">
                                                                                                                            <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                                                <div class="checkbox-align" style="color: white;">
                            
                                                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                                                <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        
                                                                                                                                    </p-checkbox>
                            
                            
                            
                                                                                                                                    Sequence Num
                                                                                                                                    <p-sortIcon field="code"></p-sortIcon>
                                                                                                                                </div>
                                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                            </th>
                                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                <div style="color: white;"> Routing Task Name
                                                                                                                                    <p-sortIcon field="name"></p-sortIcon>
                                                                                                                                </div>
                                                                                                                                <div>
                                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                </div>
                                                                                                                            </th>
                                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                <div style="color: white;">Status
                                                                                                                                    <p-sortIcon field="name"></p-sortIcon>
                                                                                                                                </div>
                                                                                                                                <div>
                                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                </div>
                                                                                                                            </th>
                                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                <div style="color: white;"> Fixed Asset
                                                                                                                                    <p-sortIcon field="name"></p-sortIcon>
                                                                                                                                </div>
                                                                                                                                <div>
                                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                </div>
                                                                                                                            </th>
                                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                <div style="color: white;"> Reserv Persons
                                                                                                                                    <p-sortIcon field="name"></p-sortIcon>
                                                                                                                                </div>
                                                                                                                                <div>
                                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                </div>
                                                                                                                            </th>
                                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                <div style="color: white;"> Start Date
                                                                                                                                    <p-sortIcon field="name"></p-sortIcon>
                                                                                                                                </div>
                                                                                                                                <div>
                                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                </div>
                                                                                                                            </th>
                            
                                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                <div style="color: white;"> Calculated Completion Date
                                                                                                                                    <p-sortIcon field="name"></p-sortIcon>
                                                                                                                                </div>
                                                                                                                                <div>
                                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                </div>
                                                                                                                            </th>
                            
                                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                <div style="color: white;"> Estimated Setup Time
                                                                                                                                    <p-sortIcon field="name"></p-sortIcon>
                                                                                                                                </div>
                                                                                                                                <div>
                                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                </div>
                                                                                                                            </th>
                            
                                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                <div style="color: white;"> Estimated Total Run Time
                                                                                                                                    <p-sortIcon field="name"></p-sortIcon>
                                                                                                                                </div>
                                                                                                                                <div>
                                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                </div>
                                                                                                                            </th>
                                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                <div style="color: white;">Actual Setup Time
                                                                                                                                    <p-sortIcon field="name"></p-sortIcon>
                                                                                                                                </div>
                                                                                                                                <div>
                                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                </div>
                                                                                                                            </th>
                                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                <div style="color: white;"> Actual Time
                                                                                                                                    <p-sortIcon field="name"></p-sortIcon>
                                                                                                                                </div>
                                                                                                                                <div>
                                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                </div>
                                                                                                                            </th>
                                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                <div style="color: white;">Produced
                                                                                                                                    <p-sortIcon field="name"></p-sortIcon>
                                                                                                                                </div>
                                                                                                                                <div>
                                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                </div>
                                                                                                                            </th>
                                                                                                                            <th [ngStyle]="{'width':'300px'}" pSortableColumn="name">
                                                                                                                                <div style="color: white;">Action
                                                                                                                                    <p-sortIcon field="name"></p-sortIcon>
                                                                                                                                </div>
                                                                                                                                <div>
                                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                </div>
                                                                                                                            </th>
                            
                            
                            
                            
                            
                                                                                                                        </tr>
                                                                                                                    </ng-template>
                                                                                                                    <ng-template pTemplate="body" let-product>
                                                                                                                        <tr>
                                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                                <div class="checkbox-align">
                                                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                                                <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        
                                                                                                                                    </p-checkbox>
                                                                                                                                    {{product.sequenceNum}}
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                            <td [ngStyle]="{'width':'170px'}"> {{product.workEffortId}} </td>
                                                                                                                            <td [ngStyle]="{'width':'170px'}"> {{product.currentStatusId}} </td>
                                                                                                                            <td [ngStyle]="{'width':'170px'}"> {{product.fixedAssetId}} </td>
                                                                                                                            <td [ngStyle]="{'width':'170px'}"> {{product.reservPersons}} </td>
                                                                                                                            <td [ngStyle]="{'width':'170px'}"> {{product.estimatedStartDate|date:'yyyy-MM-dd hh:mm:ss'}} </td>
                                                                                                                            <td [ngStyle]="{'width':'170px'}"> {{product.estimatedCompletionDate|date:'yyyy-MM-dd hh:mm:ss'}} </td>
                                                                                                                            <td [ngStyle]="{'width':'170px'}"> {{product.estimatedSetupMillis}}</td>
                                                                                                                            <td [ngStyle]="{'width':'170px'}"> {{product.estimatedTotalMilliSeconds}}</td>
                            
                                                                                                                            <td [ngStyle]="{'width':'170px'}"> {{product.actualSetupMillis}}</td>
                                                                                                                            <td [ngStyle]="{'width':'170px'}"> {{product.actualMilliSeconds}}</td>
                                                                                                                            <td [ngStyle]="{'width':'170px'}"> {{product.quantityProduced}}</td>
                                                                                                                            <td [ngStyle]="{'width':'300px'}" style="text-align: center;"><span *ngIf="product.showDeclareLink=='Y'"><a 
                                                                                        style="cursor: pointer;color: blue!important;" (click)="createProduction(product.workEffortId)" >Declare</a>&nbsp;
                                                                                          <a (click)="createQuantity(product.workEffortId)"  style="cursor: pointer;color: blue!important;">Add Qty</a></span>&nbsp;
                                                                                                                                <span *ngIf="product.showStartLink=='Y'">
                                                                                            <a (click)="startProduction(product.workEffortId)"  style="cursor: pointer;color: blue!important;">Start</a></span>                                                                                                    &nbsp;
                                                                                                                                <span *ngIf="product.showCompleteLink=='Y'">
                                                                                              <a (click)="startProduction(product.workEffortId)"  style="cursor: pointer;color: blue!important;">Complete</a></span>&nbsp;
                                                                                                                                <span *ngIf="product.showIssueLinkAtp=='Y'">
                                                                                                <a (click)="issueProductionRunRoutingTaskATP(product.workEffortId)"  style="cursor: pointer;color: blue!important;">Issue Components;ATP</a></span>&nbsp;
                                                                                                                                <span *ngIf="product.showIssueLinkQoh=='Y'">
                                                                                                  <a (click)="issueProductionRunRoutingTask(product.workEffortId)"  style="cursor: pointer;color: blue!important;">Issue Components</a></span>&nbsp;
                            
                            
                                                                                                                            </td>
                            
                            
                            
                                                                                                                        </tr>
                                                                                                                    </ng-template>
                                                                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                                                                        <tr>
                                                                                                                            <td>
                                                                                                                                <span class="text-center">
                                                                                                  <h4 class="ml-2">No Record Found
                                                                                                  </h4>
                                                                                              </span>
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                    </ng-template>
                                                                                                                </p-table>
                                                                                                                <p class="paginate_data">
                                                                                                                    View per page
                            
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                            
                            
                                                                                            </div>
                                                                                        </div>
                            
                            
                            
                                                                                            <!-- <div class="w3-card-4  w-100 mt-3">
                                                                                                <div class="accordion classCard" id="myAccordion">
                                                                                                    <div class="accordion-item">
                                                                                                        <h2 class="accordion-header"
                                                                                                            id="headingOne">
                                                                                                            <div
                                                                                                                class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion">
                                                                                                                <span class="headingAccords"> Tasks
                                                                                                                </span>
                            
                                                                                                                <button type="button"
                                                                                                                    class="accordion-button  collapsed"
                                                                                                                    data-bs-toggle="collapse"
                                                                                                                    data-bs-target="#collapse5"
                                                                                                                    style="margin-left: 75%!important; padding-top: 11px !important;
                                                                                                                padding-bottom: 11px !important;">
                                                                                                                </button>
                            
                                                                                                            </div>
                            
                                                                                                        </h2>
                                                                                                        <div id="collapse5"
                                                                                                            class="accordion-collapse collapse"
                                                                                                            data-bs-parent="#myAccordion">
                                                                                                            <div class="panel-body">
                                                                                                                <div
                                                                                                                    class="all-users-infomation font-13 font-medium own-user-select allUserDetails">
                                                                                                                    <div
                                                                                                                        class="card own-account-table">
                                                                                                                        <p-table
                                                                                                                            [value]="productionRunRoutingTasksData"
                                                                                                                            [paginator]="true"
                                                                                                                            [rows]="rows"
                                                                                                                            scrollWidth="100%"
                                                                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]"
                                                                                                                            [totalRecords]="total"
                                                                                                                            [scrollable]="true"
                                                                                                                            (sortFunction)="customSort($event)"
                                                                                                                            [customSort]="true">
                                                                                                                            <ng-template
                                                                                                                                pTemplate="header">
                                                                                                                                <tr
                                                                                                                                    style="background: #0d3769;">
                                                                                                                                    <th pSortableColumn="code"
                                                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                                                        <div class="checkbox-align"
                                                                                                                                            style="color: white;">
                            
                                                                                                                                            <p-checkbox
                                                                                                                                                _ngcontent-egj-c329=""
                                                                                                                                                styleclass="custom-checkbox"
                                                                                                                                                binary="true"
                                                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                                <div
                                                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                                    <div
                                                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                                                        <input
                                                                                                                                                            type="checkbox"
                                                                                                                                                            name="undefined"
                                                                                                                                                            value="undefined">
                                                                                                                                                    </div>
                                                                                                                                                    <div role="checkbox"
                                                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                        <span
                                                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                                    </div>
                                                                                                                                                </div>
                            
                                                                                                                                            </p-checkbox>
                            
                            
                            
                                                                                                                                            Sequence
                                                                                                                                            Num
                                                                                                                                            <p-sortIcon
                                                                                                                                                field="code"></p-sortIcon>
                                                                                                                                        </div>
                                                                                                                                        <input
                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                            value=""
                                                                                                                                            autocomplete="off"
                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                            role="textbox"
                                                                                                                                            aria-disabled="false"
                                                                                                                                            aria-readonly="false">
                                                                                                                                    </th>
                                                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                                                        pSortableColumn="name">
                                                                                                                                        <div
                                                                                                                                            style="color: white;">
                                                                                                                                            Routing
                                                                                                                                            Task
                                                                                                                                            Name
                                                                                                                                            <p-sortIcon
                                                                                                                                                field="name"></p-sortIcon>
                                                                                                                                        </div>
                                                                                                                                        <div>
                                                                                                                                            <input
                                                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                                value=""
                                                                                                                                                autocomplete="off"
                                                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                role="textbox"
                                                                                                                                                aria-disabled="false"
                                                                                                                                                aria-readonly="false">
                                                                                                                                        </div>
                                                                                                                                    </th>
                                                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                                                        pSortableColumn="name">
                                                                                                                                        <div
                                                                                                                                            style="color: white;">
                                                                                                                                            Status
                                                                                                                                            <p-sortIcon
                                                                                                                                                field="name"></p-sortIcon>
                                                                                                                                        </div>
                                                                                                                                        <div>
                                                                                                                                            <input
                                                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                                value=""
                                                                                                                                                autocomplete="off"
                                                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                role="textbox"
                                                                                                                                                aria-disabled="false"
                                                                                                                                                aria-readonly="false">
                                                                                                                                        </div>
                                                                                                                                    </th>
                                                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                                                        pSortableColumn="name">
                                                                                                                                        <div
                                                                                                                                            style="color: white;">
                                                                                                                                            Fixed
                                                                                                                                            Asset
                                                                                                                                            <p-sortIcon
                                                                                                                                                field="name"></p-sortIcon>
                                                                                                                                        </div>
                                                                                                                                        <div>
                                                                                                                                            <input
                                                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                                value=""
                                                                                                                                                autocomplete="off"
                                                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                role="textbox"
                                                                                                                                                aria-disabled="false"
                                                                                                                                                aria-readonly="false">
                                                                                                                                        </div>
                                                                                                                                    </th>
                                                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                                                        pSortableColumn="name">
                                                                                                                                        <div
                                                                                                                                            style="color: white;">
                                                                                                                                            Reserv
                                                                                                                                            Persons
                                                                                                                                            <p-sortIcon
                                                                                                                                                field="name"></p-sortIcon>
                                                                                                                                        </div>
                                                                                                                                        <div>
                                                                                                                                            <input
                                                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                                value=""
                                                                                                                                                autocomplete="off"
                                                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                role="textbox"
                                                                                                                                                aria-disabled="false"
                                                                                                                                                aria-readonly="false">
                                                                                                                                        </div>
                                                                                                                                    </th>
                                                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                                                        pSortableColumn="name">
                                                                                                                                        <div
                                                                                                                                            style="color: white;">
                                                                                                                                            Start
                                                                                                                                            Date
                                                                                                                                            <p-sortIcon
                                                                                                                                                field="name"></p-sortIcon>
                                                                                                                                        </div>
                                                                                                                                        <div>
                                                                                                                                            <input
                                                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                                value=""
                                                                                                                                                autocomplete="off"
                                                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                role="textbox"
                                                                                                                                                aria-disabled="false"
                                                                                                                                                aria-readonly="false">
                                                                                                                                        </div>
                                                                                                                                    </th>
                            
                                                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                                                        pSortableColumn="name">
                                                                                                                                        <div
                                                                                                                                            style="color: white;">
                                                                                                                                            Calculated
                                                                                                                                            Completion
                                                                                                                                            Date
                                                                                                                                            <p-sortIcon
                                                                                                                                                field="name"></p-sortIcon>
                                                                                                                                        </div>
                                                                                                                                        <div>
                                                                                                                                            <input
                                                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                                value=""
                                                                                                                                                autocomplete="off"
                                                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                role="textbox"
                                                                                                                                                aria-disabled="false"
                                                                                                                                                aria-readonly="false">
                                                                                                                                        </div>
                                                                                                                                    </th>
                            
                                                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                                                        pSortableColumn="name">
                                                                                                                                        <div
                                                                                                                                            style="color: white;">
                                                                                                                                            Estimated
                                                                                                                                            Setup
                                                                                                                                            Time
                                                                                                                                            <p-sortIcon
                                                                                                                                                field="name"></p-sortIcon>
                                                                                                                                        </div>
                                                                                                                                        <div>
                                                                                                                                            <input
                                                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                                value=""
                                                                                                                                                autocomplete="off"
                                                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                role="textbox"
                                                                                                                                                aria-disabled="false"
                                                                                                                                                aria-readonly="false">
                                                                                                                                        </div>
                                                                                                                                    </th>
                            
                                                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                                                        pSortableColumn="name">
                                                                                                                                        <div
                                                                                                                                            style="color: white;">
                                                                                                                                            Estimated
                                                                                                                                            Total
                                                                                                                                            Run
                                                                                                                                            Time
                                                                                                                                            <p-sortIcon
                                                                                                                                                field="name"></p-sortIcon>
                                                                                                                                        </div>
                                                                                                                                        <div>
                                                                                                                                            <input
                                                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                                value=""
                                                                                                                                                autocomplete="off"
                                                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                role="textbox"
                                                                                                                                                aria-disabled="false"
                                                                                                                                                aria-readonly="false">
                                                                                                                                        </div>
                                                                                                                                    </th>
                                                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                                                        pSortableColumn="name">
                                                                                                                                        <div
                                                                                                                                            style="color: white;">
                                                                                                                                            Actual
                                                                                                                                            Setup
                                                                                                                                            Time
                                                                                                                                            <p-sortIcon
                                                                                                                                                field="name"></p-sortIcon>
                                                                                                                                        </div>
                                                                                                                                        <div>
                                                                                                                                            <input
                                                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                                value=""
                                                                                                                                                autocomplete="off"
                                                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                role="textbox"
                                                                                                                                                aria-disabled="false"
                                                                                                                                                aria-readonly="false">
                                                                                                                                        </div>
                                                                                                                                    </th>
                                                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                                                        pSortableColumn="name">
                                                                                                                                        <div
                                                                                                                                            style="color: white;">
                                                                                                                                            Actual
                                                                                                                                            Time
                                                                                                                                            <p-sortIcon
                                                                                                                                                field="name"></p-sortIcon>
                                                                                                                                        </div>
                                                                                                                                        <div>
                                                                                                                                            <input
                                                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                                value=""
                                                                                                                                                autocomplete="off"
                                                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                role="textbox"
                                                                                                                                                aria-disabled="false"
                                                                                                                                                aria-readonly="false">
                                                                                                                                        </div>
                                                                                                                                    </th>
                                                                                                                                    <th [ngStyle]="{'width':'170px'}"
                                                                                                                                        pSortableColumn="name">
                                                                                                                                        <div
                                                                                                                                            style="color: white;">
                                                                                                                                            Produced
                                                                                                                                            <p-sortIcon
                                                                                                                                                field="name"></p-sortIcon>
                                                                                                                                        </div>
                                                                                                                                        <div>
                                                                                                                                            <input
                                                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                                value=""
                                                                                                                                                autocomplete="off"
                                                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                role="textbox"
                                                                                                                                                aria-disabled="false"
                                                                                                                                                aria-readonly="false">
                                                                                                                                        </div>
                                                                                                                                    </th>
                                                                                                                                    <th [ngStyle]="{'width':'300px'}"
                                                                                                                                        pSortableColumn="name">
                                                                                                                                        <div
                                                                                                                                            style="color: white;">
                                                                                                                                            Action
                                                                                                                                            <p-sortIcon
                                                                                                                                                field="name"></p-sortIcon>
                                                                                                                                        </div>
                                                                                                                                        <div>
                                                                                                                                            <input
                                                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                                value=""
                                                                                                                                                autocomplete="off"
                                                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                                role="textbox"
                                                                                                                                                aria-disabled="false"
                                                                                                                                                aria-readonly="false">
                                                                                                                                        </div>
                                                                                                                                    </th>
                            
                            
                            
                            
                            
                                                                                                                                </tr>
                                                                                                                            </ng-template>
                                                                                                                            <ng-template
                                                                                                                                pTemplate="body"
                                                                                                                                let-product>
                                                                                                                                <tr>
                                                                                                                                    <td
                                                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                                                        <div
                                                                                                                                            class="checkbox-align">
                                                                                                                                            <p-checkbox
                                                                                                                                                _ngcontent-egj-c329=""
                                                                                                                                                styleclass="custom-checkbox"
                                                                                                                                                binary="true"
                                                                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                                <div
                                                                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                                    <div
                                                                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                                                                        <input
                                                                                                                                                            type="checkbox"
                                                                                                                                                            name="undefined"
                                                                                                                                                            value="undefined">
                                                                                                                                                    </div>
                                                                                                                                                    <div role="checkbox"
                                                                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                        <span
                                                                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                                    </div>
                                                                                                                                                </div>
                            
                                                                                                                                            </p-checkbox>
                                                                                                                                            {{product.sequenceNum}}
                                                                                                                                        </div>
                                                                                                                                    </td>
                                                                                                                                    <td
                                                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                                                        {{product.workEffortId}}
                                                                                                                                    </td>
                                                                                                                                    <td
                                                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                                                        {{product.currentStatusId}}
                                                                                                                                    </td>
                                                                                                                                    <td
                                                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                                                        {{product.fixedAssetId}}
                                                                                                                                    </td>
                                                                                                                                    <td
                                                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                                                        {{product.reservPersons}}
                                                                                                                                    </td>
                                                                                                                                    <td
                                                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                                                        {{product.estimatedStartDate|date:'yyyy-MM-dd
                                                                                                                                        hh:mm:ss'}}
                                                                                                                                    </td>
                                                                                                                                    <td
                                                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                                                        {{product.estimatedCompletionDate|date:'yyyy-MM-dd
                                                                                                                                        hh:mm:ss'}}
                                                                                                                                    </td>
                                                                                                                                    <td
                                                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                                                        {{product.estimatedSetupMillis}}
                                                                                                                                    </td>
                                                                                                                                    <td
                                                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                                                        {{product.estimatedTotalMilliSeconds}}
                                                                                                                                    </td>
                            
                                                                                                                                    <td
                                                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                                                        {{product.actualSetupMillis}}
                                                                                                                                    </td>
                                                                                                                                    <td
                                                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                                                        {{product.actualMilliSeconds}}
                                                                                                                                    </td>
                                                                                                                                    <td
                                                                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                                                                        {{product.quantityProduced}}
                                                                                                                                    </td>
                                                                                                                                    <td [ngStyle]="{'width':'300px'}"
                                                                                                                                        style="text-align: center;">
                                                                                                                                        <span
                                                                                                                                            *ngIf="product.showDeclareLink=='Y'"><a
                                                                                                                                                style="cursor: pointer;color: blue!important;"
                                                                                                                                                (click)="createProduction(product.workEffortId)">Declare</a>&nbsp;
                                                                                                                                            <a (click)="createQuantity(product.workEffortId)"
                                                                                                                                                style="cursor: pointer;color: blue!important;">Add
                                                                                                                                                Qty</a></span>&nbsp;
                                                                                                                                        <span
                                                                                                                                            *ngIf="product.showStartLink=='Y'">
                                                                                                                                            <a (click)="startProduction(product.workEffortId)"
                                                                                                                                                style="cursor: pointer;color: blue!important;">Start</a></span>
                                                                                                                                        &nbsp;
                                                                                                                                        <span
                                                                                                                                            *ngIf="product.showCompleteLink=='Y'">
                                                                                                                                            <a (click)="startProduction(product.workEffortId)"
                                                                                                                                                style="cursor: pointer;color: blue!important;">Complete</a></span>&nbsp;
                                                                                                                                        <span
                                                                                                                                            *ngIf="product.showIssueLinkAtp=='Y'">
                                                                                                                                            <a (click)="issueProductionRunRoutingTaskATP(product.workEffortId)"
                                                                                                                                                style="cursor: pointer;color: blue!important;">Issue
                                                                                                                                                Components;ATP</a></span>&nbsp;
                                                                                                                                        <span
                                                                                                                                            *ngIf="product.showIssueLinkQoh=='Y'">
                                                                                                                                            <a (click)="issueProductionRunRoutingTask(product.workEffortId)"
                                                                                                                                                style="cursor: pointer;color: blue!important;">Issue
                                                                                                                                                Components</a></span>&nbsp;
                            
                            
                                                                                                                                    </td>
                            
                            
                            
                                                                                                                                </tr>
                                                                                                                            </ng-template>
                                                                                                                            <ng-template
                                                                                                                                pTemplate="emptymessage"
                                                                                                                                let-columns>
                                                                                                                                <tr>
                                                                                                                                    <td>
                                                                                                                                        <span
                                                                                                                                            class="text-center">
                                                                                                                                            <h4
                                                                                                                                                class="ml-2">
                                                                                                                                                No
                                                                                                                                                Record
                                                                                                                                                Found
                                                                                                                                            </h4>
                                                                                                                                        </span>
                                                                                                                                    </td>
                                                                                                                                </tr>
                                                                                                                            </ng-template>
                                                                                                                        </p-table>
                                                                                                                        <p class="paginate_data">
                                                                                                                            View per page
                            
                                                                                                                        </p>
                                                                                                                    </div>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                            
                                                                                            </div> -->
                                    
                                                                                    </div>
                                                                                    <div *ngIf="activeCategoryA==6">
                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select" style="display: block!important;">
                                                                                            <div class="w3-card-4 classCard">
                                                                                                <header class="w3-container w3-blue">
                                                                                                    <div class="header-tabs">
                                                                                                        <h4 class=" common-styling Hleft">
                                                                                                            Materials
                                                                                                        </h4>
                            
                                                                                                    </div>
                                                                                                </header>
                            
                                                                                                <div class="w3-container">
                                                                                                    <div class="panel-body">
                                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select allUserDetails">
                                                                                                            <div class="card own-account-table">
                                                                                                                <p-table [value]="productionRunMaterialsData" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                                    <ng-template pTemplate="header">
                                                                                                                        <tr style="background: #0d3769;">
                                                                                                                            <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                                                <div class="checkbox-align" style="color: white;">
                            
                                                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                                                <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        
                                                                                                                                    </p-checkbox>
                            
                            
                            
                            
                                                                                                                                    Routing Task ID
                                                                                                                                    <p-sortIcon field="code"></p-sortIcon>
                                                                                                                                </div>
                                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                            </th>
                                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                <div style="color: white;"> Product Name
                                                                                                                                    <p-sortIcon field="name"></p-sortIcon>
                                                                                                                                </div>
                                                                                                                                <div>
                                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                </div>
                                                                                                                            </th>
                            
                                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                <div style="color: white;">Quantity
                                                                                                                                    <p-sortIcon field="name"></p-sortIcon>
                                                                                                                                </div>
                                                                                                                                <div>
                                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                </div>
                                                                                                                            </th>
                                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                <div style="color: white;">UOM
                                                                                                                                    <p-sortIcon field="name"></p-sortIcon>
                                                                                                                                </div>
                                                                                                                                <div>
                                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                </div>
                                                                                                                            </th>
                            
                            
                            
                            
                            
                            
                                                                                                                        </tr>
                                                                                                                    </ng-template>
                                                                                                                    <ng-template pTemplate="body" let-product>
                                                                                                                        <tr>
                                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                                <div class="checkbox-align">
                                                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                                                <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        
                                                                                                                                    </p-checkbox>
                                                                                                                                    {{product.workEffortId}}
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                            <td [ngStyle]="{'width':'170px'}"> {{product.productName}} </td>
                                                                                                                            <td [ngStyle]="{'width':'170px'}">{{product.estimatedQuantity}} </td>
                                                                                                                            <td [ngStyle]="{'width':'170px'}"> {{product.uomDesc}}</td>
                            
                            
                            
                            
                                                                                                                        </tr>
                                                                                                                    </ng-template>
                                                                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                                                                        <tr>
                                                                                                                            <td>
                                                                                                                                <span class="text-center">
                                                                                                <h4 class="ml-2">No Record Found
                                                                                                </h4>
                                                                                            </span>
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                    </ng-template>
                                                                                                                </p-table>
                                                                                                                <p class="paginate_data">
                                                                                                                    View per page
                            
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                            
                            
                                                                                            </div>
                                                                                        </div> 
                            
                                                                                        
                                    
                                                                                    </div>
                                                                                    <div *ngIf="activeCategoryA==7">
                                                                                        <!-- <div class="w3-card-4  w-100 ">
                                                                                            <div class="accordion classCard" id="myAccordion">
                                                                                                <div class="accordion-item">
                                                                                                    <h2 class="accordion-header"
                                                                                                        id="headingOne">
                                                                                                        <div
                                                                                                            class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion">
                                                                                                            <span class="headingAccords"> List
                                                                                                                Of
                                                                                                                Production Run Fixed Assets
                                                                                                            </span>
                        
                                                                                                            <button type="button"
                                                                                                                class="accordion-button  collapsed"
                                                                                                                data-bs-toggle="collapse"
                                                                                                                data-bs-target="#collapse12"
                                                                                                                style="margin-left: 45%!important; padding-top: 11px !important;  padding-bottom: 11px !important;">
                                                                                                            </button>
                        
                                                                                                        </div>
                        
                                                                                                    </h2>
                                                                                                    <div id="collapse12"
                                                                                                        class="accordion-collapse collapse"
                                                                                                        data-bs-parent="#myAccordion">
                                                                                                        <div class="card-body">
                                                                                                            <div
                                                                                                                class="all-users-infomation font-13 font-medium own-user-select allUserDetails">
                                                                                                                <div
                                                                                                                    class="card own-account-table">
                                                                                                                    <p-table
                                                                                                                        [value]="productionRunAssetsData"
                                                                                                                        [paginator]="true"
                                                                                                                        [rows]="rows"
                                                                                                                        scrollWidth="100%"
                                                                                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]"
                                                                                                                        [totalRecords]="total"
                                                                                                                        [scrollable]="true"
                                                                                                                        (sortFunction)="customSort($event)"
                                                                                                                        [customSort]="true">
                                                                                                                        <ng-template
                                                                                                                            pTemplate="header">
                                                                                                                            <tr
                                                                                                                                style="background: #0d3769;">
                                                                                                                                <th pSortableColumn="code"
                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                    <div class="checkbox-align"
                                                                                                                                        style="color: white;">
                        
                                                                                                                                        <p-checkbox
                                                                                                                                            _ngcontent-egj-c329=""
                                                                                                                                            styleclass="custom-checkbox"
                                                                                                                                            binary="true"
                                                                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                            <div
                                                                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                                <div
                                                                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                                                                    <input
                                                                                                                                                        type="checkbox"
                                                                                                                                                        name="undefined"
                                                                                                                                                        value="undefined">
                                                                                                                                                </div>
                                                                                                                                                <div role="checkbox"
                                                                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                    <span
                                                                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                                </div>
                                                                                                                                            </div>
                        
                                                                                                                                        </p-checkbox>
                        
                        
                        
                        
                                                                                                                                        Work
                                                                                                                                        Effort
                                                                                                                                        ID
                                                                                                                                        <p-sortIcon
                                                                                                                                            field="code"></p-sortIcon>
                                                                                                                                    </div>
                                                                                                                                    <input
                                                                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                        value=""
                                                                                                                                        autocomplete="off"
                                                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                        role="textbox"
                                                                                                                                        aria-disabled="false"
                                                                                                                                        aria-readonly="false">
                                                                                                                                </th>
                                                                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                                                                    pSortableColumn="name">
                                                                                                                                    <div
                                                                                                                                        style="color: white;">
                                                                                                                                        Fixed
                                                                                                                                        Asset
                                                                                                                                        ID
                                                                                                                                        <p-sortIcon
                                                                                                                                            field="name"></p-sortIcon>
                                                                                                                                    </div>
                                                                                                                                    <div>
                                                                                                                                        <input
                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                            value=""
                                                                                                                                            autocomplete="off"
                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                            role="textbox"
                                                                                                                                            aria-disabled="false"
                                                                                                                                            aria-readonly="false">
                                                                                                                                    </div>
                                                                                                                                </th>
                        
                                                                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                                                                    pSortableColumn="name">
                                                                                                                                    <div
                                                                                                                                        style="color: white;">
                                                                                                                                        Status
                                                                                                                                        ID
                                                                                                                                        <p-sortIcon
                                                                                                                                            field="name"></p-sortIcon>
                                                                                                                                    </div>
                                                                                                                                    <div>
                                                                                                                                        <input
                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                            value=""
                                                                                                                                            autocomplete="off"
                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                            role="textbox"
                                                                                                                                            aria-disabled="false"
                                                                                                                                            aria-readonly="false">
                                                                                                                                    </div>
                                                                                                                                </th>
                                                                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                                                                    pSortableColumn="name">
                                                                                                                                    <div
                                                                                                                                        style="color: white;">
                                                                                                                                        From
                                                                                                                                        Date
                                                                                                                                        <p-sortIcon
                                                                                                                                            field="name"></p-sortIcon>
                                                                                                                                    </div>
                                                                                                                                    <div>
                                                                                                                                        <input
                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                            value=""
                                                                                                                                            autocomplete="off"
                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                            role="textbox"
                                                                                                                                            aria-disabled="false"
                                                                                                                                            aria-readonly="false">
                                                                                                                                    </div>
                                                                                                                                </th>
                                                                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                                                                    pSortableColumn="name">
                                                                                                                                    <div
                                                                                                                                        style="color: white;">
                                                                                                                                        Through
                                                                                                                                        Date
                                                                                                                                        <p-sortIcon
                                                                                                                                            field="name"></p-sortIcon>
                                                                                                                                    </div>
                                                                                                                                    <div>
                                                                                                                                        <input
                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                            value=""
                                                                                                                                            autocomplete="off"
                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                            role="textbox"
                                                                                                                                            aria-disabled="false"
                                                                                                                                            aria-readonly="false">
                                                                                                                                    </div>
                                                                                                                                </th>
                        
                                                                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                                                                    pSortableColumn="name">
                                                                                                                                    <div
                                                                                                                                        style="color: white;">
                                                                                                                                        Availability
                                                                                                                                        <p-sortIcon
                                                                                                                                            field="name"></p-sortIcon>
                                                                                                                                    </div>
                                                                                                                                    <div>
                                                                                                                                        <input
                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                            value=""
                                                                                                                                            autocomplete="off"
                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                            role="textbox"
                                                                                                                                            aria-disabled="false"
                                                                                                                                            aria-readonly="false">
                                                                                                                                    </div>
                                                                                                                                </th>
                        
                                                                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                                                                    pSortableColumn="name">
                                                                                                                                    <div
                                                                                                                                        style="color: white;">
                                                                                                                                        Allocated
                                                                                                                                        Cost
                                                                                                                                        <p-sortIcon
                                                                                                                                            field="name"></p-sortIcon>
                                                                                                                                    </div>
                                                                                                                                    <div>
                                                                                                                                        <input
                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                            value=""
                                                                                                                                            autocomplete="off"
                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                            role="textbox"
                                                                                                                                            aria-disabled="false"
                                                                                                                                            aria-readonly="false">
                                                                                                                                    </div>
                                                                                                                                </th>
                        
                                                                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                                                                    pSortableColumn="name">
                                                                                                                                    <div
                                                                                                                                        style="color: white;">
                                                                                                                                        Comments
                                                                                                                                        <p-sortIcon
                                                                                                                                            field="name"></p-sortIcon>
                                                                                                                                    </div>
                                                                                                                                    <div>
                                                                                                                                        <input
                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                            value=""
                                                                                                                                            autocomplete="off"
                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                            role="textbox"
                                                                                                                                            aria-disabled="false"
                                                                                                                                            aria-readonly="false">
                                                                                                                                    </div>
                                                                                                                                </th>
                        
                        
                        
                        
                        
                                                                                                                            </tr>
                                                                                                                        </ng-template>
                                                                                                                        <ng-template
                                                                                                                            pTemplate="body"
                                                                                                                            let-product>
                                                                                                                            <tr>
                                                                                                                                <td
                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                    <div
                                                                                                                                        class="checkbox-align">
                                                                                                                                        <p-checkbox
                                                                                                                                            _ngcontent-egj-c329=""
                                                                                                                                            styleclass="custom-checkbox"
                                                                                                                                            binary="true"
                                                                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                            <div
                                                                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                                <div
                                                                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                                                                    <input
                                                                                                                                                        type="checkbox"
                                                                                                                                                        name="undefined"
                                                                                                                                                        value="undefined">
                                                                                                                                                </div>
                                                                                                                                                <div role="checkbox"
                                                                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                    <span
                                                                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                                </div>
                                                                                                                                            </div>
                        
                                                                                                                                        </p-checkbox>
                                                                                                                                        {{product.workEffortId}}
                                                                                                                                    </div>
                                                                                                                                </td>
                                                                                                                                <td
                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                    {{product.fixedAssteName}}
                                                                                                                                </td>
                                                                                                                                <td
                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                    {{product.statusId}}
                                                                                                                                </td>
                                                                                                                                <td
                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                    {{product.fromDate|date:'yyyy-MM-dd
                                                                                                                                    hh:mm:ss'}}
                                                                                                                                </td>
                                                                                                                                <td
                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                    {{product.thruDate|date:'yyyy-MM-dd
                                                                                                                                    hh:mm:ss'}}
                                                                                                                                </td>
                                                                                                                                <td
                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                    {{product.availabilityStatus}}
                                                                                                                                </td>
                                                                                                                                <td
                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                    {{product.allocatedCost}}
                                                                                                                                </td>
                                                                                                                                <td
                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                    {{product.comments}}
                                                                                                                                </td>
                        
                        
                        
                                                                                                                            </tr>
                                                                                                                        </ng-template>
                                                                                                                        <ng-template
                                                                                                                            pTemplate="emptymessage"
                                                                                                                            let-columns>
                                                                                                                            <tr>
                                                                                                                                <td>
                                                                                                                                    <span
                                                                                                                                        class="text-center">
                                                                                                                                        <h4
                                                                                                                                            class="ml-2">
                                                                                                                                            No
                                                                                                                                            Record
                                                                                                                                            Found
                                                                                                                                        </h4>
                                                                                                                                    </span>
                                                                                                                                </td>
                                                                                                                            </tr>
                                                                                                                        </ng-template>
                                                                                                                    </p-table>
                                                                                                                    <p class="paginate_data">
                                                                                                                        View per page
                        
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                            </div>
                        
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                        
                                                                                        </div> -->
                        
                                                                                         <div class="all-users-infomation font-13 font-medium own-user-select" style="display: block!important;">
                                                                                        <div class="w3-card-4 classCard">
                                                                                            <header class="w3-container w3-blue">
                                                                                                <div class="header-tabs">
                                                                                                    <h4 class=" common-styling Hleft">
                                                                                                        List Of Production Run Fixed Assets
                                                                                                    </h4>
                        
                                                                                                </div>
                                                                                            </header>
                        
                                                                                            <div class="w3-container">
                                                                                                <div class="panel-body">
                                                                                                    <div class="all-users-infomation font-13 font-medium own-user-select allUserDetails">
                                                                                                        <div class="card own-account-table">
                                                                                                            <p-table [value]="productionRunAssetsData" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                                <ng-template pTemplate="header">
                                                                                                                    <tr style="background: #0d3769;">
                                                                                                                        <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                                            <div class="checkbox-align" style="color: white;">
                        
                                                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                                                            <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    
                                                                                                                                </p-checkbox>
                        
                        
                        
                        
                                                                                                                                Work Effort ID
                                                                                                                                <p-sortIcon field="code"></p-sortIcon>
                                                                                                                            </div>
                                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                        </th>
                                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                            <div style="color: white;"> Fixed Asset ID
                                                                                                                                <p-sortIcon field="name"></p-sortIcon>
                                                                                                                            </div>
                                                                                                                            <div>
                                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                            </div>
                                                                                                                        </th>
                        
                                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                            <div style="color: white;">Status ID
                                                                                                                                <p-sortIcon field="name"></p-sortIcon>
                                                                                                                            </div>
                                                                                                                            <div>
                                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                            </div>
                                                                                                                        </th>
                                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                            <div style="color: white;"> From Date
                                                                                                                                <p-sortIcon field="name"></p-sortIcon>
                                                                                                                            </div>
                                                                                                                            <div>
                                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                            </div>
                                                                                                                        </th>
                                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                            <div style="color: white;"> Through Date
                                                                                                                                <p-sortIcon field="name"></p-sortIcon>
                                                                                                                            </div>
                                                                                                                            <div>
                                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                            </div>
                                                                                                                        </th>
                        
                                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                            <div style="color: white;">Availability
                                                                                                                                <p-sortIcon field="name"></p-sortIcon>
                                                                                                                            </div>
                                                                                                                            <div>
                                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                            </div>
                                                                                                                        </th>
                        
                                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                            <div style="color: white;">Allocated Cost
                                                                                                                                <p-sortIcon field="name"></p-sortIcon>
                                                                                                                            </div>
                                                                                                                            <div>
                                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                            </div>
                                                                                                                        </th>
                        
                                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                            <div style="color: white;">Comments
                                                                                                                                <p-sortIcon field="name"></p-sortIcon>
                                                                                                                            </div>
                                                                                                                            <div>
                                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                            </div>
                                                                                                                        </th>
                        
                        
                        
                        
                        
                                                                                                                    </tr>
                                                                                                                </ng-template>
                                                                                                                <ng-template pTemplate="body" let-product>
                                                                                                                    <tr>
                                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                                            <div class="checkbox-align">
                                                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                                                            <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    
                                                                                                                                </p-checkbox>
                                                                                                                                {{product.workEffortId}}
                                                                                                                            </div>
                                                                                                                        </td>
                                                                                                                        <td [ngStyle]="{'width':'170px'}"> {{product.fixedAssteName}} </td>
                                                                                                                        <td [ngStyle]="{'width':'170px'}"> {{product.statusId}} </td>
                                                                                                                        <td [ngStyle]="{'width':'170px'}"> {{product.fromDate|date:'yyyy-MM-dd hh:mm:ss'}} </td>
                                                                                                                        <td [ngStyle]="{'width':'170px'}"> {{product.thruDate|date:'yyyy-MM-dd hh:mm:ss'}} </td>
                                                                                                                        <td [ngStyle]="{'width':'170px'}"> {{product.availabilityStatus}} </td>
                                                                                                                        <td [ngStyle]="{'width':'170px'}"> {{product.allocatedCost}}</td>
                                                                                                                        <td [ngStyle]="{'width':'170px'}"> {{product.comments}} </td>
                        
                        
                        
                                                                                                                    </tr>
                                                                                                                </ng-template>
                                                                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                                                                    <tr>
                                                                                                                        <td>
                                                                                                                            <span class="text-center">
                                                                                              <h4 class="ml-2">No Record Found
                                                                                              </h4>
                                                                                          </span>
                                                                                                                        </td>
                                                                                                                    </tr>
                                                                                                                </ng-template>
                                                                                                            </p-table>
                                                                                                            <p class="paginate_data">
                                                                                                                View per page
                        
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                        
                        
                                                                                        </div>
                                                                                    </div> 
                        
                        
                                    
                                                                                    </div>
                                                                                    <div *ngIf="activeCategoryA==8">
                                                                                     
                                                                                        <!-- <div class="w3-card-4  w-100 mt-3">
                                                                                            <div class="accordion classCard" id="myAccordion">
                                                                                                <div class="accordion-item">
                                                                                                    <h2 class="accordion-header"
                                                                                                        id="headingOne">
                                                                                                        <div
                                                                                                            class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion">
                                                                                                            <span class="headingAccords"> List
                                                                                                                Of
                                                                                                                Production Run Notes
                                                                                                            </span>
                        
                                                                                                            <button type="button"
                                                                                                                class="accordion-button  collapsed"
                                                                                                                data-bs-toggle="collapse"
                                                                                                                data-bs-target="#collapse11"
                                                                                                                style="margin-left: 52%!important; padding-top: 11px !important;
                                                                                                            padding-bottom: 11px !important;">
                                                                                                            </button>
                        
                                                                                                        </div>
                        
                                                                                                    </h2>
                                                                                                    <div id="collapse11"
                                                                                                        class="accordion-collapse collapse"
                                                                                                        data-bs-parent="#myAccordion">
                                                                                                        <div class="card-body">
                                                                                                            <div
                                                                                                                class="all-users-infomation font-13 font-medium own-user-select allUserDetails">
                                                                                                                <div
                                                                                                                    class="card own-account-table">
                                                                                                                    <p-table
                                                                                                                        [value]="budgetItem"
                                                                                                                        [paginator]="true"
                                                                                                                        [rows]="rows"
                                                                                                                        scrollWidth="100%"
                                                                                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]"
                                                                                                                        [totalRecords]="total"
                                                                                                                        [scrollable]="true"
                                                                                                                        (sortFunction)="customSort($event)"
                                                                                                                        [customSort]="true">
                                                                                                                        <ng-template
                                                                                                                            pTemplate="header">
                                                                                                                            <tr
                                                                                                                                style="background: #0d3769;">
                                                                                                                                <th pSortableColumn="code"
                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                    <div class="checkbox-align"
                                                                                                                                        style="color: white;">
                        
                                                                                                                                        <p-checkbox
                                                                                                                                            _ngcontent-egj-c329=""
                                                                                                                                            styleclass="custom-checkbox"
                                                                                                                                            binary="true"
                                                                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                            <div
                                                                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                                <div
                                                                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                                                                    <input
                                                                                                                                                        type="checkbox"
                                                                                                                                                        name="undefined"
                                                                                                                                                        value="undefined">
                                                                                                                                                </div>
                                                                                                                                                <div role="checkbox"
                                                                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                    <span
                                                                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                                </div>
                                                                                                                                            </div>
                        
                                                                                                                                        </p-checkbox>
                        
                        
                                                                                                                                        Note
                                                                                                                                        Name
                                                                                                                                        <p-sortIcon
                                                                                                                                            field="code"></p-sortIcon>
                                                                                                                                    </div>
                                                                                                                                    <input
                                                                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                        value=""
                                                                                                                                        autocomplete="off"
                                                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                        role="textbox"
                                                                                                                                        aria-disabled="false"
                                                                                                                                        aria-readonly="false">
                                                                                                                                </th>
                                                                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                                                                    pSortableColumn="name">
                                                                                                                                    <div
                                                                                                                                        style="color: white;">
                                                                                                                                        Note
                                                                                                                                        Info
                                                                                                                                        <p-sortIcon
                                                                                                                                            field="name"></p-sortIcon>
                                                                                                                                    </div>
                                                                                                                                    <div>
                                                                                                                                        <input
                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                            value=""
                                                                                                                                            autocomplete="off"
                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                            role="textbox"
                                                                                                                                            aria-disabled="false"
                                                                                                                                            aria-readonly="false">
                                                                                                                                    </div>
                                                                                                                                </th>
                        
                        
                        
                        
                        
                                                                                                                            </tr>
                                                                                                                        </ng-template>
                                                                                                                        <ng-template
                                                                                                                            pTemplate="body"
                                                                                                                            let-product>
                                                                                                                            <tr>
                                                                                                                                <td
                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                    <div
                                                                                                                                        class="checkbox-align">
                                                                                                                                        <p-checkbox
                                                                                                                                            _ngcontent-egj-c329=""
                                                                                                                                            styleclass="custom-checkbox"
                                                                                                                                            binary="true"
                                                                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                            <div
                                                                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                                <div
                                                                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                                                                    <input
                                                                                                                                                        type="checkbox"
                                                                                                                                                        name="undefined"
                                                                                                                                                        value="undefined">
                                                                                                                                                </div>
                                                                                                                                                <div role="checkbox"
                                                                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                    <span
                                                                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                                </div>
                                                                                                                                            </div>
                        
                                                                                                                                        </p-checkbox>
                        
                                                                                                                                    </div>
                                                                                                                                </td>
                                                                                                                                <td
                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                </td>
                        
                        
                                                                                                                            </tr>
                                                                                                                        </ng-template>
                                                                                                                        <ng-template
                                                                                                                            pTemplate="emptymessage"
                                                                                                                            let-columns>
                                                                                                                            <tr>
                                                                                                                                <td>
                                                                                                                                    <span
                                                                                                                                        class="text-center">
                                                                                                                                        <h4
                                                                                                                                            class="ml-2">
                                                                                                                                            No
                                                                                                                                            Record
                                                                                                                                            Found
                                                                                                                                        </h4>
                                                                                                                                    </span>
                                                                                                                                </td>
                                                                                                                            </tr>
                                                                                                                        </ng-template>
                                                                                                                    </p-table>
                                                                                                                    <p class="paginate_data">
                                                                                                                        View per page
                        
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                            </div>
                        
                        
                        
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                        
                                                                                        </div> -->
                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select" style="display: block!important;">
                                                                                        <div class="w3-card-4 classCard">
                                                                                            <header class="w3-container w3-blue">
                                                                                                <div class="header-tabs">
                                                                                                    <h4 class=" common-styling Hleft">
                                                                                                        List Of Production Run Notes
                                                                                                    </h4>
                        
                                                                                                </div>
                                                                                            </header>
                        
                                                                                            <div class="w3-container">
                                                                                                <div class="panel-body">
                                                                                                    <div class="all-users-infomation font-13 font-medium own-user-select allUserDetails">
                                                                                                        <div class="card own-account-table">
                                                                                                            <p-table [value]="budgetItem" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                                <ng-template pTemplate="header">
                                                                                                                    <tr style="background: #0d3769;">
                                                                                                                        <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                                            <div class="checkbox-align" style="color: white;">
                        
                                                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                                                            <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    
                                                                                                                                </p-checkbox>
                        
                        
                                                                                                                                Note Name
                                                                                                                                <p-sortIcon field="code"></p-sortIcon>
                                                                                                                            </div>
                                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                        </th>
                                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                            <div style="color: white;"> Note Info
                                                                                                                                <p-sortIcon field="name"></p-sortIcon>
                                                                                                                            </div>
                                                                                                                            <div>
                                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                            </div>
                                                                                                                        </th>
                        
                        
                        
                        
                        
                                                                                                                    </tr>
                                                                                                                </ng-template>
                                                                                                                <ng-template pTemplate="body" let-product>
                                                                                                                    <tr>
                                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                                            <div class="checkbox-align">
                                                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                                                            <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    
                                                                                                                                </p-checkbox>
                        
                                                                                                                            </div>
                                                                                                                        </td>
                                                                                                                        <td [ngStyle]="{'width':'170px'}"> </td>
                        
                        
                                                                                                                    </tr>
                                                                                                                </ng-template>
                                                                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                                                                    <tr>
                                                                                                                        <td>
                                                                                                                            <span class="text-center">
                                                                                                    <h4 class="ml-2">No Record Found
                                                                                                    </h4>
                                                                                                </span>
                                                                                                                        </td>
                                                                                                                    </tr>
                                                                                                                </ng-template>
                                                                                                            </p-table>
                                                                                                            <p class="paginate_data">
                                                                                                                View per page
                        
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                        
                        
                                                                                        </div>
                                                                                    </div> 
                        
                                                                                    </div>
                                                                                    <div *ngIf="activeCategoryA==9">
                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select" style="display: block!important;">
                                                                                            <div class="w3-card-4 classCard">
                                                                                                <header class="w3-container w3-blue">
                                                                                                    <div class="header-tabs">
                                                                                                        <h4 class=" common-styling Hleft">
                                                                                                            Return Unused Materials To Warehouse
                                                                                                        </h4>
                            
                                                                                                    </div>
                                                                                                </header>
                            
                                                                                                <div class="w3-container">
                                                                                                    <div class="panel-body">
                                                                                                        <div class="all-users-infomation font-13 font-medium own-user-select allUserDetails">
                                                                                                            <div class="card own-account-table">
                                                                                                                <p-table [value]="returnUnusedMaterialsToWarehouse" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                                    <ng-template pTemplate="header">
                                                                                                                        <tr style="background: #0d3769;">
                                                                                                                            <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                                                <div class="checkbox-align" style="color: white;">
                            
                                                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                                                <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        
                                                                                                                                    </p-checkbox>
                            
                            
                            
                                                                                                                                    Routing Task ID
                                                                                                                                    <p-sortIcon field="code"></p-sortIcon>
                                                                                                                                </div>
                                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                            </th>
                                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                <div style="color: white;"> Product Name
                                                                                                                                    <p-sortIcon field="name"></p-sortIcon>
                                                                                                                                </div>
                                                                                                                                <div>
                                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                </div>
                                                                                                                            </th>
                            
                                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                <div style="color: white;">Quantity
                                                                                                                                    <p-sortIcon field="name"></p-sortIcon>
                                                                                                                                </div>
                                                                                                                                <div>
                                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                </div>
                                                                                                                            </th>
                                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                <div style="color: white;">Issued
                                                                                                                                    <p-sortIcon field="name"></p-sortIcon>
                                                                                                                                </div>
                                                                                                                                <div>
                                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                </div>
                                                                                                                            </th>
                                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                <div style="color: white;">Returned
                                                                                                                                    <p-sortIcon field="name"></p-sortIcon>
                                                                                                                                </div>
                                                                                                                                <div>
                                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                </div>
                                                                                                                            </th>
                            
                                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                <div style="color: white;">Lot ID
                                                                                                                                    <p-sortIcon field="name"></p-sortIcon>
                                                                                                                                </div>
                                                                                                                                <div>
                                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                </div>
                                                                                                                            </th>
                            
                                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                <div style="color: white;">Quantity
                                                                                                                                    <p-sortIcon field="name"></p-sortIcon>
                                                                                                                                </div>
                                                                                                                                <div>
                                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                </div>
                                                                                                                            </th>
                            
                                                                                                                            <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                                <div style="color: white;">Selected
                                                                                                                                    <p-sortIcon field="name"></p-sortIcon>
                                                                                                                                    <p-checkbox _ngcontent-egj-c329="" (click)="markUnusedCheck($event)" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                            <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                                                        </div>
                                                                                                                                        
                                                                                                                                    </p-checkbox>
                                                                                                                                </div>
                                                                                                                                <div>
                                                                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                                </div>
                                                                                                                            </th>
                            
                            
                            
                            
                            
                                                                                                                        </tr>
                                                                                                                    </ng-template>
                                                                                                                    <ng-template pTemplate="body" let-product>
                                                                                                                        <tr>
                                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                                <div class="checkbox-align">
                                                                                                                                    <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                        <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                            <div class="ui-helper-hidden-accessible">
                                                                                                                                                <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                                                            <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                            </div>
                                                                                                                                        </div>
                                                                                                                                        
                                                                                                                                    </p-checkbox>
                                                                                                                                    {{product.workEffortId}}
                                                                                                                                </div>
                                                                                                                            </td>
                                                                                                                            <td [ngStyle]="{'width':'170px'}"> {{product.internalName}} </td>
                                                                                                                            <td [ngStyle]="{'width':'170px'}"> {{product.estimatedQuantity}} </td>
                                                                                                                            <td [ngStyle]="{'width':'170px'}"> {{product.issuedQuantity}} </td>
                                                                                                                            <td [ngStyle]="{'width':'170px'}"> {{product.returnedQuantity}} </td>
                                                                                                                            <td [ngStyle]="{'width':'170px'}"> <input type="text" style="width: 66%;
                                                                                                  height: 5%!important;" [(ngModel)]="product.lotId" [ngModelOptions]="{standalone: true}" /> </td>
                                                                                                                            <td [ngStyle]="{'width':'170px'}"> <input type="text" style="width: 66%;
                                                                                                  height: 5%!important;" [(ngModel)]="product.quantity" [ngModelOptions]="{standalone: true}" /> </td>
                                                                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" (click)="oneSelectReturnUnused(product)" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                        <div class="ui-helper-hidden-accessible"><input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default"><span class="ui-chkbox-icon ui-clickable"></span></div>
                                                                                                                                    </div>
                                                                                                                                    
                                                                                                                                </p-checkbox>
                                                                                                                            </td>
                            
                            
                            
                                                                                                                        </tr>
                                                                                                                    </ng-template>
                                                                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                                                                        <tr>
                                                                                                                            <td>
                                                                                                                                <span class="text-center">
                                                                                                          <h4 class="ml-2">No Record Found
                                                                                                          </h4>
                                                                                                      </span>
                                                                                                                            </td>
                                                                                                                        </tr>
                                                                                                                    </ng-template>
                                                                                                                </p-table>
                                                                                                                <p class="paginate_data">
                                                                                                                    View per page
                            
                                                                                                                </p>
                                                                                                            </div>
                                                                                                        </div>
                                                                                                        <button *ngIf="returnUnusedMaterialsToWarehouse && returnUnusedMaterialsToWarehouse.length" _ngcontent-cta-c128="" style="font-size: 12px!important;margin-left: 80%;" type="submit" class="btn btn-secondary submit-btn" (click)="productionRunTaskReturnMaterial();">Submit</button>&nbsp;
                                                                                                    </div>
                                                                                                </div>
                                                                                                <br>
                            
                                                                                            </div>
                                                                                        </div> 
                                    
                                                                                    </div>
                                                                                    <div *ngIf="activeCategoryA==10">
                                                                                        <!-- <div class="w3-card-4  w-100 mt-3">
                                                                                            <div class="accordion classCard" id="myAccordion">
                                                                                                <div class="accordion-item">
                                                                                                    <h2 class="accordion-header"
                                                                                                        id="headingOne">
                                                                                                        <div
                                                                                                            class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion">
                                                                                                            <span class="headingAccords">
                                                                                                                Materials
                                                                                                                Required By The Running Task
                                                                                                            </span>
                        
                                                                                                            <button type="button"
                                                                                                                class="accordion-button  collapsed"
                                                                                                                data-bs-toggle="collapse"
                                                                                                                data-bs-target="#collapse9"
                                                                                                                style="margin-left: 40%!important; padding-top: 11px !important;
                                                                                                            padding-bottom: 11px !important;">
                                                                                                            </button>
                        
                                                                                                        </div>
                        
                                                                                                    </h2>
                                                                                                    <div id="collapse9"
                                                                                                        class="accordion-collapse collapse"
                                                                                                        data-bs-parent="#myAccordion">
                                                                                                        <div class="panel-body">
                                                                                                            <div
                                                                                                                class="all-users-infomation font-13 font-medium own-user-select allUserDetails">
                                                                                                                <div
                                                                                                                    class="card own-account-table">
                                                                                                                    <p-table
                                                                                                                        [value]="materialsRequiredByTheRunningTask"
                                                                                                                        [paginator]="true"
                                                                                                                        [rows]="rows"
                                                                                                                        scrollWidth="100%"
                                                                                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]"
                                                                                                                        [totalRecords]="total"
                                                                                                                        [scrollable]="true"
                                                                                                                        (sortFunction)="customSort($event)"
                                                                                                                        [customSort]="true">
                                                                                                                        <ng-template
                                                                                                                            pTemplate="header">
                                                                                                                            <tr
                                                                                                                                style="background: #0d3769;">
                                                                                                                                <th pSortableColumn="code"
                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                    <div class="checkbox-align"
                                                                                                                                        style="color: white;">
                        
                                                                                                                                        <p-checkbox
                                                                                                                                            _ngcontent-egj-c329=""
                                                                                                                                            styleclass="custom-checkbox"
                                                                                                                                            binary="true"
                                                                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                            <div
                                                                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                                <div
                                                                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                                                                    <input
                                                                                                                                                        type="checkbox"
                                                                                                                                                        name="undefined"
                                                                                                                                                        value="undefined">
                                                                                                                                                </div>
                                                                                                                                                <div role="checkbox"
                                                                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                    <span
                                                                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                                </div>
                                                                                                                                            </div>
                        
                                                                                                                                        </p-checkbox>
                        
                        
                        
                                                                                                                                        Fail If
                                                                                                                                        Items
                                                                                                                                        Are
                                                                                                                                        Not
                                                                                                                                        Available
                                                                                                                                        <p-sortIcon
                                                                                                                                            field="code"></p-sortIcon>
                                                                                                                                    </div>
                                                                                                                                    <input
                                                                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                        value=""
                                                                                                                                        autocomplete="off"
                                                                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                        role="textbox"
                                                                                                                                        aria-disabled="false"
                                                                                                                                        aria-readonly="false">
                                                                                                                                </th>
                                                                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                                                                    pSortableColumn="name">
                                                                                                                                    <div
                                                                                                                                        style="color: white;">
                                                                                                                                        Location
                                                                                                                                        Seq
                                                                                                                                        ID
                                                                                                                                        <p-sortIcon
                                                                                                                                            field="name"></p-sortIcon>
                                                                                                                                    </div>
                                                                                                                                    <div>
                                                                                                                                        <input
                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                            value=""
                                                                                                                                            autocomplete="off"
                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                            role="textbox"
                                                                                                                                            aria-disabled="false"
                                                                                                                                            aria-readonly="false">
                                                                                                                                    </div>
                                                                                                                                </th>
                        
                                                                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                                                                    pSortableColumn="name">
                                                                                                                                    <div
                                                                                                                                        style="color: white;">
                                                                                                                                        Secondary
                                                                                                                                        Location
                                                                                                                                        Seq
                                                                                                                                        ID
                                                                                                                                        <p-sortIcon
                                                                                                                                            field="name"></p-sortIcon>
                                                                                                                                    </div>
                                                                                                                                    <div>
                                                                                                                                        <input
                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                            value=""
                                                                                                                                            autocomplete="off"
                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                            role="textbox"
                                                                                                                                            aria-disabled="false"
                                                                                                                                            aria-readonly="false">
                                                                                                                                    </div>
                                                                                                                                </th>
                                                                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                                                                    pSortableColumn="name">
                                                                                                                                    <div
                                                                                                                                        style="color: white;">
                                                                                                                                        Lot ID
                                                                                                                                        <p-sortIcon
                                                                                                                                            field="name"></p-sortIcon>
                                                                                                                                    </div>
                                                                                                                                    <div>
                                                                                                                                        <input
                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                            value=""
                                                                                                                                            autocomplete="off"
                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                            role="textbox"
                                                                                                                                            aria-disabled="false"
                                                                                                                                            aria-readonly="false">
                                                                                                                                    </div>
                                                                                                                                </th>
                                                                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                                                                    pSortableColumn="name">
                                                                                                                                    <div
                                                                                                                                        style="color: white;">
                                                                                                                                        Routing
                                                                                                                                        Task
                                                                                                                                        ID
                                                                                                                                        <p-sortIcon
                                                                                                                                            field="name"></p-sortIcon>
                                                                                                                                    </div>
                                                                                                                                    <div>
                                                                                                                                        <input
                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                            value=""
                                                                                                                                            autocomplete="off"
                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                            role="textbox"
                                                                                                                                            aria-disabled="false"
                                                                                                                                            aria-readonly="false">
                                                                                                                                    </div>
                                                                                                                                </th>
                        
                                                                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                                                                    pSortableColumn="name">
                                                                                                                                    <div
                                                                                                                                        style="color: white;">
                                                                                                                                        Product
                                                                                                                                        Name
                                                                                                                                        <p-sortIcon
                                                                                                                                            field="name"></p-sortIcon>
                                                                                                                                    </div>
                                                                                                                                    <div>
                                                                                                                                        <input
                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                            value=""
                                                                                                                                            autocomplete="off"
                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                            role="textbox"
                                                                                                                                            aria-disabled="false"
                                                                                                                                            aria-readonly="false">
                                                                                                                                    </div>
                                                                                                                                </th>
                        
                                                                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                                                                    pSortableColumn="name">
                                                                                                                                    <div
                                                                                                                                        style="color: white;">
                                                                                                                                        Quantity
                                                                                                                                        <p-sortIcon
                                                                                                                                            field="name"></p-sortIcon>
                                                                                                                                    </div>
                                                                                                                                    <div>
                                                                                                                                        <input
                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                            value=""
                                                                                                                                            autocomplete="off"
                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                            role="textbox"
                                                                                                                                            aria-disabled="false"
                                                                                                                                            aria-readonly="false">
                                                                                                                                    </div>
                                                                                                                                </th>
                        
                                                                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                                                                    pSortableColumn="name">
                                                                                                                                    <div
                                                                                                                                        style="color: white;">
                                                                                                                                        Issued
                                                                                                                                        <p-sortIcon
                                                                                                                                            field="name"></p-sortIcon>
                                                                                                                                    </div>
                                                                                                                                    <div>
                                                                                                                                        <input
                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                            value=""
                                                                                                                                            autocomplete="off"
                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                            role="textbox"
                                                                                                                                            aria-disabled="false"
                                                                                                                                            aria-readonly="false">
                                                                                                                                    </div>
                                                                                                                                </th>
                                                                                                                                <th [ngStyle]="{'width':'170px'}"
                                                                                                                                    pSortableColumn="name">
                                                                                                                                    <div
                                                                                                                                        style="color: white;">
                                                                                                                                        Action
                                                                                                                                        <p-sortIcon
                                                                                                                                            field="name"></p-sortIcon>
                                                                                                                                    </div>
                                                                                                                                    <div>
                                                                                                                                        <input
                                                                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                                            value=""
                                                                                                                                            autocomplete="off"
                                                                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                            role="textbox"
                                                                                                                                            aria-disabled="false"
                                                                                                                                            aria-readonly="false">
                                                                                                                                    </div>
                                                                                                                                </th>
                        
                        
                        
                        
                        
                                                                                                                            </tr>
                                                                                                                        </ng-template>
                                                                                                                        <ng-template
                                                                                                                            pTemplate="body"
                                                                                                                            let-product>
                                                                                                                            <tr>
                                                                                                                                <td
                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                    <div
                                                                                                                                        class="checkbox-align">
                                                                                                                                        <p-checkbox
                                                                                                                                            _ngcontent-egj-c329=""
                                                                                                                                            styleclass="custom-checkbox"
                                                                                                                                            binary="true"
                                                                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                            <div
                                                                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                                <div
                                                                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                                                                    <input
                                                                                                                                                        type="checkbox"
                                                                                                                                                        name="undefined"
                                                                                                                                                        value="undefined">
                                                                                                                                                </div>
                                                                                                                                                <div role="checkbox"
                                                                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                                    <span
                                                                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                                </div>
                                                                                                                                            </div>
                        
                                                                                                                                        </p-checkbox>
                                                                                                                                        <select
                                                                                                                                            class="dropdownSelect"
                                                                                                                                            [(ngModel)]="product.failIfItemsAreNotAvailable"
                                                                                                                                            [ngModelOptions]="{standalone:true}">
                                                                                                                                            <option>
                                                                                                                                                Select
                                                                                                                                            </option>
                                                                                                                                            <option
                                                                                                                                                *ngFor="let item of this.fallAvaialbe;"
                                                                                                                                                value="{{item.value}}">
                                                                                                                                                {{item.label}}
                                                                                                                                            </option>
                                                                                                                                        </select>
                                                                                                                                    </div>
                                                                                                                                </td>
                                                                                                                                <td
                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                    <select
                                                                                                                                        class="dropdownSelect"
                                                                                                                                        [(ngModel)]="product.locationSeqId"
                                                                                                                                        [ngModelOptions]="{standalone:true}">
                                                                                                                                        <option>
                                                                                                                                            Select
                                                                                                                                        </option>
                                                                                                                                        <option
                                                                                                                                            *ngFor="let item of product.locationSeqId;"
                                                                                                                                            value="{{item.locationSeqId}}">
                                                                                                                                            {{item.locationSeqId}}
                                                                                                                                        </option>
                                                                                                                                    </select>
                                                                                                                                </td>
                                                                                                                                <td
                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                    <select
                                                                                                                                        class="dropdownSelect"
                                                                                                                                        [(ngModel)]="product.secondaryLocationSeqId"
                                                                                                                                        [ngModelOptions]="{standalone:true}">
                                                                                                                                        <option>
                                                                                                                                            Select
                                                                                                                                        </option>
                                                                                                                                        <option
                                                                                                                                            *ngFor="let item of product.secondaryLocationSeqId;"
                                                                                                                                            value="{{item.locationSeqId}}">
                                                                                                                                            {{item.locationSeqId}}
                                                                                                                                        </option>
                                                                                                                                    </select>
                                                                                                                                </td>
                                                                                                                                <td
                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                    <input
                                                                                                                                        type="text"
                                                                                                                                        style="width: 66%;  height: 5%!important;" [(ngModel)]="product.lotId"
                                                                                                                                        [ngModelOptions]="{standalone: true}" />
                                                                                                                                </td>
                                                                                                                                <td
                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                    {{product.workEffortId}}
                                                                                                                                </td>
                                                                                                                                <td
                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                    {{product.internalName}}
                                                                                                                                </td>
                                                                                                                                <td
                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                    {{product.estimatedQuantity}}
                                                                                                                                </td>
                                                                                                                                <td
                                                                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                                                                    {{product.issuedQuantity}}
                                                                                                                                </td>
                        
                        
                        
                        
                                                                                                                            </tr>
                                                                                                                        </ng-template>
                                                                                                                        <ng-template
                                                                                                                            pTemplate="emptymessage"
                                                                                                                            let-columns>
                                                                                                                            <tr>
                                                                                                                                <td>
                                                                                                                                    <span
                                                                                                                                        class="text-center">
                                                                                                                                        <h4
                                                                                                                                            class="ml-2">
                                                                                                                                            No
                                                                                                                                            Record
                                                                                                                                            Found
                                                                                                                                        </h4>
                                                                                                                                    </span>
                                                                                                                                </td>
                                                                                                                            </tr>
                                                                                                                        </ng-template>
                                                                                                                    </p-table>
                                                                                                                    <p class="paginate_data">
                                                                                                                        View per page
                        
                                                                                                                    </p>
                                                                                                                </div>
                                                                                                            </div>
                                                                                                            <button _ngcontent-cta-c128=""
                                                                                                                style="font-size: 12px!important;margin-left: 80%;"
                                                                                                                type="submit"
                                                                                                                *ngIf="materialsRequiredByTheRunningTask && materialsRequiredByTheRunningTask.length"
                                                                                                                class="btn btn-secondary submit-btn"
                                                                                                                (click)="submitUnusedMaterials();">Submit</button>&nbsp;
                        
                                                                                                        </div>
                                                                                                    </div>
                                                                                                </div>
                                                                                            </div>
                        
                                                                                        </div> -->
                                                                                         <div class="all-users-infomation font-13 font-medium own-user-select" style="display: block!important;">
                                                                                        <div class="w3-card-4 classCard">
                                                                                            <header class="w3-container w3-blue">
                                                                                                <div class="header-tabs">
                                                                                                    <h4 class=" common-styling Hleft">
                                                                                                        Materials Required By The Running Task
                                                                                                    </h4>
                        
                                                                                                </div>
                                                                                            </header>
                        
                                                                                            <div class="w3-container">
                                                                                                <div class="panel-body">
                                                                                                    <div class="all-users-infomation font-13 font-medium own-user-select allUserDetails">
                                                                                                        <div class="card own-account-table">
                                                                                                            <p-table [value]="materialsRequiredByTheRunningTask" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                                                                <ng-template pTemplate="header">
                                                                                                                    <tr style="background: #0d3769;">
                                                                                                                        <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">
                                                                                                                            <div class="checkbox-align" style="color: white;">
                        
                                                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                                                            <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    
                                                                                                                                </p-checkbox>
                        
                        
                        
                                                                                                                                Fail If Items Are Not Available
                                                                                                                                <p-sortIcon field="code"></p-sortIcon>
                                                                                                                            </div>
                                                                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                        </th>
                                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                            <div style="color: white;"> Location Seq ID
                                                                                                                                <p-sortIcon field="name"></p-sortIcon>
                                                                                                                            </div>
                                                                                                                            <div>
                                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                            </div>
                                                                                                                        </th>
                        
                                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                            <div style="color: white;">Secondary Location Seq ID
                                                                                                                                <p-sortIcon field="name"></p-sortIcon>
                                                                                                                            </div>
                                                                                                                            <div>
                                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                            </div>
                                                                                                                        </th>
                                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                            <div style="color: white;">Lot ID
                                                                                                                                <p-sortIcon field="name"></p-sortIcon>
                                                                                                                            </div>
                                                                                                                            <div>
                                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                            </div>
                                                                                                                        </th>
                                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                            <div style="color: white;"> Routing Task ID
                                                                                                                                <p-sortIcon field="name"></p-sortIcon>
                                                                                                                            </div>
                                                                                                                            <div>
                                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                            </div>
                                                                                                                        </th>
                        
                                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                            <div style="color: white;">Product Name
                                                                                                                                <p-sortIcon field="name"></p-sortIcon>
                                                                                                                            </div>
                                                                                                                            <div>
                                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                            </div>
                                                                                                                        </th>
                        
                                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                            <div style="color: white;">Quantity
                                                                                                                                <p-sortIcon field="name"></p-sortIcon>
                                                                                                                            </div>
                                                                                                                            <div>
                                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                            </div>
                                                                                                                        </th>
                        
                                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                            <div style="color: white;">Issued
                                                                                                                                <p-sortIcon field="name"></p-sortIcon>
                                                                                                                            </div>
                                                                                                                            <div>
                                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                            </div>
                                                                                                                        </th>
                                                                                                                        <th [ngStyle]="{'width':'170px'}" pSortableColumn="name">
                                                                                                                            <div style="color: white;">Action
                                                                                                                                <p-sortIcon field="name"></p-sortIcon>
                                                                                                                            </div>
                                                                                                                            <div>
                                                                                                                                <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                                    role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                                                            </div>
                                                                                                                        </th>
                        
                                                                                                                    </tr>
                                                                                                                </ng-template>
                                                                                                                <ng-template pTemplate="body" let-product>
                                                                                                                    <tr>
                                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                                            <div class="checkbox-align">
                                                                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                                                                            <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                                        </div>
                                                                                                                                    </div>
                                                                                                                                    
                                                                                                                                </p-checkbox>
                                                                                                                                <select class="dropdownSelect" [(ngModel)]="product.failIfItemsAreNotAvailable" [ngModelOptions]="{standalone:true}">
                                                                                    <option>Select</option>
                                                                                      <option *ngFor="let item of this.fallAvaialbe;" value="{{item.value}}" >
                                                                                        {{item.label}}
                                                                                      </option>
                                                                                    </select>
                                                                                                                            </div>
                                                                                                                        </td>
                                                                                                                        <td [ngStyle]="{'width':'170px'}"> <select class="dropdownSelect" [(ngModel)]="product.locationSeqId" [ngModelOptions]="{standalone:true}">
                                                                                                    <option>Select</option>
                                                                                                      <option *ngFor="let item of product.locationSeqId;" value="{{item.locationSeqId}}" >
                                                                                                        {{item.locationSeqId}}
                                                                                                      </option>
                                                                                                    </select> </td>
                                                                                                                        <td [ngStyle]="{'width':'170px'}"> <select class="dropdownSelect" [(ngModel)]="product.secondaryLocationSeqId" [ngModelOptions]="{standalone:true}">
                                                                                                    <option>Select</option>
                                                                                                      <option *ngFor="let item of product.secondaryLocationSeqId;" value="{{item.locationSeqId}}" >
                                                                                                        {{item.locationSeqId}}
                                                                                                      </option>
                                                                                                    </select> </td>
                                                                                                                        <td [ngStyle]="{'width':'170px'}">
                                                                                                                            <input type="text" style="width: 66%;
                                                                                                  height: 5%!important;" [(ngModel)]="product.lotId" [ngModelOptions]="{standalone: true}" />                                                                                                    </td>
                                                                                                                        <td [ngStyle]="{'width':'170px'}"> {{product.workEffortId}} </td>
                                                                                                                        <td [ngStyle]="{'width':'170px'}"> {{product.internalName}} </td>
                                                                                                                        <td [ngStyle]="{'width':'170px'}"> {{product.estimatedQuantity}} </td>
                                                                                                                        <td [ngStyle]="{'width':'170px'}">{{product.issuedQuantity}}  </td>
                        
                        
                        
                        
                                                                                                                    </tr>
                                                                                                                </ng-template>
                                                                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                                                                    <tr>
                                                                                                                        <td>
                                                                                                                            <span class="text-center">
                                                                                                          <h4 class="ml-2">No Record Found
                                                                                                          </h4>
                                                                                                      </span>
                                                                                                                        </td>
                                                                                                                    </tr>
                                                                                                                </ng-template>
                                                                                                            </p-table>
                                                                                                            <p class="paginate_data">
                                                                                                                View per page
                        
                                                                                                            </p>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <button _ngcontent-cta-c128="" style="font-size: 12px!important;margin-left: 80%;" type="submit" *ngIf="materialsRequiredByTheRunningTask && materialsRequiredByTheRunningTask.length" class="btn btn-secondary submit-btn" (click)="submitUnusedMaterials();">Submit</button>&nbsp;
                        
                                                                                                </div>
                                                                                            </div>
                                                                                            <br>
                        
                                                                                        </div>
                                                                                    </div> 
                        
                        
                                                                                    </div>
                                                                                </div>
                                    
                                                                            </div>
                                                                        </div>
                                    
                                    
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                
                                                </div>


                                                <div *ngIf="activeCategory==3">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Production Run Tasks

                                                        </h4>
                                                        <span class="edit-buttons">
                                                            <a data-toggle="modal"
                                                                data-target="#exampleModalCenterTask">Create New Production Run Tasks</a></span>

                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select"
                                                            style="display: block!important;">
                                                            <div class="form-group">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="Productiontasks"
                                                                        [paginator]="true" [rows]="rows"
                                                                        scrollWidth="100%"
                                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]"
                                                                        [totalRecords]="total" [scrollable]="true"
                                                                        (sortFunction)="customSort($event)"
                                                                        [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th pSortableColumn="code"
                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                    <div class="checkbox-align"
                                                                                        style="color: white;">

                                                                                        <p-checkbox
                                                                                            _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>

                                                                                        </p-checkbox>

                                                                                        Sequence Num
                                                                                        <p-sortIcon
                                                                                            field="code"></p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th pSortableColumn="name"
                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                    <div style="color: white;"> Routing
                                                                                        Task Name
                                                                                        <p-sortIcon
                                                                                            field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="name"
                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                    <div style="color: white;"> Fixed
                                                                                        Asset
                                                                                        <p-sortIcon
                                                                                            field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="name"
                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                    <div style="color: white;"> Reserv
                                                                                        Persons
                                                                                        <p-sortIcon
                                                                                            field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="category"
                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                    <div style="color: white;"> Start
                                                                                        Date
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="category"
                                                                                    [ngStyle]="{'width':'190px'}">
                                                                                    <div style="color: white;">
                                                                                        Calculated Completion Date
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="category"
                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                    <div style="color: white;">
                                                                                        Estimated Setup Time
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="category"
                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                    <div style="color: white;">
                                                                                        Estimated Total Run Time
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="category"
                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                    <div style="color: white;">Action
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>

                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox
                                                                                            _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>

                                                                                        </p-checkbox>
                                                                                        {{product.sequenceNum}}
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.workEffortName}} </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.fixedAssetId}} </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.reservPersons}} </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.estimatedStartDate|date:'yyyy-MM-dd
                                                                                    hh:mm:ss'}} </td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{product.estimatedCompletionDate|date:'yyyy-MM-dd
                                                                                    hh:mm:ss'}}</td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.estimatedSetupMillis}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{product.estimatedTotalMilliSeconds}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <svg class="hover"
                                                                                        (click)="updateTask(product.workEffortId);"
                                                                                        xmlns="http://www.w3.org/2000/svg"
                                                                                        width="16" height="16"
                                                                                        viewBox="0 0 21.223 21.222">
                                                                                        <g
                                                                                            transform="translate(0 -0.004)">
                                                                                            <g
                                                                                                transform="translate(0 1.52)">
                                                                                                <path
                                                                                                    d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                    transform="translate(0 -34.137)" />
                                                                                            </g>
                                                                                            <g
                                                                                                transform="translate(4.548 0.004)">
                                                                                                <g
                                                                                                    transform="translate(0 0)">
                                                                                                    <path
                                                                                                        d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                        transform="translate(-102.409 -0.004)" />
                                                                                                </g>
                                                                                            </g>
                                                                                        </g>
                                                                                    </svg>
                                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                                        (click)="deleteProductiontasks(product.workEffortId)"
                                                                                        class="ml-20" width="16"
                                                                                        height="16" viewBox="0 0 26 26">
                                                                                        <defs>
                                                                                            <style>
                                                                                                .a {
                                                                                                    fill: #f44336;
                                                                                                }

                                                                                                .b {
                                                                                                    fill: #fafafa;
                                                                                                    margin-left: 20px;
                                                                                                }
                                                                                            </style>
                                                                                        </defs>
                                                                                        <path class="a fill-color"
                                                                                            d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                        <path class="b"
                                                                                            d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                            transform="translate(-147.576 -147.576)" />
                                                                                    </svg>
                                                                                </td>

                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="emptymessage"
                                                                            let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                        <h4 class="ml-2">No Record Found
                                                                                        </h4>
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page

                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div *ngIf="activeCategory==4">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Production Run Materials
                                                        </h4>
                                                        <span class="edit-buttons">
                                                            <a data-toggle="modal"
                                                                data-target="#exampleModalCenterMaterial">Create New  Production Run Materials</a></span>

                                                    </div>
                                                    <div class="panel-body">

                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="w3-card-4 classCard materialCard"
                                                                *ngFor="let material of ProductionMaterial">
                                                                <header class="w3-container w3-blue">
                                                                    <div class="header-tabs">
                                                                        <h4 class=" common-styling Hleft">
                                                                            {{material.workEffortName}}
                                                                            {{material.workEffortId}}
                                                                        </h4>

                                                                    </div>
                                                                </header>
                                                                <div class="form-group">
                                                                    <div class="card own-account-table AllUser">
                                                                        <p-table [value]="material.records"
                                                                            [paginator]="true" [rows]="rows"
                                                                            scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]"
                                                                            [totalRecords]="total" [scrollable]="true"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code"
                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </p-checkbox>




                                                                                            Product ID
                                                                                            <p-sortIcon
                                                                                                field="code"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="name"
                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                        <div style="color: white;">
                                                                                            Internal Name
                                                                                            <p-sortIcon
                                                                                                field="name"></p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="name"
                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                        <div style="color: white;">
                                                                                            Estimated Quantity
                                                                                            <p-sortIcon
                                                                                                field="name"></p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>

                                                                                    <th pSortableColumn="category"
                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                        <div style="color: white;">
                                                                                            Action
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>

                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-activity>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </p-checkbox>
                                                                                            {{activity.productId}}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{activity.productName}} </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{activity.estimatedQuantity}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        <svg class="hover"
                                                                                            (click)="updateMaterial(activity.productId,activity.estimatedQuantity,activity.workEffortId)"
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                            width="16" height="16"
                                                                                            viewBox="0 0 21.223 21.222">
                                                                                            <g
                                                                                                transform="translate(0 -0.004)">
                                                                                                <g
                                                                                                    transform="translate(0 1.52)">
                                                                                                    <path
                                                                                                        d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                        transform="translate(0 -34.137)" />
                                                                                                </g>
                                                                                                <g
                                                                                                    transform="translate(4.548 0.004)">
                                                                                                    <g
                                                                                                        transform="translate(0 0)">
                                                                                                        <path
                                                                                                            d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                            transform="translate(-102.409 -0.004)" />
                                                                                                    </g>
                                                                                                </g>
                                                                                            </g>
                                                                                        </svg>
                                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                                            (click)="deleteProductionMaterial(activity.fromDate,activity.productId,activity.workEffortGoodStdTypeId,activity.workEffortId)"
                                                                                            class="ml-20" width="16"
                                                                                            height="16"
                                                                                            viewBox="0 0 26 26">
                                                                                            <defs>
                                                                                                <style>
                                                                                                    .a {
                                                                                                        fill: #f44336;
                                                                                                    }

                                                                                                    .b {
                                                                                                        fill: #fafafa;
                                                                                                        margin-left: 20px;
                                                                                                    }
                                                                                                </style>
                                                                                            </defs>
                                                                                            <path class="a fill-color"
                                                                                                d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                            <path class="b"
                                                                                                d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                                transform="translate(-147.576 -147.576)" />
                                                                                        </svg>
                                                                                    </td>

                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage"
                                                                                let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No Record
                                                                                                Found
                                                                                            </h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data viewPerPage">
                                                                            View per page

                                                                        </p>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <br>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div *ngIf="activeCategory==5">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Routing Task Fixed Assets
                                                        </h4>
                                                        <span class="edit-buttons">
                                                            <a data-toggle="modal"
                                                                data-target="#exampleModalCenterFixed">Create New  Routing Task Fixed Assets </a></span>

                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select"
                                                            style="display: block!important;">
                                                            <div class="w3-card-4 classCard materialCard"
                                                                *ngFor="let fixedAsset of ProductionFixedAsset">
                                                                <header class="w3-container w3-blue">
                                                                    <div class="header-tabs">
                                                                        <h4 class=" common-styling Hleft">
                                                                            {{fixedAsset.workEffortName}}
                                                                            {{fixedAsset.workEffortId}}
                                                                        </h4>

                                                                    </div>
                                                                </header>
                                                                <div class="form-group">
                                                                    <div class="card own-account-table AllUser">
                                                                        <p-table [value]="fixedAsset.records"
                                                                            [paginator]="true" [rows]="rows"
                                                                            scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]"
                                                                            [totalRecords]="total" [scrollable]="true"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code"
                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </p-checkbox>


                                                                                            Fixed Asset ID
                                                                                            <p-sortIcon
                                                                                                field="code"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="name"
                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                        <div style="color: white;"> From
                                                                                            Date
                                                                                            <p-sortIcon
                                                                                                field="name"></p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="name"
                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                        <div style="color: white;">
                                                                                            Through Date
                                                                                            <p-sortIcon
                                                                                                field="name"></p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="name"
                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                        <div style="color: white;">
                                                                                            Availability
                                                                                            <p-sortIcon
                                                                                                field="name"></p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="name"
                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                        <div style="color: white;">
                                                                                            Allocated Cost
                                                                                            <p-sortIcon
                                                                                                field="name"></p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="name"
                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                        <div style="color: white;">
                                                                                            Comments
                                                                                            <p-sortIcon
                                                                                                field="name"></p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>

                                                                                    <th pSortableColumn="category"
                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                        <div style="color: white;">
                                                                                            Action
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>

                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-activity>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </p-checkbox>
                                                                                            {{activity.fixedAssetId}}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{activity.fromDate|date:'yyyy-MM-dd
                                                                                        hh:mm:ss'}} </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{activity.thruDate|date:'yyyy-MM-dd
                                                                                        hh:mm:ss'}} </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{activity.availabilityStatus}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{activity.allocatedCost}} </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{activity.comments}} </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        <svg class="hover"
                                                                                            (click)="updateFixedAsset(activity.fixedAssetId,activity.fromDate,activity.workEffortId)"
                                                                                            xmlns="http://www.w3.org/2000/svg"
                                                                                            width="16" height="16"
                                                                                            viewBox="0 0 21.223 21.222">
                                                                                            <g
                                                                                                transform="translate(0 -0.004)">
                                                                                                <g
                                                                                                    transform="translate(0 1.52)">
                                                                                                    <path
                                                                                                        d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                        transform="translate(0 -34.137)" />
                                                                                                </g>
                                                                                                <g
                                                                                                    transform="translate(4.548 0.004)">
                                                                                                    <g
                                                                                                        transform="translate(0 0)">
                                                                                                        <path
                                                                                                            d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                            transform="translate(-102.409 -0.004)" />
                                                                                                    </g>
                                                                                                </g>
                                                                                            </g>
                                                                                        </svg>
                                                                                        <svg xmlns="http://www.w3.org/2000/svg"
                                                                                            (click)="deleteProductionFixedAsset(activity.fixedAssetId,activity.fromDate,activity.workEffortId)"
                                                                                            class="ml-20" width="16"
                                                                                            height="16"
                                                                                            viewBox="0 0 26 26">
                                                                                            <defs>
                                                                                                <style>
                                                                                                    .a {
                                                                                                        fill: #f44336;
                                                                                                    }

                                                                                                    .b {
                                                                                                        fill: #fafafa;
                                                                                                        margin-left: 20px;
                                                                                                    }
                                                                                                </style>
                                                                                            </defs>
                                                                                            <path class="a fill-color"
                                                                                                d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                            <path class="b"
                                                                                                d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                                transform="translate(-147.576 -147.576)" />
                                                                                        </svg>
                                                                                    </td>

                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage"
                                                                                let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No Record
                                                                                                Found
                                                                                            </h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data viewPerPage">
                                                                            View per page

                                                                        </p>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div *ngIf="activeCategory==6">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Parties
                                                        </h4>
                                                        <span class="edit-buttons">
                                                            <a data-toggle="modal"
                                                                data-target="#exampleModalCenterParty">Create New Parties</a></span>

                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select"
                                                            style="display: block!important;">
                                                            <div class="form-group">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="assignedTasks" [paginator]="true"
                                                                        [rows]="rows" scrollWidth="100%"
                                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]"
                                                                        [totalRecords]="total" [scrollable]="true"
                                                                        (sortFunction)="customSort($event)"
                                                                        [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th pSortableColumn="code"
                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                    <div class="checkbox-align"
                                                                                        style="color: white;">
                                                                                        <p-checkbox
                                                                                            _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>

                                                                                        </p-checkbox>



                                                                                        Party ID
                                                                                        <p-sortIcon
                                                                                            field="code"></p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th pSortableColumn="name"
                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                    <div style="color: white;"> Role
                                                                                        <p-sortIcon
                                                                                            field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="name"
                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                    <div style="color: white;"> From
                                                                                        Date
                                                                                        <p-sortIcon
                                                                                            field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="name"
                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                    <div style="color: white;"> Through
                                                                                        Date
                                                                                        <p-sortIcon
                                                                                            field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>




                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-activity>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox
                                                                                            _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>

                                                                                        </p-checkbox>
                                                                                        {{activity.partyDesc}}
                                                                                        {{activity.partyDesc}}
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{activity.roleTypeDesc}} </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{activity.fromDate|date:'yyyy-MM-dd
                                                                                    hh:mm:ss'}} </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{activity.thruDate|date:'yyyy-MM-dd
                                                                                    hh:mm:ss'}} </td>


                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="emptymessage"
                                                                            let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                        <h4 class="ml-2">No Record Found
                                                                                        </h4>
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page

                                                                    </p>
                                                                </div>
                                                                <br>
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="assignedTasks" [paginator]="true"
                                                                        [rows]="rows" scrollWidth="100%"
                                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]"
                                                                        [totalRecords]="total" [scrollable]="true"
                                                                        (sortFunction)="customSort($event)"
                                                                        [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th pSortableColumn="code"
                                                                                    [ngStyle]="{'width':'230px'}">
                                                                                    <div class="checkbox-align"
                                                                                        style="color: white;">
                                                                                        <p-checkbox
                                                                                            _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>

                                                                                        </p-checkbox>




                                                                                        Routing Task Name
                                                                                        <p-sortIcon
                                                                                            field="code"></p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th pSortableColumn="name"
                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                    <div style="color: white;"> Party ID
                                                                                        <p-sortIcon
                                                                                            field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="name"
                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                    <div style="color: white;"> Role
                                                                                        <p-sortIcon
                                                                                            field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="name"
                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                    <div style="color: white;"> Status
                                                                                        <p-sortIcon
                                                                                            field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="name"
                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                    <div style="color: white;"> From
                                                                                        <p-sortIcon
                                                                                            field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="name"
                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                    <div style="color: white;"> Through
                                                                                        <p-sortIcon
                                                                                            field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>




                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-activity>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'230px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox
                                                                                            _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>

                                                                                        </p-checkbox>
                                                                                        {{activity.workEffortName}}
                                                                                        {{activity.workEffortId}}
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{activity.partyDesc}} </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{activity.roleTypeDesc}} </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{activity.statusDesc}} </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{activity.fromDate|date:'yyyy-MM-dd
                                                                                    hh:mm:ss'}}</td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{activity.thruDate|date:'yyyy-MM-dd
                                                                                    hh:mm:ss'}} </td>


                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="emptymessage"
                                                                            let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                        <h4 class="ml-2">No Record Found
                                                                                        </h4>
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page

                                                                    </p>
                                                                </div>

                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div *ngIf="activeCategory==7">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Manufacturing Mandatory WorkEfforts
                                                        </h4>

                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select"
                                                            style="display: block!important;">
                                                            <div class="form-group">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="precedingProductionRun"
                                                                        [paginator]="true" [rows]="rows"
                                                                        scrollWidth="100%"
                                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]"
                                                                        [totalRecords]="total" [scrollable]="true"
                                                                        (sortFunction)="customSort($event)"
                                                                        [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th pSortableColumn="code"
                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                    <div class="checkbox-align"
                                                                                        style="color: white;">
                                                                                        <p-checkbox
                                                                                            _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>

                                                                                        </p-checkbox>



                                                                                        ID
                                                                                        <p-sortIcon
                                                                                            field="code"></p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th pSortableColumn="name"
                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                    <div style="color: white;"> Routing
                                                                                        Task Name
                                                                                        <p-sortIcon
                                                                                            field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="name"
                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                    <div style="color: white;"> Qty To
                                                                                        Produce
                                                                                        <p-sortIcon
                                                                                            field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="name"
                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                    <div style="color: white;"> Produced
                                                                                        <p-sortIcon
                                                                                            field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="name"
                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                    <div style="color: white;"> Start
                                                                                        Date
                                                                                        <p-sortIcon
                                                                                            field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="name"
                                                                                    [ngStyle]="{'width':'190px'}">
                                                                                    <div style="color: white;">
                                                                                        Estimated Completion Date
                                                                                        <p-sortIcon
                                                                                            field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="name"
                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                    <div style="color: white;"> Actual
                                                                                        Start Date
                                                                                        <p-sortIcon
                                                                                            field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="name"
                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                    <div style="color: white;">
                                                                                        Completion Date
                                                                                        <p-sortIcon
                                                                                            field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="name"
                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                    <div style="color: white;"> Action
                                                                                        <p-sortIcon
                                                                                            field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>




                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-activity>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox
                                                                                            _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>

                                                                                        </p-checkbox>
                                                                                        {{activity.workEffortId}}
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{activity.workEffortName}} </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{activity.quantityToProduce}}</td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{activity.quantityProduced}} </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{activity.estimatedStartDate|date:'yyyy-MM-dd
                                                                                    hh:mm:ss'}} </td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{activity.estimatedCompletionDate|date:'yyyy-MM-dd
                                                                                    hh:mm:ss'}}</td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{activity.actualStartDate|date:'yyyy-MM-dd
                                                                                    hh:mm:ss'}} </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{activity.actualCompletionDate|date:'yyyy-MM-dd
                                                                                    hh:mm:ss'}} </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                                        (click)="removeRoutingTaskAssoc(activity.fromDate,activity.workEffortAssocTypeId,activity.workEffortId,activity.workEffortIdFrom,activity.workEffortIdTo)"
                                                                                        class="ml-20" width="16"
                                                                                        height="16" viewBox="0 0 26 26">
                                                                                        <defs>
                                                                                            <style>
                                                                                                .a {
                                                                                                    fill: #f44336;
                                                                                                }

                                                                                                .b {
                                                                                                    fill: #fafafa;
                                                                                                    margin-left: 20px;
                                                                                                }
                                                                                            </style>
                                                                                        </defs>
                                                                                        <path class="a fill-color"
                                                                                            d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                        <path class="b"
                                                                                            d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                            transform="translate(-147.576 -147.576)" />
                                                                                    </svg>
                                                                                </td>



                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="emptymessage"
                                                                            let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                        <h4 class="ml-2">No Record Found
                                                                                        </h4>
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page

                                                                    </p>
                                                                </div>
                                                                <br>
                                                                <div class="header-tabs">
                                                                    <h4 class=" common-styling">
                                                                        Manufacturing Dependent WorkEfforts
                                                                    </h4>

                                                                </div>
                                                                <br>
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="succeedingProductionRun"
                                                                        [paginator]="true" [rows]="rows"
                                                                        scrollWidth="100%"
                                                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]"
                                                                        [totalRecords]="total" [scrollable]="true"
                                                                        (sortFunction)="customSort($event)"
                                                                        [customSort]="true">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th pSortableColumn="code"
                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                    <div class="checkbox-align"
                                                                                        style="color: white;">

                                                                                        <p-checkbox
                                                                                            _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>

                                                                                        </p-checkbox>


                                                                                        ID
                                                                                        <p-sortIcon
                                                                                            field="code"></p-sortIcon>
                                                                                    </div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </th>
                                                                                <th pSortableColumn="name"
                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                    <div style="color: white;"> Routing
                                                                                        Task Name
                                                                                        <p-sortIcon
                                                                                            field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="name"
                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                    <div style="color: white;"> Qty To
                                                                                        Produce
                                                                                        <p-sortIcon
                                                                                            field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="name"
                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                    <div style="color: white;"> Produced
                                                                                        <p-sortIcon
                                                                                            field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="name"
                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                    <div style="color: white;"> Start
                                                                                        Date
                                                                                        <p-sortIcon
                                                                                            field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="name"
                                                                                    [ngStyle]="{'width':'190px'}">
                                                                                    <div style="color: white;">
                                                                                        Estimated Completion Date
                                                                                        <p-sortIcon
                                                                                            field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="name"
                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                    <div style="color: white;"> Actual
                                                                                        Start Date
                                                                                        <p-sortIcon
                                                                                            field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="name"
                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                    <div style="color: white;">
                                                                                        Completion Date
                                                                                        <p-sortIcon
                                                                                            field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>
                                                                                <th pSortableColumn="name"
                                                                                    [ngStyle]="{'width':'170px'}">
                                                                                    <div style="color: white;"> Action
                                                                                        <p-sortIcon
                                                                                            field="name"></p-sortIcon>
                                                                                    </div>
                                                                                    <div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </div>
                                                                                </th>




                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-activity>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <div class="checkbox-align">
                                                                                        <p-checkbox
                                                                                            _ngcontent-egj-c329=""
                                                                                            styleclass="custom-checkbox"
                                                                                            binary="true"
                                                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div
                                                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div
                                                                                                    class="ui-helper-hidden-accessible">
                                                                                                    <input
                                                                                                        type="checkbox"
                                                                                                        name="undefined"
                                                                                                        value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox"
                                                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span
                                                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>

                                                                                        </p-checkbox>
                                                                                        {{activity.workEffortIdTo}}
                                                                                    </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{activity.workEffortName}} </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{activity.quantityToProduce}}</td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{activity.quantityProduced}} </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{activity.estimatedStartDate|date:'yyyy-MM-dd
                                                                                    hh:mm:ss'}} </td>
                                                                                <td [ngStyle]="{'width':'190px'}">
                                                                                    {{activity.estimatedCompletionDate|date:'yyyy-MM-dd
                                                                                    hh:mm:ss'}}</td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{activity.actualStartDate|date:'yyyy-MM-dd
                                                                                    hh:mm:ss'}} </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    {{activity.actualCompletionDate|date:'yyyy-MM-dd
                                                                                    hh:mm:ss'}} </td>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <svg xmlns="http://www.w3.org/2000/svg"
                                                                                        (click)="removeRoutingTaskAssoc(activity.fromDate,activity.workEffortAssocTypeId,activity.workEffortId,activity.workEffortIdFrom,activity.workEffortIdTo)"
                                                                                        class="ml-20" width="16"
                                                                                        height="16" viewBox="0 0 26 26">
                                                                                        <defs>
                                                                                            <style>
                                                                                                .a {
                                                                                                    fill: #f44336;
                                                                                                }

                                                                                                .b {
                                                                                                    fill: #fafafa;
                                                                                                    margin-left: 20px;
                                                                                                }
                                                                                            </style>
                                                                                        </defs>
                                                                                        <path class="a fill-color"
                                                                                            d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                        <path class="b"
                                                                                            d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                            transform="translate(-147.576 -147.576)" />
                                                                                    </svg>
                                                                                </td>



                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="emptymessage"
                                                                            let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                        <h4 class="ml-2">No Record Found
                                                                                        </h4>
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page

                                                                    </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div *ngIf="activeCategory==8">
                                                    <div class="header-tabs" *ngIf="this.productionRunData">
                                                        <h4 class=" common-styling">
                                                            Import Content From Product
                                                            {{this.productionRunData.productId}}


                                                        </h4>


                                                    </div>
                                                    <br>
                                                    <div>
                                                        <!--  <button type="button" style="width: 132px;"
                                    class="btn btn-outline-secondary bg-brown ">Export as CSV</button>
                                &nbsp; <button type="button" style="width: 132px;"
                                    class="btn btn-danger" routerLinkActive="active">Export as PDF</button> -->
                                                    </div>
                                                    <div class="all-users-infomation font-13 font-medium own-user-select"
                                                        style="display: block!important;">
                                                        <div class="form-group">
                                                            <div class="card own-account-table">
                                                                <p-table [value]="productionRunContentsSaveData"
                                                                    [paginator]="true" [rows]="rows" scrollWidth="100%"
                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]"
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                    (sortFunction)="customSort($event)"
                                                                    [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th pSortableColumn="code"
                                                                                [ngStyle]="{'width':'170px'}">
                                                                                <div class="checkbox-align"
                                                                                    style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>

                                                                                    </p-checkbox>

                                                                                    Content ID
                                                                                    <p-sortIcon field="code">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;"> Dr Data
                                                                                    Resource ID
                                                                                    <p-sortIcon
                                                                                        field="name"></p-sortIcon>
                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'200px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;"> Work Effort
                                                                                    Content Type ID
                                                                                    <p-sortIcon
                                                                                        field="name"></p-sortIcon>
                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;"> Content Type
                                                                                    ID
                                                                                    <p-sortIcon
                                                                                        field="name"></p-sortIcon>
                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;"> Content Name
                                                                                    <p-sortIcon
                                                                                        field="name"></p-sortIcon>
                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;"> Description
                                                                                    <p-sortIcon
                                                                                        field="name"></p-sortIcon>
                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;"> Locale
                                                                                    String
                                                                                    <p-sortIcon
                                                                                        field="name"></p-sortIcon>
                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;"> Dr Mime Type
                                                                                    ID
                                                                                    <p-sortIcon
                                                                                        field="name"></p-sortIcon>
                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;"> Content
                                                                                    <p-sortIcon
                                                                                        field="name"></p-sortIcon>
                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;">Action
                                                                                    <p-sortIcon
                                                                                        field="name"></p-sortIcon>

                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </div>
                                                                            </th>





                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>

                                                                                    </p-checkbox>
                                                                                    <span
                                                                                        style="cursor: pointer;"></span>
                                                                                    {{product.contentId}}
                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.drDataResourceId}} </td>
                                                                            <td [ngStyle]="{'width':'200px'}">
                                                                                {{product.EffortContentType}} </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.contentTypeId}} </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.contentName}}</td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.description}}</td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.localeString}} </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.drMimeTypeId}} </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.drObjectInfo}} </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <svg xmlns="http://www.w3.org/2000/svg"
                                                                                    (click)="deleteProductionJobContentProductId(product.contentId,product.fromDate,product.workEffortContentTypeId,product.workEffortId)"
                                                                                    class="ml-20" width="16" height="16"
                                                                                    viewBox="0 0 26 26">
                                                                                    <defs>
                                                                                        <style>
                                                                                            .a {
                                                                                                fill: #f44336;
                                                                                            }

                                                                                            .b {
                                                                                                fill: #fafafa;
                                                                                                margin-left: 20px;
                                                                                            }
                                                                                        </style>
                                                                                    </defs>
                                                                                    <path class="a fill-color"
                                                                                        d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                    <path class="b"
                                                                                        d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                        transform="translate(-147.576 -147.576)" />
                                                                                </svg>

                                                                            </td>









                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                    <h4 class="ml-2">No Record Found
                                                                                    </h4>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                                <p class="paginate_data">
                                                                    View per page

                                                                </p>
                                                            </div>
                                                        </div>

                                                    </div>
                                                    <div class="panel-body">
                                                        <form class="w-100" [formGroup]="contentForm">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select"
                                                                style="display: block!important;">
                                                                <div class="col-lg-12">
                                                                    <div class="row">

                                                                        <div class="col-lg-3 form-group formRight">
                                                                            <label for="exampleInputEmail1">Content
                                                                                Locale</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="email" class="form-control"
                                                                                aria-describedby="emailHelp"
                                                                                formControlName="ContentLocale"
                                                                                placeholder="Enter Content Locale">


                                                                        </div>
                                                                        <div class="col-lg-3 form-group formRightA">
                                                                            <label for="exampleInputEmail1">Party
                                                                                ID</label>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true"
                                                                                formControlName="PartyID"
                                                                                [options]="partyIdArray"
                                                                                optionlabel="label"
                                                                                placeholder="Enter Party Id">

                                                                            </p-dropdown>
                                                                        </div>

                                                                    </div>
                                                                </div>


                                                            </div>
                                                        </form>
                                                        <div class="col-lg-12 col-12 main-submit-button" style="
                                                      margin-left: -30%;    "><button _ngcontent-cta-c128="" type="submit" class="btn btn-secondary submit-btn"
                                                                (click)="getproductionRunId();">Find</button>&nbsp;
                                                            <button _ngcontent-cta-c128="" type="submit"
                                                                class="btn btn-danger" (click)="reset()">Reset</button>
                                                        </div>
                                                    </div><br>
                                                    <div class="all-users-infomation font-13 font-medium own-user-select"
                                                        style="display: block!important;">
                                                        <div class="form-group">
                                                            <div class="card own-account-table">
                                                                <p-table [value]="productionJobContents"
                                                                    [paginator]="true" [rows]="rows" scrollWidth="100%"
                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]"
                                                                    [totalRecords]="total" [scrollable]="true"
                                                                    (sortFunction)="customSort($event)"
                                                                    [customSort]="true">
                                                                    <ng-template pTemplate="header">
                                                                        <tr style="background: #0d3769;">
                                                                            <th pSortableColumn="code"
                                                                                [ngStyle]="{'width':'170px'}">
                                                                                <div class="checkbox-align"
                                                                                    style="color: white;">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>

                                                                                    </p-checkbox>

                                                                                    Content ID
                                                                                    <p-sortIcon field="code">
                                                                                    </p-sortIcon>
                                                                                </div>
                                                                                <input
                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                    value="" autocomplete="off"
                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                    role="textbox" aria-disabled="false"
                                                                                    aria-readonly="false">
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;"> Dr Data
                                                                                    Resource ID
                                                                                    <p-sortIcon
                                                                                        field="name"></p-sortIcon>
                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;"> Content Type
                                                                                    ID
                                                                                    <p-sortIcon
                                                                                        field="name"></p-sortIcon>
                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;"> Content Name
                                                                                    <p-sortIcon
                                                                                        field="name"></p-sortIcon>
                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;"> Description
                                                                                    <p-sortIcon
                                                                                        field="name"></p-sortIcon>
                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;"> Locale
                                                                                    String
                                                                                    <p-sortIcon
                                                                                        field="name"></p-sortIcon>
                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;"> Dr Mime Type
                                                                                    ID
                                                                                    <p-sortIcon
                                                                                        field="name"></p-sortIcon>
                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;"> Content
                                                                                    <p-sortIcon
                                                                                        field="name"></p-sortIcon>
                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </div>
                                                                            </th>
                                                                            <th [ngStyle]="{'width':'170px'}"
                                                                                pSortableColumn="name">
                                                                                <div style="color: white;"> Work Effort
                                                                                    Content Type ID - Selected
                                                                                    <p-sortIcon
                                                                                        field="name"></p-sortIcon>
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        (click)="markCheckJobAll($event)"
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>

                                                                                    </p-checkbox>
                                                                                </div>
                                                                                <div>
                                                                                    <input
                                                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                        value="" autocomplete="off"
                                                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox"
                                                                                        aria-disabled="false"
                                                                                        aria-readonly="false">
                                                                                </div>
                                                                            </th>





                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="body" let-product>
                                                                        <tr>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <div class="checkbox-align">
                                                                                    <p-checkbox _ngcontent-egj-c329=""
                                                                                        styleclass="custom-checkbox"
                                                                                        binary="true"
                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                        <div
                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                            <div
                                                                                                class="ui-helper-hidden-accessible">
                                                                                                <input type="checkbox"
                                                                                                    name="undefined"
                                                                                                    value="undefined">
                                                                                            </div>
                                                                                            <div role="checkbox"
                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                <span
                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                            </div>
                                                                                        </div>

                                                                                    </p-checkbox>
                                                                                    <span
                                                                                        style="cursor: pointer;"></span>
                                                                                    {{product.contentId}}
                                                                                </div>
                                                                            </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.drDataResourceId}} </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.contentType}} </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.contentName}}</td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.description}}</td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.localeString}} </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.drMimeTypeId}} </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.drObjectInfo}} </td>
                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                {{product.drObjectInfo}} </td>

                                                                            <td [ngStyle]="{'width':'170px'}">
                                                                                <select class="dropdownSelect"
                                                                                    [(ngModel)]="product.workEffortContentTypeId"
                                                                                    [ngModelOptions]="{standalone:true}">
                                                                                    <option>Select</option>
                                                                                    <option
                                                                                        *ngFor="let item of this.workEffortContentTypeIdArray;"
                                                                                        value="{{item.value}}">
                                                                                        {{item.label}}
                                                                                    </option>
                                                                                </select>
                                                                                <p-checkbox _ngcontent-egj-c329=""
                                                                                    (click)="oneSelectContent(product)"
                                                                                    styleclass="custom-checkbox"
                                                                                    binary="true"
                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div
                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div
                                                                                            class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox"
                                                                                                name="undefined"
                                                                                                value="undefined">
                                                                                        </div>
                                                                                        <div role="checkbox"
                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span
                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>

                                                                                </p-checkbox>
                                                                            </td>


                                                                        </tr>
                                                                    </ng-template>
                                                                    <ng-template pTemplate="emptymessage" let-columns>
                                                                        <tr>
                                                                            <td>
                                                                                <span class="text-center">
                                                                                    <h4 class="ml-2">No Record Found
                                                                                    </h4>
                                                                                </span>
                                                                            </td>
                                                                        </tr>
                                                                    </ng-template>
                                                                </p-table>
                                                                <p class="paginate_data">
                                                                    View per page

                                                                </p>
                                                            </div>
                                                        </div>
                                                        <button _ngcontent-cta-c128=""
                                                            style="font-size: 12px!important;margin-left: 85%;"
                                                            type="submit" (click)="postProductionJobContent();"
                                                            class="btn btn-secondary submit-btn">Submit</button>&nbsp;
                                                    </div>
                                                </div>
                                                <div *ngIf="activeCategory==9">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Actual Costs
                                                        </h4>

                                                    </div>
                                                    <div class="panel-body">

                                                        <div class="all-users-infomation font-13 font-medium own-user-select"
                                                            style="display: block!important;">
                                                            <div class="w3-card-4 classCard materialCard"
                                                                *ngFor="let cost of ProductionJobActualCosts">
                                                                <header class="w3-container w3-blue">
                                                                    <div class="header-tabs">
                                                                        <h4 class=" common-styling Hleft">
                                                                            Actual Costs {{cost.task.workEffortName}}
                                                                            {{cost.task.workEffortId}}
                                                                        </h4>

                                                                    </div>
                                                                </header>
                                                                <div class="form-group">
                                                                    <div class="card own-account-table AllUser">
                                                                        <p-table [value]="cost.taskCosts"
                                                                            [paginator]="true" [rows]="rows"
                                                                            scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]"
                                                                            [totalRecords]="total" [scrollable]="true"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code"
                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">


                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </p-checkbox>


                                                                                            Cost Component ID
                                                                                            <p-sortIcon
                                                                                                field="code"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="name"
                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                        <div style="color: white;"> Cost
                                                                                            Component Type ID
                                                                                            <p-sortIcon
                                                                                                field="name"></p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="name"
                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                        <div style="color: white;">
                                                                                            Fixed Asset ID
                                                                                            <p-sortIcon
                                                                                                field="name"></p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>

                                                                                    <th pSortableColumn="category"
                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                        <div style="color: white;"> Cost
                                                                                            Component Calc ID
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category"
                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                        <div style="color: white;"> From
                                                                                            Date
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category"
                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                        <div style="color: white;">
                                                                                            Through Date
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category"
                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                        <div style="color: white;"> Cost
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category"
                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                        <div style="color: white;"> Cost
                                                                                            Uom ID
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>

                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-activity>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </p-checkbox>
                                                                                            {{activity.costComponentId}}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{activity.costComponentTypeDesc}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{activity.fixedAssetId}} </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{activity.costComponentCalcId}}
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{activity.fromDate|date:'yyyy-MM-dd  hh:mm:ss'}} </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{activity.thruDate|date:'yyyy-MM-dd   hh:mm:ss'}} </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{activity.cost}} </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{activity.costUomId}} </td>

                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage"
                                                                                let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No Record
                                                                                                Found
                                                                                            </h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data viewPerPage">
                                                                            View per page

                                                                        </p>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <br>
                                                        </div>
                                                    </div>

                                                </div>
                                                <div *ngIf="activeCategory==10">
                                                    <div class="header-tabs">
                                                        <h4 class=" common-styling">
                                                            Actual Materials
                                                        </h4>
                                                        <span class="edit-buttons">
                                                            <a (click)="createActualMaterial()">Create New Actual Materials </a></span>

                                                    </div>
                                                    <div class="panel-body">

                                                        <div
                                                            class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="w3-card-4 classCard materialCard"
                                                                *ngFor="let material of actualMaterial">
                                                                <header class="w3-container w3-blue">
                                                                    <div class="header-tabs">
                                                                        <h4 class=" common-styling Hleft">
                                                                            {{material.workEffortName}}
                                                                            {{material.workEffortId}}
                                                                        </h4>

                                                                    </div>
                                                                </header>
                                                                <div class="form-group">
                                                                    <div class="card own-account-table AllUser">
                                                                        <p-table [value]="material.records"
                                                                            [paginator]="true" [rows]="rows"
                                                                            scrollWidth="100%"
                                                                            [rowsPerPageOptions]="[10,25,50, 100,200,300,400,500]"
                                                                            [totalRecords]="total" [scrollable]="true"
                                                                            (sortFunction)="customSort($event)"
                                                                            [customSort]="true">
                                                                            <ng-template pTemplate="header">
                                                                                <tr style="background: #0d3769;">
                                                                                    <th pSortableColumn="code"
                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                        <div class="checkbox-align"
                                                                                            style="color: white;">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </p-checkbox>




                                                                                            Inventory Item ID
                                                                                            <p-sortIcon
                                                                                                field="code"></p-sortIcon>
                                                                                        </div>
                                                                                        <input
                                                                                            id="j_idt727:j_idt728:j_idt731:filter"
                                                                                            name="j_idt727:j_idt728:j_idt731:filter"
                                                                                            class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                            value="" autocomplete="off"
                                                                                            aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                            role="textbox"
                                                                                            aria-disabled="false"
                                                                                            aria-readonly="false">
                                                                                    </th>
                                                                                    <th pSortableColumn="name"
                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                        <div style="color: white;">
                                                                                            Product ID
                                                                                            <p-sortIcon
                                                                                                field="name"></p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="name"
                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                        <div style="color: white;">
                                                                                            Quantity
                                                                                            <p-sortIcon
                                                                                                field="name"></p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>

                                                                                    <th pSortableColumn="category"
                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                        <div style="color: white;"> Unit
                                                                                            Cost
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category"
                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                        <div style="color: white;">
                                                                                            Reason Enum ID
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>
                                                                                    <th pSortableColumn="category"
                                                                                        [ngStyle]="{'width':'170px'}">
                                                                                        <div style="color: white;">
                                                                                            Description
                                                                                            <p-sortIcon
                                                                                                field="category">
                                                                                            </p-sortIcon>
                                                                                        </div>
                                                                                        <div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </div>
                                                                                    </th>

                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="body" let-activity>
                                                                                <tr>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        <div class="checkbox-align">
                                                                                            <p-checkbox
                                                                                                _ngcontent-egj-c329=""
                                                                                                styleclass="custom-checkbox"
                                                                                                binary="true"
                                                                                                class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                <div
                                                                                                    class="custom-checkbox ui-chkbox ui-widget">
                                                                                                    <div
                                                                                                        class="ui-helper-hidden-accessible">
                                                                                                        <input
                                                                                                            type="checkbox"
                                                                                                            name="undefined"
                                                                                                            value="undefined">
                                                                                                    </div>
                                                                                                    <div role="checkbox"
                                                                                                        class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                        <span
                                                                                                            class="ui-chkbox-icon ui-clickable"></span>
                                                                                                    </div>
                                                                                                </div>

                                                                                            </p-checkbox>
                                                                                            {{activity.inventoryItemId}}
                                                                                        </div>
                                                                                    </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{activity.productId}} </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{activity.quantity}} </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{activity.unitCost| number :
                                                                                        '1.2-2'}} </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{activity.reasonEnumId}} </td>
                                                                                    <td [ngStyle]="{'width':'170px'}">
                                                                                        {{activity.description}} </td>


                                                                                </tr>
                                                                            </ng-template>
                                                                            <ng-template pTemplate="emptymessage"
                                                                                let-columns>
                                                                                <tr>
                                                                                    <td>
                                                                                        <span class="text-center">
                                                                                            <h4 class="ml-2">No Record
                                                                                                Found
                                                                                            </h4>
                                                                                        </span>
                                                                                    </td>
                                                                                </tr>
                                                                            </ng-template>
                                                                        </p-table>
                                                                        <p class="paginate_data viewPerPage">
                                                                            View per page

                                                                        </p>
                                                                    </div>
                                                                </div>

                                                            </div>
                                                            <br>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>
                                    </div>


                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
            <!-- <div class="main-submit-button">
                    <button type="submit" class="btn btn-secondary submit-btn">Create New Lead</button>
                </div> -->
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Update JobShop</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="!this.show"><a>
                                        Add JobShop

                                    </a></li>
                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="this.show"><a>
                                        Update JobShop

                                    </a></li>



                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createJob">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRightA">
                                                        <label for="exampleInputEmail1">
                                                            Product ID<span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3" *ngIf="!this.show">
                                                        <p-dropdown placeholder="Enter Product ID" filter="true"
                                                            formControlName="productId" [options]="productIdArray"
                                                            optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-3" *ngIf="this.show">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Quantity"
                                                            formControlName="productId" readonly>
                                                    </div>
                                                    <div class="col-lg-3 form-group formRightA">
                                                        <label for="exampleInputEmail1">
                                                            Quantity<span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Quantity"
                                                            formControlName="Quantity">
                                                    </div>



                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRightA" *ngIf="this.show">
                                                        <label for="exampleInputEmail1">
                                                            Manufacturer ID</label>
                                                    </div>
                                                    <div class="col-lg-3" *ngIf="this.show">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Quantity"
                                                            formControlName="ManufacturerID" readonly>
                                                    </div>
                                                    <div class="col-lg-3 form-group formRightA" *ngIf="this.show">
                                                        <label for="exampleInputEmail1">
                                                            Current Status ID</label>
                                                    </div>
                                                    <div class="col-lg-3" *ngIf="this.show">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Quantity"
                                                            formControlName="statusId" readonly>


                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRightA">
                                                        <label for="exampleInputEmail1">
                                                            Start Date<span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Quantity"
                                                            formControlName="startDate">
                                                    </div>
                                                    <div class="col-lg-3 form-group formRightA" *ngIf="this.show">
                                                        <label for="exampleInputEmail1">
                                                            Calculated Completion Date</label>
                                                    </div>
                                                    <div class="col-lg-3" *ngIf="this.show">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Quantity"
                                                            formControlName="CalculatedCompletionDate" readonly>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRightA">
                                                        <label for="exampleInputEmail1">
                                                            Facility ID<span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Facility ID" filter="true"
                                                            formControlName="FacilityID" [options]="facilityIdArray"
                                                            optionlabel="label">

                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group formRightA" *ngIf="!this.show">
                                                        <label for="exampleInputEmail1">
                                                            Routing ID</label>
                                                    </div>
                                                    <div class="col-lg-3" *ngIf="!this.show">
                                                        <p-dropdown placeholder="Enter Routing ID" filter="true"
                                                            formControlName="RoutingID" [options]="routingIdArray"
                                                            optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRightA">
                                                        <label for="exampleInputEmail1">
                                                            Production Run Name</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Production Run Name"
                                                            formControlName="ProductionRunName">

                                                    </div>
                                                    <div class="col-lg-3 form-group formRightA">
                                                        <label for="exampleInputEmail1">
                                                            Description</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Description"
                                                            formControlName="Description">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRightA" *ngIf="!this.show">
                                                        <label for="exampleInputEmail1">
                                                            Create Dependent Production Runs</label>
                                                    </div>
                                                    <div class="col-lg-3" *ngIf="!this.show">
                                                        <p-dropdown placeholder="Enter Create Dependent Production Runs"
                                                            filter="true" formControlName="dependent"
                                                            [options]="createDependent" optionlabel="label">

                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-2"></div>

                                                </div>
                                            </div>




                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: -2% !important;">

                                        <button type="submit" *ngIf="!this.show" (click)="onSubmit()"
                                            class="btn btn-secondary submit-btn">Save</button>

                                        <button type="submit" *ngIf="this.show" (click)="onUpdate()"
                                            class="btn btn-secondary submit-btn">Update</button>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>

        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenterTask" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTask"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Production Run Tasks</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebuttonA>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategoryTask==2?'active':''" *ngIf="!this.showTask"><a>
                                        Add Production Run Tasks
                                    </a></li>
                                <li [ngClass]="activeCategoryTask==2?'active':''" *ngIf="this.showTask"><a>
                                        Update Production Run Tasks
                                    </a></li>


                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createJobTaskForm">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRightAB">
                                                        <label for="exampleInputEmail1">
                                                            Routing Task ID<span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2" *ngIf="!this.showTask">
                                                        <p-dropdown placeholder="Enter Routing Task ID" filter="true"
                                                            formControlName="routingtaskid" [options]="taskListArray"
                                                            optionlabel="label">

                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-2" *ngIf="this.showTask">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Sequence Num"
                                                            formControlName="routingtaskid" readonly>


                                                    </div>
                                                    <div class="col-lg-3 form-group formRightA">
                                                        <label for="exampleInputEmail1">
                                                            Sequence Num<span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Sequence Num"
                                                            formControlName="SequenceNum">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRightAB">
                                                        <label for="exampleInputEmail1">
                                                            Routing Task Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Routing Task Name"
                                                            formControlName="RoutingTaskName">

                                                    </div>
                                                    <div class="col-lg-3 form-group formRightA">
                                                        <label for="exampleInputEmail1">
                                                            Description</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Description"
                                                            formControlName="Description">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRightAB">
                                                        <label for="exampleInputEmail1">
                                                            Reserv Persons</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Reserv Persons"
                                                            formControlName="ReservPersons">

                                                    </div>
                                                    <div class="col-lg-3 form-group formRightA">
                                                        <label for="exampleInputEmail1">
                                                            Start Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Sequence Num"
                                                            formControlName="StartDate">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRightAB">
                                                        <label for="exampleInputEmail1">
                                                            Calculated Completion Date</label>
                                                    </div>
                                                    <div class="col-lg-2" *ngIf="!this.showTask">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Calculated Completion Date"
                                                            formControlName="CalculatedCompletionDate">

                                                    </div>
                                                    <div class="col-lg-2" *ngIf="this.showTask">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Calculated Completion Date"
                                                            formControlName="CalculatedCompletionDate" readonly>


                                                    </div>
                                                    <div class="col-lg-3 form-group formRightA">
                                                        <label for="exampleInputEmail1">
                                                            Estimated Setup Time</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Estimated Setup Time"
                                                            formControlName="EstimatedSetupTime">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRightAB">
                                                        <label for="exampleInputEmail1">
                                                            Estimated Unit Run Time</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Estimated Unit Run Time"
                                                            formControlName="EstimatedUnitRunTime">


                                                    </div>
                                                    <div class="col-lg-2"></div>

                                                </div>
                                            </div>



                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: -21%;">

                                        <button type="submit" *ngIf="!this.showTask" (click)="onSubmit()"
                                            class="btn btn-secondary submit-btn">Save</button>
                                        <button type="submit" *ngIf="this.showTask" (click)="onUpdateJobTask()"
                                            class="btn btn-secondary submit-btn">Update</button>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>

        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenterMaterial" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterMaterial" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Production Run Components</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebuttonB>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategoryTask==2?'active':''" *ngIf="!this.showMaterial"><a>
                                        Add Production Run Components
                                    </a></li>
                                <li [ngClass]="activeCategoryTask==2?'active':''" *ngIf="this.showMaterial"><a>
                                        Update Production Run Components
                                    </a></li>



                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createJobMaterailJob">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRightCa">
                                                        <label for="exampleInputEmail1">
                                                            Product ID<span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2" *ngIf="!this.showMaterial">
                                                        <p-dropdown placeholder="Enter Product ID" filter="true"
                                                            formControlName="ProductID" [options]="productListArray"
                                                            optionlabel="label">

                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-2" *ngIf="this.showMaterial">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Estimated Quantity"
                                                            formControlName="ProductID" readonly>

                                                    </div>
                                                    <div class="col-lg-3 form-group formRightA">
                                                        <label for="exampleInputEmail1">
                                                            Estimated Quantity<span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Estimated Quantity"
                                                            formControlName="EstimatedQuantity">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRightCa">
                                                        <label for="exampleInputEmail1">
                                                            Routing Task<span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2" *ngIf="!this.showMaterial">
                                                        <p-dropdown placeholder="Enter Routing Task" filter="true"
                                                            formControlName="RoutingTask"
                                                            [options]="routingTaskListArray" optionlabel="label">

                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-2" *ngIf="this.showMaterial">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Estimated Quantity"
                                                            formControlName="RoutingTask" readonly>

                                                    </div>
                                                    <div class="col-lg-2"></div>

                                                </div>
                                            </div>







                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: 61%;">

                                        <button type="submit" *ngIf="!this.showMaterial" (click)="onSubmitMaterial()"
                                            class="btn btn-secondary submit-btn">Save</button>
                                        <button type="submit" *ngIf="this.showMaterial" (click)="onUpdateMateril()"
                                            class="btn btn-secondary submit-btn">Update</button>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>

        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenterFixed" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterFixed" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Routing Task Fixed Assets</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebuttonC>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategoryTask==2?'active':''" *ngIf="!this.showFixed"><a>
                                        Add Routing Task Fixed Assets
                                    </a></li>
                                <li [ngClass]="activeCategoryTask==2?'active':''" *ngIf="this.showFixed"><a>
                                        Update Routing Task Fixed Assets
                                    </a></li>



                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createJobFixed">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRightFX">
                                                        <label for="exampleInputEmail1">
                                                            Routing Task<span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2" *ngIf="!this.showFixed">
                                                        <p-dropdown placeholder="Enter Routing Task" filter="true"
                                                            formControlName="RoutingTask"
                                                            [options]="routingTaskListArray" optionlabel="label">

                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-2" *ngIf="this.showFixed">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Sequence Num"
                                                            formControlName="RoutingTask" readonly>

                                                    </div>
                                                    <div class="col-lg-3 form-group formRightA">
                                                        <label for="exampleInputEmail1">
                                                            Fixed Asset<span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2" *ngIf="!this.showFixed">
                                                        <p-dropdown placeholder="Enter Fixed Asset" filter="true"
                                                            formControlName="FixedAsset" [options]="getFixedAssetsArray"
                                                            optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2" *ngIf="this.showFixed">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Sequence Num"
                                                            formControlName="FixedAsset" readonly>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRightFX">
                                                        <label for="exampleInputEmail1">
                                                            From Date</label>
                                                    </div>
                                                    <div class="col-lg-2" *ngIf="!this.showFixed">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Sequence Num" formControlName="FromDate">


                                                    </div>
                                                    <div class="col-lg-2" *ngIf="this.showFixed">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Sequence Num" formControlName="FromDate"
                                                            readonly>


                                                    </div>
                                                    <div class="col-lg-3 form-group formRightA">
                                                        <label for="exampleInputEmail1">
                                                            Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Routing Task Name"
                                                            formControlName="ThroughDate">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRightFX">
                                                        <label for="exampleInputEmail1">
                                                            Availability</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Availability" filter="true"
                                                            formControlName="Availability" [options]="statusIdArray"
                                                            optionlabel="label">

                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group formRightA">
                                                        <label for="exampleInputEmail1">
                                                            Allocated Cost</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Allocated Cost"
                                                            formControlName="AllocatedCost">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group formRightFX">
                                                        <label for="exampleInputEmail1">
                                                            Comments</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Comments"
                                                            formControlName="Comments">

                                                    </div>
                                                    <div class="col-lg-2"></div>

                                                </div>
                                            </div>


                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: 61%;">

                                        <button type="submit" *ngIf="!this.showFixed" (click)="onSubmitJobFixed()"
                                            class="btn btn-secondary submit-btn">Save</button>
                                        <button type="submit" *ngIf="this.showFixed" (click)="onUpdateFixed()"
                                            class="btn btn-secondary submit-btn">Update</button>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>

        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenterParty" tabindex="-1" role="dialog"
    aria-labelledby="exampleModalCenterParty" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add Party</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebuttonD>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                                        Add Party




                                    </a></li>



                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createJobParty">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormXS">
                                                        <label for="exampleInputEmail1">
                                                            Party ID<span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Party ID" filter="true"
                                                            formControlName="PartyID" [options]="partyIdArray"
                                                            optionlabel="label">

                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">
                                                            Role Type ID<span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Role Type ID" filter="true"
                                                            formControlName="RoleTypeID" [options]="allroleArray"
                                                            optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormXS">
                                                        <label for="exampleInputEmail1">
                                                            Work Effort ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown placeholder="Enter Work Effort ID" filter="true"
                                                            formControlName="WorkEffortID"
                                                            [options]="routingTaskListArray" optionlabel="label">

                                                        </p-dropdown>

                                                    </div>
                                                    <div class="col-lg-3 form-group rightFormA">
                                                        <label for="exampleInputEmail1">
                                                            From Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" placeholder="Enter Description"
                                                            formControlName="fromDate">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightFormXS">
                                                        <label for="exampleInputEmail1">
                                                            Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Reserv Persons"
                                                            formControlName="throughDate">

                                                    </div>


                                                </div>
                                            </div>








                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: -22%;">

                                        <button type="submit" (click)="onSubmitParty()"
                                            class="btn btn-secondary submit-btn">Save</button>

                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>

                </div>

            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>