import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable, BehaviorSubject } from 'rxjs';
import { URLS } from 'src/app/app.constant';

@Injectable({
  providedIn: 'root'
})
export class MyContactsService {
  getAllContactRelatedAccount() {
    throw new Error('Method not implemented.');
  }
  getCustRequestItem: any;
  getMyRatess: any;


  constructor(readonly http: HttpClient) { }

  getContacts(contactDetails): Observable<any> {
    return this.http.get(URLS.contactList + "pageNo=" + contactDetails.pageNo + "&pageSize=" + contactDetails.pageSize + "&search=" + contactDetails.search);
  }
  getContactsLists(): Observable<any> {
    return this.http.get(URLS.contactList);
  }

  findVDistributor(req): Observable<any> {
    const findVDistributor = URLS.findVDistributor;
    return this.http.post(findVDistributor,req);
  }
  findDistributorByTypeDistributor(req): Observable<any> {
    const findDistributorByTypeDistributor = URLS.findDistributorByTypeDistributor;
    return this.http.post(findDistributorByTypeDistributor,req);
  }
  updateOpportunity(req): Observable<any> {
    const updateOpportunity = URLS.updateOpportunity;
    return this.http.post(updateOpportunity,req);
  }
  createOpportunities(req): Observable<any> {
    const createOpportunities = URLS.createOpportunities;
    return this.http.post(createOpportunities,req);
  }
  deleteOpportunity(formData): Observable<any> {
    const deleteOpportunity = URLS.deleteOpportunity;
    return this.http.post(deleteOpportunity, formData)
  }
  deleteVendor(req): Observable<any> {
    const deleteVendor = URLS.deleteVendor;
    return this.http.post(deleteVendor,req);
  }
  contactRelatedAccount(req): Observable<any> {
    const contactRelatedAccount = URLS.contactRelatedAccount;
    return this.http.post(contactRelatedAccount,req);
  }
  updateOpportunityContact(req): Observable<any> {
    const updateOpportunityContact = URLS.updateOpportunityContact;
    return this.http.post(updateOpportunityContact,req);
  }
  RelatedAccount(formData): Observable<any> {
    const postData = URLS.RelatedAccountContact;
    return this.http.post(postData, formData)
  }
  setDefaultImage(formData): Observable<any> {
    const setDefaultImage = URLS.setDefaultImage;
    return this.http.post(setDefaultImage, formData)
  }
  createWorkEffort(formData): Observable<any> {
    const postData = URLS.createWorkEffort;
    return this.http.post(postData, formData)
  }
  createICalendar(formData): Observable<any> {
    const postData = URLS.createICalendar;
    return this.http.post(postData, formData)
  }
  createWorkEffortContent(formData): Observable<any> {
    const postData = URLS.createWorkEffortContent;
    return this.http.post(postData, formData)
  }
  updateProductPromo(formData): Observable<any> {
    const updateProductPromo = URLS.updateProductPromo;
    return this.http.post(updateProductPromo, formData)
  }
  createWorkEffortOrderHeader(formData): Observable<any> {
    const postData = URLS.createWorkEffortOrderHeader;
    return this.http.post(postData, formData)
  }
  createWorkEffortQuote(formData): Observable<any> {
    const postData = URLS.createWorkEffortQuote;
    return this.http.post(postData, formData)
  }
  createWorkEffortQuoteItem(formData): Observable<any> {
    const postData = URLS.createWorkEffortQuoteItem;
    return this.http.post(postData, formData)
  }
  createWorkEffortRequirement(formData): Observable<any> {
    const postData = URLS.createWorkEffortRequirement;
    return this.http.post(postData, formData)
  }
  createWorkEffortRequest(formData): Observable<any> {
    const postData = URLS.createWorkEffortRequest;
    return this.http.post(postData, formData)
  }
  createShoppingListWorkEffort(formData): Observable<any> {
    const postData = URLS.createShoppingListWorkEffort;
    return this.http.post(postData, formData)
  }
  createCommunicationEvent(formData): Observable<any> {
    const postData = URLS.createCommunicationEvent;
    return this.http.post(postData, formData)
  }
  createWorkEffortTimeEntry(formData): Observable<any> {
    const postData = URLS.createWorkEffortTimeEntry;
    return this.http.post(postData, formData)
  }
  deleteWorkEffortQuote(formData): Observable<any> {
    const postData = URLS.deleteWorkEffortQuote;
    return this.http.post(postData, formData)
  }
  updateWorkEffortRate(formData): Observable<any> {
    const postData = URLS.updateWorkEffortRate;
    return this.http.post(postData, formData)
  }
  createPartyAssignment(formData): Observable<any> {
    const postData = URLS.createPartyAssignment;
    return this.http.post(postData, formData)
  }
  createWorkEffortAndAssoc(formData): Observable<any> {
    const postData = URLS.createWorkEffortAndAssoc;
    return this.http.post(postData, formData)
  }
  createWorkEffortAssoc(formData): Observable<any> {
    const postData = URLS.createWorkEffortAssoc;
    return this.http.post(postData, formData)
  }
  import(formData): Observable<any> {
    const importContacts = URLS.uploadContacts;
    return this.http.post(importContacts, formData)
  }

  exportContacts(): Observable<any> {
    return this.http.get(URLS.exportContacts, { responseType: 'blob' })
  }
  getSearchedContacts(req): Observable<any> {
    const getSearchedContact = URLS.advancedSearhContacts;
    return this.http.post(getSearchedContact, req);
  }
 
  createQuoteNote(formData, quoteId): Observable<any> {
   
    const createQuoteNote = URLS.createQuoteNote.replace(":quoteId", quoteId);
    return this.http.post(createQuoteNote, formData);

  }
  deactivatedTeamByPartyId(partyId): Observable<any> {
    const deactivatedTeamByPartyId = URLS.deactivatedTeamByPartyId.replace(":partyId", partyId);
    return this.http.post(deactivatedTeamByPartyId, "");
  }
  createContactNote(formData, id): Observable<any> {
    const createNote = URLS.createContactNote.replace(":partyId", id);
    return this.http.post(createNote, formData);
  }
  createQuotes(formData): Observable<any> {
    const createQuotes = URLS.createQuotes;
    return this.http.post(createQuotes, formData);
  }
  createQuoteWorkEffort(formData): Observable<any> {
    const createQuoteWorkEffort = URLS.createQuoteWorkEffort;
    return this.http.post(createQuoteWorkEffort,formData);
  }
  autoCreateQuoteAdjustments(formData): Observable<any> {
    const autoCreateQuoteAdjustments = URLS.autoCreateQuoteAdjustments;
    return this.http.post(autoCreateQuoteAdjustments,formData);
  }
  createQuoteAdjustment(formData): Observable<any> {
    const createQuoteAdjustment = URLS.createQuoteAdjustment;
    return this.http.post(createQuoteAdjustment,formData);
  }
 
  autoUpdateQuotePrices(formData): Observable<any> {
    const autoUpdateQuotePrices = URLS.autoUpdateQuotePrices;
    return this.http.post(autoUpdateQuotePrices,formData);
  }
  updateQuoteAdjustment(formData): Observable<any> {
    const updateQuoteAdjustment = URLS.updateQuoteAdjustment;
    return this.http.post(updateQuoteAdjustment,formData);
  }
  removeQuoteAdjustment(formData): Observable<any> {
    const removeQuoteAdjustment = URLS.removeQuoteAdjustment;
    return this.http.post(removeQuoteAdjustment,formData);
  }
  sendQuoteReportMail(formData): Observable<any> {
    const sendQuoteReportMail = URLS.sendQuoteReportMail;
    return this.http.post(sendQuoteReportMail,formData);
  }
  createProfileUserLogin(formData): Observable<any> {
    const createProfileUserLogin = URLS.createProfileUserLogin;
    return this.http.post(createProfileUserLogin,formData);
  }
  updateQuoteAttribute(formData): Observable<any> {
    const updateQuoteAttribute = URLS.updateQuoteAttribute;
    return this.http.post(updateQuoteAttribute,formData);
  }
  createQuoteAttribute(formData): Observable<any> {
    const createQuoteAttribute = URLS.createQuoteAttribute;
    return this.http.post(createQuoteAttribute,formData);
  }
  updatePartyGroup(formData,id):Observable<any>{
    const updatePartyGroup = URLS.updatePartyGroup.replace(":partyId",id,);
    return this.http.put(updatePartyGroup,formData);
  }
  updateQuotes(formData,quoteId):Observable<any>{
    const updateQuotes = URLS.updateQuotes.replace(":quoteId",quoteId,);
    return this.http.put(updateQuotes,formData);
  }
  updateQuoteNote(formData,quoteId,noteId):Observable<any>{
    const updateQuoteNote = URLS.updateQuoteNote.replace(":quoteId",quoteId)+ "?noteId=" + noteId;
    return this.http.put(updateQuoteNote,formData);
  }
 
  resetProfileAvsOverride(id): Observable<any> {
    const resetProfileAvsOverride = URLS.resetProfileAvsOverride + "?partyId=" + id;
    return this.http.delete(resetProfileAvsOverride);
  }
  deleteTeamMemberInviewTeamByAutoId(autoId): Observable<any> {
    const deleteTeamMemberInviewTeamByAutoId = URLS.deleteTeamMemberInviewTeamByAutoId.replace(":autoId", autoId);
    return this.http.delete(deleteTeamMemberInviewTeamByAutoId);
  }
  updateContactNote(formData,noteId):Observable<any>{
    const updateNote = URLS.updateContactNote.replace(":noteId",noteId);
    return this.http.put(updateNote,formData);
  }
  updateMyPortalCalendarEvent(formData):Observable<any>{
    const updateMyPortalCalendarEvent = URLS.updateMyPortalCalendarEvent;
    return this.http.put(updateMyPortalCalendarEvent,formData);
  }
  updateTeamMemberInviewTeam(formData):Observable<any>{
    const updateTeamMemberInviewTeam = URLS.updateTeamMemberInviewTeam;
    return this.http.put(updateTeamMemberInviewTeam,formData);
  }
  updateProfileOverride(avsDeclineString, partyId): Observable<any> {
    const updateProfileOverride = URLS.updateProfileOverride + "?avsDeclineString=" + avsDeclineString + "&partyId=" + partyId;
    return this.http.put(updateProfileOverride,'');
  }
  getProductCategorySubscription(): Observable<any> {
    const getProductCategorySubscription = URLS.getProductCategorySubscription;
    return this.http.get(getProductCategorySubscription);
  }
  getProjectName(): Observable<any> {
    const getProjectName = URLS.getProjectName;
    return this.http.get(getProjectName);
  }
  getProfileStatus(): Observable<any> {
    return this.http.get(URLS.getCRMStatus)
  }  
  getWebUrlPurpose(): Observable<any> {
    const getWebUrlPurpose = URLS.getWebUrlPurpose;
    return this.http.get(getWebUrlPurpose);
  }
  getPostalAddressPurpose(): Observable<any> {
    const getPostalAddressPurpose = URLS.getPostalAddressPurpose;
    return this.http.get(getPostalAddressPurpose);
  }
  getPhoneNumberPurpose(): Observable<any> {
    const getPhoneNumberPurpose = URLS.getPhoneNumberPurpose;
    return this.http.get(getPhoneNumberPurpose);
  }
  updateBillingAccountTerm(formData,billingAccountTermId):Observable<any>{
    const updateBillingAccountTerm = URLS.updateBillingAccountTerm+ "?billingAccountTermId=" + billingAccountTermId;
    return this.http.put(updateBillingAccountTerm,formData);
  }
  updateQuoteOrderNote(formData,quoteId,noteId):Observable<any>{
    const updateQuoteOrderNote = URLS.updateQuoteOrderNote.replace(":quoteId",quoteId)+ "?noteId=" + noteId;
    return this.http.put(updateQuoteOrderNote,formData);
  }
  updateCalendarEvent(formData):Observable<any>{
    const updateCalendarEvent = URLS.updateCalendarEvent;
    return this.http.put(updateCalendarEvent,formData);
  }
  getEmailAddressPurpose(): Observable<any> {
    const getEmailAddressPurpose = URLS.getEmailAddressPurpose;
    return this.http.get(getEmailAddressPurpose);
  } 
  contactTypes(): Observable<any> {
    const contactTypes = URLS.contactTypes;
    return this.http.get(contactTypes);
  }
  getCalenderEntries(party): Observable<any> {
    const getCalenderEntries = URLS.getCalenderEntries + "/" + party;
    return this.http.get(getCalenderEntries);
  }
  getProductCategory(): Observable<any> {
    const getProductCategory = URLS.getProductCategory;
    return this.http.get(getProductCategory);
  }
  getRoleTeam(): Observable<any> {
    const getRoleTeam = URLS.getRoleTeam;
    return this.http.get(getRoleTeam);
  }
  createElectronicAddressOrIPAddressOrDomainNameOrWebURLOrInternalPartyOrFileServerOrLdap(req): Observable<any> {
    const createElectronicAddressOrIPAddressOrDomainNameOrWebURLOrInternalPartyOrFileServerOrLdap = URLS.createElectronicAddressOrIPAddressOrDomainNameOrWebURLOrInternalPartyOrFileServerOrLdap;
    return this.http.post(createElectronicAddressOrIPAddressOrDomainNameOrWebURLOrInternalPartyOrFileServerOrLdap, req);

  }
  createMyPortalCalendarEvent(req): Observable<any> {
    const createMyPortalCalendarEvent = URLS.createMyPortalCalendarEvent;
    return this.http.post(createMyPortalCalendarEvent, req);

  }
  getEventType(): Observable<any> {
    const getEventType = URLS.getEventTypes;
    return this.http.get(getEventType);
  }
  createProdCatalogStore(req): Observable<any> {
    const createProdCatalogStore = URLS.createProdCatalogStore;
    return this.http.post(createProdCatalogStore, req);

  }
  updateProdCatalogStore(req): Observable<any> {
    const updateProdCatalogStore = URLS.updateProdCatalogStore;
    return this.http.post(updateProdCatalogStore, req);

  }
  deleteProdCatalogStore(req): Observable<any> {
    const deleteProdCatalogStore = URLS.deleteProdCatalogStore;
    return this.http.post(deleteProdCatalogStore, req);

  }
  createLogTaskEmailCases(req): Observable<any> {
    const createLogTaskEmailCases = URLS.createLogTaskEmailCases;
    return this.http.post(createLogTaskEmailCases, req);

  }
  createCalendarEvent(req): Observable<any> {
    const createCalendarEvent = URLS.createCalendarEvents;
    return this.http.post(createCalendarEvent, req);

  }
  updateCloseCase(req): Observable<any> {
    const updateCloseCase = URLS.updateCloseCase;
    return this.http.post(updateCloseCase, req);

  }
  createSubscriptionResource(req): Observable<any> {
    const createSubscriptionResource = URLS.createSubscriptionResource;
    return this.http.post(createSubscriptionResource, req);

  }
  updateSubscriptionResource(req): Observable<any> {
    const updateSubscriptionResource = URLS.updateSubscriptionResource;
    return this.http.post(updateSubscriptionResource, req);

  }
  deleteLogTaskEmailCases(req): Observable<any> {
    const deleteLogTaskEmailCases = URLS.deleteLogTaskEmailCases;
    return this.http.post(deleteLogTaskEmailCases, req);

  }
  createLogTaskCallCases(req): Observable<any> {
    const createLogTaskCallCases = URLS.createLogTaskCallCases;
    return this.http.post(createLogTaskCallCases, req);

  }
  createMyPortalParticipants(req): Observable<any> {
    const createMyPortalParticipants = URLS.createMyPortalParticipants;
    return this.http.post(createMyPortalParticipants, req);

  }
  UpdatePendingActivityTaskPartner(req): Observable<any> {
    const UpdatePendingActivityTaskPartner = URLS.UpdatePendingActivityTaskPartner;
    return this.http.post(UpdatePendingActivityTaskPartner, req);

  }
  deletePendingActivityTasks(req): Observable<any> {
    const deletePendingActivityTaskPartner = URLS.deletePendingActivityTasks;
    return this.http.post(deletePendingActivityTaskPartner, req);

  }
  deletePendingActivityTaskPartner(req): Observable<any> {
    const deletePendingActivityTaskPartner = URLS.deletePendingActivityTaskPartner;
    return this.http.post(deletePendingActivityTaskPartner, req);

  }
  deletePendingActivityEventPartner(req): Observable<any> {
    const deletePendingActivityEventPartner = URLS.deletePendingActivityEventPartner;
    return this.http.post(deletePendingActivityEventPartner, req);

  }
  createEmailAddressPartner(req): Observable<any> {
    const createEmailAddressPartner = URLS.createEmailAddressPartner;
    return this.http.post(createEmailAddressPartner, req);

  }                       
  UpdateEmailAddressPartner(req): Observable<any> {
    const UpdateEmailAddressPartner = URLS.UpdateEmailAddressPartner;
    return this.http.post(UpdateEmailAddressPartner, req);

  }
  UpdatePhoneNumberPartner(req): Observable<any> {
    const UpdatePhoneNumberPartner = URLS.UpdatePhoneNumberPartner;
    return this.http.post(UpdatePhoneNumberPartner, req);

  }
  UpdateSkypeContactPartner(req): Observable<any> {
    const UpdateSkypeContactPartner = URLS.UpdateSkypeContactPartner;
    return this.http.post(UpdateSkypeContactPartner, req);

  }
  UpdatePostalAddressPartner(req): Observable<any> {
    const UpdatePostalAddressPartner = URLS.UpdatePostalAddressPartner;
    return this.http.post(UpdatePostalAddressPartner, req);

  }
  deleteProductConfigProduct(req): Observable<any> {
    const deleteProductConfigProduct = URLS.deleteProductConfigProduct;
    return this.http.post(deleteProductConfigProduct, req);
  }
  createPhoneNumberPartner(req): Observable<any> {
    const createPhoneNumberPartner = URLS.createPhoneNumberPartner;
    return this.http.post(createPhoneNumberPartner, req);

  }
  createPostalAddressPartner(req): Observable<any> {
    const createPostalAddressPartner = URLS.createPostalAddressPartner;
    return this.http.post(createPostalAddressPartner, req);

  }
  deleteFlagReasons(req): Observable<any> {
    const deleteFlagReasons = URLS.deleteFlagReasons;
    return this.http.post(deleteFlagReasons, req);

  }
  createFlagReasons(req): Observable<any> {
    const createFlagReasons = URLS.createFlagReasons;
    return this.http.post(createFlagReasons, req);

  }
  updateFlagReasons(req): Observable<any> {
    const updateFlagReasons = URLS.updateFlagReasons;
    return this.http.post(updateFlagReasons, req);

  }
  prepareAddContentToProductConfigItem(req): Observable<any> {
    const prepareAddContentToProductConfigItem = URLS.prepareAddContentToProductConfigItem;
    return this.http.post(prepareAddContentToProductConfigItem, req);

  }
  createSimpleTextContentForProductConfigItem(req): Observable<any> {
    const createSimpleTextContentForProductConfigItem = URLS.createSimpleTextContentForProductConfigItem;
    return this.http.post(createSimpleTextContentForProductConfigItem, req);

  }
  ContentId(): Observable<any> {
    const ContentId = URLS.ContentId;
    return this.http.get(ContentId);
  }
  getPhonenumberPurpose(): Observable<any> {
    const getPhonenumberPurpose = URLS.getPhonenumberPurpose;
    return this.http.get(getPhonenumberPurpose);
  }
  getWebSiteId(): Observable<any> {
    const getWebSiteId = URLS.getWebSiteId;
    return this.http.get(getWebSiteId);
  }
  getSubscriptionProductList(): Observable<any> {
    const getSubscriptionProductList = URLS.getSubscriptionProductList;
    return this.http.get(getSubscriptionProductList);
  }
  getContentIdResource(): Observable<any> {
    const getContentIdResource = URLS.getContentIdResource;
    return this.http.get(getContentIdResource);
  }
  getSubscriptionResourceList(): Observable<any> {
    const getSubscriptionResourceList = URLS.getSubscriptionResourceList;
    return this.http.get(getSubscriptionResourceList);
  }
  deleteProductSubscriptionResource(req): Observable<any> {
    const deleteProductSubscriptionResource = URLS.deleteProductSubscriptionResource;
    return this.http.post(deleteProductSubscriptionResource, req);

  }
  createProductSubscriptionResourceSr(req): Observable<any> {
    const createProductSubscriptionResourceSr = URLS.createProductSubscriptionResourceSr;
    return this.http.post(createProductSubscriptionResourceSr, req);

  }
  deletePartnerTelecomNumber(req): Observable<any> {
    const deletePartnerTelecomNumber = URLS.deletePartnerTelecomNumber;
    return this.http.post(deletePartnerTelecomNumber, req);

  }
  deleteEmailAddressPartner(req): Observable<any> {
    const deleteEmailAddressPartner = URLS.deleteEmailAddressPartner;
    return this.http.post(deleteEmailAddressPartner, req);

  }
  deleteSkypeContactPartner(req): Observable<any> {
    const deleteSkypeContactPartner = URLS.deleteSkypeContactPartner;
    return this.http.post(deleteSkypeContactPartner, req);

  }
  deletePostalAddressPartner(req): Observable<any> {
    const deletePostalAddressPartner = URLS.deletePostalAddressPartner;
    return this.http.post(deletePostalAddressPartner, req);

  }
  createSkypeContactPartner(req): Observable<any> {
    const createSkypeContactPartner = URLS.createSkypeContactPartner;
    return this.http.post(createSkypeContactPartner, req);

  }
  addWorkEffortTimeToInvoice(req): Observable<any> {
    const addWorkEffortTimeToInvoice = URLS.addWorkEffortTimeToInvoice;
    return this.http.post(addWorkEffortTimeToInvoice, req);

  }
  addWorkEffortTimeToNewInvoice(req): Observable<any> {
    const addWorkEffortTimeToNewInvoice = URLS.addWorkEffortTimeToNewInvoice;
    return this.http.post(addWorkEffortTimeToNewInvoice, req);

  }
  UpdateWebUrlPartner(req): Observable<any> {
    const UpdateWebUrlPartner = URLS.UpdateWebUrlPartner;
    return this.http.post(UpdateWebUrlPartner, req);

  }
  postNewSubSite(req): Observable<any> {
    const postNewSubSite = URLS.postNewSubSite;
    return this.http.post(postNewSubSite, req);

  }
  persistContentStuff(req): Observable<any> {
    const persistContentStuff = URLS.persistContentStuff;
    return this.http.post(persistContentStuff, req);

  }
  EditAddContent(req): Observable<any> {
    const EditAddContent = URLS.EditAddContent;
    return this.http.post(EditAddContent, req);

  }
  updateSiteRoles(req): Observable<any> {
    const updateSiteRoles = URLS.updateSiteRoles;
    return this.http.post(updateSiteRoles, req);

  }
  CMSContentFind(req): Observable<any> {
    const CMSContentFind = URLS.CMSContentFind;
    return this.http.post(CMSContentFind, req);

  }
  deleteWebUrlPartner(req): Observable<any> {
    const deleteWebUrlPartner = URLS.deleteWebUrlPartner;
    return this.http.post(deleteWebUrlPartner, req);

  }
  createWebUrlPartner(req): Observable<any> {
    const createWebUrlPartner = URLS.createWebUrlPartner;
    return this.http.post(createWebUrlPartner, req);

  }
  UpdatePendingActivityEventPartner(req): Observable<any> {
    const UpdatePendingActivityEventPartner = URLS.UpdatePendingActivityEventPartner;
    return this.http.post(UpdatePendingActivityEventPartner, req);

  }
  CreatePendingActivityEventPartner(req): Observable<any> {
    const CreatePendingActivityEventPartner = URLS.CreatePendingActivityEventPartner;
    return this.http.post(CreatePendingActivityEventPartner, req);

  }
  createPendingActivityTaskPartner(req): Observable<any> {
    const createPendingActivityTaskPartner = URLS.createPendingActivityTaskPartner;
    return this.http.post(createPendingActivityTaskPartner, req);

  }
  cancelCalenderEvent(req): Observable<any> {
    const cancelCalenderEvent = URLS.cancelCalenderEvent;
    return this.http.post(cancelCalenderEvent, req);

  }
    updateAgreementsSupplierPartner(req): Observable<any> {
    const updateAgreementsSupplierPartner = URLS.updateAgreementsSupplierPartner;
    return this.http.post(updateAgreementsSupplierPartner, req);

  }
  UpdateBookMarksUrlPartner(req): Observable<any> {
    const UpdateBookMarksUrlPartner = URLS.UpdateBookMarksUrlPartner;
    return this.http.post(UpdateBookMarksUrlPartner, req);

  }
  deleteBookMarksUrlPartner(req): Observable<any> {
    const deleteBookMarksUrlPartner = URLS.deleteBookMarksUrlPartner;
    return this.http.post(deleteBookMarksUrlPartner, req);

  }
  createBookMarksUrlPartner(req): Observable<any> {
    const createBookMarksUrlPartner = URLS.createBookMarksUrlPartner;
    return this.http.post(createBookMarksUrlPartner, req);

  }
  updateDataResourceAttribute(req): Observable<any> {
    const updateDataResourceAttribute = URLS.updateDataResourceAttribute;
    return this.http.post(updateDataResourceAttribute, req);

  }
  removeDataResourceAttribute(req): Observable<any> {
    const removeDataResourceAttribute = URLS.removeDataResourceAttribute;
    return this.http.post(removeDataResourceAttribute, req);
  }
  addContentType(req): Observable<any> {
    const addContentType = URLS.addContentType;
    return this.http.post(addContentType, req);
  }
  updateContentType(req): Observable<any> {
    const updateContentType = URLS.updateContentType;
    return this.http.post(updateContentType, req);
  }
  uploadImageData(objectInfo,dataResourceId,dataResourceTypeId): Observable<any> {
    const uploadImageUpdate = URLS.uploadImageUpdate + "?objectInfo=" + objectInfo 
    +"&dataResourceId=" + dataResourceId +"&dataResourceTypeId=" + 
    dataResourceTypeId  ;
    return this.http.post(uploadImageUpdate,"");
  }
  uploadImage(formData,objectInfo,dataResourceId,dataResourceTypeId): Observable<any> {
    const uploadImageUpdate = URLS.uploadImageUpdate + "?objectInfo=" + objectInfo 
    +"&dataResourceId=" + dataResourceId +"&dataResourceTypeId=" + 
    dataResourceTypeId  ;
    return this.http.post(uploadImageUpdate,formData);
  }
  createBlogArticles(req): Observable<any> {
    const createBlogArticles = URLS.createBlogArticles;
    return this.http.post(createBlogArticles, req);
  }
  createWebSiteContact(req): Observable<any> {
    const createWebSiteContact = URLS.createWebSiteContact;
    return this.http.post(createWebSiteContact, req);

  }
  updateWebSiteContact(req): Observable<any> {
    const updateWebSiteContact = URLS.updateWebSiteContact;
    return this.http.post(updateWebSiteContact, req);
  }
  deleteWebSiteContact(req): Observable<any> {
    const deleteWebSiteContact = URLS.deleteWebSiteContact;
    return this.http.post(deleteWebSiteContact, req);

  }
  AddDataResourceAttribute(req): Observable<any> {
    const AddDataResourceAttribute = URLS.AddDataResourceAttribute;
    return this.http.post(AddDataResourceAttribute, req);
  }
  deleteAgreementsSupplierPartner(req): Observable<any> {
    const deleteAgreementsSupplierPartner = URLS.deleteAgreementsSupplierPartner;
    return this.http.post(deleteAgreementsSupplierPartner, req);

  }
  createTelecomNumber(req): Observable<any> {
    const createTelecomNumber = URLS.createTelecomNumber;
    return this.http.post(createTelecomNumber, req);

  }
  createPostalAddress(req): Observable<any> {
    const createPostalAddress = URLS.createPostalAddress;
    return this.http.post(createPostalAddress, req);

  }
  createEmailAddress(req): Observable<any> {
    const createEmailAddress = URLS.createEmailAddress;
    return this.http.post(createEmailAddress, req);

  }
  getAllBillingAccountTerms(id): Observable<any> {
    const getAllBillingAccountTerms = URLS.getAllBillingAccountTerms +"?billingAccountId=" + id ;
    return this.http.get(getAllBillingAccountTerms);
  }
  getBillingAccountTerms(id): Observable<any> {
    const getBillingAccountTerms = URLS.getBillingAccountTerms +"?billingAccountTermsId=" + id  ;
    return this.http.get(getBillingAccountTerms);
  }
  getSimpleTextContentForProductConfigItemById(configItemId): Observable<any> {
    const getSimpleTextContentForProductConfigItemById = URLS.getSimpleTextContentForProductConfigItemById +"?configItemId=" + configItemId  ;
    return this.http.get(getSimpleTextContentForProductConfigItemById);
  }
  updateElectronicAddress(): Observable<any> {
    const returnAccepted = URLS.updateElectronicAddressOrIPAddressOrDomainNameOrWebURLOrInternalPartyOrFileServerOrLdap ;
    return this.http.put(returnAccepted, '');
  }
  updateProductConfigItemContent(req): Observable<any> {
    const updateProductConfigItemContent = URLS.updateProductConfigItemContent;
    return this.http.post(updateProductConfigItemContent, req);

  }
  UploadProductConfigItemImage(req): Observable<any> {
    const UploadProductConfigItemImage = URLS.UploadProductConfigItemImage;
    return this.http.post(UploadProductConfigItemImage, req);

  }
  createOnHoldReasons(req): Observable<any> {
    const createOnHoldReasons = URLS.createOnHoldReasons;
    return this.http.post(createOnHoldReasons, req);

  }
  deleteOnHoldReasons(req): Observable<any> {
    const deleteOnHoldReasons = URLS.deleteOnHoldReasons;
    return this.http.post(deleteOnHoldReasons, req);
  }
  updateOnHoldReasons(req): Observable<any> {
    const updateOnHoldReasons = URLS.updateOnHoldReasons;
    return this.http.post(updateOnHoldReasons, req);
  }
  updateCancelResponse(req): Observable<any> {
    const updateCancelResponse = URLS.updateCancelResponse;
    return this.http.post(updateCancelResponse, req);

  }
  deleteCancelResponse(req): Observable<any> {
    const deleteCancelResponse = URLS.deleteCancelResponse;
    return this.http.post(deleteCancelResponse, req);
  }
  createCancelResponse(req): Observable<any> {
    const createCancelResponse = URLS.createCancelResponse;
    return this.http.post(createCancelResponse, req);
  }
  addContentToProductConfigItem(req): Observable<any> {
    const addContentToProductConfigItem = URLS.addContentToProductConfigItem;
    return this.http.post(addContentToProductConfigItem, req);

  }
  removeContentFromProductConfigItem(req): Observable<any> {
    const removeContentFromProductConfigItem = URLS.removeContentFromProductConfigItem;
    return this.http.post(removeContentFromProductConfigItem, req);

  }
  createDataResource(req): Observable<any> {
    const createDataResource = URLS.createDataResource;
    return this.http.post(createDataResource, req);

  }
  findDataResource(req): Observable<any> {
    const findDataResource = URLS.findDataResource;
    return this.http.post(findDataResource, req);

  }
  updateWorkEffortEventReminder(req): Observable<any> {
    const updateWorkEffortEventReminder = URLS.updateWorkEffortEventReminder;
    return this.http.post(updateWorkEffortEventReminder, req);

  }
  deleteBillingAccountRole(req): Observable<any> {
    const deleteBillingAccountRole = URLS.deleteBillingAccountRole;
    return this.http.put(deleteBillingAccountRole, req);

  }
  createBillingAccountTerms(req): Observable<any> {
    const createBillingAccountTerms = URLS.createBillingAccountTerms;
    return this.http.post(createBillingAccountTerms, req);
  }
  createBillingAccountRole(req): Observable<any> {
    const createBillingAccountRole = URLS.createBillingAccountRole;
    return this.http.post(createBillingAccountRole, req);

  }
  updateBillingAccountRole(req): Observable<any> {
    const updateBillingAccountRole = URLS.updateBillingAccountRoles;
    return this.http.put(updateBillingAccountRole, req);

  }
  addContentAssocType(req): Observable<any> {
    const addContentAssocType = URLS.addContentAssocType;
    return this.http.post(addContentAssocType, req);

  }
  updateContentAssocType(req): Observable<any> {
    const updateContentAssocType = URLS.updateContentAssocType;
    return this.http.post(updateContentAssocType, req);

  }
  removeContentAssocType(req): Observable<any> {
    const removeContentAssocType = URLS.removeContentAssocType;
    return this.http.post(removeContentAssocType, req);

  }
  removeSubscriptionCommEvent(req): Observable<any> {
    const removeSubscriptionCommEvent = URLS.removeSubscriptionCommEvent;
    return this.http.post(removeSubscriptionCommEvent, req);

  }
  deleteWebAnalyticsConfig(req): Observable<any> {
    const deleteWebAnalyticsConfig = URLS.deleteWebAnalyticsConfig;
    return this.http.post(deleteWebAnalyticsConfig, req);

  }
  removeContentType(req): Observable<any> {
    const removeContentType = URLS.removeContentType;
    return this.http.post(removeContentType, req);

  }
  createSubscriptionCommEvent(req): Observable<any> {
    const createSubscriptionCommEvent = URLS.createSubscriptionCommEvent;
    return this.http.post(createSubscriptionCommEvent, req);

  }
  UpdatePendingActivityTaskPartnerLead(req): Observable<any> {
    const UpdatePendingActivityTaskPartnerLead = URLS.UpdatePendingActivityTaskPartnerLead;
    return this.http.post(UpdatePendingActivityTaskPartnerLead, req);

  }
  UpdatePendingActivityEventPartnerLead(req): Observable<any> {
    const UpdatePendingActivityEventPartnerLead = URLS.UpdatePendingActivityEventPartnerLead;
    return this.http.post(UpdatePendingActivityEventPartnerLead, req);

  }
  CreatePendingActivityEventPartnerLead(req): Observable<any> {
    const CreatePendingActivityEventPartnerLead = URLS.CreatePendingActivityEventPartnerLead;
    return this.http.post(CreatePendingActivityEventPartnerLead, req);

  }
  deletePendingActivityEventPartnerLead(req): Observable<any> {
    const deletePendingActivityEventPartnerLead = URLS.deletePendingActivityEventPartnerLead;
    return this.http.post(deletePendingActivityEventPartnerLead, req);

  }
  deletePendingActivityTaskPartnerLead(req): Observable<any> {
    const deletePendingActivityTaskPartnerLead = URLS.deletePendingActivityTaskPartnerLead;
    return this.http.post(deletePendingActivityTaskPartnerLead, req);

  }
  updateAccounts(req): Observable<any> {
    const updateAccounts = URLS.updateAccounts;
    return this.http.post(updateAccounts, req);

  }
  deleteAccount(req): Observable<any> {
    const deleteAccount = URLS.deleteAccount;
    return this.http.post(deleteAccount, req);

  }
  createAccounts(req): Observable<any> {
    const createAccounts = URLS.createAccounts;
    return this.http.post(createAccounts, req);

  }
  createPendingActivityTaskPartnerLead(req): Observable<any> {
    const createPendingActivityTaskPartnerLead = URLS.createPendingActivityTaskPartnerLead;
    return this.http.post(createPendingActivityTaskPartnerLead, req);

  }
  updatePartner(fromData): Observable<any> {
    const updatePartner = URLS.updatePartner;
    return this.http.put(updatePartner, fromData);
  }
  updatePartyProfileIdentifications(fromData): Observable<any> {
    const updatePartyProfileIdentifications = URLS.updatePartyProfileIdentifications;
    return this.http.put(updatePartyProfileIdentifications, fromData);
  }
  updatePartyIdentifications(fromData): Observable<any> {
    const updatePartyIdentifications = URLS.updatePartyIdentifications;
    return this.http.put(updatePartyIdentifications, fromData);
  }
  createGiftCard(partyId,formData): Observable<any> {
    const createGiftCard = URLS.createGiftCard + "?partyId=" + partyId;
    return this.http.post(createGiftCard,formData);
  }
  getOpportunity(partnerId): Observable<any> {
    const getOpportunity = URLS.getOpportunity +"?partyId=" + partnerId  ;
    return this.http.get(getOpportunity);
  }
  getOpportunityContact(partnerId): Observable<any> {
    const getOpportunity = URLS.getOpportunityContact +"?partyId=" + partnerId  ;
    return this.http.get(getOpportunity);
  }
  getAccount(partnerId): Observable<any> {
    const getAccount = URLS.getAccount +"?partyId=" + partnerId  ;
    return this.http.get(getAccount);
  }
  getNotePartnerBIdLead(partnerId): Observable<any> {
    const getNotePartnerBIdLead = URLS.getNotePartnerBIdLead +"?partnerId=" + partnerId  ;
    return this.http.get(getNotePartnerBIdLead);
  }
  getBookMarksUrlIdPartner(partnerId): Observable<any> {
    const getBookMarksUrlIdPartner = URLS.getBookMarksUrlIdPartner +"?partnerId=" + partnerId  ;
    return this.http.get(getBookMarksUrlIdPartner);
  }
  getAllBillingAccountRole(id): Observable<any> {
    const getAllBillingAccountRole = URLS.getAllBillingAccountRole +"?billingAccountId=" + id  ;
    return this.http.get(getAllBillingAccountRole);
  }
  getUploadImage(id): Observable<any> {
    const getUploadImage = URLS.getUploadImage +"?dataResourceId=" + id  ;
    return this.http.get(getUploadImage);
  }
  getDataResourceByIdS(id): Observable<any> {
    const getDataResourceByIdS = URLS.getDataResourceByIdS +"?dataResourceId=" + id  ;
    return this.http.get(getDataResourceByIdS);
  }
  getPartnerById(partnerId): Observable<any> {
    const getPartnerById = URLS.getPartnerById +"?partnerId=" + partnerId  ;
    return this.http.get(getPartnerById);
  }
  getPendingActivityEventPartnerLead(partnerId): Observable<any> {
    const getPendingActivityEventPartnerLead = URLS.getPendingActivityEventPartnerLead +"?partnerId=" + partnerId  ;
    return this.http.get(getPendingActivityEventPartnerLead);
  }
  getPendingActivityTaskPartnerLead(partnerId): Observable<any> {
    const getPendingActivityTaskPartnerLead = URLS.getPendingActivityTaskPartnerLead +"?partnerId=" + partnerId  ;
    return this.http.get(getPendingActivityTaskPartnerLead);
  }
  getPendingActivityTaskPartner(partnerId): Observable<any> {
    const getPendingActivityTaskPartner = URLS.getPendingActivityTaskPartner +"?partnerId=" + partnerId  ;
    return this.http.get(getPendingActivityTaskPartner);
  }
  getAgreementsSupplierPartnerById(partnerId): Observable<any> {
    const getAgreementsSupplierPartnerById = URLS.getAgreementsSupplierPartnerById +"?partnerId=" + partnerId  ;
    return this.http.get(getAgreementsSupplierPartnerById);
  }
  getPendingActivityEventPartner(partnerId): Observable<any> {
    const getPendingActivityEventPartner = URLS.getPendingActivityEventPartner +"?partnerId=" + partnerId  ;
    return this.http.get(getPendingActivityEventPartner);
  }
  FindApprovedProductRequirements(facilityId,partyId,productId,requirementId,roleTypeId): Observable<any> {
    const FindApprovedProductRequirements = URLS.FindApprovedProductRequirements 
    +"?facilityId=" + facilityId +"&partyId=" + partyId +"&productId=" + productId 
    +"&requirementId=" + requirementId +"&roleTypeId=" + roleTypeId ;
    return this.http.get(FindApprovedProductRequirements);
  }
  getContentType(): Observable<any> {
    const getContentType = URLS.getContentType ;
    return this.http.get(getContentType);
  }
 
  getFindApprovedProductRequirements(): Observable<any> {
    const getFindApprovedProductRequirements = URLS.getFindApprovedProductRequirements ;
    return this.http.get(getFindApprovedProductRequirements);
  }
  getFsmCancelResponseById(cancelReasonsId): Observable<any> {
    const getFsmCancelResponseById = URLS.getFsmCancelResponseById +"?cancelReasonsId=" + cancelReasonsId   ;
    return this.http.get(getFsmCancelResponseById);
  }
  getfindPartyRevisionList(): Observable<any> {
    const findPartyRevisionList = URLS.findPartyRevisionList ;
    return this.http.get(findPartyRevisionList);
  }
  getFsmSCancelResponse(): Observable<any> {
    const getFsmSCancelResponse = URLS.getFsmSCancelResponse ;
    return this.http.get(getFsmSCancelResponse);
  }
  findPartyRevision(contentName,description): Observable<any> {
    const findPartyRevision = URLS.findPartyRevision +"?contentName=" + contentName +"&description=" + description ;
    return this.http.get(findPartyRevision);
  }
  getFindScrumMembers(firstName,groupId,lastName,middleName): Observable<any> {
    const getFindScrumMembers = URLS.getFindScrumMembers +"?firstName=" + firstName +"&groupId=" + groupId + "&lastName=" + lastName + "&middleName=" + middleName;
    return this.http.get(getFindScrumMembers);
  }
  getQuoteAdjustmentByIds(quoteId,quoteAdjustmentId): Observable<any> {
    const getQuoteAdjustmentByIds = URLS.getQuoteAdjustmentByIds +"?quoteId=" + quoteId +"&quoteAdjustmentId=" + quoteAdjustmentId ;
    return this.http.get(getQuoteAdjustmentByIds);
  }
  getQuoteAdjustmentByIdAndAll(quoteId): Observable<any> {
    const getQuoteAdjustmentByIdAndAll = URLS.getQuoteAdjustmentByIdAndAll +"?quoteId=" + quoteId  ;
    return this.http.get(getQuoteAdjustmentByIdAndAll);
  }

  getCalenderRole(): Observable<any> {
    const getCalenderRole = URLS.getCalenderRole ;
    return this.http.get(getCalenderRole);
  }
  getParticipants(workEffortId): Observable<any> {
    const getParticipants = URLS.getParticipants +"?workEffortId=" + workEffortId  ;
    return this.http.get(getParticipants);
  }
  getAutoUpdateQuotePrices(quoteId): Observable<any> {
    const getAutoUpdateQuotePrices = URLS.getAutoUpdateQuotePrices +"?quoteId=" + quoteId  ;
    return this.http.get(getAutoUpdateQuotePrices);
  }
    
  getCrmInitialCommunicationData(): Observable<any> {
    const getCrmInitialCommunicationData = URLS.getCrmInitialCommunicationData;
    return this.http.get(getCrmInitialCommunicationData);
  }
  updatePostCommunicationNew(data): Observable<any> {
    const updatePostCommunicationNew = URLS.updatePostCommunicationNew;
    return this.http.put(updatePostCommunicationNew,data);
  }
  getViewQuoteProfit(quoteId): Observable<any> {
    const getViewQuoteProfit = URLS.getViewQuoteProfit +"?quoteId=" + quoteId  ;
    return this.http.get(getViewQuoteProfit);
  }
  getQuoteWorkEffort(quoteId): Observable<any> {
    const getQuoteWorkEffort = URLS.getQuoteWorkEffort +"?quoteId=" + quoteId  ;
    return this.http.get(getQuoteWorkEffort);
  }
  getContentTypeById(id): Observable<any> {
    const getContentTypeById = URLS.getContentTypeById +"?contentTypeId=" + id  ;
    return this.http.get(getContentTypeById);
  }
  getContentAssocTypeById(id): Observable<any> {
    const getContentAssocTypeById = URLS.getContentAssocTypeById +"?contentAssocTypeId=" + id  ;
    return this.http.get(getContentAssocTypeById);
  }
  getContentAssocType():Observable<any> {
    const getContentAssocType = URLS.getContentAssocType;
    return this.http.get(getContentAssocType);
  }
  getQuoteItem(quoteId): Observable<any> {
    const getQuoteItem = URLS.getQuoteItem +"?quoteId=" + quoteId  ;
    return this.http.get(getQuoteItem);
  }
  getRequestType():Observable<any> {
    const getRequestType = URLS.getRequestType;
    return this.http.get(getRequestType);
  }
  getTimeEntryListById(workEffortId):Observable<any> {
    const getTimeEntryListById = URLS.getTimeEntryListById + "?workEffortId=" + workEffortId;
    return this.http.get(getTimeEntryListById);
  }
  getRequestTemporalExpression():Observable<any> {
    const getRequestTemporalExpression = URLS.getRequestTemporalExpression;
    return this.http.get(getRequestTemporalExpression);
  }
  getQuoteTemporalExpression():Observable<any> {
    const getQuoteTemporalExpression = URLS.getQuoteTemporalExpression;
    return this.http.get(getQuoteTemporalExpression);
  }
  getRequestRequirementList():Observable<any> {
    const getRequestRequirementList = URLS.getRequestRequirementList;
    return this.http.get(getRequestRequirementList);
  }
  getRequestFacilityIdList():Observable<any> {
    const getRequestFacilityIdList = URLS.getRequestFacilityIdList;
    return this.http.get(getRequestFacilityIdList);
  }
  getRequestPurpose():Observable<any> {
    const getRequestPurpose = URLS.getRequestPurpose;
    return this.http.get(getRequestPurpose);
  }
  getWebAnalytics(id): Observable<any> {
    const getWebAnalytics = URLS.getWebAnalytics +"?webSiteId=" + id  ;
    return this.http.get(getWebAnalytics);
  }
  getViewQuoteOrderProfit(quoteId): Observable<any> {
    const getViewQuoteOrderProfit = URLS.getViewQuoteOrderProfit +"?quoteId=" + quoteId  ;
    return this.http.get(getViewQuoteOrderProfit);
  }
  getWebUrlPartner(partnerId): Observable<any> {
    const getWebUrlPartner = URLS.getWebUrlPartner +"?partnerId=" + partnerId  ;
    return this.http.get(getWebUrlPartner);
  }
  getSkypeContactPartner(partnerId): Observable<any> {
    const getSkypeContactPartner = URLS.getSkypeContactPartner +"?partnerId=" + partnerId  ;
    return this.http.get(getSkypeContactPartner);
  }
  getPostalAddressPartner(partnerId): Observable<any> {
    const getPostalAddressPartner = URLS.getPostalAddressPartner +"?partnerId=" + partnerId  ;
    return this.http.get(getPostalAddressPartner);
  }
  getPhoneNumberPartner(partnerId): Observable<any> {
    const getPhoneNumberPartner = URLS.getPhoneNumberPartner +"?partnerId=" + partnerId  ;
    return this.http.get(getPhoneNumberPartner);
  }
  getEmailAddressPartner(partnerId): Observable<any> {
    const getEmailAddressPartner = URLS.getEmailAddressPartner +"?partnerId=" + partnerId  ;
    return this.http.get(getEmailAddressPartner);
  }
  getAllData(partnerId): Observable<any> {
    const getAllData = URLS.getAllData +"?partnerId=" + partnerId  ;
    return this.http.get(getAllData);
  }
  getViewQuoteProfitSummary(quoteId): Observable<any> {
    const getViewQuoteProfitSummary = URLS.getViewQuoteProfitSummary +"?quoteId=" + quoteId  ;
    return this.http.get(getViewQuoteProfitSummary);
  }
  getfindTeam(): Observable<any> {
    const findTeam = URLS.getfindTeam   ;
    return this.http.get(findTeam);
  }
  findTeam(name): Observable<any> {
    const findTeam = URLS.findTeam +"?name=" + name  ;
    return this.http.get(findTeam);
  }

  getAutoUpdateQuote(quoteId): Observable<any> {
    const getAutoUpdateQuote = URLS.getAutoUpdateQuote +"?quoteId=" + quoteId  ;
    return this.http.get(getAutoUpdateQuote);
  }
  getQuoteAdjustmentType():Observable<any> {
    const getQuoteAdjustmentType = URLS.getQuoteAdjustmentType;
    return this.http.get(getQuoteAdjustmentType);
  }
  getRoleResourceList():Observable<any> {
    const getRoleResourceList = URLS.getRoleResourceList;
    return this.http.get(getRoleResourceList);
  }
  getResourceParty(): Observable<any> {
    const getProductMemberParty = URLS.getResourceParty   ;
    return this.http.get(getProductMemberParty);
  }
  getFsmTimeCardById(timeCardId): Observable<any> {
    const getFsmTimeCardById = URLS.getFsmTimeCardById +"?timeCardId=" + timeCardId  ;
    return this.http.get(getFsmTimeCardById);
  }
  getMimeTypeIdData(): Observable<any> {
    const getMimeTypeIdData = URLS.getMimeTypeIdData   ;
    return this.http.get(getMimeTypeIdData);
  }
  getDataResourceStatusId(): Observable<any> {
    const getDataResourceStatusId = URLS.getDataResourceStatusId   ;
    return this.http.get(getDataResourceStatusId);
  }
  getDataResourceType(): Observable<any> {
    const getDataResourceType = URLS.getDataResourceType   ;
    return this.http.get(getDataResourceType);
  }
  getDataCategory(): Observable<any> {
    const getDataCategory = URLS.getDataCategory   ;
    return this.http.get(getDataCategory);
  }
  getFsmTechnicalAll(): Observable<any> {
    const getFsmTechnicalAll = URLS.getFsmTechnicalAll   ;
    return this.http.get(getFsmTechnicalAll);
  }
  getFsmTimeCard(): Observable<any> {
    const getFsmTimeCard = URLS.getFsmTimeCard   ;
    return this.http.get(getFsmTimeCard);
  }
  getFsmTechnicalById(id): Observable<any> {
    const getFsmTechnicalById = URLS.getFsmTechnicalById +"?technicianId=" + id  ;
    return this.http.get(getFsmTechnicalById);
  }
  getViewQuoteOrderProfitSummary(quoteId): Observable<any> {
    const getViewQuoteOrderProfitSummary = URLS.getViewQuoteOrderProfitSummary +"?quoteId=" + quoteId  ;
    return this.http.get(getViewQuoteOrderProfitSummary);
  }
  getBacklogTypeChartSecond(id): Observable<any> {
    const getBacklogTypeChartSecond = URLS.getBacklogTypeChartSecond +"?productId=" + id  ;
    return this.http.get(getBacklogTypeChartSecond);
  }
  getAllDetailsByTechnicianId(id): Observable<any> {
    const getAllDetailsByTechnicianId = URLS.getAllDetailsByTechnicianId +"?technicianId=" + id  ;
    return this.http.get(getAllDetailsByTechnicianId);
  }
  getSubscriptionCommEventBySubId(subscriptionId): Observable<any> {
    const getSubscriptionCommEventBySubId = URLS.getSubscriptionCommEventBySubId + "?subscriptionId=" + subscriptionId    ;
    return this.http.get(getSubscriptionCommEventBySubId);
  }
  getFsmAssignmentByIdNew(id): Observable<any> {
    const getFsmAssignmentByIdNew = URLS.getFsmAssignmentByIdNew +"?quoteId=" + id  ;
    return this.http.get(getFsmAssignmentByIdNew);
  }
  ReportBuilderRenderStarSchemaReport(id,form): Observable<any> {
    const ReportBuilderRenderStarSchemaReport = URLS.ReportBuilderRenderStarSchemaReport 
    +"?starSchemaName=" + id  ;
    return this.http.post(ReportBuilderRenderStarSchemaReport,form);
  }
  ReportBuilderSelectStarSchemaFields(id): Observable<any> {
    const ReportBuilderSelectStarSchemaFields = URLS.ReportBuilderSelectStarSchemaFields 
    +"?starSchemaName=" + id  ;
    return this.http.get(ReportBuilderSelectStarSchemaFields);
  }
  getAutoUpdateOrderQuote(quoteId): Observable<any> {
    const getAutoUpdateOrderQuote = URLS.getAutoUpdateOrderQuote +"?quoteId=" + quoteId  ;
    return this.http.get(getAutoUpdateOrderQuote);
  }
  getVisitPersonsPartyId():Observable<any> {
    const getVisitPersonsPartyId = URLS.getVisitPersonsPartyId;
    return this.http.get(getVisitPersonsPartyId);
  }
  getBacklogTypeChart(productId): Observable<any> {
    const getBacklogTypeChart = URLS.getBacklogTypeChart + "?productId=" + productId ;
    return this.http.get(getBacklogTypeChart);
  }
  getAllDetailsByInvoiceId(customerId,technicianId): Observable<any> {
    const getAllDetailsByInvoiceId = URLS.getAllDetailsByInvoiceId + "?customerId=" + customerId 
    + "&technicianId=" + technicianId   ;
    return this.http.get(getAllDetailsByInvoiceId);
  }
  getSubscriptionCommEventByCommunicationId(communicationEventId): Observable<any> {
    const getSubscriptionCommEventByCommunicationId = URLS.getSubscriptionCommEventByCommunicationId +"?communicationEventId=" + communicationEventId  ;
    return this.http.get(getSubscriptionCommEventByCommunicationId);
  }
  getSubscriptionCommEvent():Observable<any> {
    const getSubscriptionCommEvent = URLS.getSubscriptionCommEvent;
    return this.http.get(getSubscriptionCommEvent);
  }
  findVisitorsList(data,filterByDate,req): Observable<any> {
    const findVisitorsList = URLS.findVisitorsList + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize + "&filterByDate=" + filterByDate;
    return this.http.post(findVisitorsList,req);
  }
  getRoleTaskList():Observable<any> {
    const getRoleTaskList = URLS.getRoleTaskList;
    return this.http.get(getRoleTaskList);
  }
  getVisitorsList(data): Observable<any> {
    const getVisitorsList = URLS.getVisitorsList + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getVisitorsList);
  }
  technicianCreateCustomer(formData): Observable<any> {
    const technicianCreateCustomer = URLS.technicianCreateCustomer ;
    return this.http.post(technicianCreateCustomer,formData);
  }
  createWebSitePathAlias(formData): Observable<any> {
    const createWebSitePathAlias = URLS.createWebSitePathAlias ;
    return this.http.post(createWebSitePathAlias,formData);
  }
  generateMissingSeoUrlForWebsite(formData): Observable<any> {
    const generateMissingSeoUrlForWebsite = URLS.generateMissingSeoUrlForWebsite ;
    return this.http.post(generateMissingSeoUrlForWebsite,formData);
  }
  updateWebSitePathAlias(formData): Observable<any> {
    const updateWebSitePathAlias = URLS.updateWebSitePathAlias ;
    return this.http.post(updateWebSitePathAlias,formData);
  }
  removeWebSitePathAlias(formData): Observable<any> {
    const removeWebSitePathAlias = URLS.removeWebSitePathAlias ;
    return this.http.post(removeWebSitePathAlias,formData);
  }
  updateTechnicianPersonal(formData): Observable<any> {
    const updateTechnicianPersonal = URLS.updateTechnicianPersonal ;
    return this.http.post(updateTechnicianPersonal,formData);
  }
  addResource(formData): Observable<any> {
    const addResource = URLS.addResource ;
    return this.http.post(addResource,formData);
  }
  getQuoteOrderAdjustmentType():Observable<any> {
    const getQuoteOrderAdjustmentType = URLS.getQuoteOrderAdjustmentType;
    return this.http.get(getQuoteOrderAdjustmentType);
  }
  getBacklogTypePieChart(id): Observable<any> {
    const getBacklogTypePieChart = URLS.getBacklogTypePieChart +"?productId=" + id  ;
    return this.http.get(getBacklogTypePieChart);
  }
  statusToComplete(formData): Observable<any> {
    const statusToComplete = URLS.statusToComplete;
    return this.http.post(statusToComplete, formData);
  }
  removeResource(formData): Observable<any> {
    const removeResource = URLS.removeResource ;
    return this.http.post(removeResource,formData);
  }
  createEFTAccount(partyId,formData): Observable<any> {
    const createEFTAccount = URLS.createEFTAccount + "?partyId=" + partyId;
    return this.http.post(createEFTAccount,formData);
  }
  downloadProfileContent(id): Observable<any> {
    const getDownload = URLS.downloadProfileContent + "?contentId=" + id;
    return this.http.get(getDownload, { responseType: "blob" });
  }
  fsmInvoiceGenerate(id): Observable<any> {
    const fsmInvoiceGenerate = URLS.fsmInvoiceGenerate + "?invoiceId=" + id;
    return this.http.get(fsmInvoiceGenerate, { responseType: "blob" });
  }
  attachProfileContent(formData, partyContentTypeId, partyId): Observable<any> {
    const getPartyContent = URLS.attachProfileContent + "?partyContentTypeId=" +
     partyContentTypeId + "&partyId=" + partyId;
    return this.http.post(getPartyContent, formData);
  }
  getCurrencyDataList(): Observable<any> {
    const getCurrencyDataList = URLS.getCurrencyDataList;
    return this.http.get(getCurrencyDataList);
  }
  getPartyId():Observable<any> {
    const getPartyId = URLS.getPartyId;
    return this.http.get(getPartyId);
  }
  getRatesData(): Observable<any> {
    const getRatesData = URLS.getRatesData;
    return this.http.get(getRatesData);
  }
  getRequestRolesAll(): Observable<any> {
    const getRequestRolesAll = URLS.getRequestRolesAll ;
    return this.http.get(getRequestRolesAll);
  }
  getProductstoreId(): Observable<any> {
    const getProductstoreId = URLS.getProductstoreId ;
    return this.http.get(getProductstoreId);
  }
  partyContentProfile(): Observable<any> {
    const getPartyContent = URLS.partyContentProfile;
    return this.http.get(getPartyContent);
  }
  createTimeSheetData(formData): Observable<any> {
    const createTimeSheetData = URLS.createTimeSheetData;
    return this.http.post(createTimeSheetData, formData)
  }
  deleteBillingAccountTerm(billingAccountId,billingAccountTermsId){
    const deleteBillingAccountTerm = URLS.deleteBillingAccountTerm + "?billingAccountId=" + billingAccountId + "&billingAccountTermsId=" + billingAccountTermsId ;
    return this.http.delete(deleteBillingAccountTerm);
  }
  deleteContent(id){
    const deleteContent = URLS.deleteContent + "?contentId=" + id ;
    return this.http.delete(deleteContent);
  }
  deleteProfileContent(contentId,fromDate,partyContentTypeId,partyId){
    const deleteProfileContent = URLS.deleteProfileContent + "?contentId=" + contentId + "&fromDate=" + fromDate + "&partyContentTypeId=" + partyContentTypeId + "&partyId=" + partyId;
    return this.http.delete(deleteProfileContent);
  }
  createProfileGiftCard(partyId,formData): Observable<any> {
    const createProfileGiftCard = URLS.createProfileGiftCard + "?partyId=" + partyId;
    return this.http.post(createProfileGiftCard,formData);
  }
  getSecurityList(data, req): Observable<any> {
    const getSecurityList = URLS.getSecurityList + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.post(getSecurityList, req);
  }
  createCheckAccount(partyId,formData): Observable<any> {
    const createCheckAccount = URLS.createCheckAccount + "?partyId=" + partyId;
    return this.http.post(createCheckAccount,formData);
  }
  deleteRoleProfile(req): Observable<any> {
    const deleteRoleProfile = URLS.deleteRoleProfile ;
    return this.http.delete(deleteRoleProfile,req);
  }
  deleteCatalogRequestLead(req): Observable<any> {
    const deleteCatalogRequestLead = URLS.deleteCatalogRequestLead ;
    return this.http.post(deleteCatalogRequestLead,req);
  }
  UpdateCatalogRequestLead(req): Observable<any> {
    const UpdateCatalogRequestLead = URLS.UpdateCatalogRequestLead ;
    return this.http.post(UpdateCatalogRequestLead,req);
  }
  createCatalogRequestLead(req): Observable<any> {
    const createCatalogRequestLead = URLS.createCatalogRequestLead ;
    return this.http.post(createCatalogRequestLead,req);
  }
  deleteMyPortalAParticipants(req): Observable<any> {
    const deleteMyPortalAParticipants = URLS.deleteMyPortalAParticipants ;
    return this.http.post(deleteMyPortalAParticipants,req);
  }
  deleteTimeEntry(req): Observable<any> {
    const deleteTimeEntry = URLS.deleteTimeEntry ;
    return this.http.delete(deleteTimeEntry,req);
  }
  updatePartyRate(fromData): Observable<any> {
    const updatePartyRate = URLS.updatePartyRate;
    return this.http.post(updatePartyRate, fromData);
  }
  updateTimeEntry(fromData): Observable<any> {
    const updateTimeEntry = URLS.updateTimeEntry;
    return this.http.put(updateTimeEntry, fromData);
  }
  updateWorkEffortContent(fromData): Observable<any> {
    const updateWorkEffortContent = URLS.updateWorkEffortContent;
    return this.http.put(updateWorkEffortContent, fromData);
  }
  deleteWorkEffortContent(req): Observable<any> {
    const deleteWorkEffortContent = URLS.deleteWorkEffortContent ;
    return this.http.post(deleteWorkEffortContent,req);
  }
  deleteWorkEffortOrderHeader(formData): Observable<any> {
    const deleteWorkEffortOrderHeader = URLS.deleteWorkEffortOrderHeader ;
    return this.http.post(deleteWorkEffortOrderHeader, formData);
  }
  deleteWorkEffortRequirement(req): Observable<any> {
    const deleteWorkEffortRequirement = URLS.deleteWorkEffortRequirement ;
    return this.http.post(deleteWorkEffortRequirement,req);
  }
  deleteWorkEffortRequest(req): Observable<any> {
    const deleteWorkEffortRequest = URLS.deleteWorkEffortRequest ;
    return this.http.post(deleteWorkEffortRequest,req);
  }
  makePrivates(req): Observable<any> {
    const makePrivates = URLS.makePrivate ;
    return this.http.post(makePrivates,req);
  }
  deleteShoppingListWorkEffort(req): Observable<any> {
    const deleteShoppingListWorkEffort = URLS.deleteShoppingListWorkEffort ;
    return this.http.post(deleteShoppingListWorkEffort,req);
  }
  ReportBuilderRenderStarSchemaReports(req): Observable<any> {
    const ReportBuilderRenderStarSchemaReports = URLS.ReportBuilderRenderStarSchemaReports ;
    return this.http.post(ReportBuilderRenderStarSchemaReports,req);
  }
  updateWorkEffortsTimeEntry(req): Observable<any> {
    const updateWorkEffortTimeEntry = URLS.updateWorkEffortTimeEntry ;
    return this.http.post(updateWorkEffortTimeEntry,req);
  }
  deleteWorkEffortsTimeEntry(req): Observable<any> {
    const deleteWorkEffortTimeEntry = URLS.deleteWorkEffortTimeEntry ;
    return this.http.post(deleteWorkEffortTimeEntry,req);
  }
  deleteWorkEffortQuoteItem(req): Observable<any> {
    const deleteWorkEffortQuoteItem = URLS.deleteWorkEffortQuoteItem ;
    return this.http.post(deleteWorkEffortQuoteItem,req);
  }
  createQuickTimeEntry(req): Observable<any> {
    const createQuickTimeEntry = URLS.createQuickTimeEntry ;
    return this.http.post(createQuickTimeEntry,req);
  }
  updateWorkEffortPartyAssign(req): Observable<any> {
    const updateWorkEffortPartyAssign = URLS.updateWorkEffortPartyAssign ;
    return this.http.post(updateWorkEffortPartyAssign,req);
  }
  updateBillingAccount(fromData): Observable<any> {
    const updateBillingAccount = URLS.updateBillingAccount;
    return this.http.put(updateBillingAccount, fromData);
  }
  createWebSiteContent(formData): Observable<any> {
    const createWebSiteContent = URLS.createWebSiteContent ;
    return this.http.post(createWebSiteContent, formData);
  }
  autoCreateWebSiteContent(formData): Observable<any> {
    const autoCreateWebSiteContent = URLS.autoCreateWebSiteContent ;
    return this.http.post(autoCreateWebSiteContent, formData);
  }
  ContentAssocType(): Observable<any> {
    const ContentAssocType = URLS.ContentAssocType ;
    return this.http.post(ContentAssocType, "");
  }
  removeCharacterSet(formData): Observable<any> {
    const removeCharacterSet = URLS.removeCharacterSet ;
    return this.http.post(removeCharacterSet, formData);
  }
  updateCharacterSet(formData): Observable<any> {
    const updateCharacterSet = URLS.updateCharacterSet ;
    return this.http.post(updateCharacterSet, formData);
  }
  addCharacterSet(formData): Observable<any> {
    const addCharacterSet = URLS.addCharacterSet ;
    return this.http.post(addCharacterSet, formData);
  }
  RemoveWebSiteContent(formData): Observable<any> {
    const RemoveWebSiteContent = URLS.RemoveWebSiteContent ;
    return this.http.post(RemoveWebSiteContent, formData);
  }
  removeDataResourceType(formData): Observable<any> {
    const removeDataResourceType = URLS.removeDataResourceType ;
    return this.http.post(removeDataResourceType, formData);
  }
  updateDataResourceType(formData): Observable<any> {
    const updateDataResourceType = URLS.updateDataResourceType ;
    return this.http.post(updateDataResourceType, formData);
  }
  addDataResourceType(formData): Observable<any> {
    const addDataResourceType = URLS.addDataResourceType ;
    return this.http.post(addDataResourceType, formData);
  }
  UpdateWebSiteContent(formData): Observable<any> {
    const UpdateWebSiteContent = URLS.UpdateWebSiteContent ;
    return this.http.post(UpdateWebSiteContent, formData);
  }
  rejectCustomerReq(formData): Observable<any> {
    const rejectCustomerReq = URLS.rejectCustomerReq ;
    return this.http.post(rejectCustomerReq, formData);
  }
  setCustRequestStatus(formData): Observable<any> {
    const setCustRequestStatus = URLS.setCustRequestStatus ;
    return this.http.post(setCustRequestStatus, formData);
  }
  allocateMsgToPartyBy(formData): Observable<any> {
    const allocateMsgToPartyBy = URLS.allocateMsgToPartyBy ;
    return this.http.post(allocateMsgToPartyBy, formData);
  }
  updateCommunicationEventWorkEff(formData): Observable<any> {
    const updateCommunicationEventWorkEff = URLS.updateCommunicationEventWorkEff ;
    return this.http.post(updateCommunicationEventWorkEff, formData);
  }
  deleteCommunicationEventWorkEff(formData): Observable<any> {
    const deleteCommunicationEventWorkEff = URLS.deleteCommunicationEventWorkEff ;
    return this.http.post(deleteCommunicationEventWorkEff, formData);
  }
  deleteWorkEffortRate(formData): Observable<any> {
    const deleteWorkEffortRate = URLS.deleteWorkEffortRate ;
    return this.http.post(deleteWorkEffortRate, formData);
  }
  makeNotePrivate(formData): Observable<any> {
    const makeNotePrivate = URLS.makeNotePrivate ;
    return this.http.post(makeNotePrivate, formData);
  }
  addProjectNote(formData): Observable<any> {
    const addProjectNote = URLS.addProjectNote ;
    return this.http.post(addProjectNote, formData);
  }
  updateWorkEffortSurveyAppl(formData): Observable<any> {
    const updateWorkEffortSurveyAppl = URLS.updateWorkEffortSurveyAppl ;
    return this.http.post(updateWorkEffortSurveyAppl, formData);
  }
  copyQuote(formData): Observable<any> {
    const copyQuote = URLS.copyQuote ;
    return this.http.post(copyQuote, formData);
  }
  deleteWorkEffortSurveyAppl(formData): Observable<any> {
    const deleteWorkEffortSurveyAppl = URLS.deleteWorkEffortSurveyAppl ;
    return this.http.post(deleteWorkEffortSurveyAppl, formData);
  }
  addTaskContent(formData): Observable<any> {
    const addTaskContent = URLS.addTaskContent ;
    return this.http.post(addTaskContent, formData);
  }
  addTaskNote(formData): Observable<any> {
    const addTaskNote = URLS.addTaskNote ;
    return this.http.post(addTaskNote, formData);
  }
  createWorkEffortSurveyAppl(formData): Observable<any> {
    const createWorkEffortSurveyAppl = URLS.createWorkEffortSurveyAppl ;
    return this.http.post(createWorkEffortSurveyAppl, formData);
  }
  updateWebAnalyticsConfig(formData): Observable<any> {
    const updateWebAnalyticsConfig = URLS.updateWebAnalyticsConfig ;
    return this.http.post(updateWebAnalyticsConfig, formData);
  }
  createWebAnalyticsConfig(formData): Observable<any> {
    const createWebAnalyticsConfig = URLS.createWebAnalyticsConfig ;
    return this.http.post(createWebAnalyticsConfig, formData);
  }
  createTask(formData): Observable<any> {
    const createTask = URLS.createTask ;
    return this.http.post(createTask, formData);
  }
  updateTaskNote(formData): Observable<any> {
    const updateTaskNote = URLS.updateTaskNote ;
    return this.http.post(updateTaskNote, formData);
  }
  updateTeleComNumber(formData): Observable<any> {
    const updateTeleComNumber = URLS.updateTeleComNumber ;
    return this.http.post(updateTeleComNumber, formData);
  }
  updatePostalAddressProfile(formData): Observable<any> {
    const updatePostalAddressProfile = URLS.updatePostalAddressProfile ;
    return this.http.post(updatePostalAddressProfile, formData);
  }
  addRole(formData): Observable<any> {
    const addRole = URLS.addRole ;
    return this.http.post(addRole, formData);
  }
  createSalesForecast(formData): Observable<any> {
    const createSalesForecast = URLS.createSalesForecast ;
    return this.http.post(createSalesForecast, formData);
  }
 
  updatePortalPageColumnWidth(formData): Observable<any> {
    const updatePortalPageColumnWidth = URLS.updatePortalPageColumnWidth ;
    return this.http.post(updatePortalPageColumnWidth, formData);
  }
  revertToOriginal(formData): Observable<any> {
    const revertToOriginal = URLS.revertToOriginal ;
    return this.http.post(revertToOriginal, formData);
  }
  createPortalPage(formData): Observable<any> {
    const createPortalPage = URLS.createPortalPage ;
    return this.http.post(createPortalPage, formData);
  }
  updateSalesForecast(formData): Observable<any> {
    const updateSalesForecast = URLS.updateSalesForecast ;
    return this.http.post(updateSalesForecast, formData);
  }
  deleteSalesForecastDetail(formData): Observable<any> {
    const deleteSalesForecastDetail = URLS.deleteSalesForecastDetail ;
    return this.http.post(deleteSalesForecastDetail, formData);
  }
  createSalesForecastDetail(formData): Observable<any> {
    const createSalesForecastDetail = URLS.createSalesForecastDetail ;
    return this.http.post(createSalesForecastDetail, formData);
  }
  FindSalesForecast(formData): Observable<any> {
    const FindSalesForecast = URLS.FindSalesForecast ;
    return this.http.post(FindSalesForecast, formData);
  }
  getFindSalesForecast(formData): Observable<any> {
    const getFindSalesForecast = URLS.getFindSalesForecast ;
    return this.http.post(getFindSalesForecast, formData);
  }
  createTeam(formData): Observable<any> {
    const createTeam = URLS.createTeam ;
    return this.http.post(createTeam, formData);
  }
  updateTeam(formData): Observable<any> {
    const updateTeam = URLS.updateTeam ;
    return this.http.post(updateTeam, formData);
  }
  deleteNoteCRM(formData): Observable<any> {
    const deleteNoteCRM = URLS.deleteNoteCRM ;
    return this.http.post(deleteNoteCRM, formData);
  }
  UpdateNoteCRM(formData): Observable<any> {
    const UpdateNoteCRM = URLS.UpdateNoteCRM ;
    return this.http.post(UpdateNoteCRM, formData);
  }
  createNoteCRM(formData): Observable<any> {
    const createNoteCRM = URLS.createNoteCRM ;
    return this.http.post(createNoteCRM, formData);
  }
  UpdatePendingActivityEventCrm(formData): Observable<any> {
    const UpdatePendingActivityEventCrm = URLS.UpdatePendingActivityEventCrm ;
    return this.http.post(UpdatePendingActivityEventCrm, formData);
  }
  updateCase(formData): Observable<any> {
    const updateCase = URLS.updateCase ;
    return this.http.post(updateCase, formData);
  }
  CreatePendingActivityEventCrm(formData): Observable<any> {
    const CreatePendingActivityEventCrm = URLS.CreatePendingActivityEventCrm ;
    return this.http.post(CreatePendingActivityEventCrm, formData);
  }
  updateWebSite(formData): Observable<any> {
    const updateWebSite = URLS.updateWebSite ;
    return this.http.post(updateWebSite, formData);
  }
  deletePendingActivityEventCrm(formData): Observable<any> {
    const deletePendingActivityEventCrm = URLS.deletePendingActivityEventCrm ;
    return this.http.post(deletePendingActivityEventCrm, formData);
  }
  createPendingActivityTask(formData): Observable<any> {
    const createPendingActivityTask = URLS.createPendingActivityTask ;
    return this.http.post(createPendingActivityTask, formData);
  }
  createLogTaskEmailPartner(formData): Observable<any> {
    const createLogTaskEmailPartner = URLS.createLogTaskEmailPartner ;
    return this.http.post(createLogTaskEmailPartner, formData);
  }
  createLogTaskCallPartner(formData): Observable<any> {
    const createLogTaskCallPartner = URLS.createLogTaskCallPartner ;
    return this.http.post(createLogTaskCallPartner, formData);
  }
  deleteLogTaskCallPartner(formData): Observable<any> {
    const deleteLogTaskCallPartner = URLS.deleteLogTaskCallPartner ;
    return this.http.post(deleteLogTaskCallPartner, formData);
  }
  updateDataResource(formData): Observable<any> {
    const updateDataResource = URLS.updateDataResource ;
    return this.http.post(updateDataResource, formData);
  }
  deleteLogTaskEmailPartner(formData): Observable<any> {
    const deleteLogTaskEmailPartner = URLS.deleteLogTaskEmailPartner ;
    return this.http.post(deleteLogTaskEmailPartner, formData);
  }
  UpdateLogTaskEmailPartnerLead(formData): Observable<any> {
    const UpdateLogTaskEmailPartnerLead = URLS.UpdateLogTaskEmailPartnerLead ;
    return this.http.post(UpdateLogTaskEmailPartnerLead, formData);
  }
  UpdateLogTaskCallPartnerLead(formData): Observable<any> {
    const UpdateLogTaskCallPartnerLead = URLS.UpdateLogTaskCallPartnerLead ;
    return this.http.post(UpdateLogTaskCallPartnerLead, formData);
  }
  createLogTaskCallPartnerLead(formData): Observable<any> {
    const createLogTaskCallPartnerLead = URLS.createLogTaskCallPartnerLead ;
    return this.http.post(createLogTaskCallPartnerLead, formData);
  }
  createBookMarksUrlPartnerLead(formData): Observable<any> {
    const createBookMarksUrlPartnerLead = URLS.createBookMarksUrlPartnerLead ;
    return this.http.post(createBookMarksUrlPartnerLead, formData);
  }
  deleteBookMarksUrlPartnerLead(formData): Observable<any> {
    const deleteBookMarksUrlPartnerLead = URLS.deleteBookMarksUrlPartnerLead ;
    return this.http.post(deleteBookMarksUrlPartnerLead, formData);
  }
  UpdateBookMarksUrlPartnerLead(formData): Observable<any> {
    const UpdateBookMarksUrlPartnerLead = URLS.UpdateBookMarksUrlPartnerLead ;
    return this.http.post(UpdateBookMarksUrlPartnerLead, formData);
  }
  createNotePartnerLead(formData): Observable<any> {
    const createNotePartnerLead = URLS.createNotePartnerLead ;
    return this.http.post(createNotePartnerLead, formData);
  }
  UpdateNotePartnerLead(formData): Observable<any> {
    const UpdateNotePartnerLead = URLS.UpdateNotePartnerLead ;
    return this.http.post(UpdateNotePartnerLead, formData);
  }
  deleteNotePartnerLead(formData): Observable<any> {
    const deleteNotePartnerLead = URLS.deleteNotePartnerLead ;
    return this.http.post(deleteNotePartnerLead, formData);
  }
  deleteLogTaskEmailPartnerLead(formData): Observable<any> {
    const deleteLogTaskEmailPartnerLead = URLS.deleteLogTaskEmailPartnerLead ;
    return this.http.post(deleteLogTaskEmailPartnerLead, formData);
  }
  deleteLogTaskCallPartnerLead(formData): Observable<any> {
    const deleteLogTaskCallPartnerLead = URLS.deleteLogTaskCallPartnerLead ;
    return this.http.post(deleteLogTaskCallPartnerLead, formData);
  }
  createLogTaskEmailPartnerLead(formData): Observable<any> {
    const createLogTaskEmailPartnerLead = URLS.createLogTaskEmailPartnerLead ;
    return this.http.post(createLogTaskEmailPartnerLead, formData);
  }
  UpdateLogTaskEmailPartner(formData): Observable<any> {
    const UpdateLogTaskEmailPartner = URLS.UpdateLogTaskEmailPartner ;
    return this.http.post(UpdateLogTaskEmailPartner, formData);
  }
  UpdateLogTaskCallPartner(formData): Observable<any> {
    const UpdateLogTaskCallPartner = URLS.UpdateLogTaskCallPartner ;
    return this.http.post(UpdateLogTaskCallPartner, formData);
  }
  promo_updateProductStorePromoAppl(formData): Observable<any> {
    const promo_updateProductStorePromoAppl = URLS.promo_updateProductStorePromoAppl ;
    return this.http.post(promo_updateProductStorePromoAppl, formData);
  }
  promo_createProductStorePromoAppl(formData): Observable<any> {
    const promo_createProductStorePromoAppl = URLS.promo_createProductStorePromoAppl ;
    return this.http.post(promo_createProductStorePromoAppl, formData);
  }
  updateProductPromoCode(formData): Observable<any> {
    const updateProductPromoCode = URLS.updateProductPromoCode ;
    return this.http.post(updateProductPromoCode, formData);
  }
  deleteProductPromoCodeParty(formData): Observable<any> {
    const deleteProductPromoCodeParty = URLS.deleteProductPromoCodeParty ;
    return this.http.post(deleteProductPromoCodeParty, formData);
  }
  createProductPromoCodeParty(formData): Observable<any> {
    const createProductPromoCodeParty = URLS.createProductPromoCodeParty ;
    return this.http.post(createProductPromoCodeParty, formData);
  }
  createMimeTypeHtmlTemplate(formData): Observable<any> {
    const createMimeTypeHtmlTemplate = URLS.createMimeTypeHtmlTemplate ;
    return this.http.post(createMimeTypeHtmlTemplate, formData);
  }
  removeMimeTypeHtmlTemplate(formData): Observable<any> {
    const removeMimeTypeHtmlTemplate = URLS.removeMimeTypeHtmlTemplate ;
    return this.http.post(removeMimeTypeHtmlTemplate, formData);
  }
  updateMimeTypeHtmlTemplate(formData): Observable<any> {
    const updateMimeTypeHtmlTemplate = URLS.updateMimeTypeHtmlTemplate ;
    return this.http.post(updateMimeTypeHtmlTemplate, formData);
  }
  updateFileExtension(formData): Observable<any> {
    const updateFileExtension = URLS.updateFileExtension ;
    return this.http.post(updateFileExtension, formData);
  }
  removeFileExtension(formData): Observable<any> {
    const removeFileExtension = URLS.removeFileExtension ;
    return this.http.post(removeFileExtension, formData);
  }
  addFileExtension(req): Observable<any> {
    const addFileExtension = URLS.addFileExtension;
    return this.http.post(addFileExtension, req);
  }
  updateProductCode(formData): Observable<any> {
    const updateProductCode = URLS.updateProductCode ;
    return this.http.post(updateProductCode, formData);
  }
  deleteProductPromoCode(formData): Observable<any> {
    const deleteProductPromoCode = URLS.deleteProductPromoCode ;
    return this.http.post(deleteProductPromoCode, formData);
  }
  createNewProductPromo(req): Observable<any> {
    const createNewProductPromo = URLS.createNewProductPromo;
    return this.http.post(createNewProductPromo, req);
  }
  
  createProductPromoCode(formData): Observable<any> {
    const createProductPromoCode = URLS.createProductPromoCode ;
    return this.http.post(createProductPromoCode, formData);
  }
  promo_deleteProductStorePromoAppl(formData): Observable<any> {
    const promo_deleteProductStorePromoAppl = URLS.promo_deleteProductStorePromoAppl ;
    return this.http.post(promo_deleteProductStorePromoAppl, formData);
  }
  addDataResourceTypeAttr(formData): Observable<any> {
    const addDataResourceTypeAttr = URLS.addDataResourceTypeAttr ;
    return this.http.post(addDataResourceTypeAttr, formData);
  }
  removeDataResourceTypeAttr(formData): Observable<any> {
    const removeDataResourceTypeAttr = URLS.removeDataResourceTypeAttr ;
    return this.http.post(removeDataResourceTypeAttr, formData);
  }
 
  deletePendingActivityTask(formData): Observable<any> {
    const deletePendingActivityTask = URLS.deletePendingActivityTask ;
    return this.http.post(deletePendingActivityTask, formData);
  }
  deleteFsmInvoice(formData): Observable<any> {
    const deleteFsmInvoice = URLS.deleteFsmInvoice ;
    return this.http.post(deleteFsmInvoice, formData);
  }
  createCase(formData): Observable<any> {
    const createCase = URLS.createCase ;
    return this.http.post(createCase, formData);
  }
  addTeamMemberInviewTeam(formData): Observable<any> {
    const addTeamMemberInviewTeam = URLS.addTeamMemberInviewTeam ;
    return this.http.post(addTeamMemberInviewTeam, formData);
  }
  createBillingAccount(formData): Observable<any> {
    const createBillingAccount = URLS.createBillingAccount ;
    return this.http.post(createBillingAccount, formData);
  }
  dataResourceTypeIdDropDown(): Observable<any> {
    const dataResourceTypeIdDropDown = URLS.dataResourceTypeIdDropDown ;
    return this.http.get(dataResourceTypeIdDropDown);
  }
  getTeamMember(): Observable<any> {
    const getTeamMember = URLS.getTeamMember ;
    return this.http.get(getTeamMember);
  }
  getRates(partyId): Observable<any> {
    const getRates = URLS.getRates + "?partyId=" + partyId;
    return this.http.get(getRates);
  }
  updateProfileUserLoginPassword(fromData): Observable<any> {
    const updateProfileUserLoginPassword = URLS.updateProfileUserLoginPassword;
    return this.http.put(updateProfileUserLoginPassword, fromData);
  }
  getCommunicationContentUploadById(communicationContentUploadId): Observable<any> {
    const getCommunicationContentUploadById = URLS.getCommunicationContentUploadById + "?communicationContentUploadId=" + communicationContentUploadId;
    return this.http.get(getCommunicationContentUploadById);
  }
  getCommunicationCalender(): Observable<any> {
    const getCommunicationCalender = URLS.getCommunicationCalender;
    return this.http.get(getCommunicationCalender);
  }
  getparentContentIdList(): Observable<any> {
    const getparentContentIdList = URLS.getparentContentIdList;
    return this.http.get(getparentContentIdList);
  }
  getexistingContentIds(): Observable<any> {
    const getexistingContentIds = URLS.getexistingContentIds;
    return this.http.get(getexistingContentIds);
  }
  getEventRoleType(): Observable<any> {
    const getEventRoleType = URLS.getEventRoleType;
    return this.http.get(getEventRoleType);
  }
  getEventRoles(data, id): Observable<any> {
    const getEventRoles = URLS.getEventRoles.replace(":communicationEventId", id) + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getEventRoles);
  }
  getProductStoreByProductPromoId(id): Observable<any> {
    const getProductStoreByProductPromoId = URLS.getProductStoreByProductPromoId.replace(":productPromoId", id);
    return this.http.get(getProductStoreByProductPromoId);
  }
  getCreatePortalPage(): Observable<any> {
    const getCreatePortalPage = URLS.getCreatePortalPage;
    return this.http.get(getCreatePortalPage);
  }
  
  getCommunicationEventsReq(communicationEventId ): Observable<any> {
    const getCommunicationEventsReq = URLS.getCommunicationEventsReq + "?communicationEventId=" + communicationEventId;
    return this.http.get(getCommunicationEventsReq);
  }
  getCreatePortalPageByIds(parentPortalPageId ,portalPageId): Observable<any> {
    const getCreatePortalPageByIds = URLS.getCreatePortalPageByIds + "?parentPortalPageId=" + parentPortalPageId+ "&portalPageId=" + portalPageId;
    return this.http.get(getCreatePortalPageByIds);
  }
  getSalesForecastDetailById(salesForecastId ): Observable<any> {
    const getSalesForecastDetailById = URLS.getSalesForecastDetailById + "?salesForecastId=" + salesForecastId;
    return this.http.get(getSalesForecastDetailById);
  }
  getCreatePortalPageById(parentPortalPageId): Observable<any> {
    const getCreatePortalPageById = URLS.getCreatePortalPageById + "?parentPortalPageId=" + parentPortalPageId;
    return this.http.get(getCreatePortalPageById);
  }
  MimeTypeHtmlTemplate(): Observable<any> {
    const MimeTypeHtmlTemplate = URLS.MimeTypeHtmlTemplate;
    return this.http.get(MimeTypeHtmlTemplate);
  }
  MimeTypeHtmlTemplateById(id): Observable<any> {
    const MimeTypeHtmlTemplateById = URLS.MimeTypeHtmlTemplateById + "?mimeTypeId=" + id;
    return this.http.get(MimeTypeHtmlTemplateById);
  }
  getRoleTypeIdPre(): Observable<any> {
    const getRoleTypeIdPre = URLS.getRoleTypeIdPre;
    return this.http.get(getRoleTypeIdPre);
  }
  getProfileRoleList(): Observable<any> {
    const getProfileRoleList = URLS.getProfileRoleList;
    return this.http.get(getProfileRoleList);
  }
  sendSaveCommunicationEventSave(formData): Observable<any> {
    const sendSaveCommunicationEventSave = URLS.sendSaveCommunicationEventSave  ;
    return this.http.post(sendSaveCommunicationEventSave, formData);
  }
  getManagePortalPagesEmailsUnKnown(): Observable<any> {
    const getManagePortalPagesEmailsUnKnown = URLS.getManagePortalPagesEmailsUnKnown;
    return this.http.get(getManagePortalPagesEmailsUnKnown);
  }
  deleteCommunicationEventsBy(formData): Observable<any> {
    const deleteCommunicationEventsBy = URLS.deleteCommunicationEventsBy ;
    return this.http.post(deleteCommunicationEventsBy, formData);
  }
  getInvoiceTimesheetToInvoice():Observable<any>{
   const getInvoiceTimesheetToInvoice = URLS.getInvoiceTimesheetToInvoice;
   return this.http.get(getInvoiceTimesheetToInvoice);
  }
  getDepartmentById( id): Observable<any> {
    const getDepartmentById = URLS.getDepartmentById.replace(":departmentId", id);
    return this.http.get(getDepartmentById);
  }
  getEventCommRoles(data, id): Observable<any> {
    const getEventRoles = URLS.getEventCommRoles.replace(":communicationEventId", id) + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getEventRoles);
  }
  deletePortalPage(formData): Observable<any> {
    const deletePortalPage = URLS.deletePortalPage ;
    return this.http.post(deletePortalPage, formData);
  }
 
  sendCommunicationEventSave(formData): Observable<any> {
    const sendCommunicationEventSave = URLS.sendCommunicationEventSave  ;
    return this.http.post(sendCommunicationEventSave, formData);
  }
  sendCommunicationEvent(formData): Observable<any> {
    const sendCommunicationEvent = URLS.sendCommunicationEvent  ;
    return this.http.post(sendCommunicationEvent, formData);
  }
  deleteUpload(communicationContentUploadId): Observable<any> {
    const deleteUpload = URLS.deleteUpload + "?communicationContentUploadId=" +communicationContentUploadId;
    return this.http.post(deleteUpload,"");
  }
  sendSaveCommunicationEvent(formData): Observable<any> {
    const sendSaveCommunicationEvent = URLS.sendSaveCommunicationEvent  ;
    return this.http.post(sendSaveCommunicationEvent, formData);
  }
  createUpload(formData,ParentContentId,existingContentId): Observable<any> {
    const createUpload = URLS.createUpload + "?ParentContentId=" + ParentContentId + "&existingContentId=" + existingContentId ;
    return this.http.post(createUpload, formData);
  }

  deleteCommunicationEventRole(id, pid, rid): Observable<any> {
    const deleteCommunicationEventRole = URLS.deleteCommunicationEventRole.replace(":communicationEventId", id) + "?partyId=" + pid + "&roleTypeId=" + rid;
    return this.http.delete(deleteCommunicationEventRole);
  }

  createCommunicationEventRole(fromData, communicationEventId): Observable<any> {
    const createCommunicationEventRole = URLS.createCommunicationEventRole.replace(":communicationEventId", communicationEventId);
    return this.http.post(createCommunicationEventRole, fromData);
  }
  deleteRole(partyId, role): Observable<any> {
    const deleteRoleTypeId = URLS.deleteProfileRole + "?partyId=" + partyId + "&roleTypeId=" + role;
    return this.http.delete(deleteRoleTypeId);
  }
  getPreNewSecond(columnSeqId, parentPortalPageId, portalPortletId , portletCategoryId, portalPageId): Observable<any> {
    const getPreNewSecond = URLS.getPreNewSecond+ "?columnSeqId=" + columnSeqId + "&parentPortalPageId=" + parentPortalPageId+ "&portalPortletId=" + portalPortletId+ "&portletCategoryId=" + portletCategoryId+ "&portalPageId=" + portalPageId;
    return this.http.get(getPreNewSecond);
  }
  getPreNew(columnSeqId, portalPageId): Observable<any> {
    const getPreNew = URLS.getPreNew+ "?columnSeqId=" + columnSeqId + "&portalPageId=" + portalPageId;
    return this.http.get(getPreNew);
  }
  getNewList(): Observable<any> {
    const getNewList = URLS.getNewList;
    return this.http.get(getNewList);
  }
  getMainPageRequestListDetails(): Observable<any> {
    const getMainPageRequestListDetails = URLS.getMainPageRequestListDetails;
    return this.http.get(getMainPageRequestListDetails);
  }
  ParentSalesForecastID(): Observable<any> {
    const ParentSalesForecastID  = URLS.ParentSalesForecastID;
    return this.http.get(ParentSalesForecastID);
  }
  getUpdateSalesForecastById(salesForecastId): Observable<any> {
    const getUpdateSalesForecastById = URLS.getUpdateSalesForecastById+ "?salesForecastId=" + salesForecastId;
    return this.http.get(getUpdateSalesForecastById);
  }
  getRequestListDetails(): Observable<any> {
    const getRequestListDetails = URLS.getRequestListDetails;
    return this.http.get(getRequestListDetails);
  }
  getRateData(): Observable<any> {
    const getRateData = URLS.getRateData;
    return this.http.get(getRateData);
  }
  getGantChart(workEffortParentId): Observable<any> {
    const getGantChart = URLS.getGantChart+ "?workEffortParentId=" + workEffortParentId;
    return this.http.get(getGantChart);
  }
  getMainPageSecondRequestListDetails(): Observable<any> {
    const getMainPageSecondRequestListDetails = URLS.getMainPageSecondRequestListDetails;
    return this.http.get(getMainPageSecondRequestListDetails);
  }
  getProfileContentList(partyId): Observable<any> {
    const getProfileContentList = URLS.getProfileContentList+ "?partyId=" + partyId;
    return this.http.get(getProfileContentList);
  }
  FindCases(custRequestName,custRequestTypeId,priority,statusId): Observable<any> {
    const FindCases = URLS.FindCases+ "?custRequestName=" + custRequestName+ "&custRequestTypeId=" + custRequestTypeId
    + "&priority=" + priority+ "&statusId=" + statusId;
    return this.http.get(FindCases);
  }
  getPriority(): Observable<any> {
    const getPriority = URLS.getPriority;
    return this.http.get(getPriority);
  }
  getReason(): Observable<any> {
    const getReason = URLS.getReason;
    return this.http.get(getReason);
  }
  getMyCases(): Observable<any> {
    const getMyCases = URLS.getMyCases;
    return this.http.get(getMyCases);
  }
  getTypeCrm(): Observable<any> {
    const getTypeCrm = URLS.getTypeCrm;
    return this.http.get(getTypeCrm);
  }
  getStatusCrm(): Observable<any> {
    const getStatusCrm = URLS.getStatusCrm;
    return this.http.get(getStatusCrm);
  }
  getFindCases(): Observable<any> {
    const getFindCases = URLS.getFindCases;
    return this.http.get(getFindCases);
  }
  getDataResourceProductFeature(id): Observable<any> {
    const getDataResourceProductFeature = URLS.getDataResourceProductFeature+ 
    "?dataResourceId=" + id;
    return this.http.get(getDataResourceProductFeature);
  }
  
  viewroles(partyId): Observable<any> {
    const viewroles = URLS.viewroles+ "?partyId=" + partyId;
    return this.http.get(viewroles);
  }
  createProfileContent(formData,data): Observable<any> {
    const createProfileContent = URLS.createProfileContent + "?json=" + data;
    return this.http.post(createProfileContent, formData);
  }
  updateProfileContent(formData,data,contentId,dataResourceId): Observable<any> {
    const updateProfileContent = URLS.updateProfileContent + "?json=" + data + "&contentId=" + contentId + "&dataResourceId=" + dataResourceId;
    return this.http.post(updateProfileContent, formData);
  }
  getRevisions(): Observable<any> {
    const getRevisions = URLS.getRevisions;
    return this.http.get(getRevisions);
  }
  getEmploymentStatusEnumId(): Observable<any> {
    const getEmploymentStatusEnumId = URLS.getEmploymentStatusEnumId;
    return this.http.get(getEmploymentStatusEnumId);
  }
  getResidenceStatusEnumID(): Observable<any> {
    const getResidenceStatusEnumID = URLS.getResidenceStatusEnumID;
    return this.http.get(getResidenceStatusEnumID);
  }
  getpartyContentDropdown(): Observable<any> {
    const getPartyContent = URLS.getpartyContentDropdown;
    return this.http.get(getPartyContent);
  }
  deleteProfileContentData(contentId, fromDate,partyContentTypeId,partyId): Observable<any> {
    const deleteProfileContentData = URLS.deleteProfileContentData + "?contentId=" + contentId + "&fromDate=" + fromDate + "&partyContentTypeId=" + partyContentTypeId + "&partyId=" + partyId;
    return this.http.delete(deleteProfileContentData);
  }
  getPartyContentTypeIdList(): Observable<any> {
    const getPartyContentTypeIdList = URLS.getProfileContentTypeIdList;
    return this.http.get(getPartyContentTypeIdList);
  }
  getDataCategoryIdList(): Observable<any> {
    const getDataCategoryIdList = URLS.getDataCategoryIdTypeList;
    return this.http.get(getDataCategoryIdList);
  }
  getPartyStatusIdList(): Observable<any> {
    const getPartyStatusIdList = URLS.getProfileStatusIdList;
    return this.http.get(getPartyStatusIdList);
  }
  getDataResourceProductFeatureAll(): Observable<any> {
    const getDataResourceProductFeatureAll = URLS.getDataResourceProductFeatureAll;
    return this.http.get(getDataResourceProductFeatureAll);
  }
  productFeatureIds(): Observable<any> {
    const productFeatureIds = URLS.productFeatureIds;
    return this.http.get(productFeatureIds);
  }
  createPostRoleTypeData(formData, id): Observable<any> {
    const createPostRoleTypeData = URLS.createPostRoleTypeData + "?partyId=" + id;
    return this.http.post(createPostRoleTypeData, formData);
  }
  addMimeType(formData): Observable<any> {
    const addMimeType = URLS.addMimeType;
    return this.http.post(addMimeType, formData);
  }
  createComputerHardware(formData): Observable<any> {
    const createComputerHardware = URLS.createComputerHardware;
    return this.http.post(createComputerHardware, formData);
  }
  updateMimeType(formData): Observable<any> {
    const updateMimeType = URLS.updateMimeType;
    return this.http.post(updateMimeType, formData);
  }
  createComputerSoftware(formData): Observable<any> {
    const createComputerSoftware = URLS.createComputerSoftware;
    return this.http.post(createComputerSoftware, formData);
  }
  removeMimeType(formData): Observable<any> {
    const removeMimeType = URLS.removeMimeType;
    return this.http.post(removeMimeType, formData);
  }
  updateComputerHardware(formData): Observable<any> {
    const updateComputerHardware = URLS.updateComputerHardware;
    return this.http.post(updateComputerHardware, formData);
  }
  ListComputerHardware(formData): Observable<any> {
    const ListComputerHardware = URLS.ListComputerHardware;
    return this.http.post(ListComputerHardware, formData);
  }
  updateComputerSoftware(formData): Observable<any> {
    const updateComputerSoftware = URLS.updateComputerSoftware;
    return this.http.post(updateComputerSoftware, formData);
  }
  addDataCategory(formData): Observable<any> {
    const addDataCategory = URLS.addDataCategory;
    return this.http.post(addDataCategory, formData);
  }
  removeDataCategory(formData): Observable<any> {
    const removeDataCategory = URLS.removeDataCategory;
    return this.http.post(removeDataCategory, formData);
  }
  updateDataCategory(formData): Observable<any> {
    const updateDataCategory = URLS.updateDataCategory;
    return this.http.post(updateDataCategory, formData);
  }
  createDataResourceProductFeature(formData): Observable<any> {
    const createDataResourceProductFeature = URLS.createDataResourceProductFeature;
    return this.http.post(createDataResourceProductFeature, formData);
  }
  removeDataResourceProductFeature(formData): Observable<any> {
    const removeDataResourceProductFeature = URLS.removeDataResourceProductFeature;
    return this.http.post(removeDataResourceProductFeature, formData);
  }
  createAddNewWeeklyTimesheet(formData): Observable<any> {
    const createAddNewWeeklyTimesheet = URLS.createAddNewWeeklyTimesheet;
    return this.http.post(createAddNewWeeklyTimesheet, formData);
  }
  updateTimesheet(ids,id): Observable<any> {
    const updateTimesheet = URLS.updateTimesheet + "?statusId=" + ids + "&timesheetId=" + id;
    return this.http.post(updateTimesheet, "");
  }
  updateSubscription(formData): Observable<any> {
    const updateSubscription = URLS.updateSubscription;
    return this.http.post(updateSubscription, formData);
  }
  createWeeklyTimesheet(formData): Observable<any> {
    const createWeeklyTimesheet = URLS.createWeeklyTimesheet;
    return this.http.post(createWeeklyTimesheet, formData);
  }
  addWorkEffortPartyAssign(formData): Observable<any> {
    const addWorkEffortPartyAssign = URLS.addWorkEffortPartyAssign;
    return this.http.post(addWorkEffortPartyAssign, formData);
  }
  addProjectTimeToNewInvoice(formData): Observable<any> {
    const addProjectTimeToNewInvoice = URLS.addProjectTimeToNewInvoice;
    return this.http.post(addProjectTimeToNewInvoice, formData);
  }
  addProjectTimeToInvoice(formData): Observable<any> {
    const addProjectTimeToInvoice = URLS.addProjectTimeToInvoice;
    return this.http.post(addProjectTimeToInvoice, formData);
  }
  AddSubProject(formData): Observable<any> {
    const AddSubProject = URLS.AddSubProject;
    return this.http.post(AddSubProject, formData);
  }
  updateSubscriptionAttribute(formData): Observable<any> {
    const updateSubscriptionAttribute = URLS.updateSubscriptionAttribute;
    return this.http.post(updateSubscriptionAttribute, formData);
  }
  createSubscriptionAttribute(formData): Observable<any> {
    const createSubscriptionAttribute = URLS.createSubscriptionAttribute;
    return this.http.post(createSubscriptionAttribute, formData);
  }
  addPhase(formData): Observable<any> {
    const addPhase = URLS.addPhase;
    return this.http.post(addPhase, formData);
  }
  deleteFsmTimeCard(formData): Observable<any> {
    const deleteFsmTimeCard = URLS.deleteFsmTimeCard;
    return this.http.post(deleteFsmTimeCard, formData);
  }
  deleteNotes(formData): Observable<any> {
    const deleteNotes = URLS.deleteNotes;
    return this.http.post(deleteNotes, formData);
  }
  createNotes(formData): Observable<any> {
    const createNotes = URLS.createNotes;
    return this.http.post(createNotes, formData);
  }
  updateNotes(formData): Observable<any> {
    const updateNotes = URLS.updateNotes;
    return this.http.post(updateNotes, formData);
  }
  updateFsmTimeCard(formData): Observable<any> {
    const updateFsmTimeCard = URLS.updateFsmTimeCard;
    return this.http.post(updateFsmTimeCard, formData);
  }
  createFsmTimeCard(formData): Observable<any> {
    const createFsmTimeCard = URLS.createFsmTimeCard;
    return this.http.post(createFsmTimeCard, formData);
  }
  updatePhase(formData): Observable<any> {
    const updatePhase = URLS.updatePhase;
    return this.http.post(updatePhase, formData);
  }
  createWebSiteRole(formData): Observable<any> {
    const createWebSiteRole = URLS.createWebSiteRole;
    return this.http.post(createWebSiteRole, formData);
  }
  removeWebSiteRole(formData): Observable<any> {
    const removeWebSiteRole = URLS.removeWebSiteRole;
    return this.http.post(removeWebSiteRole, formData);
  }
  createProject(formData): Observable<any> {
    const createProject = URLS.createProject;
    return this.http.post(createProject, formData);
  }
  createOrderId(formData): Observable<any> {
    const createOrderId = URLS.createOrderId;
    return this.http.post(createOrderId, formData);
  }
  updateProject(formData): Observable<any> {
    const updateProject = URLS.updateProject;
    return this.http.post(updateProject, formData);
  }
  getPartyRoleList(data, id): Observable<any> {
    const getPartyRoleList = URLS.getPartyRoleList + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize + "&partyId=" + id;
    return this.http.get(getPartyRoleList);
  }
  getParty(): Observable<any> {
    const getPartyContent = URLS.partyContentDropdown;
    return this.http.get(getPartyContent);
  }
  createPartyNote(formData,partyId): Observable<any> {
    const createPartyNote = URLS.createPartyNote+ "?partyId=" + partyId;
    return this.http.post(createPartyNote,formData);
  }
  getFindTask(fromDate,partyId,projectName,projectStatusId,workEffortId,workEffortName): Observable<any> {
    const getFindTask = URLS.getFindTask + "?fromDate=" + fromDate + "&partyId=" + partyId + 
    "&projectName=" + projectName + "&projectStatusId=" + projectStatusId 
    + "&workEffortId=" + workEffortId + "&workEffortName=" + workEffortName;
    return this.http.get(getFindTask);
  }

  getFindProject(workEffortId,workEffortName): Observable<any> {
    const getFindProject = URLS.getFindProject + "?workEffortID=" + workEffortId + "&workEffortName=" + workEffortName;
    return this.http.get(getFindProject);
  }
  getFindProjectList(): Observable<any> {
    const getFindProject = URLS.getFindProject;
    return this.http.get(getFindProject);
  }
  findMailing(communicationEventId,entryDate,partyIdTo,roleTypeIdTo,workEffortId): Observable<any> {
    const findMailing = URLS.findMailing + "?communicationEventId=" + communicationEventId + "&entryDate=" + entryDate + "&partyIdTo=" + partyIdTo + "&roleTypeIdTo=" + roleTypeIdTo + "&workEffortId=" + workEffortId;
    return this.http.get(findMailing);
  }
  findMailingList(): Observable<any> {
    const getFindProject = URLS.findMailingList;
    return this.http.get(getFindProject);
  }
  createProfileIdentifications(formData): Observable<any> {
    const createProfileIdentifications = URLS.createProfileIdentifications;
    return this.http.post(createProfileIdentifications,formData);
  }
  createNewTask(formData): Observable<any> {
    const createNewTask = URLS.createNewTask;
    return this.http.post(createNewTask,formData);
  }
  addAssignAndExistingTask(formData): Observable<any> {
    const addAssignAndExistingTask = URLS.addAssignAndExistingTask;
    return this.http.post(addAssignAndExistingTask,formData);
  }
  identificationProfileList(): Observable<any> {
    const identificationProfileList = URLS.identificationProfileList;
    return this.http.get(identificationProfileList);
  }
  deleteAddUserLoginToSecurityGroup(fromData,id): Observable<any> {
    const deleteAddUserLoginToSecurityGroup = URLS.deleteAddUserLoginToSecurityGroup+ "?userLoginId=" + id;;
    return this.http.post(deleteAddUserLoginToSecurityGroup, fromData);
  }

  getPriorityList(): Observable<any> {
    const getPriorityList = URLS.getPriorityList;
    return this.http.get(getPriorityList);
  }
  getScopeList(): Observable<any> {
    const getScopeList = URLS.getScopeList;
    return this.http.get(getScopeList);
  }
  getParentProjectNameList(): Observable<any> {
    const getParentProjectNameList = URLS.getParentProjectNameList;
    return this.http.get(getParentProjectNameList);
  }
  getInternalOrganizationFindEmployment(): Observable<any> {
    const getInternalOrganizationFindEmployment = URLS.getInternalOrganizationFindEmployment;
    return this.http.get(getInternalOrganizationFindEmployment);
  }
  getTemplateId(): Observable<any> {
    const getTemplateId = URLS.getTemplateId;
    return this.http.get(getTemplateId);
  }
  getInvoiceId(): Observable<any> {
    const getInvoiceId = URLS.getInvoiceId;
    return this.http.get(getInvoiceId);
  }
  deleteProfileIdentification(partyId,partyIdentificationTypeId): Observable<any> {
    const deleteProfileIdentification = URLS.deleteProfileIdentification + "?partyId=" + partyId + "&partyIdentificationTypeId=" + partyIdentificationTypeId;
    return this.http.delete(deleteProfileIdentification);
  }
  deleterole(partyId): Observable<any> {
    const deleterole = URLS.deleterole + "?partyId=" + partyId;
    return this.http.delete(deleterole);
  }
  updateAddUserLoginSecurityGroup(fromData,id): Observable<any> {
    const updateAddUserLoginSecurityGroup = URLS.updateAddUserLoginSecurityGroup+ "?userLoginId=" + id;;
    return this.http.post(updateAddUserLoginSecurityGroup, fromData);
  }
  addDependencyTo(fromData): Observable<any> {
    const addDependencyTo = URLS.addDependencyTo;
    return this.http.post(addDependencyTo, fromData);
  }
  addDependencyFrom(fromData): Observable<any> {
    const addDependencyFrom = URLS.addDependencyFrom;
    return this.http.post(addDependencyFrom, fromData);
  }
  setCompleteTask(fromData): Observable<any> {
    const setCompleteTask = URLS.setCompleteTask;
    return this.http.post(setCompleteTask, fromData);
  }
  updateTaskSave(fromData): Observable<any> {
    const updateTaskSave = URLS.updateTaskSave;
    return this.http.post(updateTaskSave, fromData);
  }
  createTaskPartyAssign(fromData): Observable<any> {
    const createTaskPartyAssign = URLS.createTaskPartyAssign;
    return this.http.post(createTaskPartyAssign, fromData);
  }
  updateMyTask(fromData): Observable<any> {
    const updateMyTask = URLS.updateMyTask;
    return this.http.post(updateMyTask, fromData);
  }
  createTaskSkillStandard(fromData): Observable<any> {
    const createTaskSkillStandard = URLS.createTaskSkillStandard;
    return this.http.post(createTaskSkillStandard, fromData);
  }
  updateTaskSkillStandard(fromData): Observable<any> {
    const updateTaskSkillStandard = URLS.updateTaskSkillStandard;
    return this.http.post(updateTaskSkillStandard, fromData);
  }
  getNotesAll(): Observable<any> {
    const getNotesAll = URLS.getNotesAll;
    return this.http.get(getNotesAll);
  }
  getNotesByPartyId(): Observable<any> {
    const getNotesByPartyId = URLS.getNotesByPartyId;
    return this.http.get(getNotesByPartyId);
  }
  getNotesByNoteId(id): Observable<any> {
    const getNotesByNoteId = URLS.getNotesByNoteId + "?noteId=" + id;
    return this.http.get(getNotesByNoteId);
  }
  getSurveyData(workEffortId): Observable<any> {
    const getSurveyData = URLS.getSurveyData + "?workEffortId=" + workEffortId;
    return this.http.get(getSurveyData);
  }
  getRequestRolesById(custRequestId): Observable<any> {
    const getRequestRolesById = URLS.getRequestRolesById + "?custRequestId=" + custRequestId;
    return this.http.get(getRequestRolesById);
  }
  getTaskNameListExistingTask(): Observable<any> {
    const getTaskNameListExistingTask = URLS.getTaskNameListExistingTask ;
    return this.http.get(getTaskNameListExistingTask);
  }
  getRequestItemData(custRequestId): Observable<any> {
    const getRequestItemData = URLS.getRequestItemData + "?custRequestId=" + custRequestId;
    return this.http.get(getRequestItemData);
  }
  getCustRequestIdAndSeq(custRequestId): Observable<any> {
    const getCustRequestIdAndSeq = URLS.getCustRequestIdAndSeq + "?custRequestId=" + custRequestId;
    return this.http.get(getCustRequestIdAndSeq);
  }
  getRoleTypeIdRequest(): Observable<any> {
    const getRoleTypeIdRequest = URLS.getRoleTypeIdRequest ;
    return this.http.get(getRoleTypeIdRequest);
  }
  deleteCustRequestContents(fromData): Observable<any> {
    const deleteCustRequestContents = URLS.deleteCustRequestContents;
    return this.http.post(deleteCustRequestContents, fromData);
  }
  acceptRequestAndConnectToTask(fromData): Observable<any> {
    const acceptRequestAndConnectToTask = URLS.acceptRequestAndConnectToTask;
    return this.http.post(acceptRequestAndConnectToTask, fromData);
  }
  createTimeEntry(fromData): Observable<any> {
    const createTimeEntry = URLS.createTimeEntry;
    return this.http.post(createTimeEntry, fromData);
  }
  getRelatedTask(): Observable<any> {
    const getRelatedTask = URLS.getRelatedTask ;
    return this.http.get(getRelatedTask);
  }
  removeSite(id,contentId,contentIdTo): Observable<any> {
    const removeSite = URLS.removeSite + "?contentAssocTypeId=" + id + "&contentId=" + 
    contentId+ "&contentIdTo=" + contentIdTo;
    return this.http.get(removeSite);
  }
  getListAddUserLoginToSecurityGroup(id,userLoginId): Observable<any> {
    const getListAddUserLoginToSecurityGroup = URLS.getListAddUserLoginToSecurityGroup + "?partyId=" + id + "&userLoginId=" + userLoginId;
    return this.http.get(getListAddUserLoginToSecurityGroup);
  }
  profileAddUserLoginSecurityGroup(fromData,id): Observable<any> {
    const profileAddUserLoginSecurityGroup = URLS.profileAddUserLoginSecurityGroup+ "?userLoginId=" + id;;
    return this.http.post(profileAddUserLoginSecurityGroup, fromData);
  }
  makePrivate(fromData): Observable<any> {
    const makePrivate = URLS.makePrivate;
    return this.http.post(makePrivate, fromData);
  }
  createRequest(fromData): Observable<any> {
    const createRequest = URLS.createRequest;
    return this.http.post(createRequest, fromData);
  }
  createSkillType(fromData): Observable<any> {
    const createSkillType = URLS.createSkillType;
    return this.http.post(createSkillType, fromData);
  }
  getProjectSummary(): Observable<any> {
    const getProjectSummary = URLS.getProjectSummary;
    return this.http.get(getProjectSummary);
  }
  getCustRequestTypeID(): Observable<any> {
    const getCustRequestTypeID = URLS.getCustRequestTypeID;
    return this.http.get(getCustRequestTypeID);
  }
  getParentCustRequestId(): Observable<any> {
    const getParentCustRequestId = URLS.getParentCustRequestId;
    return this.http.get(getParentCustRequestId);
  }
  getProjectAndPhaseName(): Observable<any> {
    const getProjectAndPhaseName = URLS.getProjectAndPhaseName;
    return this.http.get(getProjectAndPhaseName);
  }
  
  getClassificationGroup(): Observable<any> {
    const getClassificationGroup = URLS.getClassificationGroup;
    return this.http.get(getClassificationGroup);
  }
  indexContentTree(fromData): Observable<any> {
    const indexContentTree = URLS.indexContentTree;
    return this.http.post(indexContentTree, fromData);
  }
  createrequest(fromData): Observable<any> {
    const createrequest = URLS.createrequest;
    return this.http.post(createrequest, fromData);
  }
  CMSSites(id): Observable<any> {
    const CMSSites = URLS.CMSSites+ "?forumId=" + id;
    return this.http.get(CMSSites);
  }
  getCatalogRequestAllLead(): Observable<any> {
    const getCatalogRequestAllLead = URLS.getCatalogRequestAllLead ;
    return this.http.get(getCatalogRequestAllLead);
  }
  getCatalogRequestByIdLead(id): Observable<any> {
    const getCatalogRequestByIdLead = URLS.getCatalogRequestByIdLead+ "?partnerId=" + id;
    return this.http.get(getCatalogRequestByIdLead);
  }
  getTaskTeams(workEffortId): Observable<any> {
    const getTaskTeam = URLS.getTaskTeams+ "?workEffortId=" + workEffortId;
    return this.http.get(getTaskTeam);
  }
  addSubSite(contentId,contentIdTo): Observable<any> {
    const addSubSite = URLS.addSubSite + "?contentId=" + contentId+ "&contentIdTo=" + contentIdTo;
    return this.http.get(addSubSite);
  }
  indexProducts(): Observable<any> {
    const indexProducts = URLS.indexProducts ;
    return this.http.get(indexProducts);
  }
  getShipmentGatewayConfig(): Observable<any> {
    const getShipmentGatewayConfig = URLS.getShipmentGatewayConfig ;
    return this.http.get(getShipmentGatewayConfig);
  }
  getShipmentCustomMethod(): Observable<any> {
    const getShipmentCustomMethod = URLS.getShipmentCustomMethod ;
    return this.http.get(getShipmentCustomMethod);
  }
  getProductSearch(): Observable<any> {
    const getProductSearch = URLS.getProductSearch ;
    return this.http.get(getProductSearch);
  }
  getCarrierShipmentMethodList(): Observable<any> {
    const getCarrierShipmentMethodList = URLS.getCarrierShipmentMethodList ;
    return this.http.get(getCarrierShipmentMethodList);
  }
  ProductSearch(productId): Observable<any> {
    const ProductSearch = URLS.ProductSearch + "?productId=" + productId;
    return this.http.get(ProductSearch);
  }
  prepareCreateShipMeth(formData): Observable<any> {
    const prepareCreateShipMeth = URLS.prepareCreateShipMeth;
    return this.http.post(prepareCreateShipMeth,formData);
  }
  storeCreateShipMeth(formData): Observable<any> {
    const storeCreateShipMeth = URLS.storeCreateShipMeth;
    return this.http.post(storeCreateShipMeth,formData);
  }
  storeCreateShipRate(formData): Observable<any> {
    const storeCreateShipRate = URLS.storeCreateShipRate;
    return this.http.post(storeCreateShipRate,formData);
  }
  storeUpdateShipMeth(formData): Observable<any> {
    const storeUpdateShipMeth = URLS.storeUpdateShipMeth;
    return this.http.post(storeUpdateShipMeth,formData);
  }
  getFindTaskContentById(workEffortId): Observable<any> {
    const getFindTaskContentById = URLS.getFindTaskContentById + "?workEffortId=" + workEffortId;
    return this.http.get(getFindTaskContentById);
  }
  getICalender(): Observable<any> {
    const getICalender = URLS.getICalender;
    return this.http.get(getICalender);
  }
  getCalenderPartiesRole(): Observable<any> {
    const getCalenderPartiesRole = URLS.getCalenderPartiesRole;
    return this.http.get(getCalenderPartiesRole);
  }
  metaDataPredicateData(): Observable<any> {
    const metaDataPredicateData = URLS.metaDataPredicateData;
    return this.http.get(metaDataPredicateData);
  }
  metaDataPredicateById(id): Observable<any> {
    const metaDataPredicateById = URLS.metaDataPredicateById + "?metaDataPredicateId=" + id;
    return this.http.get(metaDataPredicateById);
  }
  getCalenderVisibility(): Observable<any> {
    const getCalenderVisibility = URLS.getCalenderVisibility;
    return this.http.get(getCalenderVisibility);
  }
  geICalendarPartyAssign(workEffortId): Observable<any> {
    const geICalendarPartyAssign = URLS.geICalendarPartyAssign + "?workEffortId=" + workEffortId;
    return this.http.get(geICalendarPartyAssign);
  }
  createICalendarPartyAssign(fromData): Observable<any> {
    const createICalendarPartyAssign = URLS.createICalendarPartyAssign;
    return this.http.post(createICalendarPartyAssign, fromData);
  }
  updateICalendarPartyAssign(fromData): Observable<any> {
    const updateICalendarPartyAssign = URLS.updateICalendarPartyAssign;
    return this.http.post(updateICalendarPartyAssign, fromData);
  }
  updateICalendarData(fromData): Observable<any> {
    const updateICalendarData = URLS.updateICalendarData;
    return this.http.post(updateICalendarData, fromData);
  }
  createTimesheetEntry(fromData): Observable<any> {
    const createTimesheetEntry = URLS.createTimesheetEntry;
    return this.http.post(createTimesheetEntry, fromData);
  }
  updateTimesheetEntry(fromData): Observable<any> {
    const updateTimesheetEntry = URLS.updateTimesheetEntry;
    return this.http.post(updateTimesheetEntry, fromData);
  }
  getOriginFacilityIdReport(): Observable<any> {
    const getOriginFacilityIdReport = URLS.getOriginFacilityIdReport;
    return this.http.get(getOriginFacilityIdReport);
  }
  getProductStoreIdReport(): Observable<any> {
    const getProductStoreIdReport = URLS.getProductStoreIdReport;
    return this.http.get(getProductStoreIdReport);
  }
  getSalesChannel(): Observable<any> {
    const getSalesChannel = URLS.getSalesChannel;
    return this.http.get(getSalesChannel);
  }
  generateSalesByStoreReportPdf(fromOrderDate,orderStatusId,productStoreId,thruOrderDate,toPartyId): Observable<any> {
    const generateSalesByStoreReportPdf = URLS.generateSalesByStoreReportPdf + "?fromOrderDate=" + fromOrderDate + "&orderStatusId=" + orderStatusId +
    + "&productStoreId=" + productStoreId+ "&thruOrderDate=" + thruOrderDate+ "&toPartyId=" + toPartyId;
    return this.http.get(generateSalesByStoreReportPdf, { responseType: "blob" });
  }
  generatePurchaseByProductReportPdf(fromOrderDate,originFacilityId,productStoreId,statusId,terminalId,thruOrderDate): Observable<any> {
    const generatePurchaseByProductReportPdf = URLS.generatePurchaseByProductReportPdf + "?fromOrderDate=" + fromOrderDate + "&originFacilityId=" + originFacilityId +
    + "&productStoreId=" + productStoreId+"&statusId=" + statusId  + "&terminalId=" + terminalId+ "&thruOrderDate=" + thruOrderDate;
    return this.http.get(generatePurchaseByProductReportPdf, { responseType: "blob" });
  }
  getOrderStatusIdReport(): Observable<any> {
    const getOrderStatusIdReport = URLS.getOrderStatusIdReport;
    return this.http.get(getOrderStatusIdReport);
  }
  approvedProductRequirementsReport(): Observable<any> {
    const approvedProductRequirementsReport = URLS.approvedProductRequirementsReport ;
    return this.http.get(approvedProductRequirementsReport, { responseType: "blob" });
  }
  generateLastThreeMonthsSalesReport(): Observable<any> {
    const generateLastThreeMonthsSalesReport = URLS.generateLastThreeMonthsSalesReport ;
    return this.http.get(generateLastThreeMonthsSalesReport, { responseType: "blob" });
  }
  generateSalesOrderDiscountCode(): Observable<any> {
    const generateSalesOrderDiscountCode = URLS.generateSalesOrderDiscountCode ;
    return this.http.get(generateSalesOrderDiscountCode, { responseType: "blob" });
  }
  getOpenOrderItemsReport(): Observable<any> {
    const getOpenOrderItemsReport = URLS.getOpenOrderItemsReport  ;
    return this.http.get(getOpenOrderItemsReport);
  }
  generateSalesOrderByChannelPdf(salesChannel): Observable<any> {
    const generateSalesOrderByChannelPdf = URLS.generateSalesOrderByChannelPdf + "?salesChannel=" + salesChannel ;
    return this.http.get(generateSalesOrderByChannelPdf, { responseType: "blob" });
  }
  generateCouponSalesReport(): Observable<any> {
    const generateCouponSalesReport = URLS.generateCouponSalesReport;
    return this.http.get(generateCouponSalesReport, { responseType: "blob" });
  }
  generateProductDemandReport(productStoreId): Observable<any> {
    const generateProductDemandReport = URLS.generateProductDemandReport + "?productStoreId=" + productStoreId ;
    return this.http.get(generateProductDemandReport, { responseType: "blob" });
  }
  generateSalesReport(exportType,fromDate,reportBy): Observable<any> {
    const generateSalesReport = URLS.generateSalesReport + "?exportType=" + exportType + "&fromDate=" + fromDate +
    + "&reportBy=" + reportBy  ;
    return this.http.get(generateSalesReport, { responseType: "blob" });
  }
  generatePurchasesByPaymentMethodReportPdf(fromOrderDate,orderTypeId,originFacilityId,productStoreId,statusId,terminalId,thruOrderDate): Observable<any> {
    const generatePurchasesByPaymentMethodReportPdf = URLS.generatePurchasesByPaymentMethodReportPdf + "?fromOrderDate=" + fromOrderDate + "&orderTypeId=" + orderTypeId +
    + "&originFacilityId=" + originFacilityId + "&productStoreId=" + productStoreId  + "&statusId=" + statusId + "&terminalId=" + terminalId+ "&thruOrderDate=" + thruOrderDate;
    return this.http.get(generatePurchasesByPaymentMethodReportPdf, { responseType: "blob" });
  }
  generatePurchasesByOrganizationReportPdf(fromOrderDate,fromPartyId,orderStatusId,thruOrderDate,toPartyId): Observable<any> {
    const generatePurchasesByOrganizationReportPdf = URLS.generatePurchasesByOrganizationReportPdf + "?fromOrderDate=" + fromOrderDate + "&fromPartyId=" + fromPartyId +
    + "&orderStatusId=" + orderStatusId + "&thruOrderDate=" + thruOrderDate  + "&toPartyId=" + toPartyId;
    return this.http.get(generatePurchasesByOrganizationReportPdf, { responseType: "blob" });
  }
  // generatePieChart(): Observable<any> {
  //   const generatePieChart = URLS.generatePieChart ;
  //   return this.http.post(generatePieChart, { responseType: "blob" });
  // }

  generatePieChart(): Observable<any> {
    const generatePieChart = URLS.generatePieChart    ;
    return this.http.get(generatePieChart, { responseType: "blob" });
  }
 
  getFacilityIdListOrder(): Observable<any> {
    const getFacilityIdListOrder = URLS.getFacilityIdListOrder;
    return this.http.get(getFacilityIdListOrder);
  }
  findApprovedRequirementsBySupplier(): Observable<any> {
    const findApprovedRequirementsBySupplier = URLS.findApprovedRequirementsBySupplier;
    return this.http.get(findApprovedRequirementsBySupplier);
  }
  getProjectandPhaseName(): Observable<any> {
    const getProjectandPhaseName = URLS.getProjectandPhaseName;
    return this.http.get(getProjectandPhaseName);
  }
  getTimeSheetEntryById(timesheetId): Observable<any> {
    const getTimeSheetEntryById = URLS.getTimeSheetEntryById + "?timesheetId=" + timesheetId;
    return this.http.get(getTimeSheetEntryById);
  }
  getfindNotApprovedRequirement(req): Observable<any> {
    const getfindNotApprovedRequirement = URLS.getfindNotApprovedRequirement ;
    return this.http.post(getfindNotApprovedRequirement,req);
  }
  autoAssignRequirementToSupplier(requirementId): Observable<any> {
    const autoAssignRequirementToSupplier = URLS.autoAssignRequirementToSupplier + "?requirementId=" + requirementId ;
    return this.http.post(autoAssignRequirementToSupplier,"");
  }
  findNotApprovedRequirement(req): Observable<any> {
    const findNotApprovedRequirement = URLS.findNotApprovedRequirement ;
    return this.http.post(findNotApprovedRequirement,req);
  }
  getCommunicationEventIdSubs(): Observable<any> {
    const getCommunicationEventIdSubs = URLS.getCommunicationEventIdSubs ;
    return this.http.get(getCommunicationEventIdSubs);
  }
  approveRequirement(id): Observable<any> {
    const approveRequirement = URLS.approveRequirement.replace(":requirementId",id);
    return this.http.post(approveRequirement,"");
  }
  getSubscriptionAttributeById(subscriptionId): Observable<any> {
    const getSubscriptionAttributeById = URLS.getSubscriptionAttributeById.replace(":subscriptionId",subscriptionId);
    return this.http.get(getSubscriptionAttributeById);
  }
  getSubscriptionResourceById(subscriptionResourceId): Observable<any> {
    const getSubscriptionResourceById = URLS.getSubscriptionResourceById.replace(":subscriptionResourceId",subscriptionResourceId);
    return this.http.get(getSubscriptionResourceById);
  }
  timesheetRole(timesheetId): Observable<any> {
    const timesheetRole = URLS.timesheetRole .replace(":timesheetId",timesheetId);
    return this.http.get(timesheetRole);
  }
  updateTimeSheetwork (formData,timesheetId): Observable<any> {
    const updateTimeSheetwork = URLS.updateTimeSheetwork  + "?timesheetId=" + timesheetId;
    return this.http.post(updateTimeSheetwork,formData);
  }
  getFixedAssetIdList(): Observable<any> {
    const getFixedAssetIdList = URLS.getFixedAssetIdList;
    return this.http.get(getFixedAssetIdList);
  }
  geICalendarFixedAssetAssign(workEffortId): Observable<any> {
    const geICalendarFixedAssetAssign = URLS.geICalendarFixedAssetAssign + "?workEffortId=" + workEffortId;
    return this.http.get(geICalendarFixedAssetAssign);
  }
  createICalendarFixedAssetAssign(fromData): Observable<any> {
    const createICalendarFixedAssetAssign = URLS.createICalendarFixedAssetAssign;
    return this.http.post(createICalendarFixedAssetAssign, fromData);
  }
  addTimesheetToInvoice(fromData): Observable<any> {
    const addTimesheetToInvoice = URLS.addTimesheetToInvoice;
    return this.http.post(addTimesheetToInvoice, fromData);
  }
  addTimesheetToNewInvoice(fromData): Observable<any> {
    const addTimesheetToNewInvoice = URLS.addTimesheetToNewInvoice;
    return this.http.post(addTimesheetToNewInvoice, fromData);
  }
  getWorkEffortRatePage(workEffortId): Observable<any> {
    const getWorkEffortRatePage = URLS.getWorkEffortRatePage + "?workEffortId=" + workEffortId;
    return this.http.get(getWorkEffortRatePage);
  }
  getCommunicationEventById(workEffortId): Observable<any> {
    const getCommunicationEventById = URLS.getCommunicationEventById + "?workEffortId=" + workEffortId;
    return this.http.get(getCommunicationEventById);
  }
  getProjectSummaryById(workEffortId):Observable<any>{
    const getProjectSummaryById = URLS.getProjectSummaryById.replace(":workEffortId",workEffortId);
    return this.http.get(getProjectSummaryById);
  }
  getTeamByPartyId(partyId):Observable<any>{
    const getTeamByPartyId = URLS.getTeamByPartyId.replace(":partyId",partyId);
    return this.http.get(getTeamByPartyId);
  }
  getPartyAssignmentById(workEffortId):Observable<any>{
    const getPartyAssignmentById = URLS.getPartyAssignmentById.replace(":workEffortId",workEffortId);
    return this.http.get(getPartyAssignmentById);
  }
  getWorkEffortRequestListById(workEffortId):Observable<any>{
    const getWorkEffortRequestListById = URLS.getWorkEffortRequestListById.replace(":workEffortId",workEffortId);;
    return this.http.get(getWorkEffortRequestListById);
  }
  getShoppingListWorkEffortById(workEffortId):Observable<any>{
    const getShoppingListWorkEffortById = URLS.getShoppingListWorkEffortById.replace(":workEffortId",workEffortId);;
    return this.http.get(getShoppingListWorkEffortById);
  }
  getQuoteById(workEffortId):Observable<any>{
    const getQuoteById = URLS.getQuoteById.replace(":workEffortId",workEffortId);;
    return this.http.get(getQuoteById);
  }
  getOrderListById(workEffortId):Observable<any>{
    const getOrderListById = URLS.getOrderListById + "?workEffortId=" + workEffortId;
    return this.http.get(getOrderListById);
  }
  getWorkEffortRequestById(workEffortId):Observable<any>{
    const getWorkEffortRequestById = URLS.getWorkEffortRequestById + "?workEffortId=" + workEffortId;
    return this.http.get(getWorkEffortRequestById);
  }
  getContentDetailsById(workEffortId):Observable<any>{
    const getContentDetailsById = URLS.getContentDetailsById.replace(":workEffortId",workEffortId);;
    return this.http.get(getContentDetailsById);
  }
  getSurveyList(): Observable<any> {
    const getSurveyList = URLS.getSurveyList;
    return this.http.get(getSurveyList);
  }
  createNewTasks(formData): Observable<any> {
    const createNewTasks = URLS.createNewTasks;
    return this.http.post(createNewTasks,formData);
  } 
  deleteCustRequestParty(req): Observable<any> {
    const deleteCustRequestParty = URLS.deleteCustRequestParty;
    return this.http.post(deleteCustRequestParty, req);

  }
  expireCustRequestParty(req): Observable<any> {
    const expireCustRequestParty = URLS.expireCustRequestParty;
    return this.http.post(expireCustRequestParty, req);

  }
  createCustRequestParty(req): Observable<any> {
    const createCustRequestParty = URLS.createCustRequestParty;
    return this.http.post(createCustRequestParty, req);

  }
  updateCustRequestParty(req): Observable<any> {
    const updateCustRequestParty = URLS.updateCustRequestParty;
    return this.http.post(updateCustRequestParty, req);

  }
  createCustRequestContent(req): Observable<any> {
    const createCustRequestContent = URLS.createCustRequestContent;
    return this.http.post(createCustRequestContent, req);

  }
  deleteCustRequestContent(req): Observable<any> {
    const deleteCustRequestContent = URLS.deleteCustRequestContent;
    return this.http.post(deleteCustRequestContent, req);

  }
  createRequestItemNote(req): Observable<any> {
    const createRequestItemNote = URLS.createRequestItemNote;
    return this.http.post(createRequestItemNote, req);

  }
  expireCustRequestContent(req): Observable<any> {
    const expireCustRequestContent = URLS.expireCustRequestContent;
    return this.http.post(expireCustRequestContent, req);

  }
  copyCustRequestItem(req): Observable<any> {
    const copyCustRequestItem = URLS.copyCustRequestItem;
    return this.http.post(copyCustRequestItem, req);

  }
  updaterequestitem(req): Observable<any> {
    const updaterequestitem = URLS.updaterequestitem;
    return this.http.post(updaterequestitem, req);
  }
  updaterequest(req): Observable<any> {
    const updaterequest = URLS.updaterequest;
    return this.http.post(updaterequest, req);
  }
  updateProductReview(req): Observable<any> {
    const updateProductReview = URLS.updateProductReview;
    return this.http.post(updateProductReview, req);
  }
  updateProductReviewStatus(formData): Observable<any> {
    const updateProductReviewStatus = URLS.updateProductReviewStatus;
    return this.http.post(updateProductReviewStatus,formData);
  }
  updateProductReviewStatusApprove(formData): Observable<any> {
    const updateProductReviewStatusApprove = URLS.updateProductReviewStatusApprove;
    return this.http.post(updateProductReviewStatusApprove, formData)
  }
  createrequestitem(req): Observable<any> {
    const createrequestitem = URLS.createrequestitem;
    return this.http.post(createrequestitem, req);
  }
  findWorkEffort(formData): Observable<any> {
    const findWorkEffort = URLS.findWorkEffort;
    return this.http.post(findWorkEffort,formData);
  }
  findTimesheet(formData): Observable<any> {
    const findTimesheet = URLS.findTimesheet;
    return this.http.post(findTimesheet, formData)
  }

  getFIndReviewTable(): Observable<any> {
    const getFIndReviewTable = URLS.getFIndReviewTable;
    return this.http.post(getFIndReviewTable,'');
  }
  getFIndReview(productId,productReview,statusId): Observable<any> {
    const getFIndReview = URLS.getFIndReview + "?productId=" + productId + 
    "&productReview=" + productReview + "&statusId=" + statusId ;
    return this.http.post(getFIndReview,"");
  }
  setTaskStatus(formData): Observable<any> {
    const setTaskStatus = URLS.setTaskStatus;
    return this.http.post(setTaskStatus,formData);
  }
  findAllocationPlan(formData): Observable<any> {
    const findAllocationPlan = URLS.findAllocationPlan;
    return this.http.post(findAllocationPlan,formData);
  }
  getfindAllocationPlan(formData): Observable<any> {
    const getfindAllocationPlan = URLS.getfindAllocationPlan;
    return this.http.post(getfindAllocationPlan,formData);
  }
  getProductProductId(): Observable<any> {
    const getProductProductId = URLS.getProductProductId;
    return this.http.get(getProductProductId);
  }
  getCustRequestCategoryId(): Observable<any> {
    const getCustRequestCategoryId = URLS.getCustRequestCategoryId;
    return this.http.get(getCustRequestCategoryId);
  }
  getOrderStats(): Observable<any> {
    const getOrderStats = URLS.getOrderStats;
    return this.http.get(getOrderStats);
  }
    getRequestItemsStatusId(): Observable<any> {
    const getRequestItemsStatusId = URLS.getRequestItemsStatusId;
    return this.http.get(getRequestItemsStatusId);
  }
  custRequestResolutionId(): Observable<any> {
    const custRequestResolutionId = URLS.custRequestResolutionId;
    return this.http.get(custRequestResolutionId);
  }
  getRequestItemNote(custRequestId,custRequestItemSeqId): Observable<any> {
    const getRequestItemNote = URLS.getRequestItemNote+ "?custRequestId=" + custRequestId+ "&custRequestItemSeqId=" + custRequestItemSeqId;
    return this.http.get(getRequestItemNote);
  }
  listResourcesProject(partyId): Observable<any> {
    const listResourcesProject = URLS.listResourcesProject+ "?partyId=" + partyId;
    return this.http.get(listResourcesProject);
  }
  getDependencyTo(workEffortIdTo): Observable<any> {
    const getDependencyTo = URLS.getDependencyTo+ "?workEffortIdTo=" + workEffortIdTo;
    return this.http.get(getDependencyTo);
  }
  getTaskNoteList(): Observable<any> {
    const getTaskNoteList = URLS.getTaskNoteList;
    return this.http.get(getTaskNoteList);
  }
  getResourceList(workEffortId): Observable<any> {
    const getResourceList = URLS.getResourceList+ "?workEffortId=" + workEffortId;
    return this.http.get(getResourceList);
  }
  getFindTaskInformationById(workEffortId): Observable<any> {
    const getFindTaskInformationById = URLS.getFindTaskInformationById+ "?workEffortId=" + workEffortId;
    return this.http.get(getFindTaskInformationById);
  }
  getFindTaskInformation(workEffortId): Observable<any> {
    const getFindTaskInformation = URLS.getFindTaskInformation+ "?workEffortId=" + workEffortId;
    return this.http.get(getFindTaskInformation);
  }

  getDependencyFrom(workEffortIdFrom): Observable<any> {
    const getDependencyFrom = URLS.getDependencyFrom+ "?workEffortIdFrom=" + workEffortIdFrom;
    return this.http.get(getDependencyFrom);
  }
  getSkillList(workEffortId): Observable<any> {
    const getSkillList = URLS.getSkillList+ "?workEffortId=" + workEffortId;;
    return this.http.get(getSkillList);
  }
  getTaskTeam(workEffortId): Observable<any> {
    const getTaskTeam = URLS.getTaskTeam+ "?workEffortId=" + workEffortId;
    return this.http.get(getTaskTeam);
  }
  getRequestList(workEffortId): Observable<any> {
    const getRequestList = URLS.getRequestList+ "?workEffortId=" + workEffortId;
    return this.http.get(getRequestList);
  }
  getPhaseById(workEffortId): Observable<any> {
    const getPhaseById = URLS.getPhaseById+ "?workEffortId=" + workEffortId;
    return this.http.get(getPhaseById);
  }
  getTaskInformation(workEffortId): Observable<any> {
    const getTaskInformation = URLS.getTaskInformation+ "?workEffortId=" + workEffortId;
    return this.http.get(getTaskInformation);
  }
  getMainQuote(quoteId): Observable<any> {
    const getMainQuote = URLS.getMainQuote + "?quoteId=" + quoteId;
    return this.http.get(getMainQuote);
  }
 
  generatePdf(quoteId): Observable<any> {
    const generatePdf = URLS.generatePdf  + "?quoteId=" + quoteId   ;
    return this.http.get(generatePdf, { responseType: "blob" });
  }
  
  getQuoteRoles(quoteId): Observable<any> {
    const getQuoteRoles = URLS.getQuoteRoles+ "?quoteId=" + quoteId;
    return this.http.get(getQuoteRoles);
  }
  getQuoteNotes(quoteId): Observable<any> {
    const getQuoteNotes = URLS.getQuoteNotes+ "?quoteId=" + quoteId;
    return this.http.get(getQuoteNotes);
  }
  getQuoteRole(quoteId): Observable<any> {
    const getQuoteRole = URLS.getQuoteRole+ "?quoteId=" + quoteId;
    return this.http.get(getQuoteRole);
  }
  getQuoteAttribute(quoteId): Observable<any> {
    const getQuoteAttribute = URLS.getQuoteAttribute+ "?quoteId=" + quoteId;
    return this.http.get(getQuoteAttribute);
  }
  taskById(taskID): Observable<any> {
    const taskById = URLS.taskById+ "?workEffortId=" + taskID;
    return this.http.get(taskById);
  }
  getResourceListByProjectId(taskID): Observable<any> {
    const getResourceListByProjectId = URLS.getResourceListByProjectId+ "?workEffortId=" + taskID;
    return this.http.get(getResourceListByProjectId);
  }
 
  getTaskFind(req): Observable<any> {
    const getTaskFind = URLS.getTaskFind ;
    return this.http.post(getTaskFind,req);
  }
  
  getfindContactList(req): Observable<any> {
    const getfindContactList = URLS.getfindContactList ;
    return this.http.post(getfindContactList,req);
  }

  findRequirement(req): Observable<any> {
    const findRequirement = URLS.findRequirement ;
    return this.http.post(findRequirement,req);
  }
  
  createRequirementPlanning(req): Observable<any> {
    const createRequirementPlanning = URLS.createRequirementPlanning ;
    return this.http.post(createRequirementPlanning,req);
  }

  UpdateRequirementPlanning(req): Observable<any> {
    const UpdateRequirementPlanning = URLS.UpdateRequirementPlanning ;
    return this.http.post(UpdateRequirementPlanning,req);
  }
    
  getWorkEfforts(custRequestId): Observable<any> {
    const getWorkEfforts = URLS.getWorkEfforts+ "?custRequestId=" + custRequestId;
    return this.http.get(getWorkEfforts);
  }
  getProductIds(): Observable<any> {
    const getProductIds = URLS.getProductIds;
    return this.http.get(getProductIds);
  }
  getSubscriptionType(): Observable<any> {
    const getSubscriptionType = URLS.getSubscriptionType;
    return this.http.get(getSubscriptionType);
  }
  getSubscriptionResourceId(): Observable<any> {
    const getSubscriptionResourceId = URLS.getSubscriptionResourceId;
    return this.http.get(getSubscriptionResourceId);
  }
  getReviewStatus(): Observable<any> {
    const getReviewStatus = URLS.getReviewStatus;
    return this.http.get(getReviewStatus);
  }
  getFacilityId(): Observable<any> {
    const getFacilityId = URLS.getFacilityId;
    return this.http.get(getFacilityId);
  }
  getRequirementStatusId(): Observable<any> {
    const getRequirementStatusId = URLS.getRequirementStatusId;
    return this.http.get(getRequirementStatusId);
  }
  getRequirementTypeId(): Observable<any> {
    const getRequirementTypeId = URLS.getRequirementTypeId;
    return this.http.get(getRequirementTypeId);
  }
  getFindSupplier(): Observable<any> {
    const getFindSupplier = URLS.getFindSupplier;
    return this.http.get(getFindSupplier);
  }
  getWorkEffortOrder(custRequestId): Observable<any> {
    const getWorkEffortOrder = URLS.getWorkEffortOrder+ "?custRequestId=" + custRequestId;
    return this.http.get(getWorkEffortOrder);
  }
  getRequirementPlanningById(requirementId): Observable<any> {
    const getRequirementPlanningById = URLS.getRequirementPlanningById+ "?requirementId=" + requirementId;
    return this.http.get(getRequirementPlanningById);
  }
  getRequestInformation(custRequestId): Observable<any> {
    const getRequestInformation = URLS.getRequestInformation+ "?custRequestId=" + custRequestId;
    return this.http.get(getRequestInformation);
  }
  getCommunicationEvents(custRequestId): Observable<any> {
    const getCommunicationEvents = URLS.getCommunicationEvents+ "?custRequestId=" + custRequestId;
    return this.http.get(getCommunicationEvents);
  }
  removeCommunicationEvent(id): Observable<any> {
    const removeCommunicationEvent = URLS.removeCommunicationEvent.replace(":communicationEventId", id);
    return this.http.delete(removeCommunicationEvent);
  }
  deletegetCrmEventContentList(id, cid,fromDate): Observable<any> {
    const deletegetCrmEventContentList = URLS.deletegetCrmEventContentList.replace(":communicationEventId", id) + "?contentId=" + cid + "&fromDate=" + fromDate;
    return this.http.delete(deletegetCrmEventContentList);
  }
  getEventContentList(id, data): Observable<any> {
    const getEventContentList = URLS.getEventContentList.replace(":communicationEventId", id) + "?pageNo=" + data.pageNo + "&pageSize=" + data.pageSize;
    return this.http.get(getEventContentList);
  }
  getCommunicationEventsBYCe(communicationEventId): Observable<any> {
    const getCommunicationEventsBYCe = URLS.getCommunicationEventsBYCe+ "?communicationEventId=" + communicationEventId;
    return this.http.get(getCommunicationEventsBYCe);
  }
  getCustRequestContentById(custRequestId): Observable<any> {
    const getCustRequestContentById = URLS.getCustRequestContentById+ "?custRequestId=" + custRequestId;
    return this.http.get(getCustRequestContentById);
  }
  getSubProjectsById(taskID): Observable<any> {
    const getSubProjectsById = URLS.getSubProjectsById+ "?workEffortId=" + taskID;
    return this.http.get(getSubProjectsById);
  }

  getClientContacts(workEffortId): Observable<any> {
    const getClientContacts = URLS.getClientContacts+ "?workEffortId=" + workEffortId;
    return this.http.get(getClientContacts);
  }
  getCompanies(workEffortId): Observable<any> {
    const getCompanies = URLS.getCompanies+ "?workEffortId=" + workEffortId;
    return this.http.get(getCompanies);
  }
  getWorkEffort(custRequestId): Observable<any> {
    const getWorkEfforts = URLS.getWorkEffort+ "?custRequestId=" + custRequestId;
    return this.http.get(getWorkEfforts);
  }
  getNotesByWorkEffort(workEffortId): Observable<any> {
    const getNotesByWorkEffort = URLS.getNotesByWorkEffort+ "?workEffortId=" + workEffortId;
    return this.http.get(getNotesByWorkEffort);
  }
  getProductIdAction(): Observable<any> {
    const getProductIdAction = URLS.getProductIdAction;
    return this.http.get(getProductIdAction);
  }
  getQuoteItemList(): Observable<any> {
    const getQuoteItemList = URLS.getQuoteItemList;
    return this.http.get(getQuoteItemList);
  }
  getMyTime(): Observable<any> {
    const getMyTime = URLS.getMyTime;
    return this.http.get(getMyTime);
  }
  getChildrenWorkeffort(workEffortIdFrom): Observable<any> {
    const getChildrenWorkeffort = URLS.getChildrenWorkeffort+ "?workEffortIdFrom=" + workEffortIdFrom;
    return this.http.get(getChildrenWorkeffort);
  }
  findByEmailPartner(emailAddress): Observable<any> {
    const findByEmailPartner = URLS.findByEmailPartner+ "?emailAddress=" + emailAddress;
    return this.http.get(findByEmailPartner);
  }
  getfindByEmailPartner(): Observable<any> {
    const getfindByEmailPartner = URLS.getfindByEmailPartner ;
    return this.http.get(getfindByEmailPartner);
  }
  getfindByNamePartner(): Observable<any> {
    const getfindByNamePartner = URLS.getfindByNamePartner;
    return this.http.get(getfindByNamePartner);
  }
  getfindByPhonePartner(): Observable<any> {
    const getfindByPhonePartner = URLS.getfindByPhonePartner;
    return this.http.get(getfindByPhonePartner);
  }
  findByNamePartner(partnerId, partnerName): Observable<any> {
    const findByNamePartner = URLS.findByNamePartner+ "?partnerId=" + partnerId + "&partnerName=" + partnerName;
    return this.http.get(findByNamePartner);
  }
  findByPhonePartner(phoneNumber): Observable<any> {
    const findByPhonePartner = URLS.findByPhonePartner+ "?phoneNumber=" + phoneNumber;
    return this.http.get(findByPhonePartner);
  }
  getEmailSupplier(partyId): Observable<any> {
    const getEmailSupplier = URLS.getEmailSupplier+ "?partyId=" + partyId;
    return this.http.get(getEmailSupplier);
  }
  getEmailaddressPurpose(partyId): Observable<any> {
    const getEmailaddressPurpose = URLS.getEmailaddressPurpose+ "?partyId=" + partyId;
    return this.http.get(getEmailaddressPurpose);
  }
  getNoteSupplierBId(partyId): Observable<any> {
    const getNoteSupplierBId = URLS.getNoteSupplierBId+ "?partyId=" + partyId;
    return this.http.get(getNoteSupplierBId);
  }
  getEditSupplierById(partyId): Observable<any> {
    const getEditSupplierById = URLS.getEditSupplierById+ "?partyId=" + partyId;
    return this.http.get(getEditSupplierById);
  }
  getContactInformationSupplier(partyId): Observable<any> {
    const getContactInformationSupplier = URLS.getContactInformationSupplier+ "?partyId=" + partyId;
    return this.http.get(getContactInformationSupplier);
  }
  getAddressSupplier(partyId): Observable<any> {
    const getAddressSupplier = URLS.getAddressSupplier+ "?partyId=" + partyId;
    return this.http.get(getAddressSupplier);
  }
   
  getAgreementsSupplierById(partyId): Observable<any> {
    const getAgreementsSupplierById = URLS.getAgreementsSupplierById+ "?partyId=" + partyId;
    return this.http.get(getAgreementsSupplierById);
  }
  getWebUlSupplier(partyId): Observable<any> {
    const getWebUlSupplier = URLS.getWebUlSupplier+ "?partyId=" + partyId;
    return this.http.get(getWebUlSupplier);
  }
  getOpenOrdersSupplierById(partyId): Observable<any> {
    const getOpenOrdersSupplierById = URLS.getOpenOrdersSupplierById+ "?partyId=" + partyId;
    return this.http.get(getOpenOrdersSupplierById);
  }
  createAgreementsSupplierPartner(req): Observable<any> {
    const createAgreementsSupplierPartner = URLS.createAgreementsSupplierPartner ;
    return this.http.post(createAgreementsSupplierPartner,req);
  }
  updateWebUrlSupplier(req): Observable<any> {
    const updateWebUrlSupplier = URLS.updateWebUrlSupplier ;
    return this.http.post(updateWebUrlSupplier,req);
  }
  createWebUrlSupplier(req): Observable<any> {
    const createWebUrlSupplier = URLS.createWebUrlSupplier ;
    return this.http.post(createWebUrlSupplier,req);
  }
  deleteWebUrlSupplier(req): Observable<any> {
    const deleteWebUrlSupplier = URLS.deleteWebUrlSupplier ;
    return this.http.post(deleteWebUrlSupplier,req);
  }
  createOpenOrdersSupplier(req): Observable<any> {
    const createOpenOrdersSupplier = URLS.createOpenOrdersSupplier ;
    return this.http.post(createOpenOrdersSupplier,req);
  }
  createAgreementsSupplier(req): Observable<any> {
    const createAgreementsSupplier = URLS.createAgreementsSupplier ;
    return this.http.post(createAgreementsSupplier,req);
  }
  createNoteSupplier(req): Observable<any> {
    const createNoteSupplier = URLS.createNoteSupplier ;
    return this.http.post(createNoteSupplier,req);
  }
  deleteEmailSupplier(req): Observable<any> {
    const deleteEmailSupplier = URLS.deleteEmailSupplier ;
    return this.http.post(deleteEmailSupplier,req);
  }
  createEmailSupplier(req): Observable<any> {
    const createEmailSupplier = URLS.createEmailSupplier ;
    return this.http.post(createEmailSupplier,req);
  }
  updateEmailSupplier(req): Observable<any> {
    const updateEmailSupplier = URLS.updateEmailSupplier ;
    return this.http.post(updateEmailSupplier,req);
  }
  updateContactInformationSupplier(req): Observable<any> {
    const updateContactInformationSupplier = URLS.updateContactInformationSupplier ;
    return this.http.post(updateContactInformationSupplier,req);
  }
  updateAddressSupplier(req): Observable<any> {
    const updateAddressSupplier = URLS.updateAddressSupplier ;
    return this.http.post(updateAddressSupplier,req);
  }
  deleteAddressSupplier(req): Observable<any> {
    const deleteAddressSupplier = URLS.deleteAddressSupplier ;
    return this.http.post(deleteAddressSupplier,req);
  }
  createAddressSupplier(req): Observable<any> {
    const createAddressSupplier = URLS.createAddressSupplier ;
    return this.http.post(createAddressSupplier,req);
  }
  createContactInformationSupplier(req): Observable<any> {
    const createContactInformationSupplier = URLS.createContactInformationSupplier ;
    return this.http.post(createContactInformationSupplier,req);
  }
  deleteContactInformationSupplier(req): Observable<any> {
    const deleteContactInformationSupplier = URLS.deleteContactInformationSupplier ;
    return this.http.post(deleteContactInformationSupplier,req);
  }
  findSupplier(req): Observable<any> {
    const findSupplier = URLS.findSupplier ;
    return this.http.post(findSupplier,req);
  }
  findByAdvancePartner(req): Observable<any> {
    const findByAdvancePartner = URLS.findByAdvancePartner ;
    return this.http.post(findByAdvancePartner,req);
  }
  createSupplier(req): Observable<any> {
    const createSupplier = URLS.createSupplier ;
    return this.http.post(createSupplier,req);
  }
  getSupplierAll(): Observable<any> {
    const getSupplierAll = URLS.getSupplierAll;
    return this.http.get(getSupplierAll);
  }
  getGlAccountTypeId(): Observable<any> {
    const getGlAccountTypeId = URLS.getGlAccountTypeId;
    return this.http.get(getGlAccountTypeId);
  }
 
  deleteBookmarksUploadPartnerLead(formData): Observable<any> {
  const deleteBookmarksUploadPartnerLead = URLS.deleteBookmarksUploadPartnerLead ;
  return this.http.post(deleteBookmarksUploadPartnerLead,formData);
}

  updateSupplierForm(formData): Observable<any> {
    const updateSupplierForm = URLS.updateSupplierForm ;
    return this.http.post(updateSupplierForm,formData);
  }
  createOutsourceRoutingTask(formData): Observable<any> {
  const createOutsourceRoutingTask = URLS.createOutsourceRoutingTask ;
  return this.http.post(createOutsourceRoutingTask,formData);
}
  findFinalPartner(formData): Observable<any> {
    const findFinalPartner = URLS.findFinalPartner ;
    return this.http.post(findFinalPartner,formData);
  }
  getLogTaskCallCasesId(custRequestId): Observable<any> {
    const getLogTaskCallCasesId = URLS.getLogTaskCallCasesId+ "?custRequestId=" + custRequestId;
    return this.http.get(getLogTaskCallCasesId);
  }
  getLogTaskEmailCasesId(custRequestId): Observable<any> {
    const getLogTaskEmailCasesId = URLS.getLogTaskEmailCasesId+ "?custRequestId=" + custRequestId;
    return this.http.get(getLogTaskEmailCasesId);
  }
  getCountryPartner(): Observable<any> {
    const getCountryPartner = URLS.getCountryPartner;
    return this.http.get(getCountryPartner);
  }
  getIndustry(): Observable<any> {
    const getIndustry = URLS.getIndustry;
    return this.http.get(getIndustry);
  }
  getOwnerShip(): Observable<any> {
    const getOwnerShip = URLS.getOwnerShip;
    return this.http.get(getOwnerShip);
  }
  getPreferredCurrency(): Observable<any> {
    const getPreferredCurrency = URLS.getPreferredCurrency;
    return this.http.get(getPreferredCurrency);
  }
  getClassificationBookmarkPartner(): Observable<any> {
    const getClassificationBookmarkPartner = URLS.getClassificationBookmarkPartner;
    return this.http.get(getClassificationBookmarkPartner);
  }
  getBookMarksUrlPartnerLead(): Observable<any> {
    const getBookMarksUrlPartnerLead = URLS.getBookMarksUrlPartnerLead  ;
    return this.http.get(getBookMarksUrlPartnerLead);
  }
  getBookMarksUrlIdPartnerLead(partnerId): Observable<any> {
    const getBookMarksUrlIdPartnerLead = URLS.getBookMarksUrlIdPartnerLead +"?partnerId=" + partnerId  ;
    return this.http.get(getBookMarksUrlIdPartnerLead);
  }
  deleteNotePartner(req): Observable<any> {
    const deleteNotePartner = URLS.deleteNotePartner;
    return this.http.post(deleteNotePartner, req);

  }
  getNotePartnerBId(partnerId): Observable<any> {
    const getNotePartnerBId = URLS.getNotePartnerBId +"?partnerId=" + partnerId  ;
    return this.http.get(getNotePartnerBId);
  }
  createNotePartner(req): Observable<any> {
    const createNotePartner = URLS.createNotePartner;
    return this.http.post(createNotePartner, req);

  }
  UpdateNotePartner(req): Observable<any> {
    const UpdateNotePartner = URLS.UpdateNotePartner;
    return this.http.post(UpdateNotePartner, req);

  }
  createPartner(req): Observable<any> {
     const createPartner = URLS.createPartner;
     return this.http.post(createPartner, req);
   }
  
  
  deleteLogTaskCallCases(req): Observable<any> {
    const deleteLogTaskCallCases = URLS.deleteLogTaskCallCases;
    return this.http.post(deleteLogTaskCallCases, req);

  }
  deleteTimesheetEntry(req): Observable<any> {
    const deleteTimesheetEntry = URLS.deleteTimesheetEntry;
    return this.http.post(deleteTimesheetEntry, req);

  }
  deleteTimesheetRoleNew(req): Observable<any> {
    const deleteTimesheetRoleNew = URLS.deleteTimesheetRoleNew;
    return this.http.post(deleteTimesheetRoleNew, req);

  }
  getRequestMyRates():Observable<any> {
    const getRequestMyRates = URLS.getRequestMyRates;
    return this.http.get(getRequestMyRates);
  }
  getMyRates():Observable<any> {
    const getMyRates = URLS.getMyRates;
    return this.http.get(getMyRates);
  }
  getMyTimesheets():Observable<any> {
    const getMyTimesheets = URLS.getMyTimesheets;
    return this.http.get(getMyTimesheets);
  }
  getParticularOrderById(taskID): Observable<any> {
    const getParticularOrderById = URLS.getParticularOrderById+ "?workEffortId=" + taskID;
    return this.http.get(getParticularOrderById);
  }
  getTermTypeID(): Observable<any> {
    const getTermTypeID = URLS.getTermTypeID ;
    return this.http.get(getTermTypeID);
  }
  ListInstalledSoftware(id): Observable<any> {
    const ListInstalledSoftware = URLS.ListInstalledSoftware+ "?fixedAssetId=" + id;
    return this.http.get(ListInstalledSoftware);
  }
  IsManagerSummary(id): Observable<any> {
    const IsManagerSummary = URLS.IsManagerSummary+ "?fixedAssetId=" + id;
    return this.http.get(IsManagerSummary);
  }
  getQuoteItemSeqID(quoteId): Observable<any> {
    const getQuoteItemSeqID = URLS.getQuoteItemSeqID+ "?quoteId=" + quoteId;
    return this.http.get(getQuoteItemSeqID);
  }
  getProjectInformationById(taskID): Observable<any> {
    const getProjectInformationById = URLS.getProjectInformationById+ "?projectId=" + taskID;
    return this.http.get(getProjectInformationById);
  }
  FixedAssetParentIDSoftware(): Observable<any> {
    const FixedAssetParentIDSoftware = URLS.FixedAssetParentIDSoftware;
    return this.http.get(FixedAssetParentIDSoftware);
  }
  getRequestLists(): Observable<any> {
    const getRequestLists = URLS.getRequestLists;
    return this.http.get(getRequestLists);
  }

  FixedAssetParentIDHardware(): Observable<any> {
    const FixedAssetParentIDHardware = URLS.FixedAssetParentIDHardware;
    return this.http.get(FixedAssetParentIDHardware);
  }
  getWorkEffortName(): Observable<any> {
    const getWorkEffortName = URLS.getWorkEffortName;
    return this.http.get(getWorkEffortName);
  }
  getRateTypeId(): Observable<any> {
    const getRateTypeId = URLS.getRateTypeId;
    return this.http.get(getRateTypeId);
  }
  orderIdList(): Observable<any> {
    const orderIdList = URLS.orderIdList;
    return this.http.get(orderIdList);
  }
  getorderId(): Observable<any> {
    const getorderId = URLS.getorderId;
    return this.http.get(getorderId);
  }
  getRoleTypeList_profile(): Observable<any> {
    const getRoleTypeList_profile = URLS.getRoleTypeList_profile;
    return this.http.get(getRoleTypeList_profile);
  }
  getQuoteOrderItemSeqID(quoteId): Observable<any> {
    const getQuoteOrderItemSeqID = URLS.getQuoteOrderItemSeqID+ "?quoteId=" + quoteId;
    return this.http.get(getQuoteOrderItemSeqID);
  }
  ListHardwareAccessories(id): Observable<any> {
    const ListHardwareAccessories = URLS.ListHardwareAccessories+ "?fixedAssetId=" + id;
    return this.http.get(ListHardwareAccessories);
  }
  getContactListTypeId(): Observable<any> {
    const getContactListTypeId = URLS.getContactListTypeId;
    return this.http.get(getContactListTypeId);
  }
  getContactMechanismTypeID(): Observable<any> {
    const getContactMechanismTypeID = URLS.getContactMechanismTypeID;
    return this.http.get(getContactMechanismTypeID);
  }
  getMarketingCampaign(): Observable<any> {
    const getMarketingCampaign = URLS.getMarketingCampaign;
    return this.http.get(getMarketingCampaign);
  }
  getVerifyEmailWebsiteId(): Observable<any> {
    const getVerifyEmailWebsiteId = URLS.getVerifyEmailWebsiteId;
    return this.http.get(getVerifyEmailWebsiteId);
  }
  metaDataPredicate(): Observable<any> {
    const metaDataPredicate = URLS.metaDataPredicate;
    return this.http.get(metaDataPredicate);
  }
  contentTypeIds(): Observable<any> {
    const contentTypeIds = URLS.contentTypeIds;
    return this.http.get(contentTypeIds);
  }
  DataResourceTypeDROP(): Observable<any> {
    const DataResourceTypeDROP = URLS.DataResourceTypeDROP;
    return this.http.get(DataResourceTypeDROP);
  }
  DataTemplateType(): Observable<any> {
    const DataTemplateType = URLS.DataTemplateType;
    return this.http.get(DataTemplateType);
  }
  getworkEffortName(): Observable<any> {
    const getworkEffortName = URLS.getworkEffortName;
    return this.http.get(getworkEffortName);
  }
  getDataSourceTypeId(): Observable<any> {
    const getDataSourceTypeId = URLS.getDataSourceTypeId;
    return this.http.get(getDataSourceTypeId);
  }
  getMarketCampaignId(): Observable<any> {
    const getMarketCampaignId = URLS.getMarketCampaignId;
    return this.http.get(getMarketCampaignId);
  }
  getStatusList(): Observable<any> {
    const getStatusList = URLS.getStatusLists;
    return this.http.get(getStatusList);
  }
  getCurrencyList(): Observable<any> {
    const getCurrencyList = URLS.getCurrencyList;
    return this.http.get(getCurrencyList);
  }
  getTrackingCodeType(): Observable<any> {
    const getTrackingCodeType = URLS.getTrackingCodeTypeId;
    return this.http.get(getTrackingCodeType);
  }
getrateTypeId(): Observable<any> {
  const getrateTypeId = URLS.getrateTypeId;
  return this.http.get(getrateTypeId);
}
timeSheetList(): Observable<any> {
  const timeSheetList = URLS.timeSheetList;
  return this.http.get(timeSheetList);
}
  getMarketingCampaignIdsList(): Observable<any> {
    const getMarketingCampaignIdsList = URLS.getMarketingCampaignIdsList;
    return this.http.get(getMarketingCampaignIdsList);
  }
  defaultProductCatalogId(): Observable<any> {
    const defaultProductCatalogId = URLS.defaultProductCatalogId;
    return this.http.get(defaultProductCatalogId);
  }
  getSegmentGroupTypeID(): Observable<any> {
    const getSegmentGroupTypeID = URLS.getSegmentGroupTypeID;
    return this.http.get(getSegmentGroupTypeID);
  }
  getProductStoreID(): Observable<any> {
    const getProductStoreID = URLS.getProductStoreID;
    return this.http.get(getProductStoreID);
  }
  getFindScrumMembersList(): Observable<any> {
    const getFindScrumMembersList = URLS.getFindScrumMembersList;
    return this.http.get(getFindScrumMembersList);
  }
  getDataSourceTypeIds(): Observable<any> {
    const getDataSourceTypeIds = URLS.getDataSourceTypeIds;
    return this.http.get(getDataSourceTypeIds);
  }
  getDataSourceType(): Observable<any> {
    const getDataSourceType = URLS.getDataSourceType;
    return this.http.get(getDataSourceType);
  }
  getMarketCampaignIds(): Observable<any> {
    const getMarketCampaignIds = URLS.getMarketCampaignIds;
    return this.http.get(getMarketCampaignIds);
  }
  getStatusListss(): Observable<any> {
    const getStatusListss = URLS.getStatusListss;
    return this.http.get(getStatusListss);
  }
  getCurrencyLists(): Observable<any> {
    const getCurrencyLists = URLS.getCurrencyLists;
    return this.http.get(getCurrencyLists);
  }
  getSegmentGroupTypeIDs(): Observable<any> {
    const getSegmentGroupTypeIDs = URLS.getSegmentGroupTypeIDs;
    return this.http.get(getSegmentGroupTypeIDs);
  }
  getWorkEffortId(): Observable<any> {
    const getWorkEffortId = URLS.getWorkEffortId;
    return this.http.get(getWorkEffortId);
  }
  LookupComputerSoftwareClassEnumId(): Observable<any> {
    const LookupComputerSoftwareClassEnumId = URLS.LookupComputerSoftwareClassEnumId;
    return this.http.get(LookupComputerSoftwareClassEnumId);
  }
  getProductStoreIDs(): Observable<any> {
    const getProductStoreIDs = URLS.getProductStoreIDs;
    return this.http.get(getProductStoreIDs);
  }
  getPendingActivityTaskCrmAll(): Observable<any> {
    const getPendingActivityTaskCrmAll = URLS.getPendingActivityTaskCrmAll;
    return this.http.get(getPendingActivityTaskCrmAll);
  }
  getTrackingCodeTypeIds(): Observable<any> {
    const getTrackingCodeTypeIds = URLS.getTrackingCodeTypeIds;
    return this.http.get(getTrackingCodeTypeIds);
  }
  getResourcePage(): Observable<any> {
    const getResourcePage = URLS.getResourcePage;
    return this.http.get(getResourcePage);
  }
  getPartyIdList(): Observable<any> {
    const getPartyIdList = URLS.getPartyIdList;
    return this.http.get(getPartyIdList);
  }
  getTaskScopeList(): Observable<any> {
    const getTaskScopeList = URLS.getTaskScopeList;
    return this.http.get(getTaskScopeList);
  }
  getTasksNames(): Observable<any> {
    const getTasksNames = URLS.getTasksNames;
    return this.http.get(getTasksNames);
  }
 
  LookupComputerHardwareClassEnumId(): Observable<any> {
    const LookupComputerHardwareClassEnumId = URLS.LookupComputerHardwareClassEnumId;
    return this.http.get(LookupComputerHardwareClassEnumId);
  }
 
  autoUpdateQuoteOrderPrices(formData): Observable<any> {
    const autoUpdateQuoteOrderPrices = URLS.autoUpdateQuoteOrderPrices;
    return this.http.post(autoUpdateQuoteOrderPrices,formData);
  }
  AllContent(): Observable<any> {
    const AllContent = URLS.AllContent;
    return this.http.get(AllContent);
  }
  getUmo(): Observable<any> {
    const getUmo = URLS.getUmo;
    return this.http.get(getUmo);
  }
  getSkillTypeIdList(): Observable<any> {
    const getSkillTypeIdList = URLS.getSkillTypeIdList;
    return this.http.get(getSkillTypeIdList);
  }
  getMarketingCampaignIdsLists(): Observable<any> {
    const getMarketingCampaignIdsLists = URLS.getMarketingCampaignIdsLists;
    return this.http.get(getMarketingCampaignIdsLists);
  }
  defaultProductCatalogIds(): Observable<any> {
    const defaultProductCatalogIds = URLS.defaultProductCatalogIds;
    return this.http.get(defaultProductCatalogIds);
  }
  getTrackingCodeTypesIds(): Observable<any> {
    const getTrackingCodeTypesIds = URLS.getTrackingCodeTypesIds;
    return this.http.get(getTrackingCodeTypesIds);
  }
  getFindTaskList(): Observable<any> {
    const getFindTaskList = URLS.getFindTaskList;
    return this.http.get(getFindTaskList);
  }
  getTypeList(): Observable<any> {
    const getTypeList = URLS.getTypeList;
    return this.http.get(getTypeList);
  }
  getTaskNoteById(nodeId):Observable<any> {
    const getTaskNoteById = URLS.getTaskNoteById.replace("nodeId",nodeId)
    return this.http.get(getTaskNoteById)
    }
    getRoles(timeSheetId):Observable<any> {
      const getRole = URLS.getRole.replace("timesheetId",timeSheetId)
      return this.http.get(getRole)
      }
  getPartyList(): Observable<any> {
    const getPartyIdList = URLS.getPartyList;
    return this.http.get(getPartyIdList);
  }
  getParentPhaseList(): Observable<any> {
    const getParentPhaseList = URLS.getParentPhaseList;
    return this.http.get(getParentPhaseList);
  }
  getNoteList(): Observable<any> {
    const getNoteList = URLS.getNoteList;
    return this.http.get(getNoteList);
  }
  getSkillTypeId(): Observable<any> {
    const getSkillTypeId = URLS.getSkillTypeId;
    return this.http.get(getSkillTypeId);
  }
  getSurveyID(): Observable<any> {
    const getSurveyID = URLS.getSurveyID;
    return this.http.get(getSurveyID);
  }
  getFindTaskContentList(): Observable<any> {
    const getFindTaskContentList = URLS.getFindTaskContentList;
    return this.http.get(getFindTaskContentList);
  }
   
  CreateTaskFromRequest(data): Observable<any> {
    const CreateTaskFromRequest = URLS.CreateTaskFromRequest;
    return this.http.post(CreateTaskFromRequest, data);
  }
  parentContentIdList(): Observable<any> {
    const parentContentIdList = URLS.parentContentIdList;
    return this.http.get(parentContentIdList);
  }
  existingcontentId(): Observable<any> {
    const existingcontentId = URLS.existingcontentId;
    return this.http.get(existingcontentId);
  }
  getChannelId(): Observable<any> {
    const getChannelId = URLS.getChannelId;
    return this.http.get(getChannelId);
  }
  getQuoteTypeIdList(): Observable<any> {
    const getQuoteTypeIdList = URLS.getQuoteTypeIdList;
    return this.http.get(getQuoteTypeIdList);
  }
 
  getQuoteUomId(): Observable<any> {
    const getQuoteUomId = URLS.getQuoteUomId;
    return this.http.get(getQuoteUomId);
  }
  getProductProductStore(): Observable<any> {
    const getProductProductStore = URLS.getProductProductStore;
    return this.http.get(getProductProductStore);
  }
  getQuoteSkillTypeId(): Observable<any> {
    const getQuoteSkillTypeId = URLS.getQuoteSkillTypeId;
    return this.http.get(getQuoteSkillTypeId);
  }
  getQuoteTypeId(): Observable<any> {
    const getQuoteTypeId = URLS.getQuoteTypeId;
    return this.http.get(getQuoteTypeId);
  }
 
  getSubscriptionById(id): Observable<any> {
    const getSubscriptionById = URLS.getSubscriptionById.replace(":subscriptionId",id);
    return this.http.get(getSubscriptionById);
  }
  getQuoteItemLists(id): Observable<any> {
    const getQuoteItemLists = URLS.getQuoteItemLists.replace(":quoteId",id);
    return this.http.get(getQuoteItemLists);
  }
  getContentTypeIds(): Observable<any> {
    const getContentTypeIds = URLS.getContentTypeIds   ;
    return this.http.get(getContentTypeIds);
  }
  getSkillTypeProject(): Observable<any> {
    const getSkillTypeProject = URLS.getSkillTypeProject;
    return this.http.get(getSkillTypeProject);
  }
  FindTimeSheetProjectList(): Observable<any> {
    const FindTimeSheetProjectList = URLS.FindTimeSheetProjectList;
    return this.http.get(FindTimeSheetProjectList);
  }
  workEffortContentTypeList(): Observable<any> {
    const workEffortContentTypeList = URLS.workEffortContentTypeList;
    return this.http.get(workEffortContentTypeList);
  }
  FindTimeSheetProject(comments,fromDate,partyId,statusId,timesheetId): Observable<any> {
    const FindTimeSheetProject = URLS.FindTimeSheetProject + "?comments=" + comments +
     "&fromDate=" + fromDate + "&partyId=" + partyId + "&statusId=" + statusId + "&timesheetId=" + timesheetId
     ;
    return this.http.get(FindTimeSheetProject);
  }
  removeQuoteOrderAttribute(formData): Observable<any> {
    const removeQuoteOrderAttribute = URLS.removeQuoteOrderAttribute ;
    return this.http.post(removeQuoteOrderAttribute,formData);
  }
  getMarketingCampaignsIdsLists(): Observable<any> {
    const getMarketingCampaignsIdsLists = URLS.getMarketingCampaignsIdsLists;
    return this.http.get(getMarketingCampaignsIdsLists);
  }
  getTaskNameList(): Observable<any> {
    const getTaskNameList = URLS.getTaskNameList;
    return this.http.get(getTaskNameList);
  }
  updateTaskPartyAssign(formData): Observable<any> {
    const updateTaskPartyAssign = URLS.updateTaskPartyAssign ;
    return this.http.post(updateTaskPartyAssign,formData);
  }
  updateQuoteCoefficient(formData): Observable<any> {
    const updateQuoteCoefficient = URLS.updateQuoteCoefficient ;
    return this.http.post(updateQuoteCoefficient,formData);
  }
  removeQuoteCoefficient(formData): Observable<any> {
    const removeQuoteCoefficient = URLS.removeQuoteCoefficient ;
    return this.http.post(removeQuoteCoefficient,formData);
  }
  createQuoteCoefficient(formData): Observable<any> {
    const createQuoteCoefficient = URLS.createQuoteCoefficient ;
    return this.http.post(createQuoteCoefficient,formData);
  }
  removeQuoteAttribute(formData): Observable<any> {
    const removeQuoteAttribute = URLS.removeQuoteAttribute ;
    return this.http.post(removeQuoteAttribute,formData);
  }
  updateProductPromoRule(formData): Observable<any> {
    const updateProductPromoRule = URLS.updateProductPromoRule ;
    return this.http.post(updateProductPromoRule,formData);
  }
  getQuoteOrderWorkEffort(quoteId): Observable<any> {
    const getQuoteOrderWorkEffort = URLS.getQuoteOrderWorkEffort +"?quoteId=" + quoteId  ;
    return this.http.get(getQuoteOrderWorkEffort);
  }
  deleteProductPromoRule(formData): Observable<any> {
    const deleteProductPromoRule = URLS.deleteProductPromoRule ;
    return this.http.post(deleteProductPromoRule,formData);
  }
  removeTaskPartyAssign(formData): Observable<any> {
    const removeTaskPartyAssign = URLS.removeTaskPartyAssign ;
    return this.http.post(removeTaskPartyAssign,formData);
  }
  createQuoteItem(formData): Observable<any> {
    const createQuoteItem = URLS.createQuoteItem ;
    return this.http.post(createQuoteItem,formData);
  }
  getChildCommunicationEvent(productId,communicationEventId): Observable<any> {
    const getChildCommunicationEvent = URLS.getChildCommunicationEvent + "?productId=" + productId + "&communicationEventId=" + communicationEventId  ;
    return this.http.get(getChildCommunicationEvent);
  }
  removeQuoteItem(formData): Observable<any> {
    const removeQuoteItem = URLS.removeQuoteItem ;
    return this.http.post(removeQuoteItem,formData);
  }
  updateQuoteTerm(formData): Observable<any> {
    const updateQuoteTerm = URLS.updateQuoteTerm ;
    return this.http.post(updateQuoteTerm,formData);
  }
  getCommunicationEvent(custRequestId): Observable<any> {
    const getCommunicationEvent = URLS.getCommunicationEvent+ "?custRequestId=" + custRequestId;
    return this.http.get(getCommunicationEvent);
  }
  createQuoteRole(formData): Observable<any> {
    const createQuoteRole = URLS.createQuoteRole ;
    return this.http.post(createQuoteRole,formData);
  }
  removeQuoteRole(formData): Observable<any> {
    const removeQuoteRole = URLS.removeQuoteRole ;
    return this.http.post(removeQuoteRole,formData);
  }
  updateQuoteRole(formData): Observable<any> {
    const updateQuoteRole = URLS.updateQuoteRole ;
    return this.http.post(updateQuoteRole,formData);
  }
 
  deleteQuoteTerm(formData): Observable<any> {
    const deleteQuoteTerm = URLS.deleteQuoteTerm ;
    return this.http.post(deleteQuoteTerm,formData);
  }
  updateQuoteItem(formData): Observable<any> {
    const updateQuoteItem = URLS.updateQuoteItem ;
    return this.http.post(updateQuoteItem,formData);
  }
 

  createQuoteFromCustRequest(formData): Observable<any> {
    const createQuoteFromCustRequest = URLS.createQuoteFromCustRequest ;
    return this.http.post(createQuoteFromCustRequest,formData);
  }
  getQuoteTerm(quoteId): Observable<any> {
    const getQuoteTerm = URLS.getQuoteTerm + "?quoteId=" + quoteId;
    return this.http.get(getQuoteTerm);
  }
  getQuoteCoefficient(quoteId): Observable<any> {
    const getQuoteCoefficient = URLS.getQuoteCoefficient + "?quoteId=" + quoteId;
    return this.http.get(getQuoteCoefficient);
  }
  getPromoCodePartiesList(): Observable<any> {
    const getPromoCodePartiesList = URLS.getPromoCodePartiesList;
    return this.http.get(getPromoCodePartiesList);
  }
  getProductFeatures(): Observable<any> {
    const getProductFeatures = URLS.getProductFeatures;
    return this.http.get(getProductFeatures);
  }
  existingContentIdList(): Observable<any> {
    const existingContentIdList = URLS.existingContentIdList;
    return this.http.get(existingContentIdList);
  }
  updateProductPromoCond(formData): Observable<any> {
    const updateProductPromoCond = URLS.updateProductPromoCond ;
    return this.http.post(updateProductPromoCond,formData);
  }
  deleteProductPromoCond(formData): Observable<any> {
    const deleteProductPromoCond = URLS.deleteProductPromoCond ;
    return this.http.post(deleteProductPromoCond,formData);
  }
  createProductPromoRule(formData): Observable<any> {
    const createProductPromoRule = URLS.createProductPromoRule ;
    return this.http.post(createProductPromoRule,formData);
  }
  deleteProductActionProduct(formData): Observable<any> {
    const deleteProductActionProduct = URLS.deleteProductActionProduct ;
    return this.http.post(deleteProductActionProduct,formData);
  }
  createCategoryAction(formData): Observable<any> {
    const createCategoryAction = URLS.createCategoryAction ;
    return this.http.post(createCategoryAction,formData);
  }
  createProductActionProduct(formData): Observable<any> {
    const createProductActionProduct = URLS.createProductActionProduct ;
    return this.http.post(createProductActionProduct,formData);
  }
  createProductPromoAction(formData): Observable<any> {
    const createProductPromoAction = URLS.createProductPromoAction ;
    return this.http.post(createProductPromoAction,formData);
  }
  createQuoteOrder(formData): Observable<any> {
    const createQuoteOrder = URLS.createQuoteOrder;
    return this.http.post(createQuoteOrder, formData);
  }
  deleteProductPromoAction(formData): Observable<any> {
    const deleteProductPromoAction = URLS.deleteProductPromoAction ;
    return this.http.post(deleteProductPromoAction,formData);
  }
  updateProductPromoAction(formData): Observable<any> {
    const updateProductPromoAction = URLS.updateProductPromoAction ;
    return this.http.post(updateProductPromoAction,formData);
  }
  createProductPromoCond(formData): Observable<any> {
    const createProductPromoCond = URLS.createProductPromoCond ;
    return this.http.post(createProductPromoCond,formData);
  }
  createProductActionCategory(formData): Observable<any> {
    const createProductActionCategory = URLS.createProductActionCategory ;
    return this.http.post(createProductActionCategory,formData);
  }
  deleteProductActionCategory(formData): Observable<any> {
    const deleteProductActionCategory = URLS.deleteProductActionCategory ;
    return this.http.post(deleteProductActionCategory,formData);
  }
  removeDependencyBefore(formData): Observable<any> {
    const removeDependencyBefore = URLS.removeDependencyBefore ;
    return this.http.post(removeDependencyBefore,formData);
  }

  deleteSkillType(id): Observable<any> {
    const deleteSkillType = URLS.deleteSkillTypes.replace(":skillTypeId", id);
    return this.http.post(deleteSkillType, "");
  }
  deletedTaskContentById(contentId): Observable<any> {
    const deletedTaskContentById = URLS.deletedTaskContentById + "?contentId=" + contentId;
    return this.http.post(deletedTaskContentById,"");
  }


  // deleteOrderByIdAPI(orderId,projectId,workEffortId): Observable<any> {
  //   const deleteOrderById = URLS.deleteOrderById + "?orderId=" + orderId + "?projectId=" + projectId + "?workEffortId=" + workEffortId;
  //   // let payload = {
  //   //   "orderId": orderId,
  //   //   "projectId": projectId,
  //   //   "workEffortId": workEffortId
  //   // }
  //   return this.http.delete(deleteOrderById);

  // }


  // deleteOrderByIdAPI(orderId,projectId,workEffortId): Observable<any> {
  //   const deleteOrderById = URLS.deleteOrderById.replace("orderId", orderId).replace("projectId", projectId).replace("workEffortId", workEffortId)
  //   console.log("payload",deleteOrderById);
  //   return this.http.delete(deleteOrderById);
  // }

  deleteOrderByIdAPI(orderId,projectId,workEffortId): Observable<any> {
      let removeOrderForm = {
        "orderId": orderId,
        "projectId": projectId,
        "workEffortId": workEffortId
      }
      const deleteOrderById = URLS.deleteOrderById;
      return this.http.post(deleteOrderById,removeOrderForm);
  
    }


  defaultProductCatalogsIds(): Observable<any> {
    const defaultProductCatalogsIds = URLS.defaultProductCatalogsIds;
    return this.http.get(defaultProductCatalogsIds);
  }
  getRoleTypeId(): Observable<any> {
    const getRoleTypeId = URLS.getRoleTypeId;
    return this.http.get(getRoleTypeId);
  }
  getTrackingCode(): Observable<any> {
    const getTrackingCode = URLS.getTrackingCode;
    return this.http.get(getTrackingCode);
  }
  getEmailStatusReport(): Observable<any> {
    const getEmailStatusReport = URLS.getEmailStatusReport;
    return this.http.get(getEmailStatusReport);
  }
  getPhaseList(): Observable<any> {
    const getPhaseList = URLS.getPhaseList;
    return this.http.get(getPhaseList);
  }
  getRelated(): Observable<any> {
    const getRelated = URLS.getRelated;
    return this.http.get(getRelated);
  }
  MyStatusToComplete(statusId,timesheetId): Observable<any> {
    const MyStatusToComplete = URLS.MyStatusToComplete + "?statusId=" + statusId +
    "&timesheetId=" + timesheetId  ;
    return this.http.post(MyStatusToComplete,"");
  }
  createProductPromoProduct(formData): Observable<any> {
    const createProductPromoProduct = URLS.createProductPromoProduct ;
    return this.http.post(createProductPromoProduct,formData);
  }
  getContactmechID(): Observable<any> {
    const getContactmechID = URLS.getContactmechID;
    return this.http.get(getContactmechID);
  }
  deleteProductPromoProduct(formData): Observable<any> {
    const deleteProductPromoProduct = URLS.deleteProductPromoProduct ;
    return this.http.post(deleteProductPromoProduct,formData);
  }
  createWeeklyProTimesheet(formData): Observable<any> {
    const createWeeklyProTimesheet = URLS.createWeeklyProTimesheet ;
    return this.http.post(createWeeklyProTimesheet,formData);
  }
  removeDependencyAfter(formData): Observable<any> {
    const removeDependencyAfter = URLS.removeDependencyAfter ;
    return this.http.post(removeDependencyAfter,formData);
  }
  getPartyStatusReport(): Observable<any> {
    const getPartyStatusReport = URLS.getPartyStatusReport;
    return this.http.get(getPartyStatusReport);
  }
  getContactListName(): Observable<any> {
    const getContactListName = URLS.getContactListName;
    return this.http.post(getContactListName,"");
  }
  updateMetaDataPredicate(formData): Observable<any> {
    const updateMetaDataPredicate = URLS.updateMetaDataPredicate;
    return this.http.post(updateMetaDataPredicate,formData);
  }
  removeMetaDataPredicate(formData): Observable<any> {
    const removeMetaDataPredicate = URLS.removeMetaDataPredicate;
    return this.http.post(removeMetaDataPredicate,formData);
  }
  addMetaDataPredicate(formData): Observable<any> {
    const addMetaDataPredicate = URLS.addMetaDataPredicate;
    return this.http.post(addMetaDataPredicate,formData);
  }
  copyProjectToTemplate(formData): Observable<any> {
    const copyProjectToTemplate = URLS.copyProjectToTemplate;
    return this.http.post(copyProjectToTemplate,formData);
  }
  deleteBookmarksUpload(bookmarksUploadId): Observable<any> {
    const deleteBookmarksUpload = URLS.deleteBookmarksUpload + "?bookmarksUploadId=" + bookmarksUploadId;
    return this.http.post(deleteBookmarksUpload,"");
  }
  deleteBookMarksUrl(formData): Observable<any> {
    const deleteBookMarksUrl = URLS.deleteBookMarksUrl;
    return this.http.post(deleteBookMarksUrl,formData);
  }
  updateBookmarksUpload(formData,bookmarksUploadId,classification,custRequestId,description): Observable<any> {
    const updateBookmarksUpload = URLS.updateBookmarksUpload + "?bookmarksUploadId=" + bookmarksUploadId
    + "&classification=" + classification  + "&custRequestId=" + custRequestId + "&description=" + description;
    return this.http.post(updateBookmarksUpload,formData);
  }
  createBookmarksUpload(classification,custRequestId,description,formData): Observable<any> {
    const createBookmarksUpload = URLS.createBookmarksUpload  +  "?classification=" + classification  + 
    "&custRequestId=" + custRequestId +  "&description=" + description  ;
    return this.http.post(createBookmarksUpload,formData);
  }
  getPrepareCreateShipmentTimeEstimate(carrierShipmentString,pageNo,pageSize): Observable<any> {
    const getPrepareCreateShipmentTimeEstimate = URLS.getPrepareCreateShipmentTimeEstimate  +  "?carrierShipmentString=" + carrierShipmentString  + 
    "&pageNo=" + pageNo +  "&pageSize=" + pageSize  ;
    return this.http.post(getPrepareCreateShipmentTimeEstimate,"");
  }
  createBookMarksUrl(formData): Observable<any> {
    const createBookMarksUrl = URLS.createBookMarksUrl;
    return this.http.post(createBookMarksUrl,formData);
  }
  UpdateBookMarksUrl(formData): Observable<any> {
    const UpdateBookMarksUrl = URLS.UpdateBookMarksUrl;
    return this.http.post(UpdateBookMarksUrl,formData);
  }
  deleteBookmarksUploadPartner(formData): Observable<any> {
    const deleteBookmarksUploadPartner = URLS.deleteBookmarksUploadPartner;
    return this.http.post(deleteBookmarksUploadPartner,formData);
  }
  updateBookmarksUploadPartnerLead(formData,bookmarksUploadId,classification,description,partnerId): Observable<any> {
   
    const updateBookmarksUploadPartnerLead = URLS.updateBookmarksUploadPartnerLead + "?bookmarksUploadId=" + bookmarksUploadId
    + "&classification=" + classification  + "&description=" + description + "&partnerId=" + partnerId  ;
    return this.http.post(updateBookmarksUploadPartnerLead,formData);
  }
  updateBookmarksUploadPartner(formData,bookmarksUploadId,classification,description,partnerId): Observable<any> {
   
    const updateBookmarksUploadPartner = URLS.updateBookmarksUploadPartner + "?bookmarksUploadId=" + bookmarksUploadId
    + "&classification=" + classification  + "&description=" + description + "&partnerId=" + partnerId  ;
    return this.http.post(updateBookmarksUploadPartner,formData);
  }
  deleteProductPromoCategory(formData): Observable<any> {
    const deleteProductPromoCategory = URLS.deleteProductPromoCategory;
    return this.http.post(deleteProductPromoCategory,formData);
  }
  getAutoUpdateQuoteOrderPrices(quoteId): Observable<any> {
    const getAutoUpdateQuoteOrderPrices = URLS.getAutoUpdateQuoteOrderPrices +"?quoteId=" + quoteId  ;
    return this.http.get(getAutoUpdateQuoteOrderPrices);
  }
  deleteProductCategory(formData): Observable<any> {
    const deleteProductCategory = URLS.deleteProductCategory;
    return this.http.post(deleteProductCategory,formData);
  }
  deleteInternalRequirementPlanning(formData): Observable<any> {
    const deleteInternalRequirementPlanning = URLS.deleteInternalRequirementPlanning ;
    return this.http.post(deleteInternalRequirementPlanning, formData);
  }
  deleteTransferRequirementPlanning(formData): Observable<any> {
    const deleteTransferRequirementPlanning = URLS.deleteTransferRequirementPlanning ;
    return this.http.post(deleteTransferRequirementPlanning, formData);
  }
 
  addActionCategory(formData): Observable<any> {
    const addActionCategory = URLS.addActionCategory;
    return this.http.post(addActionCategory,formData);
  }
  createProductPromoCategory(formData): Observable<any> {
    const createProductPromoCategory = URLS.createProductPromoCategory;
    return this.http.post(createProductPromoCategory,formData);
  }
  schedule(formData): Observable<any> {
    const schedule = URLS.schedule;
    return this.http.post(schedule,formData);
  }
  deleteProductRequirementPlanning(formData): Observable<any> {
    const deleteProductRequirementPlanning = URLS.deleteProductRequirementPlanning ;
    return this.http.post(deleteProductRequirementPlanning, formData);
  }
  submitManage(caption,id,sortOrder): Observable<any> {
    const submitManage = URLS.submitManage + "?caption=" + caption + "&id=" + id  + "&sortOrder=" + sortOrder ;
    return this.http.post(submitManage,"");
  }
  deleteWorkEffortPartyAssign(formData): Observable<any> {
    const deleteWorkEffortPartyAssign = URLS.deleteWorkEffortPartyAssign;
    return this.http.post(deleteWorkEffortPartyAssign,formData);
  }
  updateICalendar(formData): Observable<any> {
    const updateICalendar = URLS.updateICalendar;
    return this.http.post(updateICalendar,formData);
  }
  updateSalesForecastDetail(formData): Observable<any> {
    const updateSalesForecastDetail = URLS.updateSalesForecastDetail;
    return this.http.post(updateSalesForecastDetail,formData);
  }
  createWorkEffortPartyAssign(formData): Observable<any> {
    const createWorkEffortPartyAssign = URLS.createWorkEffortPartyAssign;
    return this.http.post(createWorkEffortPartyAssign,formData);
  }
  duplicateWorkEffort(formData): Observable<any> {
    const duplicateWorkEffort = URLS.duplicateWorkEffort;
    return this.http.post(duplicateWorkEffort,formData);
  }
  createBookmarksUploadPartner(formData,classification,description,partnerId): Observable<any> {
    const createBookmarksUploadPartner = URLS.createBookmarksUploadPartner  + "?classification=" + classification
    + "&description=" + description + "&partnerId=" + partnerId ;
    return this.http.post(createBookmarksUploadPartner,formData);
  }
  createBookmarksUploadPartnerLead(formData,classification,description,partnerId): Observable<any> {
    const createBookmarksUploadPartnerLead = URLS.createBookmarksUploadPartnerLead  + "?classification=" + classification
    + "&description=" + description + "&partnerId=" + partnerId ;
    return this.http.post(createBookmarksUploadPartnerLead,formData);
  }
  getCustomMethodId(): Observable<any> {
    const getCustomMethodId = URLS.getCustomMethodId;
    return this.http.get(getCustomMethodId);
  }
  getCatalogProdId(): Observable<any> {
    const getCatalogProdId = URLS.getCatalogProdId;
    return this.http.get(getCatalogProdId);
  }
  findOrderRequest(formData): Observable<any> {
    const findOrderRequest = URLS.findOrderRequest;
    return this.http.post(findOrderRequest,formData);
  }
  createRequirement(formData): Observable<any> {
    const createRequirement = URLS.createRequirement;
    return this.http.post(createRequirement,formData);
  }
  getFindOrderRequest(formData): Observable<any> {
    const getFindOrderRequest = URLS.getFindOrderRequest;
    return this.http.post(getFindOrderRequest,formData);
  }
  deleteRequirement(formData): Observable<any> {
    const deleteRequirement = URLS.deleteRequirement;
    return this.http.post(deleteRequirement,formData);
  }
  getFindOrderList(formData): Observable<any> {
    const getFindOrderList = URLS.getFindOrderList;
    return this.http.post(getFindOrderList,formData);
  }
  findOrderList(formData): Observable<any> {
    const findOrderList = URLS.findOrderList;
    return this.http.post(findOrderList,formData);
  }
  getFindRequirements(formData): Observable<any> {
    const getFindRequirements = URLS.getFindRequirements;
    return this.http.post(getFindRequirements,formData);
  }
  findRequirements(formData): Observable<any> {
    const findRequirements = URLS.findRequirements;
    return this.http.post(findRequirements,formData);
  }
  removeQuoteOrderAdjustment(formData): Observable<any> {
    const removeQuoteOrderAdjustment = URLS.removeQuoteOrderAdjustment;
    return this.http.post(removeQuoteOrderAdjustment,formData);
  }
  updateQuoteOrderAttribute(formData): Observable<any> {
    const updateQuoteOrderAttribute = URLS.updateQuoteOrderAttribute;
    return this.http.post(updateQuoteOrderAttribute,formData);
  }
  updateProjectTask(formData): Observable<any> {
    const updateProjectTask = URLS.updateProjectTask;
    return this.http.post(updateProjectTask,formData);
  }
  getCurrentTimesheetNbr(id): Observable<any> {
    const getCurrentTimesheetNbr = URLS.getCurrentTimesheetNbr + "?timesheetId=" + id ;
    return this.http.get(getCurrentTimesheetNbr);
  }
  getAllTimesheet(id): Observable<any> {
    const getAllTimesheet = URLS.getAllTimesheet + "?partyId=" + id ;
    return this.http.get(getAllTimesheet);
  }
  getCurrentTimesheet(id): Observable<any> {
    const getCurrentTimesheet = URLS.getCurrentTimesheet + "?partyId=" + id ;
    return this.http.get(getCurrentTimesheet);
  }
  getITEMSEQ(fixedAssetId): Observable<any> {
    const getITEMSEQ = URLS.getITEMSEQ + "?fixedAssetId=" + fixedAssetId ;
    return this.http.get(getITEMSEQ);
  }
   getFixedAssetIdRequirement(): Observable<any> {
    const getFixedAssetIdRequirement = URLS.getFixedAssetIdRequirement;
    return this.http.get(getFixedAssetIdRequirement);
  }
  orderItemType(): Observable<any> {
    const orderItemType = URLS.orderItemType;
    return this.http.get(orderItemType);
  }
  getRequirementType(): Observable<any> {
    const getRequirementType = URLS.getRequirementType;
    return this.http.get(getRequirementType);
  }
  getFacilityRequirement(): Observable<any> {
    const getFacilityRequirement = URLS.getFacilityRequirement;
    return this.http.get(getFacilityRequirement);
  }
  getCustRequestTypeId(): Observable<any> {
    const getCustRequestTypeId = URLS.getCustRequestTypeId;
    return this.http.get(getCustRequestTypeId);
  }
  getInventoryItemId(): Observable<any> {
    const getInventoryItemId = URLS.getInventoryItemId;
    return this.http.get(getInventoryItemId);
  }
  getCurrencyValue(): Observable<any> {
    const getCurrencyValue = URLS.getCurrencyValue;
    return this.http.get(getCurrencyValue);
  }
  generatePdfQuote(quoteId): Observable<any> {
    const generatePdfQuote = URLS.generatePdfQuote  + "?quoteId=" + quoteId   ;
    return this.http.get(generatePdfQuote, { responseType: "blob" });
  }
  getLogTaskCallPartnerId(partnerId): Observable<any> {
    const getLogTaskCallPartnerId = URLS.getLogTaskCallPartnerId + "?partnerId=" + partnerId ;
    return this.http.get(getLogTaskCallPartnerId);
  }
  getLogTaskEmailPartnerId(partnerId): Observable<any> {
    const getLogTaskEmailPartnerId = URLS.getLogTaskEmailPartnerId + "?partnerId=" + partnerId ;
    return this.http.get(getLogTaskEmailPartnerId);
  }
  getLogTaskCallPartnerIdLead(partnerId): Observable<any> {
    const getLogTaskCallPartnerIdLead = URLS.getLogTaskCallPartnerIdLead + "?partnerId=" + partnerId ;
    return this.http.get(getLogTaskCallPartnerIdLead);
  }
  getLogTaskEmailPartnerIdLead(partnerId): Observable<any> {
    const getLogTaskEmailPartnerIdLead = URLS.getLogTaskEmailPartnerIdLead + "?partnerId=" + partnerId ;
    return this.http.get(getLogTaskEmailPartnerIdLead);
  }
  getBookmarksUploadPartner(partnerId): Observable<any> {
    const getBookmarksUploadPartner = URLS.getBookmarksUploadPartner + "?partnerId=" + partnerId ;
    return this.http.get(getBookmarksUploadPartner);
  }
  getBookmarksUploadPartnerLead(partnerId): Observable<any> {
    const getBookmarksUploadPartnerLead = URLS.getBookmarksUploadPartnerLead + "?partnerId=" + partnerId ;
    return this.http.get(getBookmarksUploadPartnerLead);
  }
  setCustRequestOrderStatus(formData): Observable<any> {
    const setCustRequestOrderStatus = URLS.setCustRequestOrderStatus ;
    return this.http.post(setCustRequestOrderStatus, formData);
  }
  deleteRequirementPlanning(formData): Observable<any> {
    const deleteRequirementPlanning = URLS.deleteRequirementPlanning ;
    return this.http.post(deleteRequirementPlanning, formData);
  }
  updateQuoteWorkEffort(formData): Observable<any> {
    const updateQuoteWorkEffort = URLS.updateQuoteWorkEffort ;
    return this.http.post(updateQuoteWorkEffort, formData);
  }
  deleteQuoteWorkEffort(formData): Observable<any> {
    const deleteQuoteWorkEffort = URLS.deleteQuoteWorkEffort ;
    return this.http.post(deleteQuoteWorkEffort, formData);
  }
  getProjectBilling(workEffortId): Observable<any> {
    const getProjectBilling = URLS.getProjectBilling + "?workEffortId=" + workEffortId ;
    return this.http.get(getProjectBilling);
  }
  createQuoteOrderWorkEffort(formData): Observable<any> {
    const createQuoteOrderWorkEffort = URLS.createQuoteOrderWorkEffort;
    return this.http.post(createQuoteOrderWorkEffort,formData);
  }
  getGeoIds(): Observable<any> {
    const getGeoIds = URLS.getGeoIds;
    return this.http.get(getGeoIds);
  }
  quantityUomId(): Observable<any> {
    const quantityUomId = URLS.quantityUomId;
    return this.http.get(quantityUomId);
  }
  getProductIdForecast(): Observable<any> {
    const getProductIdForecast = URLS.getProductIdForecast;
    return this.http.get(getProductIdForecast);
  }
  getPendingActivityEventCrm(custRequestId): Observable<any> {
    const getPendingActivityEventCrm = URLS.getPendingActivityEventCrm + "?custRequestId=" + custRequestId ;
    return this.http.get(getPendingActivityEventCrm);
  }
  getNoteCRMBId(custRequestId): Observable<any> {
    const getNoteCRMBId = URLS.getNoteCRMBId + "?custRequestId=" + custRequestId ;
    return this.http.get(getNoteCRMBId);
  }
  getPendingActivityTaskCrm(custRequestId): Observable<any> {
    const getPendingActivityTaskCrm = URLS.getPendingActivityTaskCrm + "?custRequestId=" + custRequestId ;
    return this.http.get(getPendingActivityTaskCrm);
  }
  getNoteCRMAll(): Observable<any> {
    const getNoteCRMAll = URLS.getNoteCRMAll  ;
    return this.http.get(getNoteCRMAll);
  }
  getSalesOpportunity(): Observable<any> {
    const getSalesOpportunity = URLS.getSalesOpportunity  ;
    return this.http.get(getSalesOpportunity);
  }
  createQuoteOrderAttribute(formData): Observable<any> {
    const createQuoteOrderAttribute = URLS.createQuoteOrderAttribute;
    return this.http.post(createQuoteOrderAttribute,formData);
  }
  
 
  getCases(): Observable<any> {
    const getCases = URLS.getCases  ;
    return this.http.get(getCases);
  }
  getProductConfigItemContentTypeID(): Observable<any> {
    const getProductConfigItemContentTypeID = URLS.getProductConfigItemContentTypeID  ;
    return this.http.get(getProductConfigItemContentTypeID);
  }
  getClassificationBookmark(): Observable<any> {
    const getClassificationBookmark = URLS.getClassificationBookmark  ;
    return this.http.get(getClassificationBookmark);
  }
  getActivity(custRequestId): Observable<any> {
    const getActivity = URLS.getActivity + "?custRequestId=" + custRequestId ;
    return this.http.get(getActivity);
  }
  getBookMark(custRequestId): Observable<any> {
    const getBookMark = URLS.getBookMark + "?custRequestId=" + custRequestId ;
    return this.http.get(getBookMark);
  }
  getPendingActivity(custRequestId): Observable<any> {
    const getPendingActivity = URLS.getPendingActivity + "?custRequestId=" + custRequestId ;
    return this.http.get(getPendingActivity);
  }
  getProductConfigProductById(configItemId,configOptionId): Observable<any> {
    const getProductConfigProductById = URLS.getProductConfigProductById + "?configItemId=" + configItemId + "&configOptionId=" + configOptionId ;
    return this.http.get(getProductConfigProductById);
  }
  createProductConfigProduct(formData): Observable<any> {
    const createProductConfigProduct = URLS.createProductConfigProduct ;
    return this.http.post(createProductConfigProduct, formData);
  }
  updateProductConfigOption(formData): Observable<any> {
    const updateProductConfigOption = URLS.updateProductConfigOption ;
    return this.http.post(updateProductConfigOption, formData);
  }
  createQuoteOrderAdjustment(formData): Observable<any> {
    const createQuoteOrderAdjustment = URLS.createQuoteOrderAdjustment;
    return this.http.post(createQuoteOrderAdjustment,formData);
  }
 
  
  updateQuoteOrderAdjustment(formData): Observable<any> {
    const updateQuoteOrderAdjustment = URLS.updateQuoteOrderAdjustment;
    return this.http.post(updateQuoteOrderAdjustment,formData);
  }
  updateProductConfigProduct(formData): Observable<any> {
    const updateProductConfigProduct = URLS.updateProductConfigProduct ;
    return this.http.post(updateProductConfigProduct, formData);
  }
  updateProductConfigItem(formData): Observable<any> {
    const updateProductConfigItem = URLS.updateProductConfigItem ;
    return this.http.post(updateProductConfigItem, formData);
  }
  getProductConfigItemArticle(configItemId): Observable<any> {
    const getProductConfigItemArticle = URLS.getProductConfigItemArticle + "?configItemId=" + configItemId ;
    return this.http.get(getProductConfigItemArticle);
  }
  updateQuoteNoteNew(formData): Observable<any> {
    const updateQuoteNoteNew = URLS.updateQuoteNoteNew ;
    return this.http.post(updateQuoteNoteNew, formData);
  }
   deleteProductConfigOption(formData): Observable<any> {
    const deleteProductConfigOption = URLS.deleteProductConfigOption ;
    return this.http.post(deleteProductConfigOption, formData);
  }
  updateSimpleTextContentForProductConfigItem(formData): Observable<any> {
    const updateSimpleTextContentForProductConfigItem = URLS.updateSimpleTextContentForProductConfigItem ;
    return this.http.post(updateSimpleTextContentForProductConfigItem, formData);
  }
  createProductConfigOption(formData): Observable<any> {
    const createProductConfigOption = URLS.createProductConfigOption ;
    return this.http.post(createProductConfigOption, formData);
  }
  getProductCatalogData(id): Observable<any> {
    const getProductCatalogConfiguration = URLS.getProductCatalogConfiguration.replace(":id", id);
    return this.http.get(getProductCatalogConfiguration);
  }
  copyQuoteOrders(formData): Observable<any> {
    const copyQuoteOrders = URLS.copyQuoteOrders ;
    return this.http.post(copyQuoteOrders, formData);
  }
  getBookmarksUpload(custRequestId): Observable<any> {
    const getBookmarksUpload = URLS.getBookmarksUpload + "?custRequestId=" + custRequestId ;
    return this.http.get(getBookmarksUpload);
  }
  getBookMarksUrlId(custRequestId): Observable<any> {
    const getBookMarksUrlId = URLS.getBookMarksUrlId + "?custRequestId=" + custRequestId ;
    return this.http.get(getBookMarksUrlId);
  }
  getCasesById(custRequestId): Observable<any> {
    const getCasesById = URLS.getCasesById + "?custRequestId=" + custRequestId ;
    return this.http.get(getCasesById);
  }
  
  getProductConfigOption(configItemId): Observable<any> {
    const getProductConfigOption = URLS.getProductConfigOption + "?configItemId=" + configItemId ;
    return this.http.get(getProductConfigOption);
  }
  getProductConfigItem(configItemId): Observable<any> {
    const getProductConfigItem = URLS.getProductConfigItem + "?configItemId=" + configItemId ;
    return this.http.get(getProductConfigItem);
  }
  EditProductConfigItemContentContent(confItemContentTypeId,configItemId,contentId,fromDate): Observable<any> {
    const EditProductConfigItemContentContent = URLS.EditProductConfigItemContentContent + "?confItemContentTypeId=" + confItemContentTypeId +
     "&configItemId=" + configItemId + "&contentId=" + contentId + "&fromDate=" + fromDate ;
    return this.http.get(EditProductConfigItemContentContent);
  }
  getEditProductConfigItemContent(configItemId): Observable<any> {
    const getEditProductConfigItemContent = URLS.getEditProductConfigItemContent + "?configItemId=" + configItemId ;
    return this.http.get(getEditProductConfigItemContent);
  }
  getShipmentTimeEstimate(shipmentMethodTypeId): Observable<any> {
    const getShipmentTimeEstimate = URLS.getShipmentTimeEstimate+"?shipmentMethodTypeId="+shipmentMethodTypeId;
    return this.http.get(getShipmentTimeEstimate);
  }

  FindForumMessages(id,idTo): Observable<any> {
    const FindForumMessages = URLS.FindForumMessages+"?contentId="+id+"&contentIdTo="+idTo;
    return this.http.get(FindForumMessages);
    }
  getCarrierShipmentMethod(): Observable<any> {
    const getCarrierShipmentMethod = URLS.getCarrierShipmentMethod  ;
    return this.http.get(getCarrierShipmentMethod);
  }
  getConditionValue(): Observable<any> {
    const getConditionValue = URLS.getConditionValue  ;
    return this.http.get(getConditionValue);
  }
  getQuoteOrderRole(quoteId): Observable<any> {
    const getQuoteOrderRole = URLS.getQuoteOrderRole+ "?quoteId=" + quoteId;
    return this.http.get(getQuoteOrderRole);
  }
  ImageRejectedList(): Observable<any> {
    const ImageRejectedList = URLS.ImageRejectedList  ;
    return this.http.get(ImageRejectedList);
  }
  listPeopleRejected(): Observable<any> {
    const listPeopleRejected = URLS.listPeopleRejected  ;
    return this.http.get(listPeopleRejected);
  }
  ImageApproveList(): Observable<any> {
    const ImageApproveList = URLS.ImageApproveList  ;
    return this.http.get(ImageApproveList);
  }
  getLeadTimeUomId(): Observable<any> {
    const getLeadTimeUomId = URLS.getLeadTimeUomId  ;
    return this.http.get(getLeadTimeUomId);
  }
 
  getProductCategoryLists(): Observable<any> {
    const getProductCategoryLists = URLS.getProductCategoryLists  ;
    return this.http.get(getProductCategoryLists);
  }
  getShipmentMethodList(): Observable<any> {
    const getShipmentMethodList = URLS.getShipmentMethodList  ;
    return this.http.get(getShipmentMethodList);
  }
  getProductPromoEnumTypeIdList(): Observable<any> {
    const getProductPromoEnumTypeIdList = URLS.getProductPromoEnumTypeIdList  ;
    return this.http.get(getProductPromoEnumTypeIdList);
  }
  getEnumCodeId(): Observable<any> {
    const getEnumCodeId = URLS.getEnumCodeId  ;
    return this.http.get(getEnumCodeId);
  }
  getShipmentTimeEstimateById(): Observable<any> {
    const getShipmentTimeEstimateById = URLS.getShipmentTimeEstimateById  ;
    return this.http.get(getShipmentTimeEstimateById);
  }
  getPriceRuleList(): Observable<any> {
    const getPriceRuleList = URLS.getPriceRuleList  ;
    return this.http.get(getPriceRuleList);
  }
  getQuoteSkillType(): Observable<any> {
    const getQuoteSkillType = URLS.getQuoteSkillType;
    return this.http.get(getQuoteSkillType);
  }
  getConditionValues(): Observable<any> {
    const getConditionValues = URLS.getConditionValues  ;
    return this.http.get(getConditionValues);
  }
  getInputList(): Observable<any> {
    const getInputList = URLS.getInputList  ;
    return this.http.get(getInputList);
  } 
  getUseTimeUom(): Observable<any> {
    const getUseTimeUom = URLS.getUseTimeUom;
    return this.http.get(getUseTimeUom);
  }
  getOriginatedFromRoleTypeId(): Observable<any> {
    const getOriginatedFromRoleTypeId = URLS.getOriginatedFromRoleTypeId ;
    return this.http.get(getOriginatedFromRoleTypeId);
  }
  updateQuoteOrderItem(formData): Observable<any> {
    const updateQuoteOrderItem = URLS.updateQuoteOrderItem ;
    return this.http.post(updateQuoteOrderItem,formData);
  }
  getUseRoleTypeId(): Observable<any> {
    const getUseRoleTypeId = URLS.getUseRoleTypeId ;
    return this.http.get(getUseRoleTypeId);
  }
  getUseTimeUomId(): Observable<any> {
    const getUseTimeUomId = URLS.getUseTimeUomId  ;
    return this.http.get(getUseTimeUomId);
  } 
  getPromotionCodeListNew(): Observable<any> {
    const getPromotionCodeListNew = URLS.getPromotionCodeListNew;
    return this.http.get(getPromotionCodeListNew);
  }
  getOrderById(): Observable<any> {
    const getOrderById = URLS.getOrderById;
    return this.http.get(getOrderById);
  }
  getActionTypeList(): Observable<any> {
    const getActionTypeList = URLS.getActionTypeList ;
    return this.http.get(getActionTypeList);
  }
  getBillingPage(productId): Observable<any> {
    const getBillingPage = URLS.getBillingPage + "?productId=" + productId ;
    return this.http.get(getBillingPage);
  }
  getQuoteOrderItemList(id): Observable<any> {
    const getQuoteOrderItemList = URLS.getQuoteOrderItemList.replace(":quoteId",id);
    return this.http.get(getQuoteOrderItemList);
  }
  deleteProductPriceCond(formData): Observable<any> {
    const deleteProductPriceCond = URLS.deleteProductPriceCond;
    return this.http.post(deleteProductPriceCond,formData);
  }
  deleteProductPriceRule(formData): Observable<any> {
    const deleteProductPriceRule = URLS.deleteProductPriceRule;
    return this.http.post(deleteProductPriceRule,formData);
  }
  updateProductPriceRule(formData): Observable<any> {
    const updateProductPriceRule = URLS.updateProductPriceRule;
    return this.http.post(updateProductPriceRule,formData);
  }
  createProductPriceCond(formData): Observable<any> {
    const createProductPriceCond = URLS.createProductPriceCond;
    return this.http.post(createProductPriceCond,formData);
  }
  updateProductPriceAction(formData): Observable<any> {
    const updateProductPriceAction = URLS.updateProductPriceAction;
    return this.http.post(updateProductPriceAction,formData);
  }
  deleteProductPriceAction(formData): Observable<any> {
    const deleteProductPriceAction = URLS.deleteProductPriceAction;
    return this.http.post(deleteProductPriceAction,formData);
  }
  sendQuoteOrderReportMail(formData): Observable<any> {
    const sendQuoteOrderReportMail = URLS.sendQuoteOrderReportMail;
    return this.http.post(sendQuoteOrderReportMail,formData);
  }
  getQuoteOrderTerm(quoteId): Observable<any> {
    const getQuoteOrderTerm = URLS.getQuoteOrderTerm + "?quoteId=" + quoteId;
    return this.http.get(getQuoteOrderTerm);
  }
  createProductPriceAction(formData): Observable<any> {
    const createProductPriceAction = URLS.createProductPriceAction;
    return this.http.post(createProductPriceAction,formData);
  }
  createProductConfigItem(formData): Observable<any> {
    const createProductConfigItem = URLS.createProductConfigItem;
    return this.http.post(createProductConfigItem,formData);
  }
  updateProductPriceCond(formData): Observable<any> {
    const updateProductPriceCond = URLS.updateProductPriceCond;
    return this.http.post(updateProductPriceCond,formData);
  }
  createShipmentTimeEstimate(formData): Observable<any> {
    const createShipmentTimeEstimate = URLS.createShipmentTimeEstimate;
    return this.http.post(createShipmentTimeEstimate,formData);
  }
  expireShipmentTimeEstimate(formData): Observable<any> {
    const expireShipmentTimeEstimate = URLS.expireShipmentTimeEstimate;
    return this.http.post(expireShipmentTimeEstimate,formData);
  }
  createProductPromoCodeSet(formData): Observable<any> {
    const createProductPromoCodeSet = URLS.createProductPromoCodeSet;
    return this.http.post(createProductPromoCodeSet,formData);
  }
  getQuoteOrderCoefficient(quoteId): Observable<any> {
    const getQuoteOrderCoefficient = URLS.getQuoteOrderCoefficient + "?quoteId=" + quoteId;
    return this.http.get(getQuoteOrderCoefficient);
  }
  createSubscription(formData): Observable<any> {
    const createSubscription = URLS.createSubscription;
    return this.http.post(createSubscription,formData);
  }
  updateProductSubscriptionResource(formData): Observable<any> {
    const updateProductSubscriptionResource = URLS.updateProductSubscriptionResource;
    return this.http.post(updateProductSubscriptionResource,formData);
  }
  updateShipmentTimeEstimate(formData): Observable<any> {
    const updateShipmentTimeEstimate = URLS.updateShipmentTimeEstimate;
    return this.http.post(updateShipmentTimeEstimate,formData);
  }
  findSubscription(formData): Observable<any> {
    const findSubscription = URLS.findSubscription;
    return this.http.post(findSubscription,formData);
  }
  closeStatus(formData): Observable<any> {
    const closeStatus = URLS.closeStatus;
    return this.http.post(closeStatus,formData);
  }
  ContentSearchResults(formData): Observable<any> {
    const ContentSearchResults = URLS.ContentSearchResults;
    return this.http.post(ContentSearchResults,formData);
  }
  navigateContent(): Observable<any> {
    const navigateContent = URLS.navigateContent;
    return this.http.post(navigateContent,"");
  }
  navigateContentC(id): Observable<any> {
    const navigateContentC = URLS.navigateContentC + "?contentIdTo=" + id ;
    return this.http.post(navigateContentC,"");
  }

  updateWebSiteRole(formData): Observable<any> {
    const updateWebSiteRole = URLS.updateWebSiteRole;
    return this.http.post(updateWebSiteRole,formData);
  }
  createWebSite(formData): Observable<any> {
    const createWebSite = URLS.createWebSite;
    return this.http.post(createWebSite,formData);
  }
  removeDocumentFromTree(formData): Observable<any> {
    const removeDocumentFromTree = URLS.removeDocumentFromTree;
    return this.http.post(removeDocumentFromTree,formData);
  }
  updateAllContentKeywords(): Observable<any> {
    const updateAllContentKeywords = URLS.updateAllContentKeywords;
    return this.http.post(updateAllContentKeywords,"");
  }
  createRequirementRole(formData): Observable<any> {
    const createRequirementRole = URLS.createRequirementRole;
    return this.http.post(createRequirementRole,formData);
  }
  updateRequirementRole(formData): Observable<any> {
    const updateRequirementRole = URLS.updateRequirementRole;
    return this.http.post(updateRequirementRole,formData);
  }
  deleteRequirementRole(formData): Observable<any> {
    const deleteRequirementRole = URLS.deleteRequirementRole;
    return this.http.post(deleteRequirementRole,formData);
  }
  getStoreWebsiteList(): Observable<any> {
    const getStoreWebsiteList = URLS.getStoreWebsiteList
    return this.http.get(getStoreWebsiteList);
  }
  getQuoteOrderAttribute(quoteId): Observable<any> {
    const getQuoteOrderAttribute = URLS.getQuoteOrderAttribute+ "?quoteId=" + quoteId;
    return this.http.get(getQuoteOrderAttribute);
  }
  updateRequirement(formData): Observable<any> {
    const updateRequirement = URLS.updateRequirement;
    return this.http.post(updateRequirement,formData);
  }
  copyProject(formData): Observable<any> {
    const copyProject = URLS.copyProject;
    return this.http.post(copyProject,formData);
  }
  createQuoteOrderNote(formData, quoteId): Observable<any> {
    const createQuoteOrderNote = URLS.createQuoteOrderNote.replace(":quoteId", quoteId);
    return this.http.post(createQuoteOrderNote, formData);
  }
  storeUpdateWebSite(formData): Observable<any> {
    const storeUpdateWebSite = URLS.storeUpdateWebSite;
    return this.http.post(storeUpdateWebSite,formData);
  }
  removeStoreUpdateWebSite(formData): Observable<any> {
    const removeStoreUpdateWebSite = URLS.storeUpdateWebSite;
    return this.http.post(removeStoreUpdateWebSite,formData);
  }
  shipmentCostEstimatedUom(): Observable<any> {
    const shipmentCostEstimatedUom = URLS.shipmentCostEstimatedUom;
    return this.http.get(shipmentCostEstimatedUom);
  }
  getQuoteOrderNotes(quoteId): Observable<any> {
    const getQuoteOrderNotes = URLS.getQuoteOrderNotes+ "?quoteId=" + quoteId;
    return this.http.get(getQuoteOrderNotes);
  }
  viewAllocationPlan(id):Observable<any>{
    const viewAllocationPlan = URLS.viewAllocationPlan.replace(":planId",id);
    return this.http.get(viewAllocationPlan);
  }
  getProductStoreWebsiteById(id):Observable<any>{
    const getProductStoreWebsiteById = URLS.getProductStoreWebsiteById.replace(":productStoreId",id);
    return this.http.get(getProductStoreWebsiteById);
  }
  getTimeSheetById(id):Observable<any>{
    const getTimeSheetById = URLS.getTimeSheetById.replace(":workEffortId",id);
    return this.http.get(getTimeSheetById);
  }
  ContentSearchOptions(): Observable<any> {
    const ContentSearchOptions = URLS.ContentSearchOptions;
    return this.http.get(ContentSearchOptions);
  }
  getSupplierList(): Observable<any> {
    const getSupplierList = URLS.getSupplierList;
    return this.http.get(getSupplierList);
  }
  billToCustomerPartyId(): Observable<any> {
    const billToCustomerPartyId = URLS.billToCustomerPartyId;
    return this.http.get(billToCustomerPartyId);
  }
 
  getRoleTypeIdOrder(): Observable<any> {
    const getRoleTypeIdOrder = URLS.getRoleTypeIdOrder;
    return this.http.get(getRoleTypeIdOrder);
  }
  getRequirementRoleListById(id):Observable<any>{
    const getRequirementRoleListById = URLS.getRequirementRoleListById.replace(":requirementId",id);
    return this.http.get(getRequirementRoleListById);
  }
  getRequirementRoleByPartyId(id):Observable<any>{
    const getRequirementRoleByPartyId = URLS.getRequirementRoleByPartyId.replace(":partyId",id);
    return this.http.get(getRequirementRoleByPartyId);
  }
  getRequirementById(id):Observable<any>{
    const getRequirementById = URLS.getRequirementById.replace(":requirementId",id);
    return this.http.get(getRequirementById);
  }
  getPromoCodeLayout(): Observable<any> {
    const getPromoCodeLayout = URLS.getPromoCodeLayout;
    return this.http.get(getPromoCodeLayout);
  }
  getScopesList(): Observable<any> {
    const getScopesList = URLS.getScopesList;
    return this.http.get(getScopesList);
  }
  getActionTypePriceRules(): Observable<any> {
    const getActionTypePriceRules = URLS.getActionTypePriceRules;
    return this.http.get(getActionTypePriceRules);
  }
  getCalenStatusList(): Observable<any> {
    const getCalenStatusList = URLS.getCalenStatusList;
    return this.http.get(getCalenStatusList);
  }
  getQuoteItem_By_QuoteItemSeqIdAndQuoteId(quoteId, quoteItemSeqId):Observable<any>{
    const getQuoteItem_By_QuoteItemSeqIdAndQuoteId = URLS.getQuoteItem_By_QuoteItemSeqIdAndQuoteId.replace(":quoteId",quoteId).replace(":quoteItemSeqId",quoteItemSeqId);
    return this.http.get(getQuoteItem_By_QuoteItemSeqIdAndQuoteId);
  }
  createQuoteOrderItem(formData): Observable<any> {
    const createQuoteOrderItem = URLS.createQuoteOrderItem ;
    return this.http.post(createQuoteOrderItem,formData);
  }
  getProductFeature(): Observable<any> {
    const getProductFeature = URLS.getProductFeatures;
    return this.http.get(getProductFeature);
  }
  getTrackCodeTypeById(trackCodeTypeId): Observable<any> {
    const getTrackCodeTypeById = URLS.getTrackCodeTypeById+"/"+trackCodeTypeId;
    return this.http.get(getTrackCodeTypeById);
  }
  getDataSourceTypeById(dataSourceTypeId): Observable<any> {
    const getDataSourceTypeById = URLS.getDataSourceTypeById.replace(":dataSourceTypeId", dataSourceTypeId);
    return this.http.get(getDataSourceTypeById);
  }
  getActionList(id): Observable<any> {
    const getActionList = URLS.getActionList.replace(":productPriceRuleId", id);
    return this.http.get(getActionList);
  }
  getPriceRuleCond(id): Observable<any> {
    const getPriceRuleCond = URLS.getPriceRuleCond.replace(":productPriceRuleId", id);
    return this.http.get(getPriceRuleCond);
  }
  getPriceRuleProductPriceRuleId(id): Observable<any> {
    const getPriceRuleProductPriceRuleId = URLS.getPriceRuleProductPriceRuleId.replace(":productPriceRuleId", id);
    return this.http.get(getPriceRuleProductPriceRuleId);
  }
  getDataSourceByDataSourceId(dataSourceId): Observable<any> {
    const getDataSourceByDataSourceId = URLS.getDataSourceByDataSourceId.replace(":dataSourceId", dataSourceId);
    return this.http.get(getDataSourceByDataSourceId);
  }
  getProductPromotionCodeByProductPromoId(id): Observable<any> {
    const getProductPromotionCodeByProductPromoId = URLS.getProductPromotionCodeByProductPromoId.replace(":productPromoCodeId", id);
    return this.http.get(getProductPromotionCodeByProductPromoId);
  }
  navigateDataResourceC(id): Observable<any> {
    const navigateDataResourceC = URLS.navigateDataResourceC + "?dataCategoryId=" + id;
    return this.http.get(navigateDataResourceC);
  }
  navigateDataResource(): Observable<any> {
    const navigateDataResource = URLS.navigateDataResource ;
    return this.http.get(navigateDataResource);
  }
  getMainQuoteOrder(quoteId): Observable<any> {
    const getMainQuoteOrder = URLS.getMainQuoteOrder + "?quoteId=" + quoteId;
    return this.http.get(getMainQuoteOrder);
  }
  getFrameList(): Observable<any> {
    const getFrameList = URLS.getFrameList ;
    return this.http.get(getFrameList);
  }
  getPromotionCodeList(id): Observable<any> {
    const getPromotionCodeList = URLS.getPromotionCodeList.replace(":productPromoId", id);
    return this.http.get(getPromotionCodeList);
  }
  getProductPromotionCodeById(id): Observable<any> {
    const getProductPromotionCodeById = URLS.getProductPromotionCodeById.replace(":productPromoCodeId", id);
    return this.http.get(getProductPromotionCodeById);
  }
  getManagedViewImage(id): Observable<any> {
    const getManagedViewImage = URLS.getManagedViewImage.replace(":id", id);
    return this.http.get(getManagedViewImage);
  }
  getPromotionCategoryList(id): Observable<any> {
    const getPromotionCategoryList = URLS.getPromotionCategoryList.replace(":productPromoId", id);
    return this.http.get(getPromotionCategoryList);
  }
  createQuoteTerm(formData): Observable<any> {
    const createQuoteTerm = URLS.createQuoteTerm ;
    return this.http.post(createQuoteTerm,formData);
  }
  getProductPromoRules(id): Observable<any> {
    const getProductPromoRules = URLS.getProductPromoRules.replace(":productPromoId", id);
    return this.http.get(getProductPromoRules);
  }
  getPromotionProductList(id): Observable<any> {
    const getPromotionProductList = URLS.getPromotionProductList.replace(":productPromoId", id);
    return this.http.get(getPromotionProductList);
  }
  removeManageImage(id): Observable<any> {
    const removeManageImage = URLS.removeManageImage.replace(":partyId", id);
    return this.http.get(removeManageImage);
  }
  getTeamMemberByPartyId(id): Observable<any> {
    const getTeamMemberByPartyId = URLS.getTeamMemberByPartyId.replace(":partyId", id);
    return this.http.get(getTeamMemberByPartyId);
  }
  ManualImportedOnly(manualOnly, productPromoId):Observable<any>{
    const ManualImportedOnly = URLS.ManualImportedOnly.replace(":manualOnly",manualOnly).replace(":productPromoId",productPromoId);
    return this.http.get(ManualImportedOnly);
  }
  getManageList(id): Observable<any> {
    const getManageList = URLS.getManageList.replace(":productId", id);
    return this.http.get(getManageList);
  }

  eventDetail(id): Observable<any> {
   
    const eventDetail = URLS.eventDetail.replace(":workEffortId", id);
    return this.http.get(eventDetail);
  }
  changeAllocationPlanStatus(planId, statusId):Observable<any>{
    const changeAllocationPlanStatus = URLS.changeAllocationPlanStatus.replace(":planId",planId).replace(":statusId",statusId);
    return this.http.post(changeAllocationPlanStatus,"");
  }
  downloadFileTaskContent(fileUrl): Observable<any> {
    const downloadFileTaskContent = URLS.downloadFileTaskContent  + "?fileUrl=" + fileUrl   ;
    return this.http.get(downloadFileTaskContent, { responseType: "blob" });
  }
  createQuoteOrderTerm(formData): Observable<any> {
    const createQuoteOrderTerm = URLS.createQuoteOrderTerm ;
    return this.http.post(createQuoteOrderTerm,formData);
  }
  createQuoteOrderRole(formData): Observable<any> {
    const createQuoteOrderRole = URLS.createQuoteOrderRole ;
    return this.http.post(createQuoteOrderRole,formData);
  }
  addContentOperation(formData): Observable<any> {
    const addContentOperation = URLS.addContentOperation ;
    return this.http.post(addContentOperation,formData);
  }
  updateContentOperation(formData): Observable<any> {
    const updateContentOperation = URLS.updateContentOperation ;
    return this.http.post(updateContentOperation,formData);
  }
  removeQuoteOrderRole(formData): Observable<any> {
    const removeQuoteOrderRole = URLS.removeQuoteOrderRole ;
    return this.http.post(removeQuoteOrderRole,formData);
  }
  removeContentOperation(formData): Observable<any> {
    const removeContentOperation = URLS.removeContentOperation ;
    return this.http.post(removeContentOperation,formData);
  }
  UpdatePendingActivityEvent(req): Observable<any> {
    const UpdatePendingActivityEventPartner = URLS.UpdatePendingActivityEvent;
    return this.http.post(UpdatePendingActivityEventPartner, req);

  }
  CreatePendingActivityEvent(req): Observable<any> {
    const CreatePendingActivityEventPartner = URLS.CreatePendingActivityEvent;
    return this.http.post(CreatePendingActivityEventPartner, req);

  }
  deleteQuoteOrderTerm(formData): Observable<any> {
    const deleteQuoteOrderTerm = URLS.deleteQuoteOrderTerm ;
    return this.http.post(deleteQuoteOrderTerm,formData);
  }
  deleteAllocationPlanItem(formData): Observable<any> {
    const deleteAllocationPlanItem = URLS.deleteAllocationPlanItem ;
    return this.http.post(deleteAllocationPlanItem, formData);
  }
  updateQuoteOrderCoefficient(formData): Observable<any> {
    const updateQuoteOrderCoefficient = URLS.updateQuoteOrderCoefficient ;
    return this.http.post(updateQuoteOrderCoefficient,formData);
  }
  updateQuoteOrderTerm(formData): Observable<any> {
    const updateQuoteOrderTerm = URLS.updateQuoteOrderTerm ;
    return this.http.post(updateQuoteOrderTerm,formData);
  }
  removeQuoteOrderCoefficient(formData): Observable<any> {
    const removeQuoteOrderCoefficient = URLS.removeQuoteOrderCoefficient ;
    return this.http.post(removeQuoteOrderCoefficient,formData);
  }
  createQuoteOrderCoefficient(formData): Observable<any> {
    const createQuoteOrderCoefficient = URLS.createQuoteOrderCoefficient ;
    return this.http.post(createQuoteOrderCoefficient,formData);
  }
  forumFilter(): Observable<any> {
    const forumFilter = URLS.forumFilter ;
    return this.http.post(forumFilter,"");
  }
  updateAgreementEmploymentAppl(formData): Observable<any> {
    const updateAgreementEmploymentAppl = URLS.updateAgreementEmploymentAppl ;
    return this.http.post(updateAgreementEmploymentAppl,formData);
  }
  updateQuoteOrderRole(formData): Observable<any> {
    const updateQuoteOrderRole = URLS.updateQuoteOrderRole ;
    return this.http.post(updateQuoteOrderRole,formData);
  }
  uploadFileTaskContent(formData,contentId,file): Observable<any> {
    const uploadFileTaskContent = URLS.uploadFileTaskContent + "?contentId=" + contentId + "&file=" + file    ;
    return this.http.post(uploadFileTaskContent, formData);
  }
  createBulkProductPromoCode(formData,productPromoId,requireEmailOrParty,useLimitPerCode,useLimitPerCustomer,userEntered): Observable<any> {
    const createBulkProductPromoCode = URLS.createBulkProductPromoCode  + "?productPromoId=" + productPromoId
    + "&requireEmailOrParty=" + requireEmailOrParty + "&useLimitPerCode=" + useLimitPerCode + "&useLimitPerCustomer=" + useLimitPerCustomer + "&userEntered=" + userEntered ;
    return this.http.post(createBulkProductPromoCode,formData);
  }
  getResizeDataList(productId, contentId,dataResourceId):Observable<any>{
    const getResizeDataList = URLS.getResizeDataList.replace(":productId",productId).replace(":contentId",contentId) + "?dataResourceId=" + dataResourceId ;
    return this.http.get(getResizeDataList);
  }
  emplPosition():Observable<any>{
    const emplPosition = URLS.emplPosition;
    return this.http.get(emplPosition);
  }
  getImageUploadList(productId):Observable<any>{
    const getImageUploadList = URLS.getImageUploadList.replace(":productId",productId);
    return this.http.get(getImageUploadList);
  }
  getEmployments(id):Observable<any>{
    const getEmployments = URLS.getEmployments.replace(":partyId",id);
    return this.http.get(getEmployments);
  }
  getPromotionManualImported(manualOnly, productPromoId):Observable<any>{
    const getPromotionManualImported = URLS.getPromotionManualImported.replace(":manualOnly",manualOnly).replace(":productPromoId",productPromoId);
    return this.http.get(getPromotionManualImported);
  }
  removeQuoteItemOrder(formData): Observable<any> {
    const removeQuoteItemOrder = URLS.removeQuoteOrderItem ;
    return this.http.post(removeQuoteItemOrder,formData);
  }
  deleteBasicWorkOrderType(formData): Observable<any> {
    const deleteBasicWorkOrderType = URLS.deleteBasicWorkOrderType ;
    return this.http.post(deleteBasicWorkOrderType,formData);
  }
  createCustomWorkOrderType(formData): Observable<any> {
    const createCustomWorkOrderType = URLS.createCustomWorkOrderType ;
    return this.http.post(createCustomWorkOrderType,formData);
  }
  deleteCustomWorkOrderType(formData): Observable<any> {
    const deleteCustomWorkOrderType = URLS.deleteCustomWorkOrderType ;
    return this.http.post(deleteCustomWorkOrderType,formData);
  }
  updateCustomWorkOrderType(formData): Observable<any> {
    const updateCustomWorkOrderType = URLS.updateCustomWorkOrderType ;
    return this.http.post(updateCustomWorkOrderType,formData);
  }
  createBasicWorkOrderType(formData): Observable<any> {
    const createBasicWorkOrderType = URLS.createBasicWorkOrderType ;
    return this.http.post(createBasicWorkOrderType,formData);
  }
  updateBasicWorkOrderType(formData): Observable<any> {
    const updateBasicWorkOrderType = URLS.updateBasicWorkOrderType ;
    return this.http.post(updateBasicWorkOrderType,formData);
  }
  deleteRepairCode(formData): Observable<any> {
    const deleteRepairCode = URLS.deleteRepairCode ;
    return this.http.post(deleteRepairCode,formData);
  }
  createRepairCode(formData): Observable<any> {
    const createRepairCode = URLS.createRepairCode ;
    return this.http.post(createRepairCode,formData);
  }
  createRepair(formData): Observable<any> {
    const createRepair = URLS.createRepair ;
    return this.http.post(createRepair,formData);
  }
  deleteRepair(formData): Observable<any> {
    const deleteRepair = URLS.deleteRepair ;
    return this.http.post(deleteRepair,formData);
  }
  deleteFSMDispatchCenterWorkZone(formData): Observable<any> {
    const deleteFSMDispatchCenterWorkZone = URLS.deleteFSMDispatchCenterWorkZone ;
    return this.http.post(deleteFSMDispatchCenterWorkZone,formData);
  }
  createFSMDispatchCenterWorkZone(formData): Observable<any> {
    const createFSMDispatchCenterWorkZone = URLS.createFSMDispatchCenterWorkZone ;
    return this.http.post(createFSMDispatchCenterWorkZone,formData);
  }
  updateFSMDispatchCenterWorkZone(formData): Observable<any> {
    const updateFSMDispatchCenterWorkZone = URLS.updateFSMDispatchCenterWorkZone ;
    return this.http.post(updateFSMDispatchCenterWorkZone,formData);
  }
  deleteDispatchBoardSettings(formData): Observable<any> {
    const deleteDispatchBoardSettings = URLS.deleteDispatchBoardSettings ;
    return this.http.post(deleteDispatchBoardSettings,formData);
  }
  createFsmWorkZoneService(formData): Observable<any> {
    const createFsmWorkZoneService = URLS.createFsmWorkZoneService ;
    return this.http.post(createFsmWorkZoneService,formData);
  }
  deleteFsmWorkZoneService(formData): Observable<any> {
    const deleteFsmWorkZoneService = URLS.deleteFsmWorkZoneService ;
    return this.http.post(deleteFsmWorkZoneService,formData);
  }
  updateFsmWorkZoneService(formData): Observable<any> {
    const updateFsmWorkZoneService = URLS.updateFsmWorkZoneService ;
    return this.http.post(updateFsmWorkZoneService,formData);
  }
  updateDispatchBoardSettings(formData): Observable<any> {
    const updateDispatchBoardSettings = URLS.updateDispatchBoardSettings ;
    return this.http.post(updateDispatchBoardSettings,formData);
  }
  createDispatchBoardSettings(formData): Observable<any> {
    const createDispatchBoardSettings = URLS.createDispatchBoardSettings ;
    return this.http.post(createDispatchBoardSettings,formData);
  }
  updateRepairCode(formData): Observable<any> {
    const updateRepairCode = URLS.updateRepairCode ;
    return this.http.post(updateRepairCode,formData);
  }
  deleteFsmWorkZone(formData): Observable<any> {
    const deleteFsmWorkZone = URLS.deleteFsmWorkZone ;
    return this.http.post(deleteFsmWorkZone,formData);
  }
  updateFsmWorkZone(formData): Observable<any> {
    const updateFsmWorkZone = URLS.updateFsmWorkZone ;
    return this.http.post(updateFsmWorkZone,formData);
  }
  createFsmWorkZone(formData): Observable<any> {
    const createFsmWorkZone = URLS.createFsmWorkZone ;
    return this.http.post(createFsmWorkZone,formData);
  }
  updateFsmEquipmentService(formData): Observable<any> {
    const updateFsmEquipmentService = URLS.updateFsmEquipmentService ;
    return this.http.post(updateFsmEquipmentService,formData);
  }
  deleteFsmEquipmentService(formData): Observable<any> {
    const deleteFsmEquipmentService = URLS.deleteFsmEquipmentService ;
    return this.http.post(deleteFsmEquipmentService,formData);
  }
  createFsmEquipmentService(formData): Observable<any> {
    const createFsmEquipmentService = URLS.createFsmEquipmentService ;
    return this.http.post(createFsmEquipmentService,formData);
  }
  createFsmEquipment(formData): Observable<any> {
    const createFsmEquipment = URLS.createFsmEquipment ;
    return this.http.post(createFsmEquipment,formData);
  }
  DeleteFsmEquipment(formData): Observable<any> {
    const DeleteFsmEquipment = URLS.DeleteFsmEquipment ;
    return this.http.post(DeleteFsmEquipment,formData);
  }
  updatePriceListGroup(formData): Observable<any> {
    const updatePriceListGroup = URLS.updatePriceListGroup ;
    return this.http.post(updatePriceListGroup,formData);
  }
  updateFsmInvoice(formData): Observable<any> {
    const updateFsmInvoice = URLS.updateFsmInvoice ;
    return this.http.post(updateFsmInvoice,formData);
  }
  createFsmInvoice(formData): Observable<any> {
    const createFsmInvoice = URLS.createFsmInvoice ;
    return this.http.post(createFsmInvoice,formData);
  }
  removeAddDataResourceRole(formData): Observable<any> {
    const removeAddDataResourceRole = URLS.removeAddDataResourceRole ;
    return this.http.post(removeAddDataResourceRole,formData);
  }
  addDataResourceRole(formData): Observable<any> {
    const addDataResourceRole = URLS.addDataResourceRole ;
    return this.http.post(addDataResourceRole,formData);
  }
  updateAddDataResourceRole(formData): Observable<any> {
    const updateAddDataResourceRole = URLS.updateAddDataResourceRole ;
    return this.http.post(updateAddDataResourceRole,formData);
  }
  updateElectronicText(formData): Observable<any> {
    const updateElectronicText = URLS.updateElectronicText ;
    return this.http.post(updateElectronicText,formData);
  }
  deletePriceListGroup(formData): Observable<any> {
    const deletePriceListGroup = URLS.deletePriceListGroup ;
    return this.http.post(deletePriceListGroup,formData);
  }
  createPriceListGroup(formData): Observable<any> {
    const createPriceListGroup = URLS.createPriceListGroup ;
    return this.http.post(createPriceListGroup,formData);
  }
  deleteFsmWorkZoneServiceMember(formData): Observable<any> {
    const deleteFsmWorkZoneServiceMember = URLS.deleteFsmWorkZoneServiceMember ;
    return this.http.post(deleteFsmWorkZoneServiceMember,formData);
  }
  createFsmWorkZoneServiceMember(formData): Observable<any> {
    const createFsmWorkZoneServiceMember = URLS.createFsmWorkZoneServiceMember ;
    return this.http.post(createFsmWorkZoneServiceMember,formData);
  }
  FindWebSitePathAlias(formData): Observable<any> {
    const FindWebSitePathAlias = URLS.FindWebSitePathAlias ;
    return this.http.post(FindWebSitePathAlias,formData);
  }
  deleteFsmEquipmentPart(formData): Observable<any> {
    const deleteFsmEquipmentPart = URLS.deleteFsmEquipmentPart ;
    return this.http.post(deleteFsmEquipmentPart,formData);
  }
  deleteFinanceSalesTaxItems(formData): Observable<any> {
    const deleteFinanceSalesTaxItems = URLS.deleteFinanceSalesTaxItems ;
    return this.http.post(deleteFinanceSalesTaxItems,formData);
  }
  updateFinanceSalesTaxItems(formData): Observable<any> {
    const updateFinanceSalesTaxItems = URLS.updateFinanceSalesTaxItems ;
    return this.http.post(updateFinanceSalesTaxItems,formData);
  }
  createFinanceSalesTaxItems(formData): Observable<any> {
    const createFinanceSalesTaxItems = URLS.createFinanceSalesTaxItems ;
    return this.http.post(createFinanceSalesTaxItems,formData);
  }
  updateFsmEquipmentAttribute(formData): Observable<any> {
    const updateFsmEquipmentAttribute = URLS.updateFsmEquipmentAttribute ;
    return this.http.post(updateFsmEquipmentAttribute,formData);
  }
  createFsmEquipmentAttribute(formData): Observable<any> {
    const createFsmEquipmentAttribute = URLS.createFsmEquipmentAttribute ;
    return this.http.post(createFsmEquipmentAttribute,formData);
  }
  deletePaymentProcessSetting(formData): Observable<any> {
    const deletePaymentProcessSetting = URLS.deletePaymentProcessSetting ;
    return this.http.post(deletePaymentProcessSetting,formData);
  }
  updatePaymentProcessSetting(formData): Observable<any> {
    const updatePaymentProcessSetting = URLS.updatePaymentProcessSetting ;
    return this.http.post(updatePaymentProcessSetting,formData);
  }
  createPaymentProcessSetting(formData): Observable<any> {
    const createPaymentProcessSetting = URLS.createPaymentProcessSetting ;
    return this.http.post(createPaymentProcessSetting,formData);
  }
  deleteFinancePriceListCategories(formData): Observable<any> {
    const deleteFinancePriceListCategories = URLS.deleteFinancePriceListCategories ;
    return this.http.post(deleteFinancePriceListCategories,formData);
  }
  createFsmMyWorkSettingsMS(formData): Observable<any> {
    const createFsmMyWorkSettingsMS = URLS.createFsmMyWorkSettingsMS ;
    return this.http.post(createFsmMyWorkSettingsMS,formData);
  }
  UpdateFinanceCustomInvoice(formData): Observable<any> {
    const UpdateFinanceCustomInvoice = URLS.UpdateFinanceCustomInvoice ;
    return this.http.post(UpdateFinanceCustomInvoice,formData);
  }
  updateFinanceBasicInvoice(formData): Observable<any> {
    const updateFinanceBasicInvoice = URLS.updateFinanceBasicInvoice ;
    return this.http.post(updateFinanceBasicInvoice,formData);
  }
  DeleteFinanceCustomInvoice(financeCustomInvoiceId): Observable<any> {
    const DeleteFinanceCustomInvoice = URLS.DeleteFinanceCustomInvoice
     + "?financeCustomInvoiceId=" + financeCustomInvoiceId;
    return this.http.post(DeleteFinanceCustomInvoice,"");
  }
  deleteFinanceBasicInvoice(financeBasicInvoiceId): Observable<any> {
    const deleteFinanceBasicInvoice = URLS.deleteFinanceBasicInvoice + "?financeBasicInvoiceId=" + financeBasicInvoiceId;
    return this.http.post(deleteFinanceBasicInvoice,"");
  }
  createFinanceCustomInvoice(formData): Observable<any> {
    const createFinanceCustomInvoice = URLS.createFinanceCustomInvoice ;
    return this.http.post(createFinanceCustomInvoice,formData);
  }
  createFinanceBasicInvoice(formData): Observable<any> {
    const createFinanceBasicInvoice = URLS.createFinanceBasicInvoice ;
    return this.http.post(createFinanceBasicInvoice,formData);
  }
  updateContentAssocPredicate(formData): Observable<any> {
    const updateContentAssocPredicate = URLS.updateContentAssocPredicate ;
    return this.http.post(updateContentAssocPredicate,formData);
  }
  addContentAssocPredicate(formData): Observable<any> {
    const addContentAssocPredicate = URLS.addContentAssocPredicate ;
    return this.http.post(addContentAssocPredicate,formData);
  }
  removeContentAssocPredicate(formData): Observable<any> {
    const removeContentAssocPredicate = URLS.removeContentAssocPredicate ;
    return this.http.post(removeContentAssocPredicate,formData);
  }
  ListComputerSoftware(formData): Observable<any> {
    const ListComputerSoftware = URLS.ListComputerSoftware ;
    return this.http.post(ListComputerSoftware,formData);
  }
  deleteFsmMyWorkSettingsMS(formData): Observable<any> {
    const deleteFsmMyWorkSettingsMS = URLS.deleteFsmMyWorkSettingsMS ;
    return this.http.post(deleteFsmMyWorkSettingsMS,formData);
  }
  updateFsmMyWorkSettingsMS(formData): Observable<any> {
    const updateFsmMyWorkSettingsMS = URLS.updateFsmMyWorkSettingsMS ;
    return this.http.post(updateFsmMyWorkSettingsMS,formData);
  }
  deleteFsmEquipmentAttribute(formData): Observable<any> {
    const deleteFsmEquipmentAttribute = URLS.deleteFsmEquipmentAttribute ;
    return this.http.post(deleteFsmEquipmentAttribute,formData);
  }
  updateFsmEquipmentTrack(formData): Observable<any> {
    const updateFsmEquipmentTrack = URLS.updateFsmEquipmentTrack ;
    return this.http.post(updateFsmEquipmentTrack,formData);
  }
  createFsmEquipmentTrack(formData): Observable<any> {
    const createFsmEquipmentTrack = URLS.createFsmEquipmentTrack ;
    return this.http.post(createFsmEquipmentTrack,formData);
  }
  deleteFsmEquipmentTrack(formData): Observable<any> {
    const deleteFsmEquipmentTrack = URLS.deleteFsmEquipmentTrack ;
    return this.http.post(deleteFsmEquipmentTrack,formData);
  }
  updateFsmEquipmentPart(formData): Observable<any> {
    const updateFsmEquipmentPart = URLS.updateFsmEquipmentPart ;
    return this.http.post(updateFsmEquipmentPart,formData);
  }
  createFsmEquipmentPart(formData): Observable<any> {
    const createFsmEquipmentPart = URLS.createFsmEquipmentPart ;
    return this.http.post(createFsmEquipmentPart,formData);
  }
  customerNotification(customerEmail,subject,text): Observable<any> {
    const customerNotification = URLS.customerNotification + "?customerEmail=" + customerEmail
    + "&subject=" + subject  + "&text=" + text ;
    return this.http.post(customerNotification,"");
  }
  updateFsmWorkZoneServiceMember(formData): Observable<any> {
    const updateFsmWorkZoneServiceMember = URLS.updateFsmWorkZoneServiceMember ;
    return this.http.post(updateFsmWorkZoneServiceMember,formData);
  }
 
  deleteFinancePriceListItem(formData): Observable<any> {
    const deleteFinancePriceListItem = URLS.deleteFinancePriceListItem ;
    return this.http.post(deleteFinancePriceListItem,formData);
  }
  createFinancePriceListItem(formData): Observable<any> {
    const createFinancePriceListItem = URLS.createFinancePriceListItem ;
    return this.http.post(createFinancePriceListItem,formData);
  }
  createFinancePriceListCategories(formData): Observable<any> {
    const createFinancePriceListCategories = URLS.createFinancePriceListCategories ;
    return this.http.post(createFinancePriceListCategories,formData);
  }
  updateContentPurposeOperation(formData): Observable<any> {
    const updateContentPurposeOperation = URLS.updateContentPurposeOperation ;
    return this.http.post(updateContentPurposeOperation,formData);
  }
  removeContentPurposeOperation(formData): Observable<any> {
    const removeContentPurposeOperation = URLS.removeContentPurposeOperation ;
    return this.http.post(removeContentPurposeOperation,formData);
  }
  updateFinancePriceListCategories(formData): Observable<any> {
    const updateFinancePriceListCategories = URLS.updateFinancePriceListCategories ;
    return this.http.post(updateFinancePriceListCategories,formData);
  }
  updateFinancePriceListItem(formData): Observable<any> {
    const updateFinancePriceListItem = URLS.updateFinancePriceListItem ;
    return this.http.post(updateFinancePriceListItem,formData);
  }
  addContentPurposeOperation(formData): Observable<any> {
    const addContentPurposeOperation = URLS.addContentPurposeOperation ;
    return this.http.post(addContentPurposeOperation,formData);
  }
  UpdateFsmEquipment(formData): Observable<any> {
    const UpdateFsmEquipment = URLS.UpdateFsmEquipment ;
    return this.http.post(UpdateFsmEquipment,formData);
  }

  findAdvanceSearch(formData): Observable<any> {
    const findAdvanceSearch = URLS.findAdvanceSearch ;
    return this.http.post(findAdvanceSearch,formData);
  }
  removeContentPurposeType(formData): Observable<any> {
    const removeContentPurposeType = URLS.removeContentPurposeType ;
    return this.http.post(removeContentPurposeType,formData);
  }
  getLocalString(): Observable<any> {
    const getLocalString = URLS.getLocalString ;
    return this.http.post(getLocalString,"");
  }
  updateContentPurposeType(formData): Observable<any> {
    const updateContentPurposeType = URLS.updateContentPurposeType ;
    return this.http.post(updateContentPurposeType,formData);
  }
  addContentTypeAttr(formData): Observable<any> {
    const addContentTypeAttr = URLS.addContentTypeAttr ;
    return this.http.post(addContentTypeAttr,formData);
  }
  addContentPurposeType(formData): Observable<any> {
    const addContentPurposeType = URLS.addContentPurposeType ;
    return this.http.post(addContentPurposeType,formData);
  }
  removeContentTypeAttr(formData): Observable<any> {
    const removeContentTypeAttr = URLS.removeContentTypeAttr ;
    return this.http.post(removeContentTypeAttr,formData);
  }
  updateRepair(formData): Observable<any> {
    const updateRepair = URLS.updateRepair ;
    return this.http.post(updateRepair,formData);
  }
  addToUploadImageCustom(formData,imageResize,productId):Observable<any>{
    const addToUploadImageCustom = URLS.addToUploadImageCustom + "?imageResize=" + imageResize
    + "&productId=" + productId  ;
    return this.http.post(addToUploadImageCustom,formData);
  }
  getFsmOnHoldResponseById(onHoldReasonsId): Observable<any> {
    const getFsmOnHoldResponseById = URLS.getFsmOnHoldResponseById +"?onHoldReasonsId=" + onHoldReasonsId  ;
    return this.http.get(getFsmOnHoldResponseById);
  }
  getContentPurposeType(): Observable<any> {
    const getContentPurposeType = URLS.getContentPurposeType   ;
    return this.http.get(getContentPurposeType);
  }
  getFinancePriceListCategories(): Observable<any> {
    const getFinancePriceListCategories = URLS.getFinancePriceListCategories   ;
    return this.http.get(getFinancePriceListCategories);
  }
  getRoleTypeIdContent(): Observable<any> {
    const getRoleTypeIdContent = URLS.getRoleTypeIdContent   ;
    return this.http.get(getRoleTypeIdContent);
  }
  getFsmOnHoldResponse(): Observable<any> {
    const getFsmOnHoldResponse = URLS.getFsmOnHoldResponse   ;
    return this.http.get(getFsmOnHoldResponse);
  }
  getFsmFlagResponseById(flagReasonsId): Observable<any> {
    const getFsmFlagResponseById = URLS.getFsmFlagResponseById +"?flagReasonsId=" + flagReasonsId ;
    return this.http.get(getFsmFlagResponseById);
  }
  getFsmFlagResponse(): Observable<any> {
    const getFsmFlagResponse = URLS.getFsmFlagResponse   ;
    return this.http.get(getFsmFlagResponse);
  }
  getBasicWorkOrderTypeById(basicWorkOrderTypeId): Observable<any> {
    const getBasicWorkOrderTypeById = URLS.getBasicWorkOrderTypeById +"?basicWorkOrderTypeId=" + basicWorkOrderTypeId ;
    return this.http.get(getBasicWorkOrderTypeById);
  }
  getBasicWorkOrderType(): Observable<any> {
    const getBasicWorkOrderType = URLS.getBasicWorkOrderType   ;
    return this.http.get(getBasicWorkOrderType);
  }
  getCustomWorkOrderTypeById(customWorkOrderTypeId): Observable<any> {
    const getCustomWorkOrderTypeById = URLS.getCustomWorkOrderTypeById +"?customWorkOrderTypeId=" + customWorkOrderTypeId ;
    return this.http.get(getCustomWorkOrderTypeById);
  }
  getCustomWorkOrderType(): Observable<any> {
    const getCustomWorkOrderType = URLS.getCustomWorkOrderType   ;
    return this.http.get(getCustomWorkOrderType);
  }
  getAutoCreateWebSiteContent(id,webSiteId): Observable<any> {
    const getAutoCreateWebSiteContent = URLS.getAutoCreateWebSiteContent +"?webSiteContentTypeId=" + id +"?webSiteId=" + webSiteId ;
    return this.http.get(getAutoCreateWebSiteContent);
  }
  getFsmRepairById(repairCategoriesId): Observable<any> {
    const getFsmRepairById = URLS.getFsmRepairById +"?repairCategoriesId=" + repairCategoriesId ;
    return this.http.get(getFsmRepairById);
  }
  getRepairCodeAll(): Observable<any> {
    const getRepairCodeAll = URLS.getRepairCodeAll   ;
    return this.http.get(getRepairCodeAll);
  }
  getRepairCodeById(repairCategoriesId): Observable<any> {
    const getRepairCodeById = URLS.getRepairCodeById + "?repairCategoriesId=" + repairCategoriesId ;
    return this.http.get(getRepairCodeById);
  }
  getFsmRepair(): Observable<any> {
    const getFsmRepair = URLS.getFsmRepair   ;
    return this.http.get(getFsmRepair);
  }
  getDispatchBoardSettingsById(dispatchBoardId): Observable<any> {
    const getDispatchBoardSettingsById = URLS.getDispatchBoardSettingsById + "?dispatchBoardId=" + dispatchBoardId ;
    return this.http.get(getDispatchBoardSettingsById);
  }
  getDispatchBoardSettingsByYes(): Observable<any> {
    const getDispatchBoardSettingsByYes = URLS.getDispatchBoardSettingsByYes  ;
    return this.http.get(getDispatchBoardSettingsByYes);
  }
  getDispatchBoardSettings(): Observable<any> {
    const getDispatchBoardSettings = URLS.getDispatchBoardSettings   ;
    return this.http.get(getDispatchBoardSettings);
  }
  getFsmWorkZoneServiceById(workZoneServiceId): Observable<any> {
    const getFsmWorkZoneServiceById = URLS.getFsmWorkZoneServiceById  + "?workZoneServiceId=" + workZoneServiceId ;
    return this.http.get(getFsmWorkZoneServiceById);
  }
  getFSMDispatchCenterWorkZone(): Observable<any> {
    const getFSMDispatchCenterWorkZone = URLS.getFSMDispatchCenterWorkZone   ;
    return this.http.get(getFSMDispatchCenterWorkZone);
  }
  getFSMDispatchCenterWorkZoneById(workZoneDispatchCenterId): Observable<any> {
    const getFSMDispatchCenterWorkZoneById = URLS.getFSMDispatchCenterWorkZoneById  + "?workZoneDispatchCenterId=" + workZoneDispatchCenterId ;
    return this.http.get(getFSMDispatchCenterWorkZoneById);
  }
  getFsmWorkZoneService(): Observable<any> {
    const getFsmWorkZoneService = URLS.getFsmWorkZoneService   ;
    return this.http.get(getFsmWorkZoneService);
  }
  getFsmWorkZoneById(workZoneId): Observable<any> {
    const getFsmWorkZoneById = URLS.getFsmWorkZoneById  + "?workZoneId=" + workZoneId ;
    return this.http.get(getFsmWorkZoneById);
  }
  getPartyRoles(id): Observable<any> {
    const getPartyRoles = URLS.getPartyRoles  + "?partyId=" + id ;
    return this.http.get(getPartyRoles);
  }
  getTimeZone(): Observable<any> {
    const getTimeZone = URLS.getTimeZone   ;
    return this.http.get(getTimeZone);
  }
  getWorkZoneId(): Observable<any> {
    const getWorkZoneId = URLS.getWorkZoneId   ;
    return this.http.get(getWorkZoneId);
  }
  DataResourceTypeAttr(): Observable<any> {
    const DataResourceTypeAttr = URLS.DataResourceTypeAttr   ;
    return this.http.get(DataResourceTypeAttr);
  }
  DataResourceTypeAttrById(id): Observable<any> {
    const DataResourceTypeAttrById = URLS.DataResourceTypeAttrById  + "?dataResourceTypeId=" + id ;
    return this.http.get(DataResourceTypeAttrById);
  }
  getFsmWorkZone(): Observable<any> {
    const getFsmWorkZone = URLS.getFsmWorkZone   ;
    return this.http.get(getFsmWorkZone);
  }
  getFsmEquipmentById(equipmentId): Observable<any> {
    const getFsmEquipmentById = URLS.getFsmEquipmentById  + "?equipmentId=" + equipmentId ;
    return this.http.get(getFsmEquipmentById);
  }
  getFsmEquipment(): Observable<any> {
    const getFsmEquipment = URLS.getFsmEquipment   ;
    return this.http.get(getFsmEquipment);
  }
  createPendingActivityTasks(req): Observable<any> {
    const createPendingActivityTaskPartner = URLS.createPendingActivityTasks;
    return this.http.post(createPendingActivityTaskPartner, req);

  }
  getFsmWorkZoneServiceMemberById(workZoneServiceMemberId): Observable<any> {
    const getFsmWorkZoneServiceMemberById = URLS.getFsmWorkZoneServiceMemberById  + "?workZoneServiceMemberId=" + workZoneServiceMemberId ;
    return this.http.get(getFsmWorkZoneServiceMemberById);
  }
  getFsmWorkZoneServiceMember(): Observable<any> {
    const getFsmWorkZoneServiceMember = URLS.getFsmWorkZoneServiceMember   ;
    return this.http.get(getFsmWorkZoneServiceMember);
  }
  getFinanceCustomInvoiceById(financeCustomInvoiceId): Observable<any> {
    const getFinanceCustomInvoiceById = URLS.getFinanceCustomInvoiceById  + "?financeCustomInvoiceId=" + financeCustomInvoiceId ;
    return this.http.get(getFinanceCustomInvoiceById);
  }
  getFinanceCustomInvoice(): Observable<any> {
    const getFinanceCustomInvoice = URLS.getFinanceCustomInvoice   ;
    return this.http.get(getFinanceCustomInvoice);
  }
  getElectronicTextById(id): Observable<any> {
    const getElectronicTextById = URLS.getElectronicTextById  + "?dataResourceId=" + id ;
    return this.http.get(getElectronicTextById);
  }
  getFinanceBasicInvoiceById(financeBasicInvoiceId): Observable<any> {
    const getFinanceBasicInvoiceById = URLS.getFinanceBasicInvoiceById  + "?financeBasicInvoiceId=" + financeBasicInvoiceId ;
    return this.http.get(getFinanceBasicInvoiceById);
  }
  getFinanceBasicInvoice(): Observable<any> {
    const getFinanceBasicInvoice = URLS.getFinanceBasicInvoice   ;
    return this.http.get(getFinanceBasicInvoice);
  }
  getAddContentOperationById(id): Observable<any> {
    const getAddContentOperationById = URLS.getAddContentOperationById  + "?contentOperationId=" + id ;
    return this.http.get(getAddContentOperationById);
  }
  getAddContentOperation(): Observable<any> {
    const getAddContentOperation = URLS.getAddContentOperation   ;
    return this.http.get(getAddContentOperation);
  }
  getContentAssocPredicate(): Observable<any> {
    const getContentAssocPredicate = URLS.getContentAssocPredicate   ;
    return this.http.get(getContentAssocPredicate);
  }
  getContentAssocPredicateById(id): Observable<any> {
    const getContentAssocPredicateById = URLS.getContentAssocPredicateById  + "?contentAssocPredicateId=" + id ;
    return this.http.get(getContentAssocPredicateById);
  }
  getContentOperation(): Observable<any> {
    const getContentOperation = URLS.getContentOperation   ;
    return this.http.get(getContentOperation);
  }
  getFsmEquipmentServiceById(equipmentId): Observable<any> {
    const getFsmEquipmentServiceById = URLS.getFsmEquipmentServiceById  + "?equipmentId=" + equipmentId ;
    return this.http.get(getFsmEquipmentServiceById);
  }
  getFsmEquipmentService(): Observable<any> {
    const getFsmEquipmentService = URLS.getFsmEquipmentService   ;
    return this.http.get(getFsmEquipmentService);
  }
  getFsmEquipmentTrackById(equipmentId): Observable<any> {
    const getFsmEquipmentTrackById = URLS.getFsmEquipmentTrackById  + "?equipmentId=" + equipmentId ;
    return this.http.get(getFsmEquipmentTrackById);
  }
  getFsmEquipmentPart(): Observable<any> {
    const getFsmEquipmentPart = URLS.getFsmEquipmentPart   ;
    return this.http.get(getFsmEquipmentPart);
  }
  getFsmEquipmentPartById(equipmentId): Observable<any> {
    const getFsmEquipmentPartById = URLS.getFsmEquipmentPartById  + "?equipmentId=" + equipmentId ;
    return this.http.get(getFsmEquipmentPartById);
  }
  getFsmEquipmentAttribute(): Observable<any> {
    const getFsmEquipmentAttribute = URLS.getFsmEquipmentAttribute   ;
    return this.http.get(getFsmEquipmentAttribute);
  }
  getFsmEquipmentAttributeById(equipmentAttributeId): Observable<any> {
    const getFsmEquipmentAttributeById = URLS.getFsmEquipmentAttributeById  + "?equipmentAttributeId=" + equipmentAttributeId ;
    return this.http.get(getFsmEquipmentAttributeById);
  }
  getDataTemplateType(): Observable<any> {
    const getDataTemplateType = URLS.getDataTemplateType   ;
    return this.http.get(getDataTemplateType);
  }
  getCharacterSetData(): Observable<any> {
    const getCharacterSetData = URLS.getCharacterSetData   ;
    return this.http.get(getCharacterSetData);
  }
  getFsmEquipmentTrack(): Observable<any> {
    const getFsmEquipmentTrack = URLS.getFsmEquipmentTrack   ;
    return this.http.get(getFsmEquipmentTrack);
  }
  getFsmEquipmentId(): Observable<any> {
    const getFsmEquipmentId = URLS.getFsmEquipmentId   ;
    return this.http.get(getFsmEquipmentId);
  }
  getSkill(): Observable<any> {
    const getSkill = URLS.getSkill   ;
    return this.http.get(getSkill);
  }
  getFsmMyWorkSettingsMSById(equipmentAttributeId): Observable<any> {
    const getFsmMyWorkSettingsMSById = URLS.getFsmMyWorkSettingsMSById  + "?equipmentAttributeId=" + equipmentAttributeId ;
    return this.http.get(getFsmMyWorkSettingsMSById);
  }
  getFinanceSalesTaxItems(): Observable<any> {
    const getFinanceSalesTaxItems = URLS.getFinanceSalesTaxItems   ;
    return this.http.get(getFinanceSalesTaxItems);
  }
  getFinanceSalesTaxItemsById(financeSalesTaxItemsId): Observable<any> {
    const getFinanceSalesTaxItemsById = URLS.getFinanceSalesTaxItemsById  
    + "?financeSalesTaxItemsId=" + financeSalesTaxItemsId ;
    return this.http.get(getFinanceSalesTaxItemsById);
  }
  DataResourceTypeById(id): Observable<any> {
    const DataResourceTypeById = URLS.DataResourceTypeById  
    + "?dataResourceTypeId=" + id ;
    return this.http.get(DataResourceTypeById);
  }
  DataResourceType(): Observable<any> {
    const DataResourceType = URLS.DataResourceType   ;
    return this.http.get(DataResourceType);
  }
  getFinancePriceListCategoriesById(priceListCategoryId): Observable<any> {
    const getFinancePriceListCategoriesById = URLS.getFinancePriceListCategoriesById  
    + "?priceListCategoryId=" + priceListCategoryId ;
    return this.http.get(getFinancePriceListCategoriesById);
  }
  getFsmMyWorkSettingsMS(): Observable<any> {
    const getFsmMyWorkSettingsMS = URLS.getFsmMyWorkSettingsMS   ;
    return this.http.get(getFsmMyWorkSettingsMS);
  }
  getFinancePriceListItemById(priceListCategoryId): Observable<any> {
    const getFinancePriceListItemById = URLS.getFinancePriceListItemById  
    + "?priceListCategoryId=" + priceListCategoryId ;
    return this.http.get(getFinancePriceListItemById);
  }
  getFinancePriceListItem(): Observable<any> {
    const getFinancePriceListItem = URLS.getFinancePriceListItem   ;
    return this.http.get(getFinancePriceListItem);
  }
  getPriceListGroupById(priceListGroupId): Observable<any> {
    const getPriceListGroupById = URLS.getPriceListGroupById  
    + "?priceListGroupId=" + priceListGroupId ;
    return this.http.get(getPriceListGroupById);
  }
  FileExtension(): Observable<any> {
    const FileExtension = URLS.FileExtension   ;
    return this.http.get(FileExtension);
  }
  getInvoiceById(invoiceId): Observable<any> {
    const getInvoiceById = URLS.getInvoiceById  
    + "?invoiceId=" + invoiceId ;
    return this.http.get(getInvoiceById);
  }
  getPriceListGroup(): Observable<any> {
    const getPriceListGroup = URLS.getPriceListGroup   ;
    return this.http.get(getPriceListGroup);
  }
  FileExtensionById(fileExtensionId): Observable<any> {
    const FileExtensionById = URLS.FileExtensionById  
    + "?fileExtensionId=" + fileExtensionId ;
    return this.http.get(FileExtensionById);
  }
  getContentTypeAttr(): Observable<any> {
    const getContentTypeAttr = URLS.getContentTypeAttr   ;
    return this.http.get(getContentTypeAttr);
  }
  getAddDataResourceAttributeById(id): Observable<any> {
    const getAddDataResourceAttributeById = URLS.getAddDataResourceAttributeById + "?dataResourceId=" + id ;
    return this.http.get(getAddDataResourceAttributeById);
  }
  MimeTypeSecond(): Observable<any> {
    const MimeTypeSecond = URLS.MimeTypeSecond   ;
    return this.http.get(MimeTypeSecond);
  }
  getPendingActivityEvent(partnerId): Observable<any> {
    const getPendingActivityEventPartner = URLS.getPendingActivityEvent +"?partnerId=" + partnerId  ;
    return this.http.get(getPendingActivityEventPartner);
  }
  MimeTypeById(id): Observable<any> {
    const MimeTypeById = URLS.MimeTypeById + "?mimeTypeId=" + id ;
    return this.http.get(MimeTypeById);
  }
  deletePendingActivityEvent(req): Observable<any> {
    const deletePendingActivityEventPartner = URLS.deletePendingActivityEvent;
    return this.http.post(deletePendingActivityEventPartner, req);

  }
  getAddDataResourceAttributeAll(): Observable<any> {
    const getAddDataResourceAttributeAll = URLS.getAddDataResourceAttributeAll   ;
    return this.http.get(getAddDataResourceAttributeAll);
  }
  getContentTypeAttrById(priceListCategoryId): Observable<any> {
    const getContentTypeAttrById = URLS.getContentTypeAttrById  
    + "?priceListCategoryId=" + priceListCategoryId ;
    return this.http.get(getContentTypeAttrById);
  }
  getExpertiseArea(): Observable<any> {
    const getExpertiseArea = URLS.getExpertiseArea   ;
    return this.http.get(getExpertiseArea);
  }
  getInvoice(): Observable<any> {
    const getInvoice = URLS.getInvoice   ;
    return this.http.get(getInvoice);
  }
  getPaymentProcessSettingById(priceListCategoryId): Observable<any> {
    const getPaymentProcessSettingById = URLS.getPaymentProcessSettingById  
    + "?priceListCategoryId=" + priceListCategoryId ;
    return this.http.get(getPaymentProcessSettingById);
  }
  getAddDataResourceRoleById(id): Observable<any> {
    const getAddDataResourceRoleById = URLS.getAddDataResourceRoleById + "?dataResourceId=" + id ;
    return this.http.get(getAddDataResourceRoleById);
  }
  getDataResourceById(id): Observable<any> {
    const getDataResourceById = URLS.getDataResourceById  
    + "?dataResourceId=" + id ;
    return this.http.get(getDataResourceById);
  }
  CharacterSetById(id): Observable<any> {
    const CharacterSetById = URLS.CharacterSetById + "?characterSetId=" + id ;
    return this.http.get(CharacterSetById);
  }
  CharacterSet(): Observable<any> {
    const CharacterSet = URLS.CharacterSet   ;
    return this.http.get(CharacterSet);
  }
  getAddDataResourceRoleAll(): Observable<any> {
    const getAddDataResourceRoleAll = URLS.getAddDataResourceRoleAll   ;
    return this.http.get(getAddDataResourceRoleAll);
  }
  getPendingActivityTask(partnerId): Observable<any> {
    const getPendingActivityTaskPartner = URLS.getPendingActivityTask +"?partnerId=" + partnerId  ;
    return this.http.get(getPendingActivityTaskPartner);
  }
  getContentPurposeTypes(): Observable<any> {
    const getContentPurposeTypes = URLS.getContentPurposeTypes   ;
    return this.http.get(getContentPurposeTypes);
  }
  FindWebSite(id): Observable<any> {
    const FindWebSite = URLS.FindWebSite + "?webSiteId=" + id ;
    return this.http.get(FindWebSite);
  }
  getPrivilegeEnumID(): Observable<any> {
    const getPrivilegeEnumID = URLS.getPrivilegeEnumID   ;
    return this.http.get(getPrivilegeEnumID);
  }
  getPrivilegeEnumIDStatusId(): Observable<any> {
    const getPrivilegeEnumIDStatusId = URLS.getPrivilegeEnumIDStatusId   ;
    return this.http.get(getPrivilegeEnumIDStatusId);
  }
  getContentId(): Observable<any> {
    const getContentId = URLS.getContentIdContent   ;
    return this.http.get(getContentId);
  }
  getContactWebsite(): Observable<any> {
    const getContactWebsite = URLS.getContactWebsite   ;
    return this.http.get(getContactWebsite);
  }
  WebSiteRole(id): Observable<any> {
    const WebSiteRole = URLS.WebSiteRole + "?webSiteId=" + id ;
    return this.http.get(WebSiteRole);
  }
  WebSiteRoleAll(): Observable<any> {
    const WebSiteRoleAll = URLS.WebSiteRoleAll   ;
    return this.http.get(WebSiteRoleAll);
  }
  getWebSiteContactList(id): Observable<any> {
    const getWebSiteContactList = URLS.getWebSiteContactList + "?webSiteId=" + id ;
    return this.http.get(getWebSiteContactList);
  }
  FindWebSiteAll(): Observable<any> {
    const FindWebSiteAll = URLS.FindWebSiteAll   ;
    return this.http.get(FindWebSiteAll);
  }
  getFindWebSitePathAlias(): Observable<any> {
    const FindWebSitePathAlias = URLS.FindWebSitePathAlias ;
    return this.http.get(FindWebSitePathAlias);
  }

  WebSitePathAliasAllBYId(id): Observable<any> {
    const WebSitePathAliasAllBYId = URLS.WebSitePathAliasAllBYId + "?webSiteId=" + id ;
    return this.http.get(WebSitePathAliasAllBYId);
  }
  ListWebSiteContent(id): Observable<any> {
    const ListWebSiteContent = URLS.ListWebSiteContent + "?webSiteId=" + id ;
    return this.http.get(ListWebSiteContent);
  }
  DataCategoryById(id): Observable<any> {
    const DataCategoryById = URLS.DataCategoryById + "?dataCategoryId=" + id ;
    return this.http.get(DataCategoryById);
  }
  UpdatePendingActivityTask(req): Observable<any> {
    const UpdatePendingActivityTaskPartner = URLS.UpdatePendingActivityTask;
    return this.http.post(UpdatePendingActivityTaskPartner, req);

  }
  getListWebSiteById(id): Observable<any> {
    const getListWebSiteById = URLS.getListWebSiteId + "?contentId=" + id ;
    return this.http.get(getListWebSiteById);
  }
  WebSiteCMS(id): Observable<any> {
    const WebSiteCMS = URLS.WebSiteCMS + "?webSiteId=" + id ;
    return this.http.get(WebSiteCMS);
  }
  getContentPurposeTypeById(id): Observable<any> {
    const getContentPurposeTypeById = URLS.getContentPurposeTypeById + "?contentId=" + id ;
    return this.http.get(getContentPurposeTypeById);
  }
  getPaymentProcessSetting(): Observable<any> {
    const getPaymentProcessSetting = URLS.getPaymentProcessSetting   ;
    return this.http.get(getPaymentProcessSetting);
  }
  WebSitePathAliasAll(): Observable<any> {
    const WebSitePathAliasAll = URLS.WebSitePathAliasAll   ;
    return this.http.get(WebSitePathAliasAll);
  }
  dataResourceTypeId(): Observable<any> {
    const dataResourceTypeId = URLS.dataResourceTypeId   ;
    return this.http.get(dataResourceTypeId);
  }
  getContentOperations(): Observable<any> {
    const getContentOperations = URLS.getContentOperations   ;
    return this.http.get(getContentOperations);
  }
  getContentPurposeOperation(): Observable<any> {
    const getContentPurposeOperation = URLS.getContentPurposeOperation   ;
    return this.http.get(getContentPurposeOperation);
  }

  WebSiteContentType(): Observable<any> {
    const WebSiteContentType = URLS.WebSiteContentType   ;
    return this.http.get(WebSiteContentType);
  }
  ListWebSiteContentAll(): Observable<any> {
    const ListWebSiteContentAll = URLS.ListWebSiteContentAll   ;
    return this.http.get(ListWebSiteContentAll);
  }
  DataCategory(): Observable<any> {
    const DataCategory = URLS.DataCategory   ;
    return this.http.get(DataCategory);
  }
  parentCategoryId(): Observable<any> {
    const parentCategoryId = URLS.parentCategoryId   ;
    return this.http.get(parentCategoryId);
  }

  chartViewHandler(): Observable<any> {
    const chartViewHandler = URLS.chartViewHandler ;
    return this.http.get(chartViewHandler, { responseType: "blob" });
  }

  chartPie(): Observable<any> {
    const chartPie = URLS.chartPie ;
    return this.http.get(chartPie, { responseType: "blob" });
  }
  EditPartyQual(id): Observable<any> {
    const EditPartyQual = URLS.EditPartyQual + "?partyId=" + id ;
    return this.http.get(EditPartyQual);
  }
  EditEmploymentApp(id): Observable<any> {
    const EditEmploymentApp = URLS.EditEmploymentApp + "?applyingPartyId=" + id ;
    return this.http.get(EditEmploymentApp);
  }
  EditEmployeeLeaves(id): Observable<any> {
    const EditEmployeeLeaves = URLS.EditEmployeeLeaves + "?partyId=" + id ;
    return this.http.get(EditEmployeeLeaves);
  }
  editTimeSheet(id): Observable<any> {
    const editTimeSheet = URLS.editTimeSheet + "?partyId=" + id ;
    return this.http.get(editTimeSheet);
  }
  EditEmployeePosition(id): Observable<any> {
    const EditEmployeePosition = URLS.EditEmployeePosition + "?partyId=" + id ;
    return this.http.get(EditEmployeePosition);
  }
  EditEmployeeSkills(id): Observable<any> {
    const EditEmployeeSkills = URLS.EditEmployeeSkills + "?partyId=" + id ;
    return this.http.get(EditEmployeeSkills);
  }
  EditPerfReview(id): Observable<any> {
    const EditPerfReview = URLS.EditPerfReview + "?managerPartyId=" + id ;
    return this.http.get(EditPerfReview);
  }
  editPartyGroup(id): Observable<any> {
    const editPartyGroup = URLS.editPartyGroup + "?partyId=" + id ;
    return this.http.get(editPartyGroup);
  }
  getDepartmentEmployee(id): Observable<any> {
    const getDepartmentEmployee = URLS.getDepartmentEmployee + "?partyId=" + id ;
    return this.http.get(getDepartmentEmployee);
  }
  EditListRelatedAccounts(id): Observable<any> {
    const EditListRelatedAccounts = URLS.EditListRelatedAccounts + "?partyIdFrom=" + id ;
    return this.http.get(EditListRelatedAccounts);
  }
  EditDepartment(id): Observable<any> {
    const EditDepartment = URLS.EditDepartment + "?partyId=" + id ;
    return this.http.get(EditDepartment);
  }
  generatePDFContent(id): Observable<any> {
    const generatePDFContent = URLS.generatePDFContent + "?contentId=" + id;
    return this.http.get(generatePDFContent, { responseType: "blob" });
  }
  generatePDFWorkeffort(id): Observable<any> {
    const generatePDFWorkeffort = URLS.generatePDFWorkeffort + "?workEffortId=" + id;
    return this.http.get(generatePDFWorkeffort, { responseType: "blob" });
  }
  generatePDFWebsite(id): Observable<any> {
    const generatePDFWebsite = URLS.generatePDFWebsite + "?webSiteId=" + id;
    return this.http.get(generatePDFWebsite, { responseType: "blob" });
  }
  generatePDFBlog(id): Observable<any> {
    const generatePDFBlog = URLS.generatePDFBlog + "?contentId=" + id;
    return this.http.get(generatePDFBlog, { responseType: "blob" });
  }
  generatePDFCMS(editForm): Observable<any> {
    const generatePDFCMS = URLS.generatePDFCMS ;
    return this.http.post(generatePDFCMS, { responseType: "blob" },editForm);
  }
  generatePDFSurvey(id): Observable<any> {
    const generatePDFSurvey = URLS.generatePDFSurvey + "?surveyId=" + id;
    return this.http.get(generatePDFSurvey, { responseType: "blob" });
  }
  generatePDFDataResource(id): Observable<any> {
    const generatePDFDataResource = URLS.generatePDFDataResource + "?dataResourceId=" + id;
    return this.http.get(generatePDFDataResource, { responseType: "blob" });
  }
  generatePDFTemplate(id): Observable<any> {
    const generatePDFTemplate = URLS.generatePDFTemplate + "?contentId=" + id;
    return this.http.get(generatePDFTemplate, { responseType: "blob" });
  }
  getcontacts(): Observable<any> {
    const getcontacts = URLS.getcontacts;
    return this.http.get(getcontacts);
  }
}


