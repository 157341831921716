import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { AccountingApService } from '../accounting-ap/accounting-ap.service';
@Component({
  selector: 'app-create-tax-product-rates',
  templateUrl: './create-tax-product-rates.component.html',
  styleUrls: ['./create-tax-product-rates.component.css']
})
export class CreateTaxProductRatesComponent implements OnInit {
  createProduct:FormGroup;
  activeCategory=2;
  party: any;
  geo: any;
  promotion: { label: string; value: string; }[];
  rateType: any;
  rateTypeArray: any[]=[];
  total = 0;
  rowNumber = 0;
  pageSize = 100;
  pageNo = 1;
  rows = 50;
  search= ""
  readonly finSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  categoryList: any;
  categoryListArray: any[]=[];
  store: any;
  storeArray: any[]=[];
  enumDataArrayList: any[]=[];
  enumId: string;
  actualfromdate: string;
  actualthroughdate: string;
  authProductSeqid: any;
  show: boolean;
  getEstimateFrom: string;
  getEstimateTo: string;
  constructor( readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
  
    readonly accountsService: AccountsService,
    readonly datePipe: DatePipe,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _ToastrService: ToastrService,) { 
      this.createProduct = this._FormBuilder.group({
        Type: [''],
        StoreID: [''],
        Category: [''],
        TitleTransfer: [''],
        MinItemPrice: [''],
        MinPurchase: [''],
        TaxShipping: [''],
        TaxPercentage: [''],
        TaxPromotions: [''],
        fromDate: [''],
        throughDate: [''],
        Description: [''],
        IsTaxShippingPrice: [''],
      
       
        
      });
      this.promotion=[{
        "label":"Y",
        "value":"Y"
      },
      {
        "label":"N",
        "value":"N"
      }]
    
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.party = params["party"];
      this.geo = params["geo"];
      this.authProductSeqid=params["authProductSeqid"]
   
    });
    this.getRateTypes();
    this.taxCategoryList();
    this.getStoreId();
    this.getEenumByTypeData();
    if(this.authProductSeqid){
this.show=true;
this.getproductRatesDetail();
    }else{
      this.show=false;
    }
  }
  
  getproductRatesDetail() {
    this.spinner.show();
    this.accountsService.getproductRatesDetail(this.authProductSeqid).subscribe(res => {
      this.spinner.hide();
      setTimeout(() => {
        const fromdate=res.data.fromDate
        this.getEstimateFrom=this.datePipe.transform(fromdate,"yyyy-MM-dd");
        const toEstdate=res.data.thruDate
        this.getEstimateTo=this.datePipe.transform(toEstdate,"yyyy-MM-dd");
        const formValue = this.createProduct;
        formValue.patchValue({
          Type:res.data.taxAuthorityRateTypeId,
          StoreID: res.data.productStoreId,
          Category: res.data.productCategoryId,
          TitleTransfer: res.data.titleTransferEnumId,
          MinItemPrice: res.data.minItemPrice,
          MinPurchase: res.data.minPurchase,
          TaxShipping: res.data.taxShipping,
          TaxPercentage: res.data.taxPercentage,
          TaxPromotions:res.data.taxPromotions,
          fromDate:  this.getEstimateFrom,
          throughDate:  this.getEstimateTo,
          Description: res.data.description,
          IsTaxShippingPrice: res.data.isTaxInShippingPrice,
        })
      }, 3000);
    })
    
  }
  getEenumByTypeData(): void {
    this.spinner.show();
    this.enumId="PTSOFTTFR"
    this.accountsService.getEenumByType(this.enumId).subscribe((res) => {
      const enumDa = res.data;
      this.spinner.hide();
      for (const value of enumDa) {
        this.enumDataArrayList.push({
          label: value.description,
          value: value.enumId,
        });
      }
    });
   
  }
  taxCategoryList() {
    this.spinner.show();
    const req={
      "productCategoryId": "",
      "taxAuthGeoId":this.geo,
      "taxAuthPartyId":this.party
    }
    this.accountsService.taxCategoryList(this.finSize,req).subscribe((res: any) => {
      this.categoryList = res.data;
      this.spinner.hide();
      for (const value of this.categoryList) {
        this.categoryListArray.push({
          label: value.productCategoryId,
          value: value.productCategoryId
        })
      }
    })
  
  }
  
  getStoreId() {
    this.spinner.show();
    this.accountsService.getStoreId().subscribe((res: any) => {

      this.store = res.data.productStores;
      this.spinner.hide();
      for (const value of this.store) {
        this.storeArray.push({
          label: value.storeName,
          value: value.productStoreId
        })
      }

    })
   
  }
  getRateTypes() {
    this.spinner.show();
    this.accountsService.getRateTypes().subscribe((res: any) => {

      this.rateType = res.data;
      this.spinner.hide();
      for (const value of this.rateType) {
        this.rateTypeArray.push({
          label: value.description,
          value: value.taxAuthorityRateTypeId
        })
      }

    })
   
  }
  cancelSubmit(){
    this._Router.navigate(['/financial/detail-tax-authority'],{ queryParams: { geo : this.geo,party : this.party}});

  }
  onSubmit() {
    this.spinner.show();
    const fromActualdate=this.createProduct.get('fromDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const throughActualdate=this.createProduct.get('throughDate').value;
    this.actualthroughdate=this.datePipe.transform(throughActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "description": this.createProduct.value.Description,
      "fromDate":  this.actualfromdate,
      "isTaxInShippingPrice": this.createProduct.value.IsTaxShippingPrice,
      "minItemPrice": this.createProduct.value.MinItemPrice,
      "minPurchase": this.createProduct.value.MinPurchase,
      "productCategoryId":this.createProduct.value.Category,
      "productStoreId":this.createProduct.value.StoreID,
      "taxAuthGeoId": this.geo,
      "taxAuthPartyId":this.party,
      "taxAuthorityRateSeqId":"",
      "taxAuthorityRateTypeId": this.createProduct.value.Type,
      "taxPercentage":this.createProduct.value.TaxPercentage,
      "taxPromotions": this.createProduct.value.TaxPromotions,
      "taxShipping": this.createProduct.value.TaxShipping,
      "thruDate":  this.actualthroughdate,
      "titleTransferEnumId":this.createProduct.value.TitleTransfer,
   
    }
    this.accountsService.postproductRates(requestData).subscribe((res: any) => { 
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this._Router.navigate(['/financial/detail-tax-authority'],{ queryParams: { geo : this.geo,party : this.party}});
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  onUpdate() {
    this.spinner.show();
    const fromActualdate=this.createProduct.get('fromDate').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const throughActualdate=this.createProduct.get('throughDate').value;
    this.actualthroughdate=this.datePipe.transform(throughActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "description": this.createProduct.value.Description,
      "fromDate":  this.actualfromdate,
      "isTaxInShippingPrice": this.createProduct.value.IsTaxShippingPrice,
      "minItemPrice": this.createProduct.value.MinItemPrice,
      "minPurchase": this.createProduct.value.MinPurchase,
      "productCategoryId":this.createProduct.value.Category,
      "productStoreId":this.createProduct.value.StoreID,
      "taxAuthGeoId": this.geo,
      "taxAuthPartyId":this.party,
      "taxAuthorityRateSeqId":this.authProductSeqid,
      "taxAuthorityRateTypeId": this.createProduct.value.Type,
      "taxPercentage":this.createProduct.value.TaxPercentage,
      "taxPromotions": this.createProduct.value.TaxPromotions,
      "taxShipping": this.createProduct.value.TaxShipping,
      "thruDate":  this.actualthroughdate,
      "titleTransferEnumId":this.createProduct.value.TitleTransfer,
    }
    this.accountsService.updateproductRates(requestData).subscribe((res: any) => { 
      if (res.success) {
        this._ToastrService.success("Updated Successfully");
        this.spinner.hide();
        this._Router.navigate(['/financial/detail-tax-authority'],{ queryParams: { geo : this.geo,party : this.party}});
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
