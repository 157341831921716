<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
                <span class="color-gry cursor-pointer" routerLink="/financial/{{agreementType}}/customer-agreeement">Agreements
                </span>
                <span class="color-gry cursor-pointer"
                    routerLink="/financial/{{agreementType}}/customer-agreeement/summary-customer-agreeement"
                    [queryParams]="{agreementID:agreement.agreementId}">>Agreements Summary </span>
                <span class="color-gry cursor-pointer"
                    routerLink="/financial/{{agreementType}}/customer-agreeement/agreement-summary-item"
                    [queryParams]="{agreementId :agreement.agreementId,agreementItemSeqId:agreement.agreementItemSeqId }">>Item
                    Summary </span>
                <span class="color-black pl-1"> > Item Term</span>
            </div>
            <div class=" bg-white color-grey">
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="port-header-contact create-lead font-medium mb-0 text-white"
                                    data-toggle="collapse" href="#collapsetwo">
                                    <div class="w-100">
                                        <div class="panel-group" id="accordion" role="tablist"
                                            aria-multiselectable="true">
                                            <div class="panel panel-default">
                                                <div class="w3-card-4" style="border:1px solid;
                border-color:#0d3769 !important;/* box-shadow: 0 4px 8px 0 rgb(0 0 0 / 20%), 0 6px 20px 0 rgb(0 0 0 / 19%); */">
                                                <div class="container-fluid">
                                                    <ul class="tabbing-section tabbing-accordians" style="margin-top: 0px!important;background: #cce0e95e!important;">
                                          
                                                      <li [ngClass]="activeCategory==1?'active':''" ><a>
                                                        Item Term</a></li>
                                                
                                                    </ul>
                                                  </div>
                                               
                                                <div >
                                                    <br>
                                                    <div class="panel-body">
                                                        <form [formGroup]="itemTermForm">
                                                            <div class="row">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                    
                                                                        <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                        margin-left: -12%;
                                                                        font-size: 10px;">
                                                                            <label for="agreementInvoiceType">Term Type
                                                                                Id</label>
                                                                        </div>
                                                                     
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown filter="true"
                                                                                [options]="itemTypeIds"
                                                                                optionlabel="label"
                                                                                formControlName="termTypeId"
                                                                                placeholder="Select Term Type ">
                                                                            </p-dropdown>
                                                                        </div>
                                    
                                                                        <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                      
                                                                        font-size: 10px;">
                                                                            <label for="agreementInvoiceType">Invoice
                                                                                Item Type</label>
                                                                        </div>
                                                                       
                                                                        <div class="col-lg-2" >
                                                                            <p-dropdown filter="true"
                                                                            [options]="invoiceItemTypeIds"
                                                                            optionlabel="label"
                                                                            formControlName="invoiceItemTypeId"
                                                                            placeholder="Select Invoice ">
                                                                        </p-dropdown>
                                                                        </div>
                                    
                                    
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                    
                                                                        <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                        margin-left: -12%;
                                                                        font-size: 10px;">
                                                                            <label for="currency">From Date</label>
                                                                        </div>
                                                                     
                                                                        <div class="col-lg-2">
                                                                            <p-calendar formControlName="fromDate"
                                                                                placeholder="Select Date">
                                                                            </p-calendar>
                                                                        </div>
                                    
                                                                        <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                     
                                                                        font-size: 10px;">
                                                                            <label for="agreementText">Thru Date</label>
                                                                        </div>
                                                                       
                                                                        <div class="col-lg-2" >
                                                                            <p-calendar formControlName="thruDate"
                                                                                placeholder="Select Date">
                                                                            </p-calendar>
                                                                        </div>
                                    
                                    
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                    
                                                                        <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                        margin-left: -12%;
                                                                        font-size: 10px;">
                                                                            <label for="agreementImage">Term Value</label>
                                                                        </div>
                                                                     
                                                                        <div class="col-lg-2">
                                                                            <input type="text" class="form-control"
                                                                            formControlName="termValue"
                                                                            placeholder="Term Value">
                                                                        </div>
                                    
                                                                        <div class="col-lg-3 form-group classInput " style="    text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                       
                                                                        font-size: 10px;">
                                                                            <label for="agreementImage">Term Days</label>
                                                                        </div>
                                                                       
                                                                        <div class="col-lg-2" >
                                                                            <input type="text" class="form-control"
                                                                            placeholder="Term Days"
                                                                            formControlName="termDays">
                                                                        </div>
                                    
                                    
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                    
                                                                        <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                        margin-left: -12%;
                                                                        font-size: 10px;">
                                                                            <label for="agreementImage">Text Value</label>
                                                                        </div>
                                                                     
                                                                        <div class="col-lg-2">
                                                                            <input type="text" class="form-control"
                                                                            placeholder="Text Value"
                                                                            formControlName="textValue">
                                                                        </div>
                                    
                                                                        <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                     
                                                                        font-size: 10px;">
                                                                            <label for="agreementImage">Min Quantity</label>
                                                                        </div>
                                                                       
                                                                        <div class="col-lg-2" >
                                                                            <input type="text" class="form-control"
                                                                            placeholder="Min Quantity"
                                                                            formControlName="minQuantity">
                                                                        </div>
                                    
                                    
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                                    
                                                                        <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                        margin-left: -12%;
                                                                        font-size: 10px;">
                                                                            <label for="agreementImage">Max Quantity</label>
                                                                        </div>
                                                                     
                                                                        <div class="col-lg-2">
                                                                            <input type="text" class="form-control"
                                                                            placeholder="Max Quantity"
                                                                            formControlName="maxQuantity">
                                                                        </div>
                                    
                                                                        <div class="col-lg-3 form-group classInput" style="    text-align: right!important;
                                                                        padding-right: 17px!important;
                                                                      
                                                                        font-size: 10px;">
                                                                            <label for="agreementImage">Description</label>
                                                                        </div>
                                                                       
                                                                        <div class="col-lg-2" >
                                                                            <input type="text" class="form-control"
                                                                            placeholder="Description"
                                                                            formControlName="description">
                                                                        </div>
                                    
                                    
                                                                    </div>
                                                                </div>
                                                           
                                                            </div>
                                                            <div class="main-submit-button" style="    margin-right: 30%;">
                                                                <button type="submit" (click)="submit()"
                                                                    class="btn btn-secondary submit-btn">{{editMode ? 'Update' :'Create'}}</button>
                                                                <button class="btn btn-danger cursor-pointer ml-2"
                                                                    (click)="reset()">Cancel</button>
                                                            </div>
                                                        </form>
                                                    </div>
                                                </div>
                                                </div>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                            </form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 