<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels"></span>
               <span class="d-flex">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>
        
                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
        

            </div>

            <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">


                        <li [ngClass]="activeCategory==1?'active':''" (click)="changeactiveCategory(1)"><a>
                                Overview </a></li>
                        <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategory(2)" *ngIf="showStatus"><a>
                                Update
                            </a></li>
                        <li [ngClass]="activeCategory==3?'active':''" (click)="changeactiveCategory(3)" *ngIf="showStatus"><a>
                                Content</a></li>
                        <li hidden [ngClass]="activeCategory==4?'active':''" (click)="changeactiveCategory(4)"><a>
                                New Project</a></li>

                    </ul>
                </div>
                <div class=" bg-white color-grey">
                    <div class="create-new-leade ">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100">
                                    <div class="port-header-contact create-lead font-medium mb-0 pt-0 mt-0 text-white">
                                        <div class="w-100">
                                            <div class="panel-group" id="accordion" role="tablist"
                                                aria-multiselectable="true">

                                                <div *ngIf="activeCategory==1">
                                                    <div class="container-fluid">
                                                        <ul class=" tabbing-accordians " style="width: 103% !important;
                                        flex-wrap: wrap !important;
                                        display: flex !important;
                                        padding: 10px !important;">
                                                            <li>
                                                                <button type="submit"
                                                                    class="btn btn-secondary submit-btn "
                                                                    (click)="changeactiveCategory(4)" *ngIf="showStatus">
                                                                    New
                                                                    Sprint
                                                                </button>

                                                                <button type="submit"
                                                                    class="btn btn-secondary submit-btn ml-2"
                                                                    (click)="updateSprintClose()" *ngIf="showStatus"> Close
                                                                    Product
                                                                </button>

                                                                <button type="submit"
                                                                class="btn btn-secondary submit-btn ml-2"
                                                                (click)="updateSprintActive()" *ngIf="!showStatus"> re-open
                                                                Product
                                                            </button>
                                                            </li>
                                                        </ul>
                                                    </div>

                                                    <div
                                                       style="color: #0d3769;font-weight: bold;font-size: 15px;">
                                                        Current Project :
                                                        <span>
                                                            {{this.internalName + "[" + this.productId + "]"}}
                                                        </span>
 
                                                    </div>

                                                    <div class="divA">

                                                        <br>
                                                        <div class="w3-card-4 classCardView">
                                                            <div class="header-tabs"
                                                                style="background:#F5F7FA!important;">
                                                                <h4 class=" common-styling hleft">
                                                                    Project : implement
                                                                </h4>
                                                            </div>

                                                            <div class="panel-body">
                                                                <div
                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                    <div class="color-black container">
                                                                        <div class="row">
                                                                            <div class="col-12">
                                                                                <div class="row">
                                                                                    <div class="col-lg-6 classInputAB">
                                                                                        Product Name

                                                                                    </div>
                                                                                    <div (click)="backToProductBacklog(this.productId)"
                                                                                        class="color-grey col-lg-6  font-weight-bold">
                                                                                        {{this.internalName + "[" + this.productId + "]"}}
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row">
                                                                                    <div class="col-lg-6 classInputAB">
                                                                                        Project Name

                                                                                    </div>
                                                                                    <div
                                                                                        class="color-grey  col-lg-6 ">
                                                                                        {{this.ProjectName}}
                                                                                    </div>


                                                                                </div>

                                                                                <div class="row ">
                                                                                    <div class="col-lg-6 classInputAB">
                                                                                        Product Owner

                                                                                    </div>
                                                                                    <div
                                                                                        class="color-grey    col-lg-6">
                                                                                        {{this.ProjectSprintOverview.billToParty}}
                                                                                    </div>
                                                                                </div>

                                                                                <div class="row ">
                                                                                    <div class="col-lg-6 classInputAB">
                                                                                        Project Goal

                                                                                    </div>
                                                                                    <div
                                                                                        class="color-grey  col-lg-6 ">
                                                                                        {{this.ProjectGoal}}
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row ">
                                                                                    <div class="col-lg-6 classInputAB">
                                                                                        Billed

                                                                                    </div>

                                                                                    <div
                                                                                        class="color-grey col-lg-6 ">
                                                                                        {{this.Billed}}
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row ">
                                                                                    <div class="col-lg-6 classInputAB">
                                                                                        Status

                                                                                    </div>

                                                                                    <div
                                                                                        class="color-grey  col-lg-6">
                                                                                        {{this.ProjectSprintOverview.productOverviewDetails[0].currentStatusId}}
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row ">
                                                                                    <div class="col-lg-6 classInputAB">
                                                                                        Actual Start Date

                                                                                    </div>
                                                                                    <div
                                                                                        class="color-grey   col-lg-6">
                                                                                        {{this.ProjectSprintOverview.productOverviewDetails[0].actualStartDate}}
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row ">
                                                                                    <div class="col-lg-6 classInputAB">
                                                                                        Actual Completion Date

                                                                                    </div>
                                                                                    <div
                                                                                        class="color-grey   col-lg-6">
                                                                                        {{this.ProjectSprintOverview.productOverviewDetails[0].actualCompletionDate}}
                                                                                    </div>
                                                                                </div>
                                                                                <div class="row ">
                                                                                    <div class="col-lg-6 classInputAB">
                                                                                        Actual Hours

                                                                                    </div>
                                                                                    <div
                                                                                        class="color-grey    col-lg-6">
                                                                                        {{this.ProjectSprintOverview.ActualHours}}
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                        <br>

                                                        <div class="w3-card-4 classCard ">
                                                            <header class="w3-container w3-blue">
                                                                <div class="header-tabs"
                                                                    style="background:#F5F7FA!important;">
                                                                    <h4 class=" common-styling h4Margin">
                                                                        Sprints List
                                                                    </h4>

                                                                </div>
                                                            </header>
                                                            <div
                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12 col-12">
                                                                    <div class="form-group">

                                                                        <div class="card own-account-table">
                                                                            <p-table [value]="SprintLists"
                                                                            [paginator]="false"
                                                                            [rows]="3"
                                                                            scrollHeight="100px"
                                                                                scrollWidth="150%"
                                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                [totalRecords]="total"
                                                                                [scrollable]="true"
                                                                                (sortFunction)="customSort($event)"
                                                                                [customSort]="true">
                                                                                <ng-template pTemplate="header">
                                                                                    <tr style="background: #0d3769;">
                                                                                        <th [ngStyle]="{'width':'250px'}"
                                                                                            pSortableColumn="code">
                                                                                            <div class="checkbox-align"
                                                                                                style="color: white;">
                                                                                                <p-checkbox
                                                                                                    _ngcontent-egj-c329=""
                                                                                                    styleclass="custom-checkbox"
                                                                                                    binary="true"
                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div
                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div
                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                            <input
                                                                                                                type="checkbox"
                                                                                                                name="undefined"
                                                                                                                value="undefined">
                                                                                                        </div>
                                                                                                        <div role="checkbox"
                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span
                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <!---->
                                                                                                </p-checkbox>
                                                                                                Sprint Name

                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>

                                                                                        <th [ngStyle]="{'width':'150px'}"
                                                                                            pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Current Status
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>
                                                                                        <th [ngStyle]="{'width':'150px'}"
                                                                                            pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Start
                                                                                                Date
                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>

                                                                                        <th [ngStyle]="{'width':'150px'}"
                                                                                            pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                End Date

                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>

                                                                                        <th [ngStyle]="{'width':'150px'}"
                                                                                            pSortableColumn="name">
                                                                                            <div style="color: white;">
                                                                                                Sprint Length (hours)

                                                                                            </div>
                                                                                            <input
                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                value=""
                                                                                                autocomplete="off"
                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                role="textbox"
                                                                                                aria-disabled="false"
                                                                                                aria-readonly="false">
                                                                                        </th>




                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="body"
                                                                                    let-product>
                                                                                    <tr>
                                                                                        <td
                                                                                            [ngStyle]="{'width':'250px'}">
                                                                                            <div class="checkbox-align">
                                                                                                <p-checkbox
                                                                                                    _ngcontent-egj-c329=""
                                                                                                    styleclass="custom-checkbox"
                                                                                                    binary="true"
                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                    <div 
                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                        <div
                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                            <input
                                                                                                                type="checkbox"
                                                                                                                name="undefined"
                                                                                                                value="undefined">
                                                                                                        </div>
                                                                                                        <div role="checkbox"
                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                            <span
                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                        </div>
                                                                                                    </div>
                                                                                                    <!---->
                                                                                                </p-checkbox>
                                                                                                <span
                                                                                                     class="account-button"  >
                                                                                                    {{product.SprintID}}
                                                                                                </span>

                                                                                            </div>
                                                                                        </td>

                                                                                        <td
                                                                                            [ngStyle]="{'width':'150px'}">
                                                                                            {{product.Status}}
                                                                                        </td>
                                                                                        <td
                                                                                            [ngStyle]="{'width':'150px'}">
                                                                                            {{product.StartDate |
                                                                                            date:'yyyy-MM-dd hh:mm:ss'}}

                                                                                        </td>

                                                                                        <td
                                                                                            [ngStyle]="{'width':'150px'}">

                                                                                            {{product.Enddate |
                                                                                            date:'yyyy-MM-dd   hh:mm:ss'}}
                                                                                        </td>

                                                                                        <td
                                                                                            [ngStyle]="{'width':'150px'}">

                                                                                            {{product.SprintLengthWeeks}}
                                                                                        </td>
                                                                                    </tr>
                                                                                </ng-template>
                                                                                <ng-template pTemplate="emptymessage"
                                                                                    let-columns>
                                                                                    <tr>
                                                                                        <td>
                                                                                            <span class="text-center">
                                                                                                <h4 class="ml-2">
                                                                                                    No
                                                                                                    Record
                                                                                                    Found
                                                                                                </h4>
                                                                                            </span>
                                                                                        </td>
                                                                                    </tr>
                                                                                </ng-template>
                                                                            </p-table>

                                                                            <!-- <p class="paginate_data">
                                                                                View per
                                                                                page </p> -->
                                                                        </div>
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                    <div class="divB mt-1">
                                                        <div class=" bg-white color-grey">
                                                            <div class="create-new-leade mt-2">
                                                                <div class="container-fluid">
                                                                    <div class="row">
                                                                        <form class="w-100">
                                                                            <div class="w3-card-4 classCard mt-2">
                                                                                <header class="w3-container w3-blue">
                                                                                    <div class="header-tabs"
                                                                                        style="background:#F5F7FA!important;">
                                                                                        <h4
                                                                                            class=" common-styling h4Margin">
                                                                                            Project Content List
                                                                                        </h4>

                                                                                    </div>
                                                                                </header>
                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select m-0 p-0 mt-1">
                                                                                    <div class="col-lg-12 col-12">
                                                                                        <div class="form-group">

                                                                                            <div
                                                                                                class="card own-account-table">
                                                                                                <p-table
                                                                                                    [value]="ContentList"
                                                                                                    [paginator]="false"
                                                                                                    [rows]="3"
                                                                                                    scrollHeight="150px"
                                                                                                    scrollWidth="100%"
                                                                                                    [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                                    [totalRecords]="total"
                                                                                                    [scrollable]="true"
                                                                                                    (sortFunction)="customSort($event)"
                                                                                                    [customSort]="true">
                                                                                                    <ng-template
                                                                                                        pTemplate="header">
                                                                                                        <tr
                                                                                                            style="background: #0d3769;">
                                                                                                            <th [ngStyle]="{'width':'150px'}"
                                                                                                                pSortableColumn="code">
                                                                                                                <div class="checkbox-align"
                                                                                                                    style="color: white;">
                                                                                                                    <p-checkbox
                                                                                                                        _ngcontent-egj-c329=""
                                                                                                                        styleclass="custom-checkbox"
                                                                                                                        binary="true"
                                                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                        <div
                                                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                            <div
                                                                                                                                class="ui-helper-hidden-accessible">
                                                                                                                                <input
                                                                                                                                    type="checkbox"
                                                                                                                                    name="undefined"
                                                                                                                                    value="undefined">
                                                                                                                            </div>
                                                                                                                            <div role="checkbox"
                                                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                <span
                                                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <!---->
                                                                                                                    </p-checkbox>
                                                                                                                    Content
                                                                                                                    Type
                                                                                                                    ID

                                                                                                                </div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </th>

                                                                                                            <th [ngStyle]="{'width':'350px'}"
                                                                                                                pSortableColumn="name">
                                                                                                                <div
                                                                                                                    style="color: white;">
                                                                                                                    Content
                                                                                                                    Name
                                                                                                                </div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </th>
                                                                                                            <th [ngStyle]="{'width':'150px'}"
                                                                                                                pSortableColumn="name">
                                                                                                                <div
                                                                                                                    style="color: white;">
                                                                                                                    From
                                                                                                                    Date
                                                                                                                </div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </th>

                                                                                                            <th [ngStyle]="{'width':'150px'}"
                                                                                                                pSortableColumn="name">
                                                                                                                <div
                                                                                                                    style="color: white;">

                                                                                                                </div>
                                                                                                                <input
                                                                                                                    id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                    class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                    value=""
                                                                                                                    autocomplete="off"
                                                                                                                    aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                    role="textbox"
                                                                                                                    aria-disabled="false"
                                                                                                                    aria-readonly="false">
                                                                                                            </th>




                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                    <ng-template
                                                                                                        pTemplate="body"
                                                                                                        let-product>
                                                                                                        <tr>
                                                                                                            <td
                                                                                                                [ngStyle]="{'width':'150px'}">
                                                                                                                <div
                                                                                                                    class="checkbox-align">
                                                                                                                    <p-checkbox
                                                                                                                        _ngcontent-egj-c329=""
                                                                                                                        styleclass="custom-checkbox"
                                                                                                                        binary="true"
                                                                                                                        class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                        <div
                                                                                                                            class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                            <div
                                                                                                                                class="ui-helper-hidden-accessible">
                                                                                                                                <input
                                                                                                                                    type="checkbox"
                                                                                                                                    name="undefined"
                                                                                                                                    value="undefined">
                                                                                                                            </div>
                                                                                                                            <div role="checkbox"
                                                                                                                                class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                                <span
                                                                                                                                    class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                            </div>
                                                                                                                        </div>
                                                                                                                        <!---->
                                                                                                                    </p-checkbox>
                                                                                                                    <span
                                                                                                                         class="account-button"  >
                                                                                                                        {{product.contentTypeId}}</span>

                                                                                                                </div>
                                                                                                            </td>

                                                                                                            <td
                                                                                                                [ngStyle]="{'width':'350px'}">
                                                                                                                {{product.imageURL}}
                                                                                                            </td>
                                                                                                            <td
                                                                                                                [ngStyle]="{'width':'150px'}">
                                                                                                                {{product.createdStamp
                                                                                                                |date:'yyyy-MM-dd  hh:mm:ss'}}

                                                                                                            </td>
                                                                                                            <td
                                                                                                                [ngStyle]="{'width':'150px'}">



                                                                                                                <button
                                                                                                                    (click)="DownloadImageProductContent(product.imageURL)"
                                                                                                                    type="submit"
                                                                                                                    class="btn btn-secondary submit-btn ml-2">
                                                                                                                    Download
                                                                                                                </button>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                    <ng-template
                                                                                                        pTemplate="emptymessage"
                                                                                                        let-columns>
                                                                                                        <tr>
                                                                                                            <td>
                                                                                                                <span
                                                                                                                    class="text-center">
                                                                                                                    <h4
                                                                                                                        class="ml-2">
                                                                                                                        No
                                                                                                                        Record
                                                                                                                        Found
                                                                                                                    </h4>
                                                                                                                </span>
                                                                                                            </td>
                                                                                                        </tr>
                                                                                                    </ng-template>
                                                                                                </p-table>

                                                                                               
                                                                                            </div>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>


                                                                        </form>

                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>


                                                        <br>
                                                    </div>
                                                </div>

                                                <div *ngIf="activeCategory==2">

                                                    <div class=" bg-white color-grey">
                                                        <div class="create-new-leade mt-2">
                                                            <div class="container-fluid">
                                                                <div class="row">
                                                                    <form class="w-100 m-0 p-0">
                                                                        <div class="container-fluid">
                                                                            <ul class="tabbing-accordians" style="width: 103% !important;
                                                            flex-wrap: wrap !important;
                                                            display: flex !important;
                                                            padding: 10px !important;">

                                                                                <button type="submit"
                                                                                    class="btn btn-secondary submit-btn "
                                                                                    (click)="changeactiveCategory(4)">
                                                                                    New
                                                                                    Sprint
                                                                                </button>

                                                                                <button type="submit"
                                                                                    class="btn btn-secondary submit-btn ml-2"
                                                                                    (click)="updateSprintClose()">Close
                                                                                    Product
                                                                                </button>

                                                                            </ul>
                                                                        </div>

                                                                        <div>
                                                                            <p
                                                                                style="color: #0d3769;font-weight: bold;font-size: 15px;">
                                                                                Current Project :
                                                                                <span> {{this.internalName + "[" + this.workEffortId + "]"}}</span>
                                                                            </p>
                                                                        </div>
                                                                        <br>

                                                                        <div class="w3-card-4 classCard">
                                                                            <div class="container-fluid">
                                                                                <ul class="tabbing-section tabbing-accordians tabClass"
                                                                                    style="width: 103% !important;
                                                                flex-wrap: wrap !important;
                                                                display: flex !important;">
                                                                                    <li><a>
                                                                                            Edit Project
                                                                                        </a>
                                                                                    </li>

                                                                                </ul>
                                                                            </div>
                                                                            <form [formGroup]="EditProjectForm">
                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                                            <div
                                                                                                class="col-lg-3 form-group rightForm">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">
                                                                                                    Move To Product
                                                                                                </label>
                                                                                            </div>
                                                                                            <div class="col-lg-3">

                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                  
                                                                                                  
                                                                                                    [options]="MoveToProductArray"
                                                                                                    formControlName="MoveToProduct"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter 
                                                                                                    Move To Product">
                                                                                                </p-dropdown>
                                                                                            </div>

                                                                                            <div
                                                                                                class="col-lg-3 form-group rightFormA">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Product
                                                                                                    Name
                                                                                                </label>
                                                                                            </div>

                                                                                            <div class="col-lg-3">
                                                                                                <input type="email" disabled
                                                                                             
                                                                                                    class="form-control"
                                                                                                    id="exampleInputEmail1"
                                                                                                    formControlName="ProductName"
                                                                                                    aria-describedby="emailHelp"
                                                                                                    placeholder="Enter Product Name">
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>

                                                                                    

                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">

                                                                                            <div
                                                                                                class="col-lg-3 form-group rightForm">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Project
                                                                                                    Name

                                                                                                </label>

                                                                                            </div>

                                                                                            <div class="col-lg-3">
                                                                                                <input type="email"  
                                                                                                    class="form-control"
                                                                                                  
                                                                                                    id="exampleInputEmail1"
                                                                                                    formControlName="ProjectName"
                                                                                                    aria-describedby="emailHelp"
                                                                                                    placeholder="Enter Project Name">
                                                                                            </div>

                                                                                            <div
                                                                                                class="col-lg-3 form-group rightFormA">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">
                                                                                                    Billed
                                                                                                </label>
                                                                                            </div>
                                                                                            <div class="col-lg-3">

                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="yesNOArray"
                                                                                                    formControlName="Billed"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter 
                                                                                                    Billed">
                                                                                                </p-dropdown>
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>
                                                                                    
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                                            <div
                                                                                                class="col-lg-3 form-group rightForm">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">
                                                                                                    Status
                                                                                                </label>
                                                                                            </div>
                                                                                            <div class="col-lg-3">

                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                              
                                                                                                  
                                                                                                    [options]="statusArray"
                                                                                                    formControlName="Status"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Enter 
                                                                                                    Status">
                                                                                                </p-dropdown>
                                                                                            </div>

                                                                                            <div
                                                                                                class="col-lg-3 form-group rightFormA">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Project
                                                                                                    Goal

                                                                                                </label>

                                                                                            </div>

                                                                                            <div class="col-lg-3">
                                                                                                <input type="email"
                                                                                                    class="form-control"
                                                                                                    id="exampleInputEmail1"
                                                                                                    formControlName="ProjectGoal"
                                                                                                    aria-describedby="emailHelp"
                                                                                                    placeholder="Enter Project Goal">
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>

                                                                                    


                                                                                </div>
                                                                            </form>
                                                                            <div class="col-lg-12  main-submit-button"
                                                                                style="margin-left: -2%;">
                                                                                <button _ngcontent-cta-c128=""
                                                                                    type="submit"
                                                                                    class="btn btn-secondary submit-btn"
                                                                                    (click)="updateScrumProject()">Save</button>&nbsp;
                                                                            </div>
                                                                        </div>
                                                                    </form>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div *ngIf="activeCategory==3">
                                                    <ul class=" tabbing-accordians " style="width: 103% !important;
                                                    flex-wrap: wrap !important;
                                                    display: flex !important;
                                                    padding: 10px !important;">
                                                        <li>

                                                            <button type="submit" class="btn btn-secondary submit-btn "
                                                                (click)="changeactiveCategory(4)">New
                                                                Sprint</button>

                                                            <button type="submit"
                                                                class="btn btn-secondary submit-btn ml-2"
                                                                (click)="updateSprintClose()">
                                                                Close
                                                                Product
                                                            </button>
                                                        </li>
                                                    </ul>
                                                    <div>
                                                        <p style="color: #0d3769;font-weight: bold;font-size: 15px;">
                                                            Current Project :<span> {{this.internalName + "[" + this.workEffortId + "]"}}</span></p>
                                                    </div>
                                                    <br>
                                                    <div class=" bg-white color-grey">
                                                        <div class="create-new-leade mt-2">
                                                            <div class="container-fluid">
                                                                <div class="row">
                                                                    <form class="w-100  m-0 p-0">
                                                                        <div class="w3-card-4 classCard mt-2">
                                                                            <header class="w3-container w3-blue">
                                                                                <div class="header-tabs"
                                                                                    style="background:#F5F7FA!important;">
                                                                                    <h4
                                                                                        class=" common-styling h4Margin">
                                                                                        Product Content List
                                                                                    </h4>

                                                                                </div>
                                                                            </header>
                                                                            <div
                                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                                <div class="col-lg-12 col-12">
                                                                                    <div class="form-group">

                                                                                        <div
                                                                                            class="card own-account-table">
                                                                                            <p-table
                                                                                                [value]="ContentList"
                                                                                                [paginator]="true"
                                                                                                [rows]="rows"
                                                                                                scrollWidth="100%"
                                                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                                [totalRecords]="total"
                                                                                                [scrollable]="true"
                                                                                                (sortFunction)="customSort($event)"
                                                                                                [customSort]="true">
                                                                                                <ng-template
                                                                                                    pTemplate="header">
                                                                                                    <tr
                                                                                                        style="background: #0d3769;">
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="code">
                                                                                                            <div class="checkbox-align"
                                                                                                                style="color: white;">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!---->
                                                                                                                </p-checkbox>
                                                                                                                Content
                                                                                                                Type
                                                                                                                ID

                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>

                                                                                                        <th [ngStyle]="{'width':'350px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                Content
                                                                                                                Name
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>
                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">
                                                                                                                From
                                                                                                                Date
                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>

                                                                                                        <th [ngStyle]="{'width':'170px'}"
                                                                                                            pSortableColumn="name">
                                                                                                            <div
                                                                                                                style="color: white;">

                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>




                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="body"
                                                                                                    let-product>
                                                                                                    <tr>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <div
                                                                                                                class="checkbox-align">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!---->
                                                                                                                </p-checkbox>
                                                                                                                <span
                                                                                                                     class="account-button"  >
                                                                                                                    {{product.contentTypeId}}</span>

                                                                                                            </div>
                                                                                                        </td>

                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'350px'}">
                                                                                                            {{product.imageURL}}
                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            {{product.createdStamp
                                                                                                            |date:'yyyy-MM-dd
                                                                                                            hh:mm:ss'}}

                                                                                                        </td>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'170px'}">
                                                                                                            <button
                                                                                                                (click)="deleteScrumProductContent(product.contentId)"
                                                                                                                type="submit"
                                                                                                                class="btn btn-secondary submit-btn ml-2">
                                                                                                                Delete
                                                                                                            </button>


                                                                                                            <button
                                                                                                                (click)="DownloadImageProductContent(product.imageURL)"
                                                                                                                type="submit"
                                                                                                                class="btn btn-secondary submit-btn ml-2">
                                                                                                                Download
                                                                                                            </button>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="emptymessage"
                                                                                                    let-columns>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <span
                                                                                                                class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                            </p-table>

                                                                                            <p class="paginate_data">
                                                                                                View per
                                                                                                page </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>

                                                                        <div class="w3-card-4 classCard mt-2">
                                                                            <div class="container-fluid">
                                                                                <ul class="tabbing-section tabbing-accordians tabClass"
                                                                                    style="width: 103% !important;
                                                                flex-wrap: wrap !important;
                                                                display: flex !important;">
                                                                                    <li><a>
                                                                                            Add Product
                                                                                            Content
                                                                                        </a>
                                                                                    </li>

                                                                                </ul>
                                                                            </div>

                                                                            <form [formGroup]="contentForm">


                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                                            <div
                                                                                                class="col-lg-3 form-group rightForm">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">
                                                                                                    Content
                                                                                                    Type
                                                                                                    ID
                                                                                                </label>
                                                                                            </div>
                                                                                            <div class="col-lg-2">

                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="contentTypeIdArray"
                                                                                                    formControlName="ContentTypeID"
                                                                                                    optionlabel="label"
                                                                                                    >
                                                                                                </p-dropdown>
                                                                                            </div>

                                                                                            <div
                                                                                                class="col-lg-3 form-group rightFormA">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Mime
                                                                                                    Type ID
                                                                                                </label>
                                                                                            </div>
                                                                                            <div class="col-lg-2">

                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="mimeTypeIdArray"
                                                                                                    formControlName="MimeTypeID"
                                                                                                    optionlabel="label"
                                                                                                    >
                                                                                                </p-dropdown>
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>

                                                                                    

                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                                            <div
                                                                                                class="col-lg-3 form-group rightForm">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Upload
                                                                                                </label>
                                                                                            </div>
                                                                                            <div class="col-lg-2">
                                                                                                <input
                                                                                                    (change)="fileProgress($event)"
                                                                                                    type="file"
                                                                                                    class="form-control"
                                                                                                    id="exampleInputEmail1"
                                                                                                    formControlName="Upload"
                                                                                                    aria-describedby="emailHelp"
                                                                                                    >
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>




                                                                                </div>
                                                                                <div class="col-lg-12  main-submit-button"
                                                                                    style="
                                                                                                           margin-left: -19%;">
                                                                                    <button _ngcontent-cta-c128=""
                                                                                        type="submit"
                                                                                        class="btn btn-secondary submit-btn"
                                                                                        (click)="oncreateScrumProductContent()">Create</button>&nbsp;


                                                                                </div>
                                                                            </form>

                                                                        </div>
                                                                    </form>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>

                                                <div *ngIf="activeCategory==4">
                                                    <ul class=" tabbing-accordians " style="width: 103% !important;
                                                    flex-wrap: wrap !important;
                                                    display: flex !important;
                                                    padding: 10px !important;">
                                                        <li>

                                                            <button type="submit" class="btn btn-secondary submit-btn "
                                                                (click)="changeactiveCategory(4)">New
                                                                Sprint</button>

                                                            <button type="submit"
                                                                class="btn btn-secondary submit-btn ml-2"
                                                                (click)="updateSprintClose()">
                                                                Close
                                                                Product
                                                            </button>
                                                        </li>
                                                    </ul>
                                                    <div>
                                                        <p style="color: #0d3769;font-weight: bold;font-size: 15px;">
                                                            Current Project :<span> {{this.internalName + "[" + this.workEffortId + "]"}}</span></p>
                                                    </div>
                                                    <br>
                                                    <div class=" bg-white color-grey">
                                                        <div class="create-new-leade mt-2">
                                                            <div class="container-fluid">
                                                                <div class="row">
                                                                    <form class="w-100">


                                                                        <div class="w3-card-4 classCard mt-2">
                                                                            <div class="container-fluid">
                                                                                <ul class="tabbing-section tabbing-accordians tabClass"
                                                                                    style="width: 103% !important;
                                                                flex-wrap: wrap !important;
                                                                display: flex !important;">
                                                                                    <li><a>
                                                                                            Create Sprint
                                                                                        </a>
                                                                                    </li>

                                                                                </ul>
                                                                            </div>

                                                                            <form [formGroup]="CreateSprintForm">


                                                                                <div
                                                                                    class="all-users-infomation font-13 font-medium own-user-select">
                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                                            <div
                                                                                                class="col-lg-3 form-group rightFormG">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">
                                                                                                    Sprint Name
                                                                                                </label>
                                                                                            </div>
                                                                                            <div class="col-lg-2">

                                                                                                <input type="email"
                                                                                                    class="form-control"
                                                                                                    id="exampleInputEmail1"
                                                                                                    formControlName="SprintName"
                                                                                                    aria-describedby="emailHelp"
                                                                                                    placeholder="Enter Sprint Name">
                                                                                            </div>

                                                                                            <div
                                                                                                class="col-lg-3 form-group rightForH">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Sprint
                                                                                                    Goal

                                                                                                </label>
                                                                                            </div>
                                                                                            <div class="col-lg-2">

                                                                                                <input type="email"
                                                                                                    class="form-control"
                                                                                                    id="exampleInputEmail1"
                                                                                                    formControlName="SprintGoal"
                                                                                                    aria-describedby="emailHelp"
                                                                                                    placeholder="Enter Sprint Goal ">
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>

                                                                                    

                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                                            <div
                                                                                                class="col-lg-3 form-group rightFormG">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Start
                                                                                                    Date
                                                                                                </label>
                                                                                            </div>
                                                                                            <div class="col-lg-2">
                                                                                                <input type="date"
                                                                                                    class="form-control"
                                                                                                    id="exampleInputEmail1"
                                                                                                    formControlName="StartDate"
                                                                                                    aria-describedby="emailHelp"
                                                                                                    placeholder="Enter Start Date">
                                                                                            </div>

                                                                                            <div
                                                                                                class="col-lg-3 form-group rightFormH">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">Sprint
                                                                                                    Length Weeks
                                                                                                </label>
                                                                                            </div>
                                                                                            <div class="col-lg-2">
                                                                                                <p-dropdown
                                                                                                    filter="true"
                                                                                                    [options]="numberArray"
                                                                                                    formControlName="SprintLengthWeeks"
                                                                                                    optionlabel="label"
                                                                                                    placeholder="Choose Sprint Length Weeks">
                                                                                                </p-dropdown>
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>

                                                                                    

                                                                                    <div class="col-lg-12">
                                                                                        <div class="row">
                                                                                            <div
                                                                                                class="col-lg-3 form-group rightFormG">
                                                                                                <label
                                                                                                    for="exampleInputEmail1">How
                                                                                                    many Sprint
                                                                                                    Backlog(s)?
                                                                                                </label>
                                                                                            </div>
                                                                                            <div class="col-lg-2">
                                                                                                <input type="email"
                                                                                                    class="form-control"
                                                                                                    id="exampleInputEmail1"
                                                                                                    formControlName="SprintBacklog"
                                                                                                    aria-describedby="emailHelp"
                                                                                                    placeholder="Enter Sprint Backlogs">
                                                                                            </div>
                                                                                            <div class="col-lg-2"
                                                                                                style="color: #0d3769;font-style: italic;">
                                                                                                0 remaining in Product
                                                                                                Backlog
                                                                                            </div>

                                                                                        </div>
                                                                                    </div>




                                                                                </div>
                                                                                <div class="col-lg-12  main-submit-button"
                                                                                    style="
                                                                                                           margin-left: -67%;">
                                                                                    <button _ngcontent-cta-c128=""
                                                                                        type="submit"
                                                                                        class="btn btn-secondary submit-btn"
                                                                                        (click)="createSprint()">Submit</button>&nbsp;


                                                                                </div>
                                                                            </form>

                                                                        </div>

                                                                        <div class="w3-card-4 classCard mt-2">
                                                                            <header class="w3-container w3-blue">
                                                                                <div class="header-tabs"
                                                                                    style="background:#F5F7FA!important;">
                                                                                    <h4
                                                                                        class=" common-styling h4Margin">
                                                                                        List of Sprint Backlogs
                                                                                    </h4>

                                                                                </div>
                                                                            </header>
                                                                            <div
                                                                                class="all-users-infomation font-13 font-medium own-user-select">
                                                                                <div class="col-lg-12 col-12">
                                                                                    <div class="form-group">

                                                                                        <div
                                                                                            class="card own-account-table">
                                                                                            <p-table
                                                                                                [value]="ListofSprintBacklogs"
                                                                                                [paginator]="true"
                                                                                                [rows]="rows"
                                                                                                scrollWidth="100%"
                                                                                                [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                                                                                [totalRecords]="total"
                                                                                                [scrollable]="true"
                                                                                                (sortFunction)="customSort($event)"
                                                                                                [customSort]="true">
                                                                                                <ng-template
                                                                                                    pTemplate="header">
                                                                                                    <tr
                                                                                                        style="background: #0d3769;">
                                                                                                        <th [ngStyle]="{'width':'150px'}"
                                                                                                            pSortableColumn="code">
                                                                                                            <div class="checkbox-align"
                                                                                                                style="color: white;">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!---->
                                                                                                                </p-checkbox>
                                                                                                                Product
                                                                                                                Backlogs
                                                                                                                Name

                                                                                                            </div>
                                                                                                            <input
                                                                                                                id="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                name="j_idt727:j_idt728:j_idt731:filter"
                                                                                                                class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                                                                                value=""
                                                                                                                autocomplete="off"
                                                                                                                aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                                                role="textbox"
                                                                                                                aria-disabled="false"
                                                                                                                aria-readonly="false">
                                                                                                        </th>






                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="body"
                                                                                                    let-product>
                                                                                                    <tr>
                                                                                                        <td
                                                                                                            [ngStyle]="{'width':'150px'}">
                                                                                                            <div
                                                                                                                class="checkbox-align">
                                                                                                                <p-checkbox
                                                                                                                    _ngcontent-egj-c329=""
                                                                                                                    styleclass="custom-checkbox"
                                                                                                                    binary="true"
                                                                                                                    class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                                                    <div
                                                                                                                        class="custom-checkbox ui-chkbox ui-widget">
                                                                                                                        <div
                                                                                                                            class="ui-helper-hidden-accessible">
                                                                                                                            <input
                                                                                                                                type="checkbox"
                                                                                                                                name="undefined"
                                                                                                                                value="undefined">
                                                                                                                        </div>
                                                                                                                        <div role="checkbox"
                                                                                                                            class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                                            <span
                                                                                                                                class="ui-chkbox-icon ui-clickable"></span>
                                                                                                                        </div>
                                                                                                                    </div>
                                                                                                                    <!---->
                                                                                                                </p-checkbox>
                                                                                                                <span
                                                                                                                    (click)="detailNewSprint(this.productId,product.des.custRequestId,product.des.description)"
                                                                                                                     class="account-button"  >
                                                                                                                    {{product.des.description
                                                                                                                    +
                                                                                                                    product.des.custRequestId}}</span>


                                                                                                            </div>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                                <ng-template
                                                                                                    pTemplate="emptymessage"
                                                                                                    let-columns>
                                                                                                    <tr>
                                                                                                        <td>
                                                                                                            <span
                                                                                                                class="text-center">
                                                                                                                <h4
                                                                                                                    class="ml-2">
                                                                                                                    No
                                                                                                                    Record
                                                                                                                    Found
                                                                                                                </h4>
                                                                                                            </span>
                                                                                                        </td>
                                                                                                    </tr>
                                                                                                </ng-template>
                                                                                            </p-table>

                                                                                            <p class="paginate_data">
                                                                                                View per
                                                                                                page </p>
                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </div>
                                                                        </div>
                                                                    </form>

                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>

                                        </div>

                                    </div>

                                </form>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>