import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { AccountingApService } from '../accounting-ap/accounting-ap.service';
@Component({
  selector: 'app-create-organization-chart-account',
  templateUrl: './create-organization-chart-account.component.html',
  styleUrls: ['./create-organization-chart-account.component.css']
})
export class CreateOrganizationChartAccountComponent implements OnInit {
  createGlobalSettingForm:FormGroup;
  activeCategory=2;
  glAccountType: any;
  glAccountTypeArray: any[]=[];
  partyId: any;
  actualfromdate: any;
  assignGLPartyId: any;
  assignGLPartyIdArray: any[]=[];
  constructor( readonly  _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
   
    readonly accountsService: AccountsService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly _ToastrService: ToastrService,) { 
      this.createGlobalSettingForm = this._FormBuilder.group({
        glAccountId: [''],
        date: [''],
        
      });
    
  }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.partyId = params["partyId"];
    });
    this.getGlAccountInitialData();
  }
  getGlAccountInitialData() {
    this.spinner.show();
    this.accountsService.getGlAccountInitialData().subscribe((res: any) => {

      this.assignGLPartyId = res.data.parentGlAccount;
      this.spinner.hide();
      for (const value of this.assignGLPartyId) {
        this.assignGLPartyIdArray.push({
          label: value.accountName,
          value: value.glAccountId
        })
      }
    })
   
  }
  getInitialData() {
    this.spinner.show();
    this.accountsService.getInitialDataAccountTypeDefault().subscribe(res => {
      this.glAccountType = res.data.glAccountTypeList;
     
      this.spinner.hide();
    
      for (const value of this.glAccountType) {
        this.glAccountTypeArray.push({
          label: value.description,
          value: value.glAccountTypeId
        })
      }
    })
   
  }
  onSubmit() {
    this.spinner.show();
    const fromActualdate=this.createGlobalSettingForm.get('date').value;
    this.actualfromdate=this.datePipe.transform(fromActualdate,"yyyy-MM-dd hh:mm:ss");
    const requestData = {
      "fromDate":  this.actualfromdate,
      "glAccountId":  this.createGlobalSettingForm.value.glAccountId, 
      "organizationPartyId":this.partyId,
    }
    this.accountsService.postAssignGLAccount(requestData).subscribe((res: any) => {
      
      if (res.success) {
        this._ToastrService.success("Created Successfully");
        this.spinner.hide();
        this._Router.navigate(['/financial/detail-organization-gl-summary'], { queryParams: { partyId:  this.partyId } });
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
        for (const value of err.error.errors) {
          this._ToastrService.error(value.fieldName + ' ' + value.fieldError);
        }
        }
        else{
          this._ToastrService.error(err.error.message);
        }
    });

  }
  
  cancelSubmit(){
    this._Router.navigate(['/financial/detail-organization-gl-summary'], { queryParams: { partyId:  this.partyId } });
  }
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}
