import { Injectable } from '@angular/core';
import { HttpClient } from '@angular/common/http';
import { Observable } from 'rxjs';
import { URLS } from 'src/app/app.constant';

@Injectable({
  providedIn: 'root'
})
export class detailedContactsService {

  constructor(readonly http: HttpClient) { }

  getParty():Observable<any>{
    const getPartyContent = URLS.partyContentDropdown;
    return this.http.get(getPartyContent);
  }
  getPartyList(id):Observable<any>{
    const getPartyContentList = URLS.getPartyContentList.replace(":partyId",id);
    return this.http.get(getPartyContentList);
  }
  getPartyContentList(id):Observable<any>{
    const getPartyContent = URLS.getPartyContentLists.replace(":partyId",id);
    return this.http.get(getPartyContent);
  }
  deleteContent(contentId,fromDate,partyContentTypeId,partyId){
    const deleteContentType = URLS.deletePartyContent + "?contentId=" + contentId + "&fromDate=" + fromDate + "&partyContentTypeId=" + partyContentTypeId + "&partyId=" + partyId;
    return this.http.delete(deleteContentType);
  }
  downloadView(id): Observable<any> {
    const getDownload = URLS.downloadPartyContent  + "?contentId=" + id
    return this.http.get(getDownload,{responseType:'blob'}) 
   }
  attachPartyContent(formData,partyContentTypeId,partyId):Observable<any>{
    const getPartyContent = URLS.attachPartyContent + "?partyContentTypeId=" + partyContentTypeId + "&partyId=" + partyId;
    return this.http.post(getPartyContent,formData);
  } 
  getContacts(contactDetails): Observable<any> {
    const getContacts = URLS.getDataByPartyId;
    const getContactsId = getContacts.replace('partyId', contactDetails)
    return this.http.get(getContactsId);
  }
  assignContact(partyIdFrom,partyIdTo):Observable<any>{
    const assignContact = URLS.assignContact + "?partyIdFrom="+partyIdFrom + "&partyIdTo=" + partyIdTo;
    return this.http.post(assignContact,null);
  }
  getAccountList():Observable<any>{
    const getAccountList = URLS.contactList;
    return this.http.get(getAccountList);
  }
   getContactNotes(id):Observable<any>{
    const getContactNotes = URLS.getContactNotes.replace(":partyId",id);
     return this.http.get(getContactNotes);
   }

   deleteNote(noteId):Observable<any>{
    const deleteNote = URLS.deleteContactNote.replace(":noteId",noteId);
    return this.http.delete(deleteNote);
  }
   
}
