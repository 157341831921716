import { Component, OnInit, OnDestroy } from '@angular/core';
import {HeaderService} from '../../header/header.service';
import { SortEvent } from 'primeng/api';
import { Router } from '@angular/router';
import { AccountsService} from '../../crm/accounts/accounts.service';
import {DropdownModule} from 'primeng/dropdown';

@Component({
  selector: 'app-create-purchase-new-address',
  templateUrl: './create-purchase-new-address.component.html',
  styleUrls: ['./create-purchase-new-address.component.css']
})
export class CreatePurchaseNewAddressComponent implements OnInit {
  products3: any;
  activeCategory = 1;
  rowNumber=0;
  pageSize = 10;
  rows = 50;
  total=0;
  partyId: any = [];
  public cols: any[];
  getContacts: any = [];
  public contact = {
    pageNo: 1,
    pageSize: 100,
    search: ""
  }
  constructor(
    readonly accountsService: AccountsService,
    readonly  HeaderService: HeaderService,
    readonly router: Router  ) {

     }
    
  ngOnInit(): void {
    this.products3 = this.accountsService.customerDetails()
   
  }

}
