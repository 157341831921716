import { Component, OnInit ,ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';

@Component({
  selector: 'app-attributes',
  templateUrl: './attributes.component.html',
  styleUrls: ['./attributes.component.css']
})
export class AttributesComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;

  activeCategory = 9;
  activeCategories=2;
  resData: any;
  total=0;
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  TeamMemberByPartyId: any;
  addAnAttributeForm:FormGroup;
  addNewCategoryAttributeForm:FormGroup;
  equipmentAttributeList: any;
  equipmentAttributeById: any;
  show: boolean;
  yesNoArray: { label: string; value: string; }[];
  dataTypeArray: { label: string; value: string; }[];
  constructor(
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _ToastrService: ToastrService,
    readonly _Router: Router
  ) 
  {
    this.addAnAttributeForm =this._FormBuilder.group({
      name:"",
      isHitorical:"",
      isRequired:"",
      dataType:"",
    });

    this.addNewCategoryAttributeForm=this._FormBuilder.group({
      name:"",
      isHitorical:"",
      isRequired:"",
      dataType:"",
      pickList:"",
    });
    this.yesNoArray =
    [{
      label: "Yes",
      value: "Yes",
    },
    {
      label: "No",
      value: "No",
    },
    ]
    this.dataTypeArray =
    [{
      label: "String",
      value: "String",
    },
    {
      label: "Integer",
      value: "Integer",
    },
    {
      label: "Phone Number",
      value: "Phone Number",
    },
    {
      label: "Float",
      value: "Float",
    },
    {
      label: "Date",
      value: "Date",
    },
    {
      label: "Time",
      value: "Time",
    },
    {
      label: "Bool",
      value: "Bool",
    },
    {
      label: "Money",
      value: "Money",
    },
    ]
  }
  ngOnInit(): void {
    this.getFsmEquipmentAttribute();
  }
  reset(){
    this.show = false;
    this.addNewCategoryAttributeForm.reset();
  }

  getFsmEquipmentAttributeById(product) {
    this.show = true;
    this.spinner.show();
    this.myContactsService.getFsmEquipmentAttributeById(product.equipmentAttributeId).subscribe(res => {
      this.equipmentAttributeById = res.data[0].getFsmEquipmentAttribute[0];
      this.spinner.hide();
      setTimeout(() => {
        const formValue = this.addNewCategoryAttributeForm;
        formValue.patchValue({
          name: this.equipmentAttributeById.name  ,
          isHitorical: this.equipmentAttributeById.historical  ,
          isRequired: this.equipmentAttributeById.requires  ,
          dataType: this.equipmentAttributeById.dataType  ,
          pickList: this.equipmentAttributeById.pickList
        })
      }, 2000);
    })
  }
 
  updateFsmEquipmentAttribute() {
    this.spinner.show();
    const requestData = {
      "dataType": this.addNewCategoryAttributeForm.value.dataType,
      "equipmentAttributeId": this.equipmentAttributeById.equipmentAttributeId,
      "historical": this.addNewCategoryAttributeForm.value.dataType,
      "name": this.addNewCategoryAttributeForm.value.dataType,
      "pickList":this.addNewCategoryAttributeForm.value.dataType,
      "requires":this.addNewCategoryAttributeForm.value.dataType,
    }
    this.myContactsService.updateFsmEquipmentAttribute(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.addNewCategoryAttributeForm.reset();
        this.getFsmEquipmentAttribute();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }

  createFsmEquipmentAttribute() {
    this.spinner.show();
    const requestData = {
        "dataType": this.addNewCategoryAttributeForm.value.dataType,
        "equipmentAttributeId": "",
        "historical": this.addNewCategoryAttributeForm.value.dataType,
        "name": this.addNewCategoryAttributeForm.value.dataType,
        "pickList":this.addNewCategoryAttributeForm.value.dataType,
        "requires":this.addNewCategoryAttributeForm.value.dataType,
      }
    this.myContactsService.createFsmEquipmentAttribute(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.addNewCategoryAttributeForm.reset();
        this.getFsmEquipmentAttribute();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getFsmEquipmentAttribute() {
    this.spinner.show();
    this.myContactsService.getFsmEquipmentAttribute().subscribe(res => {
      this.equipmentAttributeList = res.data[0].getFsmEquipmentAttribute;
      this.spinner.hide();
    })
  }
  deleteFsmEquipmentAttribute(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req = {
          "equipmentAttributeId": product.equipmentAttributeId
        }
        this.myContactsService.deleteFsmEquipmentAttribute(req).subscribe(res => {
          this.getFsmEquipmentAttribute();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )
      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

  

  ngOnDestroy(): void {
    this.spinner.hide();
  }



}
