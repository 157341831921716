import { Component, OnInit } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';

    
    import { Location } from '@angular/common';

@Component({
  selector: 'app-detail-main-portal',
  templateUrl: './detail-main-portal.component.html',
  styleUrls: ['./detail-main-portal.component.css']
})
export class DetailMainPortalComponent implements OnInit {
  noteDateTime: any;
  noteInfo: any;
  noteId: any;
  activeCategory=1;

  constructor(
    readonly activatedRoute:ActivatedRoute,
      private _location: Location,
      readonly spinner: NgxSpinnerService,
    readonly router: Router) { }

  ngOnInit(): void {
  
    this.activatedRoute.queryParams.subscribe(params=> [
      this.noteDateTime = params["noteDateTime"],
      this.noteInfo=params["noteInfo"],
      this.noteId=params["noteId"],
    
    ])
   
  }

  homeButton(){
    this.router.navigate(['/myPortal/my-portal-my-profile'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  

}
