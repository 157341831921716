import { Component, OnInit, ViewChild } from '@angular/core';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { GlobalResourceService } from 'src/app/services/global-resource.service';
import { SortEvent } from 'primeng/api';
import * as moment from 'moment';
import { DatePipe } from '@angular/common';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';

@Component({
  selector: 'app-software-detail-page',
  templateUrl: './software-detail-page.component.html',
  styleUrls: ['./software-detail-page.component.css']
})
export class SoftwareDetailPageComponent implements OnInit {
  activeCategory = 1;
  @ViewChild('closebutton1') closebutton1;
  @ViewChild('closebutton2') closebutton2;
  resData: any;
  total = 0;
  rowNumber = 0;
  rows = 50;
  pageSize = 100;
  pageNo = 1;
  updateSoftwareForm: FormGroup;
  fixedAssetId: any;
  InstalledSoftwareList: any;
  umoId: any;
  umoIdArray: any[]=[];
  classEnumId: any;
  classEnumIdArray: any[]=[];
  calendarArray: { label: string; value: string; }[];
  parentFixedAssetId: any;
  parentFixedAssetIdArray: any[]=[];
  FacilityID: any;
  FacilityIDArray: any[]=[];
  FixedAssetIdListArray: any[]=[];
  personId: any;
  ProductIdArray: any[]=[];
  ProductId: any;
  LocationSeqId: any;
  LocationSeqIdArray: any[]=[];
  InstalledSoftwareData: any;
  constructor(
    readonly _Router: Router,
    readonly _FormBuilder: FormBuilder,
    readonly spinner: NgxSpinnerService,
    readonly activatedRoute: ActivatedRoute,
    readonly router: Router,
    readonly _ToastrService: ToastrService,
    readonly _GlobalResourceService: GlobalResourceService,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly toastr: ToastrService,
    private _location: Location,
  )
  {
    this.updateSoftwareForm=this._FormBuilder.group({
      fixedAssetID:"",
      fixedAssetParentID:"",
      instanceOfProductID:"",
      classEnumID:"",
      fixedAssetName:"",
      acquireOrderID:"",
      acquireOrderItemSeqID:"",
      dateAcquired:"",
      dateLastServiced:"",
      dateNextService:"",
      expectedEndOfLife:"",
      actualEndOfLife:"",
      productionCapacity:"",
      UOM:"",
      calendar:"",
      serialNumber:"",
      locatedAtFacilityID:"",
      locatedAtLocationSeqID:"",
      salvageValue:"",
      depreciation:"",
      purchaseCost:"",
      purchaseCostUomID:"",
    })
    this.calendarArray= [
      {
        label: "[DEFAULT] Default calendar used when no specific calendar is defined",
        value:"DEFAULT"
      },
      {
        label: "[DEMO_CALENDAR] Demo Workcenter Calendar",
        value:"DEMO_CALENDAR"
      },
      {
        label: "[SUPPLIER] Calendar used for Re-Order date calculation for bought product",
        value:"SUPPLIER"
      },
    ]
   }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params=> {
      this.fixedAssetId = params["fixedAssetId"];
     
    });
    this.ListInstalledSoftware();
    this.getUmo();
    this.LookupComputerSoftwareClassEnumId();
    this.getFacilityID();
    this.FixedAssetParentIDSoftware();
    this.getFacilityID();
    this.getProductId();
    this.getFixedAssetList();
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/ismgr/ismgr-Dashboard']);
  }
  getFixedAssetList() {
    this.spinner.show();
    this.accountsService.getFixedAssetList().subscribe(res => {
      this.LocationSeqId = res.data[0].node;
      this.spinner.hide();
      for (const value of this.LocationSeqId) {
        this.LocationSeqIdArray.push({
          label: value.locatedAtLocationSeqId,
          value: value.locatedAtLocationSeqId
        })
      }
    })
  }
  getProductId() {
    this.spinner.show();
    this.accountsService.getProductId().subscribe(res => {
      this.ProductId = res.data[0].Product;
      this.spinner.hide();
      for (const value of this.ProductId) {
        this.ProductIdArray.push({
          label: value.productId,
          value: value.productId
        })
      }
    })
   
  }
  getFixedAssetIdList() {
    this.spinner.show();
    this.myContactsService.getFixedAssetIdList().subscribe(res => {
      this.personId = res.data[0].node;
      this.spinner.hide();
      for (const value of this.personId) {
        this.FixedAssetIdListArray.push({
          label: value.fixedAssetName,
          value: value.fixedAssetId
        })
      }
    })
   }
  getFacilityID() {
    this.spinner.show();
    this.accountsService.getFacilityID().subscribe(res => {
      this.FacilityID = res.data[0].data;
      this.spinner.hide();
      for (const value of this.FacilityID) {
        this.FacilityIDArray.push({
          label: value.description,
          value: value.facilityId
        })
      }
    })
  }
  FixedAssetParentIDSoftware() {
    this.spinner.show();
    this.myContactsService.FixedAssetParentIDSoftware().subscribe(res => {
      this.parentFixedAssetId = res.data[0].FixedAssetParentIDHardware;
      this.spinner.hide();
      for (const value of this.parentFixedAssetId) {
        this.parentFixedAssetIdArray.push({
          label: value.parentFixedAssetId,
          value: value.parentFixedAssetId
        })
      }
    })
  }
  getUmo() {
    this.spinner.show();
    this.myContactsService.getUmo().subscribe(res => {
      this.umoId = res.data[0].FixedAssetParentIDHardware;
      this.spinner.hide();
      for (const value of this.umoId) {
        this.umoIdArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })
  }
  LookupComputerSoftwareClassEnumId() {
    this.spinner.show();
    this.myContactsService.LookupComputerSoftwareClassEnumId().subscribe(res => {
      this.classEnumId = res.data[0].data;
      this.spinner.hide();
      for (const value of this.classEnumId) {
        this.classEnumIdArray.push({
          label: value.description,
          value: value.enumId
        })
      }
    })
  }
 
  updateComputerSoftware(): void {
    this.spinner.show();
    const requestData = {
     

      "acquireOrderId": this.updateSoftwareForm.value.acquireOrderID,
  "acquireOrderItemSeqId": this.updateSoftwareForm.value.acquireOrderItemSeqID,
  "actualEndOfLife":this.datePipe.transform(this.updateSoftwareForm.value.actualEndOfLife,"yyyy-MM-dd hh:mm:ss") , 
  "calendarId": this.updateSoftwareForm.value.calendar,
  "classEnumId": this.updateSoftwareForm.value.classEnumID,
  "dateAcquired":this.datePipe.transform(this.updateSoftwareForm.value.dateAcquired,"yyyy-MM-dd hh:mm:ss") , 
  "dateLastServiced":this.datePipe.transform(this.updateSoftwareForm.value.dateLastServiced,"yyyy-MM-dd hh:mm:ss") ,
  "dateNextService":this.datePipe.transform(this.updateSoftwareForm.value.dateNextService ,"yyyy-MM-dd hh:mm:ss") , 
  "expectedEndOfLife": this.datePipe.transform( this.updateSoftwareForm.value.expectedEndOfLife,"yyyy-MM-dd hh:mm:ss") ,
  "depreciation": this.updateSoftwareForm.value.depreciation,
  "fixedAssetId": this.updateSoftwareForm.value.fixedAssetID,
  "fixedAssetName": this.updateSoftwareForm.value.fixedAssetName,
  "fixedAssetTypeId": "COMPUTER_SOFTWARE" ,
  "instanceOfProductId": this.updateSoftwareForm.value.instanceOfProductID,
  "locatedAtFacilityId": this.updateSoftwareForm.value.locatedAtFacilityID,
  "locatedAtLocationSeqId": this.updateSoftwareForm.value.locatedAtLocationSeqID,
  "parentFixedAssetId": this.updateSoftwareForm.value.fixedAssetParentID,
  "partyId": "",
  "productionCapacity": this.updateSoftwareForm.value.productionCapacity,
  "purchaseCost": this.updateSoftwareForm.value.purchaseCost,
  "purchaseCostUomId": this.updateSoftwareForm.value.purchaseCostUomID,
  "roleTypeId": "",
  "salvageValue": this.updateSoftwareForm.value.salvageValue,
  "serialNumber": this.updateSoftwareForm.value.serialNumber,
  "uomId":this.updateSoftwareForm.value.UOMId,
  
    }  
    this.myContactsService.updateComputerSoftware(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.updateSoftwareForm.reset();
        this.closebutton2.nativeElement.click();
        this.ListInstalledSoftware();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  ListInstalledSoftware() {
    this.spinner.show();
    this.myContactsService.ListInstalledSoftware(this.fixedAssetId).subscribe(res => {
      this.InstalledSoftwareList = res.data[0].IsManagerSummary;
      this.InstalledSoftwareData = res.data[0];
      this.spinner.hide();
      setTimeout(() => {
        const form = this.updateSoftwareForm;
        form.patchValue({
          fixedAssetID: this.InstalledSoftwareList.fixedAssetId,
          fixedAssetParentID: this.InstalledSoftwareList.parentFixedAssetId,
          instanceOfProductID: this.InstalledSoftwareList.instanceOfProductId,
          classEnumID: this.InstalledSoftwareList.classEnumId,
          fixedAssetName: this.InstalledSoftwareList.fixedAssetName,
          acquireOrderID: this.InstalledSoftwareList.acquireOrderId,
          acquireOrderItemSeqID: this.InstalledSoftwareList.acquireOrderItemSeqId ,
          dateAcquired:this.datePipe.transform( this.InstalledSoftwareData.dateAcquired,"yyyy-MM-dd") ,
          dateLastServiced: this.datePipe.transform(this.InstalledSoftwareData.dateLastServiced,"yyyy-MM-dd") ,
          dateNextService: this.datePipe.transform(this.InstalledSoftwareData.dateNextService,"yyyy-MM-dd") ,
          expectedEndOfLife: this.datePipe.transform(this.InstalledSoftwareData.expectedEndOfLife,"yyyy-MM-dd") , 
          actualEndOfLife: this.datePipe.transform(this.InstalledSoftwareData.actualEndOfLife,"yyyy-MM-dd") , 
          productionCapacity: this.InstalledSoftwareList.productionCapacity,
          UOMId: this.InstalledSoftwareList.uomId,
          calendar: this.InstalledSoftwareList.calendarId,
          serialNumber: this.InstalledSoftwareList.serialNumber,
          locatedAtFacilityID: this.InstalledSoftwareList.locatedAtFacilityId,
          locatedAtLocationSeqID: this.InstalledSoftwareList.locatedAtLocationSeqId,
          salvageValue: this.InstalledSoftwareList.salvageValue,
          depreciation: this.InstalledSoftwareList.depreciation,
          purchaseCost: this.InstalledSoftwareList.purchaseCost,
          purchaseCostUomID: this.InstalledSoftwareList.purchaseCostUomId,
        })
      }, 2000);
    })
  }

  toBack()
  {
    this.router.navigate(['/ismgr/software']);
  }
  
  customSort(event: SortEvent): void {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });


  }

  ngOnDestroy(): void {
    this.spinner.hide();
  }

  changeactiveCategory(tab: number) {
    this.activeCategory = tab;

  }



}
