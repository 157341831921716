import { Component, OnInit } from '@angular/core';

import { SortEvent } from 'primeng/api';

import { NgxSpinnerService } from "ngx-spinner";
import { DatePipe } from '@angular/common';

import { ActivatedRoute, Router } from '@angular/router';
import { FormGroup, FormBuilder, Validators } from '@angular/forms';
import { ToastrService } from 'ngx-toastr';
import { LeadPersonalLeadServices } from '../leadPersonal/lead-personal/lead-personal.service';
import { CreateContactsService } from '../crm/contacts/create-contacts/create-contacts.service';
@Component({
  selector: 'app-update-contact-info',
  templateUrl: './update-contact-info.component.html',
  styleUrls: ['./update-contact-info.component.css']
})
export class UpdateContactInfoComponent implements OnInit {
  updateContact: FormGroup;
  crmArray: any[]=[];
  partyId: any;
  array: any[]=[];
  stateArray: any[]=[];
  countryGeoId: any;
  countryId: any;
  stateProvinceGeoId: any;
  stateData: any;
  statusId: string;
  stateId: any;
  activeCategory=2;

  constructor(
    readonly spinner: NgxSpinnerService,
    readonly datePipe: DatePipe,
    readonly router: Router,
    readonly toastr: ToastrService,
    readonly leadPersonalLeadServices: LeadPersonalLeadServices,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
    public createContact: CreateContactsService,
  ) {
    this.updateContact = this._FormBuilder.group({

      partyId: [''],
      externalId: [''],
     
      firstName: [''],
      lastName: [''],
      comments: [''],
      
      statusId: [''],
      postalCode:[''],
      emailAddress: [''],
      address1:[''],
      countryGeoId:[''],
      stateProvinceGeoId:[''],
      city:[''],
      phoneNumber: [''],



    });
   }
   ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
   
    this.getStatusCrm();
    this.getCountryList();
    this._ActivatedRoute.queryParams.subscribe(params => {
      this.partyId = params['updateId'];
    })
    this.getContactDetail();

  }
  onCountryChange(event) {
  
    this.stateArray = [];
    this.countryGeoId = event;
    this.countryId = event;
    if (event) {
      this.getStateList();
    }
  
  }
  onStateChange(event) {
    this.stateProvinceGeoId = event
  }
  getStateList() {
    this.spinner.show();
    this.createContact.getState(this.countryId).subscribe(res => {
      this.stateData = res.data.stateList;
      this.spinner.hide();
      this.stateData.forEach(stateLists => {
        var splitState = stateLists.split(":");
        var stateIndex = splitState[0];
        this.stateArray.push({
          label: stateIndex,
          value: stateLists.split(":").pop().trim()
        })
      })
    })
   
  }
  getCountryList() {
    this.spinner.show();
    this.createContact.getCountry().subscribe(res => {
      var country = res.data.countryList;
      this.spinner.hide();
      country.forEach(element => {
        this.array.push({
          label: element.split(":")[0].trim(),
          value: element.split(":").pop().trim()
        })
      })
    })
    
  }
  getStatusCrm() {
    this.spinner.show();
    this.leadPersonalLeadServices.getStatusCrm().subscribe(res => {
      let crmStatus = res.data;
      this.spinner.hide();
      for (const value of crmStatus) {
        this.crmArray.push({
          label: value.description,
          value: value.statusId
        })
      }
    })
   
  }
  getContactDetail() {
    this.spinner.show();
    this.createContact.getContacts(this.partyId).subscribe(res => {
     
      const form = this.updateContact;
      this.countryId = res.data.address.countryId;
      if(res.data.statusId=='Enabled'){
        this.statusId="PARTY_ENABLED"
      }
      else{
        this.statusId="PARTY_DISABLED"
      }
   
      this.onCountryChange(this.countryId);
      this.spinner.hide();
      setTimeout(() => { 
      form.patchValue({
        address1: res.data.address.address1==undefined?'':res.data.address.address1, 
        firstName: res.data.firstName, lastName: res.data.lastName, partyId:this.partyId,
        emailAddress: res.data.email==null?'':res.data.email.infoString,
        countryGeoId: res.data.address.countryId==undefined?'':res.data.address.countryId,
        comments: res.data.comments, statusId: this.statusId, externalId: res.data.externalId,
        city: res.data.address.city==undefined?'':res.data.address.city,
        postalCode: res.data.address.postalCode==undefined?'':res.data.address.postalCode,
        stateProvinceGeoId: res.data.address.stateId==undefined?'':res.data.address.stateId,
        phoneNumber:res.data.contactNumber,
      });
    }, 3000);
   })    }
  onUpdate(){
    this.spinner.show();
    const formData = this.updateContact.value;
    this.leadPersonalLeadServices.updateContact(this.partyId, formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data);
        this.spinner.hide();
        this.router.navigate(["/crm/my-contacts/details-contact"], { queryParams: { id: this.partyId } });
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.success == false) {
        if (err.error.errors) {
          for (const value of err.error.errors) {
            this.toastr.error(value.fieldName + " " + value.fieldError);
          }
        }
        else if (err.error.success == false) {
          this.toastr.error(err.error.message);
        }
      }})
    this.spinner.hide();
  }
  cancelSubmit(){
    this.router.navigate(["/crm/my-contacts/details-contact"], { queryParams: { id: this.partyId } });
  }
}
