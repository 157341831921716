<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancel()">Timesheet </span>
                <span class="color-black pl-1"> > Add New Timesheet</span>
                <button type="button" class="btn btn-danger buttonclass"  (click)="cancel()">Cancel</button>
               
            </div>
            <div class="w3-card-4 classCard" >
            <div class=" bg-white color-grey">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''" ><a>
                        Create New Employee Timesheet</a></li>
                       
                     
                    </ul>
                  </div>
              
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form *ngIf="createTimesheetFormService.create_timesheet_form"
                                [formGroup]="createTimesheetFormService.create_timesheet_form" class="w-100">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Employee ID <span
                                                    style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown filter="true" placeholder="Enter Employee ID" formControlName="partyId" [options]="partyIdArray"
                                                optionlabel="label"></p-dropdown>
                                                <small class="text-danger"
                                                *ngIf="!!createTimesheetFormService.create_timesheet_form.controls.partyId.invalid && !!createTimesheetFormService.create_timesheet_form.controls.partyId.touched"> Employee Id   is required!</small>
                                          


                                            </div>

                                            <div class="col-lg-3 form-group classInput__new_changes" >
                                                <label for="exampleInputEmail1">Approver Party </label>
                                            </div>
                                            <div class="col-lg-2" >
                                                <p-dropdown filter="true" formControlName="clientPartyId" [options]="partyIdArray"
                                                optionlabel="label" placeholder="Enter Approver Party"></p-dropdown>
                                            </div>
                                           
                                        </div>
                                    </div>
                                
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">From Date</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="date" formControlName="fromDate" class="form-control"
                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                placeholder="From Date">
                                            </div>

                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                <label for="exampleInputEmail1">Through Date</label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="date" formControlName="thruDate" class="form-control"
                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                placeholder="Through Date">
                
                                            </div>
                                          
                                        </div>
                                    </div>
                                  
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Status <span
                                                    style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown filter="true" formControlName="statusId" [options]="statusArray"
                                                optionlabel="label" placeholder="Enter Status"></p-dropdown>
                                                <small class="text-danger"
                                                *ngIf="!!createTimesheetFormService.create_timesheet_form.controls.statusId.invalid && !!createTimesheetFormService.create_timesheet_form.controls.statusId.touched">Status  is required!</small>
                                          

                                            </div>

                                            <div class="col-lg-3 form-group classInput__new_changes">
                                                <label for="exampleInputEmail1">Comments </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <input type="email" formControlName="comments" class="form-control"
                                                id="exampleInputEmail1" aria-describedby="emailHelp"
                                                placeholder="Enter Comments">
                
                                            </div>
                                          
                                        </div>
                                    </div>
                                 
                                    <div class="col-lg-12">
                                        <div class="row">
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">Approved By User Login ID </label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown filter="true" formControlName="approvedByUserLoginId" [options]="userLoginArray"
                                                optionlabel="label" placeholder="Approved by user login Id"></p-dropdown>
                                            
                                            </div>
                                            <div class="col-lg-2"></div>
                                          
                                        </div>
                                    </div>
                                   
                               
                                </div>
                                <div class="main-submit-button" style="    margin-right: 24%;">
                                    <button type="submit" *ngIf="!show" (click)="onSubmit()" class="btn btn-secondary submit-btn">Save</button>
                                    <button type="submit" *ngIf="show" (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button>
                                   
                                </div>
                            </form>
                        </div>
                    </div>

                </div>

            </div>
           </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>