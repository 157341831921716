import { DatePipe } from '@angular/common';
import { Component, OnInit, ViewChild } from '@angular/core';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { JobRequistionService } from '../recuitment/job-requisition/job-requisition.service';
import { NewJobRequistionsService } from '../recuitment/new-job-requisition/new-job-requisition.service';
import { NewJobRequistionService } from '../recuitment/new-job-requisition/new-job-requistion-service';

import { Location } from '@angular/common';

@Component({
  selector: 'app-detail-requistion-summary',
  templateUrl: './detail-requistion-summary.component.html',
  styleUrls: ['./detail-requistion-summary.component.css']
})
export class DetailRequistionSummaryComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  reqData: any;
  requistionId: any;
  activeCategory=1;
  show: boolean;
  Gender:any=[];
  activeCategoryTab = 2;
  getSkill: any;
  skillTypeArray: any[]=[];
  qualification: any;
  qualArray: any[]=[];
  enumArray: any[]=[];

  constructor(
    public newJobRequistionService: NewJobRequistionService,
    readonly newJobRequistionsService: NewJobRequistionsService,
    readonly activatedRoute: ActivatedRoute,
      private _location: Location,
      readonly toastr: ToastrService,
    public datePipe: DatePipe,
    readonly router: Router, readonly spinner: NgxSpinnerService,
    readonly jobRequistionService: JobRequistionService,
  ) { }
  ngAfterViewInit(): void { this.spinner.show(); }
  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.requistionId = params["updateId"];
    })
    this.getJobRequisition();

    this.getSkillTypeList();
    this.getQualification();
    this.activatedRoute.queryParams.subscribe(params => {
      this.requistionId = params["updateId"];
    })
    if (this.requistionId) {
      this.show = true;
      this.getJobRequisitionNew();
    }
    else {
      this.show = false;
    }
    this.Gender = [{ label: "Male", value: "M" }, { label: "Female", value: "F" }];
    this.newJobRequistionService.job_requistion_form = null;
    this.newJobRequistionService.jobRequistionForm(this.newJobRequistionService.job_requistion_form);
  }

  homeButton(){
    this.router.navigate(['/hr/overview-hrm-main'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  getJobRequisitionNew(): void {
    this.spinner.show();
    this.jobRequistionService.getJobRequisitionById(this.requistionId)
      .then(data => {
        if (data.success) {
          this.spinner.hide();
          const formValue = this.newJobRequistionService.job_requistion_form;
          formValue.patchValue({
            ...data.data, ...{
              jobLocation: data.data.jobLocation,
              age: data.data.age,
              noOfResources: data.data.noOfResources,
              gender: data.data.gender,
              durationMonths: data.data.durationMonths,
              qualification: data.data.qualification,
              examTypeEnumId: data.data.examTypeEnumId,
              skillTypeId: data.data.skillTypeId,
              experienceInMonths: data.data.experienceMonths,
              experienceInYears: data.data.experienceYears
            }
          });
        }
      });
     
  }

  getJobRequistionList() {
    
    this.spinner.show();
    const jobRequistion = {
      examTypeEnumId: "",
      experienceMonthSearchType: "Contains",
      experienceMonthSecond: "",
      experienceMonthSecondSearchType: "Contains",
      experienceMonths: "",
      experienceYearSecond: "",
      experienceYearSecondSearchType: "Contains",
      experienceYears: "",
      experienceYearsSearchType: "Contains",
      jobLocation: "",
      jobLocationSearchType: "Contains",
      jobPostingTypeEnumId: "",
      jobRequisitionId: this.requistionId,
      skillTypeId: ""
    }
    this.jobRequistionService.getJobRequistionList(jobRequistion).subscribe(res => {
      setTimeout(() => {
        const formValue = this.newJobRequistionService.job_requistion_form;
        formValue.patchValue({
          jobLocation: res.data[0].jobLocation,
          age: res.data[0].age,
          noOfResources: res.data[0].noOfResources,
          gender: res.data[0].gender,
          durationMonths: res.data[0].durationMonths,
          qualification: res.data[0].qualification,
          examTypeEnumId: res.data[0].examTypeEnumId,
          skillTypeId: res.data[0].skillTypeId,
          experienceInMonths: res.data[0].experienceMonths,
          experienceInYears: res.data[0].experienceYears
        })
      }, 3000);

      this.spinner.hide();
    })
  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategoryTab = tab;
  }
  numberOnly(event): boolean {
    const charCode = (event.which) ? event.which : event.keyCode;
    if (charCode > 31 && (charCode < 48 || charCode > 57)) {
      return false;
    }
    return true;

  }
  getSkillTypeList() {
    this.spinner.show();
    this.newJobRequistionsService.getSkillTypeList().subscribe(res => {
      this.getSkill = res.data;
      this.spinner.hide();
      for (const value of this.getSkill) {
        this.skillTypeArray.push({
          label: value.description,
          value: value.skillTypeId
        })
      }
    })
   
  }
  onUpdate() {
    this.spinner.show();
    const formData = { ...this.newJobRequistionService.job_requistion_form.value };
    
    this.newJobRequistionsService.updateJobRequistion(formData, this.requistionId).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Form Updated Successfully");
        this.spinner.hide();
        this.closebutton.nativeElement.click();
        this.router.navigate(['/hr/job-requisition'])
      }
      else if (res.success == false) {
        this.toastr.error("Error");
        this.spinner.hide();

      }
    })
   
  }
  getQualification() {
    this.spinner.show();
    this.newJobRequistionsService.getQualification().subscribe(res => {
      this.qualification = res.data;
      this.spinner.hide();
      for (const qual of this.qualification.qualifications) {
        this.qualArray.push({
          label: qual.description,
          value: qual.partyQualTypeId
        })
      }
      for (const exam of this.qualification.examEnumtype) {
        this.enumArray.push({
          label: exam.description,
          value: exam.enumId
        })
      }
    })
    
  }
  jobInfo(loc,res,gender,qual){
    if (loc && res && gender &&qual) {
      this.activeCategoryTab = 3;
    }
    else {
      this.toastr.error("Job Loaction,No. of resource,Gender and Qualification is required");
    }
  }
  getJobRequisition(): void {
    this.spinner.show();
    this.jobRequistionService.getJobRequisitionById(this.requistionId)
      .then(data => {
        if (data.success) {
         this.reqData=data.data
         this.spinner.hide();
        }
      });
     
  }
 
  ngOnDestroy(): void {
    this.spinner.hide();
      }
}

