import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { Location } from '@angular/common';
import { HeaderService } from 'src/app/header/header.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';

@Component({
  selector: 'app-new-product-store',
  templateUrl: './new-product-store.component.html',
  styleUrls: ['./new-product-store.component.css']
})
export class NewProductStoreComponent implements OnInit {
  editProductStore: any;
  ProductStoreGroupList: any;
  ProductStoreGroupListArray: any[]=[];
  LookAndFeelWebBasedStore: any;
  LookAndFeelWebBasedStoreArray: any[]=[];
  InventoryFacilityId: any;
  InventoryFacilityIdArray: any[]=[];
  RequirementMethodEnumId: any;
  RequirementMethodEnumIdArray: any[]=[];
  yesNoArray: { label: string; value: string; }[];
  ScoreCreditAccountList: any;
  ScoreCreditAccountListArray: any[]=[];
  DefaultSalesChannelEnumList: any;
  DefaultSalesChannelEnumListArray: any[]=[];
  ReserveOrderEnumId: any;
  ReserveOrderEnumIdArray: any[]=[];
  HeaderApprovedStatus: any;
  HeaderApprovedStatusArray: any[]=[];
  ItemApprovedStatus: any;
  ItemApprovedStatusArray: any[]=[];
  currencyArray: any[]=[];
  DigProdUploadCategoryList: any;
  DigProdUploadCategoryListArray: any[]=[];
  VatTaxAuthGeoId: any;
  VatTaxAuthGeoIdArray: any[]=[];
  personId: any;
  partyIdArray: any[]=[];
  customerCategoryPopup=1;

  constructor(
    public createEventFormService: CreateEventFormService,
    readonly _ActivatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,    private _location: Location,
    readonly headerService: HeaderService,
    readonly myContactsService: MyContactsService,
    readonly spinner: NgxSpinnerService,
    readonly createEventServices: CreateEventServices,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly _Router: Router,
    readonly addSkillService: AddSkillService,
  ) { 

    this.yesNoArray = 
    [{
      label: "Y",
      value: "Y",
    },
    {
      label: "N",
      value: "N",
    },
     
    ]

    this.editProductStore = this._FormBuilder.group({
      productStoreID: [''],
      primaryStoreGroupID: [''],
      storeName: [''],
      title: [''],
      subTitle: [''],
      companyName: [''],
      isDemoStore: [''],
      controlling: [''],
      inventoryFacilityID: [''],
      oneInventoryFacility: [''],
      isFulfil: [''],
      checkInventory: [''],
      reqInventory: [''],
      reqEnumID: [''],
      reserveInventory: [''],
      reserveEnumID: [''],
      resOrderCreation: [''],
      showStockProducts: [''],
      lotsManaging: [''],
      cartOnAdd: [''],
      autoSaveCart: [''],
      replaceUpsell: [''],
      removeIncompat: [''],
      giftOptions: [''],
      excludeVariants: [''],
      decimalQuantity: [''],
      prorateShipping: [''],
      addrDigItems: [''],
      paymentType: [''],
      captureFails: [''],
      splitPay: [''],
      payPartyID: [''],
      storeEnumID: [''],
      manualAudioCapture: [''],
      retryAuths: [''],
      daystoCancelnonPay: [''],
      ccTryExp: [''],
      ccOtherCards: [''],
      ccLaterNSF: [''],
      ccLaterMax: [''],
      creditValidDays: [''],
      setIssuance: [''],
      orderNumberPrefix: [''],
      channelEnumID: [''],
      exploreItems: [''],
      gcBalance: [''],
      invoiceDigitalItems: [''],
      approveInvoice: [''],
      approveOrder: [''],
      returnInventoryRecieve: [''],
      localeStorage: [''],
      currencyUomID: [''],
      timeZone: [''],
      headerApprovedStatus: [''],
      itemStatus: [''],
      digitalItemStatus: [''],
      headerDeclinedStatus: [''],
      itemDeclinedStatus: [''],
      headerCancelStatus: [''],
      itemCancelStatus: [''],
      authDeclinedMessage: [''],
      authFraudMessage: [''],
      authErrorMessage: [''],
      prorateTax: [''],
      pricesVATIncluded: [''],
      taxExempt: [''],
      vatGeoID: [''],
      vatPartyID: [''],
      approveReviews: [''],
      allowPassword: [''],
      defaultPassword: [''],
      usePrimaryUsername: [''],
      reqCustomerRole: [''],
      enableSuggestion: [''],
      enableProductUpload: [''],
      productUploadCategoryID: [''],
      allowComment: [''],
      allocateInventory: [''],
    })
  }

  ngOnInit(): void {
    this.getProductStoreGroupList();
    this.getLookAndFeelWebBasedStore();
    this.getInventoryFacilityId();
    this.getReserveOrderEnumId();
    this.getRequirementMethodEnumId();
    this.getScoreCreditAccountList();
    this.getDefaultSalesChannelEnumList();
    this.getHeaderApprovedStatus();
    this.getItemApprovedStatus();
    this.getCurrencyRate();
    this.getDigProdUploadCategoryList();
    this.getVatTaxAuthGeoId();
    this.getPartyId();
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
  homeButton(){
    this._Router.navigate(['/facilities/Dashboard'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  getProductStoreGroupList() {
    this.spinner.show();
    this.accountsService.getProductStoreGroupList().subscribe(res => {
      this.ProductStoreGroupList = res.data;
      this.spinner.hide();
      for (const value of this.ProductStoreGroupList) {
        this.ProductStoreGroupListArray.push({
          label: value.productStoreGroupName+"["+value.productStoreGroupId+"]",
          value: value.productStoreGroupId
        })
      }
    })
  }
  getLookAndFeelWebBasedStore() {
    this.spinner.show();
    this.accountsService.getLookAndFeelWebBasedStore().subscribe(res => {
      this.LookAndFeelWebBasedStore = res.data[0].visualList;
      this.spinner.hide();
      for (const value of this.LookAndFeelWebBasedStore) {
        this.LookAndFeelWebBasedStoreArray.push({
          label: value.visualThemeId+"-"+value.description,
          value: value.visualThemeId
        })
      }
    })
  }
  getInventoryFacilityId() {
    this.spinner.show();
    this.accountsService.getInventoryFacilityId().subscribe(res => {
      this.InventoryFacilityId = res.data;
      console.log("InventoryFacilityId", res.data);
      this.spinner.hide();
      for (const value of this.InventoryFacilityId) {
        this.InventoryFacilityIdArray.push({
          label: value.facilityName[0].facilityName+"["+value.facility.facilityId+"]",
          value: value.facility.facilityId
        })
      }
    })
  }
  getReserveOrderEnumId() {
    this.spinner.show();
    this.accountsService.getReserveOrderEnumId().subscribe(res => {
      this.ReserveOrderEnumId = res.data[0].requirementEnumList;
      console.log("ReserveOrderEnumId", res.data);
      this.spinner.hide();
      for (const value of this.ReserveOrderEnumId) {
        this.ReserveOrderEnumIdArray.push({
          label: value.description,
          value: value.enumId
        })
      }
    })
  }
  getRequirementMethodEnumId() {
    this.spinner.show();
    this.accountsService.getRequirementMethodEnumId().subscribe(res => {
      this.RequirementMethodEnumId = res.data[0].requirementEnumList;
      console.log("RequirementMethodEnumId", res.data);
      this.spinner.hide();
      for (const value of this.RequirementMethodEnumId) {
        this.RequirementMethodEnumIdArray.push({
          label: value.description,
          value: value.enumId
        })
      }
    })
  }
  getScoreCreditAccountList() {
    this.spinner.show();
    this.accountsService.getScoreCreditAccountList().subscribe(res => {
      this.ScoreCreditAccountList = res.data[0].creditList;
      console.log("ScoreCreditAccountList", res.data);
      this.spinner.hide();
      for (const value of this.ScoreCreditAccountList) {
        this.ScoreCreditAccountListArray.push({
          label: value.description,
          value: value.value
        })
      }
    })
  }
  getDefaultSalesChannelEnumList() {
    this.spinner.show();
    this.accountsService.getDefaultSalesChannelEnumList().subscribe(res => {
      this.DefaultSalesChannelEnumList = res.data[0].defaultSalesChannel;
      console.log("DefaultSalesChannelEnumList", res.data);
      this.spinner.hide();
      for (const value of this.DefaultSalesChannelEnumList) {
        this.DefaultSalesChannelEnumListArray.push({
          label: value.description,
          value: value.enumId
        })
      }
    })
  }
  getHeaderApprovedStatus() {
    this.spinner.show();
    this.accountsService.getHeaderApprovedStatus().subscribe(res => {
      this.HeaderApprovedStatus = res.data[0].headerApproved;
      console.log("HeaderApprovedStatus", res.data);
      this.spinner.hide();
      for (const value of this.HeaderApprovedStatus) {
        this.HeaderApprovedStatusArray.push({
          label: value.statusId,
          value: value.statusId
        })
      }
    })
  }
  getItemApprovedStatus() {
    this.spinner.show();
    this.accountsService.getItemApprovedStatus().subscribe(res => {
      this.ItemApprovedStatus = res.data[0].itemApprovedList;
      console.log("ItemApprovedStatus", res.data);
      this.spinner.hide();
      for (const value of this.ItemApprovedStatus) {
        this.ItemApprovedStatusArray.push({
          label: value.description+"["+value.statusCode+"]",
          value: value.statusId
        })
      }
    })
  }
  getCurrencyRate() {
    this.spinner.show();
    this.myContactsService.getCurrencyDataList().subscribe(res => {
      const currencyList = res.data.currencyList;
      console.log("currencyList", res.data);

      this.spinner.hide();
      for (const value of currencyList) {
        this.currencyArray.push({
          label: value.description,
          value: value.uomId
        })
      }
    })

  }
  getDigProdUploadCategoryList() {
    this.spinner.show();
    this.accountsService.getDigProdUploadCategoryList().subscribe(res => {
      this.DigProdUploadCategoryList = res.data[0].DigProdUploadCategoryList;
      this.spinner.hide();
      for (const value of this.DigProdUploadCategoryList) {
        this.DigProdUploadCategoryListArray.push({
          label: value.productCategoryId,
          value: value.productCategoryId
        })
      }
    })
  }
  getVatTaxAuthGeoId() {
    this.spinner.show();
    this.accountsService.getVatTaxAuthGeoId().subscribe(res => {
      this.VatTaxAuthGeoId = res.data[0].vatTaxAuth;
      this.spinner.hide();
      for (const value of this.VatTaxAuthGeoId) {
        this.VatTaxAuthGeoIdArray.push({
          label: value.geoId,
          value: value.geoSecCode
        })
      }
    })
  }
  getPartyId() {
    this.spinner.show();
    this.addSkillService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
   }
  createProductStore(): void {
    this.spinner.show();
    const requestData ={
      "addToCartRemoveIncompat": this.editProductStore.value.removeIncompat,
      "addToCartReplaceUpsell": this.editProductStore.value.replaceUpsell,
      "allocateInventory": this.editProductStore.value.allocateInventory,
      "allowComment": this.editProductStore.value.allowComment,
      "allowPassword": this.editProductStore.value.allowPassword,
      "authDeclinedMessage": this.editProductStore.value.authDeclinedMessage,
      "authErrorMessage": this.editProductStore.value.authErrorMessage,
      "authFraudMessage": this.editProductStore.value.authFraudMessage,
      "autoApproveInvoice": this.editProductStore.value.approveInvoice,
      "autoApproveOrder": this.editProductStore.value.approveOrder,
      "autoApproveReviews": this.editProductStore.value.approveReviews,
      "autoInvoiceDigitalItems": this.editProductStore.value.invoiceDigitalItems,
      "autoOrderCcTryExp": this.editProductStore.value.ccTryExp,
      "autoOrderCcTryLaterMax": this.editProductStore.value.ccLaterMax,
      "autoOrderCcTryLaterNsf": this.editProductStore.value.ccLaterNSF,
      "autoOrderCcTryOtherCards": this.editProductStore.value.ccOtherCards,
      "autoSaveCart": this.editProductStore.value.autoSaveCart,
      "balanceResOnOrderCreation": this.editProductStore.value.resOrderCreation,
      "checkGcBalance": this.editProductStore.value.gcBalance,
      "checkInventory": this.editProductStore.value.checkInventory,
      "companyName": this.editProductStore.value.companyName,
      "daysToCancelNonPay": this.editProductStore.value.daystoCancelnonPay,
      "defaultCurrencyUomId": this.editProductStore.value.currencyUomID,
      "defaultLocaleString": this.editProductStore.value.localeStorage,
      "defaultPassword": this.editProductStore.value.defaultPassword,
      "defaultSalesChannelEnumId": this.editProductStore.value.channelEnumID,
      "defaultTimeZoneString": this.editProductStore.value.timeZone,
      "digProdUploadCategoryId": this.editProductStore.value.productUploadCategoryID,
      "digitalItemApprovedStatus": this.editProductStore.value.digitalItemStatus,
      "ecomThemeId": this.editProductStore.value.controlling,
      "enableAutoSuggestionList": this.editProductStore.value.enableSuggestion,
      "enableDigProdUpload": this.editProductStore.value.enableProductUpload,
      "explodeOrderItems": this.editProductStore.value.exploreItems,
      "headerApprovedStatus": this.editProductStore.value.headerApprovedStatus,
      "headerCancelStatus": this.editProductStore.value.headerCancelStatus,
      "headerDeclinedStatus": this.editProductStore.value.headerDeclinedStatus,
      "inventoryFacilityId": this.editProductStore.value.inventoryFacilityID,
      "isDemoStore": this.editProductStore.value.isDemoStore,
      "isImmediatelyFulfilled": this.editProductStore.value.isFulfil,
      "itemApprovedStatus": this.editProductStore.value.itemStatus,
      "itemCancelStatus": this.editProductStore.value.itemCancelStatus,
      "itemDeclinedStatus": this.editProductStore.value.itemDeclinedStatus,
      "managedByLot": this.editProductStore.value.lotsManaging,
      "manualAuthIsCapture": this.editProductStore.value.manualAudioCapture,
      "oldHeaderLogo": " ",
      "oldHeaderMiddleBackground": " ",
      "oldHeaderRightBackground": " ",
      "oldStyleSheet": " ",
      "oneInventoryFacility": this.editProductStore.value.oneInventoryFacility,
      "orderDecimalQuantity": this.editProductStore.value.decimalQuantity,
      "orderNumberPrefix": this.editProductStore.value.orderNumberPrefix,
      "payToPartyId": this.editProductStore.value.payPartyID,
      "primaryStoreGroupId": this.editProductStore.value.primaryStoreGroupID,
      "prodSearchExcludeVariants": this.editProductStore.value.excludeVariants,
      "productStoreId": this.editProductStore.value.productStoreID,
      "prorateShipping": this.editProductStore.value.prorateShipping,
      "prorateTaxes": this.editProductStore.value.prorateTax,
      "reqReturnInventoryReceive": this.editProductStore.value.returnInventoryRecieve,
      "reqShipAddrForDigItems": this.editProductStore.value.addrDigItems,
      "requireCustomerRole": this.editProductStore.value.reqCustomerRole,
      "requireInventory": this.editProductStore.value.reqInventory,
      "requirementMethodEnumId": this.editProductStore.value.reqEnumID,
      "reserveInventory": this.editProductStore.value.reserveInventory,
      "reserveOrderEnumId": this.editProductStore.value.reserveEnumID,
      "retryFailedAuths": this.editProductStore.value.retryAuths,
      "selectPaymentTypePerItem": this.editProductStore.value.paymentType,
      "setOwnerUponIssuance": this.editProductStore.value.setIssuance,
      "shipIfCaptureFails": this.editProductStore.value.captureFails,
      "showCheckoutGiftOptions": this.editProductStore.value.giftOptions,
      "showOutOfStockProducts": this.editProductStore.value.showStockProducts,
      "showPricesWithVatTax": this.editProductStore.value.pricesVATIncluded,
      "showTaxIsExempt": this.editProductStore.value.taxExempt,
      "splitPayPrefPerShpGrp": this.editProductStore.value.splitPay,
      "storeCreditAccountEnumId": this.editProductStore.value.storeEnumID,
      "storeCreditValidDays": this.editProductStore.value.creditValidDays,
      "storeName": this.editProductStore.value.storeName,
      "subtitle": this.editProductStore.value.subTitle,
      "title": this.editProductStore.value.title,
      "usePrimaryEmailUsername": this.editProductStore.value.usePrimaryUsername,
      "vatTaxAuthGeoId": this.editProductStore.value.vatGeoID,
      "vatTaxAuthPartyId": this.editProductStore.value.vatPartyID,
      "viewCartOnAdd": this.editProductStore.value.cartOnAdd
      }
     
    this.accountsService.createProductStore(requestData).subscribe((res: any) => { 
      if (res.success) {
        this.toastr.success("Upload Successfully");
        this.spinner.hide();
        // this._Router.navigate(["/facilities/catalog/product-store-groups/editStoreGroups"])
      }
    }, (err) => {
      this.spinner.hide();
      if(err.error.errors){
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + ' ' + value.fieldError);
      }
      }
      else{
        this.toastr.error(err.error.message);
      }
    }); 
  }

  customerActiveCategoryFunction(tab: number)
    {
      this.customerCategoryPopup = tab;
    
    }

}
