<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">Employment </span>
                <span class="color-black pl-1"> > Add New</span>
            </div>
            <div class=" bg-white color-grey">
                <div class="port-header-contact create-lead font-medium mb-0">
                    Add New Employment
                </div>
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form  class="w-100">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                   

                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Internal Organization</label>
                                            <div class="">
                                                <p-dropdown filter="true" optionLabel="label">
                                                    <ng-template let-item pTemplate="selectedItem">
                                                        <span class="label-info"
                                                            style="margin-left: .5em">{{item.label}}</span>
                                                    </ng-template>
                                                    <ng-template let-car pTemplate="item">
                                                        <div class="ui-helper-clearfix"
                                                            style="position: relative;height: 25px;">
                                                            <div style="font-size:14px;margin-top:4px">{{car.label}}
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                </p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Employee Party ID <span
                                                    style="color:red">*</span></label>
                                            <input type="email" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" >
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">From Date</label>
                                            <input type="email" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Middle initial">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Amount <span
                                                    style="color:red">*</span></label>
                                            <input type="email" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Middle initial">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Comments</label>
                                            <input type="email" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Middle initial">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Pay GradeID</label>
                                            <input type="email" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Middle initial">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Salary Step Sequence ID</label>
                                            <input type="email" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Middle initial">
                                        </div>
                                    </div>
                                    <div class="col-lg-4 col-12">
                                        <div class="form-group">
                                            <label for="exampleInputEmail1">Period Type ID</label>
                                            <div class="">
                                                <p-dropdown filter="true" optionLabel="label">
                                                    <ng-template let-item pTemplate="selectedItem">
                                                        <span class="label-info"
                                                            style="margin-left: .5em">{{item.label}}</span>
                                                    </ng-template>
                                                    <ng-template let-car pTemplate="item">
                                                        <div class="ui-helper-clearfix"
                                                            style="position: relative;height: 25px;">
                                                            <div style="font-size:14px;margin-top:4px">{{car.label}}
                                                            </div>
                                                        </div>
                                                    </ng-template>
                                                </p-dropdown>
                                            </div>
                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>

                </div>

            </div>
            <div class="main-submit-button">
                <button type="submit" class="btn btn-secondary submit-btn">Submit</button>
                <button type="submit" class="btn btn-secondary bg-brown ml-3">Save as draft</button>
                <button type="submit" class="btn btn-degault cncl-btn">Cancel</button>
            </div>
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner> 