<div class="container" style="margin-top:1%!important;">
	<div class="d-flex justify-content-center own-login-container">
		<div class="card" style="border:none!important;height:500px!important">
			<div class="card-header" style="background-color: white!important; border:none!important">
				<div class="d-flex justify-content-center">
					<img style="width:100%!important;height:120px!important;" src="assets/images/FULLBLACkWHITE.jpg">
				</div>
			</div>
			<div class="card-body" style="padding: 0.5rem!important;background-color: white!important;">
				<form  [formGroup]="LoginWebPOS">
					<div class="mb-5">
						<div class="input-group form-group">
							<div class="input-group-prepend">
							</div>
							<input type="text" class="form-control" formControlName="username"
								 placeholder="Username" style="border-radius: 0px !important;
								border-top: none!important;border-left: none!important;border-right: none!important;"
								>
							<!-- { pattern="^[a-zA-Z0-9.!#$%&'*+/=?^_`{|}~-]+@[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?(?:\.[a-zA-Z0-9](?:[a-zA-Z0-9-]{0,61}[a-zA-Z0-9])?)*$"} -->
						</div>
					</div>
					<div class="mb-5">
						<div class="input-group form-group">
							<div class="input-group-prepend">
							</div>
							<input 
							username
							type="password" class="form-control" formControlName="password" 
								 placeholder="Password" style="border-radius: 0px !important;
								border-top: none!important;border-left: none!important;border-right: none!important;">
	
						</div>
						
					</div>
					<div class="input-group form-group">
						<div class="input-group-prepend">
						</div>
						<p-dropdown filter="true" placeholder="Choose Terminal"
						optionlabel="label" 
						formControlName="posTerminalId" 
						style="border-radius:0px !important; width: 400px!important"
						[options]="posTerminalArray">
						</p-dropdown>
						<!-- <input type="text" class="form-control"  name="password"
							 placeholder="Tenant Id" > -->

					</div>
				
					<div class="mb-4" style="margin-bottom: 5rem!important;">
						<div class="float-left">
							<div class="d-flex">
								<div><input type="checkbox"></div>
								<div style="padding:5px!important"><label>Remember Me</label></div>
							</div>
						</div>
						<div class="float-right">
							<a routerLink="/reset-password">Forgot your password?</a>
						</div>
					</div>
					<div class="form-group">
						<button type="submit"  
							class="btn btnHover" style="border-radius: 7px !important;background-color: #0d3769;
							height: 41px!important;width: 384px!important;font-size: 20px!important;color:#fff" (click)="LoginWebPos()">POS Login</button>
					</div>
				</form>
			</div>
			<!-- <div class="card-footer">
				<div class="d-flex justify-content-center links text-white">
					Don't have an account?<a href="#">Sign Up</a>
				</div>
				<div class="d-flex justify-content-center">
					
				</div>
			</div> -->
		</div>
	</div>
</div>

<!-- <div class="app-base-layout" *ngIf="token">
	<app-sidebar></app-sidebar>
	<div class="w-100 main-container-row">
		<app-header></app-header>
		<app-main-container></app-main-container>
		<app-footer></app-footer>
	</div>
</div> -->