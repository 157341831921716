<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-grey " (click)="cancelSubmit();" style="cursor: pointer;">Production Run Components



                </span>
                <span class="color-black pl-1"> >Add New Production Run Components




                </span>

                <button type="submit" (click)="cancelSubmit();" class="btn btn-danger buttonclass">Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass">

                            <li [ngClass]="activeCategory==2?'active':''" *ngIf="!this.show"><a>
                                    Add Production Run Components
                                </a></li>
                                <li [ngClass]="activeCategory==2?'active':''" *ngIf="this.show"><a>
                                    Update Production Run Components
                                </a></li>



                        </ul>
                    </div>


                    <div class="create-new-leade mt-2">
                        <div class="container-fluid">
                            <div class="row">
                                <form class="w-100" [formGroup]="createJob">
                                    <div class="all-users-infomation font-13 font-medium own-user-select">
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group formRightCa"  >
                                                    <label for="exampleInputEmail1">
                                                        Product ID<span style="color:red">*</span></label>
                                                </div>
                                                <div class="col-lg-2" *ngIf="!this.show">
                                                    <p-dropdown placeholder="Enter Product ID" filter="true"
                                                    formControlName="ProductID"
                                                    [options]="productListArray" optionlabel="label">

                                                </p-dropdown>

                                                </div>
                                                <div class="col-lg-2" *ngIf="this.show">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Estimated Quantity"
                                                    formControlName="ProductID" readonly>

                                                </div>
                                                <div class="col-lg-3 form-group formRightA">
                                                    <label for="exampleInputEmail1">
                                                        Estimated Quantity<span style="color:red">*</span></label>
                                                </div>
                                                <div class="col-lg-2">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                    aria-describedby="emailHelp" placeholder="Enter Estimated Quantity"
                                                    formControlName="EstimatedQuantity">
                                                </div>
                                            </div>
                                        </div>
                                      
                                        <div class="col-lg-12">
                                            <div class="row">
                                                <div class="col-lg-3 form-group formRightCa"  >
                                                    <label for="exampleInputEmail1">
                                                        Routing Task<span style="color:red">*</span></label>
                                                </div>
                                                <div class="col-lg-2" *ngIf="!this.show">
                                                    <p-dropdown placeholder="Enter Routing Task" filter="true"
                                                    formControlName="RoutingTask"
                                                    [options]="routingTaskListArray" optionlabel="label">

                                                </p-dropdown>

                                                </div>
                                                <div class="col-lg-2" *ngIf="this.show">
                                                    <input type="email" class="form-control" id="exampleInputEmail1"
                                                aria-describedby="emailHelp" placeholder="Enter Estimated Quantity"
                                                formControlName="RoutingTask" readonly>

                                                </div>
                                                <div class="col-lg-2"></div>
                                               
                                            </div>
                                        </div>
                                        


                                      



                                    </div>


                                </form>
                                <div class="main-submit-button" style="margin-left: -31% !important;">

                                    <button type="submit" *ngIf="!this.show" (click)="onSubmit()" class="btn btn-secondary submit-btn">Save</button>
                                    <button type="submit" *ngIf="this.show" (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button>

                                </div>
                            </div>
                        </div>

                    </div>
                </div>

            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>