<div class="container-fluid main-container-wrapper">
    <br>
    <div class="row">
        <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium" style="padding-top: 5px !important;padding-bottom: 5px !important;">
            <span class="color-black pl-1 titlePanels">FSM Overview</span>
            <span>
                 <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
                 <button  type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
             </span> 
         </div>

        <div class=" bg-white color-grey">
            <div class="port-header-contact create-lead font-medium mb-0">
               <span class="color-black pl-1 titlePanels">Contracts
                </span>
            </div>
            <div class="w3-card-4 classCard classCardType">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                      
                        <li [ngClass]="activeCategory==2?'active':''" (click)="changeactiveCategory(2)"><a>
                        Customer Agreements</a></li>
                    </ul>
                </div>
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="panel-group">
                            <div >
                                <div class="panel-collapse">
                                    <br>
                                    <form class="w-100" [formGroup]="CustomerAgreements">
                                        <div class="w3-card-4  w-100">
                                            <div class="accordion classCard" id="myAccordion">
                                                <div class="accordion-item">
                                                    <h2 class="accordion-header" id="headingOne">
                                                        <div class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper headingAccordion" >
                                                            <button type="button" class="btn btn-outline-secondary px-2" data-toggle="modal" data-target="#exampleModalCenter" style=" margin-bottom: 4px!important;
                                                            margin-top: 4px;">Create New Agreement</button>

                                                            <button type="button" class="accordion-button  collapsed" data-bs-toggle="collapse" data-bs-target="#collapseOne" style="margin-left: 76%!important; padding-top: 11px !important;
                                                                padding-bottom: 11px !important;">
                                                            </button>									
                
                                                        </div>
                    
                                                    </h2>
                                                    <div id="collapseOne" class="accordion-collapse collapse" data-bs-parent="#myAccordion">
                                                        <div class="card-body">
                                                            <div class="all-users-infomation font-13 font-medium own-user-select">
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Agreement ID</label>
                                                                        </div>
                
                                                                        <div class="col-lg-2" style="display: none;">
                                                                            <p-dropdown [options]="opportunityIdArray" filter="true" formControlName="agreementIdSearchType">
                                                                            </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <input type="text" name="partyId" formControlName="agreementId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Agreement ID">
                                                                        </div>
                
                                                                        <div class="col-lg-3 form-group classInputA">
                                                                            <label for="exampleInputEmail1">Product ID</label>
                                                                        </div>
                
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown [options]="productListArray" optionlabel="productId" filter="true" placeholder="Select" formControlName="productId">
                                                                            </p-dropdown>
                                                                        </div>
                
                
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Party Id From</label>
                                                                        </div>
                
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown [options]="PersonsAndPartyGroupsListArray" optionlabel="partyId" filter="true" placeholder="Select" formControlName="partyIdFrom">
                                                                            </p-dropdown>
                                                                        </div>
                
                                                                        <div class="col-lg-3 form-group classInputA">
                                                                            <label for="exampleInputEmail1">Party Id To</label>
                                                                        </div>
                
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown [options]="PersonsAndPartyGroupsListArray" optionlabel="partyId" filter="true" placeholder="Select" formControlName="partyIdTo">
                                                                            </p-dropdown>
                                                                        </div>
                
                
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">Agreement Type ID</label>
                                                                        </div>
                
                                                                        <div class="col-lg-2">
                                                                            <p-dropdown [options]="AgreementTypeListArray" optionlabel="label" filter="true" placeholder="Select" formControlName="agreementTypeId">
                                                                            </p-dropdown>
                                                                        </div>
                
                                                                        <div class="col-lg-3 form-group classInputA">
                                                                            <label for="exampleInputEmail1">From Date</label>
                                                                        </div>
                
                                                                        <div class="col-lg-2" style="display: none;">
                                                                            <p-dropdown [options]="fromDateType" filter="true" formControlName="fromDateFromSearchType">
                                                                            </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-calendar formControlName="fromDateFrom">
                                                                            </p-calendar>
                                                                        </div>
                
                                                                    </div>
                                                                </div>
                                                                <div class="col-lg-12">
                                                                    <div class="row">
                
                                                                        <div class="col-lg-3 form-group classInput">
                                                                            <label for="exampleInputEmail1">To Date</label>
                                                                        </div>
                
                                                                        <div class="col-lg-2" style="display: none;">
                                                                            <p-dropdown [options]="toDateType" filter="true" formControlName="fromDateToSearchType">
                                                                            </p-dropdown>
                                                                        </div>
                                                                        <div class="col-lg-2">
                                                                            <p-calendar formControlName="fromDateTo"></p-calendar>
                                                                        </div>
                
                
                
                                                                    </div>
                                                                </div>
                
                
                                                            </div>
                
                                                            <div class="col-lg-12 col-12 main-submit-button " style="    margin-left: -26%;">
                                                                <button _ngcontent-cta-c128="" (click)="submit()" type="submit" class="btn btn-secondary submit-btn">Find</button>&nbsp;
                                                                <button type="button" class="btn btn-danger ml-2" (click)="restForm()">Reset</button>
                
                                                            </div>
                                                            
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                            
                                        </div>

                  
                                        <br>
                                        
                                        <div class="w3-card-4 classCard">
                                            <header class="w3-container w3-blue">
                                                <div class="header-tabs">
                                                    <h4 class=" common-styling h4Margin">
                                                        Agreement List
                                                    </h4>

                                                </div>
                                            </header>
                                            <div class="all-users-infomation font-13 border-top-grey font-medium own-user-select">
                                                <div class="col-lg-12 col-12">
                                                    <div class="form-group">
                                                        <div class="card own-account-table">
                                                            <p-table [value]="agreementList" [paginator]="true" [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)" [customSort]="true">
                                                                <ng-template pTemplate="header">
                                                                    <tr style="background: #0d3769;">
                                                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="code">
                                                                            <div class="checkbox-align" style="color: white;">
                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                   
                                                                                </p-checkbox>
                                                                                Agreement ID
                                                                                <p-sortIcon field="code"></p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                            <div style="color: white;"> Product ID
                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="name">
                                                                            <div style="color: white;"> From Party ID
                                                                                <p-sortIcon field="name">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="category">
                                                                            <div style="color: white;"> To Party ID
                                                                                <p-sortIcon field="category"></p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'200px'}" width="11%" pSortableColumn="quantity">
                                                                            <div style="color: white;"> Role Type Id To
                                                                                <p-sortIcon field="quantity"></p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'200px'}" width="11%" pSortableColumn="price">
                                                                            <div style="color: white;"> Agreement Type ID
                                                                                <p-sortIcon field="price"></p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="price">
                                                                            <div style="color: white;"> From Date
                                                                                <p-sortIcon field="price">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="price">
                                                                            <div style="color: white;"> Through Date
                                                                                <p-sortIcon field="price">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="price">
                                                                            <div style="color: white;"> Description
                                                                                <p-sortIcon field="price">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'150px'}" pSortableColumn="price">
                                                                            <div style="color: white;"> Created Date
                                                                                <p-sortIcon field="price">
                                                                                </p-sortIcon>
                                                                            </div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                        <th [ngStyle]="{'width':'150px'}" width="10%" style="text-align: center;" pSortableColumn="price">
                                                                            <div style="color: white;"> Action</div>
                                                                            <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                role="textbox" aria-disabled="false" aria-readonly="false">
                                                                        </th>
                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="body" let-product>
                                                                    <tr>
                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                            <div class="checkbox-align">
                                                                                <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                    <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                        <div class="ui-helper-hidden-accessible">
                                                                                            <input type="checkbox" name="undefined" value="undefined"></div>
                                                                                        <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                            <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                        </div>
                                                                                    </div>
                                                                                   
                                                                                </p-checkbox>
                                                                                <span (click)="summary(product.agreementId,product.partyIdTo)" class="account-button">{{product.agreementId}}</span>
                                                                            </div>
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'150px'}">{{product.productId}}</td>
                                                                        <td [ngStyle]="{'width':'150px'}">{{product.partyIdFrom}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'150px'}">{{product.partyIdTo}}</td>
                                                                        <td [ngStyle]="{'width':'200px'}" width="11%">
                                                                            {{product.roleTypeIdTo}}</td>
                                                                        <td [ngStyle]="{'width':'200px'}" width="11%">
                                                                            {{product.agreementTypeId}}</td>
                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                            {{product.fromDate | date:'yyyy-MM-d hh:mm:ss'}}</td>
                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                            {{product.thruDate | date:'yyyy-MM-d hh:mm:ss'}}</td>
                                                                        <td [ngStyle]="{'width':'150px'}">
                                                                            {{product.createdStamp | date:'yyyy-MM-d hh:mm:ss'}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'150px'}">{{product.description}}
                                                                        </td>
                                                                        <td [ngStyle]="{'width':'150px'}" width="10%" style="text-align: center;">
                                                                            <div>

                                                                                <svg (click)="cancelAgreement(product.agreementId)" xmlns="http://www.w3.org/2000/svg" pTooltip="Cancel" tooltipPosition="Bottom" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                                                                                            <defs>
                                                                                                                <style>
                                                                                                                    .a {
                                                                                                                        fill: #f44336;
                                                                                                                    }
                                    
                                                                                                                    .b {
                                                                                                                        fill: #fafafa;
                                                                                                                        margin-left: 20px;
                                                                                                                    }
                                                                                                                </style>
                                                                                                            </defs>
                                                                                                            <path class="a fill-color"
                                                                                                                d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                                            <path class="b"
                                                                                                                d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                                                transform="translate(-147.576 -147.576)" />
                                                                                                        </svg>
                                                                            </div>
                                                                        </td>

                                                                    </tr>
                                                                </ng-template>
                                                                <ng-template pTemplate="emptymessage" let-columns>
                                                                    <tr>
                                                                        <td>
                                                                            <span class="text-center">
                                                                                                        <h4 class="ml-2">No Record Found</h4>
                                                                                                    </span>
                                                                        </td>
                                                                    </tr>
                                                                </ng-template>
                                                            </p-table>

                                                            <p class="paginate_data">
                                                                View per page </p>
                                                        </div>
                                                    </div>
                                                </div>
                                            </div>
                                        </div>
                                    </form>



                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Add New {{agreementType==='receiveable' ? 'Customer' :'Commission'}} Agreement</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategoryMainTs==1?'active':''"><a>
                            Create {{agreementType==='receiveable' ? 'Customer' :'Commission'}} Agreement</a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createCustomerAgreements">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="exampleInputEmail1">Product ID<span
                                                        style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="productList" optionLabel="productId" filter="true" placeholder="Select" formControlName="productId">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Party Id From<span
                                                        style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="PersonsAndPartyGroupsList" optionLabel="partyId" filter="true" placeholder="Select" formControlName="partyIdFrom">
                                                        </p-dropdown>
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="exampleInputEmail1">Party Id To<span
                                                        style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="PersonsAndPartyGroupsList" optionLabel="partyId" filter="true" placeholder="Select" formControlName="partyIdTo">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Role Type Id From<span
                                                        style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="RoleTypesList" optionLabel="description" filter="true" placeholder="Select" formControlName="roleTypeIdFrom">
                                                        </p-dropdown>
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="exampleInputEmail1">Role Type Id To</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="RoleTypesList" optionLabel="description" filter="true" placeholder="Select" formControlName="roleTypeIdTo">
                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Agreement Type ID<span
                                                        style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="AgreementTypeList" optionLabel="agreementTypeId" filter="true" placeholder="Select" formControlName="agreementTypeId">
                                                        </p-dropdown>
                                                        <small class="text-danger" *ngIf="!!createCustomerAgreements.controls.agreementTypeId.invalid && !!createCustomerAgreements.controls.agreementTypeId.touched">Agreement
                                                        Type ID is required</small>
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="exampleInputEmail1">Agreement Date</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" formControlName="agreementDate" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">From Date<span
                                                        style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" formControlName="fromDate" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="exampleInputEmail1">Through Date<span
                                                        style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="date" class="form-control" formControlName="thruDate" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="examCreate Commission Agreement
                                                pleInputEmail1">Description<span
                                                style="color:red">*</span></label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <input type="text" class="form-control" formControlName="description" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Description">
                                                    </div>


                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group classInputAB">
                                                        <label for="exampleInputEmail1">Text Data</label>
                                                    </div>

                                                    <div class="col-lg-3">
                                                        <textarea name="" id="exampleInputEmail1" formControlName="textData" class="form-control" cols="30" rows="10" placeholder="Enter Text"></textarea>
                                                    </div>

                                                </div>
                                            </div>


                                        </div>
                                        <div class="col-lg-12 col-12 main-submit-button " style="margin-left: -11%;"><button _ngcontent-cta-c128="" type="submit" (click)="submitData()" class="btn btn-secondary submit-btn">Create</button>



                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </div>
    </div>
</div>

<!-- Added by RA -->

<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1">Open: Working Schedule</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class=" bg-white color-grey" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li [ngClass]="activeCategory==2?'active':''"><a>
                                        Open: Working Schedule </a></li>


                            </ul>
                        </div>

                        <div class="create-new-leade ">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="WorkingScheduleForm" class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-12 form-group classInputD">

                                                        <input type="email" formControlName="workingSchedule" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" style="border-radius: 0px;width: 93% !important;">
                                                    </div>
                                                </div>

                                            </div>
                                            <div class="col-lg-12 mt-3">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Company</label>
                                                    </div>
                                                    <div class="col-lg-3 dropdown-new  classInputF">
                                                        <p-dropdown optionlabel="label" formControlName="Company" [options]="CompanyArray" filter="true">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Full Time </label>
                                                    </div>
                                                    <div class="col-lg-3 classInputF">
                                                        <input type="email" formControlName="FullTime" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputE">
                                                        <label for="exampleInputEmail1">Average Hour per Day
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3 classInputF">
                                                        <input type="email" formControlName="AverageHourDay" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp">
                                                    </div>



                                                </div>
                                            </div>






                                        </div>

                                        <div class="main-submit-button" style="margin-right: 7%;">
                                        </div>

                                        <div class="create-new-leade mt-2">
                                            <div class="container-fluid">
                                                <div class="row">
                                                    <form class="w-100">
                                                        <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                                            <div class="w-100">
                                                                <div class="panel-group" id="accordion" role="tablist" aria-multiselectable="true">


                                                                    <div class="w3-card-4 classCard">
                                                                        <div class="header-tabs">
                                                                            <h4 class=" common-styling ml-2">
                                                                                Working Schedule
                                                                            </h4>

                                                                        </div>

                                                                        <div class="panel-body panel">
                                                                            <form class="w-100">
                                                                                <div class=" font-13 font-medium border-top-grey own-user-select">
                                                                                    <div class="col-lg-12 col-12">
                                                                                        <div class="form-group table-responsive">
                                                                                            <table class="table align-middle">
                                                                                                <thead>
                                                                                                    <tr class="table-rows" style="background: #0d3769;">
                                                                                                        <th style="color: white;font-size: 12px;padding: 14px 20px !important;">
                                                                                                            Working Schedule Name

                                                                                                        </th>
                                                                                                        <th style="color: white;font-size: 12px;min-width: 200px;padding: 14px 20px !important;">
                                                                                                            Company

                                                                                                        </th>
                                                                                                        <th style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                                                            Full Time

                                                                                                        </th>
                                                                                                        <th style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                                                            Average Hour per Day

                                                                                                        </th>



                                                                                                        <th style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                                                            Action


                                                                                                        </th>



                                                                                                    </tr>

                                                                                                </thead>
                                                                                                <tbody>

                                                                                                    <tr class="table-rows" *ngFor="let product of WorkingScheduleTable" style="min-width: 155px;height: 11px !important;" class="align-bottom">
                                                                                                        <td style="    color: black;
                                                                                            
                                                                                          
                                                                                          ">

                                                                                                            <input type="text" [(ngModel)]="product.workingSchedule" [ngModelOptions]="{standalone:true}" class="form-control">

                                                                                                        </td>
                                                                                                        <td class="dropdown-table" style="    color: black;
                                                                                              font-weight: 100;
                                                                                              font-size: 12px;
                                                                                              min-width: 155px;">
                                                                                                            <p-dropdown filter="true" [(ngModel)]="product.partyId" [options]="CompanyArray" class="dropdowns" [ngModelOptions]="{standalone:true}" optionlabel="label">
                                                                                                            </p-dropdown>




                                                                                                        </td>
                                                                                                        <td style="min-width: 155px;">
                                                                                                            <input [(ngModel)]="product.fullTime" type="text" [ngModelOptions]="{standalone:true}" class="form-control">


                                                                                                        </td>
                                                                                                        <td style="min-width: 155px;">
                                                                                                            <input [(ngModel)]="product.averageHourPerDay" type="text" [ngModelOptions]="{standalone:true}" class="form-control">

                                                                                                        </td>


                                                                                                        <td style="color: white;font-size: 12px;min-width: 76px;padding: 12px 20px !important;">
                                                                                                            <button type="submit" (click)="updateWorkingSchedule(product)" class="btn btn-secondary submit-btn mt-2">Update
                                                                                                            </button>
                                                                                                        </td>

                                                                                                    </tr>

                                                                                                    <tr class="table-rows" class="align-bottom" [formGroup]="workingSchedule" style="height: 11px !important;">
                                                                                                        <td style="    color: black;
                                                                                        
                                                                                      
                                                                                      ">

                                                                                                            <input type="text" formControlName="WorkingScheduleName" class="form-control">

                                                                                                        </td>
                                                                                                        <td class="dropdown-table" style="    color: black;
                                                                                          font-weight: 100;
                                                                                          font-size: 12px;
                                                                                          min-width: 155px;">
                                                                                                            <p-dropdown filter="true" formControlName="Company" [options]="CompanyArray" class="dropdowns" optionlabel="label">
                                                                                                            </p-dropdown>




                                                                                                        </td>
                                                                                                        <td style="min-width: 155px;">
                                                                                                            <input formControlName="FullTime" type="text" class="form-control">


                                                                                                        </td>
                                                                                                        <td style="min-width: 155px;">
                                                                                                            <input formControlName="AverageHourperDay" type="text" class="form-control">

                                                                                                        </td>


                                                                                                        <td style="color: white;font-size: 12px;min-width: 76px;padding: 20px 20px !important;">
                                                                                                            <button type="submit" (click)="onWorkingSchedule()" class="btn btn-secondary submit-btn ">Create
                                                                                                            </button>
                                                                                                        </td>

                                                                                                    </tr>


                                                                                                </tbody>
                                                                                            </table>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </form>
                                                                        </div>



                                                                    </div>



                                                                    <div class="w3-card-4 classCard mt-4">
                                                                        <div class="header-tabs">
                                                                            <h4 class=" common-styling ml-2">
                                                                                Working Hours


                                                                            </h4>


                                                                        </div>
                                                                        <br>
                                                                        <div>

                                                                        </div>
                                                                        <div class="panel-body panel">
                                                                            <form class="w-100">
                                                                                <div class=" font-13 font-medium border-top-grey own-user-select">
                                                                                    <div class="col-lg-12 col-12">
                                                                                        <div class="form-group table-responsive">
                                                                                            <table class="table align-middle">
                                                                                                <thead>
                                                                                                    <tr class="table-rows" style="background: #0d3769;height: 12px !important;">
                                                                                                        <th style="color: white;font-size: 12px;min-width: 185px;padding: 14px 20px !important;">
                                                                                                            Name

                                                                                                        </th>
                                                                                                        <th style="color: white;font-size: 12px;min-width: 200px;padding: 14px 20px !important;">
                                                                                                            Day of Week

                                                                                                        </th>
                                                                                                        <th style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                                                            Day Period

                                                                                                        </th>
                                                                                                        <th style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                                                            Work From

                                                                                                        </th>

                                                                                                        <th style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                                                            Work To

                                                                                                        </th>

                                                                                                        <th style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                                                            Starting Date

                                                                                                        </th>

                                                                                                        <th style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                                                            End Date

                                                                                                        </th>

                                                                                                        <th style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                                                            Work Entry Type

                                                                                                        </th>



                                                                                                        <th style="color: white;font-size: 12px;min-width: 155px;padding: 14px 20px !important;">
                                                                                                            Action


                                                                                                        </th>



                                                                                                    </tr>
                                                                                                </thead>
                                                                                                <tbody>

                                                                                                    <tr class="align-bottom" *ngFor="let product of workingHoursTable" style="height: 11px !important;">
                                                                                                        <td style="    color: black;
                                                                                    font-weight: 100;
                                                                                    font-size: 12px;
                                                                                  
                                                                                  ">

                                                                                                            <input [ngModelOptions]="{standalone:true}" type="text" [(ngModel)]="product.data.name" class="form-control">

                                                                                                        </td>
                                                                                                        <td class="dropdown-table" style="    color: black;
                                                                                          font-weight: 100;
                                                                                          font-size: 12px;
                                                                                          min-width: 155px;">
                                                                                                            <p-dropdown [ngModelOptions]="{standalone:true}" filter="true" [(ngModel)]="product.data.dayOfWeek" [options]="DayOfWeekArray" class="dropdowns" optionlabel="label">
                                                                                                            </p-dropdown>



                                                                                                        </td>

                                                                                                        <td class="dropdown-table" style="min-width: 155px;">
                                                                                                            <p-dropdown [(ngModel)]="product.data.dayPeriod" filter="true" [ngModelOptions]="{standalone:true}" [options]="DayPeriodArray" class="dropdowns" optionlabel="label">
                                                                                                            </p-dropdown>
                                                                                                        </td>

                                                                                                        <td style="min-width: 155px;">
                                                                                                            <input [(ngModel)]="product.data.workFrom" type="text" [ngModelOptions]="{standalone:true}" class="form-control">
                                                                                                        </td>

                                                                                                        <td style="min-width: 155px;">
                                                                                                            <input [(ngModel)]="product.data.workTo" type="text" [ngModelOptions]="{standalone:true}" class="form-control">
                                                                                                        </td>

                                                                                                        <td style="min-width: 155px;">
                                                                                                            <input [(ngModel)]="product.data.startDate" type="date" [ngModelOptions]="{standalone:true}" class="form-control">
                                                                                                        </td>

                                                                                                        <td style="min-width: 155px;">
                                                                                                            <input [(ngModel)]="product.data.endDate" type="date" [ngModelOptions]="{standalone:true}" class="form-control">
                                                                                                        </td>

                                                                                                        <td class="dropdown-table" style="min-width: 155px;">
                                                                                                            <p-dropdown [(ngModel)]="product.data.workEntryType" filter="true" [ngModelOptions]="{standalone:true}" [options]="workEntryArray" class="dropdowns" optionlabel="label">
                                                                                                            </p-dropdown>
                                                                                                        </td>

                                                                                                        <td style="color: white;font-size: 12px;min-width: 76px;">
                                                                                                            <svg class="hover" xmlns="http://www.w3.org/2000/svg" width="16" height="16" (click)="updateWorkingHours(product)" viewBox="0 0 21.223 21.222">
                                                                                                                <g
                                                                                                                    transform="translate(0 -0.004)">
                                                                                                                    <g
                                                                                                                        transform="translate(0 1.52)">
                                                                                                                        <path
                                                                                                                            d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                                            transform="translate(0 -34.137)" />
                                                                                                                    </g>
                                                                                                                    <g
                                                                                                                        transform="translate(4.548 0.004)">
                                                                                                                        <g
                                                                                                                            transform="translate(0 0)">
                                                                                                                            <path
                                                                                                                                d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                                                transform="translate(-102.409 -0.004)" />
                                                                                                                        </g>
                                                                                                                    </g>
                                                                                                                </g>
                                                                                                            </svg>

                                                                                                            <svg (click)="deleteWorkingHours()" xmlns="http://www.w3.org/2000/svg" xmlns:xlink="http://www.w3.org/1999/xlink" aria-hidden="true" role="img" width="2em" height="2em" preserveAspectRatio="xMidYMid meet" viewBox="0 0 48 48">
                                                                                                                <g
                                                                                                                    fill="none">
                                                                                                                    <path
                                                                                                                        d="M24 7.25a5.75 5.75 0 0 1 5.746 5.53l.004.22H37a1.25 1.25 0 0 1 .128 2.493L37 15.5h-1.091l-1.703 22.57A4.25 4.25 0 0 1 29.968 42H18.032a4.25 4.25 0 0 1-4.238-3.93L12.09 15.5H11a1.25 1.25 0 0 1-1.244-1.122l-.006-.128c0-.647.492-1.18 1.122-1.243L11 13h7.25A5.75 5.75 0 0 1 24 7.25zm9.402 8.25H14.598l1.69 22.382a1.75 1.75 0 0 0 1.744 1.618h11.936a1.75 1.75 0 0 0 1.745-1.618l1.69-22.382zm-6.152 5.25c.647 0 1.18.492 1.244 1.122L28.5 22v11a1.25 1.25 0 0 1-2.494.128L26 33V22c0-.69.56-1.25 1.25-1.25zm-6.5 0c.647 0 1.18.492 1.244 1.122L22 22v11a1.25 1.25 0 0 1-2.494.128L19.5 33V22c0-.69.56-1.25 1.25-1.25zm3.25-11a3.25 3.25 0 0 0-3.245 3.066L20.75 13h6.5A3.25 3.25 0 0 0 24 9.75z"
                                                                                                                        fill="#000" />
                                                                                                                </g>
                                                                                                            </svg>


                                                                                                        </td>



                                                                                                    </tr>



                                                                                                    <tr class="align-bottom" [formGroup]="createWorkingHour" style="height: 11px !important;">
                                                                                                        <td style="    color: black;
                                                                                    font-weight: 100;
                                                                                    font-size: 12px;
                                                                                  
                                                                                  ">

                                                                                                            <input type="text" formControlName="names" class="form-control">

                                                                                                        </td>
                                                                                                        <td class="dropdown-table" style="    color: black;
                                                                                          font-weight: 100;
                                                                                          font-size: 12px;
                                                                                          min-width: 155px;">
                                                                                                            <p-dropdown filter="true" formControlName="dayOfWeek" [options]="DayOfWeekArray" class="dropdowns" optionlabel="label">
                                                                                                            </p-dropdown>



                                                                                                        </td>

                                                                                                        <td class="dropdown-table" style="min-width: 155px;">
                                                                                                            <p-dropdown filter="true" formControlName="dayPeriod" [options]="DayPeriodArray" class="dropdowns" optionlabel="label">
                                                                                                            </p-dropdown>
                                                                                                        </td>

                                                                                                        <td style="min-width: 155px;">
                                                                                                            <input formControlName="workFrom" type="text" class="form-control">
                                                                                                        </td>

                                                                                                        <td style="min-width: 155px;">
                                                                                                            <input formControlName="WorkTo" type="text" class="form-control">
                                                                                                        </td>
                                                                                                        <td style="min-width: 155px;">
                                                                                                            <input formControlName="startDate" type="date" class="form-control">
                                                                                                        </td>
                                                                                                        <td style="min-width: 155px;">
                                                                                                            <input formControlName="endDate" type="date" class="form-control">
                                                                                                        </td>

                                                                                                        <td class="dropdown-table " style="min-width: 155px;">
                                                                                                            <p-dropdown formControlName="WorkEntryType" filter="true" [options]="workEntryArray" class="dropdowns" optionlabel="label">
                                                                                                            </p-dropdown>
                                                                                                        </td>



                                                                                                        <td style="color: white;font-size: 12px;min-width: 76px;padding-top: 20px;">
                                                                                                            <button type="submit" (click)="oncreateWorkingHours()" class="btn btn-secondary submit-btn">Create
                                                                                                            </button>
                                                                                                        </td>
                                                                                                    </tr>

                                                                                                    <tr class="align-bottom" style="height: 11px !important;">
                                                                                                        <td style="    color: black;
                                                                                font-weight: 100;
                                                                                font-size: 12px;
                                                                              
                                                                              ">


                                                                                                        </td>
                                                                                                        <td style="    color: black;
                                                                                      font-weight: 100;
                                                                                      font-size: 12px;
                                                                                      min-width: 155px;">




                                                                                                        </td>

                                                                                                        <td style="min-width: 155px;">

                                                                                                        </td>

                                                                                                        <td style="min-width: 155px;">

                                                                                                        </td>

                                                                                                        <td style="min-width: 155px;">

                                                                                                        </td>
                                                                                                        <td style="min-width: 155px;">

                                                                                                        </td>
                                                                                                        <td style="min-width: 155px;">

                                                                                                        </td>

                                                                                                        <td style="min-width: 155px;">


                                                                                                        </td>



                                                                                                        <td style="color: rgb(0, 0, 0);font-size: 12px;min-width: 76px;">
                                                                                                            Total Hours :

                                                                                                            <span>{{this.TotalHours}}</span>

                                                                                                        </td>
                                                                                                    </tr>


                                                                                                </tbody>
                                                                                            </table>

                                                                                        </div>
                                                                                    </div>
                                                                                </div>
                                                                            </form>
                                                                        </div>
                                                                    </div>



                                                                </div>
                                                            </div>
                                                        </div>
                                                    </form>
                                                </div>
                                            </div>
                                        </div>

                                    </form>
                                    <div class="main-submit-button" style="margin-left:2%;position: static;">
                                        <button type="submit" class="btn btn-secondary submit-btn" (click)="onsubmitWorkingSchedule()">Save</button>
                                        <button type="submit" class="btn btn-default submit-btn ml-2" style="color: #000; background-color: #fff;border: 1px solid #666666;" (click)="discardPopUp()">Discard</button>


                                    </div>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="createPartnerPopup" tabindex="-1" role="dialog" aria-labelledby="createPartnerPopup"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span class="color-black pl-1"> Create Partner</span>
                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class=" bg-white color-grey">
                    <div class="w3-card-4 classCardPopUp">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">
                                <li>
                                    <a>Create Partner</a>
                                </li>
                            </ul>
                        </div>
                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form class="w-100" [formGroup]="createPartner">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Partner Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="partnerName"
                                                            placeholder="Enter Partner Name">
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Local Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="localName"
                                                            placeholder="Enter Local Name">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Site Name</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="siteName"
                                                            placeholder="Enter Site Name">
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Annual Revenue</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="annualRevenue"
                                                            placeholder="Enter Annual Revenue">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Preferred Currency</label>
                                                    </div>
                                                    <div class="col-lg-2 dropdowns">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            formControlName="preferredCurrency" [options]="currencyList"
                                                            placeholder="Enter Preferred Currency">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Industry</label>
                                                    </div>
                                                    <div class="col-lg-2 dropdowns">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            formControlName="industry" [options]="industryListArray"
                                                            placeholder="Enter Industry">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Number of Employees</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            formControlName="numberOfEmployees"
                                                            placeholder="Enter Number of Employees">
                                                    </div>

                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Ownership</label>
                                                    </div>
                                                    <div class="col-lg-2 dropdowns">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            formControlName="ownership" [options]="ownerShipArray"
                                                            placeholder="Enter Ownership">
                                                        </p-dropdown>
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">SIC Code</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="sitCode"
                                                            placeholder="Enter SIT Code">
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Ticker Symbol</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="tickerSymbol"
                                                            placeholder="Enter Ticker Symbol">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Country Code</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="countryCode"
                                                            placeholder="Enter Country Code">
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Area Code</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="areaCode"
                                                            placeholder="Enter Area Code">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Phone Number</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="phoneNumber"
                                                            placeholder="Enter Phone Number">
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Extension</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="extension"
                                                            placeholder="Enter Extension">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Person to Ask For
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            formControlName="personToAskFor"
                                                            placeholder="Enter Person To Ask For">
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">E-mail Address
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="emailAddress"
                                                            placeholder="Enter E-mail Address">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Web URL
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="webURL"
                                                            placeholder="Enter Web URL">
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">To Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="toName"
                                                            placeholder="Enter To Name">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Attention Name
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="attentionName"
                                                            placeholder="Enter Attention Name">
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Address Line 1
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="addressLine1"
                                                            placeholder="Enter Address Line 1">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Address Line 2
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="addressLine2"
                                                            placeholder="Enter Address Line 2">
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">City
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="city"
                                                            placeholder="Enter City">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">State/Province
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 dropdowns">
                                                        <p-dropdown filter="true" optionlabel="label"
                                                            [options]="stateArray" formControlName="state"
                                                            placeholder="Enter State/Province">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Zip/Postal Code
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="zipCode"
                                                            placeholder="Enter Zip Code">
                                                    </div>
                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Country
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2 dropdowns">
                                                        <p-dropdown filter="true" optionlabel="label" [options]="array"
                                                            formControlName="country" placeholder="Enter Country">
                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2 form-group classInput">
                                                        <label for="exampleInputEmail1">Zip/Postal Code Extension
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                            aria-describedby="emailHelp" formControlName="zipExtension"
                                                            placeholder="Enter Zip/Postal Code Extension">
                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-8  main-submit-button">
                                                <button type="submit" class="btn btn-secondary submit-btn"
                                                    (click)="createPartners()">Create</button>
                                            </div>
                                        </div>
                                    </form>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<ngx-spinner></ngx-spinner>