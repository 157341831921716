import { Component, OnInit ,ViewChild } from '@angular/core';
import { DatePipe } from '@angular/common';
import { FormBuilder, FormGroup } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';
import { SortEvent } from 'primeng/api';
import { AccountsService } from 'src/app/crm/accounts/accounts.service';
import { AddSkillService } from 'src/app/human-resource/add-skills/add-skill.service';
import { CreateEventFormService } from 'src/app/employeeApplication/create-employee-application/create-employee-application-service';
import { CreateEventServices } from 'src/app/crm/events/create-events/create-event.service';
import { HeaderService } from 'src/app/header/header.service';
import { MyContactsService } from 'src/app/crm/contacts/my-contacts/my-contacts.service';
import Swal from 'sweetalert2';
import { Location } from '@angular/common';


@Component({
  selector: 'app-work-orders-priorities',
  templateUrl: './work-orders-priorities.component.html',
  styleUrls: ['./work-orders-priorities.component.css']
})
export class WorkOrdersPrioritiesComponent implements OnInit {

  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  activeCategory=2;
  resData: any;
  total=0;
  rowNumber=0;
  rows = 50;
  pageSize= 100;
  pageNo = 1;
  addTeamMember: any;
  editTeam: any;
  TeamByPartyId: any;
  party: any;
  partyId: any;
  Role: any;
  RoleArray: any[]=[];
  show: boolean;
  TeamMemberByPartyId: any;
  updateTeamMem: any;
  Deactivated: boolean;
  personId: any;
  partyIdArray: any[]=[];
  teamMember: any;
  teamMemberArray: any[]=[];

  editworkOrderPrioritiesForm:FormGroup;
  addworkOrderPrioritiesForm:FormGroup;
  FsmPrioritiesAll: any;
  yesNoArray: { label: string; value: string; }[];
  prioritiesArray: { label: string; value: string; }[];
  updateFsmPrioritiesPtc: any;


  constructor(
    public createEventFormService: CreateEventFormService,
    readonly activatedRoute: ActivatedRoute,
    readonly datePipe: DatePipe,
    readonly accountsService: AccountsService,
    readonly myContactsService: MyContactsService,
    readonly _ToastrService: ToastrService,
    readonly _FormBuilder: FormBuilder,
    readonly toastr: ToastrService,
    readonly spinner: NgxSpinnerService,
    readonly _Router: Router,
    private _location: Location,
  ) 

  {
    this.yesNoArray = [{
      label: 'Yes',
      value: 'Y'
    },
    {
      label: 'No',
      value: 'N'
    }];
    this.prioritiesArray = [{
        label: 'Low',
        value: 'Low'
      },
      {
        label: 'Medium',
        value: 'Medium'
      },
      {
        label: 'High',
        value: 'High'
      }
    ];
    this.editworkOrderPrioritiesForm = this._FormBuilder.group({
      displayAs:"",
      priorities:"",
      consideOverDue:"",
      inDefault:"",
    })
    this.addworkOrderPrioritiesForm = this._FormBuilder.group({
      displayAs:"",
      priorities:"",
      consideOverDue:"",
      inDefault:"",
    })
  }

  ngOnInit(): void {
    this.activatedRoute.queryParams.subscribe(params => {
      this.partyId = params.party;
    })
    

    this.getFsmPriorities();
  }

  backWindow(){
      this._location.back();
    }
      toDashboard()
  {
    this._Router.navigate(['/hub/Dashboard']);
  }
  deleteFsmPriorities(product) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        const req = {
          "prioritiesId": product.prioritiesId

        }
        this.accountsService.deleteFsmPriorities(req).subscribe(res => {
          this.getFsmPriorities();
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  updateFsmPriorities(): void {
    this.spinner.show();
    const requestData = {
      "considerOverAfterHrs": this.editworkOrderPrioritiesForm.value.consideOverDue,
      "isDefault": this.editworkOrderPrioritiesForm.value.inDefault,
      "prioritiesId": this.updateFsmPrioritiesPtc.prioritiesId,
      "prioritiesName": this.editworkOrderPrioritiesForm.value.displayAs,
      "priority": this.editworkOrderPrioritiesForm.value.priorities
    }
    this.accountsService.updateFsmPriorities(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Updated Successfully");
        this.spinner.hide();
        this.getFsmPriorities();
        this.editworkOrderPrioritiesForm.reset();
        this.closebutton2.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  
  updateFsmPrioritiesPtch(product) {
    this.updateFsmPrioritiesPtc = product ;
    this.spinner.show();
    setTimeout(() => {
      const formValue = this.editworkOrderPrioritiesForm;
      formValue.patchValue({
        displayAs: this.updateFsmPrioritiesPtc.prioritiesName,
        priorities: this.updateFsmPrioritiesPtc.priority,
        consideOverDue: this.updateFsmPrioritiesPtc.considerOverAfterHrs,
        inDefault: this.updateFsmPrioritiesPtc.isDefault,
      })

    }, 2000);
    this.spinner.hide();

  }
  createFsmPriorities(): void {
    this.spinner.show();
    const requestData = {
      "considerOverAfterHrs": this.addworkOrderPrioritiesForm.value.consideOverDue,
      "isDefault": this.addworkOrderPrioritiesForm.value.inDefault,
      "prioritiesId": "",
      "prioritiesName": this.addworkOrderPrioritiesForm.value.displayAs,
      "priority": this.addworkOrderPrioritiesForm.value.priorities
    }
    this.accountsService.createFsmPriorities(requestData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success("Created Successfully");
        this.spinner.hide();
        this.getFsmPriorities();
        this.addworkOrderPrioritiesForm.reset();
        this.closebutton.nativeElement.click();
      }
    }, (err) => {
      this.spinner.hide();
      if (err.error.errors) {
        for (const value of err.error.errors) {
          this.toastr.error(value.fieldName + ' ' + value.fieldError);
        }
      }
      else {
        this.toastr.error(err.error.message);
      }
    });
  }
  getFsmPriorities() {
    this.spinner.show();
    this.accountsService.getFsmPriorities().subscribe(res => {
      this.FsmPrioritiesAll = res.data[0].getFsmPriorities;
      this.spinner.hide();

    })
  }
  cancel(){
    this.addworkOrderPrioritiesForm.reset();
  }

 

  cancelSubmit(){
    this._Router.navigate(["/crm/Teams"])
  }
  changeactiveCategory(tab: number) {
    this.activeCategory = tab;
  }

 

  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }

  ngOnDestroy(): void {
    this.spinner.hide();
  }

}
