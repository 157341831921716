<div class="container-fluid main-container-wrapper">


    <div class="d-flex port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium"
        style="padding-top: 5px !important;padding-bottom: 5px !important;">
        <span class="color-black pl-1 titlePanels">Accounting Manager Main Page
        </span>
        <span>
            <button type="submit" class="btn btn-secondary submit-btn mr-2" (click)="toDashboard()">Home</button>
            <button type="submit" class="btn btn-danger buttonclass" (click)="backWindow()">Back</button>
        </span>
    </div>

    <div class=" bg-white color-grey">
        <div class="w3-card-4 classCard">
            <div class="container-fluid">
                <ul class="tabbing-section tabbing-accordians tabClass mt-0"
                    style="padding-left:9px; width: 102%;flex-wrap: wrap;display: flex; margin-bottom: 0px !important;">
                    <li [ngClass]="activeCategoriess==1?'active':''" (click)="mainPage()">
                        <a>
                            Main </a>
                    </li>

                    <li [ngClass]="activeCategoriess==2?'active':''" (click)="accountingPage()">
                        <a>
                            Accounting </a>
                    </li>
                    <li [ngClass]="activeCategoriess==3?'active':''" (click)="invoicePage()">
                        <a>
                            Invoices</a>
                    </li>
                    <li [ngClass]="activeCategoriess==4?'active':''" (click)="paymentPage()">
                        <a>
                            Payments </a>
                    </li>
                    <li [ngClass]="activeCategoriess==5?'active':''" (click)="paymentGrpPage()">
                        <a>
                            Payment group</a>
                    </li>
                    <li [ngClass]="activeCategoriess==6?'active':''" (click)="billingAccountPage()">
                        <a>
                            Billing Account
                        </a>
                    </li>
                    <li [ngClass]="activeCategoriess==7?'active':''" (click)="agreementPage()">
                        <a>
                            Agreements</a>
                    </li>
                    <li [ngClass]="activeCategoriess==8?'active':''" (click)="commisionReportPage()">
                        <a>
                            Commission Report</a>
                    </li>
                </ul>
            </div>

            <div>
                <div class="w3-card-4 classCardView m-2">
                    <div class="header-tabs">
                        <h4 class=" common-styling" style="margin-left: 7px;">
                            Find Payment
                            
                                <button _ngcontent-cta-c128="" type="submit"
                                class="btn btn-secondary submit-btn ml-2 fontMenu"
                                  (click)="createPayment()">Create</button>

                                  <button _ngcontent-cta-c128="" type="submit"
                                  data-toggle="modal" data-target="#exampleModalCenter"  class="btn btn-secondary submit-btn ml-2 fontMenu"
                                    >Find Sales Invoices By Due Date
                                </button>

                                    <button _ngcontent-cta-c128="" type="submit"
                                    data-toggle="modal" data-target="#exampleModalCenterTwo"   class="btn btn-secondary submit-btn ml-2 fontMenu"
                                      >Find Purchase Invoices By Due Date</button>
                            
                        </h4>

                    </div>
                    <div class="panel-body">
                        <div class="all-users-infomation font-13 font-medium own-user-select">
                            <div class="form-group">
                                <div class="row">
                                    <form class="w-100" [formGroup]="findForm">
                                        <div
                                            class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Payment ID

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control"
                                                            id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Payment ID"
                                                            formControlName="paymentId">

                                                    </div>
                                                    <div
                                                        class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">

                                                            Payment Type</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true"
                                                            [options]="PaymentTypeOutgoingList"
                                                            optionlabel="label"
                                                            formControlName="paymentType"
                                                            placeholder="Select Payment Type">
                                                        </p-dropdown>

                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">
                                                            Comments

                                                        </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="text" class="form-control"
                                                            id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Comments"
                                                            formControlName="comments">

                                                    </div>

                                                    <div
                                                        class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">

                                                            Status ID </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true"
                                                            formControlName="status"
                                                            optionlabel="label"
                                                            [options]="allStatusList"
                                                            placeholder="Select Status ID">
                                                        </p-dropdown>

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">

                                                            From Party ID</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown filter="true"
                                                            formControlName="fromPartyId"
                                                            [options]="partyIdArray"
                                                            optionlabel="label"
                                                            placeholder="Select From Party ID">
                                                        </p-dropdown>
                                                    </div>

                                                    <div
                                                        class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">

                                                            To Party ID </label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown filter="true"
                                                            formControlName="toPartyId"
                                                            [options]="partyIdArray"
                                                            optionlabel="label"
                                                            placeholder="Select To Party ID">
                                                        </p-dropdown>

                                                    </div>


                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">

                                                    <div class="col-lg-3 form-group rightForm">
                                                        <label for="exampleInputEmail1">

                                                            Amount </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" class="form-control"
                                                            id="exampleInputEmail1"
                                                            aria-describedby="emailHelp"
                                                            placeholder="Enter Amount"
                                                            formControlName="amount">

                                                    </div>

                                                </div>
                                            </div>


                                        </div>


                                    </form>
                                    <div class="main-submit-button" style="margin-left: -26%;">
                                        <button _ngcontent-cta-c128="" type="submit"
                                            class="btn btn-secondary submit-btn"
                                            (click)="findPaymentsList()">Find</button>&nbsp;
                                        <button type="button" class="btn btn-danger ml-2"
                                            (click)="resetPayment()">Reset</button>


                                    </div>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>

                <div class="w3-card-4 classCard w-100 mt-3">
                    <div
                        class="d-flex bd-highlight port-header-contact-import mb-3 create-lead font-medium mb-0 suppliers-wrapper">
                        <p class="h-text">Search Results
                        </p>
                    </div>
                    <div class="all-users-infomation font-13 font-medium own-user-select">
                        <div class="col-lg-12 col-12">
                            <div class="form-group">
                                <div class="card own-account-table">
                                    <p-table [value]="PaymentsList"
                                        [paginator]="true" [rows]="rows" scrollWidth="100%"
                                        [rowsPerPageOptions]="[10,25,50, 100,200,300,500]"
                                        [responsive]="true" [totalRecords]="total"
                                        [scrollable]="true" styleClass="p-datatable-customers"
                                        (sortFunction)="customSort($event)" [customSort]="true">
                                        <ng-template pTemplate="header">
                                            <tr style="background: #0d3769;">
                                                <th pSortableColumn="code"
                                                    [ngStyle]="{'width':'190px'}">
                                                    <div class="checkbox-align"
                                                        style="color: white;">
                                                        <p-checkbox _ngcontent-egj-c329=""
                                                            styleclass="custom-checkbox"
                                                            binary="true"
                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                            <div
                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                <div
                                                                    class="ui-helper-hidden-accessible">
                                                                    <input type="checkbox"
                                                                        name="undefined"
                                                                        value="undefined">
                                                                </div>
                                                                <div role="checkbox"
                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                    <span
                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                </div>
                                                            </div>
                                                            <!---->
                                                        </p-checkbox>
                                                        Payment ID <p-sortIcon field="code">
                                                        </p-sortIcon>
                                                    </div>
                                                    <input
                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                        value="" autocomplete="off"
                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false"
                                                        aria-readonly="false">
                                                </th>
                                                <th [ngStyle]="{'width':'190px'}"
                                                    pSortableColumn="name">
                                                    <div style="color: white;"> Payment Type

                                                        <p-sortIcon field="name">
                                                        </p-sortIcon>
                                                    </div>
                                                    <input
                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                        value="" autocomplete="off"
                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false"
                                                        aria-readonly="false">
                                                </th>
                                                <th [ngStyle]="{'width':'190px'}"
                                                    pSortableColumn="category">
                                                    <div style="color: white;"> Comments
                                                        <p-sortIcon field="category">
                                                        </p-sortIcon>
                                                    </div>
                                                    <input
                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                        value="" autocomplete="off"
                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false"
                                                        aria-readonly="false">
                                                </th>
                                                <th [ngStyle]="{'width':'190px'}"
                                                    pSortableColumn="quantity">
                                                    <div style="color: white;"> Status
                                                        <p-sortIcon field="quantity">
                                                        </p-sortIcon>
                                                    </div>
                                                    <input
                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                        value="" autocomplete="off"
                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false"
                                                        aria-readonly="false">
                                                </th>
                                                <th pSortableColumn="price"
                                                    [ngStyle]="{'width':'190px'}">
                                                    <div style="color: white;"> Payment Method Id
                                                        <p-sortIcon field="price"></p-sortIcon>
                                                    </div>
                                                    <input
                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                        value="" autocomplete="off"
                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false"
                                                        aria-readonly="false">
                                                </th>
                                                <th pSortableColumn="price"
                                                    [ngStyle]="{'width':'190px'}">
                                                    <div style="color: white;"> From Party Id
                                                        <p-sortIcon field="price"></p-sortIcon>
                                                    </div>
                                                    <input
                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                        value="" autocomplete="off"
                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false"
                                                        aria-readonly="false">
                                                </th>
                                                <th pSortableColumn="price"
                                                    [ngStyle]="{'width':'190px'}">
                                                    <div style="color: white;"> To Party Id
                                                    </div>
                                                    <input
                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                        style="visibility:hidden;" value=""
                                                        autocomplete="off"
                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false"
                                                        aria-readonly="false">
                                                </th>

                                                <th pSortableColumn="price"
                                                    [ngStyle]="{'width':'190px'}">
                                                    <div style="color: white;"> Amount</div>
                                                    <input
                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                        style="visibility:hidden;" value=""
                                                        autocomplete="off"
                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false"
                                                        aria-readonly="false">
                                                </th>
                                                <th pSortableColumn="price"
                                                    [ngStyle]="{'width':'190px'}">
                                                    <div style="color: white;"> Outstanding
                                                        Amount</div>
                                                    <input
                                                        id="j_idt727:j_idt728:j_idt731:filter"
                                                        name="j_idt727:j_idt728:j_idt731:filter"
                                                        class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all"
                                                        style="visibility:hidden;" value=""
                                                        autocomplete="off"
                                                        aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                        role="textbox" aria-disabled="false"
                                                        aria-readonly="false">
                                                </th>


                                            </tr>
                                        </ng-template>
                                        <ng-template pTemplate="body" let-product>
                                            <tr>
                                                <td [ngStyle]="{'width':'200px'}">
                                                    <div class="checkbox-align">
                                                        <p-checkbox _ngcontent-egj-c329=""
                                                            styleclass="custom-checkbox"
                                                            binary="true"
                                                            class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                            <div
                                                                class="custom-checkbox ui-chkbox ui-widget">
                                                                <div
                                                                    class="ui-helper-hidden-accessible">
                                                                    <input type="checkbox"
                                                                        name="undefined"
                                                                        value="undefined">
                                                                </div>
                                                                <div role="checkbox"
                                                                    class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                    <span
                                                                        class="ui-chkbox-icon ui-clickable"></span>
                                                                </div>
                                                            </div>
                                                            <!---->
                                                        </p-checkbox>
                                                        <!--  <span
                                                            routerLink="/financial/{{invoiceType}}/invoices/detailed-summary"
                                                            [queryParams]="{invoiceId:product.invoiceId}"
                                                             class="account-button">{{product.invoiceId}}</span> -->
                                                            <span  class="account-button"  
                (click)="detailMainPage(product.paymentId,product.paymentType,product.status,product.comments,product.partyIdFrom,product.partyIdTo,product.effectiveDate,product.amount)">{{product.paymentId}}</span>
                                                    </div>
                                                </td>


                                                <td [ngStyle]="{'width':'190px'}">
                                                    {{product.paymentTypeId}}
                                                </td>
                                                <td [ngStyle]="{'width':'190px'}">
                                                    {{product.comments}}
                                                </td>
                                                <td [ngStyle]="{'width':'190px'}">
                                                    {{product.statusId}}
                                                </td>
                                                <td [ngStyle]="{'width':'190px'}">
                                                    {{product.paymentMethodId}}
                                                </td>
                                                <td [ngStyle]="{'width':'190px'}">
                                                    {{product.partyIdFrom}}
                                                </td>
                                                <td [ngStyle]="{'width':'190px'}">
                                                    {{product.partyIdTo}}
                                                </td>
                                                <td [ngStyle]="{'width':'190px'}">
                                                    {{product.amount}}
                                                </td>
                                                <td [ngStyle]="{'width':'190px'}">
                                                    {{product.outstandingAmount}}
                                                </td>



                                            </tr>

                                        </ng-template>
                                        <ng-template pTemplate="emptymessage" let-columns>
                                            <tr>
                                                <td>
                                                    <span class="text-center">
                                                        <h4 class="ml-2">No Record Found</h4>
                                                    </span>
                                                </td>
                                            </tr>
                                        </ng-template>
                                    </p-table>
                                    <p class="paginate_data">
                                        View per page

                                    </p>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Find Sales Invoices By Due Date   </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton1>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                            <li [ngClass]="activeCategory==2?'active':''"><a>
                                Find Sales Invoices By Due Date
                                </a></li>
                        </ul>
                    </div>

                    <div class="all-users-infomation font-13 font-medium own-user-select">
                        <div class="form-group">
                            <div>
                                <div class="container-fluid">
                                    <div class="row">
                                        <form class="w-100">

                                            <div>
                                                <div 
                                                    class="all-users-infomation font-13 font-medium own-user-select allUserPadding">
                                                    <form [formGroup]="createSalesInvoices">
                                                        <div class="row" style="margin-left: 6%;">
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1"
                                                                            style="color: black!important;">
                                                                            Organization Party ID
                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <p-dropdown filter="true"
                                                                            placeholder="Select Organization Party ID"
                                                                            formControlName="organizationPartyId"
                                                                            [options]="organizationPartyIdArray" optionlabel="label">
                                                                        </p-dropdown>
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                                        <label for="exampleInputEmail1"> Party ID</label>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <p-dropdown filter="true"
                                                                            placeholder="Select Party ID"
                                                                            formControlName="PartyID"
                                                                            [options]="partyIdArray" optionlabel="label">
                                                                        </p-dropdown>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Days Offset	</label>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                                         aria-describedby="emailHelp" formControlName="DaysOffset" placeholder="Enter Days Offset	">
                                                                    </div>


                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12" style="margin-left: 83%;">
                                                                <button _ngcontent-cta-c128=""
                                                                    (click)="FindSalesInvoicesByDueDate()" type="submit"
                                                                    class="btn btn-secondary submit-btn">Select</button>
                                                            </div>

                                                        </div>
                                                    </form>
                                                </div>

                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </div>
    </div>
</div>


<div class="modal fade" id="exampleModalCenterTwo" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTwo"
    aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">

                <span class="color-black pl-1"> Find Sales Invoices By Due Date   </span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>
            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class="container-fluid">
                        <ul class="tabbing-section tabbing-accordians tabClass" style="padding-left:9px">
                            <li [ngClass]="activeCategory==2?'active':''"><a>
                                Find Sales Invoices By Due Date
                                </a></li>
                        </ul>
                    </div>

                    <div class="all-users-infomation font-13 font-medium own-user-select">
                        <div class="form-group">
                            <div>
                                <div class="container-fluid">
                                    <div class="row">
                                        <form class="w-100">

                                            <div>
                                                <div
                                                    class="all-users-infomation font-13 font-medium own-user-select allUserPadding">
                                                    <form [formGroup]="createPurchaseInvoices">
                                                        <div class="row" style="margin-left: 6%;">
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1"
                                                                            style="color: black!important;">
                                                                            Organization Party ID
                                                                        </label>
                                                                    </div>

                                                                    <div class="col-lg-3">
                                                                        <p-dropdown filter="true"
                                                                            placeholder="Select Organization Party ID"
                                                                            formControlName="organizationPartyId"
                                                                            [options]="organizationPartyIdArray" optionlabel="label">
                                                                        </p-dropdown>
                                                                    </div>

                                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                                        <label for="exampleInputEmail1"> Party ID</label>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <p-dropdown filter="true"
                                                                            placeholder="Select Party ID"
                                                                            formControlName="PartyID"
                                                                            [options]="partyIdArray" optionlabel="label">
                                                                        </p-dropdown>
                                                                    </div>

                                                                </div>
                                                            </div>
                                                            <div class="col-lg-12">
                                                                <div class="row">

                                                                    <div class="col-lg-3 form-group classInput">
                                                                        <label for="exampleInputEmail1">Days Offset	</label>
                                                                    </div>
                                                                    <div class="col-lg-3">
                                                                        <input type="text" class="form-control" id="exampleInputEmail1"
                                                                         aria-describedby="emailHelp" formControlName="DaysOffset" placeholder="Enter Days Offset	">
                                                                    </div>


                                                                </div>
                                                            </div>

                                                            <div class="col-lg-12" style="margin-left: 83%;">
                                                                <button _ngcontent-cta-c128=""
                                                                    (click)="FindPurchaseInvoicesByDueDate()" type="submit"
                                                                    class="btn btn-secondary submit-btn">Select</button>
                                                            </div>

                                                        </div>
                                                    </form>
                                                </div>

                                            </div>
                                        </form>
                                    </div>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>


            </div>

        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>