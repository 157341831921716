<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-between bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels">The Profile of {{positionData.emplPositionId}} </span>
               <span class="d-flex">

                <button type="submit" (click)="homeButton();"
                    class=" btn btn-outline-secondary mr-2">Home</button>

                <button type="submit" (click)="cancelSubmit();"
                    class="btn btn-danger buttonclass ">Back</button>
            </span>
            </div>
            <div class=" bg-white color-grey">


                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form class="w-100">
                                <div class="port-header-contact create-lead font-medium mb-0 text-white">
                                    <div class="w-100">

                                        <div class="panel-group">
                                            <div class="divA">
                                                <div class="w3-card-4 classCardView">
                                                    <header class="w3-container w3-blue">
                                                        <div class="header-tabs">
                                                            <h4 class=" common-styling h4Margin">
                                                                Employee Position Information
                                                            </h4>
                                                            <span class="edit-buttons hCreate">

                                                                <a click="getEmployeePosition()" data-toggle="modal"
                                                                    data-target="#exampleModalCenter">Update</a>
                                                            </span>
                                                        </div>
                                                    </header>
                                                    <!-- <div class="header-tabs" style="background: #cce0e95e;">
                                                    
                                                        <h4 class=" common-styling hCard">
                                                            Employee Position Information
                                                        </h4>
                                                        <span class="edit-buttons hCreate"
                                                        (click)="reportTo()">Create</span>
                                                    </div> -->
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select" style="height: 232px;
                                                        overflow-y: scroll;">
                                                            <div class="color-black container">
                                                                <div *ngIf="positionData" class="row">
                                                                    <div class="article-container">
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Empl Position ID
                                                                            </p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{positionData.emplPositionId}}</span>
                                                                        </div>
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Status ID</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{positionData.status}}</span>

                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Party ID</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{positionData.partyId}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Budget ID</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{positionData.budgetId}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Budget Item Seq ID
                                                                            </p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{positionData.budgetItemSeqId}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Empl Position Type ID</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{positionData.emplPositionType}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Estimated From Date
                                                                            </p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{positionData.estimatedFromDate
                                                                                | date :'yyyy-MM-dd hh:mm:ss'}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Estimated Through Date
                                                                            </p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{positionData.estimatedThruDate
                                                                                | date :'yyyy-MM-dd hh:mm:ss'}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Salary Flag</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{positionData.salaryFlag}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Exempt Flag</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{positionData.exemptFlag}}</span>
                                                                        </div>

                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Fulltime Flag</p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{positionData.fulltimeFlag}}</span>
                                                                        </div>

                                                                        <div class="article">

                                                                            <p class="col-lg-6 col-12">Temporary Flag
                                                                            </p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{positionData.temporaryFlag}}</span>
                                                                        </div>
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Actual From Date
                                                                            </p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{positionData.actualFromDate
                                                                                | date :'yyyy-MM-dd hh:mm:ss'}}</span>
                                                                        </div>
                                                                        <div class="article">
                                                                            <p class="col-lg-6 col-12">Actual Through Date
                                                                            </p>
                                                                            <span class="color-grey pl-20 col-lg-10 col-12">{{positionData.actualThruDate
                                                                                | date :'yyyy-MM-dd hh:mm:ss'}}</span>
                                                                        </div>




                                                                    </div>




                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>

                                                </div>
                                                <br>
                                                <div class="w3-card-4 classCardView">
                                                    <div class="header-tabs">


                                                        <h4 class=" common-styling hCard">
                                                            Fulfillments
                                                        </h4>
                                                        <span class="edit-buttons hCreate" data-toggle="modal" data-target="#exampleModalTwo">Create New Fulfillments</span>
                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select allUserCardDetail">
                                                            <div class="form-group w-100">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="positionDataFulfilment" [paginator]="false"   scrollHeight="140px" [rows]="rows" 
                                                                    scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th pSortableColumn="code"  [ngStyle]="{'width':'200px'}">

                                                                                    <div style="color: white;" class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>Party Id
                                                                                        <p-sortIcon field="code">
                                                                                        </p-sortIcon>
                                                                                    </div>

                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">

                                                                                </th>
                                                                                <th pSortableColumn="name"   [ngStyle]="{'width':'200px'}">
                                                                                    <div style="color: white;">From Date
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>

                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th pSortableColumn="category"  [ngStyle]="{'width':'200px'}">
                                                                                    <div style="color: white;">Through Date
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>

                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th pSortableColumn="quantity"  [ngStyle]="{'width':'200px'}">
                                                                                    <div style="color: white;">Comment
                                                                                        <p-sortIcon field="quantity">
                                                                                        </p-sortIcon>
                                                                                    </div>

                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>

                                                                                <th pSortableColumn="price" [style]="{width:'150px'}">
                                                                                    <div style="color: white;">Action
                                                                                        <p-sortIcon field="quantity">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td  [ngStyle]="{'width':'200px'}" >
                                                                                    <div style="color: white;" class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>

                                                                                        <span class="account-button"> {{product.partyDescription}} </span>
                                                                                       
                                                                                    </div>
                                                                                </td>
                                                                                <td  [ngStyle]="{'width':'200px'}">{{product.fromDate | date :'yyyy-MM-dd HH-mm-ss'}}
                                                                                </td>
                                                                                <td  [ngStyle]="{'width':'200px'}">{{product.thruDate | date :'yyyy-MM-dd HH-mm-ss'}}
                                                                                </td>
                                                                                <td  [ngStyle]="{'width':'200px'}"> {{product.comments}}</td>

                                                                                <td  [style]="{width:'150px'}">
                                                                                    <button type="submit" class="btn btn-secondary submit-btn" (click)="editFulfill(product.partyId,
                                                                                    product.fromDate,
                                                                                    product.thruDate,product.comments,product.partyDescription)">Update</button>
    
    
     <button type="submit"  class="btn btn-secondary submit-btn" (click)="deleteFullfiilment(product.partyId, product.fromDate)">Delete</button>
                                                                                </td>

                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                        <h4 class="ml-2">No Record Found
                                                                                        </h4>
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>

                                                                    </p-table>
                                                                    <p class="paginate_data">
                                                                        View per page </p>


                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                                <br>
                                                <div class="w3-card-4 classCardView">
                                                    <div class="header-tabs">


                                                        <h4 class=" common-styling hCard">
                                                            Responsibilities
                                                        </h4>
                                                        <span class="edit-buttons hCreate" data-toggle="modal" data-target="#exampleModalThree">Create New Responsibilities</span>
                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select allUserCardDetail">
                                                            <div class="form-group">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="positionDataResponsibility" [paginator]="false"   scrollHeight="140px"  [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th pSortableColumn="code">

                                                                                    <div style="color: white;" class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>Responsibility Type Id
                                                                                        <p-sortIcon field="code">
                                                                                        </p-sortIcon>
                                                                                    </div>

                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">

                                                                                </th>
                                                                                <th pSortableColumn="name" class="frmdt">
                                                                                    <div style="color: white;">From Date
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>

                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th pSortableColumn="category">
                                                                                    <div style="color: white;">Through Date
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>

                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th pSortableColumn="quantity">
                                                                                    <div style="color: white;">Comment
                                                                                        <p-sortIcon field="quantity">
                                                                                        </p-sortIcon>
                                                                                    </div>

                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>

                                                                                <th pSortableColumn="price">
                                                                                    <div style="color: white;">Action
                                                                                        <p-sortIcon field="quantity">
                                                                                        </p-sortIcon>
                                                                                    </div>
                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" style="visibility:hidden;" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td>
                                                                                    <div style="color: white;" class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                    

                                                                                        {{product.responsibilityType}}
                                                                                    </div>
                                                                                </td>
                                                                                <td>{{product.fromDate | date :'yyyy-MM-dd HH-mm-ss'}}
                                                                                </td>
                                                                                <td>{{product.thruDate | date :'yyyy-MM-dd HH-mm-ss'}}
                                                                                </td>
                                                                                <td> {{product.comments}}</td>

                                                                                <td>
                                                                                    <div>
                                                                                        <svg class="hover" (click)="editResponsibility(product.responsibilityTypeId,
                                                                             product.fromDate,
                                                                             product.thruDate,product.comments,product.responsibilityType)" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 21.223 21.222">
                                                                                            <g
                                                                                                transform="translate(0 -0.004)">
                                                                                                <g
                                                                                                    transform="translate(0 1.52)">
                                                                                                    <path
                                                                                                        d="M17.432,43.232a.758.758,0,0,0-.758.758v7.579a.758.758,0,0,1-.758.758H2.274a.758.758,0,0,1-.758-.758V36.411a.758.758,0,0,1,.758-.758h9.1a.758.758,0,1,0,0-1.516h-9.1A2.274,2.274,0,0,0,0,36.411V51.569a2.274,2.274,0,0,0,2.274,2.274H15.917a2.274,2.274,0,0,0,2.274-2.274V43.99A.758.758,0,0,0,17.432,43.232Z"
                                                                                                        transform="translate(0 -34.137)" />
                                                                                                </g>
                                                                                                <g
                                                                                                    transform="translate(4.548 0.004)">
                                                                                                    <g
                                                                                                        transform="translate(0 0)">
                                                                                                        <path
                                                                                                            d="M118.233.854a2.9,2.9,0,0,0-4.1,0l-9.984,9.983a.766.766,0,0,0-.183.3l-1.516,4.548a.758.758,0,0,0,.719,1,.77.77,0,0,0,.24-.039l4.548-1.516a.758.758,0,0,0,.3-.183l9.984-9.984A2.9,2.9,0,0,0,118.233.854Zm-1.072,3.032-9.853,9.853-2.944.983.98-2.94L115.2,1.929a1.385,1.385,0,1,1,1.96,1.957Z"
                                                                                                            transform="translate(-102.409 -0.004)" />
                                                                                                    </g>
                                                                                                </g>
                                                                                            </g>
                                                                                        </svg>
                                                                                        <svg (click)="deleteResponsibility(product.responsibilityTypeId,
                                                                             product.fromDate)" xmlns="http://www.w3.org/2000/svg" class="ml-20" width="16" height="16" viewBox="0 0 26 26">
                                                                                            <defs>
                                                                                                <style>
                                                                                                    .a {
                                                                                                        fill: #f44336;
                                                                                                    }

                                                                                                    .b {
                                                                                                        fill: #fafafa;
                                                                                                        margin-left: 20px;
                                                                                                    }
                                                                                                </style>
                                                                                            </defs>
                                                                                            <path class="a fill-color"
                                                                                                d="M13,0A13,13,0,1,0,26,13,13.014,13.014,0,0,0,13,0Zm0,0" />
                                                                                            <path class="b"
                                                                                                d="M165.364,163.831a1.083,1.083,0,1,1-1.532,1.532l-3.255-3.256-3.255,3.256a1.083,1.083,0,0,1-1.532-1.532l3.256-3.255-3.256-3.255a1.083,1.083,0,0,1,1.532-1.532l3.255,3.256,3.255-3.256a1.083,1.083,0,0,1,1.532,1.532l-3.256,3.255Zm0,0"
                                                                                                transform="translate(-147.576 -147.576)" />
                                                                                        </svg>
                                                                                    </div>
                                                                                </td>

                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                        <h4 class="ml-2">No Record Found
                                                                                        </h4>
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>


                                                                    <p class="paginate_data">
                                                                        View per page </p>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                            </div>







                                            <div class="divB">
                                                <div class="w3-card-4 classCardView">
                                                    <div class="header-tabs">

                                                        <h4 class=" common-styling hCard">
                                                            Reporting Structure:Reports To
                                                        </h4>
                                                        <span class="edit-buttons hCreate" data-toggle="modal" data-target="#exampleModalFour">Create New Reporting Structure</span>
                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="color-black container">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="reportsTo" [paginator]="false"   scrollHeight="140px"  [rows]="rows" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">

                                                                                    <div style="color: white;" class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>Empl Position Id reporting To
                                                                                        <p-sortIcon field="code">
                                                                                        </p-sortIcon>
                                                                                    </div>

                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">

                                                                                </th>
                                                                                <th pSortableColumn="name" [ngStyle]="{'width':'170px'}">
                                                                                    <div style="color: white;">From Date
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>

                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th pSortableColumn="category" [ngStyle]="{'width':'170px'}">
                                                                                    <div style="color: white;">Through Date
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>

                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th pSortableColumn="quantity" [ngStyle]="{'width':'170px'}">
                                                                                    <div style="color: white;">Comment
                                                                                        <p-sortIcon field="quantity">
                                                                                        </p-sortIcon>
                                                                                    </div>

                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th  [ngStyle]="{'width':'170px'}" pSortableColumn="quantity">
                                                                                    <div style="color: white;">Primary Flag
                                                                                        <p-sortIcon field="quantity">
                                                                                        </p-sortIcon>
                                                                                    </div>

                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>

                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td [ngStyle]="{'width':'170px'}">
                                                                                    <div style="color: white;" class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                       
                                                                                   

                                                                                    {{product.emplPositionIdReportingTo}}
                                                                                </div>
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">{{product.fromDate | date :'yyyy-MM-dd hh-mm-ss'}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">{{product.thruDate | date :'yyyy-MM-dd hh-mm-ss'}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}"> {{product.comments}}</td>
                                                                                <td [ngStyle]="{'width':'170px'}">{{product.primaryFlag}}</td>



                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                        <h4 class="ml-2">No Record Found
                                                                                        </h4>
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>

                                                                    </p-table>

                                                                   
                                                                </div>
                                                            </div>

                                                        </div>
                                                    </div>

                                                </div>
                                                <br>
                                                <div class="w3-card-4 classCardView">
                                                    <div class="header-tabs">

                                                        <h4 class=" common-styling hCard">
                                                            Reporting Structure:Reports To
                                                        </h4>
                                                        <span class="edit-buttons hCreate" data-toggle="modal" data-target="#exampleModalFive">Create New Reporting Structure</span>
                                                    </div>
                                                    <div class="panel-body">
                                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                                            <div class="color-black container">
                                                                <div class="card own-account-table">
                                                                    <p-table [value]="reportedTo" [paginator]="false"   scrollHeight="140px"  [rows]="3" scrollWidth="100%" [rowsPerPageOptions]="[10,25,50, 100,200,300,500]" [totalRecords]="total" [scrollable]="true" (sortFunction)="customSort($event)">
                                                                        <ng-template pTemplate="header">
                                                                            <tr style="background: #0d3769;">
                                                                                <th pSortableColumn="code" [ngStyle]="{'width':'170px'}">

                                                                                    <div style="color: white;" class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>Empl Position Id Managed By
                                                                                        <p-sortIcon field="code"></p-sortIcon>
                                                                                    </div>

                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">

                                                                                </th>
                                                                                <th pSortableColumn="name" [ngStyle]="{'width':'170px'}">
                                                                                    <div style="color: white;">From Date
                                                                                        <p-sortIcon field="name">
                                                                                        </p-sortIcon>
                                                                                    </div>

                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th pSortableColumn="category" [ngStyle]="{'width':'170px'}">
                                                                                    <div style="color: white;">Through Date
                                                                                        <p-sortIcon field="category">
                                                                                        </p-sortIcon>
                                                                                    </div>

                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th pSortableColumn="quantity" [ngStyle]="{'width':'170px'}">
                                                                                    <div style="color: white;">Comment
                                                                                        <p-sortIcon field="quantity">
                                                                                        </p-sortIcon>
                                                                                    </div>

                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>
                                                                                <th pSortableColumn="quantity" [ngStyle]="{'width':'170px'}">
                                                                                    <div style="color: white;">Primary Flag
                                                                                        <p-sortIcon field="quantity">
                                                                                        </p-sortIcon>
                                                                                    </div>

                                                                                    <input id="j_idt727:j_idt728:j_idt731:filter" name="j_idt727:j_idt728:j_idt731:filter" class="ui-column-filter ui-inputfield ui-inputtext ui-widget ui-state-default ui-corner-all" value="" autocomplete="off" aria-labelledby="j_idt727:j_idt728:j_idt731:filter_label"
                                                                                        role="textbox" aria-disabled="false" aria-readonly="false">
                                                                                </th>

                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="body" let-product>
                                                                            <tr>
                                                                                <td  [ngStyle]="{'width':'170px'}">
                                                                                    <div style="color: white;" class="checkbox-align">
                                                                                        <p-checkbox _ngcontent-egj-c329="" styleclass="custom-checkbox" binary="true" class="ng-untouched ng-pristine ng-valid ng-star-inserted">
                                                                                            <div class="custom-checkbox ui-chkbox ui-widget">
                                                                                                <div class="ui-helper-hidden-accessible">
                                                                                                    <input type="checkbox" name="undefined" value="undefined">
                                                                                                </div>
                                                                                                <div role="checkbox" class="ui-chkbox-box ui-widget ui-corner-all ui-state-default">
                                                                                                    <span class="ui-chkbox-icon ui-clickable"></span>
                                                                                                </div>
                                                                                            </div>
                                                                                            <!---->
                                                                                        </p-checkbox>
                                                                                       
                                                                                    {{product.emplPositionIdManagedBy}}
                                                                                </div>

                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">{{product.fromDate | date :'yyyy-MM-dd hh:mm:ss'}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">{{product.thruDate | date :'yyyy-MM-dd hh:mm:ss'}}
                                                                                </td>
                                                                                <td [ngStyle]="{'width':'170px'}">{{product.comments}}</td>
                                                                                <td [ngStyle]="{'width':'170px'}">{{product.primaryFlag}}</td>



                                                                            </tr>
                                                                        </ng-template>
                                                                        <ng-template pTemplate="emptymessage" let-columns>
                                                                            <tr>
                                                                                <td>
                                                                                    <span class="text-center">
                                                                                        <h4 class="ml-2">No Record Found
                                                                                        </h4>
                                                                                    </span>
                                                                                </td>
                                                                            </tr>
                                                                        </ng-template>
                                                                    </p-table>
                                                                    <div>
                                                                      
                                                                    </div>
                                                                </div>
                                                            </div>
                                                        </div>
                                                    </div>


                                                </div>
                                                <br>







                                            </div>


                                        </div>
                                    </div>
                                </div>

                            </form>
                        </div>
                    </div>
                </div>

            </div>
        </div>
    </div>
</div>
<!--Newcode-->
<div class="modal fade" id="exampleModalCenter" tabindex="-1" role="dialog" aria-labelledby="exampleModalCenterTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span *ngIf="!show" class="color-black pl-1">Update New Employee Position</span>
                <span *ngIf="show" class="color-black pl-1"> Create New Employee Position</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class=" bg-white color-grey" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li *ngIf="!show" [ngClass]="activeCategory==2?'active':''"><a>
                                        Create New Employee Position </a></li>
                                <li *ngIf="show" [ngClass]="activeCategory==2?'active':''"><a>
                                        Update New Employee Position </a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form *ngIf="createEmployeePositionService.addEmployee_position_form" [formGroup]="createEmployeePositionService.addEmployee_position_form" class="w-100">
                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Employee Position ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="empPositionId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Employee Position ID">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Status <span
                                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Status" formControlName="statusId" filter="true" [options]="statusArray" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">



                                                </div>
                                            </div>
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Internal Organization
                                                            <span style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter Internal Organization" formControlName="internalOrganization" filter="true" [options]="internalArray" optionlabel="label">

                                                        </p-dropdown>
                                                        <small class="text-danger" *ngIf="!!createEmployeePositionService.addEmployee_position_form.controls.internalOrganization.invalid && !!createEmployeePositionService.addEmployee_position_form.controls.internalOrganization.touched">
                                                            Organization is required!</small>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Budget ID </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="budgetId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Budget ID">
                                                    </div>

                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Budget Item Seq ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="email" formControlName="budgetItemSeqId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Budget Item Seq ID">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Employee Position Type
                                                            ID</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown placeholder="Enter EmployeePosition Type ID" formControlName="empPositionTypeId" [options]="positionArray" filter="true" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Estimated From Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="fromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Estimated From Date">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Estimated Through Date
                                                        </label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="thruDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Estimated Through Date">

                                                    </div>

                                                </div>
                                            </div>


                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Salary Flag <span
                                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="solicitation" placeholder="Enter Salary Flag" formControlName="salaryFlag" filter="true" optionlabel="label">

                                                        </p-dropdown>
                                                        <small class="text-danger" *ngIf="!!createEmployeePositionService.addEmployee_position_form.controls.salaryFlag.invalid && !!createEmployeePositionService.addEmployee_position_form.controls.salaryFlag.touched">
                                                            Salary Flag is required!</small>

                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Exempt Flag <span
                                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="solicitation" placeholder="Enter Exempt Flag" formControlName="exemptFlag" filter="true" optionlabel="label">

                                                        </p-dropdown>
                                                        <small class="text-danger" *ngIf="!!createEmployeePositionService.addEmployee_position_form.controls.exemptFlag.invalid && !!createEmployeePositionService.addEmployee_position_form.controls.exemptFlag.touched">
                                                            Exempt Flag is required!</small>


                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Fulltime Flag <span
                                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="solicitation" placeholder="Enter Fulltime Flag" formControlName="fulltimeFlag" filter="true" optionlabel="label">

                                                        </p-dropdown>
                                                        <small class="text-danger" *ngIf="!!createEmployeePositionService.addEmployee_position_form.controls.fulltimeFlag.invalid && !!createEmployeePositionService.addEmployee_position_form.controls.fulltimeFlag.touched">
                                                            Fulltime Flag is required!</small>



                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Temporary Flag <span
                                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <p-dropdown [options]="solicitation" placeholder="Enter Temporary Flag" formControlName="temporaryFlag" filter="true" optionlabel="label">

                                                        </p-dropdown>
                                                        <small class="text-danger" *ngIf="!!createEmployeePositionService.addEmployee_position_form.controls.temporaryFlag.invalid && !!createEmployeePositionService.addEmployee_position_form.controls.temporaryFlag.touched">
                                                            Temporary Flag is required!</small>


                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInput">
                                                        <label for="exampleInputEmail1">Actual From Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="actualFromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Actual From Date">


                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Actual Through Date</label>
                                                    </div>
                                                    <div class="col-lg-3">
                                                        <input type="date" formControlName="actualThruDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Actual From Date">


                                                    </div>

                                                </div>
                                            </div>



                                        </div>

                                        <div class="main-submit-button" style="margin-right: 10%;">
                                            <button type="submit" *ngIf="show" (click)="onUpdate()" class="btn btn-secondary submit-btn">Update</button>
                                            <button type="submit" *ngIf="!show" (click)="onSubmit()" class="btn btn-secondary submit-btn">Submit</button>

                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>

                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalTwo" tabindex="-1" role="dialog" aria-labelledby="exampleModalTwoTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span *ngIf="!show" class="color-black pl-1"> Update New Position Fulfillment</span>
                <span *ngIf="show" class="color-black pl-1">Create New Position Fulfillment</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton2>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                    <div class=" bg-white color-grey" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li *ngIf="showCreateBtn" [ngClass]="activeCategory==2?'active':''"><a>
                                        Add New Position Fulfillmnet </a></li>
                                <li *ngIf="showUpdateBtn" [ngClass]="activeCategory==2?'active':''"><a>
                                        Update Position Fulfillment </a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="fullfilment_employee_form" class="w-100">


                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Party Id </label>
                                                    </div>
                                                    <div class="col-lg-2" *ngIf="showCreateBtn">
                                                        <p-dropdown [options]="partyIdArray" placeholder="Enter Party Id " formControlName="partyId" [(ngModel)]="updatePartyID" filter="true" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2" *ngIf="showUpdateBtn">
                                                        <input [(ngModel)]="updatePartyID" type="text" formControlName="partyId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA__new_changes">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input [(ngModel)]="updateDateDate" type="date" formControlName="fromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Start Date">


                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputA">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" [(ngModel)]="throughFromDate" formControlName="thruDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Through Date">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInputA__new_changes">
                                                        <label for="exampleInputEmail1">Comments </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" [(ngModel)]="updateComment" formControlName="comments" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Comment">



                                                    </div>

                                                </div>
                                            </div>


                                            <div class="col-lg-4 col-12">
                                                <div class="form-group">


                                                    <input type="hidden" [(ngModel)]="positionId" formControlName="emplPositionId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                </div>
                                            </div>
                                        </div>

                                        <div class="proper" style="    margin-right: 26%;">
                                            <div class="col-lg-12 col-12 main-submit-button" *ngIf="showCreateBtn">
                                                <button type="submit" (click)="onSubmit()" class="btn btn-secondary submit-btn">Create</button>

                                            </div>
                                            <div class="col-lg-12 col-12 main-submit-button" *ngIf="showUpdateBtn">
                                                <button type="submit" (click)="onUpdateFull()" class="btn btn-secondary submit-btn">Update</button>

                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalThree" tabindex="-1" role="dialog" aria-labelledby="exampleModalThreeTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span *ngIf="!show" class="color-black pl-1">Create New Position Responsibilities</span>
                <span *ngIf="show" class="color-black pl-1">Update New Position Responsibilities</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton3>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class="bg-white color-grey " style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li *ngIf="showCreateBtn" [ngClass]="activeCategory==2?'active':''"><a>
                                        Add New Position Responsibilities </a></li>
                                <li *ngIf="showUpdateBtn" [ngClass]="activeCategory==2?'active':''"><a>
                                        Update Position Responsibilities</a></li>

                            </ul>
                        </div>

                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="responsibility_employee_form" class="w-100">


                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputB">
                                                        <label for="exampleInputEmail1">Responsibility Type Id </label>
                                                    </div>
                                                    <div class="col-lg-2" *ngIf="showCreateBtn">
                                                        <p-dropdown [options]="employeePositionResponsibilityArray" placeholder="Enter Responsibility Id " formControlName="responsibilityTypeId" [(ngModel)]="pDes" filter="true" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2" *ngIf="showUpdateBtn">
                                                        <input [(ngModel)]="pDes" type="text" formControlName="responsibilityTypeId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" disabled>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input [(ngModel)]="updateDateDate" type="date" formControlName="fromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Start Date">

                                                    </div>
                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputB">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <input type="date" [(ngModel)]="throughFromDate" formControlName="thruDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Through Date">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Comments</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" [(ngModel)]="updateComment" formControlName="comments" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Comment">

                                                    </div>

                                                </div>
                                            </div>


                                        </div>
                                        <div class="proper" style="margin-right: 21%;">
                                            <div class="col-lg-12 col-12 main-submit-button" *ngIf="showCreateBtn">
                                                <button type="submit" (click)="onSubmitRes()" class="btn btn-secondary submit-btn">Create</button>

                                            </div>
                                            <div class="col-lg-12 col-12 main-submit-button" *ngIf="showUpdateBtn">
                                                <button type="submit" (click)="onUpdateRes()" class="btn btn-secondary submit-btn">Update</button>

                                            </div>

                                        </div>

                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalFour" tabindex="-1" role="dialog" aria-labelledby="exampleModalFourTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span *ngIf="!show" class="color-black pl-1"> Update New Reporting Structure</span>
                <span *ngIf="show" class="color-black pl-1"> Create New Reporting Structure</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton4>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class="bg-white color-grey " style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li *ngIf="showCreateBtn" [ngClass]="activeCategory==2?'active':''"><a>
                                        Add New Reporting Structure </a></li>


                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="report_employee_formTo" class="w-100">


                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputC">
                                                        <label for="exampleInputEmail1">Empl Position Id Reporting
                                                            To</label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown [options]="employeePositionArray"  
                                                        placeholder="Enter Position Id Reporting To " 
                                                        formControlName="emplPositionIdReportingTo" filter="true" 
                                                        optionlabel="label">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input [(ngModel)]="updateDateDate" type="date" formControlName="fromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Start Date">

                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputC">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                         <input type="date" [(ngModel)]="throughFromDate" formControlName="thruDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Through Date">
                                                    </div>


                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Comments </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" [(ngModel)]="updateComment" formControlName="comments" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Comment">

                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputC">
                                                        <label for="exampleInputEmail1">Primary Flag <span
                                                                style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">

                                                        <p-dropdown [(ngModel)]="flag" [options]="solicitation" placeholder="Enter Primary Flag" formControlName="primaryFlag" filter="true" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>
                                                    <div class="col-lg-2"></div>
                                                    <div class="col-lg-2 form-group">
                                                        <label for="exampleInputEmail1"> </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="hidden" [(ngModel)]="positionId" formControlName="emplPositionId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >
                                                    </div>
                                                </div>
                                            </div>


                                        </div>
                                        <div class="proper mb-5" style=" margin-right: 16%;">
                                            <div class="col-lg-12 col-12 main-submit-button" *ngIf="showCreateBtn">
                                                <button type="submit" (click)="onSubmitReport()" class="btn btn-secondary submit-btn">Create</button>

                                            </div>

                                        </div>

                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>

<div class="modal fade" id="exampleModalFive" tabindex="-1" role="dialog" aria-labelledby="exampleModalFiveTitle" aria-hidden="true">
    <div class="modal-dialog" role="document">
        <div class="modal-content cssModelLength">
            <div class="modal-header">
                <span *ngIf="!show" class="color-black pl-1">Update New Reporting Structure</span>
                <span *ngIf="show" class="color-black pl-1"> Create New Reporting Structure</span>

                <button type="button" class="close cssButtonModel" data-dismiss="modal" aria-label="Close">
                    <span aria-hidden="true" #closebutton5>&times;</span>
                </button>

            </div>
            <div class="modal-body d-flex">
                <div class="bg-white color-grey " style="width: 100%;">
                    <div class="w3-card-4 classCardPopUp" style="width: 100%;">
                        <div class="container-fluid">
                            <ul class="tabbing-section tabbing-accordians tabClass">

                                <li *ngIf="showCreateBtn" [ngClass]="activeCategory==2?'active':''"><a>
                                Add New Reporting Structure </a></li>


                            </ul>
                        </div>


                        <div class="create-new-leade mt-2">
                            <div class="container-fluid">
                                <div class="row">
                                    <form [formGroup]="report_employee_form" class="w-100">


                                        <div class="all-users-infomation font-13 font-medium own-user-select">
                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Empl Position Id Managed By</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown [options]="employeePositionArray" placeholder="Enter Position Id Reporting To " formControlName="emplPositionIdManagedBy" [(ngModel)]="pDes" filter="true" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">From Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input [(ngModel)]="updateDateDate" type="date" formControlName="fromDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Start Date">


                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Through Date</label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="date" [(ngModel)]="throughFromDate" formControlName="thruDate" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Through Date">
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1">Comments </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="email" [(ngModel)]="updateComment" formControlName="comments" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" placeholder="Enter Comment">


                                                    </div>

                                                </div>
                                            </div>

                                            <div class="col-lg-12">
                                                <div class="row">
                                                    <div class="col-lg-3 form-group classInputD">
                                                        <label for="exampleInputEmail1">Primary Flag <span
                                                            style="color:red">*</span></label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <p-dropdown [(ngModel)]="flag" [options]="solicitation" placeholder="Enter Primary Flag" formControlName="primaryFlag" filter="true" optionlabel="label">

                                                        </p-dropdown>
                                                    </div>

                                                    <div class="col-lg-3 form-group classInput__new_changes">
                                                        <label for="exampleInputEmail1"> </label>
                                                    </div>
                                                    <div class="col-lg-2">
                                                        <input type="hidden" [(ngModel)]="positionId" formControlName="emplPositionId" class="form-control" id="exampleInputEmail1" aria-describedby="emailHelp" >

                                                    </div>
                                                </div>
                                            </div>


                                        </div>

                                        <div class="proper" style=" margin-right: 17%;">
                                            <div class="col-lg-12 col-12 main-submit-button" *ngIf="showCreateBtn">
                                                <button type="submit" (click)="onSubmitReportBy()" class="btn btn-secondary submit-btn">Create</button>

                                            </div>

                                        </div>
                                    </form>
                                </div>
                            </div>

                        </div>
                    </div>
                </div>
            </div>

        </div>
    </div>
</div>
<!--code end-->
<ngx-spinner></ngx-spinner>