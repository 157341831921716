import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { SortEvent } from 'primeng/api';
import { HeaderService } from '../../header/header.service';
import { Router, ActivatedRoute } from '@angular/router';
import { AccountsService } from '../../crm/accounts/accounts.service';
import { DetailPositionService } from './detailed-employee-position.service';
import { NgxSpinnerService } from "ngx-spinner";
import { ToastrService } from 'ngx-toastr';
import { DatePipe, DOCUMENT } from '@angular/common';
import Swal from 'sweetalert2'
import { AddEmployeePositionService } from '../add-employee-position/add-employee-position.service';
import { CreateEmployeePositionService } from '../add-employee-position/add-employee-position-service';
import { FullfilmentEmployeeFormService } from '../emp-fulfillment-position/fullfilment-service';
import { FormBuilder, FormGroup } from '@angular/forms';
import { EmployeePositionService } from '../employee-position/employee-position.service';

import { Location } from '@angular/common';

@Component({
  selector: 'app-detailed-employee-position',
  templateUrl: './detailed-employee-position.component.html',
  styleUrls: ['./detailed-employee-position.component.css']
})
export class DetailedEmployeePositionComponent implements OnInit {
  @ViewChild('closebutton') closebutton;
  @ViewChild('closebutton2') closebutton2;
  @ViewChild('closebutton3') closebutton3;
  @ViewChild('closebutton4') closebutton4;
  @ViewChild('closebutton5') closebutton5;
  products3: any;
  activeCategory = 2;
  total = 0;
  rowNumber = 0;
  rows = 3;
  positionId: any;
  positionData: any;
  positionDataArray: any = [];
  comments: any;
  responsibility: any = [];
  emplPositionId: any;
  reportedTo: any;
  reportsTo: any;
  Demo: any;
  demoPartyId: any;
  positionDataFulfilment: any;
  updateDateDate: any;
  positionDataResponsibility: any[] = [];
  solicitation: { label: string; value: string; }[];
  show: boolean;
  personId: any;
  partyIdArray: any[] = [];
  internalOrganization: any;
  internalArray: any[] = [];
  status: any;
  statusArray: any[] = [];
  positionType: any;
  getEstimateFrom: string;
  getEstimateTo: string;
  getActualFrom: string;
  getActualToFrom: string;
  updateestifromdate: string;
  updateestithroughdate: string;
  updateactualfromdate: string;
  updateactualthroughdate: string;
  positionArray: any[] = [];
  updateId: any;
  employeePosition: any;
  pageSize = 100;
  pageNo = 1;
  search = ""
  readonly employessSize = {
    pageNo: this.pageNo,
    pageSize: this.pageSize,

  }
  updatePartyID: any;
  partyIDArray: any[] = [];
  updateFromDate: any;
  throughFromDate: any;
  updateComment: any;
  showUpdateBtn = false;
  showCreateBtn = false;
  updatePosition: any;
  partyId: any;
  contactPartyId: any;
  updateToDate: string;
  updateDateDateForm: string;
  throughFromDateForm: string;
  pDes: any;
  fullfilment_employee_form: FormGroup;

  updateRespId: any;
  employeePositionResponsibility: any;
  responsibility_employee_form: FormGroup;

  flag: any;
  positionID:any;
  report_employee_form: FormGroup;
  employeePositionArray: any[]=[];
  employeePositionResponsibilityArray: any[]=[];
  partyIdForCreate: any;
  report_employee_formTo: FormGroup;
  constructor(
    readonly addEmployeePositionService: AddEmployeePositionService,
    readonly createEmployeePositionService: CreateEmployeePositionService,
    readonly accountsService: AccountsService,
    readonly HeaderService: HeaderService,
    readonly activatedRoute: ActivatedRoute,
    readonly router: Router,
    readonly spinner: NgxSpinnerService,
    readonly detailPositionService: DetailPositionService,
    @Inject(DOCUMENT) readonly document: Document,
      private _location: Location,
      readonly datePipe: DatePipe,
    readonly toastr: ToastrService,
    public addFullfilmentservice: FullfilmentEmployeeFormService,
    readonly formBuilder: FormBuilder,
    readonly employeePositionService: EmployeePositionService,


  ) {
    this.solicitation = [
      {
        label: 'Yes',
        value: 'Y'
      },
      {
        label: 'No',
        value: 'N'
      }

    ];
    

  }
  ngAfterViewInit(): void { this.spinner.show(); }

  ngOnInit(): void {
    this.getStatus();
    this.getPartyId();
    this.getPositionType();
    this.getInternalOrganization();

    this.activatedRoute.queryParams.subscribe(params => {
      this.positionId = params["positionId"];
    });
    if (this.positionId) {
      this.show = true;
      this.getEmployeePosition();
    }
    else {
      this.show = false;
    }
    this.createEmployeePositionService.addEmployee_position_form = null;
    this.createEmployeePositionService.addEmployeePositionForm(this.createEmployeePositionService.addEmployee_position_form);

    this.activatedRoute.queryParams.subscribe(params => {
      this.positionId = params["positionId"];
    });
    this.getDetails();
    this.getEmpPositionResponsibilityList();
    var promise = new Promise((resolve, reject) => {
      setTimeout(() => {
        this.getPositionReportedTo();

        resolve();
      }, 2000);
    });
    var promise = new Promise((resolve, reject) => {
      setTimeout(() => {
        this.getPositionReportsTo();
        resolve();
      }, 2000);
    });

    let contactPartyIdLocal;

    this.activatedRoute.queryParams.subscribe(params => {
      this.partyIdForCreate = params["positionId"];
      if (this.partyIdForCreate) {
        contactPartyIdLocal = this.partyIdForCreate;
      }

      if (this.partyIdForCreate) {
        this.showCreateBtn = true;
      }
    })
    this.activatedRoute.queryParams.subscribe(params => {
      this.updatePartyID = params["pid"];
      this.positionID = params["positionID"];
      if (this.positionID) {
        contactPartyIdLocal = this.positionID;
      }
      let fromdate = params["fromdate"];
      this.updateDateDateForm = fromdate
      this.updateDateDate = this.datePipe.transform(fromdate, "yyyy-MM-dd");

      let todate = params["todate"];

      this.throughFromDateForm = this.datePipe.transform(todate, "yyyy-MM-dd hh:mm:ss");
      this.throughFromDate = this.datePipe.transform(todate, "yyyy-MM-dd");

      this.updateComment = params["comment"];
      this.pDes = params["pDes"];

      if (this.updatePartyID && this.positionID) {
        this.showUpdateBtn = true;
      }
    });
    this.contactPartyId = contactPartyIdLocal;


    this.fullfilment_employee_form = this.formBuilder.group({
      comments: [''],
      thruDate: [''],
      fromDate: [''],
      partyId: [''],
      emplPositionId: ['']
    });

    //this.getEmployeePositionFull();


    this.activatedRoute.queryParams.subscribe(params => {
      this.partyIdForCreate = params["positionId"];
      if (this.partyIdForCreate) {
        contactPartyIdLocal = this.partyIdForCreate;
      }

      if (this.partyIdForCreate) {
        this.showCreateBtn = true;
      }
    })
    this.activatedRoute.queryParams.subscribe(params => {
      this.updatePartyID = params["pid"];
      this.positionID = params["positionID"];
      if (this.positionID) {
        contactPartyIdLocal = this.positionID;
      }
      let fromdate = params["fromdate"];
      this.updateDateDateForm = fromdate
      this.updateDateDate = this.datePipe.transform(fromdate, "yyyy-MM-dd");

      let todate = params["todate"];

      this.throughFromDateForm = this.datePipe.transform(todate, "yyyy-MM-dd hh:mm:ss");
      this.throughFromDate = this.datePipe.transform(todate, "yyyy-MM-dd");

      this.updateComment = params["comment"];
      this.pDes = params["pDes"];

      if (this.updatePartyID && this.positionID) {
        this.showUpdateBtn = true;
      }
    });
    this.contactPartyId = contactPartyIdLocal;


    this.responsibility_employee_form = this.formBuilder.group({
      comments: [''],
      thruDate: [''],
      fromDate: [''],
      responsibilityTypeId: [''],

    });
    this.getEmpPositionResponsibility();

    this.getEmployeePositionReport();
    this.activatedRoute.queryParams.subscribe(params => {
      this.partyIdForCreate = params["positionId"];

      if (this.partyIdForCreate) {
        contactPartyIdLocal = this.partyIdForCreate;
      }

      if (this.partyIdForCreate) {
        this.showCreateBtn = true;
      }
    })
    this.report_employee_formTo = this.formBuilder.group({
      comments: [''],
      thruDate: [''],
      fromDate: [''],
      emplPositionIdReportingTo: [''],
      primaryFlag: [''],
      emplPositionId: ['']

    });


    this.getEmployeePositionReportBy();
    this.activatedRoute.queryParams.subscribe(params => {
      this.partyIdForCreate = params["positionId"];

      if (this.partyIdForCreate) {
        contactPartyIdLocal = this.partyIdForCreate;
      }

      if (this.partyIdForCreate) {
        this.showCreateBtn = true;
      }
    })
    this.report_employee_form = this.formBuilder.group({
      comments: [''],
      thruDate: [''],
      fromDate: [''],
      emplPositionIdManagedBy: [''],
      primaryFlag: [''],
      emplPositionId: ['']

    });
  }
  // getEmployeePositionFull() {
  //   this.spinner.show();
  //   this.detailPositionService.getPartId().subscribe((res: any) => {
  //     this.employeePosition = res.data;
  //     this.spinner.hide();
  //     for (const value of this.employeePosition) {
  //       this.employeePositionArray.push({
  //         label: value.emplPositionId,
  //         value: value.emplPositionId
  //       })
  //     }

  //   })

  // }
  getEmpPositionResponsibility() {
    this.spinner.show();
    this.detailPositionService.getEmpPositionResponsibility().subscribe((res: any) => {
      this.employeePositionResponsibility = res.data;
      this.spinner.hide();
      for (const value of this.employeePositionResponsibility) {
        this.employeePositionResponsibilityArray.push({
          label: value.description,
          value: value.responsibilityTypeId
        })
      }

    })

  }
  homeButton(){
    this.router.navigate(['/hr/overview-hrm-main'])
  
  }
  cancelSubmit(){
    this._location.back();
  }
  
  ////////////////////////FUNCTIONS//////////////////////////////////////////

  getEmployeePositionReportBy() {
    this.spinner.show();
    const emplPosition = {
      actualFromDateFrom: "",actualFromDateTo: "",
      actualThruDateFrom: "", actualThruDateTo: "", budgetId: "",budgetIdSearchType: "Contains",
      budgetItemSeqId: "",budgetItemSeqIdSearchType: "Contains",emplPositionId: "",emplPositionIdSearchType: "Contains",
      emplPositionTypeId: "",emplPositionTypeIdSearchType: "Contains",estimatedStartDateFrom: "",
      estimatedStartDateTo: "",estimatedThruDateFrom: "",estimatedThruDateTo: "",
      exemptFlag: "",
      fulltimeFlag: "",
      partyId: "",
      partyIdSearchType: "Contains",
      salaryFlag: "",
      statusId: "",
      statusIdSearchType: "Contains",
      temporaryFlag: ""
    }
    this.employeePositionService.getEmployeePosition(this.employessSize,emplPosition).subscribe((res:any)=> {
      this.employeePosition = res.data;
      this.spinner.hide();
      for(const value of this.employeePosition){
        this.employeePositionArray.push({
          label:value.emplPositionId,
          value:value.emplPositionId
        })
      }
    })
   
  }
  onSubmitReportBy() {
    this.spinner.show();
     const formData = {
       comments : this.report_employee_form.value.comments,
       emplPositionId : this.partyIdForCreate,
       fromDate : this.report_employee_form.value.fromDate,
       emplPositionIdManagedBy : this.report_employee_form.value.emplPositionIdManagedBy,
       thruDate : this.report_employee_form.value.thruDate,
       primaryFlag:this.report_employee_form.value.primaryFlag
     }
    
     this.detailPositionService.createPositionReportStructureBy(formData).subscribe((res:any)=> {
       if(res.success) {
         this.toastr.success(res.data.responseMessage);
         this.spinner.hide();
         this.closebutton5.nativeElement.click();
         this.getPositionReportedTo();
         this.report_employee_form.reset();
       }
       else if(res.success == false) {
         this.toastr.error(res.debugMessage);
       }
     },(err) => {
       for(const value of err.error.errors){
         this.toastr.error(value.fieldName + " " + value.fieldError);
         }
    })
    this.spinner.hide();
   }

  getEmployeePositionReport() {
    this.spinner.show();
    const emplPosition = {
      actualFromDateFrom: "", actualFromDateTo: "", actualThruDateFrom: "", actualThruDateTo: "",
      budgetId: "", budgetIdSearchType: "Contains", budgetItemSeqId: "", budgetItemSeqIdSearchType: "Contains", emplPositionId: "",
      emplPositionIdSearchType: "Contains", emplPositionTypeId: "",
      emplPositionTypeIdSearchType: "Contains", estimatedStartDateFrom: "", estimatedStartDateTo: "", estimatedThruDateFrom: "", estimatedThruDateTo: "",
      exemptFlag: "",
      fulltimeFlag: "",
      partyId: "",
      partyIdSearchType: "Contains",
      salaryFlag: "",
      statusId: "",
      statusIdSearchType: "Contains",
      temporaryFlag: ""
    }
    this.employeePositionService.getEmployeePosition(this.employessSize, emplPosition).subscribe((res: any) => {
      this.employeePosition = res.data;
      this.spinner.hide();
      for (const value of this.employeePosition) {
        this.employeePositionArray.push({
          label: value.emplPositionId,
          value: value.emplPositionId
        })
      }
    })

  }
  onSubmitReport() {
    this.spinner.show();
    const formData = {
      comments: this.report_employee_formTo.value.comments,
      emplPositionId: this.partyIdForCreate,
      fromDate: this.report_employee_formTo.value.fromDate,
      emplPositionIdReportingTo: this.report_employee_formTo.value.emplPositionIdReportingTo,
      thruDate: this.report_employee_formTo.value.thruDate,
      primaryFlag: this.report_employee_formTo.value.primaryFlag
    }

    this.detailPositionService.createPositionReportStructureTo(formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.responseMessage);
        this.spinner.hide();
        this.closebutton4.nativeElement.click();
        this.getPositionReportsTo();
        this.report_employee_formTo.reset();
      }
      else if (res.success == false) {
        this.toastr.error(res.debugMessage);
      }
    }, (err) => {
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    })
    this.spinner.hide();
  }




  onSubmitRes() {

    this.spinner.show();
    let formData = { ... this.responsibility_employee_form.value }

    this.detailPositionService.postEmpPositionResponsibility(this.partyIdForCreate, formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.closebutton3.nativeElement.click();
        this.getEmpPositionResponsibilityList();
        this.responsibility_employee_form.reset();
      }
      else if (res.success == false) {
        this.toastr.error(res.message);
      }
    }, (err) => {
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    })
    this.spinner.hide();
  }
  onUpdateRes() {
    this.spinner.show();
    let formData = {
      comments: this.responsibility_employee_form.value.comments,

      fromDate: this.updateDateDateForm,
      responsibilityTypeId: this.updatePartyID,
      thruDate: this.responsibility_employee_form.value.thruDate
    }

    this.detailPositionService.updateEmpPositionResponsibility(this.positionID, this.updatePartyID, this.updateDateDateForm, formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.closebutton3.nativeElement.click();
        this.getEmpPositionResponsibilityList();
        this.responsibility_employee_form.reset();

      }
      else if (res.success == false) {
        this.toastr.error(res.message);
      }
    }, (err) => {
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    })
    this.spinner.hide();
  }


 
  onSubmit() {
    this.spinner.show();
    const partyid = this.partyIdForCreate;
    this.fullfilment_employee_form.get('emplPositionId').setValue(partyid);
    const formData = { ... this.fullfilment_employee_form.value }

    this.detailPositionService.postEmpPositionFullfillment(this.partyIdForCreate, formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.getDetails();
        this.fullfilment_employee_form.reset();

      }
      else if (res.success == false) {
        this.toastr.error(res.message);
      }
    }, (err) => {
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    })
    this.spinner.hide();
  }
  onUpdateFull() {
    this.spinner.show();
    const formData = {
      comments: this.fullfilment_employee_form.value.comments,
      emplPositionId: this.fullfilment_employee_form.value.emplPositionId,
      fromDate: this.updateDateDateForm,
      partyId: this.updatePartyID,
      thruDate: this.fullfilment_employee_form.value.thruDate
    }

    this.detailPositionService.updateEmpPositionFullfillment(this.positionID, this.updatePartyID, this.updateDateDateForm, formData).subscribe((res: any) => {
      if (res.success) {
        this.toastr.success(res.data.successMessage);
        this.spinner.hide();
        this.closebutton2.nativeElement.click();
        this.getDetails();
        this.fullfilment_employee_form.reset();

      }
      else if (res.success == false) {
        this.toastr.error(res.message);
      }
    }, (err) => {
      for (const value of err.error.errors) {
        this.toastr.error(value.fieldName + " " + value.fieldError);
      }
    })
    this.spinner.hide();
  }
  navigateFromCreate() {
    this.router.navigate(["/hr/employee-position/employee-position-details"], { queryParams: { positionId: this.partyIdForCreate } });
  }
  navigate() {
    this.router.navigate(["/hr/employee-position/employee-position-details"], { queryParams: { positionId: this.positionID } });
  }

  getPartyId() {
    this.spinner.show();
    this.addEmployeePositionService.getPartyId().subscribe(res => {
      this.personId = res.data.PersonsGroups;
      this.spinner.hide();
      for (const value of this.personId) {
        this.partyIdArray.push({
          label: value.partyId,
          value: value.partyId
        })
      }
    })
  }

  getInternalOrganization() {
    this.spinner.show();
    this.addEmployeePositionService.getInternalOrganization().subscribe(res => {
      this.internalOrganization = res.data;
      this.spinner.hide();
      for (const value of this.internalOrganization) {
        this.internalArray.push({
          label: value.groupName,
          value: value.partyId
        })
      }

    })
  }
  getStatus() {
    this.spinner.show();
    this.addEmployeePositionService.getStatus().subscribe(res => {
      this.status = res.data.emplPositionStatus;
      this.spinner.hide();
      for (const value of this.status) {
        this.statusArray.push({
          label: value.description,
          value: value.statusId
        })
      }
    })
  }
  // getPositionType() {
  //   this.spinner.show();
  //   this.addEmployeePositionService.getPositionType().subscribe(res => {
  //     this.positionType = res.data.leaveTypeId;
  //     this.spinner.hide();
  //     for (const value of this.positionType) {
  //       this.positionData.push({
  //         label: value.description,
  //         value: value.emplPositionTypeId
  //       })
  //     }
  //   })
  // }
  getPositionType() {
    this.spinner.show();
    this.addEmployeePositionService.getPositionType().subscribe(res => {
      this.positionType = res.data.leaveTypeId;
      this.spinner.hide();
      for (const value of this.positionType) {
        this.positionArray.push({
          label: value.description,
          value: value.emplPositionTypeId
        })
      }
    })
  }


  getDetails() {
    this.spinner.show();
    this.detailPositionService.getEmployeeDetail(this.positionId).subscribe((res: any) => {
      this.positionData = res.data
      this.positionDataFulfilment = res.data.fulfillments;

      this.comments = res.data.fulfillments[0].comments;
      this.emplPositionId = res.data.emplPositionId;

      this.responsibility.push({ data: res.data.responsibilities[0] })

      this.positionDataArray.push({
        fullfillment: res.data
      })
    })
    this.spinner.hide();
  }
  getEmpPositionResponsibilityList() {
    this.spinner.show();
    this.detailPositionService.getEmpPositionResponsibilityList(this.positionId).subscribe((res: any) => {
      this.positionDataResponsibility = res.data
      this.spinner.hide();
    })

  }


  getPositionReportedTo() {
    this.spinner.show();
    this.detailPositionService.getPositionReportedTo(this.positionId).subscribe(res => {
      this.reportedTo = res.data;
      this.spinner.hide();
    })

  }
  getPositionReportsTo() {
    this.spinner.show();
    this.detailPositionService.getPositionReportsTo(this.positionId).subscribe(res => {
      this.reportsTo = res.data;
      this.spinner.hide();
    }

    )

  }


  customSort(event: SortEvent) {
    event.data.sort((data1, data2) => {
      const value1 = data1[event.field];
      const value2 = data2[event.field];
      let result = null;

      if (value1 == null && value2 != null) {
        result = -1;
      }
      else if (value1 != null && value2 == null) {
        result = 1;
      }
      else if (value1 == null && value2 == null) {
        result = 0;
      }
      else if (typeof value1 === 'string' && typeof value2 === 'string') {
        result = value1.localeCompare(value2);
      }
      else {
        result = (value1 < value2) ? -1 : (value1 > value2) ? 1 : 0;
      }
      return (event.order * result);
    });
  }
  createFullfillment() {
    this.router.navigate(['/hr/employee-position/employee-fullfilment'], { queryParams: { positionId: this.positionId } })
  }
  editFulfill(pId, fromDate, toDate, Comment, pdes) {

    this.router.navigate(['/hr/employee-position/employee-fullfilment'], {
      queryParams: {
        positionID: this.positionId,
        comment: Comment, pid: pId, fromdate: fromDate, todate: toDate, pDes: pdes
      }
    })
  }
  deleteFullfiilment(id, fromdate) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.detailPositionService.deleteEmpPositionFullfillment(this.positionId, id, fromdate).subscribe((res: any) => {
          //  this.toastr.success("Delete Successfully");
          this.document.location.reload();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  createResponsibility() {
    this.router.navigate(['/hr/employee-position/employee-responsibility'], { queryParams: { positionId: this.positionId } })
  }
  editResponsibility(pId, fromDate, toDate, Comment, pdes) {

    this.router.navigate(['/hr/employee-position/employee-responsibility'], {
      queryParams: {
        positionID: this.positionId,
        comment: Comment, pid: pId, fromdate: fromDate, todate: toDate, pDes: pdes
      }
    })
  }
  deleteResponsibility(id, fromdate) {
    Swal.fire({
      title: 'Are you sure?',
      text: 'You will not be able to recover this data.!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonText: 'Yes, delete it!',
      cancelButtonText: 'No, keep it'
    }).then((result) => {
      if (result.value) {
        this.detailPositionService.deleteResponsibility(this.positionId, id, fromdate).subscribe((res: any) => {
          //  this.toastr.success("Delete Successfully");
          this.document.location.reload();
        }, (err) => {
          this.toastr.error(err.error.message);
        })
        Swal.fire(
          'Deleted!',
          'Deleted Successfully.',
          'success'
        )

      } else if (result.dismiss === Swal.DismissReason.cancel) {
        Swal.fire(
          'Cancelled',
          'Your data is safe :)',
          'error'
        )
      }
    })
  }
  createReportTo() {
    this.router.navigate(['/hr/employee-position/employee-reported-to'], { queryParams: { positionId: this.positionId } })
  }
  reportTo() {
    this.router.navigate(['/hr/employee-position/employee-reported-by'], { queryParams: { positionId: this.positionId } })

  }
  changeactiveCategoryFunction(tab: number) {
    this.activeCategory = tab;
  }

  getEmployeePosition() {
    this.spinner.show();
    let emplPosition = {
      actualFromDateFrom: "",
      actualFromDateTo: "",
      actualThruDateFrom: "",
      actualThruDateTo: "",
      budgetId: "",
      budgetIdSearchType: "Contains",
      budgetItemSeqId: "",
      budgetItemSeqIdSearchType: "Contains",
      emplPositionId: this.positionId,
      emplPositionIdSearchType: "Contains",
      emplPositionTypeId: "",
      emplPositionTypeIdSearchType: "Contains",
      estimatedStartDateFrom: "",
      estimatedStartDateTo: "",
      estimatedThruDateFrom: "",
      estimatedThruDateTo: "",
      exemptFlag: "",
      fulltimeFlag: "",
      partyId: "",
      partyIdSearchType: "Contains",
      salaryFlag: "",
      statusId: "",
      statusIdSearchType: "Contains",
      temporaryFlag: ""
    }
    this.addEmployeePositionService.getEmployeePositionById(this.positionId)
      .then(res => {
        this.spinner.show();
        setTimeout(() => {
          let fromdate = res.data.estimatedFromDate
          this.getEstimateFrom = this.datePipe.transform(fromdate, "yyyy-MM-dd");
          let toEstdate = res.data.estimatedThruDate
          this.getEstimateTo = this.datePipe.transform(toEstdate, "yyyy-MM-dd");
          let fromActualDate = res.data.actualFromDate
          this.getActualFrom = this.datePipe.transform(fromActualDate, "yyyy-MM-dd");
          let toActualDate = res.data.actualThruDate
          this.getActualToFrom = this.datePipe.transform(toActualDate, "yyyy-MM-dd");
          const formValue = this.createEmployeePositionService.addEmployee_position_form;
          formValue.patchValue({
            empPositionId: res.data.emplPositionId,
            statusId: res.data.statusId,
            internalOrganization: res.data.partyId,
            budgetId: res.data.budgetId,
            budgetItemSeqId: res.data.budgetItemSeqId,
            empPositionTypeId: res.data.emplPositionType,
            fromDate: this.getEstimateFrom,
            thruDate: this.getEstimateTo,
            salaryFlag: res.data.salaryFlag,
            exemptFlag: res.data.exemptFlag,
            fulltimeFlag: res.data.fulltimeFlag,
            temporaryFlag: res.data.temporaryFlag,
            actualFromDate: this.getActualFrom,
            actualThruDate: this.getActualToFrom,
            partyId: res.data.partyId
          })

        }, 3000);

      })
    this.spinner.hide();
  }
  onUpdate() {
    this.spinner.show();
    let fromdate = this.createEmployeePositionService.addEmployee_position_form.get('fromDate').value;
    this.updateestifromdate = this.datePipe.transform(fromdate, "yyyy-MM-dd hh:mm:ss");
    let throughdate = this.createEmployeePositionService.addEmployee_position_form.get('thruDate').value;
    this.updateestithroughdate = this.datePipe.transform(throughdate, "yyyy-MM-dd hh:mm:ss");

    let fromActualdate = this.createEmployeePositionService.addEmployee_position_form.get('actualFromDate').value;
    this.updateactualfromdate = this.datePipe.transform(fromActualdate, "yyyy-MM-dd hh:mm:ss");
    let throughActualdate = this.createEmployeePositionService.addEmployee_position_form.get('actualThruDate').value;
    this.updateactualthroughdate = this.datePipe.transform(throughActualdate, "yyyy-MM-dd hh:mm:ss");

    let formData = {
      "actualFromDate": this.updateactualfromdate,
      "actualThruDate": this.updateactualthroughdate,
      "budgetId": this.createEmployeePositionService.addEmployee_position_form.value.budgetId,
      "budgetItemSeqId": this.createEmployeePositionService.addEmployee_position_form.value.budgetItemSeqId,
      "empPositionId": this.createEmployeePositionService.addEmployee_position_form.value.empPositionId,
      "empPositionTypeId": this.createEmployeePositionService.addEmployee_position_form.value.empPositionTypeId,
      "exemptFlag": this.createEmployeePositionService.addEmployee_position_form.value.exemptFlag,
      "fromDate": this.updateestifromdate,
      "fulltimeFlag": this.createEmployeePositionService.addEmployee_position_form.value.fulltimeFlag,
      "partyId": this.createEmployeePositionService.addEmployee_position_form.value.partyId,
      "salaryFlag": this.createEmployeePositionService.addEmployee_position_form.value.salaryFlag,
      "statusId": this.createEmployeePositionService.addEmployee_position_form.value.statusId,
      "temporaryFlag": this.createEmployeePositionService.addEmployee_position_form.value.temporaryFlag,
      "thruDate": this.updateestithroughdate,
      "internalOrganization": this.createEmployeePositionService.addEmployee_position_form.value.internalOrganization
    }


    this.addEmployeePositionService.updateEmployeePosition(formData, this.positionId).subscribe((res: any) => {
      if (res.success) {
        this.spinner.hide();
        this.toastr.success("Form Updated Successfully");
        this.getEmployeePosition();
        this.closebutton.nativeElement.click();
        this.router.navigate(['/hr/employee-position']);
      }

      else if (res.success == false) {
        this.toastr.error(res.message);
        this.spinner.hide();
      }
    })
  }
  ngOnDestroy(): void {
    this.spinner.hide();
  }
}