import { DatePipe } from '@angular/common';
import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { ActivatedRoute, Router } from '@angular/router';
import { NgxSpinnerService } from 'ngx-spinner';
import { ToastrService } from 'ngx-toastr';

@Component({
  selector: 'app-new-username',
  templateUrl: './new-username.component.html',
  styleUrls: ['./new-username.component.css']
})
export class NewUsernameComponent implements OnInit {
  show: boolean;
  activeCategory=2;
  createUserName:FormGroup;
  constructor(
    readonly spinner: NgxSpinnerService,
    readonly datePipe: DatePipe,
    readonly router: Router,
    readonly toastr: ToastrService,
   
    readonly _ActivatedRoute: ActivatedRoute,
    readonly _FormBuilder: FormBuilder,
  ) {
    this.createUserName = this._FormBuilder.group({
      loginId: [''],
      curpassword: [''],
      verpassword: [''],
      passHint: [''],
      passChange: [''],
      extAuthId: [''],
     


    });
   }

  ngOnInit(): void {
    this.show=false
  }

}
