
import { Injectable } from '@angular/core';
import { FormGroup, FormBuilder } from '@angular/forms';
import { Responsibilities } from './responsibilities-model';

@Injectable({
    providedIn: 'root'
  })


export class ResponsibilitiesService {
    responsibilities_form : FormGroup;
  
  
  constructor(public _formBuilder: FormBuilder,
    private responsibilities: Responsibilities
    ) { } 

  responsibilitiesForm(data:any): void {
    if (!this.responsibilities_form) {
        this.responsibilities_form = this._formBuilder.group(this.responsibilities.mapDataToModel(data));
    }
  }
}

