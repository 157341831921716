<div class="container-fluid main-container-wrapper">
    <div class="row">
        <div class="col-12">
            <div class="port-header-contact justify-content-start bg-white mt-20 mb-20 font-medium">
               <span class="color-black pl-1 titlePanels" (click)="cancelSubmit();" style="cursor: pointer;"> FinAccount Type Gl Account</span>
                <span class="color-black pl-1" > >Add FinAccount Type Gl Account</span>

                <button type="submit" (click)="cancelSubmit();"  class="btn btn-danger buttonclass" >Cancel</button>
            </div>
            <div class=" bg-white color-grey">
                <div class="w3-card-4 classCard">
                <div class="container-fluid">
                    <ul class="tabbing-section tabbing-accordians tabClass">
          
                      <li [ngClass]="activeCategory==2?'active':''" *ngIf="!this.show"><a>
                        Add FinAccount Type Gl Account
                    </a></li>
                    <li [ngClass]="activeCategory==2?'active':''" *ngIf="this.show" ><a>
                        Update FinAccount Type Gl Account
                    </a></li>
                       
                   
                    </ul>
                  </div>
               
               
                <div class="create-new-leade mt-2">
                    <div class="container-fluid">
                        <div class="row">
                            <form  class="w-100" [formGroup]="createFin">
                                <div class="all-users-infomation font-13 font-medium own-user-select">
                                    <div class="col-lg-12">
                                        <div class="row">
                    
                                            <div class="col-lg-3 form-group classInput">
                                                <label for="exampleInputEmail1">
                    
                                                    Fin Account Type ID<span
                                                    style="color:red">*</span></label>
                                            </div>
                                         
                                            <div class="col-lg-2">
                                                <p-dropdown *ngIf="!this.show"  placeholder="Enter Fin Account Type ID" filter="true"
                                            formControlName="finType" 
                                            [options]="finAccountTypeIdArray" optionlabel="label" >
                                                            
                                            </p-dropdown>
                                            <input type="email" *ngIf="this.show"  formControlName="finType"  class="form-control" id="exampleInputEmail1"
                                            aria-describedby="emailHelp"  placeholder="Enter Fin Account Type ID" 
                                          readonly>

                                            </div>
                                            <div class="col-lg-3 form-group classInputA">
                                                <label for="exampleInputEmail1">
                                            
                                                    GL Account Id<span
                                                    style="color:red">*</span></label>
                                            </div>
                                            <div class="col-lg-2">
                                                <p-dropdown  placeholder="Enter GL Account" filter="true"
                                                formControlName="accountId"  
                                                [options]="glChartOfAccountArray" optionlabel="label">
                                                                
                                                </p-dropdown>
                                            </div>
                    
                                           
                    
                                        </div>
                                    </div>

                                
                                   
                                </div>
                                

                            </form>
                            <div class="main-submit-button" style="margin-left: 66%;">
                               
                                <button type="submit" *ngIf="!this.show" (click)="onSubmit();"   class="btn btn-secondary submit-btn">Save</button>
                                <button type="submit" *ngIf="this.show" (click)="onUpdate();"   class="btn btn-secondary submit-btn">Update</button>
                              
                            </div>
                        </div>
                    </div>

                </div>
                </div>

            </div>
           
        </div>
    </div>
</div>
<ngx-spinner></ngx-spinner>
